import { CloseIcon } from 'components/icons';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { showDialogModal } from './DialogModal';

// layer singleton
let layer = 0;

const ModalContext = React.createContext<
  React.Dispatch<React.SetStateAction<boolean>>
>(() => false);

export const usePreventClose = () => useContext(ModalContext);

const GlobalStyle = createGlobalStyle`
  body {
    overflow : hidden;
  }
  ::-webkit-scrollbar-track {
    //background-color:#003cc2;
    border-radius: 10px;
  }

::-webkit-scrollbar {
  width: 15px;
  border-radius: 10px;
  background-color: #e5ebf8;
}
::-webkit-scrollbar-thumb {
  width: 15px;
  border-radius: 10px;
  background-color: #003cc2;
}
`;

export type ModalProps = {
  onResolve: (params?: any) => any | undefined;
  close: (params?: any) => any | undefined;
  isOpen: boolean;
  open: boolean;
  instanceId: string;
  onReject: (rej?: any) => void;
};

const FixedContainer = styled.div<{ layer?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  overflow-y: auto;
  padding: 40px 0;
  z-index: ${props => (props.layer || 0) + 101};
`;

const ModalContainer = styled.div<{ width: number }>`
  background: #ffffff;
  position: relative;
  width: ${props => props.width}px;
  box-sizing: border-box;
  padding: 40px;
  z-index: 101;
  margin: auto;
  border-radius: 8px;
`;

const CloseIconStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 100;
`;
type ModalFrameProps = {
  children?: ReactNode;
  isOpen?: boolean;
  closeIcon?: boolean;
  onClose?: (result?: any) => void;
  width?: number;
  layer?: number;
};
export const ModalFrame = ({
  children,
  onClose,
  isOpen,
  closeIcon = false,
  width = 100,
}: ModalFrameProps) => {
  const [preventClose, setPreventClose] = useState<boolean>(false);

  // by setting a ref to the singleton layer we are keeping its value along the component's life
  const thisLayer = useRef<number>(layer);
  useEffect(() => {
    layer++;
    return () => {
      layer--;
    };
  }, []);

  // hide on location change
  const isMountRef = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (!isMountRef.current) onClose && onClose();
    isMountRef.current = false;
  }, [location, isMountRef]);

  const handleCloseConfirm = async (result?: any) => {
    const confirm = preventClose
      ? await showDialogModal({
          title: 'Êtes-vous sûr(e) de vouloir fermer sans enregistrer ?',
          text: 'Toutes vos modifications seront perdues.',
          confirmLabel: 'Fermer sans enregistrer',
          cancelLabel: 'Ne pas fermer',
        })
      : true;
    if (confirm) onClose?.(result);
  };

  return (
    <ModalContext.Provider value={setPreventClose}>
      <FixedContainer layer={thisLayer.current}>
        <GlobalStyle />
        {isOpen && <Background onClick={handleCloseConfirm} key="background" />}
        {isOpen && (
          <ModalContainer width={width} key="modal">
            {closeIcon && (
              <CloseIconStyled onClick={handleCloseConfirm}>
                <CloseIcon width="25px" height="25px" fill="blue" />
              </CloseIconStyled>
            )}
            {children}
          </ModalContainer>
        )}
      </FixedContainer>
    </ModalContext.Provider>
  );
};
