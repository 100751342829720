import {
  buildContractorByScopedData,
  checkEmail,
  contractorCreateOne,
  contractorFindMany,
  contractorUpdateOne,
  CONTRACTOR_SOCIAL_STATUS_SELECT,
  dpaeUpdateOne,
  escapeEmail,
  getScopedDataBySiret,
  queryClient,
  setBirthdayDateToZeroHours,
  uploadFile,
  userFindMany,
  validateFormPhoneFormatFR,
} from '@commons';
import {
  EContractorSocialStatus,
  EDpaeStatus,
  EFileType,
  IContractorCreateParams,
  IEstablishment,
} from '@freelancelabs/teoreme-commons';
import { DownloadIcon, InfoIconBlue } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  Button,
  Cell,
  ContractorCard,
  FileInput,
  Flex,
  FormControl,
  FormInfo,
  Grid,
  Input,
  Link,
  Radio,
  Text,
  UserCard,
  PhoneInputControlled,
  DatePickerControlled,
  StaticSelectControlled,
} from 'components/ui';
import { useShowMessage } from 'hooks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Theme } from 'styles';
import { differenceInYears } from 'date-fns';
type FormValues = {
  civility: string;
  firstName: string;
  lastName: string;
  birthday: Date;
  email: string; //Email principal
  phone?: string;
  dpae?: any;
  jobTitle?: string;
  user?: {
    email: string; //
  };
  contractor: {
    tmpData?: {
      email?: string; // email rattachement
    };
  };
  sendAppInvitation?: Boolean;
  socialStatus: EContractorSocialStatus;
};

type ContractorEstablishmentFormProps = {
  establishment: IEstablishment | undefined;
  notEditable: Boolean;
  onChangeDisableEstablishmentSelect: (value: boolean) => void;
};
const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
const grid6 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '6',
};
const grid4 = {
  xs: '4',
  sm: '4',
  md: '4',
  lg: '4',
  xl: '4',
};

export const CreateContractorForm = ({
  establishment,
  notEditable,
  onChangeDisableEstablishmentSelect,
}: ContractorEstablishmentFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    getValues,
    setValue,
    control,
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const history = useHistory();
  const showMessage = useShowMessage();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sendAppInvitation, setSendAppInvitation] = React.useState(false);
  const [dpaeFile, setDpaeFile] = useState<File | undefined>();
  const [similarUsers, setSimilarUsers] = useState<any>();
  const [isLink, setIsLink] = useState<boolean>(false);
  const [checkedUser, setCheckedUser] = useState<any>(false);
  const [similarUserSelected, setSimilarUserSelected] = useState<any>();
  const socialStatus = watch('socialStatus');
  const dpae = watch('dpae');
  const checkExistingUser = async (
    firstName: string,
    lastName: string,
    birthday: Date,
    secondaryEmail?: string,
    email?: string
  ) => {
    const usersMatch: any = {
      users: [],
      contractors: [],
    };
    setIsLink(false);
    setCheckedUser(false);
    setSimilarUsers(undefined);
    const UEmail = email?.length && email;
    const Semail = secondaryEmail?.length && secondaryEmail;
    try {
      if (UEmail || Semail) {
        let OR = [];
        if (UEmail) {
          OR.push({
            'user.email': {
              $regex: `^${escapeEmail(UEmail)}$`,
              $options: 'i',
            },
          });
        }
        if (Semail) {
          OR.push({
            'user.email': {
              $regex: `^${escapeEmail(Semail)}$`,
              $options: 'i',
            },
          });
        }
        const contractorsFinded = await contractorFindMany({
          filterObject: {
            $or: OR,
          },
          sort: '',
          skip: 0,
          limit: 10,
        });
        usersMatch.contractors = [
          ...usersMatch.contractors,
          ...contractorsFinded?.contractors,
        ];
        OR = [];
        if (UEmail) {
          OR.push({
            email: {
              $regex: `^${escapeEmail(UEmail)}$`,
              $options: 'i',
            },
          });
        }
        if (Semail) {
          OR.push({
            email: {
              $regex: `^${escapeEmail(Semail)}$`,
              $options: 'i',
            },
          });
        }
        // no contractor found check in user
        if (contractorsFinded?.totalCount === 0) {
          //CHECK USERS
          const usersFinded = await userFindMany({
            filterObject: {
              $or: OR,
            },
            sort: '',
            skip: 0,
            limit: 10,
          });
          usersMatch.users = [...usersMatch.users, ...usersFinded?.users];
          if (
            usersFinded?.totalCount === 0 &&
            contractorsFinded?.totalCount === 0
          ) {
            //CHECK CONTRACTOR
            const contractorsFinded = await contractorFindMany({
              filterObject: {
                $and: [
                  {
                    firstName: {
                      $regex: `^${firstName}$`,
                      $options: 'i',
                    },
                  },
                  {
                    lastName: {
                      $regex: `^${lastName}$`,
                      $options: 'i',
                    },
                  },
                  {
                    $expr: {
                      $eq: [
                        { $year: '$birthday' },
                        new Date(birthday.toISOString()).getUTCFullYear(), // Mango start 1
                      ],
                    },
                  },
                  {
                    $expr: {
                      $eq: [
                        { $month: '$birthday' },
                        new Date(birthday.toISOString()).getUTCMonth() + 1, // Mango start 1
                      ],
                    },
                  },
                  {
                    $expr: {
                      $eq: [
                        { $dayOfMonth: '$birthday' },
                        new Date(birthday.toISOString()).getUTCDate(), // Mango start 1
                      ],
                    },
                  },
                ],
              },
              sort: '',
              skip: 0,
              limit: 10,
            });
            usersMatch.contractors = [
              ...usersMatch.contractors,
              ...contractorsFinded?.contractors,
            ];
          }
        }
      } else {
        //CHECK CONTRACTOR
        const contractorsFinded = await contractorFindMany({
          filterObject: {
            $and: [
              {
                firstName: {
                  $regex: `^${firstName}$`,
                  $options: 'i',
                },
              },
              {
                lastName: {
                  $regex: `^${lastName}$`,
                  $options: 'i',
                },
              },
              {
                $expr: {
                  $eq: [
                    { $year: '$birthday' },
                    new Date(birthday.toISOString()).getUTCFullYear(),
                  ],
                },
              },
              {
                $expr: {
                  $eq: [
                    { $month: '$birthday' },
                    new Date(birthday.toISOString()).getUTCMonth() + 1, // Mango start 1
                  ],
                },
              },
              {
                $expr: {
                  $eq: [
                    { $dayOfMonth: '$birthday' },
                    new Date(birthday.toISOString()).getUTCDate(),
                  ],
                },
              },
            ],
          },
          sort: '',
          skip: 0,
          limit: 10,
        });
        usersMatch.contractors = [
          ...usersMatch.contractors,
          ...contractorsFinded?.contractors,
        ];
      }
      setSimilarUsers(usersMatch);
      if (
        usersMatch.users?.length === 0 &&
        usersMatch.contractors.length === 1
      ) {
        if (
          getScopedDataBySiret(
            usersMatch.contractors?.[0]?.scopedData,
            establishment?.siret as string
          )
        ) {
          setIsLink(true);
        } else {
          //FORCE USE THIS CONTRACTOR
          setSimilarUserSelected(usersMatch.contractors?.[0]);
        }
        //force use email existing
        if (usersMatch.contractors?.[0]?.user?.email) {
          setValue(
            'contractor.tmpData.email',
            usersMatch.contractors?.[0]?.user?.email
          );
        }

        //setValue('user.email', usersMatch.contractors?.[0]?.user.email);
      }
    } catch (e) {
      //console.log('err', e);
    }
    onChangeDisableEstablishmentSelect(true);
    setCheckedUser(true);
    return usersMatch;
  };
  const onCreateSimilarContractor = async (method: 'UPDATE' | 'CREATE') => {
    //@ts-ignore
    const formValues: FormValues = getValues();
    const formatedBirthday = new Date(formValues?.birthday);
    formatedBirthday.setHours(0, 0, 0);

    if (similarUserSelected) {
      // get contractorEmail or user email
      const email = checkEmail(similarUserSelected?.email)
        ? similarUserSelected?.email
        : checkEmail(similarUserSelected?.user?.email)
          ? similarUserSelected?.user?.email
          : undefined;
      formValues.email = email ? email : getValues()?.email;
      formValues.firstName = similarUserSelected?.firstName;
      formValues.lastName = similarUserSelected?.lastName;
      formValues.birthday = similarUserSelected?.birthday
        ? similarUserSelected?.birthday
        : formatedBirthday;
    }
    onSubmit(formValues, true, method);
  };
  const onCreateContractor = async (data: IContractorCreateParams) => {
    try {
      await contractorCreateOne(data);
      showMessage(
        'success',
        `La validation de votre intervenant ${data?.contractor?.firstName} ${data?.contractor?.lastName} est en cours de traitement. Vous allez recevoir une notification dans votre espace connecteed dès que ce sera fait.`
      );
      queryClient.refetchQueries({ queryKey: ['contractors'] });
      history.goBack();
    } catch (e) {
      //
    }
    //
  };
  const onSubmit = async (
    formValues: FormValues,
    ignoreUserMatch?: Boolean,
    method?: 'UPDATE' | 'CREATE'
  ) => {
    if (
      socialStatus === EContractorSocialStatus.EMPLOYEE ||
      socialStatus === EContractorSocialStatus.MINORITY_MANAGER
    ) {
      if (!dpaeFile) {
        setError('dpae', { message: 'Ce champs est requis' });
        return;
      }
    }
    setLoading(true);
    const formatedBirthday = setBirthdayDateToZeroHours(formValues?.birthday);
    try {
      const usersMatch = await checkExistingUser(
        formValues.firstName,
        formValues.lastName,
        formatedBirthday,
        formValues.email, //email principal
        formValues?.contractor?.tmpData?.email //email principal
      );
      if (
        ignoreUserMatch === true ||
        (usersMatch?.users?.length === 0 &&
          usersMatch?.contractors?.length === 0)
      ) {
        let attachment;
        if (
          (method !== 'UPDATE' &&
            socialStatus === EContractorSocialStatus.EMPLOYEE) ||
          socialStatus === EContractorSocialStatus.MINORITY_MANAGER
        ) {
          // UPLLOAD DPAE
          const s3Response = await uploadFile({
            file: dpaeFile as File,
            fileType: EFileType.TEMPORARY,
            establishment: establishment?.uuid || '',
            actionType: 'upload',
          });

          attachment = {
            //@ts-ignore
            fileLocation: s3Response.fileLocation,
            //@ts-ignore
            fileName: s3Response.fileName,
            //@ts-ignore
            eTag: s3Response.eTag,
          };
        }

        const submitData: IContractorCreateParams = {
          establishment: establishment?.uuid as string,
          email: checkEmail(formValues?.user?.email)
            ? formValues?.user?.email
            : undefined,
          jobTitle: formValues?.jobTitle,
          phone: formValues?.phone,
          contractor: {
            civility: Number(formValues?.civility),
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            birthday: formatedBirthday,
            jobTitle: formValues?.jobTitle,
            phone: formValues?.phone,
            sendAppInvitation: sendAppInvitation,
            tmpData: {
              email: similarUserSelected
                ? formValues.email
                : checkEmail(formValues?.user?.email)
                  ? formValues?.user?.email
                  : formValues?.contractor?.tmpData?.email,
              dpaeAttachment: attachment,
            },
            socialStatus: socialStatus,
            email: checkEmail(formValues?.email)
              ? formValues?.email
              : undefined,
          },
          contact: localStorage?.cognitoUserId,
          contactIsContractor: false,
        };
        if (method === 'UPDATE') {
          const updateContractorResponse = await contractorUpdateOne({
            uuid: usersMatch?.contractors?.[0]?.uuid,
            scopedData: [
              {
                contact: localStorage.cognitoUserId,
                establishment: establishment?.uuid as string,
                jobTitle: formValues?.jobTitle,
                phone: formValues?.phone,
                socialStatus: socialStatus,
              },
            ],
          });
          if (
            socialStatus === EContractorSocialStatus.EMPLOYEE ||
            socialStatus === EContractorSocialStatus.MINORITY_MANAGER
          ) {
            // UPLOAD DPAE
            const s3Response: any = await uploadFile({
              file: dpaeFile as File,
              fileType: EFileType.TEMPORARY,
              establishment: establishment?.uuid || '',
              actionType: 'upload',
            });

            attachment = {
              fileLocation: s3Response.fileLocation,
              fileName: s3Response.fileName,
              eTag: s3Response.eTag,
            };
            await dpaeUpdateOne({
              //@ts-ignore
              uuid: updateContractorResponse.scopedData.find(
                (scoppedData: any) =>
                  scoppedData.establishment === establishment?.uuid
              ).dpae,
              dpae: {
                attachment: attachment,
              },
            });
          }
          showMessage('success', 'Votre intervenant à été crée');
          queryClient.refetchQueries({ queryKey: ['contractors'] });
          history.goBack();
        } else {
          await onCreateContractor(submitData);
          queryClient.refetchQueries({ queryKey: ['contractors'] });
        }
      }
    } catch (err) {
      //console.log('err', err);
    }
    setLoading(false);
  };

  const handleChangeDpae = (files: File[]) => {
    setDpaeFile(files[0]);
    clearErrors('dpae');
  };
  const isEditable = !notEditable;
  React.useEffect(() => {
    if (
      socialStatus === EContractorSocialStatus.SUBCONTRACTOR ||
      socialStatus === EContractorSocialStatus.MAJORITY_MANAGER ||
      socialStatus === EContractorSocialStatus.COMPANY_MANAGER
    ) {
      setDpaeFile(undefined);
    }
  }, [socialStatus]);
  return (
    //@ts-ignore
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid cols={12} gap="30px">
        <Cell x-span={grid12}>
          <Text>
            Votre intervenant doit appartenir à l'établissement renseigné. Si ce
            n’est pas le cas, merci de{' '}
            <Link fontSize={14} onClick={showContactUsModal}>
              nous contacter
            </Link>{' '}
            rapidement.
          </Text>
        </Cell>
        <Cell x-span={grid12}>
          <Box>
            <FormControl
              required
              label={'Civilité'}
              errorMessage={errors?.civility?.message}
            >
              <>
                <Radio
                  disabled={isLink}
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="1"
                >
                  Monsieur
                </Radio>
                <Radio
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="2"
                >
                  Madame
                </Radio>
              </>
            </FormControl>
          </Box>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            required
            label={'Prénom'}
            errorMessage={errors?.firstName?.message}
          >
            <Input
              isDisabled={!isEditable || checkedUser}
              isFullWidth
              {...register('firstName', { required: 'Ce champ est requis' })}
              type="text"
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            required
            label={'Nom'}
            errorMessage={errors?.lastName?.message}
          >
            <Input
              isDisabled={!isEditable || checkedUser}
              isFullWidth
              {...register('lastName', { required: 'Ce champ est requis' })}
              type="text"
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            required
            label={'Date de naissance'}
            errorMessage={errors?.birthday?.message}
          >
            <DatePickerControlled
              disabled={!isEditable || checkedUser}
              control={control}
              valueName="selected"
              rules={{
                required: 'Ce champs est requis',
                validate: (value: any) => {
                  let age = differenceInYears(new Date(), value);
                  if (age < 18) {
                    return "L'intervenant doit etre majeur";
                  }
                  if (age >= 99) {
                    return 'Ne peut dépasser 99';
                  }
                  return undefined;
                },
              }}
              name="birthday"
            />
          </FormControl>
        </Cell>
      </Grid>
      {!isLink && similarUsers?.contractors?.length === 1 && (
        <Box width={1 / 1} mb={20} mt={-30}>
          <FormInfo variantColor="warning">
            <Text color={Theme.colors.warning.default}>
              Cet intervenant existe déjà, mais pour un autre de vos
              établissements de cette entreprise.
            </Text>
          </FormInfo>
        </Box>
      )}
      {!isLink && (
        <Grid cols={12} gap="30px">
          <Cell x-span={grid4}>
            <FormControl
              required
              label={"Fonction liée à l'établissement"}
              errorMessage={errors?.jobTitle?.message}
            >
              <Input
                isDisabled={!isEditable}
                isFullWidth
                {...register('jobTitle', { required: 'Ce champs est requis' })}
                type="text"
              />
            </FormControl>
          </Cell>
          <Cell x-span={grid4}>
            <FormControl label={"Téléphone lié à l'établissement"}>
              <PhoneInputControlled
                control={control}
                disabled={!isEditable}
                rules={{
                  validate: (value: string) => {
                    return validateFormPhoneFormatFR(value);
                  },
                }}
                isFullWidth
                name="phone"
              />
            </FormControl>
          </Cell>

          <Cell x-span={grid4}>
            <FormControl
              label={
                <Flex mb={-18} p={0}>
                  <Text fontSize={11} mb={-20} p={0}>
                    Email lié à l'établissement
                  </Text>
                  <a data-for="main" data-tip="" data-iscapture="true">
                    <InfoIconBlue
                      fontSize={25}
                      style={{ marginTop: '-7px', marginLeft: '5px' }}
                    />
                  </a>
                  <ReactTooltip
                    id="main"
                    place={'top'}
                    variant={'dark'}
                    float={true}
                    style={{ backgroundColor: Theme.colors.primary.default }}
                  >
                    <Text color="white" fontSize={11}>
                      A renseigner si votre intervenant dispose d'un email
                      professionnel dédié à sa mission
                    </Text>
                  </ReactTooltip>
                </Flex>
              }
            >
              <Input
                isDisabled={!isEditable}
                isFullWidth
                {...register('email')}
                type="email"
              />
            </FormControl>
          </Cell>
          <Cell x-span={grid4}>
            <FormControl
              required
              label={"Statut social pour l'établissement renseigné"}
              errorMessage={errors?.socialStatus?.message}
            >
              <StaticSelectControlled
                isDisabled={!isEditable}
                control={control}
                rules={{ required: 'Ce champs est requis' }}
                name="socialStatus"
                options={CONTRACTOR_SOCIAL_STATUS_SELECT}
              />
            </FormControl>
          </Cell>
          {socialStatus &&
            socialStatus !== EContractorSocialStatus.SUBCONTRACTOR &&
            socialStatus !== EContractorSocialStatus.COMPANY_MANAGER &&
            socialStatus !== EContractorSocialStatus?.MAJORITY_MANAGER && (
              <Cell x-span={grid4}>
                <FormControl
                  required
                  label="DPAE POUR CET ÉTABLISSEMENT "
                  errorMessage={errors?.dpae?.message}
                >
                  <Box
                    borderRadius="8px"
                    width={1 / 1}
                    border={`1px ${
                      dpae?.status === EDpaeStatus.TO_BE_VALIDATED
                        ? 'solid orange'
                        : 'dashed black'
                    }`}
                    height="50px"
                    mt={0}
                  >
                    <Flex alignContent="center" alignItems="center">
                      <Box width={8 / 10}>
                        {dpaeFile ? (
                          <FileInput
                            isDisabled={!isEditable}
                            //name="dpae"
                            ml={20}
                            variant="link"
                            required
                            label=""
                            accept=".pdf"
                            subLabel=""
                            onSelect={handleChangeDpae}
                          >
                            <Link ml={20} mt={15}>
                              {dpaeFile.name}
                            </Link>
                          </FileInput>
                        ) : dpae?.attachment?.fileName ? (
                          <Link
                            ml={20}
                            color={
                              dpae?.status === EDpaeStatus.TO_BE_VALIDATED
                                ? 'orange'
                                : Theme.colors?.primary.default
                            }
                            onClick={() =>
                              showDisplayPdfModal({
                                //@ts-ignore
                                fileLocation: dpae?.attachment?.fileLocation,
                                //@ts-ignore
                                fileName: `${dpae?.attachment?.fileName}.pdf`,
                              })
                            }
                            mt={15}
                          >
                            {dpae?.attachment?.fileName}
                          </Link>
                        ) : (
                          <FileInput
                            isDisabled={!isEditable}
                            //name="dpae"
                            ml={20}
                            variant="link"
                            required
                            label=""
                            accept=".pdf"
                            subLabel=""
                            onSelect={handleChangeDpae}
                          >
                            <Link mt={10}>Ajouter une DPAE</Link>
                          </FileInput>
                        )}
                      </Box>
                      <Box width={2 / 10}>
                        <FileInput
                          isDisabled={!isEditable}
                          //name="dpae"
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link mt={10} iconLeft={<DownloadIcon />}></Link>
                        </FileInput>
                      </Box>
                    </Flex>
                  </Box>
                </FormControl>
              </Cell>
            )}
          {/* TODO WAIT APP TALENT READY !! */}
          {/* <Cell x-span={{ xs: '12' }}>
            <Controller
              disabled={!isEditable}
              control={control}
              as={CheckBox}
              onClick={() =>
                isEditable && setSendAppInvitation(!sendAppInvitation)
              }
              checked={sendAppInvitation as boolean}
              name="sendAppInvitation"
            >
              <Box>
                <Text mr={20}>
                  Je souhaite créer un compte sur{' '}
                  <a
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    href={APP_MOBILE_TALENT_LINK}
                    target={'blank'}
                  >
                    l’application mobile talents
                  </a>{' '}
                  pour cet intervenant.
                </Text>
              </Box>
            </Controller>
            <Text fontSize={'12px'} mt={20} mb={20}>
              En cochant cette case, vous autorisez freelance.com à lui
              communiquer par email un lien vers une application mobile qui lui
              permettra de saisir ses CRA de manière autonome. Il ne vous
              restera plus qu'à valider les informations remontées par votre
              intervenant depuis votre espace connecteed. Notez bien que ce mail
              servira d’identifiant à votre intervenant afin d’accéder à son
              compte. Cette information ne pourra donc pas être modifiée par la
              suite.
            </Text>
          </Cell> */}

          {sendAppInvitation && (
            <Cell x-span={grid4}>
              <FormControl
                label="Email compte"
                //@ts-ignore
                errorMessage={errors?.user?.email?.message}
              >
                <Input
                  isDisabled={isLink}
                  isFullWidth
                  {...register('contractor.tmpData.email', {
                    required: 'Ce champs est requis',
                  })}
                  type="email"
                />
              </FormControl>
            </Cell>
          )}
        </Grid>
      )}

      {!checkedUser && (
        <Flex>
          <Button
            key="button"
            type="button"
            onClick={() => history.goBack()}
            variant="ghost"
            mr={20}
          >
            Annuler
          </Button>
          <Button
            isDisabled={!isEditable}
            key="submit"
            type="submit"
            isLoading={loading}
          >
            Enregistrer
          </Button>
        </Flex>
      )}
      {isLink && (
        <Grid cols={12} gap={'20px'}>
          <Cell x-span={{ xs: '12' }}>
            <FormInfo variantColor="warning">
              <Text color={Theme.colors.warning.default}>
                Cet intervenant est déjà enregistré pour cet établissement.
              </Text>
            </FormInfo>
          </Cell>
        </Grid>
      )}

      {isLink &&
        similarUsers?.contractors?.map((user: any) => {
          return (
            <Cell x-span={grid6} mt={20}>
              <ContractorCard
                user={buildContractorByScopedData(
                  user,
                  getScopedDataBySiret(
                    user?.scopedData,
                    //@ts-ignore
                    establishment.siret as string
                  )
                )}
                isSelected={user.uuid === similarUserSelected?.uuid}
              />
            </Cell>
          );
        })}
      {!isLink &&
      similarUsers?.users?.length !== 0 &&
      (similarUsers?.users?.length > 0 ||
        similarUsers?.contractors?.length > 0) ? (
        <Grid cols={12} gap={'20px'}>
          <Cell x-span={{ xs: '12' }}>
            <FormInfo variantColor="warning">
              <Text color={Theme.colors.warning.default}>
                Plusieurs correspondances utilisateurs ont été détectées. Merci
                de sélectionner le doublon éventuels. Si aucun des utilisateurs
                ne correspond cliquez directement sur créer.
              </Text>
            </FormInfo>
          </Cell>
          {similarUsers?.contractors?.length > 1 &&
            similarUsers?.contractors?.map((user: any) => {
              return (
                <Cell x-span={grid6}>
                  <ContractorCard
                    height={'160px'}
                    user={user}
                    onChangeSelected={() =>
                      user.uuid === similarUserSelected?.uuid
                        ? setSimilarUserSelected(undefined)
                        : setSimilarUserSelected(user)
                    }
                    isSelectable
                    ignoreScopedData
                    isSelected={user.uuid === similarUserSelected?.uuid}
                  />
                </Cell>
              );
            })}
          {similarUsers?.users?.map((user: any) => {
            return (
              <Cell x-span={grid6}>
                <UserCard
                  height={'160px'}
                  user={user}
                  onChangeSelected={() =>
                    user.cognitoUserId === similarUserSelected?.cognitoUserId
                      ? setSimilarUserSelected(undefined)
                      : setSimilarUserSelected(user)
                  }
                  isSelectable
                  isSelected={
                    user.cognitoUserId === similarUserSelected?.cognitoUserId
                  }
                />
              </Cell>
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
      {checkedUser && (
        <Cell x-span={grid12} mt={20}>
          <Flex>
            <Button
              key="button"
              type="button"
              onClick={() => history.goBack()}
              variant="ghost"
              mr={20}
            >
              Annuler
            </Button>
            <Button
              isDisabled={!isEditable || isLink}
              key="button"
              type="button"
              onClick={() =>
                onCreateSimilarContractor(
                  !isLink && similarUsers?.contractors?.length === 1
                    ? 'UPDATE'
                    : 'CREATE'
                )
              }
              isLoading={loading}
            >
              {similarUserSelected ? 'Valider' : 'Créer'}
            </Button>
          </Flex>
        </Cell>
      )}
    </form>
  );
};
