import { logout } from '@commons';
import { useMe } from '@commons';
import { DoneIcon } from 'components/icons';
import { Box, Button, Flex, Spinner, Text } from 'components/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

export const OnBoardFinish = () => {
  const Theme = useTheme();
  const history = useHistory();
  const { me, status } = useMe();

  const isLoading = status === 'pending';
  if (isLoading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex
      top={0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Box>
        <Flex alignItems="center" justifyContent="center">
          <DoneIcon width="289px" height="297px" />
        </Flex>
        <Box mt={'45px'}>
          <Text
            variant="h1"
            color={Theme.colors.primary.default}
            textAlign="center"
          >
            Votre embarquement a bien été finalisé !
          </Text>
          <Text variant="p" textAlign="center">
            {me && me.onboardingStep?.ableToAccessSite
              ? `Rendez-vous sur votre espace personnel pour ajouter vos intervenants salariés et créer votre première mission.`
              : `Nous reviendrons vers vous lorsqu'un conseiller aura validé vos informations.`}
          </Text>
          <Flex alignItems="center" justifyContent="center">
            <Button
              mt={'45px'}
              onClick={async () => {
                if (me && me.onboardingStep?.ableToAccessSite) {
                  history.push('/dashboard');
                } else {
                  logout();
                  history.push('/login');
                }
              }}
            >
              {me && me.onboardingStep?.ableToAccessSite
                ? `Démarrer`
                : `J'ai bien compris`}
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};
export default OnBoardFinish;
