import {
  ADDITIONAL_ACTIVITY_TYPES_FR,
  getAdditionalActivitiesTotalAmount,
  getEstablishmentName,
  getFullName,
} from '@commons';
import {
  ECraStatus,
  EInvoiceStatus,
  EadditionalActivityStatus,
  IActivityReportData,
  IJoinedAdditionalActivity,
} from '@freelancelabs/teoreme-commons';
import { EyeIcon } from 'components/icons';
import { ALink, Box, Flex, Link, Status, Text } from 'components/ui';
import { add, getTime } from 'date-fns';
import { getAdditionnalActivityIcon, getDateByStatus } from 'helpers';
import { Theme } from 'styles';
// TODO check
const STATUS_PENDING_FILTER_OBJECT = {
  $or: [
    {
      $and: [
        { 'standByDuties.0': { $exists: true } },
        {
          'standByDuties.status': {
            $in: [
              EadditionalActivityStatus.TO_BE_FILLED,
              EadditionalActivityStatus.TO_BE_SUBMITTED,
              EadditionalActivityStatus.TO_BE_VALIDATED,
              EadditionalActivityStatus.REJECTED,
            ],
          },
        },
      ],
    },
    {
      $and: [
        { 'expenses.0': { $exists: true } },
        {
          'expenses.status': {
            $in: [
              EadditionalActivityStatus.TO_BE_FILLED,
              EadditionalActivityStatus.TO_BE_SUBMITTED,
              EadditionalActivityStatus.TO_BE_VALIDATED,
              EadditionalActivityStatus.REJECTED,
            ],
          },
        },
      ],
    },
    {
      'cra.state': {
        $in: [
          ECraStatus.TO_BE_FILLED,
          ECraStatus.TO_BE_SUBMITTED,
          ECraStatus.TO_BE_VALIDATED,
          ECraStatus.REJECTED,
        ],
      },
    },
  ],
};
const STATUS_VALIDATED_FILTER_OBJECT = {
  $and: [
    {
      $or: [
        {
          $and: [
            { 'standByDuties.0': { $exists: true } },
            {
              standByDuties: {
                $not: {
                  $elemMatch: {
                    status: {
                      $in: [
                        EadditionalActivityStatus.TO_BE_FILLED,
                        EadditionalActivityStatus.TO_BE_SUBMITTED,
                        EadditionalActivityStatus.TO_BE_VALIDATED,
                        EadditionalActivityStatus.REJECTED,
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        {
          'standByDuties.0': { $exists: false },
        },
      ],
    },
    {
      $or: [
        {
          $and: [
            { 'expenses.0': { $exists: true } },
            {
              expenses: {
                $not: {
                  $elemMatch: {
                    status: {
                      $in: [
                        EadditionalActivityStatus.TO_BE_FILLED,
                        EadditionalActivityStatus.TO_BE_SUBMITTED,
                        EadditionalActivityStatus.TO_BE_VALIDATED,
                        EadditionalActivityStatus.REJECTED,
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        {
          'expenses.0': { $exists: false },
        },
      ],
    },
  ],
};
export const getDefaultFilterObject = (
  filter: 'pending' | 'validated',
  params?: {
    debouncedFilterQuery: string;
    selectedStatus?: EadditionalActivityStatus | ECraStatus | 'ALL';
    startDate: Date | null;
    endDate: Date | null;
    selectedCustomer?: string;
    isOpen?: boolean;
  }
) => {
  const filterObject: any = {};

  const queryFilter = [
    {
      'contractor.firstName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'cra.refCra': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.businessName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.tradeName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.signBoard1': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.signBoard2': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.signBoard3': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (params) {
    if (params?.isOpen) {
      filterObject['cra.isOpen'] = true;
    }
    if (params?.selectedCustomer) {
      filterObject['estCustomer.uuid'] = params?.selectedCustomer;
    }
    if (params?.startDate !== null && params?.endDate !== null) {
      if (filterObject?.$and) {
        filterObject?.$and?.push({
          $expr: {
            $gte: [{ $toLong: '$month' }, getTime(params?.startDate)],
          },
        });
        filterObject?.$and?.push({
          $expr: {
            $lte: [
              { $toLong: '$month' },
              getTime(add(params?.endDate, { months: 1 })),
            ],
          },
        });
      } else {
        filterObject.$and = [
          {
            $expr: {
              $gte: [{ $toLong: '$month' }, getTime(params?.startDate)],
            },
          },
          {
            $expr: {
              $lte: [
                { $toLong: '$month' },
                getTime(add(params?.endDate, { months: 1 })),
              ],
            },
          },
        ];
      }

      if (params?.debouncedFilterQuery?.length > 0) {
        //@ts-ignore
        filterObject.$and.push({ $or: queryFilter });
      }
    } else {
      if (params?.debouncedFilterQuery?.length > 0) {
        //@ts-ignore
        filterObject.$or = queryFilter;
      }
    }
  }

  if (params?.selectedStatus && params?.selectedStatus !== 'ALL') {
    if (filterObject?.$and) {
      filterObject['cra.state'] = {
        $in: [params?.selectedStatus],
      };
      filterObject?.$and?.push({
        $or: [
          {
            $and: [
              { 'standByDuties.0': { $exists: true } },
              {
                'standByDuties.status': {
                  $in: [params?.selectedStatus],
                },
              },
            ],
          },
          {
            'standByDuties.0': { $exists: false },
          },
        ],
      });
      filterObject?.$and?.push({
        $or: [
          {
            $and: [
              { 'expenses.0': { $exists: true } },
              {
                'expenses.status': {
                  $in: [params?.selectedStatus],
                },
              },
            ],
          },
          {
            'expenses.0': { $exists: false },
          },
        ],
      });
    } else {
      filterObject.$or = [
        { 'cra.state': params?.selectedStatus },
        { 'standByDuties.status': params?.selectedStatus },
        { 'expenses.status': params?.selectedStatus },
      ];
    }
  } else {
    switch (filter) {
      case 'pending':
        if (filterObject?.$and) {
          filterObject?.$and?.push(STATUS_PENDING_FILTER_OBJECT);
        } else {
          filterObject.$and = [STATUS_PENDING_FILTER_OBJECT];
        }

        break;
      case 'validated':
        filterObject['cra.state'] = {
          $in: [ECraStatus?.VALIDATED, ECraStatus?.ARCHIVED],
        };
        if (filterObject?.$and) {
          STATUS_VALIDATED_FILTER_OBJECT?.$and?.forEach(element =>
            filterObject?.$and?.push(element)
          );
        } else {
          filterObject.$and = STATUS_VALIDATED_FILTER_OBJECT?.$and;
        }
    }
  }
  return filterObject;
};

export const dispathActivitiesByMission = (data: IActivityReportData[]) => {
  const activitiesByMission: any[] = [];
  data?.forEach((d: IActivityReportData) => {
    if (!activitiesByMission?.find((m: any) => m?.mission === d?.mission)) {
      activitiesByMission?.push({
        mission: d?.mission,
        missionNumber: d?.missionNumber,
        missionStatus: d?.missionStatus,
        missionStructure: d?.missionStructure,
        estCustomer: d?.estCustomer,
        estProvider: d?.estProvider,
        activities: [],
      });
    }
  });
  data?.forEach((d: IActivityReportData) => {
    const findex = activitiesByMission?.findIndex(
      (m: any) => m?.mission === d?.mission
    );
    activitiesByMission[findex]?.activities?.push(d);
  });
  return activitiesByMission;
};
const getStatusData = (
  status: EadditionalActivityStatus
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
} => {
  switch (status) {
    case EadditionalActivityStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case EadditionalActivityStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case EadditionalActivityStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case EadditionalActivityStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validé`,
        textColor: Theme.colors.success.default,
      };
    case EadditionalActivityStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusé`,
        textColor: Theme.colors.error.default,
      };
    case EadditionalActivityStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivé`,
        textColor: Theme.colors.grey.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};
const ACTIVE_STATUS = [
  EadditionalActivityStatus.REJECTED,
  EadditionalActivityStatus.TO_BE_FILLED,
  EadditionalActivityStatus.TO_BE_SUBMITTED,
  EadditionalActivityStatus.TO_BE_VALIDATED,
];
const INACTIVE_STATUS = [
  EadditionalActivityStatus.VALIDATED,
  EadditionalActivityStatus.ARCHIVED,
  EInvoiceStatus.PAID,
];
const NOT_HAVE_BUDGET_QUERY = {
  $expr: {
    $gte: [
      '$mission.billing.consumedPurchaseBudget',
      '$mission.billing.contractorRate',
    ],
  },
};
const HAVE_BUDGET_QUERY = {
  $expr: {
    $lt: [
      '$mission.billing.consumedPurchaseBudget',
      '$mission.billing.contractorRate',
    ],
  },
};
const NOT_HAVE_EXPENSES_BUDGET_QUERY = {
  $or: [
    // budget frais épuisé
    {
      'mission.expensesConfig.isEnabled': true,
      'mission.expensesConfig.purchaseBudget': { $exists: true },
      $expr: {
        $gte: [
          '$mission.expensesConfig.consumedPurchaseBudget',
          '$mission.expensesConfig.purchaseBudget',
        ],
      },
    },
    // frais activé, mais pas de budget max
    {
      'mission.expensesConfig.isEnabled': true,
      'mission.expensesConfig.purchaseBudget': { $exists: false },
    },
    // frais désactivé
    { 'mission.expensesConfig.isEnabled': false },
  ],
};
const HAVE_EXPENSES_BUDGET_QUERY = {
  'mission.expensesConfig.isEnabled': true,
  'mission.expensesConfig.purchaseBudget': { $exists: true },
  $expr: {
    $lt: [
      '$mission.expensesConfig.consumedPurchaseBudget',
      '$mission.expensesConfig.purchaseBudget',
    ],
  },
};

const FIND_ONE_INACTIVE_PRESTATION_QUERY_OR_DOSENT_EXIST = [
  {
    $or: [
      {
        milestones: {
          $not: {
            $elemMatch: {
              $or: [
                {
                  status: {
                    $in: INACTIVE_STATUS,
                  },
                  'providerInvoice.status': {
                    $nin: INACTIVE_STATUS,
                  },
                },
                {
                  status: {
                    $nin: INACTIVE_STATUS,
                  },
                },
              ],
            },
          },
        },
      },
      { 'milestones.0': { $exists: false } },
    ],
  },
  {
    $or: [
      {
        expenses: {
          $not: {
            $elemMatch: {
              $or: [
                {
                  status: {
                    $in: INACTIVE_STATUS,
                  },
                  'providerInvoice.status': {
                    $nin: INACTIVE_STATUS,
                  },
                },
                {
                  status: {
                    $nin: INACTIVE_STATUS,
                  },
                },
              ],
            },
          },
        },
      },
      { 'expenses.0': { $exists: false } },
    ],
  },
];
const ACTIVE_PRESTATION_QUERY_OR_DOSENT_EXIST = [
  {
    $or: [
      {
        'milestones.status': { $in: ACTIVE_STATUS },
      },
      {
        'milestones.status': { $nin: ACTIVE_STATUS },
        $or: [
          {
            'milestones.providerInvoice.status': {
              $in: ACTIVE_STATUS,
            },
          },
          { 'milestones.providerInvoice': { $exists: false } },
        ],
      },
      { 'milestones.0': { $exists: false } },
    ],
  },
  {
    $or: [
      {
        'expenses.status': { $in: ACTIVE_STATUS },
      },
      {
        'expenses.status': { $nin: ACTIVE_STATUS },
        $or: [
          {
            'expenses.providerInvoice.status': {
              $in: ACTIVE_STATUS,
            },
          },
          { 'expenses.providerInvoice': { $exists: false } },
        ],
      },
      { 'expenses.0': { $exists: false } },
    ],
  },
];

export const PENDING_REPORT_QUERY = {
  $or: [
    {
      // MISSION HAVE BUDGET AND INVOICE OR ACTIVTY IS DONE
      //$and: [HAVE_BUDGET_QUERY],
      $or: [HAVE_BUDGET_QUERY, HAVE_EXPENSES_BUDGET_QUERY],
    },
    // OR
    {
      // MISSION DOESN'T HAVE BUDGET AND INVOICE OR ACTIVTY NOT DONE
      $and: [
        NOT_HAVE_BUDGET_QUERY,
        {
          $or: [
            ...ACTIVE_PRESTATION_QUERY_OR_DOSENT_EXIST,
            HAVE_EXPENSES_BUDGET_QUERY,
          ],
        },
      ],
    },
  ],
};
export const TERMINATED_REPORT_QUERY = {
  $and: [
    {
      // MISSION DOESN'T HAVE BUDGET AND INVOICE AND ACTIVTY IS DONE
      $and: [
        NOT_HAVE_BUDGET_QUERY,
        {
          $and: [
            ...FIND_ONE_INACTIVE_PRESTATION_QUERY_OR_DOSENT_EXIST,
            NOT_HAVE_EXPENSES_BUDGET_QUERY,
          ],
        },
      ],
    },
  ],
};
export const getFilterObjectMileStoneAndFees = (
  filter: string,
  params?: {
    debouncedFilterQuery: string;
    selectedStatus?: EadditionalActivityStatus | ECraStatus | 'ALL';
    startDate: Date | null;
    endDate: Date | null;
    selectedCustomer?: string;
    selectedProvider?: string;
    selectedContractor?: string;
    isOpen?: boolean;
  }
) => {
  let filterObject: any = {};
  if (params) {
    const queryFilter = [
      {
        'contractor.firstName': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'contractor.lastName': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estCustomer.businessName': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estCustomer.tradeName': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estCustomer.signBoard1': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estCustomer.signBoard2': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estCustomer.signBoard3': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.businessName': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.tradeName': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.signBoard1': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.signBoard2': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.signBoard3': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'mission.displayReference': {
          $regex: params?.debouncedFilterQuery,
          $options: 'i',
        },
      },
    ];

    if (filter === 'pending') {
      filterObject = { $and: [PENDING_REPORT_QUERY] };
    }
    if (filter === 'terminated') {
      filterObject = { $and: [TERMINATED_REPORT_QUERY] };
    }
    if (params?.selectedCustomer) {
      filterObject['estCustomer.uuid'] = params?.selectedCustomer;
    }
    if (params?.selectedProvider) {
      filterObject['estProvider.uuid'] = params?.selectedProvider;
    }
    if (params?.selectedContractor) {
      filterObject['contractor.uuid'] = params?.selectedContractor;
    }
    if (params?.debouncedFilterQuery?.length > 0) {
      // //@ts-ignore
      // if (filterObject?.$and[2]?.$or) {
      //   filterObject.$and[2].$or = queryFilter;
      // } else {
      filterObject.$and?.push({ $or: queryFilter });
      //}
    } else {
      // if (filterObject?.$and[2]?.$or) {
      //   filterObject.$and?.splice(2, 1);
      // }
    }
  }
  return filterObject;
};

export const columns = [
  {
    Header: 'Réf. mission',
    accessor: 'mission.reference',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      return (
        <>
          <Text variant="p">
            {additionalActivities?.mission?.displayReference}
          </Text>
        </>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      const Icon = getAdditionnalActivityIcon(additionalActivities);
      return (
        <Flex>
          <Box>
            {' '}
            {Icon ? (
              <Icon
                fontSize={20}
                style={{ marginRight: '5px' }}
                fill={Theme?.colors?.primary?.default}
              />
            ) : (
              '??'
            )}
          </Box>
          <Text variant="p">
            {ADDITIONAL_ACTIVITY_TYPES_FR[additionalActivities?.type]}
          </Text>
        </Flex>
      );
    },
  },
  {
    Header: 'Déclaré le',
    accessor: 'statusChangedAt',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      const date = getDateByStatus(additionalActivities);
      return <Text>{date}</Text>;
    },
  },
  {
    Header: 'Statut',
    accessor: 'status',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      const statusData = getStatusData(additionalActivities?.status);
      return (
        <Status variantColor={statusData?.variantColor}>
          {statusData?.statusText}
        </Status>
      );
    },
  },
  {
    Header: 'Client',
    accessor: 'estCustomer.businessName',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      return (
        <>
          <Text variant="p">
            {getEstablishmentName(additionalActivities?.estCustomer)}
          </Text>
        </>
      );
    },
  },
  {
    Header: 'Fournisseur',
    accessor: 'estProvider.businessName',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      return (
        <>
          <Text variant="p">
            {getEstablishmentName(additionalActivities?.estProvider)}
          </Text>
        </>
      );
    },
  },
  {
    Header: 'Intervenant',
    accessor: 'contractor',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      return (
        <>
          <Text variant="p">
            {getFullName(additionalActivities?.contractor)}
          </Text>
        </>
      );
    },
  },
  {
    Header: 'Montant',
    accessor: 'milestoneAmount',
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      const amount = getAdditionalActivitiesTotalAmount(
        [additionalActivities],
        additionalActivities?.type,
        'PROVIDER'
      );
      return (
        <>
          <Text variant="p">{amount} €</Text>
        </>
      );
    },
  },
  {
    Header: 'Actions',
    accessor: false,
    Cell: (item: any) => {
      const additionalActivities: IJoinedAdditionalActivity =
        item?.row?.original;
      return (
        <Flex>
          <ALink
            href={`/invoices/flat-rate/activities/${additionalActivities?.mission?.reference}`}
          >
            <Link mr={'10px'} iconLeft={<EyeIcon />}>
              Consulter
            </Link>
          </ALink>
        </Flex>
      );
    },
  },
];
