import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { boxProperties, BoxProps } from './Box';
import { lighten } from 'polished';
type ColorTextBoxProps = {
  children?: ReactNode;
  variantColor?: 'primary' | 'grey' | 'error' | 'success' | 'warning' | 'grey';
};
export const ColorTextBox = styled.span<BoxProps & ColorTextBoxProps>`
  display: box;
  align-items: center;
  font-size: 14px;
  padding: 5px;
  border-radius: 8px;
  font-weight: bolder;
  color: ${props =>
    props.theme.colors[props.variantColor || 'primary'].default};
  ${props =>
    props.variantColor === 'primary'
      ? css`
          color: ${props.theme.colors.primary.default};
          background-color: ${props.theme.colors.primary.blueLight};
        `
      : css`
          color: ${props.theme.colors[props.variantColor || 'primary'].default};
          background-color: ${lighten(
            0.3,
            props.theme.colors[props.variantColor || 'primary'].default
          )};
        `}
  ${boxProperties};
`;
