/* eslint-disable react/display-name */
import { cutLongText } from '@commons';
import { EInvoiceStatus, EInvoiceType } from '@freelancelabs/teoreme-commons';
import { DocIcon, EditIcon, EyeIcon } from 'components/icons';
import { Link, ALink, Box, CheckBox, Flex, Status, Text } from 'components/ui';
import { snakeCase } from 'lodash';
import React from 'react';
import { Theme } from 'styles/theme';
import { InvoiceStatusEnum } from './types';

const IndeterminateCheckbox: React.FC<React.PropsWithChildren<any>> = ({
  indeterminate,
  checked,
  onChange,
  title,
  ...rest
}) => {
  return (
    <CheckBox
      isIndeterminate={indeterminate}
      checked={checked}
      aria-label={title}
      {...rest}
      ml={10}
      onClick={() => {
        const event = {
          target: {
            checked: !checked,
          },
        };
        onChange(event);
      }}
    />
  );
};

const TrimCell: React.FC<React.PropsWithChildren<{ value: string }>> = ({
  value,
}) => <div title={value}>{cutLongText(value, 20)}</div>;

export const columns = [
  // Let's make a column for selection
  {
    id: 'selection',
    disableSortBy: true,

    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <Flex>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </Flex>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }: any) => {
      // console.log(row);
      const status = row.original.status;
      return status === EInvoiceStatus.TO_BE_SUBMITTED ? (
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      ) : null;
    },
  },
  {
    Header: 'Réf. mission',
    accessor: 'mission.displayReference',
    Cell: (item: any) => {
      const refMission = item?.row?.original?.mission?.displayReference;
      return (
        <Flex pl={15}>
          <DocIcon width={25} height={25} fill={Theme.colors.primary.default} />
          <Link
            mt={1}
            fontSize={14}
            //href={`/invoices/cra/${kebabCase(state)}/${refMission}`}
          >
            {refMission}
          </Link>
        </Flex>
      );
    },
  },
  {
    Header: 'Struct.',
    accessor: 'mission.sage.structure',
    Cell: (item: any) => {
      const structure = item?.row?.original?.mission?.sage?.structure;
      return <Box pl={15}>{structure}</Box>;
    },
  },
  {
    Header: 'Mois',
    accessor: 'month',
    Cell: (item: any) => {
      let month =
        item?.row?.original?.month || item?.row?.original?.normalizedDate;
      if (month) {
        const monthToDate = new Date(month);
        month = `${
          monthToDate.getUTCMonth() + 1
        } / ${monthToDate.getUTCFullYear()}`;
      }
      return <Box pl={15}>{month || 'N/A'}</Box>;
    },
  },
  {
    Header: 'Type',
    accessor: 'invoiceType',
    Cell: (item: any) => {
      if (item?.row?.original?.invoiceType === EInvoiceType?.PROVIDER) {
        return <TrimCell value={'Prestation standard'} />;
      }
      if (
        item?.row?.original?.invoiceType ===
        EInvoiceType?.PROVIDER_STAND_BY_DUTY
      ) {
        return <TrimCell value={'Prestation complémentaire'} />;
      }
      if (item?.row?.original?.invoiceType === EInvoiceType?.PROVIDER_EXPENSE) {
        return <TrimCell value={'Frais'} />;
      }
      if (
        item?.row?.original?.invoiceType === EInvoiceType?.PROVIDER_MILESTONE
      ) {
        return <TrimCell value={'Jalon'} />;
      }
      // TODO ?? ERROR SEARCH
      return <TrimCell value={item?.row?.original?.invoiceType} />;
    },
  },
  {
    Header: 'Client',
    accessor: 'estCustomer.businessName',
    Cell: ({ value }: any) => <TrimCell value={value} />,
  },
  {
    Header: 'Établissement',
    accessor: 'estProvider.businessName',
    Cell: ({ value }: any) => <TrimCell value={value} />,
  },
  {
    Header: 'Intervenant',
    accessor: 'contractor.lastName',
    Cell: ({
      value,
      row: {
        original: {
          contractor: { firstName },
        },
      },
    }: any) => <TrimCell value={`${value} ${firstName}`} />,
  },
  {
    Header: 'Montant TTC',
    accessor: 'totalWithTaxes',
    Cell: (item: any) => {
      const currency = item?.row?.original?.mission?.billing?.currency;
      const totalWithTaxes = item?.row?.original?.totalWithTaxes;
      if (totalWithTaxes) {
        return <Text>{totalWithTaxes.toFixed(2) + ` ${currency}`}</Text>;
      }
      return <Text>N/A</Text>;
    },
  },
  {
    Header: 'Statut',
    accessor: 'state',
    Cell: (item: any) => {
      const state = item?.row?.original?.status;
      const statusChangedAt = item?.row?.original?.statusChangedAt;
      let variantColor = 'grey';
      let text = '';
      switch (state) {
        case EInvoiceStatus.TO_BE_FILLED:
          variantColor = 'grey';
          text = 'A saisir';
          break;
        case EInvoiceStatus.TO_BE_SUBMITTED:
          variantColor = 'grey';
          text = 'A soumettre';
          break;
        case EInvoiceStatus.TO_BE_VALIDATED:
          variantColor = 'warning';
          text = `Transmise le :${
            statusChangedAt && statusChangedAt.toLocaleDateString()
          }`;
          break;
        case EInvoiceStatus.VALIDATED:
          variantColor = 'success';
          text = 'Validé';
          break;
        case EInvoiceStatus.ARCHIVED:
          variantColor = 'grey';
          text = 'Archivé';
          break;
        case EInvoiceStatus.REJECTED:
          variantColor = 'error';
          text = `Refusé le ${
            statusChangedAt && statusChangedAt.toLocaleDateString()
          }`;
          break;
        case EInvoiceStatus.CANCELLED:
          variantColor = 'error';
          text = `Annulé le ${
            statusChangedAt && statusChangedAt.toLocaleDateString()
          }`;
          break;
        case EInvoiceStatus.PAID:
          variantColor = 'success';
          text = `Payée ${
            item?.row?.original?.sage?.paymentDetails?.paymentDate
              ? '- Virement émis le : ' +
                item?.row?.original?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()
              : ''
          }`;
          break;
        default:
          variantColor = 'grey';
      }
      return (
        <Flex>
          <Status
            //@ts-ignore
            variantColor={variantColor}
          >
            {text}
          </Status>
        </Flex>
      );
    },
  },
  {
    disableSortBy: true,
    Header: 'Actions',
    accessor: 'actions',
    textAlign: 'right',
    Cell: (item: any) => {
      const invoiceStatus = item?.row?.original?.status;
      const uuid = item?.row?.original?.uuid;
      const creditNote = item?.row?.original?.creditNote;
      let buttonText;
      switch (invoiceStatus) {
        case EInvoiceStatus.ARCHIVED:
          buttonText = 'Voir le formulaire';
          break;
        case EInvoiceStatus.PROJECTED:
          buttonText = 'Voir le formulaire';
          break;
        case EInvoiceStatus.REJECTED:
          buttonText = 'Editer';
          break;
        case EInvoiceStatus.TO_BE_FILLED:
          buttonText = 'Saisir';
          break;
        case EInvoiceStatus.TO_BE_SUBMITTED:
          buttonText = 'Editer';
          break;
        case EInvoiceStatus.TO_BE_VALIDATED:
          buttonText = 'Voir le formulaire';
          break;
        case EInvoiceStatus.VALIDATED:
          buttonText = 'Voir le formulaire';
          break;
        case EInvoiceStatus.PAID:
          buttonText = 'Voir le formulaire';
          break;
        case EInvoiceStatus.CANCELLED:
          buttonText = "Voir l'avoir";
          break;
      }

      return (
        <Flex>
          {buttonText === 'Voir le formulaire' ? (
            <EyeIcon
              width={25}
              height={25}
              fill={Theme.colors.primary.default}
            />
          ) : (
            <EditIcon
              width={25}
              height={25}
              color={Theme.colors.primary.default}
            />
          )}
          <ALink
            data-cy="invoice-time-spent-goForm"
            mt={1}
            fontSize={14}
            href={
              invoiceStatus !== EInvoiceStatus?.CANCELLED
                ? `/invoices/time-spent/to-be-submitted/${uuid}`
                : `/invoices/time-spent/${snakeCase(
                    creditNote?.status
                  ).toLowerCase()}/${creditNote?.uuid}`
            }
          >
            {buttonText}
          </ALink>
        </Flex>
      );
    },
  },
];

type Settings = {
  columnsHidden: Array<string>;
};
//@ts-ignore
export const settingsByFilter: Record<InvoiceStatusEnum, Settings> = {
  [InvoiceStatusEnum.TO_BE_SUBMITTED]: {
    columnsHidden: ['state'],
  },
  [InvoiceStatusEnum.TO_BE_VALIDATED]: {
    columnsHidden: ['selection', 'state'],
  },
  [InvoiceStatusEnum.VALIDATED]: {
    columnsHidden: ['selection', 'state'],
  },
  [InvoiceStatusEnum.ARCHIVED]: {
    columnsHidden: ['selection', 'state'],
  },
  [InvoiceStatusEnum.CANCELLED]: {
    columnsHidden: ['selection', 'state'],
  },
  [InvoiceStatusEnum.PAID]: {
    columnsHidden: ['selection'],
  },
};
