/* eslint-disable react/display-name */
import { Box } from 'components/ui';
import Table from 'components/ui/Table';
import React from 'react';
import { useLogic } from './hooks';

type ContractListProps = {
  filterObject: any;
};
export const ContractList = ({ filterObject }: ContractListProps) => {
  const {
    status,
    isFetching,
    data,
    columns,
    setFetchInfo,
    pageIndex,
    columnsHidden,
    updateContractListStore,
  } = useLogic({ filterObject });
  React.useEffect(() => {
    updateContractListStore({ filterObject });
  }, [filterObject]);
  return (
    <Box width={1 / 1}>
      <Table
        onFetchData={setFetchInfo}
        controlledPageIndex={pageIndex}
        setPageIndex={pageIndex => updateContractListStore({ pageIndex })}
        setSelectedRowsId={(selectedRows: any) => {}}
        identifier="uuid"
        controlledHiddenColumns={columnsHidden}
        initialSortBy={[{ id: 'state', desc: true }]}
        columns={columns}
        data={data?.contracts || []}
        controlledPageCount={Math.ceil(data.totalCount / data.limit)}
        totalCount={data.totalCount}
        labelData="Contrats-cadre"
        isFetching={status === 'success' && isFetching}
        isLoading={status === 'pending'}
      />
    </Box>
  );
};

ContractList.displayName = 'ContractList';
// CRAList.whyDidYouRender = true;

export default ContractList;
