import { getEstablishmentName, getFullName, getHumanDate } from '@commons';
import { useMissionFindOne, useMissionFindMany } from '@commons'; // moved
import { Select } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import React, { ComponentPropsWithoutRef, useState } from 'react';
type MissionSelectProps = {
  isDisabled?: boolean;
  value?: string;
  onChange?: (id: string) => void;
  onChangeCompleteObject?: (object: any) => void;
  filter: any;
  referenceValue: 'uuid' | 'siren';
  noOptionsMessage?: () => string;
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const MissionSelect = ({
  value,
  onChange,
  filter: globalFilter,
  referenceValue = 'uuid',
  isDisabled,
  ...props
}: MissionSelectProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);
  const { data: missionQuery, isFetching } = useMissionFindMany({
    filterObject: {
      'billing.type': globalFilter?.['billing.type'],
      'provider.establishment.siren': globalFilter?.[
        'provider.establishment.siren'
      ]
        ? globalFilter?.['provider.establishment.siren']
        : undefined,
      //'provider.contact': globalFilter['provider.contact'],
      $and: [
        {
          $or: [...globalFilter['$or']],
        },
        {
          $or: [
            { reference: { $regex: debouncedFilter, $options: 'i' } },
            { 'sage.missionId': { $regex: debouncedFilter, $options: 'i' } },
            {
              'provider.establishment.tradeName': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard1': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard2': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard3': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'provider.establishment.businessName': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'customer.establishment.tradeName': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard1': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard2': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard3': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
            {
              'customer.establishment.businessName': {
                $regex: debouncedFilter,
                $options: 'i',
              },
            },
          ],
        },
      ],
    },
    limit: 10,
  });

  /** Get selected establishment */
  const { data: selectedMission } = useMissionFindOne(value);
  const selectedOption = selectedMission && {
    label: `${
      selectedMission.startAt ? getHumanDate(selectedMission.startAt) : 'N/A'
    } > ${
      selectedMission.endAt ? getHumanDate(selectedMission.endAt) : 'N/A'
    } - ${getEstablishmentName(
      selectedMission?.customer?.establishment,
      20
    )} - ${getEstablishmentName(
      selectedMission?.provider?.establishment,
      20
    )} - ${getFullName(
      selectedMission?.provider?.contractor
    )} - ${selectedMission?.displayReference}`,
    value: selectedMission.reference || '',
  };

  const missionOptions =
    missionQuery?.missions?.map(e => ({
      label: `${e.startAt ? getHumanDate(e.startAt) : 'N/A'} > ${
        e.endAt ? getHumanDate(e.endAt) : 'N/A'
      } - ${getEstablishmentName(
        e?.customer?.establishment,
        20
      )} - ${getEstablishmentName(
        e?.provider?.establishment,
        20
      )} - ${getFullName(e?.provider?.contractor)} - ${e?.displayReference}`,
      value: e.reference || '',
    })) || [];
  React.useEffect(() => {
    if (props?.onChangeCompleteObject) {
      if (selectedMission) {
        props.onChangeCompleteObject(selectedMission);
      }
      if (!value) {
        props.onChangeCompleteObject(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMission, value]);
  return (
    <Select
      isDisabled={isDisabled}
      options={missionOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => setFilter(value)}
      onChange={(option: any) => {
        onChange && onChange(option && option.value);
        props.onChangeCompleteObject &&
          props.onChangeCompleteObject(selectedMission && selectedMission);
      }}
      isClearable={true}
      value={selectedOption}
      {...props}
      //@ts-ignore
      noOptionsMessage={
        filter?.length === 0
          ? props?.noOptionsMessage
            ? props?.noOptionsMessage
            : () => 'aucun résultat trouvé'
          : () => 'aucun résultat trouvé'
      }
    />
  );
};
