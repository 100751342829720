import * as React from 'react';

function SvgEyeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 7c-3.636 0-6.742 2.28-8 5.5 1.258 3.22 4.364 5.5 8 5.5s6.742-2.28 8-5.5C18.742 9.28 15.636 7 12 7zm0 9.167c-2.007 0-3.636-1.643-3.636-3.667S9.993 8.833 12 8.833s3.636 1.643 3.636 3.667-1.629 3.667-3.636 3.667zm0-5.867c-1.207 0-2.182.983-2.182 2.2s.975 2.2 2.182 2.2c1.207 0 2.182-.983 2.182-2.2s-.975-2.2-2.182-2.2z"
      />
    </svg>
  );
}

export default SvgEyeIcon;
