import { displayMonth } from '@commons';
import { IFormattedCra } from '@freelancelabs/teoreme-commons';
import { SendIcon } from 'components/icons';
import {
  EventIcon,
  AccountCircleIcon,
  BusinessIcon,
  TimeLapsIcon,
  CloudDownloadIcon,
} from 'components/ui/icons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  boxProperties,
  BoxProps,
  Flex,
  LabelField,
  Link,
} from 'components/ui';
import * as React from 'react';
import styled from 'styled-components';

const ContainerCard = styled(Box)<{
  isSelected?: boolean;
  isSelectable: Boolean | undefined;
  temporary?: boolean | undefined;
  bottomChildrens?: any;
  height: any;
  isDisabled?: boolean;
}>`
  height: ${p => (p.height ? p.height : 'auto')};
  /* padding: 21px 40px 26px 28px;
  border-radius: 4px;
  border: ${props =>
    props.temporary
      ? 'dashed 1px #d0d4e3'
      : props.isSelected
        ? 'solid 1px #003cc2'
        : 'solid 1px #d0d4e3'}; */
  cursor: ${props => (props.isSelectable ? 'pointer' : 'initial')};
  background-color: var(--color-white);
  ${boxProperties}
`;
type Props = {
  cra: IFormattedCra;
  rightChidlrens?: React.ReactNode;
  bottomChildrens?: React.ReactNode;
  temporary?: boolean | undefined;
  height?: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  onChangeSelected?: () => void;
  ignoreScopedData?: boolean;
  isDisabled?: boolean;
};

export const CustomerCardCra = ({
  cra,
  rightChidlrens,
  bottomChildrens,
  temporary,
  height,
  isSelectable,
  ignoreScopedData,
  isSelected,
  onChangeSelected,
  isDisabled,
  ...props
}: BoxProps & Props) => {
  const contractor = `${cra?.contractorFirstName} ${cra?.contractorLastName}`;
  return (
    <ContainerCard
      width={1 / 1}
      isDisabled={isDisabled}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={() => (onChangeSelected ? onChangeSelected() : false)}
      height={height}
      bottomChildrens={bottomChildrens}
      temporary={temporary}
    >
      <Flex
        width={1 / 1}
        height={40}
        display={'inline-flex'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <EventIcon style={{ marginBottom: '5px' }} />
        <LabelField
          boldReverse
          boldValue
          label=""
          value={displayMonth(cra?.month)}
        />
      </Flex>
      <Flex
        width={1 / 1}
        height={40}
        display={'inline-flex'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <AccountCircleIcon style={{ marginBottom: '5px' }} />
        <LabelField label="" value={contractor} />
      </Flex>
      <Flex
        width={1 / 1}
        height={40}
        display={'inline-flex'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <BusinessIcon style={{ marginTop: '7px' }} />
        <LabelField label="" value={cra?.estProvider} />
      </Flex>
      <Flex
        width={1 / 1}
        height={40}
        display={'inline-flex'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <TimeLapsIcon style={{ marginBottom: '5px' }} />
        <LabelField
          boldReverse
          boldValue
          label=""
          value={`${cra?.nbWorkingDay} jours`}
        />
      </Flex>
      <Flex
        width={1 / 1}
        minHeight={40}
        display={'inline-flex'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <SendIcon fontSize={18} />
        <LabelField label="" value={cra?.customerComment || 'N/A'} />
      </Flex>
      <Flex
        width={1 / 1}
        height={40}
        display={'inline-flex'}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        alignItems={'center'}
      >
        <CloudDownloadIcon />
        <LabelField
          label=""
          value={
            cra?.proof ? (
              <Link
                onClick={() =>
                  showDisplayPdfModal({
                    //@ts-ignore
                    fileLocation: cra?.proof,
                    //@ts-ignore
                    fileName: cra?.proof,
                    fromCustomer: true,
                  })
                }
              >
                Voir le justificatif
              </Link>
            ) : (
              'Aucun justificatif'
            )
          }
        />
      </Flex>
      <Box mt={'5px'}>{bottomChildrens}</Box>
    </ContainerCard>
  );
};
CustomerCardCra.displayName = 'CustomerCardCra';

export default CustomerCardCra;
