import { useMe, REACT_APP_SEED_BUILD_ID } from '@commons';
import { Box, FormControl, Text, Input, Grid, Cell, Link } from 'components/ui';
import React from 'react';
import { FlatUser } from '@freelancelabs/teoreme-commons';
import { AccountForm } from 'forms/User/AccountForm';
import { showUpdatePasswordModal } from 'components/modals/UpdatePasswordModal';
import { useBreadCrumb } from 'hooks/breadCrumb';
type AccountProps = {
  //
};
const grid6 = {
  xs: '6',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};
export const Account: React.FC<React.PropsWithChildren<AccountProps>> = () => {
  const { me } = useMe();
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Paramètres de compte`,
      path: '/user/account',
    },
  ]);
  return (
    <Box p={20}>
      <Text variant="h1" mb={40}>
        Paramètres de compte
      </Text>
      <Text variant="h2" mb={20}>
        Informations de connexion
      </Text>
      <Grid cols={12}>
        <Cell x-span={grid6}>
          <FormControl label="Email">
            <>
              <Input isFullWidth isDisabled value={me?.email || ''} />
              <Link
                onClick={() =>
                  showUpdatePasswordModal({ user: me as FlatUser })
                }
              >
                Modifier le mot de passe
              </Link>
            </>
          </FormControl>
        </Cell>
      </Grid>
      <Text variant="h2" mb={20}>
        Informations générales
      </Text>
      <Box>
        <AccountForm user={me as FlatUser} />
      </Box>
      <Box mt={20}>
        <Text>Version : {REACT_APP_SEED_BUILD_ID}</Text>
      </Box>
    </Box>
  );
};

Account.displayName = 'Account';

export default Account;
