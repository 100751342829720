import { create } from 'zustand';
type State = {
  newMessage: boolean;
  updateWSNotifications: (state: Partial<State>) => void;
  resetWSNotifications: (state: Partial<State>) => void;
  type?: string; //'ALL' | ENotificationType | undefined;
  sort: string;
  limit: number;
  skip: number;
  page: number;
  startDate: Date | null;
  endDate: Date | null;
  debouncedFilterQuery: string;
};

const initialStates = {
  newMessage: false,
  type: 'ALL',
  sort: '-updatedAt',
  limit: 10,
  skip: 0,
  page: 0,
  startDate: null,
  endDate: null,
  debouncedFilterQuery: '',
};

const store = (set: any) => ({
  ...initialStates,
  updateWSNotifications: (state: Partial<State>) => set(state),
  resetWSNotifications: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useWSNotificationsStore = create<State>(store);
