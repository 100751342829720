import { EditIcon } from 'components/icons';
import {
  FormInfo,
  Link,
  Grid,
  Cell,
  Box,
  BoxProps,
  Flex,
  Text,
  LabelField,
} from 'components/ui';
import * as React from 'react';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { UserCard } from './UserCard';
import { SignatoryInformationForm } from 'forms/establishment/SignatoryInformationForm';
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
type Props = {
  establishment: any;
  temporary?: boolean | undefined;
  editable?: boolean;
};

export const SignatoryCard = ({
  establishment,
  editable,
  ...props
}: BoxProps & Props) => {
  const [displayForm, setDisplayForm] = React.useState(false);
  const delegatedSignOfficer = establishment?.provider?.delegatedSignOfficer;
  const signOfficer = establishment?.provider?.signOfficer;
  return (
    <Box>
      <Flex width={1 / 1} justifyContent="space-between">
        <Text variant="h2">Informations du signataire</Text>
        {signOfficer && !displayForm && editable && (
          <Link onClick={() => setDisplayForm(true)} iconLeft={<EditIcon />}>
            Modifier
          </Link>
        )}
        {!signOfficer && !displayForm && editable && (
          <Link onClick={() => setDisplayForm(true)} iconLeft={<EditIcon />}>
            Compléter
          </Link>
        )}
      </Flex>
      {signOfficer && !displayForm && (
        <Grid cols={2} mt={20}>
          <Cell mt={20} x-span={grid2} placeSelf="left">
            <Box>
              <Text
                variant="p"
                fontWeight="bold"
                mb={10}
                color="grey !important"
              >
                Mandataire social
              </Text>
              <UserCard user={signOfficer} />
            </Box>
          </Cell>
          {delegatedSignOfficer && !displayForm && (
            <Cell mt={20} x-span={grid2} placeSelf="left">
              <Box>
                <Text
                  variant="p"
                  fontWeight="bold"
                  mb={10}
                  color="grey !important"
                >
                  Personne habilitée à signer
                </Text>
                <UserCard
                  bottomChildrens={
                    <LabelField
                      label="Délégation de signature : "
                      value={
                        <Link
                          onClick={() =>
                            showDisplayPdfModal({
                              fileName:
                                delegatedSignOfficer?.delegationOrder?.fileName,
                              fileLocation:
                                delegatedSignOfficer?.delegationOrder
                                  ?.fileLocation,
                            })
                          }
                        >
                          {delegatedSignOfficer?.delegationOrder?.fileName}
                        </Link>
                      }
                    />
                  }
                  user={delegatedSignOfficer}
                />
              </Box>
            </Cell>
          )}
        </Grid>
      )}
      {!signOfficer && !displayForm && (
        <FormInfo variantColor="warning">Informations à compléter</FormInfo>
      )}
      {displayForm && (
        <SignatoryInformationForm
          establishment={establishment}
          setDisplayForm={(value: boolean) => setDisplayForm(value)}
        />
      )}
    </Box>
  );
};
SignatoryCard.displayName = 'SignatoryCard';

export default SignatoryCard;
