import {
  getHumanDate,
  getSructure,
  isExpenseInvoice,
  isMileStoneInvoice,
  isStandByDutyInvoice,
} from '@commons';
import {
  EInvoiceStatus,
  EMissionCraValidatedBy,
  EMissionStructure,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';

import {
  Box,
  Cell,
  DatePickerControlled,
  FormControl,
  Grid,
  Input,
  Text,
} from 'components/ui';
type FormValues = {
  invoice: IJoinedInvoice;
};
type InvoiceInformationsFormProps = {
  form: FormValues & any;
  isEditable: boolean;
  invoice: IJoinedInvoice;
  isCreditNote?: boolean;
};
const grid2 = {
  xs: '3',
  sm: '3',
  md: '3',
  lg: '2',
  xl: '2',
};
const grid4 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};
const grid6 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};
const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
export const InvoiceInformationsForm = ({
  form: {
    formState: { errors, isDirty },
    register,
    watch,
    control,
    setValue,
  },
  isEditable,
  invoice,
  isCreditNote,
}: InvoiceInformationsFormProps) => {
  const invoiceDate = watch('invoiceDate');
  const month = watch('month');
  const isDirectPayment =
    invoice?.mission?.customer?.billingOptions?.isDirectPayment;
  let textDeadline = <Text mt={-20}></Text>;

  /**
   * Date de facture : invoiceDate
   * Date de transmission : new Date()
   * Délai de paiement : invoice?.paymentDeadline
   * Date d'échéance :  invoiceDate + invoice?.paymentDeadline
   *
   * Si la cra a été validé par le client ,
   * alors date d'échéance = date de validation du cra + délai de paiement
   * Sinon date d'échéance = date de soumission du cra + délai de paiement
   *
   * 41 eme modif
   *
   * Si validation du CRA par le client, afficher :
   *    L'échéance de paiement de cette facture est de [Délai de paiement fournisseur].
   *    Cette échéance de paiement sera déclenchée à partir de la date de validation de votre CRA par le client.
   *
   * Si validation du CRA par l’ADV, afficher :
   *    L'échéance de paiement de cette facture est de [Délai de paiement fournisseur].
   *    Cette échéance de paiement sera déclenchée à partir de la date de soumission de votre CRA à nos équipes
   *
   *
   */
  const textType = isMileStoneInvoice(invoice?.invoiceType)
    ? 'jalon'
    : isStandByDutyInvoice(invoice?.invoiceType)
      ? 'déclaration de prestations complémentaires'
      : isExpenseInvoice(invoice?.invoiceType)
        ? 'déclaration de frais'
        : 'CRA';

  const isCraInvoice = (): boolean => {
    if (
      isStandByDutyInvoice(invoice?.invoiceType) ||
      isExpenseInvoice(invoice?.invoiceType) ||
      isMileStoneInvoice(invoice?.invoiceType)
    ) {
      return false;
    }

    return true;
  };

  const canShowDueDate = (status: EInvoiceStatus): boolean => {
    if (isDirectPayment) return false;

    const notAllowedStatus = [
      EInvoiceStatus.PROJECTED,
      EInvoiceStatus.TO_BE_FILLED,
      EInvoiceStatus.TO_BE_SUBMITTED,
    ];
    return !notAllowedStatus.includes(status);
  };

  if (invoiceDate) {
    if (!isCraInvoice()) {
      // standByDuty or expense 'validation par nos equipes'
      textDeadline = (
        <Text mt={-20} mb={15}>
          L'échéance de paiement de cette facture est de{' '}
          <b>{invoice?.paymentDeadline} jours nets. </b>
          Cette échéance de paiement sera déclenchée à partir de la{' '}
          <b> date de validation </b> de votre {textType} par nos équipes.
        </Text>
      );
    } else {
      // cra invoice
      if (
        invoice?.cra.craValidatedBy === EMissionCraValidatedBy.CUSTOMER_CONTACT
      ) {
        textDeadline = (
          <Text mt={-20} mb={15}>
            L'échéance de paiement de cette facture est de{' '}
            <b>{invoice?.paymentDeadline} jours nets. </b>
            Cette échéance de paiement sera déclenchée à partir de la{' '}
            <b> date de validation </b> de votre {textType} par le client.
          </Text>
        );
      } else if (
        invoice?.cra.craValidatedBy === EMissionCraValidatedBy.ACCOUNT_MANAGER
      ) {
        textDeadline = (
          <Text mt={-20} mb={15}>
            L'échéance de paiement de cette facture est de{' '}
            <b>{invoice?.paymentDeadline} jours nets. </b>
            Cette échéance de paiement sera déclenchée à partir de la{' '}
            <b> date de soumission </b> de votre {textType} à nos équipes.
          </Text>
        );
      }
    }
  }
  return (
    <Grid cols={12} gap="20px">
      <Cell x-span={{ xs: '12' }}>
        <Text variant="h2">Informations liées à la facture</Text>
        {isDirectPayment && (
          <Box mt={10}>
            <Text
              color="red"
              variant="b"
            >{`Votre facture sera réglée directement par le Client final et non pas par ${getSructure(invoice?.mission?.billingInformation?.structure as EMissionStructure)?.complete}`}</Text>
          </Box>
        )}
      </Cell>
      <Cell x-span={grid4}>
        <FormControl
          required={true}
          label="Numéro de facture"
          errorMessage={errors?.providerInvoiceRef?.message}
        >
          <Input
            isDisabled={!isEditable}
            isFullWidth
            pr={20}
            {...register('providerInvoiceRef', {
              required: 'Ce champ est requis',
            })}
          />
        </FormControl>
      </Cell>
      <Cell x-span={grid4}>
        <FormControl
          required
          label="Date de facturation"
          errorMessage={errors?.invoiceDate?.message}
        >
          <DatePickerControlled
            disabled={!isEditable || isCreditNote}
            control={control}
            minDate={month}
            maxDate={new Date()}
            rules={{ required: 'Ce champ est requis' }}
            name="invoiceDate"
            valueName="selected"
          />
        </FormControl>
      </Cell>
      {canShowDueDate(invoice?.status) && (
        <Cell x-span={grid4}>
          <FormControl label={`Date d'échéance`}>
            <Input
              isDisabled
              isFullWidth
              value={
                invoice?.dueDate
                  ? (getHumanDate(invoice?.dueDate) as string)
                  : undefined
              }
            />
          </FormControl>
        </Cell>
      )}
      <Cell x-span={grid12} placeSelf="start">
        {!isCreditNote && !isDirectPayment && textDeadline}
        {/* {invoice?.paymentDeadline !== 60 ? (
          <Text mt={-20}>
            L'échéance de paiement de cette facture est de{' '}
            <b>{invoice?.paymentDeadline} jours nets</b>. Cette échéance de
            paiement sera déclenchée à partir de la <b>date de transmission</b>{' '}
            de votre facture valide à nos équipes Connecteed.
          </Text>
        ) : (
          <Text mt={-20}>
            L'échéance de paiement de cette facture est de :{' '}
            <b>60 jours nets (soit 45 jours fin de mois).</b> Cette échéance de
            paiement sera déclenchée à partir de la date de transmission de
            votre facture valide à nos équipes Connecteed.
          </Text>
        )} */}
      </Cell>
    </Grid>
  );
};
