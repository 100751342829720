import * as React from 'react';

function BusinessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="33"
      viewBox="0 0 16 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00065 5.31047V2.6438H1.33398V14.6438H14.6673V5.31047H8.00065ZM4.00065 13.3105H2.66732V11.9771H4.00065V13.3105ZM4.00065 10.6438H2.66732V9.31047H4.00065V10.6438ZM4.00065 7.97713H2.66732V6.6438H4.00065V7.97713ZM4.00065 5.31047H2.66732V3.97713H4.00065V5.31047ZM6.66732 13.3105H5.33398V11.9771H6.66732V13.3105ZM6.66732 10.6438H5.33398V9.31047H6.66732V10.6438ZM6.66732 7.97713H5.33398V6.6438H6.66732V7.97713ZM6.66732 5.31047H5.33398V3.97713H6.66732V5.31047ZM13.334 13.3105H8.00065V11.9771H9.33398V10.6438H8.00065V9.31047H9.33398V7.97713H8.00065V6.6438H13.334V13.3105ZM12.0007 7.97713H10.6673V9.31047H12.0007V7.97713ZM12.0007 10.6438H10.6673V11.9771H12.0007V10.6438Z"
        fill="#808080"
      />
    </svg>
  );
}

export default BusinessIcon;
