import {
  getServiceAmount,
  useInvoiceUpdateOne,
  getInvoiceWithTaxeAndDecution,
  uploadFile,
  cutLongText,
  isCreditNoteInvoice,
  getInvoiceTotalAmount,
  isCustomerInvoice,
  extractVerifyRib,
  providerExtractVerifyRibNeedWarning,
} from '@commons';
import {
  EFileType,
  EInvoiceStatus,
  IJoinedInvoice,
  IFile,
  EInvoiceType,
} from '@freelancelabs/teoreme-commons';
import { DownloadIcon, EuroIcon } from 'components/icons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  Button,
  Cell,
  FileInput,
  Flex,
  FormControl,
  Grid,
  Spinner,
  Text,
  Link,
  Divider,
  TextAreaControlled,
} from 'components/ui';
import { queryClient } from '@commons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AdditionalServicesForm } from 'forms/partials/invoices/AdditionalServicesForm';
import { InvoiceMissionInfomationsForm } from 'forms/partials/invoices/InvoiceMissionInfomationsForm';
import { InvoiceInformationsForm } from 'forms/partials/invoices/InvoiceInformationsForm';
import { InvoiceEstablishmentInformations } from 'forms/partials/invoices/InvoiceEstablishmentInformations';
import { showOnConfirmFormModal } from 'components/modals/OnConfirmFormModal';
import { Theme } from 'styles';
import TextInformation from 'components/TextInformation';

type FormValues = any;

type CreditNoteFormProps = {
  onSubmit?: () => void;
  defaultValues: IJoinedInvoice;
};

const grid6 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};

export const CreditNoteForm: React.FC<React.PropsWithChildren<FormValues>> = ({
  defaultValues,
}: CreditNoteFormProps) => {
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const theme = useTheme();
  const history = useHistory();
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLater, setLoadingLater] = useState(false);
  const [invoiceFormFile, setInvoiceFormFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceFile, setInvoiceFile] = React.useState<IFile | null>(
    defaultValues?.invoiceFile ? defaultValues?.invoiceFile : null
  );
  const [checkFileWarning, setCheckFileWarning] = useState<
    React.ReactNode | undefined
  >();
  const form = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      //invoiceDate: addDays(new Date(), 10),
    },
  });
  const {
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    control,
    getValues,
  } = form;

  const nbWorkingDays = watch('nbWorkingDays');
  //const totalAmountHT = nbWorkingDays * contractorRate;
  const totalAmountHT = getInvoiceTotalAmount(
    defaultValues,
    isCustomerInvoice(defaultValues?.invoiceType) ? 'CUSTOMER' : 'PROVIDER'
  );
  const deductionAmount = watch('deductionAmount');
  const vatRate = watch('vatRate');
  const isVat = watch('isVat');
  const checkIsEditableForm = () => {
    if (!defaultValues) {
      return false;
    }
    switch (defaultValues.status) {
      case EInvoiceStatus.VALIDATED:
        return true;
      case EInvoiceStatus.SENT:
        return false;
      default:
        return false;
    }
  };
  const handleChangeProofFile = async (files: File[]) => {
    setLoadingFile(true);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        fileType: EFileType.TEMPORARY,
        missionRef: defaultValues?.mission?.reference,
        actionType: 'upload',
      });
      const data = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag as string,
      };
      setInvoiceFile(data);
    } catch (e) {
      setLoadingFile(false);
    }
    setLoadingFile(false);
    setInvoiceFormFile(files[0]);
    clearErrors('invoiceFile');
  };
  const onUpdateInvoice = async (
    formValues: FormValues,
    saveLater: boolean
  ) => {
    if (saveLater) {
      setLoadingLater(true);
    } else {
      setLoading(true);
    }
    let onError = false;
    if (isCreditNoteInvoice(defaultValues?.invoiceType as EInvoiceType)) {
      const submitData = {
        comment: formValues?.comment,
        invoiceFile: formValues?.invoiceFile,
        status: saveLater ? defaultValues?.status : EInvoiceStatus?.SENT,
        providerInvoiceRef: formValues?.providerInvoiceRef,
      };
      if (invoiceFile || invoiceFormFile) {
        setLoadingFile(true);
        if (invoiceFormFile) {
          try {
            submitData.invoiceFile = invoiceFile;
          } catch (e) {
            onError = true;
          }
        }
        setLoadingFile(false);
        if (onError === false) {
          try {
            await updateInvoice({
              uuids: [defaultValues.uuid],
              invoice: submitData,
            });
            queryClient.refetchQueries({
              queryKey: [defaultValues.uuid],
              type: 'active',
            });
            queryClient.refetchQueries({ queryKey: ['Invoices'] });
            history?.goBack();
          } catch (e) {
            //console.log(e)
          }
        }
        if (saveLater) {
          setLoadingLater(false);
        } else {
          setLoading(false);
        }
      } else {
        if (!saveLater) {
          onError = true;
          setError('invoiceFile', { message: 'Ce champs est requis' });
        }
      }
    } else {
      const submitData = {
        comment: formValues?.comment,
        providerInvoiceRef: formValues?.providerInvoiceRef,
        //invoiceDate: removeOffsetDate(formValues?.invoiceDate),
        invoiceFile: formValues?.invoiceFile,
        status: saveLater ? EInvoiceStatus.VALIDATED : EInvoiceStatus.SENT,
      };
      if (invoiceFile || invoiceFormFile) {
        if (invoiceFormFile) {
          setLoadingFile(true);
          try {
            const s3Response = await uploadFile({
              file: invoiceFormFile,
              fileType: EFileType.TEMPORARY,
              missionRef: defaultValues?.mission?.reference,
              actionType: 'upload',
            });
            const data = {
              fileName: s3Response?.fileName,
              fileLocation: s3Response?.fileLocation as string,
              eTag: s3Response?.eTag,
            };
            submitData.invoiceFile = data;
          } catch (e) {
            onError = true;
          }
          setLoadingFile(false);
        }
      } else {
        if (!saveLater) {
          onError = true;
          setError('invoiceFile', { message: 'Ce champs est requis' });
        }
      }

      if (onError === false) {
        try {
          await updateInvoice({
            uuids: [defaultValues.uuid],
            invoice: submitData,
          });
          await queryClient.invalidateQueries({ queryKey: ['Invoices'] });
          await queryClient.invalidateQueries({
            queryKey: [defaultValues.uuid],
          });
          queryClient.refetchQueries({ queryKey: [defaultValues.uuid] });
          queryClient.refetchQueries({ queryKey: ['Invoices'] });

          // history.push(
          //   `/invoices/credit-note/${!saveLater ? 'sent' : 'validated'}`
          // );
          history?.goBack();
        } catch (e) {
          //console.log(e)
        }
      }
      if (saveLater) {
        setLoadingLater(false);
      } else {
        setLoading(false);
      }
    }
  };
  const onSaveLater = async () => {
    const formValues = getValues();
    await onUpdateInvoice(formValues, true);
  };
  const onSubmit = async (formValues: FormValues) => {
    if (!invoiceFile && !invoiceFormFile) {
      setError('invoiceFile', { message: 'Ce champs est requis' });
    } else {
      clearErrors('invoiceFile');
      await showOnConfirmFormModal({
        title: "Etes-vous sûr(e) de vouloir envoyer cette facture d'avoir ?",
        text: '',
        confirmLabelButton: 'Envoyer',
        data: { ...getValues(), invoiceFile },
        checkInvoice: true,
      }).then(action => {
        if (action === true) {
          onUpdateInvoice(formValues, false);
          queryClient.refetchQueries({ queryKey: ['Invoices'] });
        }
      });
      queryClient?.refetchQueries({ queryKey: ['Invoices'] });
    }
  };

  const getIsDisabledForm = () => {
    return false;
  };
  const isEditable = checkIsEditableForm();
  return (
    <form style={{ padding: 5 }} onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal
        active={isDirty && !loading && !loadingLater && !isSubmitSuccessful}
      />
      <InvoiceInformationsForm
        invoice={defaultValues}
        form={form}
        isEditable={isEditable}
        isCreditNote
      />
      <Grid cols={12} gap="40px">
        <Cell x-span={{ xs: '12' }}>
          <Text variant="h2">Importer la facture correspondante</Text>
        </Cell>
        <Cell x-span={grid6} placeSelf="center">
          <Text>
            Merci de vérifier que votre facture correspond bien aux données
            indiquées dans ce formulaire.
          </Text>
        </Cell>
        <Cell x-span={grid6} justifySelf="end">
          <FormControl
            required={isEditable}
            label={
              isEditable ? 'Importer la facture (.pdf seulement)' : 'Facture'
            }
            errorMessage={errors?.invoiceFile?.message}
          >
            {isEditable ? (
              <FileInput
                isDisabled={!isEditable}
                pr={20}
                label=""
                accept=".pdf"
                subLabel=""
                onSelect={handleChangeProofFile}
              >
                {loadingFile && <Spinner />}
                <Link iconLeft={<DownloadIcon />}>
                  {invoiceFormFile || invoiceFile?.fileName
                    ? `Modifier le fichier ${
                        invoiceFormFile?.name
                          ? cutLongText(invoiceFormFile.name, 20)
                          : cutLongText(
                              invoiceFile && invoiceFile.fileName
                                ? invoiceFile?.fileName
                                : '',
                              20
                            )
                      }`
                    : 'Importer un document'}
                </Link>
              </FileInput>
            ) : (
              <Box pr={20}>
                <Flex
                  pl={'10px'}
                  justifyContent="flex-start"
                  alignContent="center"
                  alignItems="center"
                  border={`1px solid ${theme.colors.primary.inputBorder}`}
                  height={45}
                  borderRadius={5}
                  backgroundColor="rgba(182,198,238,0.5)"
                >
                  <Link
                    onClick={() =>
                      showDisplayPdfModal({
                        fileLocation: invoiceFile?.fileLocation || '',
                        fileName: invoiceFile?.fileName || '',
                      })
                    }
                  >
                    fileName
                    <u>{invoiceFile?.fileName}</u>
                  </Link>
                </Flex>
              </Box>
            )}
          </FormControl>
          {checkFileWarning && (
            <TextInformation variant="warning">
              {checkFileWarning}
            </TextInformation>
          )}
        </Cell>
      </Grid>
      <Divider mb={40} mt={40} />
      <InvoiceMissionInfomationsForm form={form} isEditable={isEditable} />
      <Divider mb={40} mt={40} />
      <Grid cols={12} gap="40px">
        <Cell x-span={{ xs: '10' }}>
          <Text variant="h2">Montant HT</Text>
        </Cell>
        <Cell x-span={{ xs: '2' }}>
          <Text
            variant="h1"
            color="#003cc2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#f4f6fd"
            fontWeight={500}
          >
            {totalAmountHT.toFixed(2) || '0'} <EuroIcon fill="#003cc2" />
          </Text>
        </Cell>
      </Grid>
      <Divider mb={40} mt={40} />
      <AdditionalServicesForm
        invoice={defaultValues}
        form={form}
        isEditable={false}
      />
      <Divider mb={40} mt={40} />
      <Grid cols={12} gap="40px">
        <Cell x-span={{ xs: '10' }}>
          <Text variant="h2">Montant Net (HT)</Text>
        </Cell>
        <Cell x-span={{ xs: '2' }}>
          <Text
            variant="h1"
            color="#003cc2"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#f4f6fd"
            fontWeight={500}
          >
            {(
              totalAmountHT -
              getServiceAmount(totalAmountHT, nbWorkingDays, defaultValues)
            ).toFixed(2)}
            <EuroIcon fill="#003cc2" />
          </Text>
        </Cell>
      </Grid>
      <Divider mb={40} mt={40} />
      <InvoiceEstablishmentInformations
        invoiceData={defaultValues}
        form={form}
        isEditable={false}
      />
      <Divider mb={40} mt={40} />
      <Grid cols={12} gap="40px">
        <Cell x-span={{ xs: '10' }}>
          <Text variant="h1" color="#009933">
            Montant Total TTC
          </Text>
        </Cell>
        <Cell x-span={{ xs: '2' }}>
          <Text
            variant="h1"
            color="#009933"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 153, 51, 0.1)"
            fontWeight={500}
          >
            {getInvoiceWithTaxeAndDecution(
              totalAmountHT,
              nbWorkingDays,
              defaultValues,
              isVat ? vatRate : 0,
              deductionAmount
            ).toFixed(2)}
            <EuroIcon fill="#009933" />
          </Text>
        </Cell>
      </Grid>
      <Divider mb={40} mt={40} />
      <Grid cols={12} gap="40px">
        <Cell x-span={{ xs: '12' }}>
          <Text variant="h2">Commentaire</Text>
        </Cell>
        <Cell x-span={{ xs: '12' }}>
          <FormControl
            label="Message à adresser à votre conseiller lors de la transmission de votre facture"
            errorMessage={
              errors?.comment?.type === 'maxLength'
                ? 'Veuillez renseigner moins de 1000 caractères'
                : errors?.comment?.message
            }
          >
            <TextAreaControlled
              isDisabled={!isEditable}
              control={control}
              name="comment"
              rules={{ maxLength: 999 }}
              minRows={3}
            />
          </FormControl>
        </Cell>
      </Grid>
      <Flex justifyContent="flex-start">
        <Button
          mr={10}
          key="Cancel"
          type="button"
          onClick={() => history?.goBack()}
          variant="ghost"
        >
          Fermer
        </Button>
        {isEditable && (
          <>
            <Button
              onClick={handleSubmit(onSaveLater)}
              mr={10}
              key="saveLater"
              type="button"
              isLoading={loadingLater}
              isDisabled={getIsDisabledForm() || loading || loadingLater}
              variant="ghost"
            >
              Enregistrer et finir plus tard
            </Button>
            <Button
              key="submit"
              type="submit"
              isLoading={loading}
              isDisabled={getIsDisabledForm() || loading || loadingLater}
            >
              Envoyer
            </Button>
          </>
        )}
      </Flex>
    </form>
  );
};

export default CreditNoteForm;
