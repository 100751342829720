import { EconfirmUserAction } from '@freelancelabs/teoreme-commons';
import {
  checkValidPassword,
  confirmPassword,
  verifyToken,
  checkFormPassword,
} from '@commons';
import { useAmplifyLogout } from '@commons';
import { WelcomeImage } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Input,
  Link,
  Row,
  Spinner,
  Text,
} from 'components/ui';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import AccesDenied from 'routes/Common/AccesDenied';
import { useTheme } from 'styled-components';
import { useBreadCrumb } from 'hooks/breadCrumb';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const ForgetPassword: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Réinitialiser votre mot de passe`,
      path: '',
    },
  ]);
  const history = useHistory();
  const { mutateAsync: logout } = useAmplifyLogout();
  const Theme = useTheme();
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = React.useState('noFetch');
  const [email, setEmail] = React.useState('');
  const [isloading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const loading = status === 'pending';
  const checkToken = React.useCallback(async (): Promise<void> => {
    try {
      const response = await verifyToken({ token: token }, true);
      setEmail(response.email);
      setStatus('success');
    } catch {
      setStatus('error');
    }
  }, [token]);

  React.useEffect(() => {
    if (status === 'noFetch') {
      setStatus('loading');
      checkToken();
    }
  }, [checkToken, status]);

  const onSubmit = async (data: FormValues) => {
    let onError = false;
    if (data.password === data.confirmPassword) {
      if (checkValidPassword(data.password)) {
        setError('password', { message: undefined });
        setError('confirmPassword', { message: undefined });
      } else {
        onError = true;
        setError('password', {
          message: "Le format du mot de passe n'est pas valide",
        });
        setError('confirmPassword', {
          message: "Le format du mot de passe n'est pas valide",
        });
      }
    } else {
      onError = true;
      setError('password', {
        message: 'Les mots de passe ne sont pas identiques',
      });
      setError('confirmPassword', {
        message: 'Les mots de passe ne sont pas identiques',
      });
    }
    if (onError === false) {
      setIsLoading(true);
      try {
        const params = {
          email: email,
          newPassword: data.password,
          action: EconfirmUserAction.RESET,
        };

        await confirmPassword(params);
        setIsLoading(false);
        if (localStorage.token) {
          logout();
        }
        await showDialogModal({
          title: 'Félicitations, votre mot de passe a bien été réinitialisé !',
          text: 'Vous allez recevoir un mail de confirmation.',
          confirmLabel: 'Retourner à la page de connexion',
        }).then(action => {
          if (action) {
            history.push('/login');
          }
        });
      } catch (e) {
        //console.log(e);
      }
    }
  };

  if (loading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  } else {
    if (status === 'success') {
      return (
        <Flex
          height={'100vh'}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center" width="100%" height={'100%'}>
            <Row spacing={20} width="100%" height={'100%'}>
              <Flex
                width="100%"
                height={'100%'}
                alignItems="center"
                justifyContent="center"
              >
                <Box width={500}>
                  <Text
                    variant="h1"
                    fontSize={32}
                    mb={50}
                    color={Theme.colors.primary.default}
                  >
                    Bienvenue
                  </Text>
                  <Text variant="h3">
                    <b>Réinitialiser votre mot de passe</b>
                  </Text>
                  <Text mb={10}>
                    Il doit contenir au moins 8 caractères, 1 majuscule, 1
                    minuscule et 1 caractère spécial parmi : ^ $ * . [ ] {} ( )
                    ? " ! @ # % & / \ , {'>'} {'<'} \' : ; | _ ~.
                  </Text>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                      required
                      label="Mot de passe"
                      errorMessage={errors?.password?.message}
                    >
                      <Box width="85%">
                        <Input
                          isFullWidth
                          type="password"
                          id="password"
                          {...register('password', {
                            validate: (value: any) => checkFormPassword(value),
                          })}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      required
                      label="Confirmer votre mot de passe"
                      errorMessage={errors?.confirmPassword?.message}
                    >
                      <Box width="85%">
                        <Input
                          isFullWidth
                          type="password"
                          id="confirmPassword"
                          {...register('confirmPassword', {
                            validate: (value: any) => checkFormPassword(value),
                          })}
                        />
                      </Box>
                    </FormControl>
                    <Button mb={10} type="submit" isLoading={isloading}>
                      Réinitialiser
                    </Button>
                  </form>
                  <Box mt={40}>
                    <Divider />
                  </Box>
                  <Text variant="p" color={'#819CAD'}>
                    Vous avez besoin d’aide ?{' '}
                    <Link onClick={() => showContactUsModal()}>
                      Contactez-nous
                    </Link>
                  </Text>
                </Box>
              </Flex>
              <Box height={'100%'} width="100%">
                <WelcomeImage width={800} height="100vh" />
              </Box>
            </Row>
          </Flex>
        </Flex>
      );
    }
    if (status === 'error') {
      return <AccesDenied />;
    } else {
      return <></>;
    }
  }
};
export default ForgetPassword;
