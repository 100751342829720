import { Container } from 'components/ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TimeSpentList } from 'routes/Invoices/TimeSpent/List';
import EditTimeSpent from './Details/EditTimeSpent';

export const TimeSpent = () => {
  return (
    <Switch>
      <Route path="/invoices/time-spent/:filter(to-be-filled|to-be-submitted|to-be-validated|validated|archived|rejected|cancelled|paid|sent)/:id">
        <Container isFullWidth>
          <EditTimeSpent />
        </Container>
      </Route>
      <Route path="/invoices/time-spent/:filter(to-be-filled|to-be-submitted|to-be-validated|validated|archived|cancelled|paid)">
        <Container>
          <TimeSpentList />
        </Container>
      </Route>
    </Switch>
  );
};
