import {
  craUpdateOne,
  getFormulaDutyFormulaAmount,
  getFullName,
  getHumanDate,
  getStandByDutiesTotalAmount,
  getTradeNameSafe,
  queryClient,
  round,
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  additionalActivityUpdateOne,
  getHumanDateMonthAndYear,
  EXPENSES_TYPE_TRANSLATE_FR,
  getAdditionalActivitiesTotalAmount,
} from '@commons';
import {
  ECraStatus,
  EStandByDutyUnits,
  IJoinedAdditionalActivity,
  IJoinedCraWithInvoice,
  IJoinedMission,
  EadditionalActivityStatus,
  EadditionalActivityType,
  IAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
} from '@freelancelabs/teoreme-commons';
import Accordion from 'components/Accordion';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import {
  Box,
  Button,
  Cell,
  ColorTextBox,
  Divider,
  Flex,
  Grid,
  LabelField,
  Link,
  Text,
} from 'components/ui';
import {
  DutyTimeIcon,
  FeesIcon,
  GoBackIcon,
  TimeLapsIcon,
  ValidateIcon,
} from 'components/ui/icons';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { useHistory } from 'react-router-dom';
import { Theme } from 'styles';
import { showDisplayPdfModal } from '../DisplayPdfModal';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  month?: Date;
  mission: IJoinedMission;
  activities?: IJoinedAdditionalActivity[];
  cra?: IJoinedCraWithInvoice;
};
const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
export const SubmitAllActivitiesModal = ({
  onResolve,
  isOpen,
  layer,
  month,
  mission,
  activities,
  cra,
}: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();
  const missionRef = mission?.reference;
  const onSubmit = async () => {
    setIsFetching(true);
    try {
      if (Array?.isArray(activities)) {
        await Promise.all(
          activities.map(async activity => {
            await additionalActivityUpdateOne({
              uuid: activity?.uuid as string,
              status: EadditionalActivityStatus?.TO_BE_VALIDATED,
            });
          })
        );
      }

      if (cra?.state === ECraStatus?.TO_BE_SUBMITTED) {
        await craUpdateOne({
          refCras: [cra.refCra],
          cra: {
            state: ECraStatus?.TO_BE_VALIDATED,
          },
        });
      }
      if (cra) {
        queryClient?.refetchQueries({ queryKey: [cra?.refCra] });
      }
      if (activities) {
        queryClient?.invalidateQueries({ queryKey: ['additionalActivities'] });
        queryClient?.refetchQueries({ queryKey: [mission?.reference] });
        queryClient?.refetchQueries({
          queryKey: ['ActivitiesReport'],
          type: 'active',
        });
      }
      setIsSubmit(true);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  };
  const standByDuties = activities?.filter(
    a => a?.type === EadditionalActivityType?.STAND_BY_DUTY
  );
  const expenses = activities?.filter(
    a => a?.type === EadditionalActivityType?.EXPENSE
  );
  const mileStones = activities?.filter(
    a => a?.type === EadditionalActivityType?.MILESTONE
  );
  const getProviderExpensesTotalAmount = (
    aa: IAdditionalActivity | IJoinedAdditionalActivityWithInvoice
  ): number => {
    let total = 0;
    aa?.expenseLines?.forEach(expense => (total = total + expense?.amount));
    return total;
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1000}
      closeIcon
      layer={layer}
    >
      <Box width={1 / 1} minHeight={500}>
        <Text variant="h2">
          {!isSubmit ? 'Vérification de votre saisie' : 'Déposer vos factures'}
        </Text>
        {!isSubmit ? (
          <Box width={1 / 1}>
            <Box mt={40} mb={10}>
              <Text variant="p" textAlign={'center'}>
                Merci de vérifier l'éxactitude des informations saisies avant
                transmission.
              </Text>
            </Box>
            <Box width={1 / 1}>
              <Grid cols={12} gap="10px" mb={20}>
                <Cell x-span={grid12}>
                  <Flex
                    height={'100px'}
                    p={20}
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    backgroundColor="#edf3ff"
                    borderRadius="8px"
                  >
                    {month && (
                      <LabelField
                        underline
                        label="Période"
                        value={getHumanDateMonthAndYear(month)}
                      />
                    )}
                    <LabelField
                      underline
                      label="Référence mission"
                      value={mission?.displayReference}
                    />
                    <LabelField
                      underline
                      label="Fournisseur"
                      value={
                        mission &&
                        getTradeNameSafe(mission?.provider?.establishment, 20)
                      }
                    />
                    <LabelField
                      underline
                      label="Client"
                      value={
                        mission &&
                        getTradeNameSafe(mission?.customer?.establishment, 20)
                      }
                    />
                    <LabelField
                      underline
                      label="Intervenant"
                      value={getFullName(mission?.provider?.contractor, 20)}
                    />
                  </Flex>
                </Cell>
              </Grid>
              <Grid cols={12} gap="10px" mt={10} mb={10}>
                {cra && (
                  <Cell x-span={grid12}>
                    <Accordion
                      defaultOpen
                      openOnTop
                      propsBar={{
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        border: '1px solid #d0d4e3',
                      }}
                      childrenTop={
                        <Flex
                          alignContent="center"
                          alignItems="center"
                          justifyContent="space-between"
                          width={1 / 1}
                        >
                          <Flex alignContent="center" alignItems="center">
                            <TimeLapsIcon
                              width={'30px'}
                              height={'30px'}
                              fill={Theme?.colors?.primary?.default}
                            />
                            <Text ml={10} variant="h2">
                              Prestation standard
                            </Text>
                          </Flex>
                          <Flex alignContent="center" alignItems="center">
                            <ColorTextBox
                              variantColor="primary"
                              ml={5}
                              mr={'5px'}
                            >
                              {`${
                                cra?.nbWorkingDays
                                  ? `${cra?.nbWorkingDays} jours : ${round(
                                      cra?.nbWorkingDays * cra?.contractorRate
                                    )} €`
                                  : 'N/A'
                              }`}
                            </ColorTextBox>
                          </Flex>
                        </Flex>
                      }
                      key={`cras`}
                    >
                      <Box
                        position="relative"
                        top={-5}
                        p={10}
                        borderLeft="1px solid"
                        borderRight="1px solid"
                        borderBottom="1px solid"
                        borderColor={'#d0d4e3'}
                      >
                        <Flex
                          width={1 / 1}
                          alignContent="center"
                          alignItems="center"
                        >
                          <LabelField
                            label="Nombre de jours"
                            value={
                              <Box mt={'10px'}>
                                <ColorTextBox variantColor="primary">
                                  {cra?.nbWorkingDays
                                    ? `${cra?.nbWorkingDays} jours `
                                    : 'N/A'}
                                </ColorTextBox>
                              </Box>
                            }
                            underline
                          />
                          <LabelField
                            label="TJM HT"
                            underline
                            value={
                              <Box mt={'10px'}>
                                <ColorTextBox variantColor="primary">
                                  {`${cra?.contractorRate} €`}{' '}
                                </ColorTextBox>
                              </Box>
                            }
                          />
                          <LabelField
                            label="JUSTIFICATIF"
                            underline
                            value={
                              cra?.proof ? (
                                <Link
                                  onClick={() =>
                                    showDisplayPdfModal({
                                      fileLocation:
                                        cra?.proof?.fileLocation || '',
                                      fileName: cra?.proof?.fileName || '',
                                      layer: 102,
                                    })
                                  }
                                >
                                  <u>{cra?.proof?.fileName}</u>
                                </Link>
                              ) : (
                                'N/A'
                              )
                            }
                          />
                        </Flex>
                      </Box>
                    </Accordion>
                  </Cell>
                )}
                {mileStones && mileStones?.length > 0 && (
                  <Cell x-span={grid12}>
                    <Accordion
                      defaultOpen
                      openOnTop
                      propsBar={{
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        border: '1px solid #d0d4e3',
                      }}
                      childrenTop={
                        <Flex justifyContent="space-between" width={1 / 1}>
                          <Flex alignContent="center" alignItems="center">
                            <DutyTimeIcon
                              width={'30px'}
                              height={'30px'}
                              fill={Theme?.colors?.primary?.default}
                            />
                            <Text ml={10} variant="h2">
                              Prestation
                            </Text>
                          </Flex>
                          <Flex alignContent="center" alignItems="center">
                            <ColorTextBox variantColor="primary" ml={5}>
                              Total :
                              {getAdditionalActivitiesTotalAmount(
                                mileStones,
                                EadditionalActivityType.MILESTONE,
                                'PROVIDER'
                              )}
                              €
                            </ColorTextBox>
                          </Flex>
                        </Flex>
                      }
                      key={`MILESTONE`}
                    >
                      <Box
                        position="relative"
                        top={-5}
                        p={10}
                        borderLeft="1px solid"
                        borderRight="1px solid"
                        borderBottom="1px solid"
                        borderColor={'#d0d4e3'}
                      >
                        {mileStones?.map((mileStone, index) => {
                          return (
                            <Box width={1 / 1}>
                              <Flex
                                width={1 / 1}
                                alignItems="center"
                                alignContent="center"
                              >
                                <Box width={1 / 4}>
                                  <LabelField
                                    label="Type de prestation"
                                    value={'Prestation'}
                                    underline
                                  />
                                </Box>
                                <Box width={1 / 4}>
                                  <LabelField
                                    label="Déclaré"
                                    value={
                                      <ColorTextBox variantColor="primary">
                                        {mileStone?.milestoneAmount} €
                                      </ColorTextBox>
                                    }
                                    underline
                                  />
                                </Box>
                                <Box width={1 / 4}>
                                  <LabelField
                                    label="JUSTIFICATIF"
                                    underline
                                    value={
                                      mileStone.attachments &&
                                      mileStone.attachments?.length > 0 ? (
                                        <Link
                                          onClick={() =>
                                            showDisplayPdfModal({
                                              fileLocation:
                                                mileStone.attachments?.[0]
                                                  ?.fileLocation || '',
                                              fileName:
                                                mileStone.attachments?.[0]
                                                  ?.fileName || '',
                                              layer: 102,
                                            })
                                          }
                                        >
                                          <u>
                                            {
                                              mileStone.attachments?.[0]
                                                ?.fileName
                                            }
                                          </u>
                                        </Link>
                                      ) : (
                                        'N/A'
                                      )
                                    }
                                  />
                                </Box>
                              </Flex>
                              {index !==
                                //@ts-ignore
                                mileStone?.length - 1 && <Divider />}
                            </Box>
                          );
                        })}
                      </Box>
                    </Accordion>
                  </Cell>
                )}
                {standByDuties &&
                  standByDuties?.length > 0 &&
                  standByDuties?.map(sbd => (
                    <Cell x-span={grid12}>
                      <Accordion
                        defaultOpen
                        openOnTop
                        propsBar={{
                          borderRadius: '8px',
                          backgroundColor: 'white',
                          border: '1px solid #d0d4e3',
                        }}
                        childrenTop={
                          <Flex justifyContent="space-between" width={1 / 1}>
                            <Flex alignContent="center" alignItems="center">
                              <DutyTimeIcon
                                width={'30px'}
                                height={'30px'}
                                fill={Theme?.colors?.primary?.default}
                              />
                              <Text ml={10} variant="h2">
                                Prestation complémentaire
                              </Text>
                            </Flex>
                            <Flex alignContent="center" alignItems="center">
                              <ColorTextBox variantColor="primary" ml={5}>
                                Total :{' '}
                                {getStandByDutiesTotalAmount(
                                  //@ts-ignore
                                  sbd?.standByDutyLines,
                                  'PROVIDER'
                                )}
                                €
                              </ColorTextBox>
                            </Flex>
                          </Flex>
                        }
                        key={`STAND_BY_DUTIES`}
                      >
                        <Box
                          position="relative"
                          top={-5}
                          p={10}
                          borderLeft="1px solid"
                          borderRight="1px solid"
                          borderBottom="1px solid"
                          borderColor={'#d0d4e3'}
                        >
                          {sbd?.standByDutyLines?.map((line, index) => {
                            const formula = mission?.standByDutyFormulas?.find(
                              f => f?.uuid === line?.standByDutyFormulaLineUuid
                            );
                            return (
                              <Box width={1 / 1}>
                                <Flex
                                  width={1 / 1}
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Box width={1 / 4}>
                                    <LabelField
                                      label="Type de prestation"
                                      value={formula?.name}
                                      underline
                                    />
                                  </Box>
                                  <Box width={1 / 4}>
                                    <LabelField
                                      label="Déclaré"
                                      value={
                                        <ColorTextBox variantColor="primary">
                                          {line?.formulaParameters?.INPUT}{' '}
                                          {formula?.unit &&
                                            STAND_BY_DUTY_UNIT_TRANSLATE_FR[
                                              formula?.unit as EStandByDutyUnits
                                            ]}
                                        </ColorTextBox>
                                      }
                                      underline
                                    />
                                  </Box>
                                  <Box width={1 / 4}>
                                    <LabelField
                                      label="Total"
                                      value={
                                        <ColorTextBox variantColor="primary">
                                          {getFormulaDutyFormulaAmount(
                                            line,
                                            'PROVIDER'
                                          )}{' '}
                                          €
                                        </ColorTextBox>
                                      }
                                      underline
                                    />
                                  </Box>
                                </Flex>
                                {index !==
                                  //@ts-ignore
                                  sbd?.standByDutyLines?.length - 1 && (
                                  <Divider />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      </Accordion>
                    </Cell>
                  ))}
                {expenses &&
                  expenses?.length > 0 &&
                  expenses?.map(expenses => (
                    <Cell x-span={grid12}>
                      <Accordion
                        defaultOpen
                        openOnTop
                        propsBar={{
                          borderRadius: '8px',
                          backgroundColor: 'white',
                          border: '1px solid #d0d4e3',
                        }}
                        childrenTop={
                          <Flex justifyContent="space-between" width={1 / 1}>
                            <Flex alignContent="center" alignItems="center">
                              <FeesIcon
                                width={'30px'}
                                height={'30px'}
                                fill={Theme?.colors?.primary?.default}
                              />
                              <Text ml={10} variant="h2">
                                Frais
                              </Text>
                            </Flex>
                            <Flex alignContent="center" alignItems="center">
                              <ColorTextBox variantColor="primary" ml={5}>
                                Total :{' '}
                                {getProviderExpensesTotalAmount(expenses)} €
                              </ColorTextBox>
                            </Flex>
                          </Flex>
                        }
                        key={`EXPENSES`}
                      >
                        <Box
                          position="relative"
                          top={-5}
                          p={10}
                          borderLeft="1px solid"
                          borderRight="1px solid"
                          borderBottom="1px solid"
                          borderColor={'#d0d4e3'}
                        >
                          {expenses?.expenseLines?.map((line, index) => {
                            // const formula = mission?.standByDutyFormulas?.find(
                            //   f => f?.uuid === line?.standByDutyFormulaLineUuid
                            // );
                            return (
                              <Box width={1 / 1}>
                                <Flex
                                  width={1 / 1}
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Box width={1 / 4}>
                                    <LabelField
                                      label="Type de frais"
                                      value={
                                        EXPENSES_TYPE_TRANSLATE_FR[line?.type]
                                      }
                                      underline
                                    />
                                  </Box>
                                  <Box width={1 / 4}>
                                    <LabelField
                                      label="TOTAL"
                                      value={
                                        <ColorTextBox variantColor="primary">
                                          {line?.amount} €
                                        </ColorTextBox>
                                      }
                                      underline
                                    />
                                  </Box>
                                </Flex>
                                {index !==
                                  //@ts-ignore
                                  expenses?.expenseLines?.length - 1 && (
                                  <Divider />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      </Accordion>
                    </Cell>
                  ))}
              </Grid>
              <Flex mt={10} width={1 / 1} justifyContent={'center'}>
                <Button
                  mr={10}
                  variant="ghost"
                  onClick={() => onResolve(false)}
                >
                  <Flex alignItems="center">
                    Modifier ma déclaration
                    <Box ml={'5px'}>
                      <GoBackIcon fill={Theme?.colors?.primary?.default} />
                    </Box>
                  </Flex>
                </Button>
                <Button
                  variant="filled"
                  isLoading={isFetching}
                  onClick={() => onSubmit()}
                >
                  <Flex alignItems="center">
                    Soumettre
                    <Box ml={'5px'}>
                      <ValidateIcon fill={'white'} />
                    </Box>
                  </Flex>
                </Button>
              </Flex>
            </Box>
          </Box>
        ) : (
          <Box height={500} width={1 / 1}>
            <Flex
              display={'inline-flex'}
              flexWrap="wrap"
              height={'calc(100% - 50px)'}
              width={1 / 1}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box width={1 / 1}>
                <Text fontSize={40} textAlign={'center'} variant="p">
                  👌
                </Text>
                {/* <Text mb={20} textAlign={'center'} variant="h3">
                  Confirmation
                </Text> */}
                <Text textAlign={'center'} variant="h3">
                  Votre déclaration a bien été transmise à nos équipes.
                </Text>
                <Text mb={20} textAlign={'center'} variant="h3">
                  Vous pouvez dès a présent soumettre vos factures.
                </Text>
                <Flex
                  borderRadius={'10px'}
                  backgroundColor="#edf3ff"
                  height={100}
                  width={1 / 1}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  {month ? (
                    <Button
                      onClick={() =>
                        history.push(
                          `/invoices/activities/billing/${missionRef}/${getHumanDate(
                            month,
                            'yyyy-MM-dd'
                          )}`
                        )
                      }
                    >
                      Déposer les factures
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        history.push(
                          `/invoices/flat-rate/activities/billing/${missionRef}`
                        )
                      }
                    >
                      Déposer les factures
                    </Button>
                  )}
                </Flex>
              </Box>
            </Flex>
            <Box cursor="pointer !important">
              <Text
                onClick={() => onResolve(false)}
                mb={20}
                textAlign={'center'}
                variant="h3"
                fontSize={14}
                color={Theme?.colors?.primary?.default}
              >
                Non merci, je déposerai les factures plus tard
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </ModalFrame>
  );
};

export const showSubmitAllActivitiesModal = create<Props>(
  SubmitAllActivitiesModal
);
