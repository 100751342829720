import { ChevronRightIcon } from 'components/icons';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FormInfo,
  FormLabel,
  Input,
  Link,
  Text,
} from 'components/ui';
import React, { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
type FormValues = {
  identifier?: string;
};
type VerifySiretFormProps = {
  defaultValues?: FormValues;
  onSubmit?: (formValues: FormValues) => any | void;
  error?: ReactNode;
  warning?: ReactNode;
  isLocked?: boolean;
  onUnlock?: () => void;
  establishmentRole?: 'PROVIDER' | 'CUSTOMER';
  required?: boolean;
  label?: string;
};

export const VerifySiretForm = (props: BoxProps & VerifySiretFormProps) => {
  const {
    defaultValues,
    onSubmit: onSubmitParent,
    error,
    warning,
    isLocked,
    onUnlock,
    establishmentRole,
    required,
    label,
  } = props;
  const [siretError, setSiretError] = useState<false | string>(false);
  const [loading, setLoading] = useState(false);
  const Theme = useTheme();
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues,
  });

  const [inputValue, setInputValue] = React.useState(
    defaultValues ? defaultValues.identifier : ''
  );
  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    if (data && data?.identifier && data?.identifier?.trim()?.length !== 14) {
      setSiretError('N° siret invalide');
    } else {
      setSiretError(false);
      onSubmitParent &&
        (await onSubmitParent({ identifier: data?.identifier?.trim() }));
    }
    setLoading(false);
  };
  return (
    // ?? {...(props as BoxProps)}
    <Box {...(props as BoxProps)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {false && (
          <Text variant="h2" mb={10}>
            Ajouter un établissement{' '}
            {establishmentRole === 'CUSTOMER' ? 'client' : 'fournisseur'}
          </Text>
        )}
        <FormLabel>
          {label ? label : 'N° SIRET (14 CHIFFRES)'}
          {required && (
            <span
              style={{ color: Theme.colors.primary.default, marginLeft: 5 }}
            >
              *
            </span>
          )}
        </FormLabel>
        <Flex width={3 / 3}>
          <Input
            isFullWidth
            {...register('identifier')}
            type="number"
            variantColor={error ? 'error' : warning ? 'warning' : undefined}
            placeholder="n° SIRET (14 chiffres)"
            autoComplete="siret"
            onChange={e => {
              // @ts-ignore
              //e.target.value = e.target.value.trim();
              // @ts-ignore
              setInputValue(e.target.value);
            }}
            isDisabled={!!isLocked}
          />
          {!isLocked ? (
            <Button
              borderRadius="0px !important"
              borderTopRightRadius="4px !important"
              borderBottomRightRadius="4px !important"
              data-cy="verify-siret-submit"
              type="button"
              onClick={() => onSubmit({ identifier: inputValue })}
              isLoading={loading}
              variant={error ? 'error' : warning ? 'warning' : undefined}
            >
              <ChevronRightIcon color="#fff" />
            </Button>
          ) : (
            <Button
              key="modify"
              type="button"
              onClick={onUnlock}
              variant={error ? 'error' : warning ? 'warning' : undefined}
            >
              Modifier
            </Button>
          )}
        </Flex>
        {siretError && <FormInfo variantColor="error">{siretError}</FormInfo>}
        {error && <FormInfo variantColor="error">{error}</FormInfo>}
        {warning && <FormInfo variantColor="warning">{warning}</FormInfo>}
        {false && (
          <Box mt="20px">
            <Link>Établissement domicilié à l'etranger ?</Link>
          </Box>
        )}
      </form>
    </Box>
  );
};
