import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ActivitiesFlatRateList } from './List';
import { ActivitiyFlateRateDetails } from './Details';
import { InvoicesDetails } from './InvoicesDetails';
import { Container } from 'components/ui';

export const ActivitiesFlatRate = () => {
  return (
    <Switch>
      <Route path="/invoices/flat-rate/activities/billing/:missionRef">
        <Container>
          <InvoicesDetails />
        </Container>
      </Route>
      <Route path="/invoices/flat-rate/activities/list/:filter(pending|terminated)">
        <Container>
          <ActivitiesFlatRateList />
        </Container>
      </Route>
      <Route path="/invoices/flat-rate/activities/:missionRef">
        <Container>
          <ActivitiyFlateRateDetails />
        </Container>
      </Route>
    </Switch>
  );
};
