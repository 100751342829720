import { NotFoundIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Box, Flex, Link, Text } from 'components/ui';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

type PropsTypes = {
  customText?: string | ReactNode;
  customML?: string;
};
export const PageNotFound = ({ customText, customML }: PropsTypes) => {
  const Theme = useTheme();
  return (
    <Flex
      top={0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Box ml={customML ? customML : '320px'}>
          <Text variant="h1" color={Theme.colors.primary.default}>
            Oups !
          </Text>
          <Text variant="p">
            {customText
              ? customText
              : 'La page que vous cherchez semble introuvable'}
            .
          </Text>
          <Text variant="p">
            Vous avez besoin d’aide ?{' '}
            <Link onClick={() => showContactUsModal()}>Contactez-nous</Link>
          </Text>
        </Box>
      </Flex>
      <NotFoundIcon width={703} height={647} />
    </Flex>
  );
};
export default PageNotFound;
