import React from 'react';
import { REACT_APP_SEED_STAGE_NAME } from '@commons';
import { Box, Text } from 'components/ui';

type StageRibbonProps = {};

export const StageRibbon: React.FC<
  React.PropsWithChildren<StageRibbonProps>
> = props => {
  const getBackGroundColor = () => {
    switch (REACT_APP_SEED_STAGE_NAME) {
      case 'localhost':
        return '#858b9c';
      case 'testing':
        return 'black';
      case 'develop':
        return 'green';
      case 'staging':
        return 'orange';
      case 'preprod':
        return 'red';
      default:
        return '#858b9c';
    }
  };
  const bk = getBackGroundColor();
  return (
    <Box
      width={200}
      zIndex={100}
      textAlign={'center'}
      backgroundColor={bk}
      p={'5px'}
      position={'fixed'}
      top={25}
      left={-59}
      style={{ textTransform: 'uppercase', transform: 'rotate(-45deg)' }}
    >
      <Text color="white">{REACT_APP_SEED_STAGE_NAME}</Text>
    </Box>
  );
};

StageRibbon.displayName = 'StageRibbon';

export default StageRibbon;
