import { ECraStatus } from '@freelancelabs/teoreme-commons';
import { useCraUpdateOne } from '@commons';
import { useShowMessage } from 'hooks';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFetchData } from './api';
import { getFilterByUrl, getHiddenColumns } from './helpers';
import { useCraListStore } from 'store/cra';
import { columns, settingsByFilter } from './TableConfig';
import { snakeCase } from 'lodash';
export const useLogic = () => {
  const theme = useTheme();
  const t = useTranslationFormat();
  const { filter } = useParams<{ filter: string }>();

  useSetCrumbs(
    [filter],
    [
      { label: 'CRA & Factures', path: '/invoices' },
      { label: 'CRA', path: '/invoices' },
      {
        label: t(
          `status:${snakeCase(filter).toLocaleUpperCase()}`,
          'capitalize-first'
        ),
        path: '/invoices/cra/' + filter,
      },
    ]
  );

  const {
    updateCraStore,
    resetCraStore,
    startDate,
    endDate,
    selectedStatus,
    searchQuery,
    pageIndex,
    selectedRowIds,
    isOpen,
  } = useCraListStore();

  const filterStatus = getFilterByUrl(filter);
  const showMessage = useShowMessage();
  const { mutateAsync: updateCra } = useCraUpdateOne();
  const windowSize = useWindowSize();

  const history = useHistory();

  React.useEffect(() => {
    return history.listen(history => {
      const filter = history.pathname.split('/').slice(-1).pop() || '';
      const filterStatus = getFilterByUrl(filter);

      const columnsHidden = settingsByFilter?.[filterStatus]?.columnsHidden;
      resetCraStore({ columnsHidden });
    });
  }, [history]);

  const [fetchInfo, setFetchInfo] = React.useState({
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'month', desc: true }],
  });

  const { invoices, data, isFetching, status } = useFetchData(fetchInfo);

  const selectableCra = data.cras
    .filter(({ state }) => state === ECraStatus.TO_BE_SUBMITTED)
    .map(({ refCra }) => refCra);

  const isSelectAll = selectableCra.length ? undefined : 'selection';

  return {
    t,
    updateCraStore,
    theme,

    columnsHidden: [
      ...settingsByFilter?.[filterStatus]?.columnsHidden,
      isSelectAll,
      // @ts-ignore
      ...getHiddenColumns(windowSize.width),
    ],
    columns,
    isFetching,
    data,
    invoices: invoices?.invoices || [],
    status,
    setFetchInfo,
    updateCra,
    showMessage,
    selectedRowIds,

    filterStatus,
    searchQuery,
    startDate,
    endDate,
    selectedStatus,
    selectableCra,
    isOpen,

    pageIndex,
    history,
  };
};
