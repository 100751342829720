import { create } from 'zustand';

type State = {
  pageIndex: number;
  columnsHidden: Array<any>;
  updateRCPROListStore: (state: Partial<State>) => void;
  resetRCPROListStore: (state: Partial<State>) => void;
  filterObject: any;
  uuid: string | undefined;
};

const initialStates = {
  pageIndex: 0,
  columnsHidden: [],
  filterObject: undefined,
  uuid: undefined,
};

const store = (set: any) => ({
  ...initialStates,
  updateRCPROListStore: (state: Partial<State>) => set(state),
  resetRCPROListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useRCPROListStore = create<State>(store);
