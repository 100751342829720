import {
  Box,
  Flex,
  LoaderLine,
  Pagination,
  Spinner,
  Text,
} from 'components/ui';
import React from 'react';
import {
  useAsyncDebounce,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import styled from 'styled-components';

const Styles = styled.div`
  width: 100%;
  table {
    border: none;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    thead th {
    }
    tbody td {
      border-top: 1px solid #d0d4e3;
      border-bottom: 1px solid #d0d4e3;
    }
    tbody td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: 1px solid #d0d4e3;
    }
    tbody td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-right: 1px solid #d0d4e3;
    }
    tbody tr {
      height: 45px;
    }
  }
`;

export type SortOrder = 'asc' | 'desc';

type TableProps = {
  columns: Array<any>;
  data: Array<Object>;
  controlledPageCount: number;
  controlledPageIndex: number;
  isLoading: boolean;
  isFetching: boolean;
  onFetchData: (options: any) => void;
  setPageIndex: (pageIndex: number) => void;
  setSelectedRowsId: (selectedRowIds: any) => void;
  initialSortBy?: Array<any>;
  controlledHiddenColumns?: Array<any>;
  totalCount: number;
  labelData: string;
  identifier: string;
  disableNoDataText?: boolean;
  disablePagination?: boolean;
};

const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  columns,
  data,
  onFetchData,
  controlledPageCount,
  controlledPageIndex,
  controlledHiddenColumns,
  totalCount,
  labelData,
  identifier,

  isLoading,
  isFetching,
  setPageIndex,
  setSelectedRowsId,
  initialSortBy,
  disableNoDataText,
  disablePagination,
}) => {
  const {
    //  base
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // others
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      // autoResetSelectedRows: false,
      pageCount: controlledPageCount,
      initialState: {
        sortBy: initialSortBy,
      },
      getRowId: React.useCallback((row: any) => row[identifier], []),

      useControlledState: state => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: controlledPageIndex,
            hiddenColumns: controlledHiddenColumns,
          }),
          [state, controlledPageIndex, controlledHiddenColumns]
        );
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const pagination = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    pageIndex,
    gotoPage: setPageIndex,
    setPageSize,
    pageSize,
    labelData,
    totalCount,
  };

  // Debounce our onFetchData call for 100ms
  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 400);

  React.useEffect(() => {
    onFetchDataDebounced({ pageSize, sortBy });
  }, [onFetchDataDebounced, pageSize, sortBy]);

  React.useEffect(() => {
    setSelectedRowsId(selectedRowIds);
  }, [selectedRowIds]);
  const convertAlignToFlex = (textAlign?: string) => {
    if (textAlign) {
      if (textAlign === 'left') {
        return 'flex-start';
      }
      if (textAlign === 'center') {
        return 'center';
      }
      if (textAlign === 'right') {
        return 'flex-end';
      }
    } else {
      return 'flex-start';
    }
  };
  if (isLoading) {
    return (
      <Flex
        top={120}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  }

  if (!data.length) {
    return (
      <Flex
        top={35}
        left={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {!disableNoDataText && (
          <Text variant="h3">Vous n’avez pas encore de {labelData}.</Text>
        )}
      </Flex>
    );
  }

  return (
    <Styles>
      {isFetching && <LoaderLine isLoading={true} />}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    textAlign: column.id === 'actions' ? 'center' : 'left',
                    cursor:
                      column.id !== 'selection' && column.id !== 'actions'
                        ? 'pointer'
                        : 'initial',
                  }}
                  {...column.getHeaderProps()}
                  key={index}
                >
                  <Flex
                    pl={'20px'}
                    pr={'20px'}
                    width={1 / 1}
                    //@ts-ignore
                    justifyContent={convertAlignToFlex(column?.textAlign)}
                    flexWrap="wrap"
                  >
                    <Box>
                      <Text width={1 / 1} variant="span">
                        {column.render('Header')}
                      </Text>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Box display={'inline-block'} ml={10} mb={'-4px'}>
                            ↓
                          </Box>
                        ) : (
                          <Box display={'inline-block'} ml={10} mb={'-4px'}>
                            ↑
                          </Box>
                        )
                      ) : null}
                    </Box>
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps()} key={'tbody_td' + i}>
                    <Flex
                      pl={'20px'}
                      pr={'20px'}
                      justifyContent={convertAlignToFlex(
                        //@ts-ignore
                        cell?.column?.textAlign
                      )}
                    >
                      {cell.render('Cell')}
                    </Flex>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!disablePagination && <Pagination {...pagination} />}
    </Styles>
  );
};

export default Table;
