import { Select } from 'components/ui';
import React, { ComponentPropsWithoutRef } from 'react';
import { BoxProps } from '../ui/Box';
type StaticSelectProps = {
  isClearable?: boolean;
  isDisabled?: boolean;
  value?: string | number;
  onChange?: (value: string | number) => any;
  options: { label: string; value: string | number; key?: string | number }[];
} & BoxProps &
  Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const StaticSelect = ({
  options,
  value,
  onChange,
  isDisabled = false,
  isClearable = false,
  ...props
}: StaticSelectProps) => {
  return (
    <Select
      value={options.find((option: any) => option.value === value)}
      options={options}
      onChange={(option: any) => onChange?.(option?.value)}
      isDisabled={isDisabled}
      isClearable={isClearable}
      {...props}
    />
  );
};
