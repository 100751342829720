import { showLegalNoticeModal } from 'components/modals/LegalNoticeModal';
import { Flex, Box, Text, Link } from 'components/ui';
import { FreelanceComIcon } from 'components/ui/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import styled, { useTheme } from 'styled-components';
import { CustomerTopBar } from 'components/CustomerTopBar';

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${p => p.theme.sizes.xl};
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
`;

type TemplateProps = {
  children: any;
  disableTopBar?: boolean;
};

export const CustomerTemplate = ({
  disableTopBar,
  children,
}: TemplateProps) => {
  const theme = useTheme();
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      {!disableTopBar && <CustomerTopBar />}
      <Box width={1 / 1} maxWidth={theme?.innerSize}>
        <Flex
          justifyContent={'flex-start'}
          mt={20}
          width={1 / 1}
          height={'80px'}
        >
          <Box ml={20} height={55} width={110}>
            <FreelanceComIcon />
          </Box>
        </Flex>
      </Box>

      <Container>{children}</Container>
      <Flex
        position="fixed"
        bottom={'0px'}
        height={60}
        backgroundColor="white"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        flexWrap="wrap"
        width={1 / 1}
        borderTop="1px solid #e4e6ef"
      >
        <Box>
          <Text textAlign={'center'} variant="p" color={'#819CAD'}>
            <Link fontSize={14} onClick={() => showLegalNoticeModal()}>
              Mentions légales
            </Link>{' '}
            | Vous avez besoin d’aide ? &nbsp;
            <Link fontSize={14} onClick={showContactUsModal}>
              Contactez-nous
            </Link>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

CustomerTemplate.displayName = 'CustomerTemplate';

export default CustomerTemplate;
