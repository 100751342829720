import {
  isCreditNoteInvoice,
  useInvoiceFindOne,
  useMissionFindOne,
} from '@commons';
import { EInvoiceStatus, EInvoiceType } from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { TextInformation } from 'components/TextInformation';
import { Box, CustomToolTip, SpinnerBox, Status, Text } from 'components/ui';
import { EditTimeSpentForm } from 'forms/invoice/EditTimeSpentForm';
import { CreditNoteForm } from 'forms/invoice/CreditNoteForm';
import { EditStandByDutyForm } from 'forms/invoice/EditStandByDutyForm';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { snakeCase } from 'lodash';
import { InfoIconBlue } from 'components/icons';
export const EditTimeSpent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const t = useTranslationFormat();
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const { filter } = useParams<{ filter: string }>();
  const { data: Invoice, status } = useInvoiceFindOne(id);
  const { data: mission } = useMissionFindOne(Invoice?.mission?.reference);
  const getCrumbText = () => {
    switch (Invoice?.status) {
      case EInvoiceStatus.TO_BE_FILLED:
        return 'Saisir une facture';
      case EInvoiceStatus.TO_BE_SUBMITTED:
        return 'Editer une facture';
      case EInvoiceStatus.TO_BE_VALIDATED:
        return 'Voir une facture';
      case EInvoiceStatus.VALIDATED:
        return 'Voir une facture';
      case EInvoiceStatus.ARCHIVED:
        return 'Voir une facture';
      case EInvoiceStatus.REJECTED:
        return 'Editer une facture';
      case EInvoiceStatus.CANCELLED:
        return 'Facture annulée';
      case EInvoiceStatus.PAID:
        return `Facture payée`;
      default:
        return '';
    }
  };
  useSetCrumbs(
    [Invoice],
    [
      { label: 'CRA & Factures', path: '/invoices' },
      {
        label: 'Facture',
        path: '/invoices/time-spent/to-be-submitted',
      },
      {
        label: t(
          `${t(`status:${snakeCase(filter).toLocaleUpperCase()}`)}`,
          'capitalize-first'
        ),
        path: '/invoices/time-spent/' + filter,
      },
      {
        label: getCrumbText(),
        path: `/invoices/time-spent/${filter}/${id}`,
      },
    ]
  );
  const isEditableState = () => {
    if (isCreditNoteInvoice(Invoice?.invoiceType as EInvoiceType)) {
      switch (Invoice?.status) {
        case EInvoiceStatus.VALIDATED:
          return true;
        case EInvoiceStatus.SENT:
          return false;
        case EInvoiceStatus.PAID:
          return false;
        default:
          return false;
      }
    } else {
      switch (Invoice?.status) {
        case EInvoiceStatus.TO_BE_FILLED:
          return true;
        case EInvoiceStatus.TO_BE_SUBMITTED:
          return true;
        case EInvoiceStatus.REJECTED:
          return true;
        default:
          return false;
      }
    }
  };
  const getStatus = () => {
    if (!Invoice) {
      return;
    }
    if (isCreditNoteInvoice(Invoice?.invoiceType as EInvoiceType)) {
      switch (Invoice?.status) {
        case EInvoiceStatus.VALIDATED:
          return {
            variantColor: 'grey',
            statusText: 'À saisir',
            textColor: theme.colors.grey?.default,
          };
        case EInvoiceStatus.SENT:
          return {
            variantColor: 'success',
            statusText: `Validé le ${
              Invoice?.validatedAt
                ? new Date(Invoice?.validatedAt).toLocaleDateString()
                : Invoice?.statusChangedAt
                  ? new Date(Invoice?.statusChangedAt).toLocaleDateString()
                  : 'N/A'
            }`,
            textColor: theme.colors.success.default,
          };
        case EInvoiceStatus.PAID:
          return {
            variantColor: 'success',
            statusText: `Payée ${
              Invoice?.sage?.paymentDetails?.paymentDate
                ? '- Virement émis le : ' +
                  Invoice?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()
                : ''
            }`,
            textColor: theme.colors.success.default,
          };
        default:
          return false;
      }
    } else {
      switch (Invoice?.status) {
        case EInvoiceStatus.TO_BE_FILLED:
          return {
            variantColor: 'grey',
            statusText: 'A saisir',
            textColor: theme.colors.grey.default,
          };
        case EInvoiceStatus.TO_BE_SUBMITTED:
          return {
            variantColor: 'grey',
            statusText: 'A soumettre',
            textColor: theme.colors.grey.default,
          };
        case EInvoiceStatus.TO_BE_VALIDATED:
          return {
            variantColor: 'warning',
            statusText: 'En attente de validation',
            textColor: theme.colors.warning.default,
          };
        case EInvoiceStatus.VALIDATED:
          return {
            variantColor: 'success',
            statusText: `Validé le ${
              Invoice?.validatedAt
                ? new Date(Invoice?.validatedAt).toLocaleDateString()
                : Invoice?.statusChangedAt
                  ? new Date(Invoice?.statusChangedAt).toLocaleDateString()
                  : 'N/A'
            }`,
            textColor: theme.colors.success.default,
          };
        case EInvoiceStatus.REJECTED:
          return {
            variantColor: 'error',
            statusText: `Refusé le ${
              Invoice?.statusChangedAt &&
              new Date(Invoice?.statusChangedAt).toLocaleDateString()
            }`,
            textColor: theme.colors.error.default,
          };
        case EInvoiceStatus.CANCELLED:
          return {
            variantColor: 'error',
            statusText: `Annulé le ${
              Invoice?.statusChangedAt &&
              new Date(Invoice?.statusChangedAt).toLocaleDateString()
            }`,
            textColor: theme.colors.error.default,
          };
        case EInvoiceStatus.ARCHIVED:
          return {
            variantColor: 'grey',
            statusText: `Archivé le ${
              Invoice?.statusChangedAt &&
              new Date(Invoice?.statusChangedAt).toLocaleDateString()
            }`,
            textColor: theme.colors.grey.default,
          };
        case EInvoiceStatus.PAID:
          return {
            variantColor: 'success',
            statusText: `Payée - Virement émis le : ${Invoice?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()}`,
            textColor: theme.colors.success.default,
          };

        default:
          return false;
      }
    }
  };
  const statusData = getStatus();
  return (
    <Box width={1 / 1}>
      {Invoice && isCreditNoteInvoice(Invoice?.invoiceType as EInvoiceType) ? (
        <Header tabs={false}>
          <Text mt={22} variant="h1">
            Facture d'avoir ( Annule la facture n°
            {Invoice?.originalInvoice?.providerInvoiceRef} émise précédemment).
          </Text>
          {statusData && (
            <Status variantColor={statusData.variantColor as any}>
              <Text variant="b" color={statusData.textColor}>
                {statusData.statusText}
              </Text>
              {Invoice?.status === EInvoiceStatus.PAID && (
                <Box p={10}>
                  <CustomToolTip
                    text={
                      <Text variant="p" color="white">
                        Le statut 'Payé' correspond au lettrage de votre facture
                        dans notre système comptable. Le lettrage peut être
                        effectué avec un règlement mais également avec un avoir
                        non encore imputé. Par ailleurs, la date de paiement
                        correspond à la date du lettrage dans notre système
                        comptable. Un décalage de quelques jours peut exister
                        avec la date de réception sur votre compte bancaire.
                      </Text>
                    }
                    id={Invoice?.uuid + 'payed'}
                  >
                    <InfoIconBlue fontSize={25} />
                  </CustomToolTip>
                </Box>
              )}
            </Status>
          )}
        </Header>
      ) : (
        <Header tabs={false}>
          <Text mt={22} variant="h1">
            {Invoice &&
            Invoice?.status !== EInvoiceStatus?.PAID &&
            Invoice?.status !== EInvoiceStatus?.CANCELLED &&
            Invoice?.status !== EInvoiceStatus?.ARCHIVED
              ? 'Saisir une facture'
              : 'Facture'}
          </Text>
          {statusData && (
            <Status variantColor={statusData.variantColor as any}>
              <Text variant="b" color={statusData.textColor}>
                {statusData.statusText}
              </Text>
              {Invoice?.status === EInvoiceStatus.PAID && (
                <Box p={10}>
                  <CustomToolTip
                    text={
                      <Text variant="p" color="white">
                        Le statut 'Payé' correspond au lettrage de votre facture
                        dans notre système comptable. Le lettrage peut être
                        effectué avec un règlement mais également avec un avoir
                        non encore imputé. Par ailleurs, la date de paiement
                        correspond à la date du lettrage dans notre système
                        comptable. Un décalage de quelques jours peut exister
                        avec la date de réception sur votre compte bancaire.
                      </Text>
                    }
                    id={Invoice?.uuid + 'payed'}
                  >
                    <InfoIconBlue fontSize={25} />
                  </CustomToolTip>
                </Box>
              )}
            </Status>
          )}
        </Header>
      )}
      {Invoice?.rejectReason && Invoice?.status === EInvoiceStatus.REJECTED && (
        <TextInformation variant="error" width={1 / 1} mb={60}>
          {`Cette facture a été refusé le ${new Date(
            Invoice?.statusChangedAt as Date
          ).toLocaleDateString()} pour cause de : ${Invoice?.rejectReason}`}
        </TextInformation>
      )}
      <Box mt={10}>
        {status === 'pending' && <SpinnerBox />}
        {status === 'success' &&
          Invoice?.invoiceType === EInvoiceType?.PROVIDER && (
            <EditTimeSpentForm
              defaultValues={Invoice}
              editable={isEditableState()}
            />
          )}
        {status === 'success' &&
          Invoice?.invoiceType === EInvoiceType?.PROVIDER_CREDIT_NOTE && (
            <CreditNoteForm
              defaultValues={Invoice}
              editable={isEditableState()}
            />
          )}
        {status === 'success' &&
          (Invoice?.invoiceType === EInvoiceType?.PROVIDER_STAND_BY_DUTY ||
            Invoice?.invoiceType ===
              EInvoiceType?.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE) && (
            <EditStandByDutyForm
              defaultValues={Invoice}
              mission={mission}
              editable={isEditableState()}
            />
          )}
        {status === 'success' &&
          Invoice?.invoiceType === EInvoiceType?.PROVIDER_EXPENSE && (
            <EditStandByDutyForm
              defaultValues={Invoice}
              mission={mission}
              editable={isEditableState()}
            />
          )}
        {status === 'success' &&
          Invoice?.invoiceType ===
            EInvoiceType?.PROVIDER_EXPENSE_CREDIT_NOTE && (
            <EditStandByDutyForm
              defaultValues={Invoice}
              mission={mission}
              editable={isEditableState()}
            />
          )}
        {status === 'success' &&
          Invoice?.invoiceType === EInvoiceType?.PROVIDER_MILESTONE && (
            <EditStandByDutyForm
              defaultValues={Invoice}
              mission={mission}
              editable={isEditableState()}
            />
          )}
        {status === 'success' &&
          Invoice?.invoiceType ===
            EInvoiceType?.PROVIDER_MILESTONE_CREDIT_NOTE && (
            <EditStandByDutyForm
              defaultValues={Invoice}
              mission={mission}
              editable={isEditableState()}
            />
          )}
      </Box>
    </Box>
  );
};

EditTimeSpent.displayName = 'EditTimeSpent';

export default EditTimeSpent;
