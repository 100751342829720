import { Header } from 'components/Header';
import { useCraFindOne, createDateToUrl } from '@commons';
import { Box, SpinnerBox, Text, Status, Flex } from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EditCRAForm } from 'forms/CRA/EditCRAForm';
import {
  ECraStatus,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { TextInformation } from 'components/TextInformation';
import { useTheme } from 'styled-components';
import { snakeCase } from 'lodash';
type EditCRAPageProps = {
  //
};

export const EditCRA: React.FC<
  React.PropsWithChildren<EditCRAPageProps>
> = () => {
  const t = useTranslationFormat();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { filter } = useParams<{ filter: string }>();
  const { data: CRA, status } = useCraFindOne(id, true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsloading] = React.useState(false);
  // const [othersCRA, setOthersCRA] = React.useState<
  //   Array<IJoinedCraWithInvoice> | false
  // >(false);
  // const findOthersCras = async () => {
  //   setIsloading(true);
  //   try {
  //     const parent = CRA?.mission?.parent || CRA?.mission?.reference;
  //     const othersCRAS = await craFindMany({
  //       filterObject: {
  //         //parentMission: CRA?.parentMission,
  //         //'mission.parent': CRA?.mission?.parent,
  //         state: {
  //           $in: [
  //             ECraStatus?.TO_BE_FILLED,
  //             ECraStatus.TO_BE_SUBMITTED,
  //             ECraStatus.REJECTED,
  //           ],
  //         },
  //         refCra: { $ne: id },
  //         $or: [
  //           { 'mission.parent': parent },
  //           {
  //             'mission.reference': parent,
  //           },
  //         ],

  //         month: CRA?.month,
  //       },
  //     });
  //     setOthersCRA(othersCRAS?.cras);
  //   } catch (e) {
  //     //
  //   }
  //   setIsloading(false);
  // };
  useSetCrumbs(
    [CRA],
    [
      { label: 'CRA & Factures', path: '/invoices' },
      { label: 'CRA', path: '/invoices/cra' },
      {
        label: t(
          `status:${snakeCase(filter).toLocaleUpperCase()}`,
          'capitalize-first'
        ),
        path: '/invoices/cra/' + filter,
      },
      {
        label: `N° ${id}`,
        path: `/invoices/cra/${filter}/${id}`,
      },
    ]
  );
  const isEditableState = (CRA: IJoinedCraWithInvoice) => {
    switch (CRA?.state) {
      case ECraStatus.TO_BE_FILLED:
        return true;
      case ECraStatus.TO_BE_SUBMITTED:
        return true;
      case ECraStatus.REJECTED:
        return true;
      default:
        return false;
    }
  };
  const getStatus = (CRA: IJoinedCraWithInvoice) => {
    switch (CRA?.state) {
      case ECraStatus.TO_BE_FILLED:
        return {
          variantColor: 'grey',
          statusText: 'A saisir',
          textColor: theme.colors.grey.default,
        };
      case ECraStatus.TO_BE_SUBMITTED:
        return {
          variantColor: 'grey',
          statusText: 'A soumettre',
          textColor: theme.colors.grey.default,
        };
      case ECraStatus.TO_BE_VALIDATED:
        return {
          variantColor: 'warning',
          statusText: 'En attente de validation',
          textColor: theme.colors.warning.default,
        };
      case ECraStatus.VALIDATED:
        return {
          variantColor: 'success',
          statusText: `Validé le ${
            CRA?.stateChangedAt &&
            new Date(CRA?.stateChangedAt).toLocaleDateString()
          }`,
          textColor: theme.colors.success.default,
        };
      case ECraStatus.REJECTED:
        return {
          variantColor: 'error',
          statusText: `Refusé le ${
            CRA?.stateChangedAt &&
            new Date(CRA?.stateChangedAt).toLocaleDateString()
          }`,
          textColor: theme.colors.error.default,
        };
      case ECraStatus.ARCHIVED:
        return {
          variantColor: 'grey',
          statusText: `Archivé le ${
            CRA?.stateChangedAt &&
            new Date(CRA?.stateChangedAt).toLocaleDateString()
          }`,
          textColor: theme.colors.grey.default,
        };
      default:
        return false;
    }
  };

  // React.useEffect(() => {
  //   if (CRA && !isLoading) {
  //     findOthersCras();
  //   }
  // }, [id, CRA]);
  if (isLoading) {
    return <SpinnerBox />;
  } else {
    // REDIRECTION TO PAGE ACTIVITIES
    if (CRA) {
      history?.push(
        `/invoices/activities/${CRA?.mission?.reference}/${createDateToUrl(
          CRA?.month
        )}`
      );
    }
  }

  const statusData = getStatus(CRA as IJoinedCraWithInvoice);
  return (
    <Box width={1 / 1}>
      <Header tabs={false}>
        <Text mb={'5px'} variant="h1">
          CRA n° {id}
        </Text>
        {statusData && (
          <Flex justifyContent={'flex-end'}>
            <Box backgroundColor="#e5f5ff " p={10} borderRadius={'5px'}>
              <Status variantColor={statusData.variantColor as any}>
                <Text variant="b" color={statusData.textColor}>
                  {statusData.statusText}
                </Text>
              </Status>
            </Box>
          </Flex>
        )}
      </Header>
      {CRA?.rejectReason && (
        <div style={{ padding: 5 }}>
          <TextInformation variant="error" width={1 / 1} mb={10}>
            {`Ce CRA a été refusé le ${new Date(
              CRA?.stateChangedAt as Date
            ).toLocaleDateString()} pour cause de : ${
              CRA?.rejectMetaData
                ? CRA?.rejectMetaData
                : ' justificatif non conforme.'
            }`}
          </TextInformation>
        </div>
      )}
      {status === 'pending' && <SpinnerBox />}
      {status === 'success' && (
        <EditCRAForm
          key={CRA?.refCra}
          redirect={true}
          defaultValues={CRA}
          editable={isEditableState(CRA as IJoinedCraWithInvoice)}
        />
      )}
    </Box>
  );
};

EditCRA.displayName = 'EditCRA';

export default EditCRA;
