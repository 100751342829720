import * as React from 'react';

function HelpOutlineIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#EDF3FF"
      />
      <path
        d="M23 30H25V28H23V30ZM24 14C18.48 14 14 18.48 14 24C14 29.52 18.48 34 24 34C29.52 34 34 29.52 34 24C34 18.48 29.52 14 24 14ZM24 32C19.59 32 16 28.41 16 24C16 19.59 19.59 16 24 16C28.41 16 32 19.59 32 24C32 28.41 28.41 32 24 32ZM24 18C21.79 18 20 19.79 20 22H22C22 20.9 22.9 20 24 20C25.1 20 26 20.9 26 22C26 24 23 23.75 23 27H25C25 24.75 28 24.5 28 22C28 19.79 26.21 18 24 18Z"
        fill="#003CC2"
      />
    </svg>
  );
}

export default HelpOutlineIcon;
