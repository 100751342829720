import React from 'react';
import ReactSelect from 'react-select';
import { Props } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { boxProperties, BoxProps } from './Box';
import CSSProperties from 'styled-components';

export const selectStyles = (theme: any, props: any) => ({
  container: (provided: typeof CSSProperties) => ({
    ...provided,
    width: '100%',
    backgroundColor: props.isDisabled
      ? theme.colors.disabled.inputBackGround
      : theme.colors.primary.inputBackGround,
    cursor: props.isDisabled ? 'not-allowed' : 'inherit',
  }),
  control: (provided: typeof CSSProperties, props: any) => ({
    ...provided,
    height: '50px',
    backgroundColor: props.isDisabled
      ? theme.colors.disabled.inputBackGround
      : theme.colors.primary.inputBackGround,
    cursor: props.isDisabled ? 'not-allowed' : 'inherit',
  }),
  option: (provided: typeof CSSProperties, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: theme.colors.text.input,
    backgroundColor: state.isSelected
      ? theme.colors.primary.inputBackGround
      : undefined,
    cursor: props.isDisabled ? 'not-allowed' : 'inherit',
  }),
  singleValue: (provided: typeof CSSProperties) => ({
    ...provided,
    fontSize: '14px',
    color: theme.colors.text.input,
    cursor: props.isDisabled ? 'not-allowed' : 'inherit',
  }),
});

const SelectStyled = styled(ReactSelect)<BoxProps>`
  ${boxProperties}
`;
export const Select = (props: BoxProps & Omit<Props, 'theme'>) => {
  const theme = useTheme();
  return (
    <SelectStyled
      //@ts-ignore
      styles={selectStyles(theme, props)}
      {...props}
    />
  );
};
