import { TopBar } from 'components/TopBar';
import { showLegalNoticeModal } from 'components/modals/LegalNoticeModal';
import { Flex, Box, Text, Spinner, Link } from 'components/ui';
import { showContactUsModal } from 'components/modals/ContactUs';
import React, { useState } from 'react';
import {
  replaceSpecificCaractereInFileName,
  downloadFile,
  FILE_GUIDE_LOCATION,
} from '@commons';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${p => p.theme.sizes.xl}px;
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
`;

type TemplateProps = {
  children: any;
};
const fileName = 'guide_utilisateur_connecteed';
export const Template = ({ children }: TemplateProps) => {
  const [loadingFile, setLoadingFile] = useState(false);
  const onDownload = async () => {
    setLoadingFile(true);
    try {
      const data = await downloadFile(FILE_GUIDE_LOCATION);
      if (data) {
        const reader = new FileReader();
        //@ts-ignore
        reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
        reader.onload = () => {
          const a = document.createElement('a');
          a.href = (reader.result as string) || '';
          a.download = fileName.endsWith('.pdf')
            ? replaceSpecificCaractereInFileName(fileName)
            : replaceSpecificCaractereInFileName(fileName) + '.pdf';
          a.click();
        };
      }
    } catch (e) {
      setLoadingFile(false);
    }
    setLoadingFile(false);
  };

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <TopBar />
      <Container>{children}</Container>
      <Flex
        position="fixed"
        bottom={'0px'}
        height={60}
        backgroundColor="white"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        flexWrap="wrap"
        width={1 / 1}
        borderTop="1px solid #e4e6ef"
      >
        <Box>
          <Text variant="p" color={'#819CAD'}>
            <Link fontSize={14} onClick={() => showLegalNoticeModal()}>
              Mentions légales
            </Link>{' '}
            | Vous avez besoin d’aide ?{' '}
            <Link fontSize={14} onClick={onDownload}>
              Télécharger le guide utilisateur Connecteed
              {!loadingFile ? '' : <Spinner size={10} />}
            </Link>
            &nbsp; ou &nbsp;
            <Link fontSize={14} onClick={showContactUsModal}>
              Contactez-nous
            </Link>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

Template.displayName = 'TopBar';

export default Template;
