import {
  MONTH_FR,
  cutLongText,
  dateAreSameMonth,
  dateIsWithinInterval,
  dateIsWithinIntervalByMonth,
  getDecimalInput,
  getEstablishmentName,
  getFirstDayOfMonth,
  getFullName,
  getHolidaysInWeek,
  getHolidaysWithinInterval,
  getHumanDate,
  getLastDayOfMonth,
  getWorkingDaysWithoutHolidays,
  queryClient,
  round,
  transformText,
  uploadFile,
  useCraUpdateOne,
  useGetHolidays,
} from '@commons';
import {
  ECraStatus,
  EFileType,
  EMissionCraValidatedBy,
  IFile,
  IHolidays,
} from '@freelancelabs/teoreme-commons';
import { TextInformation } from 'components/TextInformation';
import { CloseIcon, DownloadIcon, EuroIcon } from 'components/icons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showContactUsModal } from 'components/modals/ContactUs';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  Button,
  Cell,
  FileInput,
  Flex,
  FormControl,
  Grid,
  Input,
  LabelField,
  Link,
  Radio,
  Spinner,
  Text,
  TextAreaControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks/useShowMessage';
import bigDecimal from 'js-big-decimal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

type FormValues = any; //ICra;

type EditCRAFormProps = {
  onSubmit?: () => void;
  defaultValues?: any; //ICra;
  redirect?: boolean;
  onRefreshCRA?: () => void;
  isLast?: boolean;
  disableRightBar: boolean;
  isDisabled?: boolean;
  onClose?: () => void;
};

const holidaysText = (holidays: IHolidays[]) => {
  const holidaysMonths = holidays.map(day => getHumanDate(day?.date, 'dd/MM'));
  const resText = `${holidaysMonths.length > 1 ? 'Les' : 'Le'}
        ${holidaysMonths.join(', ').replace(/, ([^,]*)$/, ' et $1')}
        ${holidaysMonths.length > 1 ? ' sont fériés.' : ' est férié.'}
  `;
  return resText;
};

const grid2 = {
  xs: '6',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '2',
};
const grid5 = {
  xs: '12',
  sm: '5',
  md: '5',
  lg: '5',
  xl: '5',
};
const grid6 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};
const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};

export const EditCRAForm: React.FC<React.PropsWithChildren<FormValues>> = ({
  defaultValues,
  redirect,
  onRefreshCRA,
  disableRightBar,
  onSubmit: onSubmitParent,
  isDisabled,
  onClose,
}: EditCRAFormProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { data: holidays } = useGetHolidays();
  const holidaysWeekDays = holidays ? getHolidaysInWeek(holidays) : [];

  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLater, setLoadingLater] = useState(false);
  const [showFreeDaysInput, setShowFreeDaysInput] = useState(
    defaultValues?.nbFreeDays && defaultValues?.nbFreeDays > 0 ? true : false
  );
  const [showCancelFreeDaysInput, setShowCancelFreeDaysInput] = useState(false);

  const showMessage = useShowMessage();
  const { mutateAsync: updateCra } = useCraUpdateOne();
  const missionEndAt = defaultValues?.mission?.endAt;
  const missionStartAt = defaultValues?.mission?.startAt;
  const currentCraMonth = new Date(defaultValues.month);
  const firstDayOfCurrentCra = dateAreSameMonth(missionStartAt, currentCraMonth)
    ? missionStartAt
    : getFirstDayOfMonth(currentCraMonth);
  const lastDayOfMonth = getLastDayOfMonth(new Date(defaultValues.month));
  const workingDaysToMissionEnd = getWorkingDaysWithoutHolidays(
    firstDayOfCurrentCra,
    missionEndAt,
    holidays
  );

  const workingDaysToMonthEnd = getWorkingDaysWithoutHolidays(
    firstDayOfCurrentCra,
    lastDayOfMonth,
    holidays
  );
  const holidaysOfMonthWeekDays = getHolidaysWithinInterval(
    firstDayOfCurrentCra,
    lastDayOfMonth,
    holidaysWeekDays
  );

  const mission = defaultValues?.mission;
  const estCustomer = defaultValues?.estCustomer;
  const estProvider = defaultValues?.estProvider;
  const contractor = defaultValues?.contractor;
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    control,
    getValues,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      accountManagerComment: defaultValues?.accountManagerComment,
      customerComment: defaultValues?.customerComment,
      craValidatedBy: defaultValues?.craValidatedBy,
      nbWorkingDays: Number(defaultValues?.nbWorkingDays),
      nbFreeDays: defaultValues?.nbFreeDays,
      contractorRate: defaultValues?.contractorRate,
      proof: defaultValues?.proof,
      month:
        new Date(defaultValues?.month).getUTCMonth() +
        1 +
        '/' +
        new Date(defaultValues?.month).getUTCFullYear(),
      refCra: defaultValues?.refCra,
      customer: getEstablishmentName(defaultValues?.estCustomer),
      establishment: getEstablishmentName(defaultValues?.estProvider),
      contractor: `${
        Number(defaultValues?.contractor?.civility) === 1 ? 'M' : 'Mme'
      }  ${getFullName(defaultValues?.contractor)}`,
    },
  });
  const Theme = useTheme();
  const [haveComment, setHaveComment] = React.useState(
    defaultValues?.accountManagerComment || defaultValues?.customerComment
  );
  const [proofFile, setProofFile] = React.useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [proof, setProof] = React.useState<IFile | null>(
    defaultValues?.proof ? defaultValues?.proof : null
  );
  const nbWorkingDays = watch('nbWorkingDays');
  const nbFreeDays = watch('nbFreeDays');
  const contractorRate = watch('contractorRate');
  const declaredWorkingDaysInCras =
    defaultValues?.mission?.billing?.declaredWorkingDaysInCras || 0;
  const estimatedDays = defaultValues?.mission?.billing?.estimatedDays;
  // Si le status du cra est à soummettre deduire nbWorkingDays
  const nbRemainingDays = Number(
    bigDecimal.subtract(
      (estimatedDays || 0) +
        (defaultValues?.state === ECraStatus?.TO_BE_SUBMITTED
          ? (defaultValues?.nbWorkingDays as number)
          : 0),
      declaredWorkingDaysInCras
    )
  );

  const canShowAddFreeDaysButton = () => {
    if (!checkIsEditableForm()) return false;
    if (!mission?.isFreeDaysEnabled) return false;
    return true;
  };
  const checkSubmitationAvalaible = (): {
    submitionAvalaible: boolean;
    avalaibleDateOfCRA: boolean;
    avalaibleRemaningDays: boolean;
    avalaibleEndMission: boolean;
    avalaibleEndMonth: boolean;
    avalaibleFreeDays: boolean;
  } => {
    /*
    CAS 1 : nombre de jours ouvrés restant
    CAS 2 : nombre de jours estimées
    CAS 3 : nombre de jours dépasse la fin de mission
    CAS 4 : CRA avant ou après les dates de mission
    */
    let submitionAvalaible = true;
    let avalaibleDateOfCRA = true;
    let avalaibleRemaningDays = true;
    let avalaibleEndMission = true;
    let avalaibleEndMonth = true;
    let avalaibleFreeDays = true;
    if (
      !dateIsWithinIntervalByMonth(
        currentCraMonth,
        missionStartAt,
        missionEndAt
      )
    ) {
      avalaibleDateOfCRA = false;
      submitionAvalaible = false;
    }
    if (Number(nbFreeDays) > nbWorkingDays) {
      avalaibleFreeDays = false;
      submitionAvalaible = false;
    }
    if (nbWorkingDays > nbRemainingDays) {
      avalaibleRemaningDays = false;
      submitionAvalaible = false;
    }
    if (nbWorkingDays > workingDaysToMonthEnd) {
      avalaibleEndMonth = false;
      submitionAvalaible = false;
    }
    if (nbWorkingDays > workingDaysToMissionEnd) {
      avalaibleEndMission = false;
      submitionAvalaible = false;
    }
    return {
      submitionAvalaible,
      avalaibleDateOfCRA,
      avalaibleRemaningDays,
      avalaibleEndMission,
      avalaibleEndMonth,
      avalaibleFreeDays,
    };
  };
  const submitationAvalaible = checkSubmitationAvalaible();
  const stepperPercent = () => {
    const percent = (declaredWorkingDaysInCras / estimatedDays) * 100;
    if (percent > 100) {
      return '100%';
    }
    return `${percent}%`;
  };
  const isMissionLastMonth = () => {
    return (
      dateIsWithinInterval(currentCraMonth, missionStartAt, missionEndAt) &&
      dateAreSameMonth(firstDayOfCurrentCra, missionEndAt)
    );
  };
  const handleChangeProofFile = async (files: File[]) => {
    setProofFile(files[0]);
    setLoadingFile(true);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        fileType: EFileType.CRA,
        //@ts-ignore
        craRef: defaultValues.refCra,
        missionRef: defaultValues.mission.reference,
        actionType: 'upload',
      });
      const proof = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag,
      };
      setValue('proof', proof);
    } catch (e) {
      //
    }
    setLoadingFile(false);
  };
  const onSaveLater = async (fromUpdateService?: boolean) => {
    const formValues = getValues();
    setLoadingLater(true);

    if (formValues?.nbWorkingDays) {
      try {
        const updateCRAResponse = await updateCra({
          refCras: [formValues.refCra],
          cra: {
            customerComment:
              craValidatedBy === EMissionCraValidatedBy?.CUSTOMER_CONTACT
                ? formValues?.customerComment
                : '',
            accountManagerComment:
              craValidatedBy === EMissionCraValidatedBy?.ACCOUNT_MANAGER
                ? formValues?.accountManagerComment
                : '',
            craValidatedBy: formValues?.craValidatedBy,
            nbWorkingDays:
              formValues?.nbWorkingDays && formValues?.nbWorkingDays !== null
                ? formValues?.nbWorkingDays
                : undefined,
            nbFreeDays: formValues?.nbFreeDays,
            proof: formValues?.proof?.fileLocation
              ? formValues?.proof
              : undefined,
            // fromUpdateService to update data but not change state
            state: ECraStatus.TO_BE_SUBMITTED,
          },
        });
        if (
          //@ts-ignore
          updateCRAResponse?.errorUpdateArray &&
          //@ts-ignore
          updateCRAResponse?.errorUpdateArray?.length > 0
        ) {
          showMessage('error', 'Une erreur est survenue sur le  CRA');
        } else {
          reset(formValues);
          await queryClient.refetchQueries({ queryKey: [formValues.refCra] });
          await queryClient.invalidateQueries({ queryKey: ['Cras'] });
          if (redirect && !fromUpdateService) {
            history.push('/invoices/cra/to-be-submitted');
          }
        }
        if (onClose) {
          onClose();
        }
      } catch (e) {
        //
      }
    } else {
      await queryClient.refetchQueries({ queryKey: [formValues.refCra] });
      await queryClient.invalidateQueries({ queryKey: ['Cras'] });
    }

    setLoadingLater(false);
  };
  const enableValidationMode = defaultValues?.mission?.craValidatedBy?.find(
    (value: any) => value === EMissionCraValidatedBy?.CUSTOMER_CONTACT
  );
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    try {
      const updateCRAResponse = await updateCra({
        refCras: [formValues.refCra],
        cra: {
          customerComment:
            craValidatedBy === EMissionCraValidatedBy?.CUSTOMER_CONTACT
              ? formValues?.customerComment
              : '',
          accountManagerComment:
            craValidatedBy === EMissionCraValidatedBy?.ACCOUNT_MANAGER
              ? formValues?.accountManagerComment
              : '',
          craValidatedBy: formValues?.craValidatedBy,
          nbWorkingDays:
            formValues?.nbWorkingDays && formValues?.nbWorkingDays !== null
              ? formValues?.nbWorkingDays
              : undefined,
          nbFreeDays: formValues?.nbFreeDays,
          proof: formValues?.proof?.fileLocation
            ? formValues?.proof
            : undefined,
          state: ECraStatus.TO_BE_VALIDATED,
        },
      });
      reset(formValues);
      if (
        //@ts-ignore
        updateCRAResponse?.errorUpdateArray &&
        //@ts-ignore
        updateCRAResponse?.errorUpdateArray?.length > 0
      ) {
        showMessage(
          'error',
          'Une erreur est survenue lors de la soumission du CRA'
        );
      } else {
        showMessage(
          'success',
          'Votre CRA a bien été envoyé à votre conseiller Connecteed pour validation.'
        );
        // const invoiceProvider = await invoiceFindMany({
        //   filterObject: {
        //     cra: defaultValues?.refCra,
        //     invoiceType: 'PROVIDER',
        //     status: EInvoiceStatus?.TO_BE_FILLED,
        //   },
        //   limit: 10,
        //   skip: 0,
        // });
        await queryClient.refetchQueries({ queryKey: formValues.refCra });
        await queryClient.invalidateQueries({ queryKey: ['Cras'] });
        if (onClose) {
          onClose();
        }
        // if (Number(formValues?.nbWorkingDays) !== 0) {
        //   showDialogModal({
        //     title: 'Dépôt de votre facture',
        //     text: 'Vous venez de soumettre votre CRA, souhaitez vous maintenant déposer votre facture ? ',
        //     confirmLabel: 'Oui',
        //     cancelLabel: 'Plus tard',
        //   })?.then(action => {
        //     if (action) {
        //       history.push(
        //         `/invoices/time-spent/to-be-submitted/${invoiceProvider?.invoices?.[0]?.uuid}`
        //       );
        //     } else {
        //       history.push('/invoices/cra/to-be-validated');
        //     }
        //   });
        // } else {
        //   history.push('/invoices/cra/to-be-validated');
        // }
      }

      if (onRefreshCRA) {
        onRefreshCRA();
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };
  const getIsDisabledForm = () => {
    if (isDisabled) {
      return true;
    }
    if (!nbWorkingDays || loadingFile) {
      return true;
    }
    if (!submitationAvalaible?.submitionAvalaible) return true;
  };
  const checkIsEditableForm = () => {
    if (isDisabled) {
      return false;
    }
    switch (defaultValues.state) {
      case ECraStatus.TO_BE_FILLED:
        return true;
      case ECraStatus.TO_BE_SUBMITTED:
        return true;
      case ECraStatus.TO_BE_VALIDATED:
        return false;
      case ECraStatus.VALIDATED:
        return false;
      case ECraStatus.ARCHIVED:
        return false;
      case ECraStatus.REJECTED:
        return true;
    }
  };
  const checkDisplayMessage = () => {
    if (defaultValues) {
      switch (defaultValues.state) {
        case ECraStatus.TO_BE_FILLED:
          return true;
        case ECraStatus.REJECTED:
          return true;
        case ECraStatus.TO_BE_SUBMITTED:
          return true;
        case ECraStatus.TO_BE_VALIDATED:
          return false;
        case ECraStatus.VALIDATED:
          return false;
        case ECraStatus.ARCHIVED:
          return false;
      }
    }
  };
  const displayMessage = checkDisplayMessage();
  const isEditable = checkIsEditableForm();
  const craValidatedBy = watch('craValidatedBy');
  const customMessageForProvider =
    defaultValues?.mission?.customer?.customMessageForProvider ||
    defaultValues?.estCustomer?.customer?.customMessageForProvider;
  const stepperWidth = stepperPercent();
  const amount = getDecimalInput(
    ((nbWorkingDays || 0) - (nbFreeDays ?? 0)) * contractorRate,
    3
  );
  const textMonth = `${transformText(
    MONTH_FR[new Date(defaultValues?.month)?.getUTCMonth()],
    'capitalize-first'
  )}  ${new Date(defaultValues?.month)?.getUTCFullYear()}`;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
        <Grid cols={12}>
          <Cell
            x-span={
              disableRightBar
                ? {
                    xs: '12',
                    sm: '12',
                    md: '12',
                    lg: '12',
                    xl: '12',
                  }
                : {
                    xs: '12',
                    sm: '12',
                    md: '8',
                    lg: '8',
                    xl: '8',
                  }
            }
            mb={20}
            pr={10}
          >
            <Box mb={20}>
              <Flex
                width={1 / 1}
                zIndex={100}
                justifyContent="space-around"
                borderBottom={'12px solid #eaeefc'}
                borderRadius={'6px'}
              >
                <Box
                  position="absolute"
                  left={0}
                  bottom={-12}
                  //@ts-ignore
                  width={stepperWidth}
                  borderRadius={'6px'}
                  borderBottom={`12px solid ${Theme.colors.primary.default}`}
                />
              </Flex>
              <Text mt={20} width={1 / 1}>
                Vous avez consommé {round(declaredWorkingDaysInCras, 3)} jours
                sur {estimatedDays} jours prévus sur cette mission. Si besoin,
                n’hésitez pas à &nbsp;
                <Link fontSize={14} onClick={() => showContactUsModal()}>
                  {' '}
                  contacter{' '}
                </Link>
                &nbsp; votre chargé de compte Freelance.com
              </Text>
              {!submitationAvalaible?.avalaibleEndMonth && (
                <Text
                  color={Theme?.colors?.warning?.default}
                  width={1 / 1}
                  mb={20}
                  mt={20}
                >
                  Vous ne pouvez pas déclarer plus de {workingDaysToMonthEnd}{' '}
                  jours travaillés pour ce CRA. Si besoin, merci de nous
                  contacter directement.
                </Text>
              )}
              {!submitationAvalaible?.avalaibleFreeDays && (
                <Text
                  color={Theme?.colors?.warning?.default}
                  width={1 / 1}
                  mb={20}
                  mt={20}
                >
                  le nombre de jours gratuits ({nbFreeDays}) ne peut pas être
                  superieur au nombre jours travaillés ({nbWorkingDays}) pour ce
                  CRA.{' '}
                </Text>
              )}
              {displayMessage &&
                !submitationAvalaible?.avalaibleRemaningDays && (
                  <Text
                    color={Theme?.colors?.warning?.default}
                    width={1 / 1}
                    mb={20}
                    mt={20}
                  >
                    Le nombre de jours saisi ({nbWorkingDays}) est supérieur au
                    nombre de jour restant à facturer sur la mission (
                    {nbRemainingDays}).
                  </Text>
                )}
              {displayMessage && !submitationAvalaible?.avalaibleDateOfCRA && (
                <Text
                  color={Theme?.colors?.error?.default}
                  width={1 / 1}
                  mb={20}
                  mt={20}
                >
                  {`Ce CRA ne peut pas être saisi car il est postérieur à la date de fin de mission.`}
                </Text>
              )}
              {displayMessage &&
                !submitationAvalaible?.avalaibleEndMission &&
                isMissionLastMonth() && (
                  <TextInformation variant="error" width={1 / 1} mb={20}>
                    Vous ne pouvez pas déclarer plus de{' '}
                    {workingDaysToMissionEnd} jours travaillés pour ce CRA car
                    la mission se termine le{' '}
                    {getHumanDate(defaultValues?.mission?.endAt)}. Si besoin,
                    merci de nous contacter directement.
                  </TextInformation>
                )}
            </Box>
            <Grid cols={12}>
              <Cell x-span={showFreeDaysInput ? grid5 : grid12}>
                <FormControl
                  required={isEditable}
                  label="Nb. de jours travaillés"
                  errorMessage={errors?.nbWorkingDays?.message}
                  warningMessage={
                    nbWorkingDays === '0'
                      ? 'Aucune facture ne sera crée chez connecteed pour tout CRA fournisseur validé à 0j travaillés'
                      : false
                  }
                >
                  <>
                    <Input
                      data-cy="workingDaysInput"
                      isDisabled={
                        !isEditable || !submitationAvalaible?.avalaibleDateOfCRA
                      }
                      isFullWidth
                      type="number"
                      maxDecimal={3}
                      min="0"
                      step={'0.001'}
                      {...register('nbWorkingDays', {
                        required: 'Ce champ est obligatoire',
                      })}
                    />
                    {canShowAddFreeDaysButton() && !showCancelFreeDaysInput && (
                      <Box mt="10px">
                        <Link
                          iconLeft={<AddIcon />}
                          onClick={() => {
                            setShowFreeDaysInput(true);
                            setShowCancelFreeDaysInput(true);
                          }}
                        >
                          Ajouter des jours gratuits
                        </Link>
                      </Box>
                    )}
                  </>
                </FormControl>
              </Cell>
              {showFreeDaysInput && (
                <>
                  <Cell x-span={grid2} pt={30} textAlign="center">
                    <Text variant="span">
                      <b>dont</b>
                    </Text>
                  </Cell>

                  <Cell x-span={grid5}>
                    <FormControl
                      required={isEditable}
                      label="Nb. de jours offerts"
                      errorMessage={errors?.nbWorkingDays?.message}
                      warningMessage={
                        nbWorkingDays === '0'
                          ? 'Aucune facture ne sera crée chez connecteed pour tout CRA fournisseur validé à 0j travaillés'
                          : false
                      }
                    >
                      <>
                        <Input
                          data-cy="freedaysInput"
                          isDisabled={
                            !isEditable ||
                            !submitationAvalaible?.avalaibleDateOfCRA
                          }
                          isFullWidth
                          type="number"
                          maxDecimal={3}
                          min="0"
                          step={'0.001'}
                          {...register('nbFreeDays', {
                            required: 'Ce champ est obligatoire',
                          })}
                        />
                        {isEditable && (
                          <Box mt="10px">
                            <Link
                              iconLeft={<CloseIcon />}
                              onClick={() => {
                                setValue(
                                  'nbFreeDays',
                                  defaultValues?.nbFreeDays ? 0 : undefined
                                );
                                setShowFreeDaysInput(false);
                                setShowCancelFreeDaysInput(false);
                              }}
                            >
                              Supprimer les jours gratuits
                            </Link>
                          </Box>
                        )}
                      </>
                    </FormControl>
                  </Cell>
                </>
              )}

              <Cell x-span={grid6}>
                <FormControl
                  label="TJM HT"
                  errorMessage={errors?.contractorRate?.message}
                >
                  <Input
                    icon={<EuroIcon style={{ marginRight: 10 }} />}
                    isDisabled
                    pr={10}
                    isFullWidth
                    {...register('contractorRate')}
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid6}>
                <FormControl
                  label="MONTANT HT ASSOCIÉ"
                  errorMessage={errors?.contractorRate?.message}
                >
                  <Input
                    icon={<EuroIcon style={{ marginRight: 10 }} />}
                    isDisabled
                    isFullWidth
                    value={amount}
                    name="amount"
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid12}>
                {enableValidationMode && (
                  <FormControl required label="Mode de validation">
                    <Flex
                      flexWrap={'wrap'}
                      justifyContent={'flex-start'}
                      mt={20}
                      width={1 / 1}
                    >
                      <Box width={1 / 1}>
                        <Radio
                          disabled={
                            !isEditable ||
                            !submitationAvalaible?.avalaibleDateOfCRA
                          }
                          {...register('craValidatedBy', {
                            required: 'Ce champ est requis',
                          })}
                          value={EMissionCraValidatedBy.CUSTOMER_CONTACT}
                        >
                          <Text
                            fontWeight={'bold'}
                            color={Theme?.colors?.primary?.default}
                          >
                            Je fais valider numériquement ce CRA par le client
                          </Text>
                        </Radio>
                      </Box>
                      <Box width={1 / 1}>
                        <Radio
                          disabled={
                            !isEditable ||
                            !submitationAvalaible?.avalaibleDateOfCRA
                          }
                          {...register('craValidatedBy', {
                            required: 'Ce champ est requis',
                          })}
                          value={EMissionCraValidatedBy.ACCOUNT_MANAGER}
                        >
                          <Text
                            fontWeight={'bold'}
                            color={Theme?.colors?.primary?.default}
                          >
                            Je joins un justificatif de CRA approuvé par le
                            client
                          </Text>
                        </Radio>
                      </Box>
                    </Flex>
                  </FormControl>
                )}
              </Cell>
              <Cell x-span={grid12}>
                <FormControl
                  label={
                    <Flex>
                      <Text fontSize={11}>
                        {isEditable
                          ? 'Importer un justificatif'
                          : 'justificatif'}
                      </Text>
                    </Flex>
                  }
                  errorMessage={errors?.proof?.message}
                >
                  {isEditable ? (
                    <FileInput
                      isDisabled={!isEditable}
                      label=""
                      accept=".pdf"
                      subLabel=""
                      onSelect={handleChangeProofFile}
                    >
                      {loadingFile && <Spinner />}
                      <Link iconLeft={<DownloadIcon />}>
                        {proofFile || proof?.fileName
                          ? `Modifier le fichier ${
                              proofFile?.name
                                ? cutLongText(proofFile.name, 20)
                                : cutLongText(
                                    proof && proof.fileName
                                      ? proof?.fileName
                                      : '',
                                    20
                                  )
                            }`
                          : 'Importer un document'}
                      </Link>
                    </FileInput>
                  ) : (
                    <Box pr={20}>
                      <Flex
                        pl={'10px'}
                        justifyContent="flex-start"
                        alignContent="center"
                        alignItems="center"
                        border={`1px solid ${theme.colors.primary.inputBorder}`}
                        height={45}
                        borderRadius={5}
                        backgroundColor="rgba(182,198,238,0.5)"
                      >
                        {proof ? (
                          <Link
                            onClick={() =>
                              showDisplayPdfModal({
                                fileLocation: proof?.fileLocation || '',
                                fileName: proof?.fileName || '',
                                layer: 102,
                              })
                            }
                          >
                            <u>{proof?.fileName}</u>
                          </Link>
                        ) : (
                          <Text>Aucun justificatif</Text>
                        )}
                      </Flex>
                    </Box>
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid12}>
                {haveComment ? (
                  <FormControl label="Commentaire envoyé avec votre CRA">
                    <>
                      {craValidatedBy ===
                        EMissionCraValidatedBy?.CUSTOMER_CONTACT && (
                        <TextAreaControlled
                          minRows={3}
                          name={'customerComment'}
                          control={control}
                          placeholder={
                            'Ajouter un commentaire destiné à votre client'
                          }
                        />
                      )}
                      {craValidatedBy ===
                        EMissionCraValidatedBy?.ACCOUNT_MANAGER && (
                        <TextAreaControlled
                          minRows={3}
                          name={'accountManagerComment'}
                          control={control}
                          placeholder={
                            'Ajouter un commentaire destiné aux équipes Freelance.com'
                          }
                        />
                      )}
                    </>
                  </FormControl>
                ) : isEditable ? (
                  <Link
                    iconLeft={<AddIcon />}
                    onClick={() => setHaveComment(true)}
                  >
                    Ajouter un commentaire
                  </Link>
                ) : (
                  <></>
                )}
              </Cell>
            </Grid>
          </Cell>
          <Cell
            x-span={{
              xs: '12',
              sm: '12',
              md: '4',
              lg: '4',
              xl: '4',
            }}
            mb={20}
            pl={10}
          >
            <Box hidden={disableRightBar}>
              <Text mb={20} variant="h1" color={Theme.colors.text.secondary}>
                {textMonth}
              </Text>
              {holidaysOfMonthWeekDays.length > 0 && (
                <Box
                  my={20}
                  width={1 / 1}
                  borderRadius={'5px'}
                  backgroundColor="#ffedcc"
                >
                  <Box p={10}>
                    {
                      <LabelField
                        label="Jours fériés"
                        value={holidaysText(holidaysOfMonthWeekDays)}
                        underline
                      />
                    }
                  </Box>
                </Box>
              )}
              <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
                <Box p={10}>
                  <LabelField
                    label="Référence de la mission"
                    value={mission?.displayReference}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Client"
                    value={getEstablishmentName(estCustomer)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Établissement"
                    value={getEstablishmentName(estProvider)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Intervenant"
                    value={getFullName(contractor)}
                    underline
                    mb={10}
                  />
                </Box>
              </Box>
              <Box
                mt={20}
                width={1 / 1}
                borderRadius={'5px'}
                backgroundColor="#ffedcc "
              >
                {customMessageForProvider && (
                  <Box p={10}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: customMessageForProvider,
                      }}
                    ></div>
                  </Box>
                )}
              </Box>
              {enableValidationMode && (
                <Box
                  mt={20}
                  width={1 / 1}
                  borderRadius={'5px'}
                  backgroundColor="#e5f5ff"
                >
                  <Box p={10}>
                    {craValidatedBy ===
                    EMissionCraValidatedBy.CUSTOMER_CONTACT ? (
                      <LabelField
                        label="Validation par le client"
                        value={
                          'Votre CRA sera envoyé pour validation au client. N’hésitez pas à joindre un justificatif si vous souhaitez lui apportez des précisions.'
                        }
                        underline
                      />
                    ) : (
                      <LabelField
                        label="Validation par Freelance.com"
                        value={
                          'Votre CRA sera envoyé pour validation à votre chargé de compte Freelance.com. Merci d’inclure un justificatif signé par votre client ou tout autre forme de preuve du travail réalisé.'
                        }
                        underline
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Cell>
        </Grid>
        <Grid cols={12}>
          <Cell
            x-span={{
              xs: '12',
              sm: '12',
              md: '8',
              lg: '8',
              xl: '8',
            }}
            mb={20}
          >
            <Box>
              <Flex justifyContent="flex-end" mt={20}>
                <Button
                  mr={10}
                  key="Cancel"
                  type="button"
                  onClick={() =>
                    onClose
                      ? onClose()
                      : history.push('/invoices/cra/to-be-submitted')
                  }
                  variant="ghostError"
                >
                  Annuler
                </Button>
                {isEditable && (
                  <>
                    <Button
                      onClick={() => onSaveLater()}
                      mr={10}
                      key="saveLater"
                      type="button"
                      isLoading={loadingLater}
                      isDisabled={
                        getIsDisabledForm() || loading || loadingLater
                      }
                      variant="ghost"
                    >
                      Enregistrer
                    </Button>
                    {/* <Button
                      key="submit"
                      type="submit"
                      isLoading={loading}
                      isDisabled={
                        getIsDisabledForm() || loading || loadingLater
                      }
                    >
                      Soumettre
                    </Button> */}
                  </>
                )}
              </Flex>
            </Box>
          </Cell>
        </Grid>
      </Box>
    </form>
  );
};

export default EditCRAForm;
