import { useDownloadFile, replaceSpecificCaractereInFileName } from '@commons';
import { Box, Button, Flex, Link, SpinnerBox } from 'components/ui';
import { ChevronLeftIcon } from 'components/icons';
import { useEffect, useRef, useState } from 'react';
import { create } from 'react-modal-promise';
import { useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
  body {
    overflow : hidden;
  }
`;

const FixedContainer = styled.div<{ layer?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100;
  overflow-y: auto;
  padding: 0px 0 40px 0;
  z-index: ${props => (props.layer || 0) + 101};
`;

const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
`;

const TopBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 80px;
  background-color: white;
  z-index: 200;
  display: flex;
  align-items: center;
  padding: 0 30px;
`;
type DisplayPdfModalProps = {
  layer?: number;
  fileLocation: string;
  fileName: string;
  fileUrl?: string;
  onResolve: (result?: any) => void;
  isOpen: boolean;
  onReject: (rej?: any) => void;
  confirmLabel?: string;
  onConfirm?: (data?: any) => any;
  onCancel?: (data?: any) => any;
  downloadConfirm?: () => Promise<any>;
  fromCustomer?: boolean;
  open: boolean;
  instanceId: string;
  close: (params?: any) => any | undefined;
};

export const DisplayPdfModal = ({
  onResolve,
  isOpen,
  layer,
  fileUrl,
  fileLocation,
  fileName,
  confirmLabel,
  onConfirm,
  onCancel,
  downloadConfirm,
  // IF FROM CUSTOMER USE UNSECURED DOWNLOAD FILE !!
  fromCustomer,
}: DisplayPdfModalProps) => {
  // hide on location change
  const location = useLocation();
  const isMountRef = useRef(true);
  useEffect(() => {
    if (!isMountRef.current) onResolve();
    isMountRef.current = false;
  }, [location, onResolve]);

  // load file
  const [file, setFile] = useState<string>();
  const [actionLoading, setActionLoading] = useState(false);
  const { status, data } = useDownloadFile(
    fileLocation,
    {},
    // IF FROM CUSTOMER USE UNSECURED DOWNLOAD FILE !!
    fromCustomer
  );

  useEffect(() => {
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload

      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  const loading = status === 'pending';

  const handleConfirm = async () => {
    setActionLoading(true);
    await onConfirm?.();
    setActionLoading(false);
    onResolve(true);
  };

  const onDownload = async () => {
    const confirm = !downloadConfirm || (await downloadConfirm());
    if (!confirm) return;
    const a = document.createElement('a');
    a.href = file || '';
    a.download = fileName.endsWith('.pdf')
      ? replaceSpecificCaractereInFileName(fileName)
      : replaceSpecificCaractereInFileName(fileName) + '.pdf';
    a.click();
  };
  useEffect(() => {
    if (!file && fileLocation) {
      const uploadFile = async (url: string) => {
        await fetch(url)
          .then(response => response.blob())
          .then(blob => {
            // Create a new FileReader innstance
            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
              setFile(reader.result as string);
            };
          });
      };
      uploadFile(fileLocation);
    }
  }, [file]);
  return (
    <FixedContainer layer={layer}>
      <GlobalStyle />

      {isOpen && (
        <>
          <TopBar>
            <Flex flex={1} alignItems="center">
              <Link
                color="#fff"
                iconLeft={<ChevronLeftIcon fill="#fff" />}
                onClick={() => {
                  onCancel && onCancel();
                  onResolve();
                }}
              >
                Retour
              </Link>
            </Flex>
            <Flex alignItems="center">
              <Button
                variant="ghost"
                mr={30}
                onClick={() => {
                  onCancel && onCancel();
                  onResolve();
                }}
              >
                Fermer
              </Button>
              {!loading && (
                <Button mr={30} onClick={onDownload}>
                  Télécharger
                </Button>
              )}
              {confirmLabel && (
                <Button
                  mr={30}
                  isLoading={actionLoading}
                  onClick={handleConfirm}
                >
                  {confirmLabel}
                </Button>
              )}
            </Flex>
          </TopBar>
          <Background />
          {loading && (
            <Box mt={100} zIndex={100} width="100%">
              <SpinnerBox color="#fff" />
            </Box>
          )}
          {!loading && data && (
            <FileContainer>
              <iframe
                //@ts-ignore
                src={data && (data as any)?.config?.url + '#toolbar=0'}
                frameBorder="0"
                title="iframe"
                style={{ width: '100%', height: 'calc(100vh - 80px)' }}
              ></iframe>
            </FileContainer>
          )}
        </>
      )}
    </FixedContainer>
  );
};

export const showDisplayPdfModal = create<DisplayPdfModalProps, boolean>(
  DisplayPdfModal
);
