import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { Box, CheckBox, Text, Link, Button } from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import {
  PUBLIC_APP_PROVIDER_URL,
  PUBLIC_APP_PROVIDER_NAME,
  PUBLIC_APP_CONTACT_PHONE,
  FCOM_CAPITAL,
  PUBLIC_APP_DPO_EMAIL,
} from '@commons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
};
type LinkProps = {
  href: string;
  target?: string;
};
const LinkHtml = ({ href, target = '_blank' }: LinkProps) => {
  return (
    <a href={href} target={target}>
      <Link fontSize={14}>{href}</Link>
    </a>
  );
};
export const CGUModal = ({
  onResolve,
  isOpen,
  layer,
  beforeValidation,
}: Props) => {
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const onValidate = async () => {
    setLoading(true);
    try {
      if (beforeValidation) {
        await beforeValidation();
      }
      onResolve(true);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  return (
    <ModalFrame isOpen={isOpen} width={window?.innerWidth - 40} layer={layer}>
      <Box overflowY="scroll" p={10} height={'70vh'} mt={10}>
        <Text variant="h3">Conditions générales d’utilisation</Text>
        <Box mb={20} mt={20}>
          <Text variant="p">
            <LinkHtml href={PUBLIC_APP_PROVIDER_URL} /> est une plateforme
            propriété de FREELANCE.COM Société Anonyme au capital de &nbsp;
            {FCOM_CAPITAL} euros, dont le siège social est sis Grande Arche -
            Paroi Nord, 1 parvis de la Défense 92044 Paris La Défense cedex,
            immatriculée 384 174 348 RCS Nanterre, représentée par Monsieur
            Sylvestre BLAVET, son Président Directeur Général;
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            le site Internet <LinkHtml href={PUBLIC_APP_PROVIDER_URL} /> est
            édité par Freelance.com dont le siège est situé 1 Parvis de la
            Défense 92044 Paris la Défense cedex.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">Tél. : {PUBLIC_APP_CONTACT_PHONE}</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            <LinkHtml href={PUBLIC_APP_PROVIDER_URL} />
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="h3">
            <u>OBJET DU SITE</u>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les présentes mentions légales ont pour objet de définir les
            modalités d’accès et d’utilisation du site (ci-après le Site){' '}
            <LinkHtml href={PUBLIC_APP_PROVIDER_URL} /> . Le Site a pour objet
            l’enregistrement des UTILISATEURS dans un processus « d’embarquement
            » en vue d’un référencement et la conclusion d’un contrat de
            prestations de services avec Freelance.com ou l’une de ses filiales
            selon les étapes suivantes :
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <ul>
            <Text variant="li">
              Suite à la réception d’un mail d’invitation déclenché par un
              chargé de compte du Site, l’UTILISATEUR doit créer ses
              identifiants sur le portail.
            </Text>
            <Text variant="li">
              Il renseigne son mail et son mot de passe avant le parcours
              d’embarquement en 3 étapes :
            </Text>
            <ul>
              <Text
                variant="li"
                style={{ listStyleType: 'circle', marginLeft: 20 }}
              >
                Etape 1 : L’UTILISATEUR confirme son Prénom, nom, fonction et
                téléphone (données préalablement renseignées par le chargé des
                compte du Site)
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'circle', marginLeft: 20 }}
              >
                Etape 2 : Il renseigne le SIRET de son entreprise (société,
                entreprise individuelle...), la raison social, l’adresse, pays,
                numéro de TVA, les coordonnées bancaires de son entreprise,
                transmet le RIB, et il spécifie s’il est mandataire social de la
                société ou s’il agit avec un pouvoir spécifique (délégation de
                pouvoir ou de signature), dans ce cas il transmet cette
                délégation au Site.
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'circle', marginLeft: 20 }}
              >
                Etape 3 : L’UTILISATEUR précise si ses engagements contractuels
                seront réalisés personnellement lors d’une prochaine mission
                (oui/non) ou s’il fera appel à un ou plusieurs intervenants lors
                de la prochaine mission (oui/non) par des ressources internes ou
                en sous-traitance.
              </Text>
            </ul>
          </ul>
          <Text variant="li">
            Suite à la validation de ses données, l’UTILISATEUR aura accès à un
            espace qui lui permettra de gérer personnellement ses CRA et
            factures, créer une nouvelle mission qui sera validée par le Site,
            visualiser et signer ses contrats, gérer ses établissements s’il en
            existe et d’autres documents afférents à ses missions.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR déclare avoir pris connaissance et avoir accepté sans
            réserve les présentes mentions légales, avant tout accès et
            utilisation du Site. L’UTILISATEUR déclare qu’il a toute capacité
            légale et tous moyens matériels lui permettant d’accéder et
            d’utiliser le Site.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Le Site est disponible 24h sur 24 et 7 jours sur 7, sauf cas de
            force majeure, de pannes éventuelles ou de toute opération de
            maintenance nécessaire au bon fonctionnement du Site.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les informations contenues sur ce Site sont aussi précises que
            possibles et le Site est périodiquement remis à jour, mais peut
            toutefois contenir des inexactitudes, des omissions ou des lacunes.
            En cas d’erreur ou de dysfonctionnement, l’UTILISATEUR en informe le
            Site par email en précisant sommairement le problème.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR est exclusivement responsable de toutes données et
            contenu téléchargés. En conséquence, {PUBLIC_APP_PROVIDER_NAME} ne
            peut être tenue responsable des dommages éventuellement subis le
            matériel informatique de l'UTILISATEUR ou d'une quelconque perte de
            données consécutives au téléchargement.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Aucun lien hypertexte présent sur le Site, qui renvoie à d'autres
            ressources sur le réseau Internet ne pourra de quelque manière que
            ce soit engager la responsabilité de {PUBLIC_APP_PROVIDER_NAME}.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les présente mentions légales sont régies par les lois françaises et
            toute contestation ou litiges qui pourraient naître de
            l'interprétation ou de l'exécution de celles-ci seront de la
            compétence exclusive du tribunal de commerce de Nanterre.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="h3">
            <u>CONFORMITE RGPD</u>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="h3">I - Informatique et libertés</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            1. Cette politique a notamment pour objet d’informer les
            utilisateurs, des modalités de collecte, de traitement, et
            d’utilisation de leurs données personnelles et de leurs droits en
            matière de protection des données à caractère personnel au regard
            des dispositions applicables en la matière, notamment le règlement
            n° 2016/679 du 27 avril 2016 sur la protection des données et la loi
            78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
            aux libertés dite « Informatique et libertés » telle que modifiée
            par la loi n° 2018-493 du 20 juin 2018, promulguée le 21 juin 2018.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les informations suivantes vous sont communiquées afin que vous
            puissiez prendre connaissance des engagements en matière de
            protection des données à caractère personnel de{' '}
            {PUBLIC_APP_PROVIDER_NAME}, qui agit en tant que responsable du
            traitement pour les traitements de données à caractère personnel
            évoqués dans le présent document.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            2. Qui est notre délégué à la protection des données ?1.{' '}
            {PUBLIC_APP_PROVIDER_NAME} a désigné un délégué à la protection des
            données personnelles dont les coordonnées sont les suivantes :
            Georges Cherfan ( {PUBLIC_APP_DPO_EMAIL} )
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            3. Quand traitons-nous des données ?1. Les données vous concernant
            sont notamment collectées ou traitées en tout ou partie à l’occasion
            de :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              la création de votre compte sur le site,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              l’accès à toute partie du site nécessitant une authentification,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              l’utilisation de tout service du site nécessitant une
              authentification.
            </Text>
          </ul>
          <Text>
            2. Les données sont collectées directement auprès de vous, à votre
            demande et lors de l’utilisation du site internet{' '}
            {PUBLIC_APP_PROVIDER_NAME} .
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            1. Dans le cadre de l’utilisation du site internet et des services
            associés, plusieurs types de données à caractère personnel peuvent
            être collectés.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            2. Principalement les données collectées correspondant aux
            catégories suivantes :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              données relatives à l’identité (nom, prénom, adresse, adresse mél,
              etc.) ,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              données relatives au suivi de la relation contractuelle,{' '}
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              données de connexion (date, heure, adresse Internet, protocole de
              l’ordinateur du visiteur, page consultée etc.)
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            3. Les données obligatoires sont signalées dans les formulaires de
            collecte par un astérisque. En leur absence, la prise en compte
            cette collecte pourrait ne pas être effective .
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            5. Pour quelles finalités les données sont-elles collectées ?
          </Text>
        </Box>
        <Box ml={20} mb={20} mt={20}>
          <Text variant="p">
            1. Selon les cas, {PUBLIC_APP_PROVIDER_NAME} traite vos données en
            tout ou partie pour les principales finalités suivantes :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              a gestion et le suivi de la création de compte et de la relation
              contractuelle avec Feelance.com,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              la gestion et le suivi des commandes, paiements ainsi que la
              relation contractuelle en général ,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              la gestion des comptes ,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              la gestion commerciale ,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              la mesure de la qualité et de la satisfaction le cas échéant,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              Analyses / Etudes / Rapports liés à l’activité de{' '}
              {PUBLIC_APP_PROVIDER_NAME}
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            6. Quels sont les fondements juridiques de nos traitements ?
          </Text>
          <Text ml={20} mb={20} mt={20} variant="p">
            1. Les données ainsi collectées sont indispensables à ces
            traitements et sont destinées aux services concernés de{' '}
            {PUBLIC_APP_PROVIDER_NAME}
          </Text>
          <Text ml={20} mb={20} mt={20} variant="p">
            2. Nous ne mettons en œuvre des traitements de données que si au
            moins l’une des conditions suivantes est remplie :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              votre consentement aux opérations de traitement a été recueilli ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              l’existence de notre intérêt légitime, ou de celui d’un tiers, qui
              justifie que nous mettions en œuvre le traitement de données à
              caractère personnel concerné ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              l’exécution d’un contrat (ou un contrat à conclure) qui nous lie à
              vous nécessite que nous mettions en œuvre le traitement de données
              à caractère personnel concerné ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              nous sommes tenus par des obligations légales et réglementaires
              qui nécessitent la mise en œuvre du traitement de données à
              caractère personnel concerné.
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            7. A qui sont-elles transmises ? / Qui sont les destinataires de vos
            données ?
          </Text>
          <Text ml={20} mb={20} mt={20} variant="p">
            1. Les données à caractère personnel que nous collectons, de même
            que celles qui sont recueillies ultérieurement, nous sont destinées
            en notre qualité de responsable du traitement.
          </Text>
          <Text ml={20} mb={20} mt={20} variant="p">
            2. Les données traitées sont destinées selon les cas aux personnes
            suivantes :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              notre service client,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              Partenaires contractuels et commerciaux.
            </Text>
          </ul>
          <Text ml={20} mb={20} mt={20} variant="p">
            3. Par ailleurs, les données pourront être communiquées à toute
            autorité légalement habilitée à en connaître en particulier en cas
            de réquisition judiciaire des autorités judiciaires, policières ou
            administratives.
          </Text>
          <Text ml={20} mb={20} mt={20} variant="p">
            4. Nous veillons à ce que seules les personnes habilitées puissent
            avoir accès à ces données. Nos prestataires de services peuvent être
            destinataires de ces données pour réaliser les prestations que nous
            leur confions. Certaines données personnelles peuvent être adressées
            à des tiers ou à des autorités légalement habilitées et ce pour
            satisfaire nos obligations légales, réglementaires ou
            conventionnelles.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            8. Combien de temps les données sont-elles conservées ?1.{' '}
            {PUBLIC_APP_PROVIDER_NAME}
            conserve vos données pour une durée proportionnée aux finalités pour
            lesquelles vos données ont été collectées.2. Les données collectées
            sont conservées pendant toute la durée des relations contractuelles
            et sont supprimées à la terminaison des relations contractuelles, à
            l’exception des données permettant d’établir la preuve des relations
            contractuelles et qui sont conservées jusqu’à la fin des délais de
            prescription en vigueur.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">Quels sont vos droits ?</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            1. Conformément aux dispositions du règlement n° 2016/679 du 27
            avril 2016 sur la protection des données et la loi 78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux libertés
            dite « Informatique et libertés » telle que modifiée par la loi n°
            2018-493 du 20 juin 2018, promulguée le 21 juin 2018, , vous
            disposez :* d’un droit à l’information :
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            2. Vous reconnaissez que la présente notice légale vous informe des
            finalités, du cadre légal, des intérêts, des destinataires ou
            catégories de destinataires avec lesquels sont partagées vos données
            personnelles, et de la possibilité d’un transfert de données vers un
            pays tiers ou à une organisation internationale.{' '}
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            3. En plus de ces informations et dans l’objectif de garantir un
            traitement équitable et transparent de vos données, vous déclarez
            avoir reçu des informations complémentaires concernant :
            <ul>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                a durée de conservation de vos données personnelles ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                l’existence des droits qui sont reconnus à votre bénéfice et des
                modalités de leur exercice.
              </Text>
            </ul>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            4. Si nous décidons de traiter des données pour des finalités autres
            que celles indiquées, toutes les informations relatives à ces
            nouvelles finalités vous seront communiquées.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit d’accès, d’interrogation et de rectification :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            5. Vous disposez du droit d’accéder et de faire rectifier vos
            données personnelles, que vous pouvez exercer auprès de{' '}
            {PUBLIC_APP_PROVIDER_NAME} à l’adresse suivante :{' '}
            {PUBLIC_APP_DPO_EMAIL}
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            6. A ce titre, vous avez la confirmation que vos données
            personnelles sont ou ne sont pas traitées et lorsqu’elles le sont,
            et disposez de l’accès à vos données ainsi qu’aux informations
            concernant :
            <ul>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                les finalités du traitement ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                les catégories de données personnelles concernées ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                les destinataires ou catégories de destinataires ainsi que les
                organisations internationales auxquels les données personnelles
                ont été ou seront communiquées le cas échéant, en particulier
                les destinataires qui sont établis éventuellement dans des pays
                tiers ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                lorsque cela est possible, la durée de conservation des données
                personnelles envisagée ou, lorsque ce n’est pas possible, les
                critères utilisés pour déterminer cette durée ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                l’existence du droit de demander au responsable du traitement la
                rectification ou l’effacement de vos données personnelles, du
                droit de demander une limitation du traitement de vos données
                personnelles, du droit de vous opposer à ce traitement ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                le droit d’introduire une réclamation auprès d’une autorité de
                contrôle ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                des informations relatives à la source des données quand elles
                ne sont pas collectées directement auprès des personnes
                concernées ;
              </Text>
              <Text
                variant="li"
                style={{ listStyleType: 'disc', marginLeft: 20 }}
              >
                l’existence d’une prise de décision automatisée, y compris de
                profilage, et dans ce dernier cas, des informations utiles
                concernant la logique sous-jacente, ainsi que l’importance et
                les conséquences prévues de ce traitement pour les personnes
                concernées.
              </Text>
            </ul>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            7. Vous pouvez nous demander que vos données personnelles soient,
            selon les cas, rectifiées, complétées si elles sont inexactes,
            incomplètes, équivoques, périmées.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit à l’effacement des données vous concernant ;
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            8. Vous pouvez nous demander l’effacement de vos données
            personnelles lorsque l’un des motifs suivants s’applique :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              les données personnelles ne sont plus nécessaires au regard des
              finalités pour lesquelles elles ont été collectées ou traitées
              d’une autre manière ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              vous retirez le consentement préalablement donné ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              vous vous opposez au traitement de vos données personnelles
              lorsqu’il n’existe pas de motif légal audit traitement ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              le traitement de données personnelles n’est pas conforme aux
              dispositions de la législation et de la réglementation applicable
              ;
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9. Néanmoins, l’exercice de ce droit ne sera pas possible lorsque la
            conservation de vos données personnelles est nécessaire au regard de
            la législation ou de la réglementation et notamment par exemple pour
            la constatation, l’exercice ou la défense de droits en justice.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit à la limitation des traitements de données :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.1 Vous pouvez demander la limitation du traitement de vos données
            personnelles dans les cas prévus par la législation et la
            réglementation.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit d’opposition aux traitements de données :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.2 Vous disposez également d’un droit d’opposition pour des raisons
            tenant à votre situation particulière et d’un droit d’opposition à
            tout moment lorsque les données sont traitées à des fins de
            prospection commerciale.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit à la portabilité de vos données :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les données sur lesquelles peut s’exercer ce droit sont :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              uniquement vos données personnelles, ce qui exclut les données
              personnelles anonymisées ou les données qui ne vous concernent pas
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              les données personnelles déclaratives ainsi que les données
              personnelles de fonctionnement évoquées précédemment ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              les données personnelles qui ne portent pas atteinte aux droits et
              libertés de tiers telles que celles protégées par le secret des
              affaires.
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.3 Ce droit est limité aux traitements basés sur le consentement ou
            sur un contrat ainsi qu’aux données personnelles que vous avez
            personnellement générées.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.4 Ce droit n’inclut ni les données dérivées ni les données
            inférées, qui sont des données personnelles créées par{' '}
            {PUBLIC_APP_PROVIDER_NAME}.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit de retirer votre consentement :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.5 Lorsque les traitements de données que nous mettons en œuvre
            sont fondés sur votre consentement, vous pouvez le retirer à
            n’importe quel moment. Nous cessons alors de traiter vos données à
            caractère personnel sans que les opérations antérieures pour
            lesquelles vous aviez consenti ne soient remises en cause.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit d’introduire un recours :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.6 Vous avez le droit d’introduire une réclamation auprès de la
            Cnil sur le territoire français et ce sans préjudice de tout autre
            recours administratif ou juridictionnel.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit de définir des directives post-mortem
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            9.7 Vous avez la possibilité de définir des directives relatives à
            la conservation, à l’effacement et à la communication de vos données
            personnelles après votre décès et ce auprès d’un tiers de confiance,
            certifié et chargé de faire respecter la volonté du défunt,
            conformément aux exigences du cadre juridique applicable.
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              10. Comment exercer vos droits ?
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              11
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            1. Vous pouvez exercer vos droits en vous adressant à l’adresse mél
            suivante : {PUBLIC_APP_DPO_EMAIL} ou à l’adresse postale suivante :
            1 Parvis de la défense, 92044 Paris l Défense cedex. , France
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            2. Pour ce faire, vous devez indiquer clairement vos nom(s) et
            prénom(s), l’adresse à laquelle vous souhaitez que la réponse vous
            soit envoyée et y joindre la photocopie d’un titre d’identité
            portant votre signature.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            3. Dans un souci de confidentialité et de protection des données
            personnelles, {PUBLIC_APP_PROVIDER_NAME} doit s’assurer de
            l’identité de l'utilisateur avant de répondre à sa demande. Aussi,
            toute demande tendant à l’exercice de ces droits devra être
            accompagnée d’une copie d’un titre d’identité signé.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            4. Par principe, vous pouvez exercer sans frais l’ensemble de vos
            droits. Cependant en matière de droit d’accès, il pourra vous être
            demandé le paiement de frais raisonnables basés sur les coûts
            administratifs pour toute copie des données que vous demanderez.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            5. Concernant le droit d’information, {PUBLIC_APP_PROVIDER_NAME}{' '}
            n’aura pas l’obligation d’y donner suite lorsque vous disposez déjà
            des informations dont vous sollicitez la communication.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            6. {PUBLIC_APP_PROVIDER_NAME} vous informera s’il ne peut donner
            suite à vos demandes.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            7. {PUBLIC_APP_PROVIDER_NAME} tient à vous informer que le
            non-renseignement ou la modification de vos données sont
            susceptibles d’avoir des conséquences dans le traitement de
            certaines demandes dans le cadre de l’exécution des relations
            contractuelles et que votre demande au titre de l’exercice de vos
            droits sera conservée à des fins de suivi.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="h3">II- POLITIQUE DE GESTION DES COOKIES</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Notre politique d’utilisation des cookies vous permet de mieux
            comprendre les dispositions que nous mettons en œuvre en matière de
            navigation sur notre site web. Elle vous informe notamment sur
            l’ensemble des cookies présents sur notre site web, leur finalité et
            vous donne la marche à suivre pour les paramétrer
          </Text>
        </Box>
        <Box mb={20} mt={20} ml={20}>
          <Text variant="h3">
            A- INFORMATIONS GÉNÉRALES SUR LES COOKIES PRÉSENTS SUR LE SITE
            {PUBLIC_APP_PROVIDER_NAME}{' '}
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} pourra procéder à l’implantation d’un
            cookie sur le disque dur de votre terminal (ordinateur, tablette,
            mobile etc.) afin de vous garantir une navigation fluide, adaptée et
            optimale sur notre site Internet.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les « cookies » (ou témoins de connexion) sont des petits fichiers
            texte de taille limitée qui permettent de reconnaître votre
            ordinateur, votre tablette ou votre mobile aux fins de personnaliser
            les services que nous vous proposons.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les informations recueillies par le biais des cookies ne permettent
            en aucune manière de vous identifier nominativement. Elles sont
            utilisées exclusivement pour nos besoins propres afin d’améliorer
            l’interactivité et la performance de notre site web et de vous
            adresser des contenus adaptés à vos centres d’intérêts. Aucune de
            ces informations ne fait l’objet d’une communication auprès de tiers
            sauf lorsque {PUBLIC_APP_PROVIDER_NAME} a obtenu au préalable votre
            consentement ou bien lorsque la divulgation de ces informations est
            requise par la loi, sur ordre d’un tribunal ou toute autorité
            administrative ou judiciaire habilitée à en connaître.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Une balise sur toutes les pages du site permet à la connexion (+
            login), de stocker les données de l’UTILISATEUR sur la fiche de son
            profil Intercom.
          </Text>
        </Box>
        <Box mb={20} mt={20} ml={20}>
          <Text variant="h3">
            B- CONFIGURATION DE VOS PRÉFÉRENCES SUR LES COOKIES
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Vous pouvez accepter, refuser le dépôt de cookies ou le paramétrer à
            tout moment.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Lors de votre première connexion sur le site{' '}
            {PUBLIC_APP_PROVIDER_NAME} , une bannière présentant des
            informations relatives au dépôt de cookies et de technologies
            similaires apparaît sur votre écran. Une bannière vous indiquera la
            possibilité d’accepter ou de refuser les cookies.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Selon le type de cookie en cause, le recueil de votre consentement
            au dépôt et à la lecture de cookies sur votre terminal peut être
            impératif.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="b">a. Les cookies exemptés de consentement</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Conformément aux recommandations de la Commission Nationale de
            l’Informatique et des Libertés (CNIL), certains cookies sont
            dispensés du recueil préalable de votre consentement dans le sens de
            l’article 82 de la loi informatique et liberté, dans la mesure où
            ils sont strictement nécessaires au fonctionnement du site internet
            ou ont pour finalité exclusive de permettre ou faciliter la
            communication par voie électronique. Il s’agit notamment des cookies
            d’identifiant de session, d’authentification, de session
            d’équilibrage de charge ainsi que des cookies de personnalisation de
            votre interface. Ces cookies sont intégralement soumis à la présente
            politique dans la mesure où ils sont émis et gérés par{' '}
            {PUBLIC_APP_PROVIDER_NAME} .
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="b">
            b. Les cookies nécessitant le recueil préalable de votre
            consentement
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Cette exigence concerne les cookies émis par des tiers et qui sont
            qualifiés de « persistants » dans la mesure où ils demeurent dans
            votre terminal jusqu’à leur effacement ou leur date d’expiration.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            De tels cookies étant émis par des tiers (dont la liste exhaustive
            est établie ci-après), leur utilisation et leur dépôt sont soumis à
            leurs propres politiques de confidentialité.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            b.1 Les cookies de mesure d’audience établissent des statistiques
            concernant la fréquentation et l’utilisation de divers éléments du
            site web (comme les contenus/pages que vous avez visité). Ces
            données participent à l’amélioration de l’ergonomie du site
            {PUBLIC_APP_PROVIDER_NAME} . Un outil de mesure d’audience est
            utilisé sur le site app.Intercom.com .
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="b">
            C . Vous disposez de divers outils de paramétrage des cookies
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            La plupart des navigateurs Internet sont configurés par défaut de
            façon à ce que le dépôt de cookies soit autorisé. Votre navigateur
            vous offre l’opportunité de modifier ces paramètres standards de
            manière à ce que l’ensemble des cookies soit rejeté systématiquement
            ou bien à ce qu’une partie seulement des cookies soit acceptée ou
            refusée en fonction de leur émetteur.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            ATTENTION : Nous attirons votre attention sur le fait que le refus
            du dépôt de cookies sur votre terminal est néanmoins susceptible
            d’altérer votre expérience d’utilisateur ainsi que votre accès à
            certains services ou fonctionnalités du présent site. Le cas
            échéant, {PUBLIC_APP_PROVIDER_NAME} décline toute responsabilité
            concernant les conséquences liées à la dégradation de vos conditions
            de navigation qui interviennent en raison de votre choix de refuser,
            supprimer ou bloquer les cookies nécessaires au fonctionnement du
            site. Ces conséquences ne sauraient constituer un dommage et vous ne
            pourrez prétendre à aucune indemnité de ce fait.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Votre navigateur vous permet également de supprimer les cookies
            existants sur votre terminal ou encore de vous signaler lorsque de
            nouveaux cookies sont susceptibles d’être déposés sur votre
            terminal. Ces paramètres n’ont pas d’incidence sur votre navigation
            mais vous font perdre tout le bénéfice apporté par le cookie.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Votre navigateur vous permet également de supprimer les cookies
            existants sur votre terminal ou encore de vous signaler lorsque de
            nouveaux cookies sont susceptibles d’être déposés sur votre
            terminal. Ces paramètres n’ont pas d’incidence sur votre navigation
            mais vous font perdre tout le bénéfice apporté par le cookie.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Veuillez ci-dessous prendre connaissance des multiples outils mis à
            votre disposition afin que vous puissiez paramétrer les cookies
            déposés sur votre terminal.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="b">
            D. LE PARAMÉTRAGE DE VOTRE NAVIGATEUR INTERNET
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Chaque navigateur Internet propose ses propres paramètres de gestion
            des cookies. Pour savoir de quelle manière modifier vos préférences
            en matière de cookies, vous trouverez ci-dessous les liens vers
            l’aide nécessaire pour accéder au menu de votre navigateur prévu à
            cet effet :
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Chrome : https://support.google.com/chrome/answer/95647?hl=fr
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Firefox :
            https://support.mozilla.org/fr/kb/activer-desactiver-cookies
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            internet Explorer :
            https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Opera : http://help.opera.com/Windows/10.20/fr/cookies.html
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Safari :
            https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Il n’est pas possible d’assurer une information détaillée et à jour
            de ces éléments de paramétrage qui sont sous le seul contrôle
            exclusif et l’autorité des éditeurs des logiciels de navigation
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            A titre de renseignements, les utilisateurs peuvent disposer
            d’informations complémentaires :
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              de la part des éditeurs de leur logiciel de navigation sur leur
              site internet,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              de manière plus générale sur le site de la Cnil à l’adresse
              www.cnil.fr en utilisant le mot clé « cookies » dans les moteurs
              de recherche.
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} attire l’attention de l’utilisateur sur
            le fait que, lorsqu’il accepte ou s’oppose à l’installation ou à
            l’utilisation d’un cookie, un cookie de consentement ou de refus est
            installé sur son équipement terminal. Si l’utilisateur supprime ce
            cookie de consentement ou de refus, il ne pourra plus être identifié
            comme ayant accepté ou refusé l’utilisation de cookies. Les cookies
            de consentement ou de refus doivent rester sur l’équipent terminal
            de l’utilisateur.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’utilisateur dispose d’un droit d’accès, de retrait et de
            modification des données à caractère personnel communiquées par le
            biais des cookies dans les conditions indiquées ci-dessus.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’utilisateur est tenu de respecter les dispositions de la loi
            Informatique et libertés du 6 janvier 1978 modifiée par le règlement
            général sur la protection des données (RGPD) du 27 avril 2016 dont
            la violation est passible de sanctions pénales.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Il doit notamment s’abstenir, s’agissant des informations à
            caractère personnel auxquelles il accède ou pourrait accéder, de
            toute collecte, de toute utilisation détournée d’une manière
            générale, de tout acte susceptible de porter atteinte à la vie
            privée ou à la réputation des personnes.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="h3">E. DISPOSITIONS DIVERSES</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">- Liens</Text>
          <ol>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              1. Les utilisateurs et visiteurs du site ne peuvent pas mettre en
              place un lien en direction de ce site sans l’autorisation expresse
              et préalable de l’éditeur du site.
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              2. En aucun cas, cette autorisation ne pourra être qualifiée de
              convention implicite d’affiliation.
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              3. En toute hypothèse, les liens renvoyant au site devront être
              retirés à première demande de {PUBLIC_APP_PROVIDER_NAME} .
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              4. {PUBLIC_APP_PROVIDER_NAME} se réserve la possibilité de mettre
              en place des liens sur sa plateforme donnant accès à des pages web
              autres que celles de sa plateforme.
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              5. Les utilisateurs sont formellement informés que les sites
              auxquels ils peuvent accéder par l’intermédiaire des liens
              n’appartiennent pas à {PUBLIC_APP_PROVIDER_NAME} .
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              6. {PUBLIC_APP_PROVIDER_NAME} ne saurait être responsable de
              l’accès par les utilisateurs via les liens mis en place dans le
              cadre du site en direction d’autres ressources présentes sur le
              réseau internet, ni du contenu des informations fournies sur ces
              sites au titre de l’activation du lien.
            </Text>
          </ol>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">- Modification notice légale</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            1. {PUBLIC_APP_PROVIDER_NAME} se réserve le droit de modifier et
            d’actualiser la présente notice légale à tout moment et sans
            préavis.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            2. L’utilisateur est donc invité à la consulter régulièrement.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">-Durée de conservation</Text>
        </Box>
        <Box mb={20} mt={20}>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              la durée de vie des cookies est limitée à 13 mois, durée
              permettant une comparaison pertinente des audiences dans le temps,
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              les informations collectées par l'intermédiaire des cookies est
              conservées pour une durée maximale de vingt-cinq mois ;
            </Text>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              les durées de vie et de conservation ci-dessus mentionnées fassent
              l’objet d’un réexamen périodique afin d’être limitées au strict
              nécessaire.
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Pour de plus amples informations concernant les outils de maîtrise
            des cookies, vous pouvez consulter le site internet de la CNIL :
            https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Pour toute interrogation ou demande complémentaire d’informations
            relative à la présente politique des cookies, merci de bien vouloir
            nous contacter par le biais de notre formulaire.
          </Text>
        </Box>

        <Box mb={20} mt={20}>
          <Text variant="h3">Mesures de sécurité</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            a) Le Site offrira suffisamment de garanties en ce qui concerne les
            mesures techniques et d'organisation liées à la sécurité des données
            ;
          </Text>
          <ul>
            <Text
              variant="li"
              style={{ listStyleType: 'disc', marginLeft: 20 }}
            >
              d’un droit à la limitation des traitements de données :
            </Text>
          </ul>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            b) après l'évaluation des exigences du droit applicable à la
            protection des données, les mesures de sécurité sont adéquates pour
            protéger les données à caractère personnel contre une destruction
            fortuite ou illicite, une perte fortuite, une altération, une
            divulgation ou un accès non autorisé, notamment lorsque le
            traitement suppose la transmission de données par réseau, et contre
            toute autre forme illicite de traitement et elles assurent un niveau
            de sécurité adapté aux risques liés au traitement et à la nature des
            données à protéger, eu égard au niveau technologique et au coût de
            mise en œuvre;
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            c) Le Site veillera au respect des mesures de sécurité ;
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            d) si le transfert porte sur des catégories particulières de données
            (des données à caractère personnel), la personne concernée sera
            informée avant le transfert ou dès que possible après le transfert
            que ses données pourraient être transmises à un pays tiers ;
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            d) si le transfert porte sur des catégories particulières de données
            (des données à caractère personnel), la personne concernée sera
            informée avant le transfert ou dès que possible après le transfert
            que ses données pourraient être transmises à un pays tiers ;
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            e) Le Site mettra à la disposition des personnes concernées, si
            elles le demandent, une copie des présentes Clauses, et une
            description sommaire des mesures de sécurité ;
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            f) en cas de sous-traitance ultérieure, l'activité de traitement
            sera effectuée par un sous-traitant offrant au moins le même niveau
            de protection des données à caractère personnel et des droits de la
            personne concernée conformément aux présentes clauses et dans le
            respect des dispositions du présent article.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="b">Coopération avec les autorités de contrôle</Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            1) L’UTILISATEUR accepte que le Site dépose une copie de toute
            donnée auprès de l'autorité de contrôle si celle-ci l'exige ou si ce
            dépôt est prévu par le droit applicable à la protection des données.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            2) L’UTILISATEUR est informé que l'autorité de contrôle a le droit
            d'effectuer des vérifications sur le Site de données et chez tout
            sous-traitant ultérieur dans la même mesure et dans les mêmes
            conditions qu'en cas de vérifications opérées chez l’UTILISATEUR de
            données conformément au droit applicable à la protection des
            données.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            3) Le Site informe l’UTILISATEUR dans les meilleurs délais de
            l’existence d’une législation le concernant ou concernant tout
            sous-traitant ultérieur faisant obstacle à ce que des vérifications
            soient effectuées chez lui ou chez tout sous-traitant ultérieur
            conformément aux présentes dispositions.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les UTILISATEURS déclarent faire bon usage des données personnelle
            accessibles sur {PUBLIC_APP_PROVIDER_NAME} et déclare à ce titre se
            conformer aux dispositions légales régissant la matière.{' '}
            {PUBLIC_APP_PROVIDER_NAME} ne peut à ce titre être rendu responsable
            de tout agissement dont l’UTILISATEUR en la matière.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} déclare que les données personnelles
            publiées par les UTILISATEURS font l’objet d’un traitement conforme
            à la règlementation RGP.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            En tout état de cause l’UTILISATEUR autorise expressément{' '}
            {PUBLIC_APP_PROVIDER_NAME} à transmettre ses données personnelles à
            des partenaires dans le cadre de relations d’affaires.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="b">
            <u>PROPRITE INTELLECTUELLE</u>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Le Site et la totalité de ses composantes techniques graphiques et
            toutes marques verbales ou figuratives, sont la propriété de
            Freelance.com.com ou font l’objet. La propriété du Site et ses
            composantes sont protégés par les dispositions du Code de la
            propriété intellectuelle contre toute utilisation non autorisée par
            la loi, la jurisprudence y afférente et les dispositions des
            présentes.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            La consultation de ce Site n’emporte aucune licence, ni aucune
            cession des droits afférents aux éléments de ce Site, sauf accord
            exprès et écrit de Freelance.com.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Toute copie, reproduction, représentation, adaptation, diffusion,
            intégrale ou partielle du Site{' '}
            <LinkHtml href={PUBLIC_APP_PROVIDER_URL} /> et de son contenu, par
            quelque procédé que ce soit et sur quelque support que ce soit est
            illicite, sauf accord préalable exprès et écrit de Freelance.com.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Toute copie, reproduction, représentation, adaptation, diffusion,
            intégrale ou partielle du Site
          </Text>
        </Box>

        <Box mb={20} mt={20}>
          <Text variant="b">
            <u>COOKIES ET LIENS HYPERTEXTES</u>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR est prévenu que des informations peuvent être
            automatiquement collectées par l’utilisation de cookies qui se
            placent automatiquement sur le logiciel de navigation de
            l’utilisateur.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Le cookie est un bloc de données que le Site Internet de{' '}
            {PUBLIC_APP_PROVIDER_NAME} peut envoyer au moteur de recherche
            utilisé par l’UTILISATEUR et qui peut être stocké dans le système de
            l’UTILISATEUR.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les cookies envoyés par <LinkHtml href={PUBLIC_APP_PROVIDER_URL} />{' '}
            ne contiennent aucune donnée à caractère personnel au sens de la loi
            n°2004-801 du 6 août 2004. L’UTILISATEUR est libre de refuser ces
            cookies en configurant son système en conséquence lors de la
            consultation du Site. L’UTILISATEUR doit néanmoins être averti qu’en
            cas de refus des cookies, l’accès à certaines pages du Site Internet
            peut lui être directement refusé.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            Les liens hypertextes mis en place dans le cadre du Site qui
            renvoient à d’autres ressources notamment, vers ses partenaires,
            sont clairement identifiés et font l’objet d’une autorisation
            préalable des partenaires.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR reconnaît que les liens hypertextes présents sur le
            Site n’engagent nullement la responsabilité de Freelance.com quant
            aux contenus de ces Sites. Le Site ne pourra en aucun cas être
            intégré, en tout ou en partie, dans un autre Site Internet, quel que
            soit le moyen technique de cette démarche, sans l’accord exprès et
            écrit et préalable de Freelance.com.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            En outre, le renvoi sur un Site internet par un lien hypertexte ou
            tout autre lien, ne signifie en aucune manière que{' '}
            {PUBLIC_APP_PROVIDER_NAME} reconnaît ou accepte quelque
            responsabilité quant à la teneur ou à l'utilisation dudit Site.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            En conséquence, il incombe strictement à l’UTILISATEUR de prendre
            toutes mesure et de s’assurer d’une éventuelle intrusion de virus,
            trojans, etc....
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR déclare avoir pleinement conscience des risques
            internet et les accepte. Il reconnaît notamment que les informations
            qui y transitent ou y sont stockées peuvent être interceptées ou
            altérées contre la volonté de {PUBLIC_APP_PROVIDER_NAME}. En
            conséquence l’UTILISATEUR doit prendre toutes les précautions
            nécessaires afin de se prémunir contre la piraterie, notamment en
            adoptant une configuration informatique sécurisée et adaptée, par la
            mise en place d’un logiciel de détection de virus régulièrement mis
            à jour.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} ne pourra en aucun cas être tenue pour
            responsable en cas de contamination du matériel informatique de
            l’UTILISATEUR au cours ou après la navigation de celui-ci sur le
            Site. De plus, {PUBLIC_APP_PROVIDER_NAME} ne saurait être tenue pour
            responsable de la mauvaise utilisation des informations ou services
            par l’UTILISATEUR.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} exhorte les UTILISATEURS du Site de lui
            faire part d’éventuelles omissions, erreurs ou corrections, en
            adressant un courrier électronique dont les références sont
            indiquées sur le Site.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="h3">
            <u>RESPONSABILITE</u>
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} ne peut de quelques manières que ce soit
            et pour quelques raisons que ce soit être tenue pour responsable des
            erreurs éventuelles présentes sur le Site, de la complétude des
            informations traitées, ni d’une absence de disponibilité du Site
            et/ou des informations et de tous contenus.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR reconnaît que {PUBLIC_APP_PROVIDER_NAME} se réserve le
            droit de modifier, d’interrompre temporairement ou de façon
            permanente tout ou partie du Site, sans préavis.{' '}
            {PUBLIC_APP_PROVIDER_NAME} ne pourra voir sa responsabilité
            recherchée en cas d’indisponibilité temporaire, partielle ou totale,
            du Site notamment en cas de maintenance du Site ou du serveur, en
            cas d’incident technique et plus généralement en cas d’évènements
            indépendants de sa volonté.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} se réserve le droit de mettre à jour et
            ou de modifier les présentes mentions légales à tout moment en
            fonction de l’évolution du contenu du Site et/ou de la
            réglementation en vigueur. {PUBLIC_APP_PROVIDER_NAME} invite tout
            UTILISATEUR à consulter systématiquement les présentes mentions
            légales.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            {PUBLIC_APP_PROVIDER_NAME} met en œuvre tous les moyens dont elle
            dispose, pour assurer une information fiable et une mise à jour
            fiable du Site. Toutefois, des erreurs ou omissions peuvent
            survenir. {PUBLIC_APP_PROVIDER_NAME}
            n'est en aucun cas responsable de l'utilisation faite de ces
            informations, et de tout préjudice direct ou indirect pouvant en
            découler.
          </Text>
        </Box>
        <Box mb={20} mt={20}>
          <Text variant="p">
            L’UTILISATEUR garantit {PUBLIC_APP_PROVIDER_NAME} de toute
            intrusion, virus... ou dommage causé par lui de quelque nature
            soit-il. Il est entièrement responsable des écrits communiqués par
            lui, notamment toute déclaration ou publication contraire aux
            dispositions légales et règlementaires en vigueur et dédommagera à
            ce titre Freelance.com de tout préjudice résultant d’une infraction.
          </Text>
        </Box>
      </Box>
      <Box mb={20} mt={20}>
        <CheckBox
          mb={10}
          checked={accept}
          data-cy="cgu-checkbox"
          onClick={() => setAccept(!accept)}
        >
          <Box>
            <Text variant="b">
              Accepter les Conditions générales d’utilisation
            </Text>
          </Box>
        </CheckBox>
      </Box>
      <Button
        onClick={onValidate}
        isLoading={loading}
        type="button"
        variant="filled"
        data-cy="cgu-submit"
        isDisabled={!accept}
      >
        Valider
      </Button>
    </ModalFrame>
  );
};

export const showCGUModal = create<Props>(CGUModal);
