import { ComponentPropsWithoutRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { boxProperties, BoxProps } from '.';

import { boxProperties, BoxProps } from './Box';

const A = styled(Link)`
  display: inline-block;
  cursor: pointer;
  color: ${p => (p.color ? p.color : p.theme.colors.primary.default)};
  &:hover {
    color: ${p => (p.color ? p.color : p.theme.colors.primary.default)};
  }
  ${boxProperties}
`;
type ALinkProps = ComponentPropsWithoutRef<'a'> & {
  children: any;
  href?: string;
} & BoxProps;

export const ALink = ({ children, href, ...props }: ALinkProps) => {
  return (
    <A
      to={href || ''}
      style={{ display: 'flex', alignItems: 'center' }}
      {...props}
    >
      {children}
    </A>
  );
};
