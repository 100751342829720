import {
  ContactIcon,
  DashboardIcon,
  EtablishementIcon,
  InvoiceIcon,
  LogoutIcon,
  Missioncon,
  LogoConnecteed,
} from 'components/icons';
import { ExternalLinkIcon } from 'components/ui/icons';
import NotificationsBell from './NotificationsIcon';
import { useWSNotificationsStore } from 'store/wsNotifications';
import {
  SpinnerBox,
  Box,
  Tab,
  Menu,
  MenuItem,
  ALink,
  Grid,
  Cell,
  Flex,
} from 'components/ui';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import React from 'react';
import styled from 'styled-components';
import { UserButton } from './UserButton';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getTradeNameSafe,
  useAmplifyLogout,
  REACT_APP_SEED_STAGE_NAME,
  deleteAllCognitoCookies,
  useMe,
} from '@commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { useHeadQuarterFindMany, useProviderStatistics } from '@commons';
import { useIntercom } from 'react-use-intercom';
import { useRouterStore } from 'store';
export const Container = styled(Grid)`
  height: 80px;
  margin-bottom: 20px;
  width: 100%;
  //max-width: ${p => p.theme.breakpoints.xl};
  background-color: ${p => p.theme.colors.primary.white};
  border-bottom: solid 1px #d0d4e3;

  svg {
    font-size: 24px;
  }
`;
Container.displayName = 'TopBarContainer';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  //cursor: pointer;
  padding: 0 20px;
  border-left: 1px solid #e7e7e7;
  background-color: white;
  border-bottom: solid 1px #d0d4e3;
`;
const partnerLink = {
  localhost: 'https://develop.partenaire.freelance.com/',
  develop: 'https://develop.partenaire.freelance.com/',
  staging: 'https://staging.partenaire.freelance.com/',
  preprod: 'https://preprod.partenaire.freelance.com/',
  production: 'https://partenaire.freelance.com/',
};
export const TopBar = () => {
  const { data: providerStats } = useProviderStatistics();
  const { hardShutdown } = useIntercom();
  const { pathname } = useLocation();
  const { newMessage, updateWSNotifications } = useWSNotificationsStore();
  const t = useTranslationFormat();
  // use this after login on redirect on targetLocation
  const { resetRouteStoreSates } = useRouterStore();
  const { mutateAsync: logout } = useAmplifyLogout();
  const history = useHistory();
  const { data: dataHQ, status: hqStatus } = useHeadQuarterFindMany();
  const { me } = useMe();
  const headQuarters = dataHQ?.sort((a, b) => {
    if (getTradeNameSafe(a.headquarter) < getTradeNameSafe(b.headquarter)) {
      return -1;
    }
    if (getTradeNameSafe(a.headquarter) > getTradeNameSafe(b.headquarter)) {
      return 1;
    }
    return 0;
  });
  const haveTimeSpentMission =
    providerStats && providerStats?.dayMission?.totalCount > 0;
  const haveFlateRateMission =
    providerStats && providerStats?.flatRateMission?.totalCount > 0;
  const items = (
    <>
      <Flex
        ml={10}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <LogoConnecteed />
      </Flex>
      <Tab href="/dashboard" icon={<DashboardIcon />}>
        {t('texts:dashboard', 'capitalize-first')}
      </Tab>
      <Menu
        menuItems={
          //@ts-ignore
          hqStatus === 'pending' ? (
            <SpinnerBox color="#fff" />
          ) : (
            <>
              {headQuarters &&
                headQuarters.map((hq: any) => (
                  <MenuItem>
                    <ALink
                      color="white"
                      href={`/headquarter/${hq?.headquarter?.uuid}`}
                    >
                      {getTradeNameSafe(hq?.headquarter)}
                    </ALink>
                  </MenuItem>
                ))}
              {/* <MenuItem>
                <Text color="white">Ajouter un établissement</Text>
              </MenuItem> */}
            </>
          )
        }
      >
        <Tab
          isSelected={pathname?.includes('/headquarter')}
          icon={<EtablishementIcon />}
        >
          {t('texts:establishment', 'capitalize-first', true)}
        </Tab>
      </Menu>
      {false && (
        <Tab icon={<Missioncon />}>
          {t('texts:mission', 'capitalize-first', true)}
        </Tab>
      )}
      {
        <>
          <Menu
            menuItems={
              <>
                <MenuItem>
                  <ALink color="white" href={'/contractors'}>
                    Vos intervenants
                  </ALink>
                </MenuItem>
              </>
            }
          >
            <Tab href="/contractors" icon={<ContactIcon />}>
              {t('texts:contractor', 'capitalize-first', true)}
            </Tab>
          </Menu>
        </>
      }
      <Menu
        menuItems={
          <>
            <MenuItem>
              <ALink
                color="white"
                href={
                  '/invoices/activities/list/pending'
                  //   haveTimeSpentMission
                  //     ? '/invoices/activities/list/pending'
                  //     : haveFlateRateMission
                  //       ? '/invoices/flat-rate/activities/list/pending'
                  //       : '/invoices/activities/list/pending'
                }
              >
                Activités au temps passé
              </ALink>
            </MenuItem>
            <MenuItem>
              <ALink
                color="white"
                href={'/invoices/flat-rate/activities/list/pending'}
              >
                Activités au forfait
              </ALink>
            </MenuItem>
            <MenuItem>
              <ALink
                color="white"
                href={'/invoices/time-spent/to-be-submitted'}
              >
                Factures
              </ALink>
            </MenuItem>
            <MenuItem>
              <ALink color="white" href={'/invoices/credit-note/validated'}>
                Avoirs
              </ALink>
            </MenuItem>
          </>
        }
      >
        <Tab
          href={
            haveTimeSpentMission
              ? '/invoices/activities/list/pending'
              : haveFlateRateMission
                ? '/invoices/flat-rate/activities/list/pending'
                : '/invoices/activities/list/pending'
          }
          icon={<InvoiceIcon />}
        >
          {t('texts:invoice')}
        </Tab>
      </Menu>
      {false && (
        <Tab icon={<ContactIcon />}>
          {t('texts:contact', 'capitalize-first', true)}
        </Tab>
      )}
    </>
  );
  const onLogout = () => {
    showDialogModal({
      title: 'Êtes-vous sûr(e) de vouloir vous déconnecter ?',
      text: 'Toutes vos données en cours de saisie seront perdues.',
      confirmLabel: 'Déconnexion',
      btnFull: true,
      width: 470,
    }).then(async value => {
      if (value) {
        await deleteAllCognitoCookies();
        hardShutdown();
        resetRouteStoreSates({});
        await logout();
        history.push('/login');
      }
    });
  };
  const disableNewNotification = React.useCallback(async (): Promise<void> => {
    updateWSNotifications({
      newMessage: false,
    });
  }, [updateWSNotifications]);

  const partenaireApp = me?.apps?.find(
    a => a?.appName === 'PORTAIL_PARTENAIRE'
  );
  return (
    <Container cols={12}>
      <Cell x-span={{ xs: '8' }}>
        <Flex>{items}</Flex>
      </Cell>

      <Cell x-span={{ xs: '4' }} justifySelf="right">
        <Flex height="100%">
          {partenaireApp && (
            <Tab icon={<ExternalLinkIcon />}>
              <a
                target="_blank"
                href={`https://${partenaireApp?.url}`}
                rel="noreferrer"
              >
                Portail Partenaire
              </a>
            </Tab>
          )}
          {/*<FlexWrapper>
            <SearchIcon />
          </FlexWrapper>*/}
          <FlexWrapper>
            <ALink
              href={'/notifications'}
              onClick={() => disableNewNotification()}
            >
              <NotificationsBell hasUnread={newMessage} />
            </ALink>
          </FlexWrapper>
          <FlexWrapper>
            <UserButton />
          </FlexWrapper>
          <FlexWrapper style={{ cursor: 'pointer' }}>
            <Box borderLeft="1px solid #e7e7e7" />
            <LogoutIcon onClick={onLogout} />
          </FlexWrapper>
        </Flex>
      </Cell>
    </Container>
  );
};

TopBar.displayName = 'TopBar';

export default TopBar;
