import { changeUserEmail } from '@commons';
import { ChangeEmailIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Link, Box, Flex, Row, Spinner, Text } from 'components/ui';
import * as React from 'react';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import AccesDenied from 'routes/Common/AccesDenied';

export const ChangeEmail: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = React.useState('noFetch');
  const loading = status === 'pending';
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Votre email a été vérifié avec succès`,
      path: '',
    },
  ]);
  const checkToken = React.useCallback(async (): Promise<void> => {
    try {
      await changeUserEmail(
        {
          action: 'VALIDATE_CHANGE',
          cipher: token,
        },
        true
      );
      setStatus('success');
    } catch {
      setStatus('error');
    }
  }, [token]);

  React.useEffect(() => {
    if (status === 'noFetch') {
      setStatus('loading');
      checkToken();
    }
  }, [checkToken, status]);

  if (loading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  } else {
    if (status === 'success') {
      return (
        <Flex
          height={'100vh'}
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center" width="100%" height={'100%'}>
            <Row spacing={20} width="100%" height={'100%'}>
              <Flex
                height={'100%'}
                width="100%"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
              >
                <ChangeEmailIcon width={800} height="50vh" />
              </Flex>
              <Flex
                height={'100%'}
                width="100%"
                alignItems="center"
                alignContent="center"
                justifyContent="flex-start"
              >
                <Box width={500}>
                  <Text variant="h1">Merci !</Text>
                  <Text>Votre email a été vérifié avec succès.</Text>
                  <Text variant="p">
                    Vous avez besoin d’aide ?{' '}
                    <Link onClick={showContactUsModal}>Contactez-nous</Link>
                  </Text>
                </Box>
              </Flex>
            </Row>
          </Flex>
        </Flex>
      );
    }
    if (status === 'error') {
      return (
        <AccesDenied
          customText={'Le lien de vérification n’est plus valide.'}
        />
      );
    } else {
      return <></>;
    }
  }
};
export default ChangeEmail;
