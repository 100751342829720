import { DatePicker } from 'components/ui';
import { useController } from 'react-hook-form';
export const DatePickerControlled = (props: any) => {
  const {
    field: { ref, value, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });
  return (
    <DatePicker
      {...inputProps}
      {...props}
      inputRef={ref}
      valueName="selected"
      value={value}
    />
  );
};
