import { BoxProps, Flex, LetterBox } from 'components/ui';
import React from 'react';

type Props = BoxProps & {
  value?: string | undefined;
  onChange: (value: string) => void;
};

export const AlphabeticalSearch = ({ value, onChange }: Props) => {
  const letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  return (
    <Flex width={1 / 1} justifyContent="center">
      {letters.map(letter => {
        return (
          <LetterBox
            isSelected={value === letter}
            variant="primary"
            //@ts-ignore
            onClick={() => onChange(letter === value ? undefined : letter)}
          >
            <Flex
              width={1 / 1}
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              {letter.toUpperCase()}
            </Flex>
          </LetterBox>
        );
      })}
    </Flex>
  );
};
