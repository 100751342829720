import { EDpaeStatus } from '@freelancelabs/teoreme-commons';
import { create } from 'zustand';

export enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}

type State = {
  pageIndex: number;
  columnsHidden: Array<any>;
  updateContractorListStore: (state: Partial<State>) => void;
  resetContractorListStore: (state: Partial<State>) => void;
  filterObject: any;
  filterDPAE: 'N/A' | EDpaeStatus | undefined;
  selectedStatus: 'N/A' | 'ACTIVE' | 'PENDING' | undefined;
  searchQuery: string;
};

const initialStates = {
  pageIndex: 0,
  columnsHidden: [],
  filterObject: undefined,
  selectedStatus: undefined,
  filterDPAE: undefined,
  searchQuery: '',
};

const store = (set: any) => ({
  ...initialStates,
  updateContractorListStore: (state: Partial<State>) => set(state),
  resetContractorListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useContractorListStore = create<State>(store);
