import {
  IInvoiceGetParams,
  IInvoiceGetResponse,
  IInvoiceSearchParams,
  IInvoiceSearchResponse,
  IInvoiceUpdateParams,
  IInvoiceUpdateResponse,
  IInvoiceSendReminderParams,
  IInvoiceSendReminderResponse,
  IInvoiceUpdateServiceParams,
  IInvoiceSearchIbanParams,
  IInvoiceSearchIbanResponse,
  IInvoiceCreateCreditNoteParams,
  IInvoiceCreateCreditNoteResponse,
} from '@freelancelabs/teoreme-commons';
import { INVOICE_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const invoiceFindOne = (identifier: string, disableAlert?: boolean) =>
  fetcher<IInvoiceGetParams, IInvoiceGetResponse>(
    INVOICE_SERVICE_URL + `/get?uuid=${identifier}`,
    undefined,
    disableAlert
  );
export const invoiceFindMany = (searchParams: any, disableAlert?: boolean) =>
  fetcher<IInvoiceSearchParams, IInvoiceSearchResponse>(
    INVOICE_SERVICE_URL + `/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );

export const invoiceUpdateOne = (
  body: IInvoiceUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IInvoiceUpdateParams, IInvoiceUpdateResponse>(
    INVOICE_SERVICE_URL + `/update`,
    {
      method: 'PUT',
      body,
    },
    disableAlert
  );
export const invoiceSendReminder = (
  body: IInvoiceSendReminderParams,
  disableAlert?: boolean
) =>
  fetcher<IInvoiceSendReminderParams, IInvoiceSendReminderResponse>(
    `${INVOICE_SERVICE_URL}/send-reminder`,
    { method: 'post', body },
    disableAlert
  );

//TODO MAKE PARAM WHEN API READY
export const EditInvoiceService = (body: any, disableAlert?: boolean) =>
  fetcher<IInvoiceUpdateServiceParams, any>(
    INVOICE_SERVICE_URL + `/update-service`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
//TODO MAKE PARAM WHEN API READY
export const GenerateInvoice = (body: any, disableAlert?: boolean) =>
  fetcher<IInvoiceUpdateServiceParams, any>(
    INVOICE_SERVICE_URL + `/generate`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
export const invoiceIbanFindMany = (
  searchParams: any,
  disableAlert?: boolean
) =>
  fetcher<IInvoiceSearchIbanParams, IInvoiceSearchIbanResponse>(
    INVOICE_SERVICE_URL + `/iban/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const createCreditNote = (body: any, disableAlert?: boolean) =>
  fetcher<IInvoiceCreateCreditNoteParams, IInvoiceCreateCreditNoteResponse>(
    INVOICE_SERVICE_URL + `/create-credit-note`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
