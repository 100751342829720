import {
  getInvoiceTvaAmount,
  useEstablishmentFindOne,
  round,
  isAdditionalActivityInvoice,
  getInvoiceTotalAmount,
  isCustomerInvoice,
  isFrenchEstablishment,
} from '@commons';
import {
  IJoinedInvoice,
  IEstablishmentMinifiedUsers,
  IJoinedAdditionalActivity,
} from '@freelancelabs/teoreme-commons';
import { BankAccount } from 'components/BankAccount';
import { EuroIcon } from 'components/icons';
import {
  Box,
  Cell,
  CheckSwitch,
  Divider,
  FormControl,
  Grid,
  Input,
  Text,
  StaticSelectControlled,
} from 'components/ui';

type FormValues = {
  invoice: IJoinedInvoice;
};
type InvoiceEstablishmentInformationsProps = {
  form: FormValues & any;
  invoiceData: IJoinedInvoice;
  isEditable: boolean;
  activity?: IJoinedAdditionalActivity;
};

const grid3 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '3',
  xl: '3',
};
const grid6 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
};

export const InvoiceEstablishmentInformations = ({
  form: {
    formState: { errors, isDirty },
    register,
    watch,
    control,
    setValue,
    formState,
    getValues,
  },
  isEditable,
  invoiceData,
  activity,
}: InvoiceEstablishmentInformationsProps) => {
  const invoice = getValues();

  const nbWorkingDays = watch('nbWorkingDays');
  const isVat = watch('isVat');
  const vatRate = watch('vatRate');
  const isDeductible = watch('isDeductible');
  const estProvider = watch('estProvider');

  const totalAmountHT = getInvoiceTotalAmount(
    invoice,
    isCustomerInvoice(invoice?.invoiceType) ? 'CUSTOMER' : 'PROVIDER',
    activity
  );
  const { data: establishment } = useEstablishmentFindOne(estProvider?.uuid);
  return (
    <>
      <Grid cols={12} gap="40px">
        <Cell x-span={{ xs: '12' }}>
          <Text variant="h2" width={'calc(100% - 250px)'}>
            Informations liées à l’établissement
          </Text>
        </Cell>
      </Grid>
      <BankAccount
        establishment={establishment as IEstablishmentMinifiedUsers}
      />
      <Divider mt={20} />
      <Grid cols={12} gap="40px">
        <Cell x-span={grid3}>
          <Box mt={20}>
            <CheckSwitch
              style={{ minWidth: 500 }}
              isDisabled={
                !isEditable || !isFrenchEstablishment(establishment as any)
              }
              id="isVat"
              {...register('isVat')}
            >
              <Text variant="h3">TVA</Text>
              <Text variant="p" width={200}>
                A activer si vous êtes assujetti à la TVA
              </Text>
            </CheckSwitch>
          </Box>
        </Cell>
        {isVat && (
          <>
            <Cell x-span={grid3}>
              <FormControl
                required={true}
                label="N° TVA"
                errorMessage={errors?.vatNumber?.message}
              >
                <Input
                  isDisabled={
                    !isVat ||
                    !isEditable ||
                    !isFrenchEstablishment(establishment as any)
                  }
                  isFullWidth
                  pr={20}
                  {...register('vatNumber')}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid3}>
              <FormControl
                required={true}
                label="% TVA"
                errorMessage={errors?.vatRate?.message}
              >
                <StaticSelectControlled
                  isDisabled={
                    !isVat ||
                    !isEditable ||
                    !isFrenchEstablishment(establishment as any)
                  }
                  control={control}
                  name="vatRate"
                  options={[
                    { value: 20, label: '20%' },
                    { value: 10, label: '10%' },
                    { value: 5.5, label: '5,5%' },
                    { value: 2.1, label: '2,1%' },
                  ]}
                  placeholder="% TVA"
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid3}>
              <FormControl
                required={true}
                label="Montant TVA"
                errorMessage={errors?.vat?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  pr={20}
                  {...register('vate')}
                  value={
                    isAdditionalActivityInvoice(invoice?.invoiceType)
                      ? isVat
                        ? round(
                            Number(invoice?.totalBeforeTaxes) *
                              Number(vatRate / 100)
                          )
                        : 0
                      : isVat
                        ? getInvoiceTvaAmount(
                            totalAmountHT,
                            nbWorkingDays,
                            invoiceData,
                            vatRate
                          )
                        : 0
                  }
                />
              </FormControl>
            </Cell>
          </>
        )}
        {!isVat && (
          <>
            <Cell x-span={{ xs: '2' }}></Cell>
            <Cell x-span={{ xs: '6' }} alignSelf="right" placeSelf="center">
              <Text width={1 / 1} ml={20}>
                En décochant cette option, vous déclarez être{' '}
                <b>exonéré de TVA.</b>
              </Text>
              <Text width={1 / 1} ml={20}>
                Merci de bien vouloir mentionner sur <b>votre facture</b>{' '}
                l'article qui vous exonère de TVA.
              </Text>
            </Cell>
          </>
        )}
      </Grid>
      <Divider />
      <Grid cols={12} gap="40px">
        <Cell
          x-span={{
            xs: '12',
            sm: '12',
            md: '12',
            lg: '6',
            xl: '6',
          }}
        >
          <Box mt={20}>
            <CheckSwitch
              style={{ minWidth: 500 }}
              isDisabled={
                !isEditable || !isFrenchEstablishment(establishment as any)
              }
              id="isDeductible"
              {...register('isDeductible')}
              onChange={e => {
                if (!e.target.checked) {
                  setValue('deductionAmount', undefined);
                  setValue('socialSecurityNumber', undefined);
                }
                setValue('isDeductible', e.target.checked);
              }}
            >
              <Text variant="h3">Précomptes</Text>
              <Text variant="p" width={300}>
                A activer si vous êtes soumis au précompte de vos cotisations
                sociales d’artiste ou d’auteur
              </Text>
            </CheckSwitch>
          </Box>
        </Cell>
        <Cell x-span={grid3}>
          <FormControl
            required={true}
            label="N° de sécurité sociale"
            errorMessage={
              errors?.socialSecurityNumber?.type === 'maxLength' ||
              errors?.socialSecurityNumber?.type === 'minLength'
                ? 'Veuillez saisir 15 caractères'
                : errors?.socialSecurityNumber?.message
            }
          >
            <Input
              isDisabled={
                !isDeductible ||
                !isEditable ||
                !isFrenchEstablishment(establishment as any)
              }
              isFullWidth
              pr={20}
              {...(isDeductible
                ? register('socialSecurityNumber', {
                    required: 'ce champs est requis',
                    maxLength: 15,
                    minLength: 15,
                  })
                : register('socialSecurityNumber', { required: undefined }))}
              name="socialSecurityNumber"
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid3}>
          <FormControl
            required={true}
            label="Montant"
            errorMessage={
              errors?.deductionAmount?.type === 'max'
                ? `Veuillez saisir un montant inférieur ou égal à ${totalAmountHT}`
                : errors?.deductionAmount?.type === 'min'
                  ? `Veuillez saisir un montant positif`
                  : errors?.deductionAmount?.message
            }
          >
            <Input
              isDisabled={
                !isDeductible ||
                !isEditable ||
                !isFrenchEstablishment(establishment as any)
              }
              type="number"
              step={'0.01'}
              maxDecimal={2}
              {...(isDeductible
                ? register('deductionAmount', {
                    min: 0,
                    max: totalAmountHT,
                    required: 'Ce champs est requis',
                  })
                : undefined)}
              isFullWidth
              name="deductionAmount"
              icon={<EuroIcon />}
            />
          </FormControl>
        </Cell>
      </Grid>
    </>
  );
};
