import * as React from 'react';
import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

export const DropDownContent = styled.div<{ align?: string }>`
  display: none;
  position: absolute;
  background-color: ${p => p.theme.colors.primary.default};
  min-width: 300px;
  overflow: hidden;
  border-radius: 5px;
  z-index: 200;
  overflow: hidden;
  top: 80%;
  margin-left: 50px;
  ${props => (props.align === 'right' ? 'right' : 'left')} : 0px;
`;

export const MenuItem = styled.div`
  color: white;
  padding: 10px 16px;
  text-decoration: none;
  font-size: 14px;
  display: block;

  &:hover,
  &:focus {
    font-weight: bold;
  }
`;
export const ArrowTop = styled.div<BoxProps>`
  display: none;
  position: fixed;
  margin-top: -20px;
  background-color: ${p => p.theme.colors.primary.default};
  margin-left: 70px;
  width: 10px;
  height: 10px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  ${boxProperties}
`;
export const DropDownWrapper = styled.div<BoxProps>`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover ${DropDownContent} {
    position: absolute;
    display: block;
  }
  &:hover ${ArrowTop} {
    display: block;
  }

  ${boxProperties}
`;

type MenuProps = {
  menuItems: React.ReactNode;
  children: React.ReactNode;
  align?: 'left' | 'right';
};

export const Menu: React.FC<React.PropsWithChildren<MenuProps & BoxProps>> = ({
  children,
  menuItems,
  align,
  ...props
}) => {
  return (
    <DropDownWrapper {...props}>
      {children}
      <ArrowTop />
      <DropDownContent align={align}>{menuItems}</DropDownContent>
    </DropDownWrapper>
  );
};
