import { CalendarIcon } from 'components/icons';
import { fr } from 'date-fns/locale';
import React from 'react';
import DatePickerElement, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Input } from './Input';

const DatePickerElementWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const DatePicker = (props: ReactDatePickerProps) => {
  registerLocale('fr', fr);
  const [localDate, setLocalDate] = React.useState<Date | null>(
    props?.value ? (props?.value as unknown as Date) : null
  );
  React.useEffect(() => {
    if (props.value === null) {
      setLocalDate(null);
    }
  }, [props.value]);
  return (
    <DatePickerElementWrapper>
      <DatePickerElement
        autoComplete="off"
        onSelect={date => {
          setLocalDate(date);
        }}
        selected={localDate}
        locale="fr"
        dateFormat={props.dateFormat ? props.dateFormat : 'dd/MM/yyyy'}
        showPopperArrow={false}
        showMonthYearPicker={props.showMonthYearPicker}
        customInput={
          <Input
            autoComplete="off"
            //@ts-ignore
            variantColor={props?.variantColor}
            isDisabled={props.disabled}
            isFullWidth
            icon={<CalendarIcon />}
          />
        }
        {...props}
      />
    </DatePickerElementWrapper>
  );
};
