import { CheckIcon } from 'components/icons';
import React, { forwardRef, InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { Box } from './Box';
import { useCombinedRefs } from './helpers/useCombinedRefs';

const Checker = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #003cc2;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  svg {
    min-width: 16px;
    min-height: 16px;
    transform: scale(0.8);
    stroke-width: 2px;
  }
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  line-height: 15px;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
`;

const CheckboxContainer = styled(Box)`
  position: relative;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:focus ~ ${Label}, &:hover ${Label} {
    ${Checker} {
      border-color: ${props => props.theme.colors.primary.default};
    }
  }

  input:not(checked) ~ ${Label} {
    ${Checker} {
      svg {
        display: none;
      }
    }
  }

  input:indeterminate ~ ${Label} {
    ${Checker} {
      background-color: ${props => props.theme.colors.primary.default};
      border-color: ${props => props.theme.colors.primary.default};
      font-size: 20px;
      svg {
        fill: white;
        display: block;
      }
    }
  }

  input:checked ~ ${Label} {
    ${Checker} {
      background-color: ${props => props.theme.colors.primary.default};
      border-color: ${props => props.theme.colors.primary.default};
      svg {
        fill: white;
        display: block;
      }
    }
  }
`;

type CheckboxProps = {
  children?: React.ReactNode;
  id?: string;
  name?: string;
  onClick?: any;
  isIndeterminate?: boolean;
};

export const CheckBox = forwardRef(
  (
    {
      children,
      id,
      name,
      onChange,
      onClick,
      checked,
      isIndeterminate,
      defaultChecked,
      ...props
    }: InputHTMLAttributes<HTMLInputElement> & CheckboxProps & SpaceProps,
    ref
  ) => {
    const thisRef = useRef(null) as React.RefObject<HTMLInputElement | null>;
    const combinedRef = useCombinedRefs(
      ref,
      thisRef,
      (el: any) => el && (el.indeterminate = isIndeterminate)
    ) as React.RefObject<HTMLInputElement>;

    return (
      <CheckboxContainer {...props}>
        <input
          type="checkbox"
          ref={combinedRef}
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          {...props}
        />
        <Label htmlFor={id} onClick={onClick}>
          <Checker>
            {isIndeterminate ? '-' : <CheckIcon color="#fff" />}
          </Checker>

          <div>{children}</div>
        </Label>
      </CheckboxContainer>
    );
  }
);

CheckBox.displayName = 'CheckBox';
