import { useAmplifyLogout } from '@commons';
import { InfoModal } from 'components/icons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { Box, Button, Flex, Text } from 'components/ui';
import React from 'react';
import { create } from 'react-modal-promise';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';

type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
};

export const SaveLaterModal = ({ onResolve, isOpen, layer }: Props) => {
  const theme = useTheme();
  const { mutateAsync: logout, status } = useAmplifyLogout();
  const history = useHistory();
  const onLogout = async () => {
    try {
      logout();
      history.push('/login');
    } catch (e) {}
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
      layer={layer}
    >
      <Box>
        <Flex mt={20} justifyContent="center" width={1 / 1}>
          <Box>
            <InfoModal width="255px" height="166px" />
          </Box>
        </Flex>
        <Box textAlign="center" mt={20}>
          <Text mb={10} variant="h2" color={theme.colors.text.secondary}>
            Vos informations ont bien été enregistrées !
          </Text>
          <Text variant="p">
            Vous pouvez vous reconnecter à tout moment pour terminer
            l’embarquement.
          </Text>
        </Box>
        <Flex mt={20} justifyContent="center" width={1 / 1}>
          <Box width={1 / 1}>
            <Button
              width={1 / 1}
              onClick={onLogout}
              isLoading={status === 'pending'}
            >
              Se déconnecter
            </Button>
          </Box>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showSaveLaterModal = create<Props>(SaveLaterModal);
