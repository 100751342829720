import { LogoConnecteed, Missioncon } from 'components/icons';
import { Box, Cell, Flex, Grid, Tab } from 'components/ui';
import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
export const Container = styled(Grid)`
  height: 80px;
  margin-bottom: 20px;
  width: 100%;
  //max-width: ${p => p.theme.breakpoints.xl};
  background-color: ${p => p.theme.colors.primary.white};
  border-bottom: solid 1px #d0d4e3;

  svg {
    font-size: 24px;
  }
`;
Container.displayName = 'TopBarContainer';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  //cursor: pointer;
  padding: 0 20px;
  border-left: 1px solid #e7e7e7;
  background-color: white;
  border-bottom: solid 1px #d0d4e3;
`;

export const CustomerTopBar = () => {
  const location = useLocation();
  const items = (
    <>
      <Flex
        ml={10}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <LogoConnecteed />
      </Flex>

      <Tab href={location?.pathname} icon={<Missioncon />}>
        CRA
      </Tab>
    </>
  );

  return (
    <Container cols={12}>
      <Cell x-span={{ xs: '8' }}>
        <Flex>{items}</Flex>
      </Cell>

      <Cell x-span={{ xs: '4' }} justifySelf="right">
        <Flex height="100%">
          <FlexWrapper style={{ cursor: 'pointer' }}>
            <Box borderLeft="1px solid #e7e7e7" />
          </FlexWrapper>
        </Flex>
      </Cell>
    </Container>
  );
};

CustomerTopBar.displayName = 'TopBar';

export default CustomerTopBar;
