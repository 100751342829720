import * as React from 'react';

function AccountCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00065 1.97717C4.32065 1.97717 1.33398 4.96384 1.33398 8.64384C1.33398 12.3238 4.32065 15.3105 8.00065 15.3105C11.6807 15.3105 14.6673 12.3238 14.6673 8.64384C14.6673 4.96384 11.6807 1.97717 8.00065 1.97717ZM4.71398 12.8305C5.00065 12.2305 6.74732 11.6438 8.00065 11.6438C9.25398 11.6438 11.0073 12.2305 11.2873 12.8305C10.3807 13.5505 9.24065 13.9772 8.00065 13.9772C6.76065 13.9772 5.62065 13.5505 4.71398 12.8305ZM12.2407 11.8638C11.2873 10.7038 8.97398 10.3105 8.00065 10.3105C7.02732 10.3105 4.71398 10.7038 3.76065 11.8638C3.08065 10.9705 2.66732 9.85717 2.66732 8.64384C2.66732 5.70384 5.06065 3.31051 8.00065 3.31051C10.9407 3.31051 13.334 5.70384 13.334 8.64384C13.334 9.85717 12.9207 10.9705 12.2407 11.8638ZM8.00065 4.64384C6.70732 4.64384 5.66732 5.68384 5.66732 6.97717C5.66732 8.27051 6.70732 9.31051 8.00065 9.31051C9.29398 9.31051 10.334 8.27051 10.334 6.97717C10.334 5.68384 9.29398 4.64384 8.00065 4.64384ZM8.00065 7.97717C7.44732 7.97717 7.00065 7.53051 7.00065 6.97717C7.00065 6.42384 7.44732 5.97717 8.00065 5.97717C8.55398 5.97717 9.00065 6.42384 9.00065 6.97717C9.00065 7.53051 8.55398 7.97717 8.00065 7.97717Z"
        fill="#808080"
      />
    </svg>
  );
}

export default AccountCircleIcon;
