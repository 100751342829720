/* eslint-disable react/display-name */
import { getPriceByUnit, getFullName, displayMonth } from '@commons';
import { Box, Text, Link, Status } from 'components/ui';
import React from 'react';

export const columns = [
  {
    disableSortBy: false,
    Header: 'Réf.Mission',
    accessor: 'displayRefMission',
    Cell: (item: any) => {
      const displayRefMission = item?.row?.original?.displayRefMission;
      return (
        <>
          <Text variant="p" ml={20}>
            <Link fontSize={14} mr={10}>
              {`${displayRefMission}`}
            </Link>
          </Text>
        </>
      );
    },
  },
  {
    disableSortBy: false,
    Header: 'Contact',
    accessor: 'contact',
    Cell: (item: any) => {
      const contact = item?.row?.original?.contact;
      return getFullName(contact) || 'N/A';
    },
  },
  {
    disableSortBy: false,
    Header: 'Email',
    accessor: 'email',
    Cell: (item: any) => {
      const contact = item?.row?.original?.contact;
      return <>{contact?.email || 'N/A'}</>;
    },
  },
  {
    disableSortBy: false,
    Header: 'Statut',
    accessor: 'state',
    Cell: (item: any) => {
      const status = item?.row?.original?.status;
      const obj: { color: 'grey' | 'success' | 'warning'; text: string } = {
        color: 'grey',
        text: '',
      };
      switch (status) {
        case 'INACTIVE':
          obj.color = 'grey';
          obj.text = 'Résilié';
          break;
        case 'ACTIVE':
          obj.color = 'success';
          obj.text = 'Actif';
          break;
        case 'PENDING':
          obj.color = 'warning';
          obj.text = 'En cours de souscription';
          break;
      }
      return (
        <Box>
          <Status variantColor={obj.color}>{obj.text}</Status>
        </Box>
      );
    },
  },
  {
    Header: 'PÉRIODE DE VALIDITÉ',
    accessor: 'validity',
    Cell: (item: any) => {
      const validityStart = new Date(item?.row?.original?.validityStart);
      const validityEnd = new Date(item?.row?.original?.validityEnd);
      return (
        <Text fontSize={14}>{`${displayMonth(validityStart)} > ${displayMonth(
          validityEnd as Date
        )}`}</Text>
      );
    },
  },
  {
    Header: 'Montant',
    accessor: 'PÉRIODE DE VALIDITÉ',
    Cell: (item: any) => {
      const price = item?.row?.original?.price;
      const unit = getPriceByUnit(item?.row?.original?.unit);
      return (
        <Text fontSize={14}>
          {price}
          {unit}
        </Text>
      );
    },
  },
];
