//import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { format as formatfns } from 'date-fns';
import React, { useEffect } from 'react';
import {
  FR_ERRORS,
  EN_ERRORS,
  FR_TEXTS,
  EN_TEXTS,
  EN_STATUS,
  FR_STATUS_JSON,
  FR_NOTIFICATIONS,
  EN_NOTIFICATIONS,
  FR_LINKS,
  EN_LINKS,
  FR_NOTIFICATIONS_PROVIDER,
  EN_NOTIFICATIONS_PROVIDER,
  FR_LINKS_PROVIDER,
  EN_LINKS_PROVIDER,
} from '@commons';

// initialize i18next with catalog and language to use
const resources = {
  fr: {
    texts: FR_TEXTS,
    status: FR_STATUS_JSON,
    errors: FR_ERRORS,
    notifications: FR_NOTIFICATIONS,
    notificationsProvider: FR_NOTIFICATIONS_PROVIDER,
    links: FR_LINKS,
    linksProvider: FR_LINKS_PROVIDER,
  },
  en: {
    texts: EN_TEXTS,
    status: EN_STATUS,
    errors: EN_ERRORS,
    notifications: EN_NOTIFICATIONS,
    notificationsProvider: EN_NOTIFICATIONS_PROVIDER,
    links: EN_LINKS,
    linksProvider: EN_LINKS_PROVIDER,
  },
};

type Props = {
  children: React.ReactNode;
};
export const Translator = ({ children }: Props) => {
  const translateLang = 'fr';
  useEffect(() => {
    i18next.init({
      interpolation: {
        format: function (value, format) {
          if (format === 'uppercase') return value.toUpperCase();
          if (value instanceof Date) return formatfns(value, format as string);
          return value;
        },
      },
      resources,
      lng: translateLang,
    });
  }, [translateLang]);

  return <>{children}</>;
};

export default Translator;
