import { EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import { create } from 'zustand';

type State = {
  pageIndex: number;
  updateInvoiceStore: (state: Partial<State>) => void;
  resetInvoiceStore: (state: Partial<State>) => void;
  selectedStatus: EInvoiceStatus | 'ALL';
  selectedRowIds: Array<string>;
  startDate: Date | null;
  endDate: Date | null;
  searchQuery: string;
  columnsHidden: Array<any>;
};

const initialStates = {
  startDate: null,
  endDate: null,
  selectedStatus: 'ALL' as const,
  pageIndex: 0,
  selectedRowIds: [],
  searchQuery: '',
  columnsHidden: [],
};

const store = (set: any) => ({
  ...initialStates,
  updateInvoiceStore: (state: Partial<State>) => set(state),
  resetInvoiceStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useInvoiceListStore = create<State>(store);
