import { buildOneLineAddress, getFullName, getTradeNameSafe } from '@commons';
import { EtablishementTreeIcon, EyeIcon } from 'components/icons';
import { Box, boxProperties, BoxProps, Flex, Text } from 'components/ui';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { FormLabel } from '../Form';
import { LabelField } from '../LabelField';
import { Link } from '../Link';
const ContainerCard = styled(Box)<{
  temporary?: boolean | undefined;
  withAddress?: boolean | undefined;
  height?: string | undefined;
}>`
  width: 570px;
  height: ${props => (props?.height ? props?.height : '168px')};
  margin: 38px 28px 30px 1px;
  padding: 21px 40px 26px 28px;
  border-radius: 4px;
  border: ${props =>
    props.temporary ? 'dashed 1px #d0d4e3' : 'solid 1px #d0d4e3'};

  background-color: var(--color-white);
  ${boxProperties}
`;
type Props = {
  establishment: any;
  status: React.ReactNode;
  temporary?: boolean | undefined;
  withAddress?: boolean | undefined;
  height?: string | undefined;
};

export const EstablishmentCard = ({
  establishment,
  status,
  temporary,
  withAddress,
  height,
  ...props
}: BoxProps & Props) => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <ContainerCard height={height} temporary={temporary}>
      <Flex opacity={temporary ? '0.7' : '1'}>
        <Box>
          <EtablishementTreeIcon fontSize={60} />
        </Box>
        <Flex width={1 / 1} flexWrap="wrap">
          <Box ml={'15px'}>
            <Text variant="b" color={theme.colors.text.secondary}>
              {getTradeNameSafe(establishment)}
            </Text>
          </Box>
          {withAddress && (
            <Box
              width={1 / 1}
              ml={'15px'}
              mt={'2px'}
              opacity={temporary ? '0.7' : '1'}
            >
              <Flex width="100%">
                <LabelField
                  label="adresse"
                  value={buildOneLineAddress(establishment?.address)}
                />
              </Flex>
            </Box>
          )}
          <Flex width="100%">
            <FormLabel
              color={theme.colors.primary.inputLabel}
              ml={'15px'}
              mt={'2px'}
            >
              {establishment?.siret ? 'N° SIRET :' : `N° D'IDENTIFICATION`}
            </FormLabel>
            <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
              {establishment?.siret ?? establishment?.identifier}
            </Text>
          </Flex>
        </Flex>
        <Box ml={'15px'}>
          <Box width={'100px'}>
            {!temporary && (
              <Link
                onClick={() =>
                  history.push(
                    `/headquarter/establishment/${establishment?.uuid}/information`
                  )
                }
                iconLeft={<EyeIcon />}
              >
                Voir la fiche
              </Link>
            )}
          </Box>
        </Box>
      </Flex>

      {establishment?.provider?.manager?.firstName && (
        <Box width={1 / 1} mt={20} opacity={temporary ? '0.7' : '1'}>
          <Flex width="100%">
            <FormLabel
              ml={'15px'}
              mt={'2px'}
              color={theme.colors.primary.inputLabel}
            >
              Responsable de compte :
            </FormLabel>
            <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
              {getFullName(establishment?.provider?.manager)}
            </Text>
          </Flex>
        </Box>
      )}
      <Box width={1 / 1} opacity={temporary ? '0.7' : '1'}>
        {!temporary && (
          <Flex width="100%">
            <Link
              ml={'15px'}
              mt={'10px'}
              onClick={() =>
                history.push(
                  `/headquarter/establishment/${establishment?.uuid}/information`
                )
              }
            >
              Modifier les coordonnées bancaires
            </Link>
          </Flex>
        )}
      </Box>
      <Box>{status}</Box>
    </ContainerCard>
  );
};
EstablishmentCard.displayName = 'EstablishmentCard';

export default EstablishmentCard;
