import { Box, Button, CheckBox, Radio, Text } from 'components/ui';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import styled, { useTheme } from 'styled-components';
import { ModalFrame, ModalProps } from './ModalFrame';
const Actions = styled.div`
  display: flex;
`;

type Props = ModalProps & {
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string;
  text?: string;
  closeIcon?: boolean;
  radioConfirmation?: boolean;
  checkboxConfirmation?: string[];
  beforeValidation?: () => void;
  btnFull?: boolean;
  width?: number;
};

export const DialogModal = ({
  onResolve,
  isOpen,
  text,
  title,
  confirmLabel = 'Valider',
  cancelLabel,
  closeIcon = false,
  radioConfirmation = false,
  checkboxConfirmation = [],
  btnFull,
  width = 650,
  beforeValidation,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [isRadioConfirmed, setRadioConfirmed] = useState(!radioConfirmation);
  const [checkboxConfirmedCount, setCheckboxConfirmedCount] = useState(0);
  const theme = useTheme();
  const onValidate = async () => {
    try {
      if (beforeValidation) {
        setLoading(true);
        await beforeValidation();
        setLoading(false);
      }
      onResolve(true);
    } catch (err) {
      //
    }
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={width}
      closeIcon={closeIcon}
    >
      {title && (
        <Text variant="h2" color={theme.colors.text.secondary} mb={30}>
          {title}
        </Text>
      )}
      {text && (
        <Text variant="p" mb={30}>
          {text}
        </Text>
      )}
      {radioConfirmation && (
        <Box mb={20}>
          <Radio
            name="confirmation"
            onChange={e => e.currentTarget.checked && setRadioConfirmed(true)}
          >
            Oui
          </Radio>
          <Radio
            name="confirmation"
            onChange={e => e.currentTarget.checked && setRadioConfirmed(false)}
          >
            Non
          </Radio>
        </Box>
      )}
      {!!checkboxConfirmation.length && (
        <Box mb={20}>
          {checkboxConfirmation.map(message => {
            return (
              <CheckBox
                mb={10}
                key={message}
                id={message}
                onChange={e => {
                  if (e.currentTarget.checked)
                    setCheckboxConfirmedCount(count => count + 1);
                  else setCheckboxConfirmedCount(count => count - 1);
                }}
              >
                <Box>{message}</Box>
              </CheckBox>
            );
          })}
        </Box>
      )}
      <Actions style={{ width: btnFull ? '100%' : 'auto' }}>
        <Button
          style={{ width: btnFull ? '100%' : 'auto' }}
          isDisabled={
            !isRadioConfirmed ||
            checkboxConfirmedCount !== checkboxConfirmation.length
          }
          onClick={onValidate}
          mr={10}
          isLoading={loading}
          id="confirm-modal"
        >
          {confirmLabel}
        </Button>
        {cancelLabel && (
          <Button
            style={{ width: btnFull ? '100%' : 'auto' }}
            variant="ghost"
            id="cancel-modal"
            onClick={() => onResolve(false)}
          >
            {cancelLabel}
          </Button>
        )}
      </Actions>
    </ModalFrame>
  );
};

export const showDialogModal = create<Props, boolean>(DialogModal);
