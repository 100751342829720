import { create } from 'zustand';

type State = {
  message: string;
  open: boolean;
  variant: 'success' | 'error' | 'info';
  updateNotifications: (state: Partial<State>) => void;
  resetNotifications: (state: Partial<State>) => void;
};

const initialStates = {
  message: '',
  open: false,
  variant: 'success' as const,
};

const store = (set: any) => ({
  ...initialStates,
  updateNotifications: (state: Partial<State>) => set(state),
  resetNotifications: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useNotificationStore = create<State>(store);
