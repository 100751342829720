/* eslint-disable react/display-name */
import { Header } from 'components/Header';
import { EContractorStatus, EDpaeStatus } from '@freelancelabs/teoreme-commons';
import { SearchIcon } from 'components/icons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { Button, Box, Cell, FormLabel, Grid, Input, Text } from 'components/ui';
import Table from 'components/ui/Table';
import React from 'react';
import { useLogic } from './hook';

const grid = {
  xs: '12',
  sm: '8',
  md: '4',
};

export const ContractorList = () => {
  const {
    updateContractorListStore,
    status,
    isFetching,
    data,
    columns,
    setFetchInfo,
    selectedStatus,
    searchQuery,
    pageIndex,
    columnsHidden,
    history,
    filterDPAE,
  } = useLogic();

  //@ts-ignore
  const wrappedData = data?.contractors?.map((contractor: any) => {
    return { ...contractor };
  });
  return (
    <Box width={1 / 1}>
      <Header height="auto">
        <Text variant="h1">Intervenants</Text>
      </Header>
      <Text variant="p" mt={-40} mb={20}>
        Retrouvez ici l'ensemble de vos intervenants et accédez à leur fiche
        détaillée.
      </Text>
      <Box mb={20}>
        <Grid cols={12} gap={'20px'}>
          <Cell x-span={grid}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              icon={<SearchIcon />}
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) =>
                updateContractorListStore({
                  searchQuery: e.target.value,
                  pageIndex: 0,
                })
              }
            />
          </Cell>

          <Cell x-span={grid}>
            <FormLabel>STATUT DES DPAE</FormLabel>
            <StaticSelect
              width={1 / 1}
              options={[
                {
                  //@ts-ignore
                  value: undefined,
                  key: undefined,
                  label: 'Tous les statuts',
                },
                {
                  //@ts-ignore
                  value: EDpaeStatus.PENDING,
                  key: undefined,
                  label: 'Action requise',
                },
                {
                  //@ts-ignore
                  value: EDpaeStatus.TO_BE_VALIDATED,
                  key: EDpaeStatus.TO_BE_VALIDATED,
                  label: 'En attente de validation',
                },
                //...DPAE_STATUS_SELECT,
                {
                  //@ts-ignore
                  value: 'N/A',
                  key: 'N/A',
                  label: 'Non requise',
                },
              ]}
              value={filterDPAE}
              onChange={(value: any) => {
                updateContractorListStore({
                  filterDPAE: value as EDpaeStatus,
                  pageIndex: 0,
                });
              }}
            />
          </Cell>
          <Cell x-span={grid}>
            <FormLabel>STATUT DE L'intervenant</FormLabel>
            <StaticSelect
              width={1 / 1}
              options={[
                {
                  //@ts-ignore
                  value: undefined,
                  key: undefined,
                  label: 'Tous les statuts',
                },
                {
                  label: 'Actif',
                  key: EContractorStatus.ACTIVE,
                  value: EContractorStatus.ACTIVE,
                },
                {
                  label: 'En attente de validation',
                  key: EContractorStatus.PENDING,
                  value: EContractorStatus.PENDING,
                },
                {
                  label: 'Refusé',
                  key: 'REJECTED',
                  value: 'REJECTED',
                },
              ]}
              value={selectedStatus}
              onChange={value =>
                updateContractorListStore({
                  selectedStatus: value as 'ACTIVE' | 'PENDING',
                  pageIndex: 0,
                })
              }
              placeholder="Statut social"
            />
          </Cell>
          <Cell x-span={grid} placeSelf="left">
            <Button
              onClick={() => history.push('/contractors/create')}
              variant="filled"
            >
              Créer un nouvel intervenant
            </Button>
          </Cell>
        </Grid>
      </Box>
      <Box width={1 / 1}>
        <Table
          onFetchData={setFetchInfo}
          controlledPageIndex={pageIndex}
          setPageIndex={pageIndex => updateContractorListStore({ pageIndex })}
          identifier="uuid"
          controlledHiddenColumns={columnsHidden}
          initialSortBy={[{ id: 'firstName', desc: true }]}
          columns={columns}
          data={wrappedData}
          setSelectedRowsId={() => {}}
          controlledPageCount={Math.ceil(data.totalCount / data.limit)}
          totalCount={data.totalCount}
          labelData="Intervenants"
          isFetching={status === 'success' && isFetching}
          isLoading={status === 'pending'}
        />
      </Box>
    </Box>
  );
};

ContractorList.displayName = 'ContractorList';

export default ContractorList;
