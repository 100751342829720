import {
  ECraStatus,
  EadditionalActivityStatus,
} from '@freelancelabs/teoreme-commons';
import { create } from 'zustand';

type State = {
  pageSize: number;
  sort?: string;
  pageIndex: number;
  updateActivityFlateRateStore: (state: Partial<State>) => void;
  resetActivityFlatRateStore: (state: Partial<State>) => void;
  selectedStatus: ECraStatus | EadditionalActivityStatus | 'ALL';
  selectedCustomer?: string;
  selectedProvider?: string;
  selectedContractor?: string;
  selectedRowIds: Array<string>;
  startDate: Date | null;
  endDate: Date | null;
  searchQuery: string;
  columnsHidden: Array<any>;
  isOpen?: boolean;
};

const initialStates = {
  pageSize: 20,
  sort: '-submitedAt',
  startDate: null,
  endDate: null,
  selectedStatus: 'ALL' as const,
  selectedCustomer: undefined,
  selectedProvider: undefined,
  selectedContractor: undefined,
  pageIndex: 0,
  selectedRowIds: [],
  searchQuery: '',
  columnsHidden: [],
  isOpen: true,
};

const store = (set: any) => ({
  ...initialStates,
  updateActivityFlateRateStore: (state: Partial<State>) => set(state),
  resetActivityFlatRateStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useActivityFlatRateStore = create<State>(store);
