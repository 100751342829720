import {
  CRA_STATUS_SELECT,
  createDateToUrl,
  customerValidationReminder,
  getActivityStatus,
  getFullName,
  getHumanDate,
  getHumanDateMonthAndYear,
  getStandByDutiesTotalAmount,
  getTradeNameSafe,
  MONTH_FR,
  round,
  transformActivitiesForTable,
  useActivitiesReportFindMany,
  useMissionFindMany,
  useProviderStatistics,
} from '@commons';
import {
  EadditionalActivityStatus,
  EBillingType,
  ECraStatus,
  EMissionCraValidatedBy,
  IActivityReportData,
  IAdditionalActivity,
  IEstablishment,
  IJoinedAdditionalActivityWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { Accordion } from 'components/Accordion';
import { Header } from 'components/Header';
import { InfoIconBlue } from 'components/icons';
import { showCreateActivityModal } from 'components/modals/CreateActivityModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  Box,
  Button,
  Cell,
  CheckBox,
  ColorTextBox,
  CustomToolTip,
  DatePickerRange,
  Flex,
  FormLabel,
  Grid,
  Input,
  Pagination,
  SpinnerBox,
  SubItem,
  Text,
} from 'components/ui';
import {
  DutyTimeIcon,
  FeesIcon,
  SwitchIcon,
  TimeLapsIcon,
} from 'components/ui/icons';
import { useDebounce, useSetCrumbs, useShowMessage } from 'hooks';
import { snakeCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useActivityReportListStore } from 'store';
import { Theme } from 'styles';
import { getDefaultFilterObject } from './TableConfig';

const grid3 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '3',
  xl: '3',
};
const getStatusData = (
  status: ECraStatus | EadditionalActivityStatus
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
} => {
  switch (status) {
    case ECraStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case ECraStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validé`,
        textColor: Theme.colors.success.default,
      };
    case ECraStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusé`,
        textColor: Theme.colors.error.default,
      };
    case ECraStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivé`,
        textColor: Theme.colors.grey.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};
export declare enum DataType {
  CRA = 'CRA',
  DUTY = 'DUTY',
  FEES = 'FEES',
}
const dataType = {
  CRA: 'Prestation standard',
  DUTY: 'Prestation complémentaire',
  FEES: 'Frais',
};
export const getTabsTextsByState = (state: 'pending' | 'validated') => {
  switch (snakeCase(state).toUpperCase()) {
    case 'pending': {
      return 'Retrouvez ici l’ensemble des CRA validés pour lesquels une facture fournisseur valide a été transmise à nos conseillers Connecteed.';
    }
    case 'validated': {
      return 'Retrouvez ici l’ensemble des CRA validés par votre conseiller Connecteed et pour lesquels vous pouvez émettre les factures associées.';
    }
  }
};
const TabItems = () => {
  const { data: providerStats, isFetching: isFetchingStats } =
    useProviderStatistics();
  return (
    <>
      <SubItem variant="primary" href={'/invoices/activities/list/pending'}>
        A traiter ({' '}
        {isFetchingStats
          ? '...'
          : providerStats?.dayMission?.totalActivityReportWaitingForAction}{' '}
        )
      </SubItem>
      <SubItem variant="primary" href={'/invoices/activities/list/validated'}>
        Validées ({' '}
        {isFetchingStats
          ? '...'
          : providerStats?.dayMission?.totalActivityReportTreated}{' '}
        )
      </SubItem>
    </>
  );
};
const ActivityRow = ({ activity }: any) => {
  const duties = activity?.children?.filter(
    (item: any) => item?.type === 'DUTY'
  );
  const fees = activity?.children?.filter((item: any) => item?.type === 'FEES');
  const lastCraStatus = getStatusData(activity?.cra?.state);
  const lastDutyStatus = getStatusData(
    getActivityStatus(activity, ['CRA', 'FEES'])
  );
  const lastFeesStatus = getStatusData(
    getActivityStatus(activity, ['CRA', 'DUTY'])
  );

  return (
    <Flex width={1 / 1} alignItems="center">
      <Box width={2 / 12}>{getHumanDateMonthAndYear(activity?.month)}</Box>
      <Box width={2 / 12}>{getTradeNameSafe(activity?.estCustomer, 20)}</Box>
      <Box width={2 / 12}>{getTradeNameSafe(activity?.estProvider, 20)}</Box>
      <Box width={2 / 12}>{getFullName(activity?.contractor)}</Box>
      <Box width={2 / 12}>
        <Flex>
          <Box mr={10}>
            <CustomToolTip
              color={lastCraStatus?.textColor}
              id={`cra_icon_${activity?.cra?.refCra}`}
              text={`CRA ( ${1} )`}
            >
              <TimeLapsIcon fill={lastCraStatus?.textColor} />
            </CustomToolTip>
          </Box>
          {duties?.length > 0 && (
            <Box mr={10}>
              <CustomToolTip
                color={lastDutyStatus?.textColor}
                id={`duty_icon_${duties?.[0]?.uuid}`}
                text={`Prestations complémentaires ( ${duties?.length} )`}
              >
                <DutyTimeIcon fill={lastDutyStatus?.textColor} />
              </CustomToolTip>
            </Box>
          )}
          {fees?.length > 0 && (
            <Box mr={10}>
              <CustomToolTip
                color={lastFeesStatus?.textColor}
                id={`fees_icon_${fees?.[0]?.uuid}`}
                text={`Frais ( ${fees?.length} )`}
              >
                <FeesIcon fill={lastFeesStatus?.textColor} />
              </CustomToolTip>
            </Box>
          )}
        </Flex>
      </Box>
      <Box width={2 / 12}>
        <ActivityRowAction data={activity} />
      </Box>
    </Flex>
  );
};

const ActivityRowAction = ({ data, isLine }: any) => {
  const history = useHistory();
  const showMessage = useShowMessage();
  const [isLoadingReminder, setLoadingReminder] = useState(false);
  const type = data?.type;
  const status = type !== 'CRA' ? data?.status : data?.state;
  const isOpen = data?.isOpen;
  const month = data?.month;
  const defaultOpenAccordion = type === 'CRA' ? 'CRA' : 'STAND_BY_DUTY';
  const activeCraResendButton =
    type === 'CRA' &&
    data?.craValidatedBy === EMissionCraValidatedBy.CUSTOMER_CONTACT &&
    data?.state === ECraStatus.TO_BE_VALIDATED;
  const onSendReminder = async () => {
    setLoadingReminder(true);
    try {
      await customerValidationReminder({
        refCra: data?.cra?.refCra || data?.refCra,
      });
      showMessage('success', 'La relance à bien été envoyée');
    } catch (e) {
      setLoadingReminder(false);
    }
    setLoadingReminder(false);
  };
  if (!isOpen) {
    return (
      <Flex mr={20} justifyContent="flex-end" height={1 / 1} width={1 / 1}>
        <CustomToolTip
          id={month + '_3'}
          text={
            <Text color="white">
              La saisie sera disponible à partir du dernier jours ouvrable du
              mois {new Date(month)?.getUTCMonth() === 3 ? "d'" : 'de '}
              {MONTH_FR[new Date(month)?.getUTCMonth()]}
            </Text>
          }
        >
          <InfoIconBlue fontSize={25} />
        </CustomToolTip>
      </Flex>
    );
  }
  if (status === EadditionalActivityStatus?.TO_BE_SUBMITTED) {
    return (
      <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
        <Button
          minWidth="140px"
          height="33.5px"
          onClick={() =>
            history.push(
              `/invoices/activities/${data?.mission}/${createDateToUrl(
                data?.month
              )}`,
              { defaultOpenAccordion }
            )
          }
        >
          Soumettre
        </Button>
      </Flex>
    );
  }
  if (status === EadditionalActivityStatus?.TO_BE_FILLED) {
    return (
      <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
        <Button
          minWidth="140px"
          height="33.5px"
          onClick={() =>
            history.push(
              `/invoices/activities/${data?.mission}/${createDateToUrl(
                data?.month
              )}`,
              { defaultOpenAccordion }
            )
          }
        >
          Déclarer
        </Button>
      </Flex>
    );
  }
  if (status === EadditionalActivityStatus?.TO_BE_VALIDATED) {
    return (
      <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
        {activeCraResendButton && isLine && (
          <Button
            isLoading={isLoadingReminder}
            variant="filled"
            minWidth="140px"
            height="33.5px"
            mr={'5px'}
            onClick={() => onSendReminder()}
          >
            Relancer
          </Button>
        )}
        <Button
          variant="ghost"
          minWidth="140px"
          height="33.5px"
          onClick={() =>
            history.push(
              `/invoices/activities/${data?.mission}/${createDateToUrl(
                data?.month
              )}`,
              { defaultOpenAccordion }
            )
          }
        >
          Consulter
        </Button>
      </Flex>
    );
  }
  //DEFAULT
  return (
    <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
      <Button
        variant="ghost"
        minWidth="140px"
        height="33.5px"
        onClick={() =>
          history.push(
            `/invoices/activities/${data?.mission}/${createDateToUrl(
              data?.month
            )}`,
            { defaultOpenAccordion }
          )
        }
      >
        Consulter
      </Button>
    </Flex>
  );
};

const getIconType = (type: 'CRA' | 'DUTY' | 'FEES', fill?: string) => {
  if (type === 'CRA') {
    return <TimeLapsIcon fill={fill || Theme?.colors?.primary?.default} />;
  }
  if (type === 'DUTY') {
    return <DutyTimeIcon fill={fill || Theme?.colors?.primary?.default} />;
  }
  if (type === 'FEES') {
    return <FeesIcon fill={fill || Theme?.colors?.primary?.default} />;
  }
};
export const ActivitiesList = () => {
  const history = useHistory();
  const { data: providerStats } = useProviderStatistics();
  // const haveTimeSpentMission =
  //   providerStats && providerStats?.dayMission?.totalCount > 0;
  const haveFlateRateMission =
    providerStats && providerStats?.flatRateMission?.totalCount > 0;
  const flateRatePendingTotalCount =
    providerStats?.flatRateMission?.totalMilestoneReportWaitingForAction;

  const { filter: filterStatus } = useParams<{
    filter: 'pending' | 'validated';
  }>();
  const {
    updateActivityReportStore,
    resetActivityReportStore,
    startDate,
    endDate,
    selectedStatus,
    selectedCustomer,
    searchQuery,
    pageIndex,
    sort,
    pageSize,
    isOpen,
  } = useActivityReportListStore();
  const { data: missionQuery } = useMissionFindMany({
    filterObject: {
      'provider.contact': localStorage?.cognitoUserId,
    },
    limit: 100,
  });

  const customerEstablishment: IEstablishment[] | [] = [];
  missionQuery?.missions?.forEach(m => {
    if (
      !customerEstablishment?.find(
        c => c?.uuid === m?.customer?.establishment?.uuid
      )
    ) {
      customerEstablishment?.push(m?.customer?.establishment as never);
    }
  });
  const CUSTOMER_ESTABLISHMENT_SELECT = customerEstablishment
    ?.map(ce => {
      return {
        key: ce?.uuid,
        label: getTradeNameSafe(ce),
        value: ce?.uuid as string,
        data: ce,
      };
    })
    .filter(k => k?.key !== undefined);

  const debouncedFilterQuery: string = useDebounce(searchQuery, 400);
  const {
    data: activitiesData,
    status: statusActivitiesReport,
    isFetching,
  } = useActivitiesReportFindMany({
    filterObject: getDefaultFilterObject(filterStatus, {
      startDate,
      endDate,
      selectedStatus,
      debouncedFilterQuery,
      selectedCustomer,
      isOpen,
    }),
    skip: pageIndex * pageSize,
    limit: pageSize,
    sort: sort,
  });
  useSetCrumbs(
    [],
    [
      { label: 'CRA & Factures', path: '/activities/list' },
      { label: 'CRA', path: '/activities/list' },
    ]
  );

  const totalCount = activitiesData?.totalCount;
  const items = transformActivitiesForTable(
    activitiesData?.reports as IActivityReportData[]
  ) as any;
  const p = round(totalCount ? Math.ceil(totalCount / pageSize) : 0, 0);
  const pageCount = Number.isInteger(p) ? p : p + 1;
  const getpageOptions = () => {
    const opt = [];
    for (let i = 0; i < pageCount; i++) {
      opt?.push(i + 1);
    }
    return opt;
  };
  const pOptions = getpageOptions();
  const pagination = {
    canPreviousPage: pageIndex >= 1,
    canNextPage: pageIndex + 2 <= pageCount,
    pageOptions: pOptions,
    pageCount,
    pageIndex,
    gotoPage: (index: number) =>
      updateActivityReportStore({ pageIndex: index }),
    setPageSize: (number: any) =>
      updateActivityReportStore({ pageSize: number, pageIndex: 0 }),
    pageSize,
    labelData: 'Activités',
    totalCount,
  };

  useEffect(() => {
    resetActivityReportStore({ selectedCustomer });
  }, [filterStatus]);
  const getProviderExpensesTotalAmount = (
    aa: IAdditionalActivity | IJoinedAdditionalActivityWithInvoice
  ): number => {
    let total = 0;
    aa?.expenseLines?.forEach(expense => (total = total + expense?.amount));
    return total;
  };

  return (
    <Box>
      <Header tabs={<TabItems />}>
        <Flex justifyContent="space-between">
          <Text variant="h1"> Activités au temps passé</Text>
          {haveFlateRateMission && (
            <Button
              onClick={() =>
                history.push('/invoices/flat-rate/activities/list/pending')
              }
            >
              <Flex alignItems={'center'}>
                <SwitchIcon
                  fontSize={22}
                  style={{ marginRight: '5px' }}
                  fill={'white'}
                />
                {`Forfait (${flateRatePendingTotalCount})`}
              </Flex>
            </Button>
          )}
        </Flex>
      </Header>
      <Text variant="p" mt={20}>
        {getTabsTextsByState(filterStatus)}
      </Text>
      <Flex width={1 / 1} justifyContent={'flex-end'}>
        <Button
          onClick={() =>
            showCreateActivityModal({ billingType: EBillingType.DAY })
          }
        >
          Ajouter
        </Button>
      </Flex>
      <Box mt={20} mb={20}>
        <Grid cols={12}>
          <Cell x-span={grid3}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) =>
                updateActivityReportStore({
                  searchQuery: e.target.value,
                  pageIndex: 0,
                })
              }
              pr={20}
            />
          </Cell>

          <Cell x-span={grid3}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value =>
                updateActivityReportStore({ startDate: value, pageIndex: 0 })
              }
              setEndDate={value =>
                updateActivityReportStore({ endDate: value, pageIndex: 0 })
              }
            />
          </Cell>
          <Cell x-span={grid3} pl={20}>
            <FormLabel>Client</FormLabel>
            <StaticSelect
              isClearable
              width={1 / 1}
              options={CUSTOMER_ESTABLISHMENT_SELECT}
              value={selectedCustomer}
              onChange={value => {
                updateActivityReportStore({
                  selectedCustomer: value as string | undefined,
                  pageIndex: 0,
                });
              }}
              placeholder="Établissements"
            />
          </Cell>
          <Cell x-span={grid3} pl={20}>
            <FormLabel>STATUT</FormLabel>
            <StaticSelect
              width={1 / 1}
              options={CRA_STATUS_SELECT}
              value={selectedStatus}
              onChange={value =>
                updateActivityReportStore({
                  selectedStatus: value as 'ALL' | ECraStatus | undefined,
                  pageIndex: 0,
                })
              }
              placeholder="Statut ( cra , prestation complémentaire , frais ) "
            />
          </Cell>
          {filterStatus === 'pending' && (
            <Cell x-span={grid3}>
              <FormLabel></FormLabel>
              <Flex
                mt={10}
                justifyContent={'flex-start'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <Box>
                  <CheckBox
                    checked={isOpen}
                    onChange={() =>
                      updateActivityReportStore({
                        isOpen: !isOpen,
                        pageIndex: 0,
                      })
                    }
                    id="isOpen"
                  >
                    {'Activités ouvertes à la saisie uniquement'}
                  </CheckBox>
                </Box>
              </Flex>
            </Cell>
          )}
        </Grid>
      </Box>
      {statusActivitiesReport === 'success' ? (
        <Box width={1 / 1} mt={20}>
          {items?.length > 0 && (
            <Flex width={1 / 1} mb={'20px'} pl={'12px'} pr={'36px'}>
              <Flex
                onClick={() =>
                  updateActivityReportStore({
                    sort: sort === '-month' ? 'month' : '-month',
                  })
                }
                width={2 / 12}
                cursor="pointer"
              >
                <Text fontWeight="bolder" variant="span">
                  Mois {sort === 'month' ? '↓' : sort === '-month' ? ' ↑' : ''}
                </Text>
              </Flex>
              <Flex
                onClick={() =>
                  updateActivityReportStore({
                    sort:
                      sort === '-estCustomer' ? 'estCustomer' : '-estCustomer',
                  })
                }
                cursor="pointer"
                width={2 / 12}
              >
                <Text fontWeight="bolder" variant="span">
                  Client{' '}
                  {sort === 'estCustomer'
                    ? '↓'
                    : sort === '-estCustomer'
                      ? ' ↑'
                      : ''}
                </Text>
              </Flex>
              <Flex
                onClick={() =>
                  updateActivityReportStore({
                    sort:
                      sort === '-estCustomer' ? 'estCustomer' : '-estCustomer',
                  })
                }
                cursor="pointer"
                width={2 / 12}
              >
                <Text fontWeight="bolder" variant="span">
                  Fournisseur{' '}
                  {sort === 'estProvider'
                    ? '↓'
                    : sort === '-estProvider'
                      ? ' ↑'
                      : ''}
                </Text>
              </Flex>
              <Flex
                onClick={() =>
                  updateActivityReportStore({
                    sort: sort === '-contractor' ? 'contractor' : '-contractor',
                  })
                }
                cursor="pointer"
                width={2 / 12}
              >
                <Text fontWeight="bolder" variant="span">
                  Intervenant{' '}
                  {sort === 'contractor'
                    ? '↓'
                    : sort === '-contractor'
                      ? ' ↑'
                      : ''}
                </Text>
              </Flex>
              <Flex width={2 / 12}>
                <Text fontWeight="bolder" variant="span">
                  Déclarations
                </Text>
              </Flex>
              <Flex width={2 / 12}>
                <Text
                  width={1 / 1}
                  textAlign="end"
                  fontWeight="bolder"
                  variant="span"
                  pr={90}
                >
                  Actions
                </Text>
              </Flex>
            </Flex>
          )}
          {items?.length > 0 ? (
            items?.map((activity: any, index: number) => {
              return (
                <Accordion
                  openOnTop
                  propsBar={{
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #d0d4e3',
                  }}
                  childrenTop={<ActivityRow activity={activity} />}
                  key={`activity_${filterStatus}_${index}`}
                >
                  <Box
                    position="relative"
                    top={-5}
                    p={10}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor={'#d0d4e3'}
                  >
                    <Flex
                      width={1 / 1}
                      borderBottom="1px solid"
                      borderColor={'#d0d4e3'}
                    >
                      <Box pt={10} pl={10} pb={10} width={4 / 16}>
                        <Text
                          fontSize={10}
                          variant="b"
                          color={Theme?.colors?.primary?.light}
                        >
                          Type
                        </Text>
                      </Box>
                      <Box pt={10} pb={10} pl={10} width={2 / 16}>
                        <Text
                          fontSize={10}
                          variant="b"
                          color={Theme?.colors?.primary?.light}
                        >
                          Déclaré le
                        </Text>
                      </Box>
                      <Box pt={10} pb={10} width={2 / 16}>
                        <Text
                          fontSize={10}
                          variant="b"
                          color={Theme?.colors?.primary?.light}
                        >
                          Total HT
                        </Text>
                      </Box>
                      <Box pt={10} pb={10} width={2 / 16}>
                        <Text
                          fontSize={10}
                          variant="b"
                          color={Theme?.colors?.primary?.light}
                        >
                          Statut
                        </Text>
                      </Box>
                      <Box
                        textAlign="end"
                        pr={60}
                        pt={10}
                        pb={10}
                        width={8 / 16}
                      >
                        <Text
                          fontSize={10}
                          variant="b"
                          color={Theme?.colors?.primary?.light}
                        >
                          Actions
                        </Text>
                      </Box>
                    </Flex>
                    {activity?.children?.map(
                      (additional: any, index: number) => {
                        const statuscolors = getStatusData(
                          additional?.type !== 'CRA'
                            ? additional?.status
                            : additional?.state
                        );
                        return (
                          <Flex
                            width={1 / 1}
                            key={`additional_${index}`}
                            p={10}
                          >
                            <Box width={4 / 16}>
                              <Flex>
                                <Box mr={'5px'}>
                                  {getIconType(additional?.type)}
                                </Box>
                                <Box>
                                  {dataType?.[additional?.type as DataType]}
                                </Box>
                              </Flex>
                            </Box>
                            <Box width={2 / 16}>
                              {additional?.type === 'CRA'
                                ? additional.state === ECraStatus.VALIDATED
                                  ? getHumanDate(
                                      additional?.submittedAt ||
                                        additional?.stateChangedAt
                                    )
                                  : additional?.submittedAt
                                    ? getHumanDate(additional?.submittedAt)
                                    : 'N/A'
                                : additional?.submittedAt
                                  ? getHumanDate(additional?.submittedAt)
                                  : 'N/A'}
                            </Box>
                            <Box width={2 / 16}>
                              <ColorTextBox variantColor="primary" ml={'-5px'}>
                                {additional?.type === 'CRA' &&
                                  `${
                                    additional?.nbWorkingDays
                                      ? `${round(
                                          additional?.contractorRate *
                                            additional?.nbWorkingDays
                                        )}€`
                                      : 'N/A'
                                  }`}
                                {additional?.type === 'FEES' &&
                                  `${getProviderExpensesTotalAmount(
                                    additional
                                  )} €`}
                                {additional?.type === 'DUTY' &&
                                  `${getStandByDutiesTotalAmount(
                                    additional?.standByDutyLines,
                                    'PROVIDER'
                                  )} €`}

                                {/* {additional?.type === 'CRA'
                                  ? additional?.nbWorkingDays
                                    ? `${round(
                                        additional?.contractorRate *
                                          additional?.nbWorkingDays
                                      )} €`
                                    : 'N/A'
                                  : `${getStandByDutiesTotalAmount(
                                      additional?.standByDutyLines,
                                      'PROVIDER'
                                    )} €`} */}
                              </ColorTextBox>
                            </Box>
                            <Box width={4 / 16}>
                              <ColorTextBox
                                ml={'-5px'}
                                variantColor={statuscolors?.variantColor}
                              >
                                {statuscolors?.statusText}
                              </ColorTextBox>
                            </Box>
                            <Box width={6 / 16}>
                              <ActivityRowAction data={additional} isLine />
                            </Box>
                          </Flex>
                        );
                      }
                    )}
                  </Box>
                </Accordion>
              );
            })
          ) : (
            <Flex mt={'50px'} width={1 / 1} justifyContent={'center'}>
              {!isFetching && activitiesData?.totalCount === 0 && (
                <Text variant="b">
                  {debouncedFilterQuery?.length > 0
                    ? 'Aucun résultat'
                    : 'Vous n’avez pas encore d’activités. Les activités à déclarer s’afficheront lorsque nous aurons reçu le bon de commande client et validé la prestation.'}
                </Text>
              )}
            </Flex>
          )}
          {items?.length > 0 && (
            <Box width={1 / 1}>
              <Pagination width={1 / 1} {...pagination} />
            </Box>
          )}
        </Box>
      ) : (
        <SpinnerBox />
      )}
    </Box>
  );
};
export default ActivitiesList;
