import { ExclamationCircleIcon } from 'components/ui/icons';
import * as React from 'react';
import { Box, BoxProps } from '../Box';
import { Flex } from '../Flex';
export type PropsBlocInformation = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  disableIcon?: boolean;
} & BoxProps;
export const BlocInformation = ({
  children,
  style,
  disableIcon,
  ...props
}: PropsBlocInformation) => {
  return (
    <Box {...props}>
      <Flex
        backgroundColor={'lightGray'}
        alignItems="center"
        p="10px"
        fontSize={'11px'}
        style={style}
      >
        <Flex
          alignItems="center"
          alignContent="center"
          ml={disableIcon ? 20 : 0}
        >
          {!disableIcon && (
            <Box margin="0 10px" mr={20}>
              <ExclamationCircleIcon fill="grey" />
            </Box>
          )}
          {children}
        </Flex>
      </Flex>
    </Box>
  );
};

export default BlocInformation;
