import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import { fr, enGB } from 'date-fns/locale';
// const locales = { fr, enGB };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'fr',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      skipOnVariables: false,
    },
  });

export default i18n;
