import * as React from 'react';
import { useEffect } from 'react';
import { useNotificationStore } from 'store/notifications';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
type Props = {};

export const Notifications: React.FC<React.PropsWithChildren<Props>> = () => {
  const { updateNotifications, open, variant, message } =
    useNotificationStore();

  const closeNotification = React.useCallback(async (): Promise<void> => {
    updateNotifications({
      message: '',
      open: false,
      variant: 'success',
    });
  }, [useNotificationStore]);

  const autoCloseNotification = React.useCallback(async (): Promise<void> => {
    await setTimeout(function () {
      closeNotification();
    }, 5000);
  }, [closeNotification]);

  useEffect(() => {
    if (open === true) {
      toast?.[variant](message);
      autoCloseNotification();
    }
  }, [autoCloseNotification, open, message]);
  return (
    <div>
      <ToastContainer
        style={{ top: 80 }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Notifications;
