import React from 'react';
import { REACT_APP_SEED_STAGE_NAME, getFullName, useMe } from '@commons';
import { Flex, Box, Text } from 'components/ui';
import { jwtDecode } from 'jwt-decode';
import { EyeIcon } from 'components/icons';

type AnonymRibbonProps = {};

export const AnonymRibbon: React.FC<
  React.PropsWithChildren<AnonymRibbonProps>
> = props => {
  const isImpersonateAction = localStorage?.token
    ? (jwtDecode(localStorage?.token) as any)?.isImpersonateAction
    : false;
  const { me } = useMe();
  // const getBackGroundColor = () => {
  //   switch (REACT_APP_SEED_STAGE_NAME) {
  //     case 'localhost':
  //       return 'red';
  //     case 'testing':
  //       return 'red';
  //     case 'develop':
  //       return 'red';
  //     case 'staging':
  //       return 'red';
  //     case 'preprod':
  //       return 'red';
  //     case 'production':
  //       return 'red';
  //     default:
  //       return '#858b9c';
  //   }
  // };
  //const bk = getBackGroundColor();
  if (!isImpersonateAction) {
    return null;
  }
  return (
    <Box
      width={460}
      zIndex={100}
      textAlign={'center'}
      //backgroundColor={bk}
      p={'5px'}
      position={'fixed'}
      top={0}
      left={'calc(50% - 230px)'}
      style={{
        textTransform: 'uppercase',
        background:
          'repeating-linear-gradient( 45deg, #bc606c, #bc607f 10px, #465298 10px, #465298 20px )',
      }}
    >
      <Flex justifyContent={'center'} alignItems={'center'}>
        <EyeIcon fill="white" fontSize={25} />
        <Text color="white">
          <b> Connecté en tant que : {getFullName(me)}</b>
        </Text>
      </Flex>
    </Box>
  );
};

AnonymRibbon.displayName = 'AnonymRibbon';

export default AnonymRibbon;
