import { create } from 'zustand';

type State = {
  pageIndex: number;
  columnsHidden: Array<any>;
  updateContractListStore: (state: Partial<State>) => void;
  resetContractListStore: (state: Partial<State>) => void;
  filterObject: any;
  //   selectedStatus: EInvoiceStatus | 'ALL';
  //   selectedRowIds: Array<string>;
  //   startDate: Date | null;
  //   endDate: Date | null;
  //   searchQuery: string;
};

const initialStates = {
  pageIndex: 0,
  columnsHidden: [],
  filterObject: undefined,
  //   startDate: null,
  //   endDate: null,
  //   selectedStatus: 'ALL' as const,
  //   selectedRowIds: [],
  //   searchQuery: '',
};

const store = (set: any) => ({
  ...initialStates,
  updateContractListStore: (state: Partial<State>) => set(state),
  resetContractListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useContractListStore = create<State>(store);
