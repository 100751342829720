import {
  IAddress,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { Text, Grid, Cell, BoxProps, Flex, ALink } from 'components/ui';
import React from 'react';
import {
  getEstablishmentName,
  buildOneLineAddress,
  establishmentUseIban,
} from '@commons';

type BankAccountProps = BoxProps & {
  establishment: IEstablishmentMinifiedUsers;
};
const grid4 = {
  xs: '4',
  sm: '4',
  md: '4',
  lg: '4',
  xl: '4',
};
export const BankAccount = ({ establishment }: BankAccountProps) => {
  const bankAccount = establishment?.provider?.bankAccount;
  return (
    <Grid cols={12} gap="5px" mt={20}>
      {establishmentUseIban(establishment) ? (
        <>
          <Cell x-span={grid4}>
            <Text variant="h3">{getEstablishmentName(establishment)}</Text>
          </Cell>
          <Cell x-span={grid4}>
            <Text variant="h3">Coordonnées bancaires</Text>
          </Cell>
          <Cell x-span={grid4}>
            <Text variant="b"></Text>
          </Cell>
          <Cell x-span={grid4}>
            <Text variant="p">
              {buildOneLineAddress(establishment?.address as IAddress)}
            </Text>
          </Cell>
          <Cell x-span={grid4}>
            <Flex>
              <Text variant="b">IBAN: &nbsp; </Text>
              <Text variant="p">{bankAccount?.iban || 'N/A'}</Text>
            </Flex>
          </Cell>
          <Cell x-span={grid4}>
            <Flex>
              <Text variant="b">BIC: &nbsp;</Text>
              <Text variant="p">{bankAccount?.bic || 'N/A'}</Text>
            </Flex>
          </Cell>
          <Cell x-span={grid4}>
            <Flex>
              <Text variant="b">N° SIRET: &nbsp;</Text>
              <Text variant="p">{establishment?.siret}</Text>
            </Flex>
          </Cell>
          <Cell x-span={grid4}>
            <Flex>
              <Text variant="b">Nom de la banque: &nbsp;</Text>
              <Text variant="p">{bankAccount?.bankName || 'N/A'}</Text>
            </Flex>
          </Cell>
          <Cell x-span={grid4}>
            <Flex>
              <Text variant="b">Titulaire du compte: &nbsp;</Text>
              <Text variant="p">{bankAccount?.owner || 'N/A'}</Text>
            </Flex>
          </Cell>
        </>
      ) : (
        <Cell x-span={grid4}>
          <Flex>
            <Text variant="b">RIB: &nbsp;</Text>
            {bankAccount?.rib?.fileLocation ? (
              <ALink
                target="_blank"
                href={`/file/${bankAccount?.rib?.fileLocation?.replaceAll(
                  '/',
                  '§'
                )}`}
              >
                {bankAccount?.rib?.fileName || 'N/A'}
              </ALink>
            ) : (
              <Text variant="p">{'N/A'}</Text>
            )}
          </Flex>
        </Cell>
      )}
    </Grid>
  );
};
