import { Header } from 'components/Header';
import {
  FormLabel,
  Flex,
  Box,
  Container,
  Input,
  Text,
  Link,
  Spinner,
  Tag,
  SubItem,
  CheckBox,
  DatePickerRange,
} from 'components/ui';
import TablePagination from 'components/ui/CustomPagination/TablePagination';
import { add, getTime } from 'date-fns';
import { StaticSelect } from 'components/selects/StaticSelect';
import * as React from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useParams } from 'react-router-dom';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import {
  IJoinedNotificationUser,
  INotificationState,
  ENotificationType,
} from '@freelancelabs/teoreme-commons';
import { notificationUpdateOne, getCivilityAllKeys } from '@commons';
import { useNotificationFindMany } from '@commons';
import { ALink } from 'components/ui';
import {
  EyeIcon,
  StarIcon,
  TaskIcon,
  EyeSlashIcon,
  StarSlashIcon,
} from 'components/ui/icons';
import { Theme } from 'styles';
import { NOTIFICATION_TYPES } from '@commons';
//import TablePagination from 'components/ui/Table/components/TablePagination';
import { formatDateAndTime } from '@commons';
import { useWSNotificationsStore } from 'store/wsNotifications';
import { useDebounce } from 'hooks/useDebounce';

import { queryClient } from '@commons';
export type Itemize<T> = T & { key: string };

export type NotificationsListProps = {};
type Item = Itemize<IJoinedNotificationUser>;

const filterLabel: { [index: string]: string } = {
  all: 'Toutes les notifications',
  'non-read': 'Non lues',
  read: 'Lues',
  prioritized: 'Priorités',
};

const filterQuery: { [index: string]: any } = {
  all: {},
  'non-read': {
    'notificationUser.state.read': false,
  },
  read: {
    'notificationUser.state.read': true,
  },
  prioritized: {
    'notificationUser.state.priority': true,
  },
};

const tabItems = (
  <>
    <SubItem variant="primary" href={'/notifications/all'}>
      {filterLabel['all']}
    </SubItem>
    <SubItem variant="primary" href={'/notifications/non-read'}>
      {filterLabel['non-read']}
    </SubItem>
    <SubItem variant="primary" href={'/notifications/read'}>
      {filterLabel['read']}
    </SubItem>
    <SubItem variant="primary" href={'/notifications/prioritized'}>
      {filterLabel['prioritized']}
    </SubItem>
  </>
);

export const NotificationsList: React.FunctionComponent<
  React.PropsWithChildren<NotificationsListProps>
> = () => {
  const { filter } = useParams<{ filter: string }>();
  const t = useTranslationFormat();
  const {
    updateWSNotifications,
    resetWSNotifications,
    type,
    sort,
    limit,
    page,
    startDate,
    endDate,
  } = useWSNotificationsStore();
  useSetCrumbs(
    [filter],
    [
      {
        label: 'notifications',
        path: '/notifications',
      },
      {
        label: filterLabel[filter].toLowerCase(),
        path: '/notifications/' + filter,
      },
    ]
  );

  const [checkAll, setCheckAll] = React.useState(false);
  const [checkedNotifications, setCheckedNotifications] = React.useState<
    Array<string>
  >([]);
  //const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    resetWSNotifications({});
  }, [filter]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const { data: notificationsQuery, status } = useNotificationFindMany({
    filterObject: {
      $or:
        debouncedFilterQuery.length > 1
          ? [
              {
                title: {
                  $regex: debouncedFilterQuery,
                  $options: 'i',
                },
              },
              {
                description: {
                  $regex: debouncedFilterQuery,
                  $options: 'i',
                },
              },
            ]
          : undefined,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$updatedAt' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$updatedAt' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      ...filterQuery[filter],
      type: type === 'ALL' ? undefined : type,
    },
    sort: sort,
    limit: limit,
    skip: limit * page,
  });
  const loading = status === 'pending';
  const totalCount = notificationsQuery?.totalCount || 0;
  const totalPages = Math.ceil(totalCount / 10);
  const items: Item[] =
    //@ts-ignore
    notificationsQuery?.results.map(
      (notification: IJoinedNotificationUser) => ({
        ...notification,
        key: notification.uuid || '',
      })
    ) || [];

  const updateState = async (state: Partial<INotificationState>) => {
    const data = checkedNotifications.map(uuid => {
      return {
        uuid,
        state,
      };
    });
    if (data.length > 0) {
      await notificationUpdateOne({
        notificationStates: data,
      });
    }

    setCheckAll(false);
    setCheckedNotifications([]);
    queryClient.refetchQueries({ queryKey: ['notifications'] });
  };
  return (
    <Box>
      <Header ml={20} tabs={tabItems}>
        <Text ml={10} variant="h1">
          Notifications
        </Text>
      </Header>
      <Flex
        justifyContent="space-between"
        ml={20}
        mr={20}
        mt={20}
        padding={'10px'}
      >
        <Box width={3 / 12}>
          <FormLabel>RECHERCHER</FormLabel>
          <Input
            isFullWidth
            type="search"
            onChange={e => {
              //@ts-ignore
              setSearchQuery(e.target.value);
            }}
          />
        </Box>
        <Box width={4 / 12}>
          <FormLabel>Types</FormLabel>
          <StaticSelect
            width={1 / 1}
            options={[
              {
                value: 'ALL',
                label: 'Tout les types',
              },
              {
                value: ENotificationType.ALERT,
                label: 'Alertes',
              },
              {
                value: ENotificationType.ACTION,
                label: 'Actions',
              },
              {
                value: ENotificationType.INFO,
                label: 'Informations',
              },
            ]}
            value={type}
            onChange={value =>
              updateWSNotifications({
                type: value as ENotificationType | undefined,
                page: 0,
              })
            }
            placeholder="Types"
          />
        </Box>
        <Box width={4 / 12}>
          <FormLabel pl={20}>PÉRIODE</FormLabel>
          <DatePickerRange
            startDate={startDate}
            endDate={endDate}
            isClearable={true}
            setStartDate={value =>
              updateWSNotifications({ startDate: value, page: 0 })
            }
            setEndDate={value =>
              updateWSNotifications({ endDate: value, page: 0 })
            }
          />
        </Box>
      </Flex>
      <Container paddingTop={4}>
        {!loading && items.length === 0 && (
          <Box alignContent="center" alignItems="center">
            <TaskIcon style={{ margin: '0 auto' }} />
            <Text variant="h3" color={'primary.light'} textAlign="center">
              Aucune notification
            </Text>
            <Text color={'primary.light'} textAlign="center">
              Dès que vous recevrez une nouvelle notification, elle sera visible
              ici.
            </Text>
          </Box>
        )}
        {items && items.length > 0 && (
          <Box display="flex" ml={20} mr={20} mb={20} padding={'10px'}>
            <CheckBox
              checked={checkAll}
              mr={2}
              id={'check-all'}
              onChange={e => {
                if (e.currentTarget.checked) {
                  setCheckAll(true);
                  const saved: Array<string> = [];
                  items.forEach(item => {
                    saved.push(item.uuid);
                  });
                  setCheckedNotifications(saved);
                } else {
                  setCheckAll(false);
                  setCheckedNotifications([]);
                }
              }}
            >
              {' '}
            </CheckBox>
            {filter !== 'prioritized' && (
              <Link
                mt={-1}
                iconLeft={<StarIcon fill={'gold'} />}
                color={Theme.colors.primary.default}
                fontSize={14}
                onClick={() => updateState({ priority: true })}
              >
                Prioriser
              </Link>
            )}
            <Link
              mt={-1}
              ml={filter !== 'prioritized' ? 4 : 0}
              iconLeft={<StarSlashIcon fill="grey" />}
              color={Theme.colors.primary.default}
              fontSize={14}
              onClick={() => updateState({ priority: false })}
            >
              Déprioriser
            </Link>
            {filter !== 'read' && (
              <Link
                mt={-1}
                ml={4}
                iconLeft={<EyeIcon fill={Theme.colors.primary.default} />}
                color={Theme.colors.primary.default}
                fontSize={14}
                onClick={() => updateState({ read: true })}
              >
                Marquer comme lue
              </Link>
            )}
            {filter !== 'non-read' && (
              <Link
                mt={-1}
                ml={4}
                iconLeft={<EyeSlashIcon fill={Theme.colors.primary.default} />}
                color={Theme.colors.primary.default}
                fontSize={14}
                onClick={() => updateState({ read: false })}
              >
                Marquer comme non lue
              </Link>
            )}
          </Box>
        )}
        {loading && (
          <Flex
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner />
          </Flex>
        )}
        {items.map(item => (
          <Box
            border="1px solid #d0d4e3"
            borderRadius={5}
            margin={20}
            padding={'10px'}
            minHeight={130}
            key={item.uuid}
            opacity={
              item.notificationUser?.state.read || item.defaultState?.read
                ? 0.5
                : 1
            }
          >
            <Flex
              display="inline-flex"
              justifyContent="flex-start"
              flexWrap="wrap"
              mb={2}
              mt={2}
              width={1 / 1}
            >
              <Flex width={'60px'}>
                <CheckBox
                  checked={checkAll || checkedNotifications.includes(item.uuid)}
                  mt={'5px'}
                  id={item.uuid}
                  onChange={e => {
                    if (
                      e.currentTarget.checked &&
                      !checkedNotifications.includes(item.uuid)
                    ) {
                      setCheckedNotifications([
                        ...checkedNotifications,
                        item.uuid,
                      ]);
                    } else {
                      const saved = [...checkedNotifications];
                      const index = saved.indexOf(item.uuid);
                      if (index > -1) {
                        saved.splice(index, 1);
                      }
                      setCheckedNotifications(saved);
                      setCheckAll(false);
                    }
                  }}
                >
                  {' '}
                </CheckBox>
                <StarIcon
                  fontSize={25}
                  fill={
                    item.notificationUser?.state.priority ||
                    item.defaultState?.priority
                      ? 'gold'
                      : 'grey'
                  }
                />
              </Flex>
              <Flex justifyContent="flex-start" width={6 / 10}>
                <Flex justifyContent="flex-start" width={1 / 1}>
                  <Tag
                    maxHeight={20}
                    variantColor={
                      item.type === 'ALERT'
                        ? 'warning'
                        : item.type === 'ACTION'
                          ? 'error'
                          : 'success'
                    }
                  >
                    {
                      //@ts-ignore
                      NOTIFICATION_TYPES[item.type]
                    }
                  </Tag>
                  <Text width={1 / 1} ml={'10px'} fontSize={14} variant="b">
                    {
                      t(
                        `notificationsProvider:${item.reference}`,
                        false,
                        false,
                        {
                          ...getCivilityAllKeys(
                            item.parameters,
                            item?.reference
                          ),
                        }
                      ).split('--')[0]
                    }
                  </Text>
                </Flex>
              </Flex>
              <Flex justifyContent="flex-end" width={3 / 10}>
                {/* TODO ACTIVE LINK WHEN FRONT READY */}
                <ALink
                  href={t(
                    `linksProvider:${item.reference}`,
                    false,
                    false,
                    item.parameters
                  )}
                >
                  <Link iconLeft={<EyeIcon />}>Voir</Link>
                </ALink>
              </Flex>
            </Flex>
            <Box pl={4}>
              <Text>
                {t(`notificationsProvider:${item.reference}`, false, false, {
                  ...getCivilityAllKeys(item.parameters, item?.reference),
                })
                  .split('--')[1]
                  ?.replaceAll('&amp;', '&')}
              </Text>
            </Box>
            {item.createdAt && (
              <Box mt={3} ml={'30px'}>
                <Text color={Theme.colors.grey.default}>
                  {formatDateAndTime(item.createdAt || '')}
                </Text>
              </Box>
            )}
          </Box>
        ))}
        {items && items.length > 0 && totalPages > 1 && (
          <Box ml={20}>
            <TablePagination
              labelData="notifications"
              total={totalCount}
              page={page}
              perPage={limit}
              onChangePage={(page: number) => {
                updateWSNotifications({
                  page: page,
                });
                setCheckAll(false);
                setCheckedNotifications([]);
              }}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};
