import {
  EadditionalActivityStatus,
  ECraStatus,
  IActivityReportData,
} from '@freelancelabs/teoreme-commons';
import { add, getTime } from 'date-fns';
import { Theme } from 'styles';
// TODO check
const STATUS_PENDING_FILTER_OBJECT = {
  $or: [
    {
      $and: [
        { 'standByDuties.0': { $exists: true } },
        {
          'standByDuties.status': {
            $in: [
              EadditionalActivityStatus.TO_BE_FILLED,
              EadditionalActivityStatus.TO_BE_SUBMITTED,
              EadditionalActivityStatus.TO_BE_VALIDATED,
              EadditionalActivityStatus.REJECTED,
            ],
          },
        },
      ],
    },
    {
      $and: [
        { 'expenses.0': { $exists: true } },
        {
          'expenses.status': {
            $in: [
              EadditionalActivityStatus.TO_BE_FILLED,
              EadditionalActivityStatus.TO_BE_SUBMITTED,
              EadditionalActivityStatus.TO_BE_VALIDATED,
              EadditionalActivityStatus.REJECTED,
            ],
          },
        },
      ],
    },
    {
      'cra.state': {
        $in: [
          ECraStatus.TO_BE_FILLED,
          ECraStatus.TO_BE_SUBMITTED,
          ECraStatus.TO_BE_VALIDATED,
          ECraStatus.REJECTED,
        ],
      },
    },
  ],
};
const STATUS_VALIDATED_FILTER_OBJECT = {
  $and: [
    {
      $or: [
        {
          $and: [
            { 'standByDuties.0': { $exists: true } },
            {
              standByDuties: {
                $not: {
                  $elemMatch: {
                    status: {
                      $in: [
                        EadditionalActivityStatus.TO_BE_FILLED,
                        EadditionalActivityStatus.TO_BE_SUBMITTED,
                        EadditionalActivityStatus.TO_BE_VALIDATED,
                        EadditionalActivityStatus.REJECTED,
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        {
          'standByDuties.0': { $exists: false },
        },
      ],
    },
    {
      $or: [
        {
          $and: [
            { 'expenses.0': { $exists: true } },
            {
              expenses: {
                $not: {
                  $elemMatch: {
                    status: {
                      $in: [
                        EadditionalActivityStatus.TO_BE_FILLED,
                        EadditionalActivityStatus.TO_BE_SUBMITTED,
                        EadditionalActivityStatus.TO_BE_VALIDATED,
                        EadditionalActivityStatus.REJECTED,
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        {
          'expenses.0': { $exists: false },
        },
      ],
    },
  ],
};
export const getDefaultFilterObject = (
  filter: 'pending' | 'validated',
  params?: {
    debouncedFilterQuery: string;
    selectedStatus?: EadditionalActivityStatus | ECraStatus | 'ALL';
    startDate: Date | null;
    endDate: Date | null;
    selectedCustomer?: string;
    isOpen?: boolean;
  }
) => {
  const filterObject: any = {};

  const queryFilter = [
    {
      'contractor.firstName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'cra.refCra': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.businessName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.tradeName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.signBoard1': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.signBoard2': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estCustomer.signBoard3': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: params?.debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (params) {
    if (params?.isOpen) {
      filterObject['cra.isOpen'] = true;
    }
    if (params?.selectedCustomer) {
      filterObject['estCustomer.uuid'] = params?.selectedCustomer;
    }
    if (params?.startDate !== null && params?.endDate !== null) {
      if (filterObject?.$and) {
        filterObject?.$and?.push({
          $expr: {
            $gte: [{ $toLong: '$month' }, getTime(params?.startDate)],
          },
        });
        filterObject?.$and?.push({
          $expr: {
            $lte: [
              { $toLong: '$month' },
              getTime(add(params?.endDate, { months: 1 })),
            ],
          },
        });
      } else {
        filterObject.$and = [
          {
            $expr: {
              $gte: [{ $toLong: '$month' }, getTime(params?.startDate)],
            },
          },
          {
            $expr: {
              $lte: [
                { $toLong: '$month' },
                getTime(add(params?.endDate, { months: 1 })),
              ],
            },
          },
        ];
      }

      if (params?.debouncedFilterQuery?.length > 0) {
        //@ts-ignore
        filterObject.$and.push({ $or: queryFilter });
      }
    } else {
      if (params?.debouncedFilterQuery?.length > 0) {
        //@ts-ignore
        filterObject.$or = queryFilter;
      }
    }
  }

  if (params?.selectedStatus && params?.selectedStatus !== 'ALL') {
    if (filterObject?.$and) {
      filterObject['cra.state'] = {
        $in: [params?.selectedStatus],
      };
      filterObject?.$and?.push({
        $or: [
          {
            $and: [
              { 'standByDuties.0': { $exists: true } },
              {
                'standByDuties.status': {
                  $in: [params?.selectedStatus],
                },
              },
            ],
          },
          {
            'standByDuties.0': { $exists: false },
          },
        ],
      });
      filterObject?.$and?.push({
        $or: [
          {
            $and: [
              { 'expenses.0': { $exists: true } },
              {
                'expenses.status': {
                  $in: [params?.selectedStatus],
                },
              },
            ],
          },
          {
            'expenses.0': { $exists: false },
          },
        ],
      });
    } else {
      filterObject.$or = [
        { 'cra.state': params?.selectedStatus },
        { 'standByDuties.status': params?.selectedStatus },
        { 'expenses.status': params?.selectedStatus },
      ];
    }
  } else {
    switch (filter) {
      case 'pending':
        if (filterObject?.$and) {
          filterObject?.$and?.push(STATUS_PENDING_FILTER_OBJECT);
        } else {
          filterObject.$and = [STATUS_PENDING_FILTER_OBJECT];
        }

        break;
      case 'validated':
        filterObject['cra.state'] = {
          $in: [ECraStatus?.VALIDATED, ECraStatus?.ARCHIVED],
        };
        if (filterObject?.$and) {
          STATUS_VALIDATED_FILTER_OBJECT?.$and?.forEach(element =>
            filterObject?.$and?.push(element)
          );
        } else {
          filterObject.$and = STATUS_VALIDATED_FILTER_OBJECT?.$and;
        }
    }
  }
  return filterObject;
};
export const dispathActivitiesByMission = (data: IActivityReportData[]) => {
  const activitiesByMission: any[] = [];
  data?.forEach((d: IActivityReportData) => {
    if (!activitiesByMission?.find((m: any) => m?.mission === d?.mission)) {
      activitiesByMission?.push({
        mission: d?.mission,
        missionNumber: d?.missionNumber,
        missionStatus: d?.missionStatus,
        missionStructure: d?.missionStructure,
        estCustomer: d?.estCustomer,
        estProvider: d?.estProvider,
        activities: [],
      });
    }
  });
  data?.forEach((d: IActivityReportData) => {
    const findex = activitiesByMission?.findIndex(
      (m: any) => m?.mission === d?.mission
    );
    activitiesByMission[findex]?.activities?.push(d);
  });
  return activitiesByMission;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStatus = (CRA: any) => {
  switch (CRA?.state) {
    case ECraStatus.TO_BE_FILLED:
      return {
        background: Theme?.colors?.primary?.greyLight,
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_SUBMITTED:
      return {
        background: Theme?.colors?.primary?.greyLight,
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_VALIDATED:
      return {
        background: 'rgb(255,237,204)',
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case ECraStatus.VALIDATED:
      return {
        background: 'rgb(239,249,235)',
        variantColor: 'success',
        statusText: 'Validé',
        // statusText: `Validé le ${
        //   CRA?.stateChangedAt &&
        //   new Date(CRA?.stateChangedAt).toLocaleDateString()
        // }`,
        textColor: Theme.colors.success.default,
      };
    case ECraStatus.REJECTED:
      return {
        background: 'rgb(255,239,240)',
        variantColor: 'error',
        statusText: 'Refusé',
        // statusText: `Refusé le ${
        //   CRA?.stateChangedAt &&
        //   new Date(CRA?.stateChangedAt).toLocaleDateString()
        // }`,
        textColor: Theme.colors.error.default,
      };
    case ECraStatus.ARCHIVED:
      return {
        background: Theme?.colors?.grey?.transparent,
        variantColor: 'grey',
        statusText: 'Archivé',
        // statusText: `Archivé le ${
        //   CRA?.stateChangedAt &&
        //   new Date(CRA?.stateChangedAt).toLocaleDateString()
        // }`,
        textColor: Theme.colors.grey.default,
      };
    default:
      return false;
  }
};
