import {
  cutLongText,
  EXPENSES_TYPE_TRANSLATE_FR,
  extractVerifyRib,
  getEstablishmentName,
  getFormulaDutyFormulaAmount,
  isCreditNoteInvoice,
  isExpenseInvoice,
  isStandByDutyInvoice,
  queryClient,
  removeOffsetDate,
  round,
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  uploadFile,
  useAdditionalActivityFindOne,
  useInvoiceUpdateOne,
  providerExtractVerifyRibNeedWarning,
  useDownloadFile,
} from '@commons';
import {
  EFileType,
  EInvoiceStatus,
  EInvoiceType,
  EStandByDutyUnits,
  IFile,
  EExpenseType,
  IJoinedInvoice,
  IMission,
  IStandByDutyFormulaLine,
  EadditionalActivityStatus,
  EadditionalActivityType,
} from '@freelancelabs/teoreme-commons';
import { Accordion } from 'components/Accordion';
import TextInformation from 'components/TextInformation';
import { DownloadIcon, EuroIcon } from 'components/icons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showOnConfirmFormModal } from 'components/modals/OnConfirmFormModal';
import {
  Box,
  Button,
  Cell,
  Divider,
  FileInput,
  Flex,
  FormControl,
  Grid,
  LabelField,
  Link,
  Spinner,
  Text,
  TextAreaControlled,
  CustomToolTip,
} from 'components/ui';
import { ExclamationCircleIcon } from 'components/ui/icons';
import { AdditionalServicesForm } from 'forms/partials/invoices/AdditionalServicesForm';
import { InvoiceEstablishmentInformations } from 'forms/partials/invoices/InvoiceEstablishmentInformations';
import { InvoiceInformationsForm } from 'forms/partials/invoices/InvoiceInformationsForm';
import { InvoiceMissionInfomationsForm } from 'forms/partials/invoices/InvoiceMissionInfomationsForm';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Theme } from 'styles';
import styled from 'styled-components';

type FormValues = any;

type EditStandByDutyFormProps = {
  onSubmit?: () => void;
  defaultValues: IJoinedInvoice;
  mission: IMission;
  editable?: boolean;
};

const grid6 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};
const grid30 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '5',
  xl: '5',
};
const grid70 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '7',
  xl: '7',
};
const grid100 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;
export const EditStandByDutyForm: React.FC<
  React.PropsWithChildren<FormValues>
> = ({ defaultValues, mission, editable }: EditStandByDutyFormProps) => {
  const { data: activity } = useAdditionalActivityFindOne(
    defaultValues?.additionalActivity
  );
  const invoice = defaultValues;
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const theme = useTheme();
  const history = useHistory();
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLater, setLoadingLater] = useState(false);
  const [invoiceFormFile, setInvoiceFormFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceFile, setInvoiceFile] = React.useState<IFile | null>(
    defaultValues?.invoiceFile ? defaultValues?.invoiceFile : null
  );
  const [checkFileWarning, setCheckFileWarning] = useState<
    React.ReactNode | undefined
  >();
  const form = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
    },
  });
  const {
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    control,
    getValues,
  } = form;
  const vatRate = watch('vatRate');
  const isVat = watch('isVat');
  const deductionAmount = watch('deductionAmount');

  const isMandateMode = mission?.isMandateMode;
  const fileLocation = invoiceFile?.fileLocation;
  const { status, data } = useDownloadFile(fileLocation || '', {
    enabled: fileLocation ? true : false,
  });

  useEffect(() => {
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload

      reader.onload = () => {
        //setFile(reader.result as string);
      };
    }
  }, [data]);

  const checkIsEditableForm = () => {
    if (!defaultValues) {
      return false;
    }
    if (editable !== undefined) {
      return editable;
    }
    switch (defaultValues.status) {
      case EInvoiceStatus.TO_BE_FILLED:
        return true;
      case EInvoiceStatus.TO_BE_SUBMITTED:
        return true;
      case EInvoiceStatus.TO_BE_VALIDATED:
        return false;
      case EInvoiceStatus.VALIDATED:
        return false;
      case EInvoiceStatus.ARCHIVED:
        return false;
      case EInvoiceStatus.REJECTED:
        return true;
      default:
        return false;
    }
  };
  const handleChangeProofFile = async (files: File[]) => {
    setLoadingFile(true);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        fileType: EFileType.TEMPORARY,
        missionRef: defaultValues?.mission?.reference,
        actionType: 'upload',
      });
      const data = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag as string,
      };
      setInvoiceFile(data);
    } catch (e) {
      setLoadingFile(false);
    }
    setLoadingFile(false);
    setInvoiceFormFile(files[0]);
    clearErrors('invoiceFile');
  };

  const onUpdateInvoice = async (
    formValues: FormValues,
    saveLater: boolean
  ) => {
    let onError = false;

    if (saveLater) {
      setLoadingLater(true);
    } else {
      setLoading(true);
    }
    if (isCreditNoteInvoice(defaultValues?.invoiceType as EInvoiceType)) {
      const submitData = {
        comment: formValues?.comment,
        invoiceFile: formValues?.invoiceFile,
        status: saveLater ? defaultValues?.status : EInvoiceStatus?.SENT,
        providerInvoiceRef: formValues?.providerInvoiceRef,
      };
      if (invoiceFile || invoiceFormFile) {
        setLoadingFile(true);
        if (invoiceFormFile) {
          try {
            // const s3Response = await uploadFile({
            //   file: invoiceFormFile,
            //   fileType: EFileType.TEMPORARY,
            //   missionRef: defaultValues?.mission?.reference,
            //   actionType: 'upload',
            // });
            // const data = {
            //   fileName: s3Response?.fileName,
            //   fileLocation: s3Response?.fileLocation as string,
            //   eTag: s3Response?.eTag,
            // };
            submitData.invoiceFile = invoiceFile;
          } catch (e) {
            onError = true;
          }
        }
        setLoadingFile(false);
        if (onError === false) {
          try {
            await updateInvoice({
              uuids: [defaultValues.uuid],
              invoice: submitData,
            });
            queryClient.refetchQueries({
              queryKey: [defaultValues.uuid],
              type: 'active',
            });
            queryClient.refetchQueries({
              queryKey: ['Invoices'],
              type: 'active',
            });
            history?.goBack();
          } catch (e) {
            //console.log(e)
          }
        }
        if (saveLater) {
          setLoadingLater(false);
        } else {
          setLoading(false);
        }
      } else {
        if (!saveLater) {
          onError = true;
          setError('invoiceFile', { message: 'Ce champs est requis' });
        }
      }
    } else {
      const submitData = {
        comment: formValues?.comment,
        deductionAmount: formValues?.deductionAmount,
        invoiceDate:
          formValues?.invoiceDate &&
          formValues?.invoiceDate !== null &&
          removeOffsetDate(formValues?.invoiceDate),
        invoiceFile: formValues?.invoiceFile,
        isDeductible: formValues?.isDeductible,
        isVat: formValues?.isVat,
        providerInvoiceRef: formValues?.providerInvoiceRef,
        socialSecurityNumber: formValues?.socialSecurityNumber,
        vatNumber: formValues?.vatNumber,
        vatRate: formValues?.vatRate,
        status: saveLater
          ? EInvoiceStatus.TO_BE_SUBMITTED
          : EInvoiceStatus.TO_BE_VALIDATED,
      };
      if (invoiceFile || invoiceFormFile) {
        if (invoiceFormFile) {
          setLoadingFile(true);
          try {
            const s3Response = await uploadFile({
              file: invoiceFormFile,
              fileType: EFileType.TEMPORARY,
              missionRef: defaultValues?.mission?.reference,
              actionType: 'upload',
            });
            const data = {
              fileName: s3Response?.fileName,
              fileLocation: s3Response?.fileLocation as string,
              eTag: s3Response?.eTag,
            };
            submitData.invoiceFile = data;
          } catch (e) {
            onError = true;
          }
          setLoadingFile(false);
        }
      } else {
        if (!saveLater) {
          onError = true;
          setError('invoiceFile', { message: 'Ce champs est requis' });
        }
      }

      if (onError === false) {
        try {
          await updateInvoice({
            uuids: [defaultValues.uuid],
            invoice: submitData,
          });
          queryClient?.refetchQueries({ queryKey: ['milestoneReport'] });
          queryClient.refetchQueries({ queryKey: [defaultValues.uuid] });
          queryClient.refetchQueries({ queryKey: ['Invoices'], exact: true });
          history?.goBack();
          // history.push(
          //   `/invoices/time-spent/${
          //     !saveLater ? 'to-be-validated' : 'to-be-submitted'
          //   }`
          // );
          //history?.goBack();
        } catch (e) {
          //console.log(e)
        }
      }
      if (saveLater) {
        setLoadingLater(false);
      } else {
        setLoading(false);
      }
    }
  };
  const onSaveLater = async () => {
    const formValues = getValues();
    await onUpdateInvoice(formValues, true);
  };
  const onSubmit = async (formValues: FormValues) => {
    if (!invoiceFile && !invoiceFormFile) {
      setError('invoiceFile', { message: 'Ce champs est requis' });
    } else {
      clearErrors('invoiceFile');
      await showOnConfirmFormModal({
        title:
          'Etes-vous sûr(e) de vouloir envoyer cette facture  à votre conseiller Connecteed pour validation ?',
        text: '',
        confirmLabelButton: 'Envoyer',
        cancelLabelButton: 'Corriger',
        data: { ...getValues(), invoiceFile },
        checkInvoice: true,
      }).then(action => {
        if (action === true) {
          onUpdateInvoice(formValues, false);
        }
      });
    }
  };

  const getIsDisabledForm = () => {
    return false;
  };
  const getIsDisabledSend = () => {
    if (
      ![
        EInvoiceType.PROVIDER_EXPENSE,
        EInvoiceType.PROVIDER_STAND_BY_DUTY,
      ].includes(defaultValues?.invoiceType)
    )
      return false;
    if (activity?.status === EadditionalActivityStatus.REJECTED) return true;
    return false;
  };
  const getActivityText = () => {
    switch (activity?.type) {
      case EadditionalActivityType.STAND_BY_DUTY:
        return (
          <Box>
            Cette facture ne peut pas être soumise car la prestation
            complémentaire qui lui est associée a été refusée. Veuillez d’abord
            soumettre de nouveau la prestation complémentaire avant de renvoyer
            la facture associée.
          </Box>
        );
      case EadditionalActivityType.EXPENSE:
        return (
          <Box>
            Cette facture ne peut pas être soumise car le frais qui lui est
            associé a été refusé. Veuillez d’abord soumettre de nouveau le frais
            avant de renvoyer la facture associée.
          </Box>
        );
      default:
        return '';
    }
  };
  const isEditable = checkIsEditableForm();
  return (
    <form style={{ padding: 5 }} onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal
        active={isDirty && !loading && !loadingLater && !isSubmitSuccessful}
      />

      <Grid cols={12}>
        <Cell x-span={invoiceFile ? grid70 : grid100}>
          <InvoiceInformationsForm
            invoice={defaultValues}
            form={form}
            isEditable={isEditable}
          />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '12' }}>
              <Text variant="h2">Importer la facture correspondante</Text>
            </Cell>
            <Cell x-span={grid6} placeSelf="center">
              {!isMandateMode ? (
                <Text>
                  Merci de vérifier que votre facture correspond bien aux
                  données indiquées dans ce formulaire. Une fois envoyée, elle
                  sera vérifiée par votre conseiller pour traitement.
                </Text>
              ) : (
                <Flex alignItems={'center'}>
                  <Box width={80} mr={10}>
                    <ExclamationCircleIcon
                      fill={theme?.colors?.primary?.default}
                      fontSize={40}
                    />
                  </Box>

                  <Text>
                    Merci de vérifier que votre facture correspond bien aux
                    données indiquées dans ce formulaire. Il faut faire
                    apparaitre sur la facture la mention :{' '}
                    <b style={{ color: 'red' }}>
                      « Facture adressée par Freelance.com, mandataire, au nom
                      et pour le compte de{' '}
                      {getEstablishmentName(invoice?.estProvider)}, mandant ».
                    </b>{' '}
                    Une fois envoyée, elle sera vérifiée par votre conseiller
                    pour traitement.
                  </Text>
                </Flex>
              )}
            </Cell>
            <Cell x-span={grid6} justifySelf="end">
              <FormControl
                required={isEditable}
                label={
                  isEditable
                    ? 'Importer la facture (.pdf seulement)'
                    : 'Facture'
                }
                errorMessage={errors?.invoiceFile?.message}
              >
                <>
                  {isEditable ? (
                    <FileInput
                      isDisabled={!isEditable}
                      pr={20}
                      label=""
                      accept=".pdf"
                      subLabel=""
                      onSelect={handleChangeProofFile}
                    >
                      {loadingFile && <Spinner />}
                      <Link iconLeft={<DownloadIcon />}>
                        {invoiceFormFile || invoiceFile?.fileName
                          ? `Modifier le fichier ${
                              invoiceFormFile?.name
                                ? cutLongText(invoiceFormFile.name, 20)
                                : cutLongText(
                                    invoiceFile && invoiceFile.fileName
                                      ? invoiceFile?.fileName
                                      : '',
                                    20
                                  )
                            }`
                          : 'Importer un document'}
                      </Link>
                    </FileInput>
                  ) : (
                    <Box pr={20}>
                      <Flex
                        pl={'10px'}
                        justifyContent="flex-start"
                        alignContent="center"
                        alignItems="center"
                        border={`1px solid ${theme.colors.primary.inputBorder}`}
                        height={45}
                        borderRadius={5}
                        backgroundColor="rgba(182,198,238,0.5)"
                      >
                        <Link
                          onClick={() =>
                            showDisplayPdfModal({
                              fileLocation: invoiceFile?.fileLocation || '',
                              fileName: invoiceFile?.fileName || '',
                            })
                          }
                        >
                          fileName
                          <u>{invoiceFile?.fileName}</u>
                        </Link>
                      </Flex>
                    </Box>
                  )}
                </>
              </FormControl>
              {checkFileWarning && (
                <TextInformation variant="warning">
                  {checkFileWarning}
                </TextInformation>
              )}
            </Cell>
          </Grid>
          <Divider mb={20} mt={20} />
          <InvoiceMissionInfomationsForm form={form} isEditable={isEditable} />
          {isStandByDutyInvoice(invoice?.invoiceType) && (
            <Grid cols={12} gap="30px">
              <Cell x-span={{ xs: '12' }}>
                <Accordion
                  defaultOpen
                  openOnTop
                  propsBar={
                    {
                      //borderRadius: '8px',
                      //backgroundColor: 'white',
                      //border: '1px solid #d0d4e3',
                    }
                  }
                  childrenTop={
                    <Text width={23 / 24} variant="h2">
                      Types de prestation complémentaire
                    </Text>
                  }
                  key={`cras`}
                >
                  <Grid cols={12} gap="30px" p={'12px'}>
                    {activity?.standByDutyLines?.map(
                      (sdb: any, index: number) => {
                        const formula = mission?.standByDutyFormulas?.find(
                          f => f?.uuid === sdb?.standByDutyFormulaLineUuid
                        ) as IStandByDutyFormulaLine;
                        return (
                          <>
                            <Cell x-span={{ xs: '2' }}>
                              <LabelField
                                underline
                                label="Type prestation"
                                value={formula?.name}
                              />
                            </Cell>
                            <Cell x-span={{ xs: '4' }}>
                              <LabelField
                                underline
                                label="Description"
                                value={formula?.description}
                              />
                            </Cell>
                            <Cell x-span={{ xs: '2' }}>
                              <LabelField
                                label="Déclaré"
                                underline
                                value={`${sdb?.formulaParameters?.INPUT} ${
                                  STAND_BY_DUTY_UNIT_TRANSLATE_FR[formula?.unit]
                                }`}
                              />
                            </Cell>
                            <Cell x-span={{ xs: '2' }}>
                              <LabelField
                                underline
                                label="Tarif unitaire HT"
                                value={`${
                                  formula?.unit === EStandByDutyUnits.EUROS
                                    ? //sdb?.formulaParameters?.INPUT
                                      round(
                                        (getFormulaDutyFormulaAmount(
                                          sdb,
                                          'PROVIDER'
                                        ) /
                                          sdb?.formulaParameters
                                            ?.INPUT) as number
                                      )
                                    : //@ts-ignore
                                      round(
                                        (getFormulaDutyFormulaAmount(
                                          sdb,
                                          'PROVIDER'
                                        ) /
                                          sdb?.formulaParameters
                                            ?.INPUT) as number
                                      )
                                } €`}
                              />
                            </Cell>
                            <Cell x-span={{ xs: '2' }}>
                              <LabelField
                                underline
                                label="Montant HT"
                                value={`${getFormulaDutyFormulaAmount(
                                  sdb,
                                  'PROVIDER'
                                )} €`}
                              />
                            </Cell>
                            {activity?.standByDutyLines &&
                              index !==
                                activity?.standByDutyLines?.length - 1 && (
                                <Cell x-span={{ xs: '12' }}>
                                  <Divider mb={10} mt={10} />
                                </Cell>
                              )}
                          </>
                        );
                      }
                    )}
                  </Grid>
                </Accordion>
              </Cell>
            </Grid>
          )}
          {isExpenseInvoice(invoice?.invoiceType) && (
            <Grid cols={12} gap="30px">
              <Cell x-span={{ xs: '12' }}>
                <Accordion
                  defaultOpen
                  openOnTop
                  propsBar={
                    {
                      //borderRadius: '8px',
                      //backgroundColor: 'white',
                      //border: '1px solid #d0d4e3',
                    }
                  }
                  childrenTop={
                    <Text width={23 / 24} variant="h2">
                      Types de frais
                    </Text>
                  }
                  key={`EXPENSES`}
                >
                  <Grid cols={12} gap="30px" p={'12px'}>
                    {activity?.expenseLines?.map(
                      (expense: any, index: number) => {
                        return (
                          <>
                            <Cell x-span={{ xs: '2' }}>
                              <LabelField
                                underline
                                label="Type frais"
                                value={
                                  EXPENSES_TYPE_TRANSLATE_FR[
                                    expense?.type as EExpenseType
                                  ]
                                }
                              />
                            </Cell>
                            <Cell x-span={{ xs: '4' }}>
                              <LabelField
                                underline
                                label="Description"
                                value={expense?.comment || 'N/A'}
                              />
                            </Cell>
                            <Cell x-span={{ xs: '2' }}>
                              <LabelField
                                label="Montant HT"
                                underline
                                value={`${expense?.amount}€`}
                              />
                            </Cell>
                            {activity?.expenseLines &&
                              index !== activity?.expenseLines?.length - 1 && (
                                <Cell x-span={{ xs: '12' }}>
                                  <Divider mb={10} mt={10} />
                                </Cell>
                              )}
                          </>
                        );
                      }
                    )}
                  </Grid>
                </Accordion>
              </Cell>
            </Grid>
          )}
          <Divider mb={10} mt={10} />
          <AdditionalServicesForm
            invoice={defaultValues}
            activity={activity}
            form={form}
            isEditable={
              invoice?.invoiceType === EInvoiceType.PROVIDER_MILESTONE &&
              isEditable
            }
          />
          <Divider mb={10} mt={10} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '10' }}>
              <Text variant="h2">Montant Net (HT)</Text>
            </Cell>
            <Cell x-span={{ xs: '2' }}>
              <Text
                variant="h1"
                color="#003cc2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#f4f6fd"
                fontWeight={500}
              >
                {round(invoice?.totalBeforeTaxes || 0)}
                <EuroIcon fill="#003cc2" />
              </Text>
            </Cell>
          </Grid>
          <Divider mb={10} mt={10} />
          <InvoiceEstablishmentInformations
            invoiceData={defaultValues}
            form={form}
            isEditable={isEditable}
            activity={activity}
          />
          <Divider mb={10} mt={10} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '10' }}>
              <Text variant="h1" color="#009933">
                Montant Total TTC
              </Text>
            </Cell>
            <Cell x-span={{ xs: '2' }}>
              <Text
                variant="h1"
                color="#009933"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="rgba(0, 153, 51, 0.1)"
                fontWeight={500}
              >
                {round(
                  (invoice?.totalBeforeTaxes as number) +
                    (deductionAmount ? -Number(deductionAmount) : 0) +
                    (isVat
                      ? ((invoice?.totalBeforeTaxes as number) * vatRate) / 100
                      : 0)
                )}
                <EuroIcon fill="#009933" />
              </Text>
            </Cell>
          </Grid>
          <Divider mb={20} mt={20} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '12' }}>
              <Text variant="h2">Commentaire</Text>
            </Cell>
            <Cell x-span={{ xs: '12' }}>
              <FormControl
                label="Message à adresser à votre conseiller lors de la transmission de votre facture"
                errorMessage={
                  errors?.comment?.type === 'maxLength'
                    ? 'Veuillez renseigner moins de 1000 caractères'
                    : errors?.comment?.message
                }
              >
                <TextAreaControlled
                  isDisabled={!isEditable}
                  control={control}
                  name="comment"
                  rules={{ maxLength: 999 }}
                  minRows={3}
                />
              </FormControl>
            </Cell>
          </Grid>
        </Cell>
        <Cell
          hidden={!invoiceFile ? true : false}
          x-span={grid30}
          marginLeft={'15px'}
        >
          {!loading && data && (
            <FileContainer
              style={{
                width: '100%',
                position: 'sticky',
                top: '0px',
                paddingBottom: '60px',
                height: 'calc(100vh - 80px)',
              }}
            >
              <iframe
                //@ts-ignore
                src={data && (data as any)?.config?.url + '#toolbar=0'}
                frameBorder="0"
                title="iframe"
                style={{ width: '100%', height: 'calc(100vh - 80px)' }}
              ></iframe>
            </FileContainer>
          )}
        </Cell>
      </Grid>

      <Flex justifyContent="flex-start">
        <Button
          mr={10}
          key="Cancel"
          type="button"
          onClick={() => history?.goBack()}
          variant="ghost"
        >
          Fermer
        </Button>
        {isEditable && (
          <>
            <Button
              onClick={handleSubmit(onSaveLater)}
              mr={10}
              key="saveLater"
              type="button"
              isLoading={loadingLater}
              isDisabled={
                getIsDisabledForm() || loading || loadingLater || loadingFile
              }
              variant="ghost"
            >
              Enregistrer et finir plus tard
            </Button>
            <Button
              key="submit"
              type="submit"
              isLoading={loading}
              isDisabled={
                getIsDisabledForm() ||
                loading ||
                loadingLater ||
                getIsDisabledSend() ||
                loadingFile
              }
            >
              {getIsDisabledSend() ? (
                <CustomToolTip
                  color={Theme?.colors?.error?.default}
                  text={getActivityText()}
                  id={'send_invoice'}
                >
                  Envoyer
                </CustomToolTip>
              ) : (
                <Box>Envoyer</Box>
              )}
            </Button>
          </>
        )}
      </Flex>
    </form>
  );
};

export default EditStandByDutyForm;
