import {
  ECraStatus,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { useCraFindOne } from '@commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { create } from 'react-modal-promise';
import { EditCRAForm } from 'forms/CRA/EditCRAForm';
import { Box, Flex, SpinnerBox, Status, Text } from 'components/ui';
import { Theme } from 'styles';
import { Header } from 'components/Header';
import TextInformation from 'components/TextInformation';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  refCra: string;
};

export const UpdateCraModal = ({ onResolve, isOpen, layer, refCra }: Props) => {
  const { data: CRA, isFetching, status } = useCraFindOne(refCra, true);
  const isEditableState = (CRA: IJoinedCraWithInvoice) => {
    switch (CRA?.state) {
      case ECraStatus.TO_BE_FILLED:
        return true;
      case ECraStatus.TO_BE_SUBMITTED:
        return true;
      case ECraStatus.REJECTED:
        return true;
      default:
        return false;
    }
  };
  const getStatus = (CRA: IJoinedCraWithInvoice) => {
    switch (CRA?.state) {
      case ECraStatus.TO_BE_FILLED:
        return {
          variantColor: 'grey',
          statusText: 'A saisir',
          textColor: Theme.colors.grey.default,
        };
      case ECraStatus.TO_BE_SUBMITTED:
        return {
          variantColor: 'grey',
          statusText: 'A soumettre',
          textColor: Theme.colors.grey.default,
        };
      case ECraStatus.TO_BE_VALIDATED:
        return {
          variantColor: 'warning',
          statusText: 'En attente de validation',
          textColor: Theme.colors.warning.default,
        };
      case ECraStatus.VALIDATED:
        return {
          variantColor: 'success',
          statusText: `Validé le ${
            CRA?.stateChangedAt &&
            new Date(CRA?.stateChangedAt).toLocaleDateString()
          }`,
          textColor: Theme.colors.success.default,
        };
      case ECraStatus.REJECTED:
        return {
          variantColor: 'error',
          statusText: `Refusé le ${
            CRA?.stateChangedAt &&
            new Date(CRA?.stateChangedAt).toLocaleDateString()
          }`,
          textColor: Theme.colors.error.default,
        };
      case ECraStatus.ARCHIVED:
        return {
          variantColor: 'grey',
          statusText: `Archivé le ${
            CRA?.stateChangedAt &&
            new Date(CRA?.stateChangedAt).toLocaleDateString()
          }`,
          textColor: Theme.colors.grey.default,
        };
      default:
        return false;
    }
  };
  const statusData = getStatus(CRA as IJoinedCraWithInvoice);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1200}
      closeIcon
      layer={layer}
    >
      {!isFetching ? (
        <>
          <Header tabs={false}>
            <Text mb={'5px'} variant="h1">
              CRA n° {refCra}
            </Text>
            {statusData && (
              <Flex justifyContent={'flex-end'}>
                <Box backgroundColor="#e5f5ff " p={10} borderRadius={'5px'}>
                  <Status variantColor={statusData.variantColor as any}>
                    <Text variant="b" color={statusData.textColor}>
                      {statusData.statusText}
                    </Text>
                  </Status>
                </Box>
              </Flex>
            )}
          </Header>
          {CRA?.rejectReason && (
            <div style={{ padding: 5 }}>
              <TextInformation variant="error" width={1 / 1} mb={10}>
                {`Ce CRA a été refusé le ${new Date(
                  CRA?.stateChangedAt as Date
                ).toLocaleDateString()} pour cause de : ${
                  CRA?.rejectMetaData
                    ? CRA?.rejectMetaData
                    : ' justificatif non conforme.'
                }`}
              </TextInformation>
            </div>
          )}
          {status === 'success' && (
            <EditCRAForm
              onClose={() => onResolve()}
              key={CRA?.refCra}
              defaultValues={CRA}
              editable={isEditableState(CRA as IJoinedCraWithInvoice)}
            />
          )}
        </>
      ) : (
        <SpinnerBox />
      )}
    </ModalFrame>
  );
};

export const showUpdateCraModal = create<Props>(UpdateCraModal);
