const colors = {
  /* white: '#FFFFFF',
  blue: '#003cc2',
  darkBlue: '#004269',
  lightBlue: '#B4C6D3',
  grayBlue: '#819CAD',
  red: '#F24750',
  green: '#1BC773',
  lightGray: '#FAF9F9',
  paleGray: '#F4F8FA',
  lightRed: '#FDEEEF',
  superLightBlue: '#EEF6FA',
  inProgress: '#FF8C4C',*/

  primary: {
    lighter: '#eef6fa',
    light: '#8fa3cf',
    blueLight: '#e5f5ff',
    default: '#003cc2',
    //dark: '#004269',
    greyLight: '#e4e6ef',
    white: '#FFFFFF',
    input: '#49506c',
    inputBackGround: '#f4f6fd',
    inputBorder: '#8fa3cf',
    inputLabel: '#49506c',
  },
  warning: {
    lighter: '#eef6fa',
    light: '#8fa3cf',
    default: '#FF8C4C',
    //dark: '#004269',
    greyLight: '#e4e6ef',
    white: '#FFFFFF',
    input: '#FF8C4C',
    inputBackGround: '#fff4ec',
    inputBorder: '#FF8C4C',
    inputLabel: '#49506c',
  },
  // #e4e6ef
  disabled: {
    lighter: '#eef6fa',
    light: '#819CAD',
    default: '#003cc2',
    dark: '#004269',
    white: '#FFFFFF',
    input: '#a0a4b6',
    inputBackGround: '#FFFFFF',
    inputBorder: '#c9c9c9',
    inputLabel: 'red',
  },

  text: {
    title: '#003cc2',
    default: '#3d4454',
    primary: '#3d4454',
    secondary: '#49506c',
    bold: '#8a8f99',
    input: '#49506c',
    inputLabel: '#49506c',
  },

  error: {
    default: '#f24750',
    dark: '#f24750',
    light: '#fdeeef',
    lighter: '#fdeeef',
    input: '#a0a4b6',
    inputBackGround: '#fff3f4',
    inputBorder: '#ea1c30',
    inputLabel: '#49506c',
  },
  success: { default: '#1BC773', light: '#1BC773', lighter: '#1BC773' },
  grey: {
    default: '#819CAD',
    light: '#819CAD',
    lighter: '#819CAD',
    transparent: '#c9c9c9',
  },
};

const fontSize = {
  small: 11,
  regular: 14,
  xlarge: 20,
};

const padding = {
  regular: 20,
};

const sizes = {
  xs: 0,
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
};

export const Theme = {
  colors,
  fontSize,
  padding,
  breakpoints: sizes,
  sizes,
  innerSize: 1170,

  background: '#FFFFFF',
  text: colors.text.default,
  success: '#009933',
  danger: `#ea1c30`,
};

export default Theme;
