import { ReactNode } from 'react';
import { boxProperties, BoxProps } from 'components/ui';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import styled from 'styled-components';
import 'styles/bootstrap.css';

const DropDownButtonStyled = styled(DropdownButton)`
  button {
    background-color: ${props => props?.theme?.colors?.primary?.default};
    border-color: ${props => props?.theme?.colors?.primary?.default};
  }
  .dropdown-menu {
    color: white;
    background-color: ${props => props?.theme?.colors?.primary?.default};
    border-color: ${props => props?.theme?.colors?.primary?.default};
  }
  .dropdown-item {
    color: white;
    background-color: ${props => props?.theme?.colors?.primary?.default};
    border-color: ${props => props?.theme?.colors?.primary?.default};
  }
  ${boxProperties}
`;
type DropDownButtonCustomItemProps = {
  href?: string;
  onClick?: (e: any) => void;
  children: ReactNode;
};
type DropDownButtonCustomProps = BoxProps & {
  buttonText: string;
  items: DropDownButtonCustomItemProps[];
  onClick?: (e: any) => void | false;
};
export const DropDownButtonCustom = ({
  buttonText,
  items,
  onClick,
}: DropDownButtonCustomProps) => {
  return (
    <DropDownButtonStyled
      id="dropdown-basic-button"
      title={buttonText}
      onClick={onClick ? onClick : undefined}
    >
      {items?.map(item => (
        <Dropdown.Item
          onClick={(e: any) => (item?.onClick ? item?.onClick(e) : false)}
          href={item?.href}
        >
          {item?.children}
        </Dropdown.Item>
      ))}
    </DropDownButtonStyled>
  );
};
export default DropDownButtonCustom;
