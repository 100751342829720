import { useDpaeFindMany } from '@commons';
import { mapSortByToApi } from 'helpers/table';
import { useDpaeListStore } from 'store/dpae';
export const useFetchData = ({ pageSize, sortBy }: any) => {
  const { pageIndex, filterObject } = useDpaeListStore();
  const { data, status, isFetching } = useDpaeFindMany({
    filterObject,
    sort: mapSortByToApi(sortBy),
    limit: pageSize,
    skip: pageIndex * pageSize,
    disabledFetch: filterObject ? false : true,
  });
  return {
    data,
    status,
    isFetching,
  };
};
