import { IJoinedContractor } from '@freelancelabs/teoreme-commons';
import { Box } from 'components/ui';
import React from 'react';
type ContractorMissionsProps = {
  contractor: IJoinedContractor;
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const ContractorMissions: React.FC<
  React.PropsWithChildren<ContractorMissionsProps>
> = () => {
  //const { data: contractor } = useContractorFindOne(id);

  return <Box width={1 / 1}>ContractorMissions</Box>;
};

ContractorMissions.displayName = 'ContractorMissions';

export default ContractorMissions;
