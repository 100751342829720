import styled, { css } from 'styled-components';
import { boxProperties, BoxProps } from './Box';
type TextProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'p' | 'u' | 'b' | 'span' | 'li' | 'a';
};
export const Text = styled.div.attrs<TextProps>(props => ({
  as: props.variant,
}))<TextProps & BoxProps>`
  color: ${p => p.theme.colors.primary.default};
  line-height: normal;
  margin: 0;
  ${props => {
    switch (props.variant) {
      case 'a':
        return css`
          text-decoration: underline;
          color: #003cc2;
          font-size: 32px;
          //font-weight: 700;
        `;
      case 'h1':
        return css`
          color: ${p => p.theme.colors.text.title};
          font-size: 32px;
          font-weight: 700;
        `;
      case 'h2':
        return css`
          color: ${p => p.theme.colors.text.title};
          font-size: 24px;
          font-weight: 700;
        `;
      case 'h3':
        return css`
          color: ${p => p.theme.colors.text.primary};
          font-size: 18px;
          font-weight: 700;
        `;
      case 'b':
        return css`
          color: ${p => p.theme.colors.text.secondary};
          font-size: 16px;
          font-weight: bold;
        `;
      case 'li':
        return css`
          padding: 5px;
          color: ${p => p.theme.colors.text.secondary};
          font-size: 14px;
          list-style: inside;
        `;

      case 'p':
      default:
        return css`
          color: ${p => p.theme.colors.text.secondary};
          font-size: 14px;
        `;
    }
  }}
  ${boxProperties}
`;

Text.defaultProps = {
  variant: 'p',
};
