/* eslint-disable react/display-name */
import { getTradeNameSafe, FR_STATUS } from '@commons';
import { EContractState } from '@freelancelabs/teoreme-commons';
import { Text, Link, Flex, Status } from 'components/ui';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';

export const columns = [
  {
    disableSortBy: false,
    Header: 'Réf.',
    accessor: 'refContract',
    Cell: (item: any) => {
      const refContract = item?.row?.original?.refContract;
      const attachment = item?.row?.original?.attachment;
      return (
        <>
          <Text variant="p" ml={20}>
            <Link
              fontSize={14}
              mr={10}
              onClick={() =>
                showDisplayPdfModal({
                  fileLocation: attachment?.fileLocation || '',
                  fileName: `${attachment?.fileName}.pdf`,
                  layer: 200,
                })
              }
            >
              {`${refContract}`}
            </Link>
          </Text>
        </>
      );
    },
  },
  {
    disableSortBy: false,
    Header: 'Type',
    accessor: 'type',
    Cell: (item: any) => {
      const type = item?.row?.original?.type;
      const lang = item?.row?.original?.lang;
      //@ts-ignore
      return `${type && FR_STATUS[type]} ${lang}`;
    },
  },
  {
    disableSortBy: false,
    Header: 'Statut',
    accessor: 'state',
    Cell: (item: any) => {
      const state = item?.row?.original?.state;
      const signedAt = item?.row?.original?.signedAt;
      const stateChangedAt = item?.row?.original?.stateChangedAt;
      let variantColor = 'grey';
      let text = '';
      /*
      Valeurs possibles pour tous les contrats (cadre et de formation) :

Pas de contrat : pas de contrat cadre pour l’entreprise  (rouge)

A signer : contrat à signer (rouge)

En cours de signature : contrat en cours de signature entre fournisseur et Téorème (orange)

Signé : contrat signé électroniquement par fournisseur et Téorème (vert)

Importé signé : contrat  généré et signé hors Téorème puis importé dans Téorème (vert)

Résilié : contrat résilié

Archivé : contrat archivé
      */
      switch (state) {
        case EContractState.ARCHIVED:
          variantColor = 'grey';
          text = 'Archivé';
          break;
        case EContractState.DONE:
          variantColor = 'success';
          text = `Signé le : ${signedAt?.toLocaleDateString()}`;
          break;
        case EContractState.SIGNED_BY_PROVIDER:
          // variantColor = 'success';
          // text = `Signé le : ${signedAt.toLocaleDateString()}`;
          variantColor = 'warning';
          text = 'En attente de signature';
          break;
        case EContractState.SIGNED_BY_FCOM:
          // variantColor = 'success';
          // text = `Signé le : ${signedAt.toLocaleDateString()}`;
          variantColor = 'warning';
          text = 'En attente de signature';
          break;
        case EContractState.TO_SIGN_PROVIDER:
          variantColor = 'warning';
          text = 'En attente de signature';
          break;
        case EContractState.TO_SIGN_FCOM:
          variantColor = 'warning';
          text = 'En attente de signature';
          break;
        case EContractState.REFUSED_BY_PROVIDER:
          variantColor = 'error';
          text = `Refusé le ${stateChangedAt?.toLocaleDateString()}`;
          break;
        case EContractState.REFUSED_BY_FCOM:
          variantColor = 'error';
          text = `Refusé le ${stateChangedAt?.toLocaleDateString()}`;
          break;

        default:
          variantColor = 'grey';
      }
      return (
        <Flex>
          <Status
            //@ts-ignore
            variantColor={variantColor}
          >
            {text}
          </Status>
        </Flex>
      );
    },
  },
  {
    disableSortBy: false,
    Header: 'Établissement',
    accessor: 'establishment',
    Cell: (item: any) => {
      const establishment = item?.row?.original?.establishment;
      return getTradeNameSafe(establishment);
    },
  },
  // {
  //   disableSortBy: true,
  //   Header: 'Actions',
  //   accessor: 'actions',

  //   Cell: (item: any) => {
  //     return <></>;
  //   },
  // },
];
