import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { boxProperties, BoxProps } from 'components/ui';
type SubItemsProps = {
  isSelected?: boolean;
  variant?: 'primary' | 'secondary';
  onChange: (value: string) => void;
  children?: ReactNode;
  icon?: ReactElement;
} & BoxProps;

export const StyledSubItem = styled.div<SubItemsProps>`
  display: flex;
  align-items: center;
  //padding: 0px 10px 0px 10px;
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  line-height: normal;
  font-size: 16px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 31px;
  width: 31px;
  border-radius: 50%;
  white-space: nowrap;
  ${props =>
    props.variant === 'primary' &&
    css`
      color: white;
      background-color: ${p => p.theme.colors.primary.default};
    `}
  ${props =>
    props.variant === 'secondary' &&
    css`
      color: ${p => p.theme.colors.primary.default};
      box-shadow: 0 -4px 0 0 ${p => p.theme.colors.primary.default} inset;
    `}
  ${props =>
    !props.isSelected &&
    css`
      background-color: white;
      color: ${p => p.theme.colors.primary.default};
      border: none;
      &:hover {
        color: ${p => p.theme.colors.primary.default};
      }
    `}
    ${props =>
    props.isSelected &&
    css`
      //padding: 0px 10px 0px 20px;
    `}



  cursor: pointer;

  ${boxProperties}

  svg {
    margin-right: 10px;
    opacity: 0.4;
  }
`;

export const LetterBox = ({
  onChange,
  isSelected,
  children,
  icon,
  ...props
}: SubItemsProps) => {
  return (
    <StyledSubItem
      //@ts-ignore
      onChange={(value: string) => onChange(value)}
      isSelected={isSelected}
      {...props}
    >
      {children}
      {icon}
    </StyledSubItem>
  );
};

LetterBox.defaultProps = {
  variant: 'primary',
};
