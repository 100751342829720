import {
  buildOneLineAddress,
  getFullName,
  getSructure,
  getTradeNameSafe,
  isAdditionalActivityInvoice,
  isDisableFONEDate,
} from '@commons';
import {
  EMissionStructure,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import {
  Cell,
  Flex,
  FormControl,
  Grid,
  Input,
  LabelField,
  Text,
} from 'components/ui';
import { TextInformation } from 'components/TextInformation';
import { ExclamationCircleIcon } from 'components/ui/icons';
import { Theme } from 'styles';

type FormValues = {
  invoice: IJoinedInvoice;
};
type InvoiceMissionInfomationsFormProps = {
  form: FormValues & any;
  isEditable: boolean;
};

const grid2 = {
  xs: '3',
  sm: '3',
  md: '3',
  lg: '3',
  xl: '3',
};
const grid4 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '12',
};

export const InvoiceMissionInfomationsForm = ({
  form: {
    formState: { errors, isDirty },
    register,
    watch,
    control,
    setValue,
    formState,
    getValues,
  },
  isEditable,
}: InvoiceMissionInfomationsFormProps) => {
  const invoice = getValues();
  const mission = invoice?.mission;
  const isMandateMode = mission?.isMandateMode;
  const structure = watch('mission.sage.structure');
  const isDeadStructure = () => {
    if (structure === EMissionStructure?.FONE) {
      if (isDisableFONEDate()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const getStructureNotDead = () => {
    if (isDeadStructure()) {
      return EMissionStructure?.FCOM;
    } else {
      return structure;
    }
  };
  const contractorCivlity =
    Number(watch('contractor')?.civility) === 1 ? 'M. ' : 'Mme ';
  const contractor = contractorCivlity + getFullName(watch('contractor'));
  const month = watch('month');
  return (
    <Grid cols={12} gap="20px">
      <Cell x-span={{ xs: '12' }}>
        <Text variant="h2" width={'calc(100% - 250px)'}>
          Informations liées à la mission
        </Text>
      </Cell>
      <Cell x-span={{ xs: '12' }}>
        {isDeadStructure() && !isMandateMode && (
          <TextInformation width={1 / 1} variant="error">
            <ExclamationCircleIcon
              fill={Theme?.colors?.error?.default}
              style={{ marginRight: '5px' }}
            />
            <b>
              A la suite du transfert universel de partrimoine de FreelanceOne
              vers Freelance.com, vérifiez bien que vous facturez l'entité
              Freelance.com pour que nous puissions accepter votre facture.
            </b>
          </TextInformation>
        )}
        <Flex width={1 / 1} flexWrap={'wrap'} display="inline-flex">
          <LabelField
            minWidth={'230px'}
            label="Entité à facturer :"
            value={
              !isMandateMode
                ? getSructure(getStructureNotDead())?.complete
                : getTradeNameSafe(invoice?.estCustomer)
            }
            mr={20}
          />
          <LabelField
            minWidth={'630px'}
            label="Adresse :"
            value={
              !isMandateMode
                ? buildOneLineAddress(
                    getSructure(getStructureNotDead())?.address
                  )
                : buildOneLineAddress(invoice?.estCustomer?.address)
            }
          />
          <LabelField
            minWidth={'266px'}
            label="SIREN de l'établissement :"
            value={
              !isMandateMode
                ? getSructure(getStructureNotDead())?.siren
                : invoice?.estCustomer?.siren
            }
          />
        </Flex>
      </Cell>
      <Cell x-span={grid2}>
        <FormControl
          label="Référence de la mission"
          errorMessage={errors?.mission?.displayReference?.message}
        >
          <Input
            isDisabled
            isFullWidth
            pr={20}
            {...register('mission.displayReference')}
          />
        </FormControl>
      </Cell>
      {month && (
        <Cell x-span={grid2}>
          <FormControl label="Mois" errorMessage={errors?.month?.message}>
            <Input
              isDisabled
              isFullWidth
              pr={20}
              value={`${new Date(month)?.getUTCMonth() + 1}/${new Date(
                month
              )?.getUTCFullYear()}`}
            />
          </FormControl>
        </Cell>
      )}

      <Cell x-span={grid2}>
        <FormControl label="Intervenant">
          <Input isDisabled isFullWidth pr={20} value={contractor} />
        </FormControl>
      </Cell>

      {!isAdditionalActivityInvoice(invoice.invoiceType) && (
        <>
          <Cell x-span={grid2}>
            <FormControl label="TJM HT de l'intervenant">
              <Input
                isDisabled
                isFullWidth
                pr={20}
                {...register('contractorRate')}
              />
            </FormControl>
          </Cell>
          <Cell x-span={grid2}>
            <FormControl
              label="NB. de jours travaillés"
              errorMessage={errors?.nbWorkingDays?.message}
            >
              <Input
                isDisabled
                isFullWidth
                pr={20}
                {...register('nbWorkingDays')}
              />
            </FormControl>
          </Cell>
        </>
      )}
    </Grid>
  );
};
