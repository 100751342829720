import { ChangeEmailIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Box, Flex, Link, Row, Text } from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import * as React from 'react';

export const RefreshToken: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Votre lien d’activation à été généré`,
      path: '',
    },
  ]);

  return (
    <Flex
      height={'100vh'}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Flex alignItems="center" width="100%" height={'100%'}>
        <Row spacing={20} width="100%" height={'100%'}>
          <Flex
            height={'100%'}
            width="100%"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <ChangeEmailIcon width={800} height="50vh" />
          </Flex>
          <Flex
            height={'100%'}
            width="100%"
            alignItems="center"
            alignContent="center"
            justifyContent="flex-start"
          >
            <Box width={500}>
              <Text variant="h1">Merci !</Text>
              <Text mt={20}>
                Votre lien d’activation à été généré, veuillez consulter vos
                emails et cliquer sur le lien d'activation.
              </Text>
              <Text variant="p">
                Vous avez besoin d’aide ?{' '}
                <Link onClick={showContactUsModal}>Contactez-nous</Link>
              </Text>
            </Box>
          </Flex>
        </Row>
      </Flex>
    </Flex>
  );
};
export default RefreshToken;
