import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { MilestonesDetailsForm } from 'forms/Activities/Milestones';
import { create } from 'react-modal-promise';
import { useAdditionalActivityFindOne, useMissionFindOne } from '@commons';
import {
  IJoinedAdditionalActivity,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import { SpinnerBox, Text } from 'components/ui';
type Props = ModalProps & {
  mission: IJoinedMission;
  beforeValidation?: () => void;
  layer?: number;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
};

export const CreateOrUpdateMileStoneModal = ({
  onResolve,
  isOpen,
  layer,
  method,
  mission,
  uuid,
}: Props) => {
  const { data: activity, isFetching } = useAdditionalActivityFindOne(uuid);
  const { data: missionComplet, isFetching: isFetchingMission } =
    useMissionFindOne(mission?.reference);
  const isLoading = isFetching || isFetchingMission;
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={1200}
      closeIcon
      layer={layer}
    >
      <Text variant="h1" mb={20}>
        {method === 'CREATE'
          ? 'Saisie du montant HT à facturer'
          : 'Saisie du montant HT à facturer'}
      </Text>
      {isLoading && <SpinnerBox />}
      {!isLoading && (
        <MilestonesDetailsForm
          mission={missionComplet as IJoinedMission}
          method={method}
          mileStone={activity as IJoinedAdditionalActivity}
          onResolve={(params: any) => onResolve(params)}
          onClose={(value: any) => onResolve(value)}
        />
      )}
    </ModalFrame>
  );
};

export const showCreateOrUpdateMileStoneModal = create<Props>(
  CreateOrUpdateMileStoneModal
);
