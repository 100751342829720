import React, { forwardRef, InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { Box } from './Box';
import { useCombinedRefs } from './helpers/useCombinedRefs';
import { Spinner } from './Spinner';

const Checker = styled.div<{ isLoading: boolean }>`
  width: 60px;
  height: 30px;
  background: ${props => props.theme.colors.primary.light};
  border-radius: 20px;
  position: relative;
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
`;

const Ball = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 200px;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.2s;
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
`;

const SwitchContainer = styled(Box)`
  position: relative;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:disabled ~ ${Label} {
    opacity: 0.5;
  }

  input:focus ~ ${Label}, &:hover ${Label} {
    ${Checker} {
    }
  }

  input:not(checked) ~ ${Label} {
    ${Checker} {
    }
  }

  input:checked ~ ${Label} {
    ${Checker} {
      background-color: ${props => props.theme.colors.primary.default};
      ${Ball} {
        transform: translateX(30px);
      }
    }
  }
`;

type SwitchProps = {
  children: React.ReactNode;
  id: string;
  name?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  labelPosition?: 'left' | 'right';
};

export const CheckSwitch = forwardRef(
  (
    {
      children,
      id,
      name,
      onChange,
      checked,
      defaultChecked,
      labelPosition = 'right',
      onClick,
      isLoading = false,
      isDisabled = false,
      ...props
    }: InputHTMLAttributes<HTMLInputElement> & SwitchProps & SpaceProps,
    ref
  ) => {
    const thisRef = useRef(null) as React.RefObject<HTMLInputElement | null>;
    const combinedRef = useCombinedRefs(
      ref,
      thisRef
    ) as React.RefObject<HTMLInputElement>;

    return (
      <SwitchContainer {...props}>
        <input
          onClick={onClick}
          type="checkbox"
          ref={combinedRef}
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={isDisabled}
        />
        <Label htmlFor={id}>
          {labelPosition === 'left' && (
            <div style={{ marginRight: 10 }}>{children}</div>
          )}
          <Checker isLoading={isLoading}>
            <Ball />
          </Checker>

          {labelPosition === 'right' && (
            <div style={{ marginLeft: 10 }}>{children}</div>
          )}
          {isLoading && <Spinner m="0 10px" size={10} thickness={1} />}
        </Label>
      </SwitchContainer>
    );
  }
);
CheckSwitch.displayName = 'CheckSwitch';
