import { ECraStatus } from '@freelancelabs/teoreme-commons';
import { create } from 'zustand';

type State = {
  pageIndex: number;
  updateCraStore: (state: Partial<State>) => void;
  resetCraStore: (state: Partial<State>) => void;
  selectedStatus: ECraStatus | 'ALL';
  selectedRowIds: Array<string>;
  startDate: Date | null;
  endDate: Date | null;
  searchQuery: string;
  columnsHidden: Array<any>;
  isOpen?: boolean;
};

const initialStates = {
  startDate: null,
  endDate: null,
  selectedStatus: 'ALL' as const,
  pageIndex: 0,
  selectedRowIds: [],
  searchQuery: '',
  columnsHidden: [],
  isOpen: false,
};

const store = (set: any) => ({
  ...initialStates,
  updateCraStore: (state: Partial<State>) => set(state),
  resetCraStore: (state: Partial<State>) => set({ ...initialStates, ...state }),
});

export const useCraListStore = create<State>(store);
