import {
  updateOnBoardingStep,
  checkProviderEstaLinkedToRole,
  formatAllObject,
  establishmentUseIban,
} from '@commons';
import { electronicFormatIBAN } from 'ibantools';
import { useMe } from '@commons';
import { showSaveLaterModal } from 'components/modals/SaveLaterModal';
import { Box, Button, Divider, Flex, Link, Text } from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { EstablishmentForm } from './EstablishmentForm';
import { PaiementForm } from './PaiementForm';
import { SignatoryInformationForm } from './SignatoryInformationForm';
import { showDialogModal } from 'components/modals/DialogModal';
import IconModal from 'forms/IconModal';
import { yousignNameRestrictionPattern } from '@commons';
import { printFormat } from 'iban';
import { ESignOfficerType } from '@freelancelabs/teoreme-commons';
type FormValues = any;

type OnBoardingStepTwoProps = {
  onSubmit?: () => void;
  onBoardingData: any;
  refreshStep: () => void;
};

export const OnBoardingStepTwo = ({
  refreshStep,
  onBoardingData,
}: OnBoardingStepTwoProps) => {
  const [initialState, setItinitalState] = React.useState(true);
  const [isLoadingRib, setIsLoadingRib] = React.useState(false);
  const [isLoadingDelegatedOrder, setIsLoadingDelegatedOrder] =
    React.useState(false);
  const [isSaveLater, setIsSaveLater] = React.useState<boolean>(false);
  const [localEstablishment, setLocalEstablishment] = React.useState<
    any | null
  >(null);
  const onBoardingEstablishment =
    onBoardingData?.establishment && onBoardingData?.establishment.length > 0
      ? onBoardingData?.establishment[0]
      : undefined;

  /* transform civility to string */
  if (onBoardingEstablishment && onBoardingEstablishment?.provider) {
    if (onBoardingEstablishment.provider.signOfficer) {
      onBoardingEstablishment.provider.signOfficer.civility = String(
        onBoardingEstablishment.provider.signOfficer.civility
      );
    }
    if (onBoardingEstablishment?.provider?.delegatedSignOfficer) {
      onBoardingEstablishment.provider.delegatedSignOfficer.civility = String(
        onBoardingEstablishment.provider.delegatedSignOfficer.civility
      );
    }
  }
  /* transform bankAccount to update */
  if (onBoardingEstablishment?.provider?.bankAccount) {
    const bankAccount = onBoardingEstablishment?.provider?.bankAccount;
    onBoardingEstablishment.provider.bankAccount = {
      bankName: bankAccount.bankName,
      bic: bankAccount.bic?.toUpperCase(),
      iban: bankAccount?.iban ? printFormat(bankAccount.iban, ' ') : '',
      owner: bankAccount.owner,
      rib: bankAccount?.rib,
    };
  }
  const form = useForm<FormValues>({
    defaultValues: {
      establishment: {
        ...onBoardingEstablishment,
        isCorporateOfficer: true,
        attachRequestSent: false,
      },
    },
  });
  const { me } = useMe();
  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setError,
    getValues,
    setValue,
  } = form;
  console.log('ON ERROR', errors);
  const [isCorporateOfficer, setIsCorporateOfficer] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const establishmentForm = watch('establishment');
  const getFormInfos = (): any => {
    const establishmentTested = initialState
      ? onBoardingEstablishment
      : localEstablishment; // new establishment selected !
    const formInfos = {
      initialState,
      establishmentTested,
      formCase: -1,
      displayForms: false,
      disableButton: false,
      needLink: false,
    };

    if (establishmentTested) {
      const isProviderResponsible = checkProviderEstaLinkedToRole(
        establishmentTested,
        'PROVIDER_RESPONSIBLE',
        (me as any)?.cognitoUserId
      );
      const isProviderContact = checkProviderEstaLinkedToRole(
        establishmentTested,
        'PROVIDER_CONTACT',
        (me as any)?.cognitoUserId
      );
      if (isProviderContact) {
        formInfos.formCase = 1;
        formInfos.displayForms = false;
        formInfos.needLink = false;
      }
      if (isProviderResponsible) {
        formInfos.formCase = 2;
        formInfos.displayForms = true;
        formInfos.needLink = false;
      }
      if (!isProviderContact && !isProviderResponsible) {
        if (establishmentTested.uuid) {
          formInfos.formCase = 3;

          if (establishmentTested?.provider?.manager) {
            formInfos.needLink = true;
            formInfos.disableButton = true;
            formInfos.displayForms = false;
          } else {
            formInfos.needLink = false;
            formInfos.disableButton = false;
            formInfos.displayForms = true;
          }
        } else {
          formInfos.formCase = 3;
          formInfos.displayForms = true;
          formInfos.disableButton = false;
          formInfos.needLink = false;
        }
      }
    } else {
      formInfos.formCase = 3;
      formInfos.displayForms = false;
      formInfos.disableButton = true;
      formInfos.needLink = false;
    }
    return formInfos;
  };
  const formInfos = getFormInfos();
  const handleUpdateStep = async (saveLater: boolean) => {
    setIsSaveLater(saveLater);
    let onError = false;
    const submitData = {
      saveLater: saveLater,
      data: {
        establishment: formInfos.establishmentTested,
      },
    };
    if (formInfos.formCase !== 1) {
      submitData.data.establishment.isCorporateOfficer = isCorporateOfficer;
    }
    if (saveLater === true) {
      if (formInfos.formCase === 3 || formInfos.formCase === 2) {
        if (establishmentForm.provider) {
          if (!submitData.data.establishment.provider) {
            submitData.data.establishment.provider = {};
          }
          if (establishmentForm.provider.bankAccount) {
            submitData.data.establishment.provider.bankAccount =
              establishmentForm.provider.bankAccount;
          }
          if (
            establishmentForm.provider.signOfficerType &&
            establishmentForm.provider.signOfficerType !== ''
          ) {
            submitData.data.establishment.provider.signOfficerType =
              establishmentForm.provider.signOfficerType;
          }
          if (establishmentForm.provider.signOfficer) {
            submitData.data.establishment.provider.signOfficer =
              establishmentForm.provider.signOfficer;
          }
          if (establishmentForm.provider.delegatedSignOfficer) {
            submitData.data.establishment.provider.delegatedSignOfficer =
              establishmentForm.provider.delegatedSignOfficer;
          }
          // force signOfficerType
          if (submitData.data.establishment.provider.signOfficerType === '') {
            if (submitData.data.establishment.provider.signOfficer) {
              submitData.data.establishment.provider.signOfficerType =
                'CORPORATE_OFFICER';
            }
            if (submitData.data.establishment.provider.signOfficer) {
              submitData.data.establishment.provider.delegatedSignOfficer =
                'DELEGATED_OFFICER';
            }
          }
        }
      }
      // delete data of signatoryOfficer if isCorporateOfficer === true
      if (
        (formInfos.formCase === 3 || formInfos.formCase === 2) &&
        isCorporateOfficer === true
      ) {
        if (
          submitData.data.establishment &&
          submitData.data.establishment.provider
        ) {
          delete submitData.data.establishment.provider.signOfficerType;
          delete submitData.data.establishment.provider.signOfficer;
          delete submitData.data.establishment.provider.delegatedSignOfficer;
        }
      }
      try {
        setLoading(true);
        if (
          submitData.data.establishment &&
          submitData.data.establishment.provider
        ) {
          submitData.data.establishment.provider = formatAllObject(
            submitData.data.establishment.provider,
            yousignNameRestrictionPattern
          );
        }
        await updateOnBoardingStep(submitData);
        setLoading(false);
        await showSaveLaterModal().then(async value => {
          if (value === false) {
            await refreshStep();
          }
        });
      } catch (e) {
        //console.log('e', e);
      }
    }
    if (saveLater === false) {
      submitData.data.establishment = establishmentForm;
      if (formInfos.formCase !== 1) {
        const bankAccount =
          submitData?.data?.establishment?.provider?.bankAccount;
        submitData.data.establishment.isCorporateOfficer = isCorporateOfficer;
        if (bankAccount?.bankAccount) {
          submitData.data.establishment.provider.bankAccount = {
            ...bankAccount.bankAccount,
            iban: bankAccount?.iban
              ? electronicFormatIBAN(bankAccount?.iban)
              : undefined,
          };
        }
        if (!establishmentForm?.provider?.bankAccount?.rib?.fileLocation) {
          setError('establishment.provider.bankAccount.rib', {
            message: 'Ce champ est obligatoire',
          });
          onError = true;
        } else {
          clearErrors('establishment.provider.bankAccount.rib');
        }
        if (isCorporateOfficer === false) {
          if (errors?.establishment?.provider?.signOfficer?.phone?.message) {
            onError = true;
          }
          if (
            establishmentForm?.provider?.signOfficerType === 'DELEGATED_OFFICER'
          ) {
            if (
              errors?.establishment?.provider?.delegatedSignOfficer?.phone
                ?.message
            ) {
              onError = true;
            }
            if (
              !establishmentForm?.provider?.delegatedSignOfficer
                ?.delegationOrder
            ) {
              setError(
                'establishment.provider.delegatedSignOfficer.delegationOrder',
                { message: 'Ce champ est obligatoire' }
              );
              onError = true;
            } else {
              // setError(
              //   'establishment.provider.delegatedSignOfficer.delegationOrder',
              //   { message: undefined }
              // );
              clearErrors(
                'establishment.provider.delegatedSignOfficer.delegationOrder'
              );
            }
          }
        } else {
          // delete data of signatoryOfficer if isCorporateOfficer === true
          if (
            submitData.data.establishment &&
            submitData.data.establishment.provider
          ) {
            delete submitData.data.establishment.provider.signOfficerType;
            delete submitData.data.establishment.provider.signOfficer;
            delete submitData.data.establishment.provider.delegatedSignOfficer;
          }
        }
      }
      if (
        (formInfos.formCase === 3 || formInfos.formCase === 2) &&
        isCorporateOfficer === false
      ) {
        if (
          submitData.data.establishment.provider.signOfficerType ===
          'CORPORATE_OFFICER'
        ) {
          delete submitData.data.establishment.provider.delegatedSignOfficer;
        }
      }
      if (onError === false) {
        try {
          await showDialogModal({
            title: 'Êtes-vous sûr(e) de vouloir valider ce formulaire ?',
            text: '',
            confirmLabel: 'Valider',
            cancelLabel: 'Annuler',
          }).then(async action => {
            if (action) {
              if (
                submitData.data.establishment &&
                submitData.data.establishment.provider
              ) {
                submitData.data.establishment.provider = formatAllObject(
                  submitData.data.establishment.provider
                );
              }
              try {
                setLoading(true);
                await updateOnBoardingStep({
                  saveLater: submitData?.saveLater,
                  data: {
                    establishment: {
                      siret: submitData.data.establishment.siret,
                      //@ts-ignore
                      uuid: submitData.data.establishment?.uuid,
                      isCorporateOfficer:
                        formInfos.formCase !== 1
                          ? isCorporateOfficer
                          : undefined,
                      provider: {
                        bankAccount:
                          submitData.data.establishment?.provider?.bankAccount,
                        signOfficerType:
                          submitData.data.establishment?.provider
                            ?.signOfficerType,
                        signOfficer:
                          submitData.data.establishment?.provider?.signOfficer,
                        delegatedSignOfficer:
                          submitData.data.establishment?.provider
                            ?.signOfficerType ===
                          ESignOfficerType.DELEGATED_OFFICER
                            ? submitData.data.establishment?.provider
                                ?.delegatedSignOfficer
                            : undefined,
                      },
                    },
                  },
                });
                await refreshStep();
              } catch (e) {
                //
              }
            }
          });
          setLoading(false);
        } catch (e) {
          //console.log('e', e);
        }
      }
    }
    setLoading(false);
  };

  const onSubmit = () => handleUpdateStep(false);
  const onSubmitLater = () => handleUpdateStep(true);
  const onSubmitLaterFake = async () => showSaveLaterModal();
  const checkDisableSubmit = () => {
    const formValues = getValues();
    const establishmentTested = initialState
      ? onBoardingEstablishment
      : localEstablishment; // new establishment selected
    // DATA MIXED ON LOCAL STATE AND API
    const bankAccount =
      formValues?.establishment?.provider?.bankAccount ||
      establishmentTested?.provider?.bankAccount;

    const signOfficer =
      formValues?.establishment?.provider?.signOfficer ||
      establishmentTested?.provider?.signOfficer;

    const signOfficerType =
      formValues?.establishment?.provider?.signOfficerType ||
      establishmentTested?.provider?.signOfficerType;
    const delegatedSignOfficer =
      formValues?.establishment?.provider?.delegatedSignOfficer ||
      establishmentTested?.provider?.delegatedSignOfficer;

    if (establishmentTested) {
      if (formInfos?.formCase !== 1) {
        if (establishmentUseIban(establishmentTested))
          if (
            !bankAccount?.bankName ||
            !bankAccount?.bic ||
            !bankAccount?.iban ||
            !bankAccount?.owner ||
            !bankAccount?.rib
          ) {
            //console.log('BLOC 1 ');
            return true;
          }
        if (!isCorporateOfficer) {
          if (signOfficerType === 'CORPORATE_OFFICER' || !signOfficerType) {
            if (
              !signOfficer?.civility ||
              !signOfficer?.jobTitle ||
              !signOfficer?.firstName ||
              !signOfficer?.email ||
              !signOfficer?.lastName
            ) {
              //console.log('BLOC 2 ');
              return true;
            }
          }
          if (signOfficerType === 'DELEGATED_OFFICER') {
            if (
              !signOfficer?.civility ||
              !signOfficer?.jobTitle ||
              !signOfficer?.firstName ||
              !signOfficer?.lastName
            ) {
              //console.log('BLOC 3 ');
              return true;
            }
            if (
              !delegatedSignOfficer?.civility ||
              //!delegatedSignOfficer?.jobTitle ||
              !delegatedSignOfficer?.firstName ||
              !delegatedSignOfficer?.lastName ||
              !delegatedSignOfficer?.email ||
              !delegatedSignOfficer?.delegationOrder
            ) {
              //console.log('BLOC 4 ');
              return true;
            }
          }
        }
      }
    }
  };
  const disableSubmit = checkDisableSubmit();
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: 'calc(100vh - 200px)', overflow: 'scroll' }}
    >
      <Box>
        <Box paddingLeft="10%" paddingRight="10%" mt={60}>
          <Flex mt={20} justifyContent="flex-end" width={1 / 1}>
            <IconModal />
          </Flex>
          <Box>
            <Text mb={13} variant="h1">
              Dites-nous en plus sur votre établissement
            </Text>
            <Text variant="p">
              {formInfos.formCase === 1 && (
                <p>
                  Vous avez été ajouté comme <b>chargé de mission</b> de
                  l'établissement suivant. Si votre prochaine mission concerne
                  cet établissement, vous pouvez passer directement à l'étape
                  suivante. Si non, merci de renseigner un autre établissement.
                </p>
              )}
              {formInfos.formCase === 2 && (
                <p>
                  Vous avez été désigné <b>responsable de compte</b> de
                  l'établissement suivant. Si votre prochaine mission concerne
                  cet établissement, merci de vérifier l’exactitude des
                  informations suivantes et/ou de les compléter le cas échéant.
                  Si non, merci de renseigner un autre établissement.
                </p>
              )}
              {formInfos.formCase === 3 && (
                <p>
                  Afin d’accéder à votre espace personnel, merci de compléter
                  les informations de votre établissement.
                </p>
              )}
            </Text>
          </Box>
          <EstablishmentForm
            formInfos={formInfos}
            form={form}
            onBoardingData={onBoardingData}
            onChangeEstablishment={(establishment: any) => {
              setItinitalState(false);
              setIsCorporateOfficer(true);
              if (establishment && !establishment.closedSince) {
                setLocalEstablishment(establishment);
              } else {
                setLocalEstablishment(null);
              }
            }}
            refreshStep={refreshStep}
          />
          <Link
            fontSize={14}
            onClick={() =>
              showDialogModal({
                title: "Ajout d'un établissement étranger",
                text: `Pour ajouter  un établissement étranger, veuillez contacter votre chargé de compte. Une fois qu’il aura créé votre établissement, vous pourrez reprendre votre parcours d’embarquement sur Connecteed.`,
                confirmLabel: 'Fermer',
              })
            }
          >
            Mon établissement ne dispose pas d’un SIRET (établissement étranger)
          </Link>

          <PaiementForm
            formInfos={formInfos}
            form={form}
            onBoardingData={onBoardingData}
            isLoadingRib={(value: boolean) => setIsLoadingRib(value)}
          />

          <SignatoryInformationForm
            formInfos={formInfos}
            form={form}
            onBoardingData={onBoardingData}
            onChangeIsCorporateOfficer={value => {
              setValue('establishment.isCorporateOfficer', value);
              setIsCorporateOfficer(value);
            }}
            isLoadingDelegatedOrder={(value: boolean) =>
              setIsLoadingDelegatedOrder(value)
            }
          />
        </Box>
      </Box>
      <Box width={'100%'} position="fixed" bottom={0} height={100}>
        <Divider />
        <Flex alignItems="center" justifyContent="flex-end" pr={'10%'}>
          {/* <Button
            isDisabled={
              loading || isLoadingRib || isLoadingDelegatedOrder
                ? true
                : formInfos.disableButton &&
                  (formInfos.establishmentTested === null ||
                    formInfos.establishmentTested === undefined)
                ? false
                : formInfos.disableButton
            }
            variant={'ghost'}
            type="button"
            isLoading={loading && isSaveLater ? true : false}
            onClick={() =>
              formInfos.disableButton &&
              (formInfos.establishmentTested === null ||
                formInfos.establishmentTested === undefined)
                ? onSubmitLaterFake()
                : onSubmitLater()
            }
          >
            Enregistrer et finir plus tard
          </Button> */}
          <Button
            isDisabled={
              disableSubmit ||
              loading ||
              formInfos.disableButton ||
              isLoadingRib ||
              isLoadingDelegatedOrder
            }
            variant={'filled'}
            ml={20}
            type="submit"
            isLoading={loading && !isSaveLater}
          >
            Valider
          </Button>
        </Flex>
      </Box>
    </form>
  );
};

export default OnBoardingStepTwo;
