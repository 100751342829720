import React, { forwardRef, ReactNode, useEffect, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Box, boxProperties, BoxProps } from './Box';
import { Flex } from 'components/ui';
import { SearchFieldIcon, EyeIcon, EyeCloseIcon } from 'components/icons';
import { getDecimalInput } from '@commons';
type InputStyledProps = {
  variantColor?: 'primary' | 'error' | 'warning';
  isDisabled?: boolean;
  isFullWidth?: boolean;
  width?: string;
  icon?: ReactNode;
  min?: string;
  step?: string;
  maxDecimal?: number;
};

const InputStyled = styled.input<InputStyledProps>`
  height: 50px;
  border-radius: 4px;
  border: solid 1px
    ${props => props.theme.colors[props.variantColor || 'primary'].inputBorder};
  color: ${props => props.theme.colors[props.variantColor || 'primary'].input};
  background-color: ${props => {
    if (props.variantColor === 'warning') {
      return props.theme.colors.warning.inputBackGround;
    } else {
      return props.theme.colors[props.variantColor || 'primary']
        .inputBackGround;
    }
    //props.theme.colors[props.variantColor || 'primary'].inputBackGround
  }};
  padding: 15px;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  &:focus {
    border: solid 1px
      ${props => props.theme.colors[props.variantColor || 'primary'].default};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.disabled.inputBackGround};
    color: ${props => props.theme.colors.disabled.input};
    border: solid 1px ${props => props.theme.colors.disabled.inputBorder};
    cursor: not-allowed;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.isFullWidth &&
    css`
      width: 100%;
    `}

  padding-right: ${props => props.icon && '50px'};

  /* Hide input number arrows */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${boxProperties}
`;

const Icon = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  right: 17px;
  font-size: 20px;
  color: #ccc;
`;

type InputProps = BoxProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as'> &
  InputStyledProps;

export const Input = forwardRef(
  (
    {
      value,
      onChange,
      isFullWidth,
      icon,
      type,
      name,
      placeholder,
      variantColor,
      min,
      step,
      maxDecimal,
      ...props
    }: InputProps,
    ref
  ) => {
    const theme = useTheme();
    const [passwordType, setPasswordType] = React.useState(type);
    return (
      <Box
        display="inline-flex"
        width={isFullWidth ? '100%' : props.width ? props.width : 'auto'}
        position="relative"
        {...props}
      >
        <InputStyled
          {...{
            value,
            onChange,
            isFullWidth,
            type: passwordType,
            icon,
            name,
            placeholder,
            variantColor,
          }}
          width={props.width}
          disabled={props.isDisabled}
          ref={ref as any}
          min={min ? min : undefined}
          step={step}
          //FIX disabled increment on Scroll in input number !
          //@ts-ignore
          onWheel={e => type === 'number' && document?.activeElement?.blur()}
          onChange={e => {
            if (type === 'number' && maxDecimal) {
              e.target.value = getDecimalInput(
                e.target.value,
                maxDecimal
              ) as string;
            }
            if (typeof onChange === 'function') {
              onChange(e);
            }
            return e;
          }}
        />
        {type === 'password' ? (
          passwordType === 'text' ? (
            <Icon>
              <EyeCloseIcon
                fill={theme.colors.primary.default}
                onClick={() => setPasswordType('password')}
                style={{ cursor: 'pointer' }}
              />
            </Icon>
          ) : (
            <Icon>
              <EyeIcon
                fill={theme.colors.primary.default}
                onClick={() => setPasswordType('text')}
                viewBox="0 0 20 20"
                style={{
                  cursor: 'pointer',
                }}
              />
            </Icon>
          )
        ) : null}
        {type === 'search' && (
          <Flex
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            width={'70px'}
            borderRadius={'0px 5px 5px 0px'}
            backgroundColor={theme.colors.primary.default}
          >
            <SearchFieldIcon style={{ width: 30, height: 30 }} fill="white" />
          </Flex>
        )}
        {icon && <Icon>{icon}</Icon>}
      </Box>
    );
  }
);

Input.displayName = 'Input';
