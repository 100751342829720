import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CRA } from './CRA';
import { TimeSpent } from './TimeSpent';
import { CreditNote } from './CreditNote';
import { Template } from 'components/Template';
import { Activities } from './Activities';
import { ActivitiesFlatRate } from './ActivitiesFlateRate';
export const Invoices: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Switch>
      <Template>
        <Route exact path="/invoices">
          <Redirect to="/invoices/cra/to-be-submitted" />
        </Route>
        <Route path="/invoices/cra">
          <CRA />
        </Route>
        <Route path="/invoices/time-spent">
          <TimeSpent />
        </Route>
        <Route path="/invoices/credit-note">
          <CreditNote />
        </Route>
        <Route path="/invoices/activities">
          <Activities />
        </Route>
        <Route path="/invoices/flat-rate/activities">
          <ActivitiesFlatRate />
        </Route>
      </Template>
    </Switch>
  );
};
