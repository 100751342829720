import * as React from 'react';

function SvgAddIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18.857 10.857h-5.714V5.143a1.143 1.143 0 00-2.286 0v5.714H5.143a1.143 1.143 0 000 2.286h5.714v5.714a1.143 1.143 0 002.286 0v-5.714h5.714a1.143 1.143 0 000-2.286z"
      />
    </svg>
  );
}

export default SvgAddIcon;
