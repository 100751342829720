import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotificationsList } from './List';
import { Template } from 'components/Template';
import { Container } from 'components/ui';
export const Notifications = () => {
  return (
    <Switch>
      <Template>
        <Route exact path="/notifications">
          <Container>
            <Redirect to="/notifications/all" />
          </Container>
        </Route>
        <Route path="/notifications/:filter(all|non-read|read|prioritized)">
          <Container>
            <NotificationsList />
          </Container>
        </Route>
      </Template>
    </Switch>
  );
};
