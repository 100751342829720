import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  getTradeNameSafe,
  contractorUpdateOne,
  contractorNeedDpae,
} from '@commons';
import {
  IJoinedContractor,
  EDpaeStatus,
  EContractorStatus,
} from '@freelancelabs/teoreme-commons';
import {
  Grid,
  Cell,
  Box,
  Text,
  Divider,
  EstablishmentCard,
  Button,
  UserCard,
} from 'components/ui';
import { DpaeList } from 'components/Tables/DpaeList';
import React from 'react';
import { useParams } from 'react-router-dom';
import { StaticSelect } from 'components/selects/StaticSelect';
import { ContractorEstablishmentForm } from 'forms/Contractor/ContractorEstablishmentForm';
//import { queryClient } from '@commons';
import { UserSelect } from 'components/selects/UserSelect';
import { useShowMessage } from 'hooks';
import { queryClient } from '@commons';
type ContractorEstablishmentsProps = {
  contractor: IJoinedContractor;
  onChangeEstaSelected: (esta: any) => void;
};
const grid6 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '6',
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const ContractorEstablishments: React.FC<
  React.PropsWithChildren<ContractorEstablishmentsProps>
> = ({ contractor, onChangeEstaSelected }) => {
  const showMessage = useShowMessage();
  const { id } = useParams<{ id: string; tab: string }>();
  const [contactSelected, setContactSelected] = React.useState<false | string>(
    false
  );
  const [loadingContact, setLoadingContact] = React.useState(false);
  const estaLinked = contractor?.scopedData;
  const [estaSelected, setEstaSelected] = React.useState(
    estaLinked?.[0]?.establishment?.uuid
  );
  const scopedData =
    estaLinked?.[
      estaLinked.findIndex(esta => esta?.establishment?.uuid === estaSelected)
    ];
  const establishment = scopedData?.establishment;
  const isResponsible =
    establishment?.provider?.manager === localStorage.cognitoUserId;
  const isContact =
    scopedData?.contact?.cognitoUserId === localStorage.cognitoUserId;
  const handleChangeContractorContact = async () => {
    setLoadingContact(true);
    try {
      await contractorUpdateOne({
        uuid: contractor.uuid,
        scopedData: [
          {
            establishment: establishment.uuid,
            contact: contactSelected as string,
          },
        ],
        contractor: {},
      });
      showMessage(
        'success',
        "Le contact fournisseur de l'intervenant à bien été modifié"
      );
      queryClient.refetchQueries({ queryKey: [contractor.uuid] });
    } catch (e) {
      //
    }
    setLoadingContact(false);
  };
  React.useEffect(() => {
    onChangeEstaSelected(estaSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estaSelected]);
  return (
    <Box width={1 / 1}>
      {estaLinked?.length > 1 && (
        <Box width={570}>
          <StaticSelect
            onChange={value => setEstaSelected(value as string)}
            value={estaSelected}
            options={estaLinked?.map(data => {
              return {
                label: `${getTradeNameSafe(data?.establishment)} - ${
                  data?.establishment?.siret
                }`,
                key: data?.establishment?.uuid,
                value: data?.establishment?.uuid,
              };
            })}
          />
        </Box>
      )}
      <EstablishmentCard
        status={<></>}
        establishment={establishment}
        withAddress
        height={'auto'}
      />
      <Divider mb={20} mt={20} />
      <Text variant="h2">
        Informations de l’intervenant pour cet établissement
      </Text>
      <Box mt={40}>
        {scopedData && (
          <ContractorEstablishmentForm
            establishment={establishment}
            scopedData={scopedData}
            contractor={contractor}
            isDisabled={scopedData?.disabled}
          />
        )}
      </Box>
      <Divider mb={20} mt={20} />
      <Grid cols={12} gap={'20px'}>
        <Cell x-span={grid6}>
          <Text variant="h2" mb={20}>
            Contact fournisseur
          </Text>
          {(isResponsible || isContact) &&
            scopedData?.disabled !== true &&
            contractor.status === EContractorStatus.ACTIVE && (
              <Box width={570} mb={20}>
                <UserSelect
                  filter={{
                    roles: {
                      $elemMatch: {
                        name: {
                          $in: [
                            RolesTypes.PROVIDER_CONTACT,
                            RolesTypes.PROVIDER_RESPONSIBLE,
                          ],
                        },
                        resource: { $in: [establishment.uuid] },
                      },
                    },
                  }}
                  placeholder="Tous les contacts"
                  onChange={value => setContactSelected(value)}
                  value={undefined}
                />
              </Box>
            )}
          <UserCard user={scopedData?.contact} />
          {
            /*(isResponsible || isContact)*/ true &&
              contractor.status === EContractorStatus.ACTIVE && (
                <Button
                  mt={20}
                  variant="filled"
                  type="button"
                  onClick={() => handleChangeContractorContact()}
                  isDisabled={
                    !contactSelected ||
                    contactSelected === scopedData?.contact?.cognitoUserId
                  }
                  isLoading={loadingContact}
                >
                  Enregistrer
                </Button>
              )
          }
        </Cell>
        {!contractorNeedDpae(scopedData) ? (
          <></>
        ) : (
          <Cell x-span={grid6}>
            <Text variant="h2" mb={20}>
              DPAE Archivée
            </Text>
            <DpaeList
              filterObject={{
                status: EDpaeStatus.ARCHIVED,
                siren: establishment?.siren,
                contractor: id,
                attachment: { $exists: true },
              }}
            />
          </Cell>
        )}
      </Grid>
    </Box>
  );
};

ContractorEstablishments.displayName = 'ContractorEstablishments';

export default ContractorEstablishments;
