import { EBillingType } from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { CreateActivityForm } from 'forms/CreateActivityForm';
import React from 'react';
import { create } from 'react-modal-promise';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  billingType: EBillingType;
};

export const CreateActivityModal = ({
  onResolve,
  isOpen,
  layer,
  billingType,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={868}
      closeIcon
      layer={layer}
    >
      <CreateActivityForm
        billingType={billingType}
        onResolve={(value?: any) => onResolve(value)}
      />
    </ModalFrame>
  );
};

export const showCreateActivityModal = create<Props>(CreateActivityModal);
