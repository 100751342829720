import React, { ReactElement, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { boxProperties, BoxProps } from './Box';

type SubItemsProps = {
  isSelected?: boolean;
  variant?: 'primary' | 'secondary';
  href?: string; // Maybe to deprecate
  children?: ReactNode;
  icon?: ReactElement;
  disableArrow?: boolean | undefined;
} & BoxProps;

export const ArrowBottom = styled.div<BoxProps>`
  position: relative;
  margin-top: 30px;
  background-color: ${p => p.theme.colors.primary.default};
  right: 50%;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  ${boxProperties}
`;
export const StyledSubItem = styled.div<SubItemsProps>`
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  line-height: normal;
  font-size: 16px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 31px;
  border-radius: 5px;
  white-space: nowrap;
  ${props =>
    props.variant === 'primary' &&
    css`
      color: white;
      background-color: ${p => p.theme.colors.primary.default};
    `}
  ${props =>
    props.variant === 'secondary' &&
    css`
      color: ${p => p.theme.colors.primary.default};
      box-shadow: 0 -4px 0 0 ${p => p.theme.colors.primary.default} inset;
    `}
  ${props =>
    !props.isSelected &&
    css`
      background-color: ${p => lighten(0.6, p.theme.colors.primary.default)};
      color: ${p => lighten(0.3, p.theme.colors.primary.default)};
      border: none;
      &:hover {
        color: ${p => p.theme.colors.primary.default};
      }
    `}
    ${props =>
    props.isSelected &&
    !props.disableArrow &&
    css`
      padding: 0px 10px 0px 20px;
    `}

  

  cursor: pointer;

  ${boxProperties}

  svg {
    margin-right: 10px;
    opacity: 0.4;
  }
`;

export const SubItem = ({
  href,
  isSelected,
  children,
  icon,
  disableArrow,
  ...props
}: SubItemsProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const onNavigate = () => !!href && history.push(href);

  const routeIsSelected = pathname.includes(href || '');
  return (
    <StyledSubItem
      disableArrow={disableArrow}
      onClick={onNavigate}
      isSelected={href ? routeIsSelected : isSelected}
      {...props}
    >
      {icon}
      {children}
      {routeIsSelected && !disableArrow && <ArrowBottom />}
    </StyledSubItem>
  );
};

SubItem.defaultProps = {
  variant: 'primary',
};
