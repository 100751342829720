import { Container } from 'components/ui';
import { Route, Switch } from 'react-router-dom';
import { CreditNoteList } from 'routes/Invoices/CreditNote/List';

export const CreditNote = () => {
  return (
    <Switch>
      <Route path="/invoices/credit-note/:filter(validated|sent|paid)">
        <Container>
          <CreditNoteList />
        </Container>
      </Route>
    </Switch>
  );
};
