import { useContractorFindMany, useContractorFindOne } from '@commons';
import { Select } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import { ComponentPropsWithoutRef, useState } from 'react';

type ContractorSelectProps = {
  value?: string;
  onChange?: (id: string) => void;
  onChangeCompleteObject?: (object: any) => void;
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const ContractorSelect = ({
  value,
  onChange,
  ...props
}: ContractorSelectProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);

  /** Get selected user */
  const { data: selectedContractor } = useContractorFindOne(value);
  const selectedOption = selectedContractor && {
    label: selectedContractor.lastName + ' ' + selectedContractor.firstName,
    value: selectedContractor.uuid || '',
  };

  // get user list
  const { data: contractorsQuery, isFetching } = useContractorFindMany({
    filterObject: {
      $or: [
        { firstName: { $regex: debouncedFilter, $options: 'i' } },
        { lastName: { $regex: debouncedFilter, $options: 'i' } },
      ],
    },
    limit: 10,
    skip: 0,
    sort: 'firstName',
  });

  const contractorOptions =
    contractorsQuery?.contractors?.map(c => ({
      label: c.lastName + ' ' + c.firstName,
      value: c.uuid || '',
    })) || [];

  return (
    <Select
      options={contractorOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => setFilter(value)}
      onChange={(option: any) => onChange && onChange(option && option.value)}
      isClearable={true}
      value={selectedOption}
      {...props}
    />
  );
};
