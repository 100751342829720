import * as React from 'react';
import { BoxProps, boxProperties } from '../index';
import styled from 'styled-components';

const DividerH = styled.div`
  width: 80%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: ${p => p.theme.colors.primary.greyLight};
  ${boxProperties}
`;
type Props = {
  width?: string;
  marginLeft?: string;
};

export const Divider = ({ width, marginLeft, ...props }: BoxProps & Props) => {
  return (
    <DividerH
      {...props}
      style={{
        width: width ? width : '100%',
        marginLeft: marginLeft ? marginLeft : undefined,
      }}
    />
  );
};
Divider.displayName = 'ContentLayout';

export default Divider;
