/* eslint-disable react/display-name */
import { ECraStatus } from '@freelancelabs/teoreme-commons';
import { EditIcon, EyeIcon, DocIcon, InfoIconBlue } from 'components/icons';
import { ALink, Box, CheckBox, Flex, Link, Status, Text } from 'components/ui';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { cutLongText, MONTH_FR } from '@commons';
import React from 'react';
import { Theme } from 'styles/theme';
import { kebabCase } from 'lodash';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const IndeterminateCheckbox: React.FC<React.PropsWithChildren<any>> = ({
  indeterminate,
  checked,
  onChange,
  title,
  ...rest
}) => {
  return (
    <CheckBox
      isIndeterminate={indeterminate}
      checked={checked}
      aria-label={title}
      {...rest}
      ml={10}
      onClick={() => {
        const event = {
          target: {
            checked: !checked,
          },
        };
        onChange(event);
      }}
    />
  );
};

const TrimCell: React.FC<React.PropsWithChildren<{ value: string }>> = ({
  value,
}) => <div title={value}>{cutLongText(value, 20)}</div>;

export const columns = [
  // Let's make a column for selection
  {
    id: 'selection',
    disableSortBy: true,

    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <Flex>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </Flex>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }: any) => {
      // console.log(row);
      const state = row.original.state;
      return state === ECraStatus.TO_BE_SUBMITTED ? (
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      ) : null;
    },
  },
  {
    Header: 'N° CRA',
    accessor: 'refCra',
    Cell: (item: any) => {
      const refCra = item?.row?.original?.refCra;
      const state = item?.row?.original?.state;
      return (
        <Flex>
          <DocIcon width={25} height={25} fill={Theme.colors.primary.default} />
          <ALink
            mt={1}
            fontSize={14}
            href={`/invoices/cra/${kebabCase(state)}/${refCra}`}
          >
            {refCra}
          </ALink>
        </Flex>
      );
    },
  },
  {
    Header: 'Mois',
    accessor: 'month',
    Cell: (item: any) => {
      let month = item?.row?.original?.month;
      if (month) {
        const monthToDate = new Date(month);
        month = `${
          monthToDate.getUTCMonth() + 1
        } / ${monthToDate.getUTCFullYear()}`;
      }
      return <Box pl={15}>{month}</Box>;
    },
  },
  {
    Header: 'Client',
    accessor: 'estCustomer.businessName',
    Cell: ({ value }: any) => <TrimCell value={value} />,
  },
  {
    Header: 'Établissement',
    accessor: 'estProvider.businessName',
    Cell: ({ value }: any) => <TrimCell value={value} />,
  },
  {
    Header: 'Intervenant',
    accessor: 'contractor.firstName',
    Cell: ({
      value,
      row: {
        original: {
          contractor: { firstName },
        },
      },
    }: any) => <TrimCell value={`${value} ${firstName}`} />,
  },
  {
    Header: 'TJM HT',
    accessor: 'contractorRate',
    Cell: (item: any) => {
      const currency = item?.row?.original?.mission?.billing?.currency;
      return (
        <Text>{item?.row?.original?.contractorRate + ` ${currency}`}</Text>
      );
    },
  },
  {
    Header: 'Nb. de j',
    accessor: 'nbWorkingDays',
  },
  {
    Header: 'Statut',
    accessor: 'state',
    Cell: (item: any) => {
      const crastate = item?.row?.original?.state;
      const stateChangedAt = item?.row?.original?.stateChangedAt;
      let variantColor = 'grey';
      let text = '';
      switch (crastate) {
        case ECraStatus.TO_BE_FILLED:
          variantColor = 'grey';
          text = 'A saisir';
          break;
        case ECraStatus.TO_BE_SUBMITTED:
          variantColor = 'grey';
          text = 'A soumettre';
          break;
        case ECraStatus.TO_BE_VALIDATED:
          variantColor = 'warning';
          text = 'En attente de validation';
          break;
        case ECraStatus.VALIDATED:
          variantColor = 'success';
          text = 'Validé';
          break;
        case ECraStatus.ARCHIVED:
          variantColor = 'grey';
          text = 'Archivé';
          break;
        // case ECraStatus.PENDING:
        //   variantColor = 'grey';
        //   text = 'A venir';
        //   break;
        case ECraStatus.REJECTED:
          variantColor = 'error';
          text = `Refusé le ${
            stateChangedAt && stateChangedAt.toLocaleDateString()
          }`;
          break;
        default:
          variantColor = 'grey';
      }
      if (!item?.row?.original?.isOpen) {
        variantColor = 'grey';
        text = 'A venir';
      }
      return (
        <Flex>
          <Status
            //@ts-ignore
            variantColor={variantColor}
          >
            {text}
          </Status>
        </Flex>
      );
    },
  },
  {
    disableSortBy: true,
    Header: 'Facture',
    accessor: 'invoice',
    textAlign: 'left',
    Cell: (item: any) => {
      const invoice = item?.row?.original?.invoice;
      if (invoice) {
        return (
          <ALink href={`/invoices/time-spent/to-be-submitted/${invoice?.uuid}`}>
            <Link iconLeft={<EyeIcon />}>Voir la facture</Link>
          </ALink>
        );
      } else {
        return <></>;
      }
    },
  },
  {
    disableSortBy: true,
    Header: 'Actions',
    accessor: 'actions',
    textAlign: 'right',

    Cell: (item: any) => {
      const crastate = item?.row?.original?.state;
      const refCra = item?.row?.original?.refCra;
      const proof = item?.row?.original?.proof;
      const month = item?.row?.original?.month;
      const isOpen = item?.row?.original?.isOpen;
      if (
        !isOpen &&
        (crastate === ECraStatus?.TO_BE_FILLED ||
          crastate === ECraStatus?.TO_BE_SUBMITTED)
      ) {
        return (
          <Flex alignContent="center" justifyContent="center" mr={10}>
            <a data-for="main" data-tip="" data-iscapture="true">
              <InfoIconBlue fontSize={25} />
            </a>
            <ReactTooltip
              id="main"
              place={'top'}
              variant={'dark'}
              float={true}
              style={{
                backgroundColor: Theme.colors.primary.default,
              }}
            >
              <Text color="white">
                La saisie sera disponible à partir du dernier jours ouvrable du
                mois {new Date(month)?.getUTCMonth() === 3 ? "d'" : 'de '}
                {MONTH_FR[new Date(month)?.getUTCMonth()]}
              </Text>
            </ReactTooltip>
          </Flex>
        );
      }
      if (crastate === ECraStatus.TO_BE_FILLED) {
        return (
          <Flex justifyContent="center" mr={10}>
            <EditIcon
              width={25}
              height={25}
              color={Theme.colors.primary.default}
            />
            <ALink
              data-cy="invoice-cra-goForm"
              mt={1}
              fontSize={14}
              href={`/invoices/cra/to-be-submitted/${refCra}`}
            >
              <u>Saisir</u>
            </ALink>
          </Flex>
        );
      }
      if (
        crastate === ECraStatus.TO_BE_VALIDATED ||
        crastate === ECraStatus.VALIDATED
      ) {
        return (
          <Flex justifyContent="flex-end">
            {proof ? (
              <Link
                mr={20}
                iconLeft={<EyeIcon />}
                onClick={() =>
                  showDisplayPdfModal({
                    fileLocation: proof?.fileLocation || '',
                    fileName: proof?.fileName || '',
                  })
                }
              >
                Voir le justificatif
              </Link>
            ) : (
              <Text variant="span" mr={20}>
                Aucun justificatif
              </Text>
            )}
          </Flex>
        );
      }
      if (crastate === ECraStatus.ARCHIVED) {
        return (
          <Flex justifyContent="center">
            <EyeIcon
              width={25}
              height={25}
              fill={Theme.colors.primary.default}
            />
            <Link mt={1} fontSize={14}>
              Voir la facture
            </Link>
          </Flex>
        );
      }
      if (
        crastate === ECraStatus.REJECTED ||
        crastate === ECraStatus.TO_BE_SUBMITTED
      ) {
        return (
          <Flex justifyContent="center">
            <EditIcon
              width={25}
              height={25}
              color={Theme.colors.primary.default}
            />
            <ALink
              mt={1}
              fontSize={14}
              href={`/invoices/cra/to-be-submitted/${refCra}`}
            >
              <u>Editer</u>
            </ALink>
          </Flex>
        );
      }
      return <></>;
    },
  },
];

type Settings = {
  columnsHidden: Array<string>;
};

export const settingsByFilter: Record<ECraStatus, Settings> = {
  [ECraStatus.TO_BE_SUBMITTED]: {
    columnsHidden: ['refCra', 'invoice'],
  },
  [ECraStatus.TO_BE_VALIDATED]: {
    columnsHidden: ['selection', 'state', 'invoice'],
  },
  [ECraStatus.VALIDATED]: {
    columnsHidden: ['selection', 'state'],
  },
  [ECraStatus.ARCHIVED]: {
    columnsHidden: ['selection', 'state'],
  },
  [ECraStatus.TO_BE_FILLED]: {
    columnsHidden: ['selection', 'state'],
  },
  [ECraStatus.REJECTED]: {
    columnsHidden: ['selection', 'state'],
  },
};
