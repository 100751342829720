import {
  getServiceValues,
  establishmentFindRCPRO,
  getRcproAvalaibleDates,
  getInvoiceTotalAmount,
  isExpenseInvoice,
  isStandByDutyInvoice,
} from '@commons';
import {
  EAdditionalServiceStatus,
  EAdditionalServiceType,
  EBillingType,
  EInvoiceLineType,
  EPriceUnit,
  IInvoiceLine,
  IJoinedAdditionalActivity,
  IJoinedInvoice,
  RcProListResult,
  IFastCashService,
} from '@freelancelabs/teoreme-commons';
import { EuroIcon, PercentIcon } from 'components/icons';
import { showEditFastCashModal } from 'components/modals/EditFastCashModal';
import { showAddRCPROModal } from 'components/modals/AddRcProModal';
import {
  Box,
  Cell,
  Flex,
  FormControl,
  Grid,
  Input,
  Link,
  Row,
  Text,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { isWithinInterval } from 'date-fns';
import React from 'react';
import { queryClient } from '@commons';
import { useTheme } from 'styled-components';

type FormValues = {
  invoiceLines: IInvoiceLine;
};
type AdditionalServicesFormProps = {
  form: FormValues & any;
  isEditable: boolean;
  invoice: IJoinedInvoice;
  activity?: IJoinedAdditionalActivity;
};
const grid50 = {
  xs: '6',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};
export const AdditionalServicesForm = ({
  form: {
    formState: { errors, isDirty },
    watch,
  },
  isEditable,
  invoice,
  activity,
}: AdditionalServicesFormProps) => {
  const invoiceLines = invoice?.invoiceLines || []; //watch('invoiceLines');
  const theme = useTheme();
  const [RCPRO, setRCPRO] = React.useState<RcProListResult[]>([]);
  const [rcProChecked, setRcProChecked] = React.useState(false);
  const findEstablishmentRCPO = async () => {
    try {
      const rcPro = await establishmentFindRCPRO({
        uuid: invoice?.estProvider?.uuid,
        filterObject: {
          status: {
            $in: [
              EAdditionalServiceStatus.ACTIVE,
              EAdditionalServiceStatus.PENDING,
            ],
          },
        },
      });
      setRCPRO(rcPro?.results);
      setRcProChecked(true);
    } catch (e) {
      //
    }
  };
  // RCPRO ACTIVE CURRENT MONTH -1

  React.useEffect(() => {
    if (invoice && !rcProChecked) {
      findEstablishmentRCPO();
    }
  }, [invoice, rcProChecked]);
  const isFlatRateMission =
    invoice?.mission?.billing?.type === EBillingType?.FLAT_RATE;
  const items =
    RCPRO?.map(u => ({
      key: u.uuid || String(new Date().getTime()),
      ...u,
      isWithinInterval: !isFlatRateMission
        ? isWithinInterval(u?.validityStart, {
            start: new Date(invoice?.month as Date),
            end: new Date(invoice?.mission?.endAt as Date),
          })
        : undefined,
    })) || [];
  //@ts-ignore
  const rcproRangeAvalaibleDates = !isFlatRateMission
    ? //@ts-ignore
      getRcproAvalaibleDates(invoice, items)
    : false;
  const rcProAvalaible =
    rcproRangeAvalaibleDates && !rcproRangeAvalaibleDates?.isAfter
      ? true
      : false;
  const FCAvalaible =
    (
      invoice?.mission?.additionalServices?.find(
        as =>
          as?.type === EAdditionalServiceType.FAST_CASH &&
          as?.status === EAdditionalServiceStatus.ACTIVE
      ) as IFastCashService
    )?.catalog?.length > 0;
  return (
    <Grid cols={12} gap="40px">
      <Cell x-span={{ xs: '12' }}>
        <Text variant="h2">Services complémentaires et autres frais</Text>
      </Cell>
      {isEditable &&
        FCAvalaible &&
        invoiceLines.findIndex(
          (il: any) => il.type === EAdditionalServiceType.FAST_CASH
        ) === -1 && (
          <Cell x-span={{ xs: '12' }}>
            <Link
              iconLeft={
                <AddIcon
                  style={{
                    marginTop: '2px',
                    fill: theme.colors.primary.default,
                  }}
                />
              }
              onClick={() =>
                showEditFastCashModal({
                  invoice: invoice,
                }).then(action =>
                  queryClient.refetchQueries({ queryKey: [invoice?.uuid] })
                )
              }
            >
              <Text color={theme.colors.primary.default}>
                <Flex alignContent="center" justifyContent="center" mr={10}>
                  Activer le fast Cash
                </Flex>
              </Text>
            </Link>
          </Cell>
        )}
      {isEditable && rcProAvalaible && (
        <>
          {items?.find(
            rcP => rcP.refMission === invoice?.mission?.reference
          ) ? (
            <></>
          ) : (
            <Cell x-span={{ xs: '12' }}>
              <Link
                iconLeft={
                  <AddIcon
                    style={{
                      marginTop: '2px',
                      fill: theme.colors.primary.default,
                    }}
                  />
                }
                onClick={() =>
                  showAddRCPROModal({
                    uuid: invoice?.estProvider?.uuid as string,
                    providerContactsIds: [
                      invoice?.mission?.provider?.contact as string,
                    ],
                    invoice: invoice,
                    from: 'INVOICE',
                    rcproRangeAvalaibleDates: rcproRangeAvalaibleDates,
                  }).then(action => {
                    queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
                    setRcProChecked(false);
                  })
                }
              >
                <Text color={theme.colors.primary.default}>
                  <Flex alignContent="center" justifyContent="center" mr={10}>
                    Ajouter une responsabilité Civile Pro
                  </Flex>
                </Text>
              </Link>
            </Cell>
          )}
          {/* {items.findIndex(
            rc => rc?.status === 'ACTIVE' || rc.status === 'PENDING'
          ) > -1 ? (
            <Cell x-span={{ xs: '12' }}>
              {items?.find(
                rcP => rcP?.refMission === invoice?.mission?.reference
              ) ? (
                <></>
              ) : (
                <>
                  <Link
                    isDisabled
                    iconLeft={
                      <AddIcon
                        style={{
                          marginTop: '2px',
                          fill: theme.colors.grey.default,
                          textDecoration: 'none',
                        }}
                      />
                    }
                  >
                    <Text color={theme.colors.grey.default}>
                      <Flex
                        alignContent="center"
                        justifyContent="center"
                        mr={10}
                      >
                        Ajouter une responsabilité Civile Pro
                      </Flex>
                    </Text>
                  </Link>
                  <Text ml={25}>
                    Une responsabilité Civile Pro est déja active pour
                    l'entreprise {getTradeNameSafe(invoice?.estProvider)} (
                    siren : {invoice?.estProvider?.siren} )
                  </Text>
                </>
              )}
            </Cell>
          ) : (
            true && (
              <Cell x-span={{ xs: '12' }}>
                <Link
                  iconLeft={
                    <AddIcon
                      style={{
                        marginTop: '2px',
                        fill: theme.colors.primary.default,
                      }}
                    />
                  }
                  onClick={() =>
                    showAddRCPROModal({
                      siren: invoice?.estProvider?.siren as string,
                      providerContactsIds: [
                        invoice?.mission?.provider?.contact as string,
                      ],
                      invoice: invoice,
                      from: 'INVOICE',
                    }).then(action => {
                       queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
                      setRcProChecked(false);
                    })
                  }
                >
                  <Text color={theme.colors.primary.default}>
                    <Flex alignContent="center" justifyContent="center" mr={10}>
                      Ajouter une responsabilité Civile Pro
                    </Flex>
                  </Text>
                </Link>
              </Cell>
            )
          )} */}
        </>
      )}
      {Array.isArray(invoiceLines) &&
        invoiceLines
          .sort((a, b) =>
            a.type === EAdditionalServiceType.FAST_CASH
              ? -1
              : a.type === EAdditionalServiceType.HANDLING_FEE ||
                  a.type === EAdditionalServiceType.MISC
                ? 1
                : -1
          )
          .map(line => {
            const nbWorkingDays = watch('nbWorkingDays');
            //const totalAmountHT = nbWorkingDays * contractorRate;
            const totalAmountHT = getInvoiceTotalAmount(
              invoice as any,
              'PROVIDER',
              activity
            );
            const missionService =
              invoice?.mission?.additionalServices?.[
                invoice?.mission?.additionalServices?.findIndex(
                  ms => ms.uuid === line.uuid
                )
              ];

            const values = getServiceValues(
              totalAmountHT as number,
              nbWorkingDays,
              line,
              invoice
            );
            return (
              <>
                <Cell x-span={grid50}>
                  <Text variant="b">
                    {line?.type === EAdditionalServiceType.FAST_CASH ? (
                      <>
                        {values.labelType.replace(
                          '{deadline}',
                          line?.metadata?.deadline
                        )}
                        {isEditable &&
                          !isExpenseInvoice(invoice?.invoiceType) &&
                          !isStandByDutyInvoice(invoice?.invoiceType) &&
                          missionService?.paidByProvider && (
                            <Link
                              color={theme.colors.primary.default}
                              onClick={() =>
                                showEditFastCashModal({
                                  invoice: invoice,
                                  invoiceLine: line,
                                }).then(action =>
                                  queryClient.refetchQueries({
                                    queryKey: [invoice?.uuid],
                                  })
                                )
                              }
                              ml={'5px'}
                            >
                              <Flex>Modifier</Flex>
                            </Link>
                          )}
                      </>
                    ) : line?.type === EAdditionalServiceType.MISC ? (
                      <Box>
                        <Box>{values.labelType}</Box>
                        <Box>
                          <Text fontSize={14} color="grey !important">
                            {line.description}
                          </Text>
                        </Box>
                      </Box>
                    ) : (
                      <> {values.labelType}</>
                    )}
                  </Text>
                </Cell>
                <Cell x-span={grid50}>
                  <Row spacing={0}>
                    {line?.type === EInvoiceLineType.FREE_DAYS && (
                      <FormControl label="Nombre de jours">
                        <Input
                          isDisabled
                          isFullWidth
                          pr={20}
                          value={line.amount}
                          icon={
                            line.unit === EPriceUnit.PERCENT_INVOICE ? (
                              <PercentIcon style={{ marginRight: 5 }} />
                            ) : (
                              <EuroIcon style={{ marginRight: 5 }} />
                            )
                          }
                        />
                      </FormControl>
                    )}
                    {line?.type !== EAdditionalServiceType.MISC && (
                      <FormControl label={values.labelUnit}>
                        <Input
                          isDisabled
                          isFullWidth
                          pr={20}
                          value={line.price}
                          icon={
                            line.unit === EPriceUnit.PERCENT_INVOICE ? (
                              <PercentIcon style={{ marginRight: 5 }} />
                            ) : (
                              <EuroIcon style={{ marginRight: 5 }} />
                            )
                          }
                        />
                      </FormControl>
                    )}
                    <FormControl label="Montant total HT">
                      <Input
                        isDisabled
                        isFullWidth
                        pr={20}
                        value={`${values.price >= 0 ? '-' : ''}${values.price}`}
                        icon={<EuroIcon style={{ marginRight: 5 }} />}
                      />
                    </FormControl>
                  </Row>
                </Cell>
              </>
            );
          })}
      {!invoiceLines ||
        (invoiceLines.length === 0 && (
          <Cell x-span={grid50}>Aucun service complémentaire</Cell>
        ))}
    </Grid>
  );
};
