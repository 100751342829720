import React, { ReactNode, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Box, boxProperties, BoxProps } from './Box';
import { FormInfo, FormLabel } from './Form';

const FileInputStyled = styled.div<
  { variant: 'area' | 'link'; error?: boolean } & BoxProps
>`
  ${p =>
    p.variant === 'link' &&
    css`
      display: inline-block;
    `}

  ${p =>
    p.variant === 'area' &&
    css`
      display: flex;
      border-radius: 8px;
      width: 100%;
      border: 1px dashed black;
      align-items: center;
      justify-content: center;
      background-color: ${p.theme.colors.primary.lighter};
      height: 50px;
      ${p.error ? `border-color: ${p.theme.danger};` : ''}
    `}

  cursor : pointer;
  ${boxProperties}
`;

type FileInputProps = {
  label?: string;
  subLabel?: string;
  onSelect?: (files: File[]) => void;
  style?: React.CSSProperties;
  isLoading?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
  accept?: string;
  maxSize?: number;
  multiple?: boolean;
  required?: boolean;
  errorMessage?: string;
  variant?: 'area' | 'link';
} & BoxProps;
export const FileInput = ({
  variant = 'area',
  onSelect,
  children,
  label,
  subLabel,
  accept,
  maxSize,
  multiple = false,
  required = false,
  isDisabled,
  errorMessage,
  ...props
}: FileInputProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const theme = useTheme();
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    // @ts-ignore
    const files = [...e.currentTarget.files];
    if (files?.length === 0) {
      return;
    }
    // @ts-ignore
    if (files?.[0]?.type !== 'application/pdf') {
      setError('Veuillez fournir un document pdf');
    } else {
      if (maxSize && files.find(file => file.size > maxSize)) {
        setError('Vous avez dépassé la taille maximale autorisée');
        return;
      }
      // @ts-ignore
      return onSelect?.([...e.currentTarget.files]);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      {...props}
      alignItems={'flex-start'}
    >
      {label && (
        <FormLabel>
          {label}&nbsp;
          {required && (
            <span style={{ color: theme.colors.primary.default }}>*</span>
          )}
        </FormLabel>
      )}
      {subLabel && (
        <FormLabel style={{ fontWeight: 300 }}>{subLabel}</FormLabel>
      )}

      <FileInputStyled
        variant={variant}
        onClick={() => input?.current?.click()}
        error={!!error || !!errorMessage}
      >
        <input
          disabled={isDisabled}
          style={{ display: 'none' }}
          type="file"
          onChange={handleSelect}
          accept={accept}
          multiple={multiple}
          ref={input}
        />
        {children}
      </FileInputStyled>
      {error && <FormInfo variantColor="error">{error}</FormInfo>}
      {errorMessage && <FormInfo variantColor="error">{errorMessage}</FormInfo>}
    </Box>
  );
};
