import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CRAList } from 'routes/Invoices/CRA/List';
import { EditCRA } from 'routes/Invoices/CRA/Details/EditCRA';
import { Container } from 'components/ui';
export const CRA = () => {
  return (
    <Switch>
      <Route path="/invoices/cra/:filter(to-be-submitted|to-be-validated|validated|archived)/:id">
        <Container>
          <EditCRA />
        </Container>
      </Route>
      <Route path="/invoices/cra/:filter(to-be-submitted|to-be-validated|validated|archived)">
        <Container>
          <CRAList />
        </Container>
      </Route>
    </Switch>
  );
};
