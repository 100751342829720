import { useShowMessage } from 'hooks';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFetchData } from './api';
import { useContractListStore } from 'store/contracts';
import { columns } from './TableConfig';
type LogicProps = {
  filterObject: any;
};
export const useLogic = ({ filterObject }: LogicProps) => {
  const theme = useTheme();
  const t = useTranslationFormat();
  const { updateContractListStore, pageIndex } = useContractListStore();

  const showMessage = useShowMessage();

  const history = useHistory();

  // React.useEffect(() => {
  //   updateContractListStore({ filterObject });
  // }, [filterObject]);

  const [fetchInfo, setFetchInfo] = React.useState({
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'state', desc: true }],
    //filterObject,
  });

  const { data, isFetching, status } = useFetchData(fetchInfo);

  // const selectableInvoice = data.invoices
  //   .filter(({ status }) => status === EInvoiceStatus.TO_BE_SUBMITTED)
  //   .map(({ uuid }) => uuid);

  // const isSelectAll = selectableInvoice.length ? undefined : 'selection';

  return {
    t,
    updateContractListStore,
    theme,

    columnsHidden: [],
    columns,
    isFetching,
    data,
    status,
    setFetchInfo,
    showMessage,
    pageIndex,
    history,
  };
};
