import { NotFoundIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Box, Flex, Link, Text, Button } from 'components/ui';
import * as React from 'react';
import { useTheme } from 'styled-components';
import { refreshCustomerToken } from '@commons';
import { useShowMessage } from 'hooks';
type PropsTypes = {
  customText?: string;
  token?: string;
};
export const CustomerAccesDenied = ({ customText, token }: PropsTypes) => {
  const Theme = useTheme();
  const showMessage = useShowMessage();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasRefresh, sethHasRefresh] = React.useState(false);
  const onRefreshToken = async () => {
    setIsLoading(true);
    try {
      await refreshCustomerToken({ token: token }, true);
      showMessage('success', 'Un nouveau lien à été envoyé par email');
      sethHasRefresh(true);
      setIsLoading(false);
    } catch (e) {
      showMessage('success', 'Un nouveau lien à été envoyé par email');
      sethHasRefresh(true);
      setIsLoading(false);
    }
  };
  return (
    <Flex
      top={0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Box ml={'320px'}>
          <Text variant="h1" color={Theme.colors.primary.default}>
            Oups !
          </Text>
          <Text variant="p">
            {customText ? customText : 'Le lien vers cette page à éxpiré'}.
          </Text>
          <Text variant="p">
            Vous avez besoin d’aide ?{' '}
            <Link onClick={() => showContactUsModal()}>Contactez-nous</Link>
          </Text>
          {!hasRefresh && (
            <Box mt={20}>
              <Button isLoading={isLoading} onClick={onRefreshToken}>
                Renvoyer un nouveau lien
              </Button>
            </Box>
          )}
        </Box>
      </Flex>
      <NotFoundIcon width={703} height={647} />
    </Flex>
  );
};
export default CustomerAccesDenied;
