import { useCraUpdateOne } from '@commons';
import { useShowMessage } from 'hooks';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFetchData } from './api';
import { getHiddenColumns } from './helpers';
import { useContractorListStore } from 'store/contractors';
import { columns } from './TableConfig';
export const useLogic = () => {
  const theme = useTheme();
  const t = useTranslationFormat();
  const { filter } = useParams<{ filter: string }>();

  useSetCrumbs([filter], [{ label: 'Intervenants', path: '/contractors' }]);

  const {
    updateContractorListStore,
    selectedStatus,
    searchQuery,
    pageIndex,
    filterDPAE,
  } = useContractorListStore();

  const showMessage = useShowMessage();
  const { mutateAsync: updateCra } = useCraUpdateOne();
  const windowSize = useWindowSize();

  const history = useHistory();

  const [fetchInfo, setFetchInfo] = React.useState({
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'firstName', desc: true }],
  });

  const { data, isFetching, status } = useFetchData(fetchInfo);

  return {
    t,
    updateContractorListStore,
    theme,
    selectedStatus,
    columnsHidden: [
      //@ts-ignore
      ...getHiddenColumns(windowSize.width),
    ],
    columns,
    isFetching,
    data,
    status,
    setFetchInfo,
    updateCra,
    showMessage,
    searchQuery,
    pageIndex,
    history,
    filterDPAE,
  };
};
