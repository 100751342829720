import { useAmplifyLogout } from '@commons';
import { InfoModal } from 'components/icons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { Box, Button, Flex, Text } from 'components/ui';
import { create } from 'react-modal-promise';
import { useTheme } from 'styled-components';

type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
};

export const LinkedEstablishmentModal = ({
  onResolve,
  isOpen,
  layer,
}: Props) => {
  const theme = useTheme();
  const { status } = useAmplifyLogout();
  // const history = useHistory();
  // const onLogout = async () => {
  //   try {
  //     await logout();
  //     history.push('/login');
  //   } catch (e) {}
  // };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
      layer={layer}
    >
      <Box>
        <Flex mt={20} justifyContent="center" width={1 / 1}>
          <Box>
            <InfoModal width="255px" height="166px" />
          </Box>
        </Flex>
        <Box textAlign="center" mt={20}>
          <Text mb={10} variant="h2" color={theme.colors.text.secondary}>
            Votre demande de rattachement a bien été envoyée !
          </Text>
          <Text variant="p">
            Nous vous préviendrons dès que vous aurez été rattaché à votre
            établissement.
          </Text>
        </Box>
        <Flex mt={20} justifyContent="center" width={1 / 1}>
          <Box width={1 / 1}>
            <Button
              width={1 / 1}
              onClick={() => onResolve(true)}
              isLoading={status === 'pending'}
            >
              {/* Passer à l'étape suivante */}
              Suivant
            </Button>
          </Box>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showLinkedEstablishmentModal = create<Props>(
  LinkedEstablishmentModal
);
