import { getHumanDate, getFirstDayOfMonth } from '@commons';
import {
  EBillingType,
  EMissionStatus,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
import {
  Grid,
  Cell,
  Text,
  Button,
  Flex,
  FormControl,
  MissionSelectControlled,
  DatePickerControlled,
} from 'components/ui';
import { subMonths } from 'date-fns';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
const grid6 = {
  xs: '12',
  sm: '12',
  md: '6',
  lg: '6',
  xl: '6',
};
type FormValues = {
  mission?: string;
  month?: Date | null;
};
type CreateActivityFormProps = {
  onResolve: (value?: any) => void;
  defaultValues?: FormValues;
  //onSubmit?: (formValues: FormValues) => any | void;
  billingType: EBillingType;
};

export const CreateActivityForm = ({
  onResolve,
  billingType,
}: CreateActivityFormProps) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [missionComplet, setMissionComplet] = useState<
    IJoinedMission | undefined
  >();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({});

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    if (billingType === EBillingType.DAY) {
      history.push(
        `/invoices/activities/${missionComplet?.reference}/${getHumanDate(
          monthSelected as Date,
          'MM-dd-yyyy'
        )}`,
        {
          defaultOpenAccordion: 'STAND_BY_DUTY',
        }
      );
      onResolve();
    }
    if (billingType === EBillingType.FLAT_RATE) {
      history.push(
        `/invoices/flat-rate/activities/${missionComplet?.reference}`
      );
      onResolve();
    }

    setLoading(false);
  };
  const monthSelected = watch('month');
  const minDate = missionComplet?.startAt || new Date();
  const selectMinDate = getFirstDayOfMonth(minDate);
  const selectMaxDate =
    new Date() > new Date(missionComplet?.endAt as Date)
      ? missionComplet?.endAt
      : subMonths(new Date(), 1);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text variant="h1">Déclarer une activité</Text>
      {/* <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} /> */}
      <Grid cols={12} gap="10px">
        <Cell x-span={grid12} mt={20}>
          <FormControl
            label="Séléctionner une mission"
            errorMessage={errors?.mission?.message}
          >
            <MissionSelectControlled
              control={control}
              name="mission"
              filter={{
                'billing.type': billingType,
                $or: [
                  {
                    status: EMissionStatus.VALIDATED,
                  },
                  { status: EMissionStatus.TERMINATED },
                ],
                status: EMissionStatus.VALIDATED,
              }}
              onChangeCompleteObject={(data: any) => {
                setValue('month', null);
                setMissionComplet(data);
              }}
              width="100%"
              referenceValue="uuid"
              placeholder="Rechercher..."
              noOptionsMessage={() =>
                'Aucune mission disponible pour le moment. Vous serez avertis par mail lorsque lorsque nous aurons reçu le bon de commande client et validé la prestation.'
              }
              rules={{ required: 'Ce champs est requis' }}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid6}>
          {missionComplet && billingType === EBillingType.DAY && (
            <FormControl
              label="Séléctionner la période"
              errorMessage={errors?.month?.message}
            >
              <DatePickerControlled
                control={control}
                name="month"
                minDate={selectMinDate}
                maxDate={selectMaxDate}
                valueName="selected"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </FormControl>
          )}
        </Cell>
        <Cell x-span={grid12}>
          <Flex justifyContent="flex-end">
            <Button
              isDisabled={
                billingType === EBillingType.DAY
                  ? !monthSelected || !missionComplet
                  : !missionComplet
              }
              type="submit"
            >
              Suivant
            </Button>
          </Flex>
        </Cell>
      </Grid>
    </form>
  );
};
