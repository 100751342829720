import {
  updateOnBoardingStep,
  formatAllObject,
  validateFormPhoneFormatFR,
} from '@commons';
import { showSaveLaterModal } from 'components/modals/SaveLaterModal';
import { showDialogModal } from 'components/modals/DialogModal';
import {
  Box,
  Button,
  Cell,
  Divider,
  Flex,
  FormControl,
  Grid,
  Input,
  Radio,
  Text,
  PhoneInputControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import IconModal from './IconModal';

type FormValues = {
  civility: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
};

type OnBoardingStepOneProps = {
  onSubmit?: () => void;
  onBoardingData: any;
  refreshStep: () => void;
};

const grid = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};

export const OnBoardingStepOne = ({
  refreshStep,
  onBoardingData,
}: OnBoardingStepOneProps) => {
  const [loading, setLoading] = useState(false);
  const [isSaveLater, setIsSaveLater] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    control,
    setError,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      //@ts-ignore
      civility: onBoardingData?.user?.civility
        ? String(onBoardingData?.user?.civility)
        : 0,
      firstName: onBoardingData?.user?.firstName,
      lastName: onBoardingData?.user?.lastName,
      jobTitle: onBoardingData?.user?.jobTitle,
      phone: onBoardingData?.user?.phone,
    },
  });

  const civility = watch('civility');
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const jobTitle = watch('jobTitle');
  const phone = watch('phone');
  const onSubmit = async (formValues: FormValues) => {
    setIsSaveLater(false);
    const submitData = {
      saveLater: false,
      data: {
        user: {
          civility: Number(formValues.civility),
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          jobTitle: formValues.jobTitle,
          phone: formValues.phone,
        },
      },
    };
    try {
      let onError = false;
      // HOTFIX
      if (phone === undefined /*|| phone.length !== 11*/) {
        setError('phone', { message: 'Ce champ est obligatoire' });
        onError = true;
      } else {
        setError('phone', { message: undefined });
      }
      if (jobTitle.trim() === '') {
        setError('jobTitle', { message: 'Ce champ est obligatoire' });
        onError = true;
      } else {
        setError('jobTitle', { message: undefined });
      }
      if (onError === false) {
        await showDialogModal({
          title: 'Êtes-vous sûr(e) de vouloir valider ce formulaire ?',
          text: '',
          confirmLabel: 'Valider',
          cancelLabel: 'Annuler',
        }).then(async action => {
          if (action) {
            try {
              setLoading(true);
              submitData.data.user = formatAllObject(submitData.data.user);
              await updateOnBoardingStep(submitData);
              await refreshStep();
            } catch (e) {
              //
            }
          }
        });
      }
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const onSubmitLater = async () => {
    setLoading(true);
    setIsSaveLater(true);
    try {
      const submitData = {
        saveLater: true,
        data: {
          user: {
            civility: Number(civility),
            firstName,
            lastName,
            jobTitle: jobTitle.trim(),
            phone,
          },
        },
      };
      submitData.data.user = formatAllObject(submitData.data.user);

      await updateOnBoardingStep(submitData);
      showSaveLaterModal().then(async value => {
        if (value === false) {
          await refreshStep();
        }
      });
    } catch (e) {}

    setLoading(false);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: 'calc(100vh - 200px)', overflow: 'scroll' }}
    >
      <Box paddingLeft="10%" paddingRight="10%" mt={60}>
        <Flex mt={20} justifyContent="flex-end" width={1 / 1}>
          <IconModal />
        </Flex>
        <Box>
          <Text mb={13} variant="h1">
            Faisons connaissance
          </Text>
          <Text variant="p">
            Afin d’accéder à votre espace personnel, merci de vérifier
            l’exactitude des informations suivantes ou de les compléter le cas
            échéant.
          </Text>
        </Box>

        <Box mt={60}>
          <FormControl
            required
            label={'Civilité'}
            errorMessage={errors?.civility?.message}
          >
            <>
              <Radio
                {...register('civility', {
                  required: 'Ce champ est obligatoire',
                })}
                value="1"
              >
                Monsieur
              </Radio>
              <Radio
                {...register('civility', {
                  required: 'Ce champ est obligatoire',
                })}
                value="2"
              >
                Madame
              </Radio>
            </>
          </FormControl>
        </Box>
        <Box>
          <Grid cols={12}>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Prénom"
                errorMessage={errors?.firstName?.message}
              >
                <Input
                  isFullWidth
                  pr={20}
                  {...register('firstName', {
                    required: 'Ce champ est obligatoire',
                  })}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Nom"
                errorMessage={errors?.lastName?.message}
              >
                <Input
                  isFullWidth
                  pr={20}
                  {...register('lastName', {
                    required: 'Ce champ est obligatoire',
                  })}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Fonction"
                errorMessage={errors?.jobTitle?.message}
              >
                <Input
                  isFullWidth
                  pr={20}
                  {...register('jobTitle', {
                    required: 'Ce champ est obligatoire',
                  })}
                />
              </FormControl>
            </Cell>
            <Cell x-span={grid}>
              <FormControl
                required
                label="Téléphone"
                errorMessage={errors?.phone?.message}
              >
                <PhoneInputControlled
                  pr={20}
                  control={control}
                  isFullWidth
                  name="phone"
                  rules={{
                    validate: (value: string) => {
                      return validateFormPhoneFormatFR(value);
                    },
                  }}
                />
              </FormControl>
            </Cell>
          </Grid>
        </Box>
      </Box>
      <Box width={'100%'} position="fixed" bottom={0} height={100}>
        <Divider />
        <Flex alignItems="center" justifyContent="flex-end" pr={'10%'}>
          <Button
            variant={'ghost'}
            type="button"
            isLoading={loading && isSaveLater === true ? true : false}
            isDisabled={loading}
            onClick={() => onSubmitLater()}
          >
            Enregistrer et finir plus tard
          </Button>
          <Button
            variant={'filled'}
            ml={20}
            type="submit"
            isDisabled={loading || !isValid}
            isLoading={loading && !isSaveLater}
          >
            Valider
          </Button>
        </Flex>
      </Box>
    </form>
  );
};

export default OnBoardingStepOne;
