import {
  checkEmail,
  contractorUpdateOne,
  CONTRACTOR_SOCIAL_STATUS,
  CONTRACTOR_SOCIAL_STATUS_SELECT,
  dpaeCreateOne,
  dpaeUpdateOne,
  uploadFile,
  validateFormPhoneFormatFR,
} from '@commons';
import {
  EContractorSocialStatus,
  EContractorStatus,
  EDpaeStatus,
  EFileType,
  IContractorUpdateParams,
  IEstablishment,
  IFile,
  IJoinedContractor,
  IJoinedContractorScopedData,
  IMinifiedUser,
} from '@freelancelabs/teoreme-commons';
import { DownloadIcon, InfoIconBlue } from 'components/icons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  Button,
  Cell,
  FileInput,
  Flex,
  FormControl,
  Grid,
  Input,
  Link,
  Status,
  Text,
  PhoneInputControlled,
  StaticSelectControlled,
} from 'components/ui';
import { TextInformation } from 'components/TextInformation';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Theme } from 'styles';
type FormValues = IMinifiedUser &
  IEstablishment &
  IJoinedContractorScopedData & {
    dpae?: any;
  };

type ContractorEstablishmentFormProps = {
  contractor: IJoinedContractor;
  scopedData: IJoinedContractorScopedData;
  establishment: IEstablishment;
  isDisabled?: boolean;
};

const grid4 = {
  xs: '4',
  sm: '4',
  md: '4',
  lg: '4',
  xl: '4',
};

export const ContractorEstablishmentForm = ({
  contractor,
  scopedData,
  establishment,
  isDisabled,
}: ContractorEstablishmentFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    setValue,
    control,
    watch,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { ...scopedData },
  });
  // const isResponsible =
  //   establishment?.provider?.manager === localStorage.cognitoUserId;
  // const isContact =
  //   scopedData?.contact?.cognitoUserId === localStorage.cognitoUserId;
  // ALL ROLE HAVE PERMISSION TO UPDATE !!
  const isEditable = /*(isResponsible || isContact) &&*/ !isDisabled;
  const [dpaeFile, setDpaeFile] = useState<File | undefined>();
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      let onError = false;
      const submitData: IContractorUpdateParams = {
        uuid: contractor?.uuid,
        scopedData: [
          {
            jobTitle: formValues?.jobTitle,
            phone: formValues?.phone,
            email: formValues?.email,
            socialStatus: formValues?.socialStatus,
            establishment: scopedData?.establishment?.uuid,
          },
        ],
      };

      if (
        socialStatus !== EContractorSocialStatus.SUBCONTRACTOR &&
        socialStatus !== EContractorSocialStatus.COMPANY_MANAGER &&
        socialStatus !== EContractorSocialStatus?.MAJORITY_MANAGER
      ) {
        if (dpaeFile) {
          const uploadResponse = await uploadFile({
            file: dpaeFile,
            actionType: 'upload',
            fileType: EFileType.DPAE_FILE,
            establishment: scopedData?.establishment?.siren as string,
            contractor: contractor?.uuid,
          });
          const attachment = {
            fileLocation: uploadResponse?.fileLocation,
            fileName: uploadResponse?.fileName,
            eTag: uploadResponse?.eTag,
          };
          if (contractor.status !== EContractorStatus.ACTIVE) {
            submitData.contractor = {
              tmpData: {
                //@ts-ignore
                dpaeAttachment: attachment,
              },
            };
          }
          if (
            contractor.status === EContractorStatus.ACTIVE &&
            scopedData?.dpae?.uuid &&
            scopedData?.dpae.status !== EDpaeStatus.ACTIVE
          ) {
            await dpaeUpdateOne({
              uuid: scopedData?.dpae?.uuid,
              dpae: {
                attachment: attachment as IFile,
              },
            });
          } else {
            if (contractor.status === EContractorStatus.ACTIVE) {
              await dpaeCreateOne({
                dpae: {
                  attachment: attachment as IFile,
                  contractor: contractor?.uuid,
                  establishment: scopedData?.establishment?.uuid,
                },
              });
            }

            //submitData.scopedData[0].dpae = dpae;
          }
        }
        if (
          !dpae?.attachment &&
          !dpaeFile &&
          contractor.status === EContractorStatus.ACTIVE
        ) {
          onError = true;
          setError('dpae', { message: 'Ce champ est requis' });
        }
      }

      if (onError === false) {
        //@ts-ignore
        await contractorUpdateOne(submitData);
        queryClient.refetchQueries({ queryKey: [contractor?.uuid] });
      }
    } catch (err) {}
    setLoading(false);
  };

  const handleChangeDpae = (files: File[]) => {
    setDpaeFile(files[0]);
    clearErrors('dpae');
  };
  const socialStatus = watch('socialStatus');
  const dpae = watch('dpae');
  React.useEffect(() => {
    if (scopedData) {
      //@ts-ignore
      setValue('jobTitle', scopedData?.jobTitle);
      setValue('phone', scopedData?.phone);
      setValue('email', scopedData?.email);
      setValue('socialStatus', scopedData?.socialStatus);
      setValue('dpae', scopedData?.dpae);
      setDpaeFile(undefined);
    }
  }, [scopedData]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid cols={12} gap="30px">
        <Cell x-span={{ xs: '12' }}>
          {scopedData?.dpae?.status === EDpaeStatus.REJECTED &&
            scopedData?.dpae?.rejectReason && (
              <TextInformation variant="error" width={1 / 1} mb={60}>
                {`La DPAE a été refusé le ${new Date(
                  scopedData?.dpae?.statusChangedAt as Date
                )?.toLocaleDateString()} pour la raison suivante : ${
                  scopedData?.dpae?.rejectReason
                }`}
              </TextInformation>
            )}
        </Cell>
        <Cell x-span={grid4}>
          <FormControl label={"Fonction liée à l'établissement"}>
            <Input
              isDisabled={!isEditable}
              isFullWidth
              {...register('jobTitle')}
              type="text"
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl label={"Téléphone lié à l'établissement"}>
            <PhoneInputControlled
              control={control}
              disabled={!isEditable}
              rules={{
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
              isFullWidth
              name="phone"
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            errorMessage={errors?.email?.message}
            label={
              <Flex mb={-18} p={0}>
                <Text fontSize={11} mb={-20} p={0}>
                  Email lié à l'établissement
                </Text>
                <a data-for="main" data-tip="" data-iscapture="true">
                  <InfoIconBlue
                    fontSize={25}
                    style={{ marginTop: '-7px', marginLeft: '5px' }}
                  />
                </a>
                <ReactTooltip
                  id="main"
                  place={'top'}
                  variant={'dark'}
                  float={true}
                  style={{ backgroundColor: Theme.colors.primary.default }}
                >
                  <Text color="white" fontSize={11}>
                    A renseigner si votre intervenant dispose d'un email
                    professionnel dédié à sa mission
                  </Text>
                </ReactTooltip>
              </Flex>
            }
          >
            <Input
              isDisabled={!isEditable}
              isFullWidth
              {...register('email', {
                validate: (value: any) =>
                  value?.length > 1
                    ? !checkEmail(value)
                      ? 'Email incorrect'
                      : undefined
                    : undefined,
              })}
              type="email"
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          {(contractor?.status === EContractorStatus.PENDING ||
            contractor?.status === EContractorStatus.REJECTED) && (
            <FormControl label={"Statut social pour l'établissement renseigné"}>
              <StaticSelectControlled
                control={control}
                options={CONTRACTOR_SOCIAL_STATUS_SELECT}
                name="socialStatus"
                placeholder="Statut social"
              />
            </FormControl>
          )}
          {(contractor?.status === EContractorStatus.ACTIVE ||
            contractor?.status === EContractorStatus.ARCHIVED) && (
            <FormControl label={"Statut social pour l'établissement renseigné"}>
              <Input
                isFullWidth
                value={
                  CONTRACTOR_SOCIAL_STATUS[
                    socialStatus as EContractorSocialStatus
                  ]
                }
                type="text"
                isDisabled
              />
            </FormControl>
          )}
        </Cell>
        {socialStatus !== EContractorSocialStatus.SUBCONTRACTOR &&
          socialStatus !== EContractorSocialStatus.COMPANY_MANAGER &&
          socialStatus !== EContractorSocialStatus?.MAJORITY_MANAGER &&
          (contractor.status !== EContractorStatus.ACTIVE ? (
            <Cell x-span={grid4}>
              <FormControl
                required
                label="DPAE POUR CET ÉTABLISSEMENT "
                errorMessage={errors?.dpae?.message}
              >
                <Box
                  borderRadius="8px"
                  width={1 / 1}
                  border={`1px ${
                    dpae?.status === EDpaeStatus.TO_BE_VALIDATED
                      ? 'solid orange'
                      : dpae?.status === EDpaeStatus.REJECTED
                        ? 'dashed red'
                        : 'dashed black'
                  }`}
                  height="50px"
                  mt={0}
                >
                  <Flex alignContent="center" alignItems="center">
                    <Box width={8 / 10}>
                      {dpaeFile ? (
                        <FileInput
                          isDisabled={!isEditable}
                          //name="dpae"
                          ml={20}
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link ml={20} mt={15}>
                            {dpaeFile.name}
                          </Link>
                        </FileInput>
                      ) : contractor?.tmpData?.dpaeAttachment?.fileName ? (
                        <Link
                          ml={20}
                          color={'orange'}
                          onClick={() =>
                            showDisplayPdfModal({
                              //@ts-ignore
                              fileLocation:
                                contractor?.tmpData?.dpaeAttachment
                                  ?.fileLocation,
                              //@ts-ignore
                              fileName: `${contractor?.tmpData?.dpaeAttachment?.fileName}.pdf`,
                            })
                          }
                          mt={15}
                        >
                          {contractor?.tmpData?.dpaeAttachment?.fileName}
                        </Link>
                      ) : (
                        <FileInput
                          ml={20}
                          isDisabled={!isEditable}
                          //name="dpae"
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link mt={10}>Ajouter une DPAE</Link>
                        </FileInput>
                      )}
                    </Box>
                    {isEditable && (
                      <Box width={3 / 10}>
                        <FileInput
                          isDisabled={!isEditable}
                          //name="dpae"
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link mt={10} iconLeft={<DownloadIcon />}></Link>
                        </FileInput>
                      </Box>
                    )}
                  </Flex>
                </Box>
              </FormControl>
              {dpae?.status === EDpaeStatus.ACTIVE ? (
                <Status mt={-20} variantColor="success">
                  Active
                </Status>
              ) : dpae?.status === EDpaeStatus.TO_BE_VALIDATED ? (
                <Status mt={-20} variantColor="warning">
                  DPAE en attente de validation
                </Status>
              ) : dpae?.status === EDpaeStatus.REJECTED ? (
                <Status mt={-20} variantColor="error">
                  DPAE refusée
                </Status>
              ) : (
                false
              )}
            </Cell>
          ) : (
            <Cell x-span={grid4}>
              <FormControl
                required
                label="DPAE POUR CET ÉTABLISSEMENT "
                errorMessage={errors?.dpae?.message}
              >
                <Box
                  borderRadius="8px"
                  width={1 / 1}
                  border={`1px ${
                    dpae?.status === EDpaeStatus.TO_BE_VALIDATED
                      ? 'solid orange'
                      : dpae?.status === EDpaeStatus.REJECTED
                        ? 'dashed red'
                        : 'dashed black'
                  }`}
                  height="50px"
                  mt={0}
                >
                  <Flex alignContent="center" alignItems="flex-start">
                    <Box width={7 / 10}>
                      {dpaeFile ? (
                        <FileInput
                          isDisabled={!isEditable}
                          //name="dpae"
                          ml={20}
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link ml={20} mt={15}>
                            {dpaeFile.name}
                          </Link>
                        </FileInput>
                      ) : dpae?.attachment?.fileName ? (
                        <Link
                          ml={20}
                          color={
                            dpae?.status === EDpaeStatus.TO_BE_VALIDATED
                              ? Theme.colors?.warning?.default
                              : dpae?.status === EDpaeStatus.REJECTED
                                ? Theme.colors?.error?.default
                                : Theme.colors?.primary.default
                          }
                          onClick={() =>
                            showDisplayPdfModal({
                              //@ts-ignore
                              fileLocation: dpae?.attachment?.fileLocation,
                              //@ts-ignore
                              fileName: `${dpae?.attachment?.fileName}.pdf`,
                            })
                          }
                          mt={15}
                        >
                          {dpae?.attachment?.fileName}
                        </Link>
                      ) : (
                        <FileInput
                          isDisabled={!isEditable}
                          ml={20}
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link mt={10}>Ajouter une DPAE</Link>
                        </FileInput>
                      )}
                    </Box>
                    {isEditable && (
                      <Box width={2 / 10}>
                        <FileInput
                          isDisabled={!isEditable}
                          //name="dpae"
                          variant="link"
                          required
                          label=""
                          accept=".pdf"
                          subLabel=""
                          onSelect={handleChangeDpae}
                        >
                          <Link
                            mt={10}
                            mb={15}
                            iconLeft={<DownloadIcon />}
                          ></Link>
                        </FileInput>
                      </Box>
                    )}
                  </Flex>
                </Box>
              </FormControl>
              {dpae?.status === EDpaeStatus.ACTIVE ? (
                <Status mt={-20} variantColor="success">
                  Active
                </Status>
              ) : dpae?.status === EDpaeStatus.TO_BE_VALIDATED ? (
                <Status mt={-20} variantColor="warning">
                  DPAE en attente de validation
                </Status>
              ) : dpae?.status === EDpaeStatus.REJECTED ? (
                <Status mt={-20} variantColor="error">
                  DPAE refusée
                </Status>
              ) : (
                false
              )}
            </Cell>
          ))}
      </Grid>
      {isEditable && (
        <Button key="submit" type="submit" isLoading={loading}>
          Enregistrer
        </Button>
      )}
    </form>
  );
};
