import { validateFormPhoneFormatFR } from '@commons';
import { FlatUser } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Cell,
  FormControl,
  Grid,
  Input,
  Radio,
  PhoneInputControlled,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
type FormValues = FlatUser;

type AccountFormProps = {
  user: FlatUser;
};

const grid4 = {
  xs: '4',
  sm: '4',
  md: '4',
  lg: '4',
  xl: '4',
};

export const AccountForm = ({ user }: AccountFormProps) => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...user,
      //@ts-ignore
      civility: user?.civility?.toString(),
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    setLoading(false);
  };

  React.useEffect(() => {}, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid cols={12} gap="30px">
        <Cell x-span={{ xs: '12' }}>
          <Box mt={40}>
            <FormControl
              required
              label={'Civilité'}
              errorMessage={errors?.civility?.message}
            >
              <>
                <Radio
                  disabled
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="1"
                >
                  Monsieur
                </Radio>
                <Radio
                  disabled
                  {...register('civility', { required: 'Ce champ est requis' })}
                  value="2"
                >
                  Madame
                </Radio>
              </>
            </FormControl>
          </Box>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            required
            label="Prénom"
            errorMessage={errors?.firstName?.message}
          >
            <Input
              isFullWidth
              type="text"
              isDisabled
              {...register('firstName')}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            required
            label="Nom"
            errorMessage={errors?.lastName?.message}
          >
            <Input
              isFullWidth
              type="text"
              isDisabled
              {...register('lastName')}
            />
          </FormControl>
        </Cell>
        {/* <Cell x-span={grid4}>
          <FormControl
            required
            label="Date de naissance"
            errorMessage={errors?.birthday?.message}
          >
            <Input
              isFullWidth
              type="text"
              isDisabled
              {...register('birthday')}
            />
          </FormControl>
        </Cell> */}
        <Cell x-span={grid4}>
          <FormControl
            required
            label="Fonction"
            errorMessage={errors?.jobTitle?.message}
          >
            <Input
              isFullWidth
              type="text"
              isDisabled
              {...register('jobTitle')}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid4}>
          <FormControl
            required
            label="Téléphone"
            errorMessage={errors?.phone?.message}
          >
            <PhoneInputControlled
              control={control}
              disabled
              rules={{
                required: 'Ce champs est requis',
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
              isFullWidth
              name="phone"
            />
          </FormControl>
        </Cell>
      </Grid>
      {false && (
        <Button key="submit" type="submit" isLoading={loading}>
          Enregistrer
        </Button>
      )}
    </form>
  );
};
