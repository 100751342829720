/* eslint-disable react/display-name */
import { Box } from 'components/ui';
import Table from 'components/ui/Table';
import React from 'react';
import { useLogic } from './hooks';

type RCPROListProps = {
  filterObject: any;
  uuid: string;
};
export const RCPROList = ({ filterObject, uuid }: RCPROListProps) => {
  const {
    status,
    isFetching,
    data,
    columns,
    setFetchInfo,
    pageIndex,
    columnsHidden,
    updateRCPROListStore,
  } = useLogic();
  React.useEffect(() => {
    if (uuid) {
      updateRCPROListStore({ uuid, filterObject });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject, uuid]);
  return (
    <Box width={1 / 1}>
      <Table
        onFetchData={setFetchInfo}
        controlledPageIndex={pageIndex}
        setPageIndex={pageIndex => updateRCPROListStore({ pageIndex })}
        setSelectedRowsId={(selectedRows: any) => {}}
        identifier="uuid"
        controlledHiddenColumns={columnsHidden}
        initialSortBy={[{ id: 'createdAt', desc: true }]}
        columns={columns}
        data={data?.results || []}
        controlledPageCount={data ? Math.ceil(data.totalCount / data.limit) : 0}
        totalCount={data?.totalCount || 0}
        labelData="RC PRO"
        disableNoDataText={true}
        isFetching={status === 'success' && isFetching}
        isLoading={status === 'pending'}
      />
    </Box>
  );
};

RCPROList.displayName = 'RcproList';

export default RCPROList;
