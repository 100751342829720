import { IJoinedContractor } from '@freelancelabs/teoreme-commons';
import { Box } from 'components/ui';
import React from 'react';
type ContractorInvoicesProps = {
  contractor: IJoinedContractor;
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const ContractorInvoices: React.FC<
  React.PropsWithChildren<ContractorInvoicesProps>
> = () => {
  //const { data: contractor } = useContractorFindOne(id);

  return <Box width={1 / 1}>ContractorInvoices</Box>;
};

ContractorInvoices.displayName = 'ContractorInvoices';

export default ContractorInvoices;
