export enum InvoiceStatusEnum {
  PROJECTED = 'PROJECTED',
  TO_BE_SUBMITTED = 'TO_BE_SUBMITTED',
  TO_BE_VALIDATED = 'TO_BE_VALIDATED',
  VALIDATED = 'VALIDATED',
  ARCHIVED = 'ARCHIVED',
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
}
