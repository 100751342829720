import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { Box, Button, Flex, Text } from 'components/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { create } from 'react-modal-promise';
import { useTheme } from 'styled-components';
import { EnvoyIcon } from 'components/icons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
};

export const EmailHasSendModal = ({ onResolve, isOpen, layer }: Props) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
      layer={layer}
    >
      <Box>
        <Flex mt={20} justifyContent="center" width={1 / 1}>
          <Box>
            <EnvoyIcon
              width="207px"
              height="302px"
              style={{ margin: '0 auto' }}
            />
          </Box>
        </Flex>
        <Box textAlign="center" mt={20}>
          <Text mb={10} variant="h2" color={theme.colors.text.secondary}>
            Un email de réinitialisation vous a été envoyé
          </Text>
        </Box>
        <Flex mt={40} justifyContent="center" width={1 / 1}>
          <Box width={1 / 1}>
            <Button
              width={1 / 1}
              onClick={() => {
                history.push('/login');
                onResolve(true);
              }}
            >
              Retourner à la page de connexion
            </Button>
          </Box>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showEmailHasSendModalModal = create<Props>(EmailHasSendModal);
