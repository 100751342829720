import {
  getAdditionalActivitiesTotalAmount,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  getTradeNameSafe,
  queryClient,
  round,
  useHeadQuarterFindMany,
  useMileStoneReportFindMany,
  useMissionFindMany,
  useProviderStatistics,
} from '@commons';
import {
  EadditionalActivityStatus,
  EadditionalActivityType,
  EBillingType,
  EInvoiceStatus,
  IAdditionalActivity,
  IEstablishment,
  IMilestoneReportExpenseData,
  IMilestoneReportMilestoneData,
  IAdditionalActivityMilestoneReportData,
} from '@freelancelabs/teoreme-commons';
import Accordion from 'components/Accordion';
import { Header } from 'components/Header';
import { Missioncon, SearchIcon } from 'components/icons';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  Box,
  Button,
  Cell,
  ColorTextBox,
  CustomToolTip,
  DropDownButton,
  Flex,
  FormLabel,
  Grid,
  Input,
  Pagination,
  SubItem,
  Text,
} from 'components/ui';
import { FeesIcon, MileStoneIcon, SwitchIcon } from 'components/ui/icons';
import { getStatusData } from 'helpers';
import { useDebounce } from 'hooks';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useActivityFlatRateStore } from 'store';
import { Theme } from 'styles';
import { getFilterObjectMileStoneAndFees } from './TableConfig';
import { showCreateOrUpdateExpensesModal } from 'components/modals/Activities/CreateOrUpdateExpensesModal';
import { showCreateOrUpdateMileStoneModal } from 'components/modals/Activities/CreateOrUpdateMileStoneModal';
import { Button as ButtonBootsrap } from 'react-bootstrap';
import { ContractorSelect } from 'components/selects/ContractorSelect';

export const ActivitiesFlatRateList = () => {
  const {
    pageSize,
    //sort,
    startDate,
    endDate,
    selectedStatus,
    selectedCustomer,
    selectedProvider,
    selectedContractor,
    pageIndex,
    searchQuery,
    updateActivityFlateRateStore,
  } = useActivityFlatRateStore();
  const history = useHistory();
  const { filter } = useParams<{ filter: 'pending' | 'terminated' }>();
  const debouncedFilterQuery: string = useDebounce(searchQuery, 400);
  const { data: meEstablishments } = useHeadQuarterFindMany();
  const providerEstablishmentsUuids: string[] = [];
  meEstablishments?.map(me =>
    me?.establishments?.forEach(e => providerEstablishmentsUuids?.push(e?.uuid))
  );
  const { data: mileStoneReport, isFetching } = useMileStoneReportFindMany({
    filterObject: getFilterObjectMileStoneAndFees(filter, {
      debouncedFilterQuery,
      startDate,
      endDate,
      selectedStatus,
      selectedCustomer,
      selectedProvider,
      selectedContractor,
    }),
    sort: '-mission.validatedAt mission.reference',
    skip: pageIndex * pageSize,
    limit: pageSize,
  });
  const { data: missionQuery } = useMissionFindMany({
    filterObject: {
      'billing.type': EBillingType.FLAT_RATE,
      'provider.contact': localStorage?.cognitoUserId,
    },
    limit: 100,
  });

  const { data: providerStats } = useProviderStatistics();
  const haveTimeSpentMission =
    providerStats && providerStats?.dayMission?.totalCount > 0;
  // const haveFlateRateMission =
  //   providerStats && providerStats?.flatRateMission?.totalCount > 0;
  const timeSpentPendingTotalCount =
    providerStats?.dayMission?.totalActivityReportWaitingForAction;

  const customerEstablishment: IEstablishment[] | [] = [];
  missionQuery?.missions?.forEach(m => {
    if (
      !customerEstablishment?.find(
        c => c?.uuid === m?.customer?.establishment?.uuid
      )
    ) {
      customerEstablishment?.push(m?.customer?.establishment as never);
    }
  });
  const TabItems = () => {
    return (
      <>
        <SubItem
          variant="primary"
          href={'/invoices/flat-rate/activities/list/pending'}
        >
          {`En cours (${providerStats?.flatRateMission?.totalMilestoneReportWaitingForAction})`}
        </SubItem>
        <SubItem
          variant="primary"
          href={'/invoices/flat-rate/activities/list/terminated'}
        >
          {`Terminé (${providerStats?.flatRateMission?.totalMilestoneReportTreated})`}
        </SubItem>
      </>
    );
  };
  const grid = {
    xs: '3',
    sm: '3',
  };
  enum DataType {
    MILESTONE = 'MILESTONE',
    EXPENSE = 'EXPENSE',
  }
  const dataType = {
    [EadditionalActivityType.MILESTONE]: 'Prestation',
    [EadditionalActivityType.EXPENSE]: 'Frais',
  };
  const getIconType = (type: EadditionalActivityType, fill?: string) => {
    if (type === EadditionalActivityType.MILESTONE) {
      return <MileStoneIcon fill={fill || Theme?.colors?.primary?.default} />;
    }
    if (type === EadditionalActivityType.EXPENSE) {
      return <FeesIcon fill={fill || Theme?.colors?.primary?.default} />;
    }
  };
  const getInvoiceOrActivyStatusData = (
    activity: IMilestoneReportExpenseData | IMilestoneReportMilestoneData
  ) => {
    if (
      activity?.status === EadditionalActivityStatus.REJECTED ||
      !activity?.providerInvoice
    ) {
      return getStatusData(activity);
    } else {
      return getStatusData(activity?.providerInvoice);
    }
  };
  type ActivityRowActionProps = {
    data: IMilestoneReportExpenseData | IMilestoneReportMilestoneData;
    mission: IAdditionalActivityMilestoneReportData['mission'];
  };
  const ActivityRowAction = ({ data, mission }: ActivityRowActionProps) => {
    const history = useHistory();
    //const showMessage = useShowMessage();
    //const [isLoadingReminder, setLoadingReminder] = useState(false);
    const type = data?.type;
    const haveActivityInProgress =
      data?.status === EadditionalActivityStatus.REJECTED ||
      data?.status === EadditionalActivityStatus.TO_BE_SUBMITTED ||
      data?.status === EadditionalActivityStatus.TO_BE_FILLED;
    const haveInvoiceInProgress =
      data?.providerInvoice?.status === EInvoiceStatus.REJECTED ||
      data?.providerInvoice?.status === EInvoiceStatus.TO_BE_SUBMITTED ||
      data?.providerInvoice?.status === EInvoiceStatus.TO_BE_FILLED;
    if (haveActivityInProgress) {
      return (
        <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
          <Button
            variant="filled"
            minWidth="140px"
            height="33.5px"
            onClick={() =>
              type === EadditionalActivityType.EXPENSE
                ? showCreateOrUpdateExpensesModal({
                    missionRef: mission?.reference,
                    method: 'UPDATE',
                    uuid: data?.uuid,
                    layer: 104,
                  })
                : type === EadditionalActivityType.MILESTONE
                  ? showCreateOrUpdateMileStoneModal({
                      //@ts-ignore
                      mission: mission,
                      method: 'UPDATE',
                      uuid: data?.uuid,
                      layer: 104,
                    })
                  : false
            }
          >
            Modifier
          </Button>
        </Flex>
      );
    }
    if (haveInvoiceInProgress) {
      return (
        <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
          <Button
            variant="filled"
            minWidth="140px"
            height="33.5px"
            onClick={() =>
              history?.push(
                `/invoices/time-spent/to-be-submitted/${data?.providerInvoice?.uuid}`
              )
            }
          >
            Modifier
          </Button>
        </Flex>
      );
    }
    return (
      <Flex justifyContent="flex-end" alignItems="center" width={1 / 1}>
        <Button
          variant="filled"
          minWidth="140px"
          height="33.5px"
          onClick={() =>
            history?.push(
              `/invoices/time-spent/to-be-submitted/${data?.providerInvoice?.uuid}`
            )
          }
        >
          Consulter
        </Button>
      </Flex>
    );
  };
  const AccordionWithLines = ({
    msreport,
  }: {
    msreport: IAdditionalActivityMilestoneReportData;
  }) => {
    const [forceOpen, setForceOpen] = useState(false);
    return (
      <Accordion
        key={msreport?.mission?.reference}
        //openOnTop
        onForceOpen={forceOpen}
        onChangeForceOpen={value => setForceOpen(value)}
        childrenTop={
          <Flex width={1 / 1}>
            <Flex alignItems={'center'} width={5 / 10}>
              <Missioncon fontSize={25} />
              <Text ml={10}>
                {`${msreport?.mission?.displayReference} - ${getTradeNameSafe(
                  msreport?.estCustomer
                )} - ${getTradeNameSafe(msreport?.estProvider)} - ${getFullName(
                  msreport?.contractor
                )}`}
              </Text>
            </Flex>
            <Flex
              justifyContent={'flex-end'}
              alignItems={'center'}
              width={5 / 10}
            >
              <CustomToolTip
                id={msreport?.mission?.reference + '_presta'}
                text={'Budget de la prestation'}
              >
                <ColorTextBox variantColor="primary">
                  <Flex alignItems={'center'}>
                    <MileStoneIcon fill={Theme?.colors?.primary?.default} />
                    <Box ml={10}>
                      {`${
                        msreport?.mission?.billing?.consumedPurchaseBudget?.toFixed(
                          2
                        ) || 0
                      } € / ${
                        msreport?.mission?.billing?.contractorRate?.toFixed(
                          2
                        ) || 'N/A'
                      } €`}
                    </Box>
                  </Flex>
                </ColorTextBox>
              </CustomToolTip>
              {msreport?.mission?.expensesConfig?.isEnabled &&
              msreport?.mission?.expensesConfig?.purchaseBudget ? (
                <CustomToolTip
                  id={msreport?.mission?.reference + '_expenses'}
                  text={'Budget des frais'}
                >
                  <ColorTextBox variantColor="primary">
                    <Flex alignItems={'center'}>
                      <FeesIcon fill={Theme?.colors?.primary?.default} />
                      <Box ml={10}>
                        {`${
                          //@ts-ignore
                          msreport?.mission?.expensesConfig?.consumedPurchaseBudget?.toFixed(
                            2
                          ) || 0
                        } € ${
                          msreport?.mission?.expensesConfig?.purchaseBudget
                            ? '/' +
                              //@ts-ignore
                              msreport?.mission?.expensesConfig?.purchaseBudget?.toFixed(
                                2
                              ) +
                              '€'
                            : ''
                        }`}
                      </Box>
                    </Flex>
                  </ColorTextBox>
                </CustomToolTip>
              ) : (
                <></>
              )}
              <Box ml={10} position={'relative'}>
                {msreport?.mission?.expensesConfig?.isEnabled ? (
                  <DropDownButton
                    buttonText="Déposer une facture"
                    ml={10}
                    minWidth="180px"
                    height="33.5px"
                    onClick={() => setForceOpen(true)}
                    items={[
                      {
                        children: (
                          <Text color="white">Facture de prestation</Text>
                        ),
                        onClick: () =>
                          showCreateOrUpdateMileStoneModal({
                            //@ts-ignore
                            mission: msreport?.mission,
                            method: 'CREATE',
                          })?.then(() =>
                            queryClient?.refetchQueries({
                              queryKey: ['milestoneReport'],
                            })
                          ),
                      },
                      {
                        children: <Text color="white">Facture de frais</Text>,
                        onClick: () =>
                          showCreateOrUpdateExpensesModal({
                            missionRef: msreport?.mission?.reference,
                            method: 'CREATE',
                          })?.then(() =>
                            queryClient?.refetchQueries({
                              queryKey: ['milestoneReport'],
                              type: 'active',
                            })
                          ),
                      },
                    ]}
                  />
                ) : (
                  <ButtonBootsrap
                    style={{
                      minWidth: '180px',
                      height: '33.5px',
                      background: Theme?.colors?.primary?.default,
                    }}
                    onClick={() =>
                      showCreateOrUpdateMileStoneModal({
                        //@ts-ignore
                        mission: msreport?.mission,
                        method: 'CREATE',
                      })?.then(() =>
                        queryClient?.refetchQueries({
                          queryKey: ['milestoneReport'],
                          type: 'active',
                        })
                      )
                    }
                  >
                    Déposer une facture
                  </ButtonBootsrap>
                )}
              </Box>
            </Flex>
          </Flex>
        }
      >
        <Box
          position="relative"
          top={-5}
          p={10}
          borderLeft="1px solid"
          borderRight="1px solid"
          borderBottom="1px solid"
          borderColor={'#d0d4e3'}
        >
          <Flex
            width={1 / 1}
            borderBottom="1px solid"
            borderColor={'#d0d4e3'}
            mb={'5px'}
          >
            <Box pt={10} pl={10} pb={10} width={4 / 16}>
              <Text
                fontSize={10}
                variant="b"
                color={Theme?.colors?.primary?.light}
              >
                Type
              </Text>
            </Box>
            <Box pt={10} pb={10} pl={10} width={2 / 16}>
              <Text
                fontSize={10}
                variant="b"
                color={Theme?.colors?.primary?.light}
              >
                Déclaré le
              </Text>
            </Box>
            <Box pt={10} pb={10} width={2 / 16}>
              <Text
                fontSize={10}
                variant="b"
                color={Theme?.colors?.primary?.light}
              >
                Montant HT
              </Text>
            </Box>
            <Box pt={10} pb={10} width={2 / 16}>
              <Text
                fontSize={10}
                variant="b"
                color={Theme?.colors?.primary?.light}
              >
                Date d'échéance
              </Text>
            </Box>
            <Box pt={10} pb={10} width={2 / 16}>
              <Text
                fontSize={10}
                variant="b"
                color={Theme?.colors?.primary?.light}
              >
                Statut
              </Text>
            </Box>
            <Box textAlign="end" pr={60} pt={10} pb={10} width={6 / 16}>
              <Text
                fontSize={10}
                variant="b"
                color={Theme?.colors?.primary?.light}
              >
                Actions
              </Text>
            </Box>
          </Flex>
          {[...msreport?.milestones, ...msreport?.expenses]?.map(
            (additional: any, index: number) => {
              const statuscolors = getInvoiceOrActivyStatusData(additional);
              return (
                <Flex width={1 / 1} key={`additional_${index}`} p={10}>
                  <Box width={4 / 16}>
                    <Flex>
                      <Box mr={'5px'}>{getIconType(additional?.type)}</Box>
                      <Box>{dataType?.[additional?.type as DataType]}</Box>
                    </Flex>
                  </Box>
                  <Box width={2 / 16}>
                    {getHumanDate(
                      additional?.submittedAt || additional?.createdAt
                    )}
                  </Box>
                  <Box width={2 / 16}>
                    <ColorTextBox variantColor="primary" ml={'-5px'}>
                      {`${getAdditionalActivitiesTotalAmount(
                        [additional as IAdditionalActivity],
                        additional.type,
                        'PROVIDER'
                      )} €`}
                    </ColorTextBox>
                  </Box>
                  <Box width={2 / 16}>
                    <Text ml={'-5px'}>
                      {additional?.providerInvoice?.dueDate
                        ? getHumanDate(additional?.providerInvoice?.dueDate)
                        : 'N/A'}
                    </Text>
                  </Box>
                  <Box width={4 / 16}>
                    {statuscolors?.rejectReason ? (
                      <CustomToolTip
                        id={additional?.uuid}
                        text={statuscolors?.rejectReason}
                        color={statuscolors?.textColor}
                      >
                        <ColorTextBox
                          ml={'-5px'}
                          variantColor={statuscolors?.variantColor}
                        >
                          {statuscolors?.statusText}
                        </ColorTextBox>
                      </CustomToolTip>
                    ) : (
                      <ColorTextBox
                        ml={'-5px'}
                        variantColor={statuscolors?.variantColor}
                      >
                        {statuscolors?.statusText}
                      </ColorTextBox>
                    )}
                  </Box>
                  <Box width={4 / 16}>
                    <ActivityRowAction
                      data={additional}
                      mission={msreport?.mission}
                    />
                  </Box>
                </Flex>
              );
            }
          )}
        </Box>
      </Accordion>
    );
  };
  const totalCount = mileStoneReport?.totalCount;
  const p = round(totalCount ? Math.ceil(totalCount / pageSize) : 0, 0);
  const pageCount = Number.isInteger(p) ? p : p + 1;
  const getpageOptions = () => {
    const opt = [];
    for (let i = 0; i < pageCount; i++) {
      opt?.push(i + 1);
    }
    return opt;
  };
  const pOptions = getpageOptions();
  const pagination = {
    canPreviousPage: pageIndex >= 1,
    canNextPage: pageIndex + 2 <= pageCount,
    pageOptions: pOptions,
    pageCount,
    pageIndex,
    gotoPage: (index: number) =>
      updateActivityFlateRateStore({ pageIndex: index }),
    setPageSize: (number: any) =>
      updateActivityFlateRateStore({ pageSize: number, pageIndex: 0 }),
    pageSize,
    labelData: 'Missions',
    totalCount,
  };
  useEffect(() => {
    updateActivityFlateRateStore({ pageIndex: 0, selectedStatus: 'ALL' });
  }, [filter]);
  return (
    <Box width={1 / 1}>
      <Header tabs={<TabItems />}>
        <Flex justifyContent={'space-between'}>
          <Text variant="h1">Activités au forfait</Text>
          {haveTimeSpentMission && (
            <Button
              onClick={() => history.push('/invoices/activities/list/pending')}
            >
              <Flex alignItems={'center'}>
                <SwitchIcon
                  fontSize={22}
                  style={{ marginRight: '5px' }}
                  fill={'white'}
                />
                {`Temps passé (${timeSpentPendingTotalCount})`}
              </Flex>
            </Button>
          )}
        </Flex>
      </Header>
      <Text variant="p" mt={20}>
        {/* {getTabsTextsByState(filterStatus)} */}
      </Text>
      <Box mt={20} mb={20}>
        <Grid cols={12}>
          <Cell x-span={grid}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              icon={<SearchIcon />}
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) =>
                updateActivityFlateRateStore({
                  searchQuery: e.target.value,
                  pageIndex: 0,
                })
              }
              pr={20}
            />
          </Cell>
          <Cell x-span={grid} pl={20}>
            <FormLabel>Client</FormLabel>
            <StaticSelect
              isClearable
              width={1 / 1}
              options={customerEstablishment?.map(ce => {
                return {
                  key: ce?.uuid,
                  label: getEstablishmentName(ce),
                  value: ce?.uuid as string,
                  data: ce,
                };
              })}
              value={selectedCustomer}
              onChange={value =>
                updateActivityFlateRateStore({
                  selectedCustomer: value as string,
                  pageIndex: 0,
                })
              }
              placeholder="Établissements clients"
            />
          </Cell>
          {/* <Cell x-span={grid} pl={20}>
            <FormLabel>Établissements</FormLabel>
            <EstablishmentSelect
              filter={{ uuid: { $in: providerEstablishmentsUuids } }}
              width={1 / 1}
              value={selectedProvider}
              referenceValue="uuid"
              onChange={value =>
                updateActivityFlateRateStore({
                  selectedProvider: value as string,
                  pageIndex: 0,
                })
              }
              placeholder="Vos établissements"
            />
          </Cell> */}
          <Cell x-span={grid} pl={20}>
            <FormLabel>Intervenants</FormLabel>
            <ContractorSelect
              width={1 / 1}
              value={selectedContractor}
              onChange={value =>
                updateActivityFlateRateStore({
                  selectedContractor: value as string,
                  pageIndex: 0,
                })
              }
              placeholder="Établissements"
            />
          </Cell>
        </Grid>
      </Box>
      <Box width={1 / 1}>
        {totalCount && totalCount > 0 ? (
          // @ts-ignore
          mileStoneReport?.reports?.map(
            (msreport: IAdditionalActivityMilestoneReportData) => {
              return <AccordionWithLines msreport={msreport} />;
            }
          )
        ) : (
          <Box textAlign={'center'}>
            {!isFetching && (
              <Text variant="b">
                {`Vous n’avez pas encore d’activités ${
                  filter === 'pending' ? 'en cours' : 'terminé'
                } `}
              </Text>
            )}
          </Box>
        )}
        {totalCount && totalCount > 0 ? (
          <Box width={1 / 1}>
            <Pagination width={1 / 1} {...pagination} />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default ActivitiesFlatRateList;
