import * as React from 'react';
import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

export const LinkStyled = styled.div<{
  variantColor: 'error' | 'primary' | 'grey';
  isDisabled?: boolean;
}>`
  cursor: ${p => (p?.isDisabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 11px;
  text-decoration: ${p => (p?.isDisabled ? 'none' : 'underline')};

  color: ${p =>
    p?.isDisabled
      ? 'grey'
      : p.theme.colors[p.variantColor || 'primary'].default};

  svg {
    font-size: 2em;
    fill: ${p => p.theme.colors[p.variantColor || 'primary'].default};
  }

  ${boxProperties}
`;

export type LinkProps = BoxProps & {
  children?: React.ReactNode;
  style?: object;
  variantColor?: 'error' | 'primary' | 'grey';
  onClick?: () => void;

  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;

  isFullWidth?: boolean;
  isDisabled?: boolean;
};
export const Link: React.FunctionComponent<
  React.PropsWithChildren<LinkProps>
> = props => {
  const {
    variantColor = 'primary',
    children,
    // isFullWidth,
    iconLeft,
    iconRight,
    isDisabled,
    ...rest
  } = props;

  return (
    <LinkStyled isDisabled={isDisabled} variantColor={variantColor} {...rest}>
      {iconLeft && <div style={{ marginRight: 5 }}>{iconLeft}</div>}
      <div
        style={{
          whiteSpace: 'nowrap',
          marginTop: iconLeft || iconRight ? 5 : 0,
        }}
      >
        {children}
      </div>
      {iconRight && <div style={{ marginLeft: 5 }}>{iconRight}</div>}
    </LinkStyled>
  );
};
