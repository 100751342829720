import { uploadFile, EXPENSES_TYPE_SELECT } from '@commons';
import {
  EFileType,
  IJoinedMission,
  IExpenseLine,
} from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  Cell,
  Flex,
  FormControl,
  Grid,
  StaticSelectControlled,
  Text,
  NumberFormInputControlled,
  TextAreaControlled,
  CustomToolTip,
} from 'components/ui';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

type Line = IExpenseLine & {
  lock?: boolean;
};
type FormValues = Line & {
  lock: boolean;
};
type AddExpensesLineProps = {
  line: Line;
  readOnly?: boolean;
  mission: IJoinedMission;
  onCreateOrUpdateLine: (line: Line) => void;
  onDeleteLine: (line: Line) => void;
  unLockToUpdate?: (line: Line) => void;
  isEditable?: boolean;
};
const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
const grid4 = {
  xs: '3',
  sm: '3',
  md: '3',
  lg: '3',
  xl: '3',
};
const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '2',
};
export const AddExpensesLine: React.FC<
  React.PropsWithChildren<AddExpensesLineProps>
> = ({
  line,
  readOnly,
  isEditable,
  mission,
  onCreateOrUpdateLine,
  onDeleteLine,
  unLockToUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [attachmentFile, setAttachmentFile] = useState<File | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingFile, setLoadingFile] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      ...line,
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    const onError = false;
    setLoading(true);
    // let attachmentResponse;
    try {
      if (attachmentFile) {
        const uploadResponse = await uploadFile({
          file: attachmentFile as File,
          fileType: EFileType?.TEMPORARY,
          missionRef: mission?.reference,
          actionType: 'upload',
        });

        if (!uploadResponse) return;

        // attachmentResponse = {
        //   fileLocation: uploadResponse.fileLocation,
        //   fileName: uploadResponse.fileName,
        //   eTag: uploadResponse.eTag,
        // };
      }
      if (!onError) {
        const submitValues = {
          uuid: line?.uuid || new Date()?.getTime(),
          type: formValues?.type,
          amount: formValues?.amount,
          comment: formValues?.comment,
          lock: true,
        };
        //@ts-ignore
        onCreateOrUpdateLine(submitValues);
      }
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  };
  // const onChangeFile = (files: any) => {
  //   setLoadingFile(true);
  //   setAttachmentFile(files[0]);
  //   setLoadingFile(false);
  // };
  useEffect(() => {
    reset({
      ...line,
    });
  }, [line]);
  return (
    <Box width={1 / 1} mt={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid cols={12}>
          <Cell x-span={grid4}>
            <FormControl
              required
              label="Type de frais"
              p={'5px'}
              errorMessage={errors?.type?.message}
            >
              <StaticSelectControlled
                isDisabled={readOnly}
                control={control}
                rules={{ required: 'Ce champs est requis' }}
                name="type"
                options={EXPENSES_TYPE_SELECT}
              />
            </FormControl>
          </Cell>
          <Cell x-span={grid2}>
            <CustomToolTip
              text="Le montant à saisir doit correspondre au montant TTC (et non HT) des frais à déclarer"
              id={`${line?.uuid || new Date()?.toISOString()}_expense_line`}
            >
              <FormControl
                required
                label={'Montant TTC'}
                p={'5px'}
                errorMessage={
                  errors?.amount?.type === 'min'
                    ? 'Ne peut pas être inférieur à 1'
                    : errors?.amount?.message
                }
              >
                <Flex>
                  <NumberFormInputControlled
                    control={control}
                    name="amount"
                    maxDecimal={3}
                    step={'0.001'}
                    type="number"
                    isDisabled={readOnly}
                    isFullWidth
                    rules={{
                      required: 'Ce champ est requis',
                      min: 1,
                    }}
                  />
                </Flex>
              </FormControl>
            </CustomToolTip>
          </Cell>
          <Cell x-span={grid4}>
            <Flex
              width={1 / 1}
              alignItems="center"
              justifyContent="center"
              mt={30}
            >
              <Flex width={3 / 12}>
                {readOnly && isEditable ? (
                  <>
                    <Button
                      onClick={() => unLockToUpdate && unLockToUpdate(line)}
                      height="40px"
                      ml={'5px'}
                      mr={'5px'}
                      variant="ghost"
                    >
                      Modifier
                    </Button>
                    <Button
                      onClick={() => onDeleteLine(line)}
                      height="40px"
                      variant="ghostError"
                    >
                      Supprimer
                    </Button>
                  </>
                ) : (
                  <Box>
                    {!readOnly && (
                      <Button
                        isLoading={loading}
                        height="40px"
                        minWidth="125px"
                        variant="filled"
                        type="submit"
                      >
                        Ajouter
                      </Button>
                    )}
                  </Box>
                )}
              </Flex>
            </Flex>
          </Cell>
          <Cell x-span={grid12}>
            <FormControl label="Informations complémentaires">
              {!readOnly ? (
                <TextAreaControlled
                  minRows={3}
                  name="comment"
                  placeholder="Description du frais"
                  control={control}
                />
              ) : (
                <Text>{line?.comment || 'N/A'}</Text>
              )}
            </FormControl>
          </Cell>
        </Grid>
      </form>
    </Box>
  );
};

AddExpensesLine.displayName = 'AddExpensesLine';

export default AddExpensesLine;
