import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContractorList } from './List';
import { CreateContractor } from './Create';
import { ContractorDetails } from './Details';
import { Template } from 'components/Template';
import { Container } from 'components/ui';
export const Contractors = () => {
  return (
    <Switch>
      <Template>
        <Route exact path="/contractors">
          <Container>
            <ContractorList />
          </Container>
        </Route>
        <Route path="/contractors/:id/:tab">
          <Container>
            <ContractorDetails />
          </Container>
        </Route>
        <Route path="/contractors/create">
          <Container>
            <CreateContractor />
          </Container>
        </Route>
      </Template>
    </Switch>
  );
};
