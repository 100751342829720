import { EInvoiceStatus, EInvoiceType } from '@freelancelabs/teoreme-commons';
import { add, getTime } from 'date-fns';
import { InvoiceStatusEnum } from './types';
import { snakeCase } from 'lodash';

export const getObjectFilterInvoice = (
  debouncedFilterQuery = '',
  startDate: Date | null,
  endDate: Date | null,
  filterUrl: InvoiceStatusEnum,
  selectedStatus: EInvoiceStatus | 'ALL'
): any => {
  const filterObject = {
    invoiceType: {
      $in: [
        EInvoiceType?.PROVIDER_CREDIT_NOTE,
        EInvoiceType?.PROVIDER_EXPENSE_CREDIT_NOTE,
        EInvoiceType?.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
        EInvoiceType?.PROVIDER_MILESTONE_CREDIT_NOTE,
      ],
    },
    status: filterUrl,
  };
  const queryFilter = [
    {
      'estCustomer.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.firstName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.displayReference': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.sage.structure': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      providerInvoiceRef: {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (startDate !== null && endDate !== null) {
    //@ts-ignore
    filterObject.$and = [
      {
        $expr: {
          $gte: [{ $toLong: '$month' }, getTime(startDate)],
        },
      },
      {
        $expr: {
          $lte: [{ $toLong: '$month' }, getTime(add(endDate, { months: 1 }))],
        },
      },
    ];
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$and.push({ $or: queryFilter });
    }
  } else {
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$or = queryFilter;
    }
  }
  return filterObject;
};

export const getTabsTextsByState = (state: EInvoiceStatus) => {
  switch (snakeCase(state).toUpperCase()) {
    case EInvoiceStatus.VALIDATED: {
      return `Retrouvez ici l’ensemble des avoirs émis. Merci de saisir votre avoir et de joindre votre facture au format PDF.`;
    }
    case EInvoiceStatus.SENT: {
      return `Retrouvez ici l’ensemble des factures d’avoir validées.`;
    }
    case EInvoiceStatus.PAID: {
      return `Retrouvez ici l’ensemble des factures d’avoir payées.`;
    }
  }
};

export const getHiddenColumns = (innerWidth: number): string[] => {
  if (innerWidth <= 992) {
    return [
      'estCustomer.businessName',
      'estProvider.businessName',
      'totalWithTaxes',
    ];
  }

  return [];
};
