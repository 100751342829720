import {
  getFullName,
  getFirstCaracts,
  cutLongText,
  CONTRACTOR_SOCIAL_STATUS,
  transformPhoneToInternationalSafe,
  getHumanDate,
} from '@commons';
import { EContractorSocialStatus } from '@freelancelabs/teoreme-commons';
import { CheckIcon } from 'components/icons';
import {
  Box,
  boxProperties,
  BoxProps,
  Flex,
  Text,
  Initial,
  LabelField,
  Radio,
} from 'components/ui';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ContainerCard = styled(Box)<{
  isSelected?: boolean;
  isSelectable: Boolean | undefined;
  temporary?: boolean | undefined;
  bottomChildrens?: any;
  height: any;
  isDisabled?: boolean;
}>`
  width: 570px;
  height: ${p => (p.height ? p.height : 'auto')};
  padding: 21px 40px 26px 28px;
  border-radius: 4px;
  border: ${props =>
    props.temporary
      ? 'dashed 1px #d0d4e3'
      : props.isSelected
        ? 'solid 1px #003cc2'
        : 'solid 1px #d0d4e3'};
  cursor: ${props => (props.isSelectable ? 'pointer' : 'initial')};
  background-color: var(--color-white);
  ${boxProperties}
`;
type Props = {
  user: any;
  rightChidlrens?: React.ReactNode;
  bottomChildrens?: React.ReactNode;
  temporary?: boolean | undefined;
  height?: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  onChangeSelected?: () => void;
  ignoreScopedData?: boolean;
  isDisabled?: boolean;
};
const reverseString = (str: string) => str.split('').reverse().join('');

export const ContractorCard = ({
  user,
  rightChidlrens,
  bottomChildrens,
  temporary,
  height,
  isSelectable,
  ignoreScopedData,
  isSelected,
  onChangeSelected,
  isDisabled,
  ...props
}: BoxProps & Props) => {
  const theme = useTheme();
  const fullName = getFullName(user);

  return (
    <ContainerCard
      isDisabled={isDisabled}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={() => (onChangeSelected ? onChangeSelected() : false)}
      height={height}
      bottomChildrens={bottomChildrens}
      temporary={temporary}
    >
      {fullName.length > 25 && (
        <>
          <ReactTooltip
            id={user.uuid}
            place={'top'}
            variant={'dark'}
            float={true}
            style={{ backgroundColor: theme.colors.primary.default }}
          >
            <Text color="white" fontSize={11}>
              {fullName}
            </Text>
          </ReactTooltip>
        </>
      )}
      <Flex opacity={temporary ? '0.7' : '1'}>
        {isSelectable && (
          <Box>
            <Radio
              checked={isSelected}
              onChange={() => (onChangeSelected ? onChangeSelected() : false)}
            >
              <></>
            </Radio>
          </Box>
        )}
        <Box ml={'15px'}>
          <Initial initial={reverseString(getFirstCaracts(user as any))} />
        </Box>
        <Flex width={1 / 1} flexWrap="wrap">
          <Box ml={'15px'}>
            <Text variant="b">
              <Flex>
                {fullName.length < 25 ? (
                  <Text variant="b" color={theme.colors.text.secondary}>
                    {fullName}
                  </Text>
                ) : (
                  <a data-for={user.uuid} data-tip="" data-iscapture="true">
                    <Text variant="b" color={theme.colors.text.secondary}>
                      {cutLongText(getFullName(user), 25)}
                    </Text>
                  </a>
                )}

                <Box
                  ml={10}
                  backgroundColor={temporary || isDisabled ? 'gray' : 'green'}
                  height={15}
                  width={15}
                  borderRadius={'50%'}
                >
                  <CheckIcon color="white" />
                </Box>
              </Flex>
            </Text>
          </Box>
          <Flex width="100%">
            <LabelField
              ml={'15px'}
              label={''}
              value={getHumanDate(user?.birthday)}
            />
          </Flex>
        </Flex>
        <Box ml={'15px'}>
          <Box width={'100px'}>{rightChidlrens ? rightChidlrens : <></>}</Box>
        </Box>
      </Flex>
      <Box
        opacity={temporary ? '0.7' : '1'}
        width={1 / 1}
        mt={bottomChildrens ? '10px' : 20}
      >
        {!ignoreScopedData && (
          <>
            {' '}
            <Flex width="100%">
              <LabelField
                mb={0}
                mt={0}
                ml={'15px'}
                label={'Fonction liée à l’etbl. : '}
                value={user?.jobTitle || 'N/A'}
              />
            </Flex>
            <Flex width="100%">
              <LabelField
                mb={0}
                mt={0}
                ml={'15px'}
                label="Tél. lié à l’etbl. : "
                value={
                  transformPhoneToInternationalSafe(user.phone, 'FR') || 'N/A'
                }
              />
            </Flex>
          </>
        )}
        <Flex width="100%">
          <LabelField
            mb={0}
            mt={0}
            ml={'15px'}
            label="Email : "
            value={
              ignoreScopedData ? (
                user?.user?.email ? (
                  <Text ml={'5px'} mb={'2px'} fontSize={13} variant="a">
                    <a href={`mailto: ${user?.user?.email}`}>
                      {' '}
                      {user?.user?.email}
                    </a>
                  </Text>
                ) : (
                  <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
                    N/A
                  </Text>
                )
              ) : user?.email ? (
                <Text ml={'5px'} mb={'2px'} fontSize={13} variant="a">
                  <a href={`mailto: ${user?.email}`}> {user?.email}</a>
                </Text>
              ) : (
                <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
                  N/A
                </Text>
              )
            }
          />
        </Flex>
        {!ignoreScopedData && (
          <>
            <Flex width="100%">
              <LabelField
                mb={0}
                mt={0}
                ml={'15px'}
                label="Email secondaire : "
                value={
                  user?.alternateEmail ? (
                    <Text ml={'5px'} mb={'2px'} fontSize={13} variant="a">
                      <a href={`mailto: ${user?.alternateEmail}`}>
                        {' '}
                        {user?.alternateEmail}
                      </a>
                    </Text>
                  ) : (
                    <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
                      N/A
                    </Text>
                  )
                }
              />
            </Flex>
            <Flex width="100%">
              <LabelField
                mb={0}
                mt={0}
                ml={'15px'}
                label="Statut social : "
                value={
                  CONTRACTOR_SOCIAL_STATUS?.[
                    user.socialStatus as EContractorSocialStatus
                  ] || 'N/A'
                }
              />
            </Flex>
          </>
        )}
      </Box>
      {bottomChildrens && (
        <Box ml={'15px'} mt={'10px'}>
          {bottomChildrens}
        </Box>
      )}
    </ContainerCard>
  );
};
ContractorCard.displayName = 'ContractorCard';

export default ContractorCard;
