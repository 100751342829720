import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6b5feed82adb479fb20449c26fee680b@o113549.ingest.sentry.io/5644177',
    environment: process.env.NODE_ENV, //process.env.REACT_APP_STAGE,
    integrations: [Sentry.browserTracingIntegration()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
  });
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
