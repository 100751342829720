import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Flex, Box, Text } from 'components/ui';
import { InfoIcon } from 'components/ui/icons';
import React from 'react';
import { Theme } from 'styles';
type CustomToolTipProps = {
  text?: string | React.ReactNode;
  id?: string;
  color?: string;
  children: React.ReactNode;
  withIcon?: boolean;
};
export const CustomToolTip = ({
  text,
  id,
  color,
  withIcon,
  children,
}: CustomToolTipProps) => {
  return (
    <>
      <a data-for={id} data-tip="" data-iscapture="true">
        <Flex alignItems={'center'}>
          {withIcon && (
            <Box mr={10}>
              <InfoIcon width={14} height={14} />
            </Box>
          )}
          <ReactTooltip
            id={id}
            place={'top'}
            variant={'dark'}
            float={false}
            style={{
              backgroundColor: color || Theme?.colors?.primary?.default,
            }}
          >
            <Text color="white" maxWidth={500} fontSize={11} p={'3px'}>
              {text}
            </Text>
          </ReactTooltip>
          {children}
        </Flex>
      </a>
    </>
  );
};
