import { useCraFindMany, useInvoiceFindMany } from '@commons';
import { useDebounce } from 'hooks/useDebounce';
import { useParams } from 'react-router-dom';
import { getFilterByUrl, getObjectFilterCRA, mapSortByToApi } from './helpers';
import { useCraListStore } from 'store/cra';
export const useFetchData = ({ pageSize, sortBy }: any) => {
  const { startDate, endDate, selectedStatus, searchQuery, pageIndex, isOpen } =
    useCraListStore();

  const { filter } = useParams<{ filter: string }>();
  const filterUrl = getFilterByUrl(filter);

  const debounceSearchQuery = useDebounce(searchQuery, 400);

  const filterObject = getObjectFilterCRA(
    debounceSearchQuery,
    startDate,
    endDate,
    filterUrl,
    selectedStatus,
    isOpen
  );

  const {
    data = { cras: [], totalCount: -1, limit: 10 },
    status,
    isFetching,
  } = useCraFindMany({
    filterObject,
    sort: mapSortByToApi(sortBy),
    limit: pageSize,
    skip: pageIndex * pageSize,
  });
  const { data: invoices } = useInvoiceFindMany({
    filterObject: {
      invoiceType: 'PROVIDER',
      cra: {
        //@ts-ignore
        $in: data?.cras?.map((cra: any) => cra.refCra),
      },
    },
    limit: pageSize,
    skip: 0,
  });

  return {
    data,
    status,
    isFetching,
    invoices,
  };
};
