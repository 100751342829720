import { useContractFindMany } from '@commons';
import { mapSortByToApi } from 'helpers/table';
import { useContractListStore } from 'store/contracts';

export const useFetchData = ({ pageSize, sortBy }: any) => {
  const { pageIndex, filterObject } = useContractListStore();
  const {
    data = { contracts: [], totalCount: -1, limit: 10 },
    status,
    isFetching,
  } = useContractFindMany({
    filterObject,
    sort: mapSortByToApi(sortBy),
    limit: pageSize,
    skip: pageIndex * pageSize,
  });
  return {
    data,
    status,
    isFetching,
  };
};
