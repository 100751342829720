import { create } from 'zustand';

type State = {
  pageIndex: number;
  columnsHidden: Array<any>;
  updateDpaeListStore: (state: Partial<State>) => void;
  resetDpaeListStore: (state: Partial<State>) => void;
  filterObject: any;

  searchQuery: string;
};

const initialStates = {
  pageIndex: 0,
  columnsHidden: [],
  filterObject: undefined,

  searchQuery: '',
};

const store = (set: any) => ({
  ...initialStates,
  updateDpaeListStore: (state: Partial<State>) => set(state),
  resetDpaeListStore: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useDpaeListStore = create<State>(store);
