import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { ForgetPasswordForm } from 'forms/ForgetPassword';
import { create } from 'react-modal-promise';

type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
};

export const ForgetPasswordModal = ({ onResolve, isOpen, layer }: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={470}
      closeIcon
      layer={layer}
    >
      <ForgetPasswordForm onClose={() => onResolve(false)} />
    </ModalFrame>
  );
};

export const showForgetPasswordModal = create<Props>(ForgetPasswordModal);
