import { EconfirmUserAction } from '@freelancelabs/teoreme-commons';
import {
  checkValidPassword,
  confirmPassword,
  verifyToken,
  REACT_APP_CAPTCHA_KEY,
  checkEmail,
} from '@commons';
import { useAmplifyLogout, checkFormPassword, resendToken } from '@commons';
import { ConnexionIcon, LogoConnecteed, LogoFcomIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { showDialogModal } from 'components/modals/DialogModal';
import { showCGUModal } from 'components/modals/CGUModal';
import {
  Container,
  Box,
  Button,
  Cell,
  Divider,
  Flex,
  FormControl,
  Input,
  Link,
  Spinner,
  Text,
  Grid,
} from 'components/ui';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import AccesDenied from 'routes/Common/AccesDenied';
import { useTheme } from 'styled-components';
import { useSetCrumbs } from 'hooks/breadCrumb';
import ReCAPTCHA from 'react-google-recaptcha';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const CreatePassword = () => {
  const history = useHistory();
  const { mutateAsync: logout } = useAmplifyLogout();
  const Theme = useTheme();
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = React.useState('noFetch');
  const [email, setEmail] = React.useState('');
  const [resendEmail, setResendEmail] = React.useState('');
  const [isloading, setIsLoading] = React.useState(false);
  const [errorCGU, setErrorCGU] = React.useState<string | false>(false);
  const [openReCAPTCHA, setOpenReCAPTCHA] = React.useState(false);

  //const { setCrumbs } = useBreadCrumb();
  useSetCrumbs([token], [{ label: `Créer votre mot de passe`, path: '' }]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();
  const loading = status === 'pending';
  const checkToken = React.useCallback(async (): Promise<void> => {
    try {
      const response = await verifyToken({ token: token }, true);
      setEmail(response.email);
      setStatus('success');
    } catch {
      setStatus('error');
    }
  }, [token]);

  React.useEffect(() => {
    if (status === 'noFetch') {
      setStatus('loading');
      checkToken();
    }
  }, [checkToken, status]);

  const onSubmit = async (data: FormValues) => {
    let onError = false;
    if (data.password === data.confirmPassword) {
      if (checkValidPassword(data.password)) {
        setError('password', { message: undefined });
        setError('confirmPassword', { message: undefined });
      } else {
        onError = true;
        setError('password', {
          message: "Le format du mot de passe n'est pas valide",
        });
        setError('confirmPassword', {
          message: "Le format du mot de passe n'est pas valide",
        });
      }
    } else {
      onError = true;
      setError('password', {
        message: 'Les mots de passe ne sont pas identiques',
      });
      setError('confirmPassword', {
        message: 'Les mots de passe ne sont pas identiques',
      });
    }
    if (onError === false) {
      setIsLoading(true);
      await showCGUModal().then(async accepted => {
        if (accepted) {
          setErrorCGU(false);
          try {
            const params = {
              email: email,
              newPassword: data.password,
              action: EconfirmUserAction.ACTIVATE,
            };

            await confirmPassword(params);
            setIsLoading(false);
            if (localStorage.token) {
              logout();
            }
            await showDialogModal({
              title: 'Félicitations, votre mot de passe a bien été défini !',
              text: 'Vous allez recevoir un mail de confirmation.',
              confirmLabel: 'Se connecter',
            }).then(action => {
              if (action) {
                history.push('/login');
              } else {
              }
            });
          } catch (e) {
            //console.log(e);
          }
        } else {
          setErrorCGU(
            'Veuillez accepter les conditions générales d’utilisation pour finaliser votre inscription.'
          );
        }
      });
    }
    setIsLoading(false);
  };
  function onChangeCaptcha(value: any) {
    if (value) {
      handleResendToken();
    }
  }

  const handleResendToken = async () => {
    try {
      await resendToken({ email: resendEmail, activationToken: token });
      history.push('/refresh-success');
    } catch (e) {
      //
    }
  };
  if (loading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  } else {
    if (status === 'success') {
      return (
        <Flex
          display="inline-flex"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          width="100%"
          height="100vh"
        >
          <Container>
            <Grid
              style={{
                height: '100vh',
                maxHeight: '900px',
                maxWidth: '1440px',
              }}
              cols={12}
            >
              <Cell x-span={{ xs: '6' }} placeSelf="center">
                <Box maxWidth="400px">
                  <Text variant="h1" fontSize={32} mb={4} color={'#000000'}>
                    bienvenue sur{' '}
                    <Text variant="h1" fontSize={32}>
                      {' '}
                      connecteed
                    </Text>
                  </Text>
                  <Box position="absolute" top={50} left={'50%'}>
                    <LogoConnecteed />
                  </Box>
                  <Text variant="h3">
                    <b>Créer votre mot de passe</b>
                  </Text>
                  <Text mb={10}>
                    Il doit contenir au moins 8 caractères, 1 majuscule, 1
                    minuscule et 1 caractère spécial parmi : ^ $ * . [ ] {} ( )
                    ? " ! @ # % & / \ , {'>'} {'<'} \' : ; | _ ~.
                  </Text>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl
                      required
                      label="Mot de passe"
                      errorMessage={errors?.password?.message}
                    >
                      <Box>
                        <Input
                          isFullWidth
                          type="password"
                          id="password"
                          {...register('password', {
                            validate: (value: any) => checkFormPassword(value),
                          })}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      required
                      label="Confirmer votre mot de passe"
                      errorMessage={errors?.confirmPassword?.message}
                    >
                      <Box>
                        <Input
                          isFullWidth
                          type="password"
                          id="confirmPassword"
                          {...register('confirmPassword', {
                            validate: (value: any) => checkFormPassword(value),
                          })}
                        />
                      </Box>
                    </FormControl>
                    <Button mb={10} type="submit" isLoading={isloading}>
                      Créer votre compte
                    </Button>
                    <Text variant="p" color={Theme.colors.error.default}>
                      {errorCGU}
                    </Text>
                  </form>
                  <Box mt={40}>
                    <Divider />
                  </Box>
                  <Text variant="p" color={'#819CAD'}>
                    Vous avez besoin d’aide ?{' '}
                    <Link onClick={() => showContactUsModal()}>
                      Contactez-nous
                    </Link>
                  </Text>
                </Box>
              </Cell>
              <Cell x-span={{ xs: '6' }}>
                <ConnexionIcon
                  style={{
                    height: '100vh',
                    maxHeight: '900px',
                    maxWidth: '1440px',
                  }}
                  width={'100%'}
                />
                <Box
                  position="relative"
                  bottom={130}
                  height={0}
                  left={'60%'}
                  width={'100px'}
                >
                  <LogoFcomIcon fontSize={100} />
                </Box>
              </Cell>
            </Grid>
          </Container>
        </Flex>
      );
    }
    if (status === 'error') {
      return (
        <AccesDenied
          customML="440px"
          customText={
            <Box width={400} pt={20}>
              <Text>
                Le lien d’activation que vous avez utilisé est invalide ou
                expiré. Afin d’en générer un nouveau, veuillez saisir votre
                email dans le champ ci-dessous.
              </Text>
              <FormControl label="email">
                <Input
                  isDisabled={openReCAPTCHA}
                  type="email"
                  onChange={(e: any) => setResendEmail(e?.target?.value)}
                />
              </FormControl>
              {openReCAPTCHA ? (
                <ReCAPTCHA
                  sitekey={REACT_APP_CAPTCHA_KEY as string}
                  onChange={onChangeCaptcha}
                />
              ) : (
                <Button
                  onClick={() => setOpenReCAPTCHA(true)}
                  isDisabled={
                    resendEmail?.length === 0 || !checkEmail(resendEmail)
                  }
                >
                  Renvoyer le lien
                </Button>
              )}
            </Box>
          }
        />
      );
    } else {
      return <></>;
    }
  }
};
export default CreatePassword;
