import React, { useEffect, useCallback, useRef, useState } from 'react';
import {
  getBuildFront,
  REACT_APP_SEED_BUILD_ID,
  REACT_APP_SEED_STAGE_NAME,
  CHECK_BUILD_BEFORE_MS,
} from '@commons';
import { showDialogModal } from 'components/modals/DialogModal';

type Props = {
  children: React.ReactNode;
};

export const CheckBuild = ({ children }: Props) => {
  const [isFirstCheck, setIsFirstCheck] = useState(true);
  const [modalBuildDeprectedOpen, setModalBuildDeprectedOpen] = useState(false);
  const intervalRef = useRef();
  const getTimeLeft = useCallback(async () => {
    try {
      const buildVersion = await getBuildFront();
      if (
        buildVersion &&
        String(buildVersion) !== String(REACT_APP_SEED_BUILD_ID) &&
        !modalBuildDeprectedOpen
      ) {
        onOpenModalBuildDepracted();
      }
    } catch (e) {
      console.warn('API BUILD NUMBER NOT FOUND', e);
    }
  }, [modalBuildDeprectedOpen]);
  const handleRefreshClick = async () => {
    //Delete navigation caches
    caches.keys().then(keyList => {
      return Promise.all(
        keyList.map(key => {
          return caches.delete(key);
        })
      );
    });
  };
  const onOpenModalBuildDepracted = useCallback(async () => {
    setModalBuildDeprectedOpen(true);
    await showDialogModal({
      title: 'Nouvelle Version',
      text: `Une nouvelle version de Connecteed est disponible !`,
      confirmLabel: 'Mettre à jour',
    }).then(async action => {
      if (action) {
        setModalBuildDeprectedOpen(false);
        await handleRefreshClick();
        window.location.reload();
      }
    });
  }, []);
  useEffect(() => {
    if (
      REACT_APP_SEED_STAGE_NAME &&
      REACT_APP_SEED_STAGE_NAME !== 'localhost'
    ) {
      if (isFirstCheck) {
        setIsFirstCheck(false);
        getTimeLeft();
      } else {
        //@ts-ignore
        intervalRef.current = setInterval(getTimeLeft, CHECK_BUILD_BEFORE_MS);
        return () => {
          clearInterval(intervalRef.current);
        };
      }
    }
  }, [getTimeLeft, isFirstCheck]);

  return <>{children}</>;
};

export default React.memo(CheckBuild);
