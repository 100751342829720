import { snakeCase } from 'lodash';
import { ECraStatus, EInvoiceStatus } from '@freelancelabs/teoreme-commons';

export const getSelectByStatus = (
  row: any,
  selectByStatus: any
): boolean | null => {
  for (let i = 0; i < selectByStatus.length; i++) {
    let valueFinded = row?.original;
    if (!valueFinded) {
      return null;
    }
    for (let y = 0; y < selectByStatus[i].keys.length; y++) {
      const key = selectByStatus[i].keys[y];
      if (valueFinded[key]) {
        valueFinded = valueFinded[key];
      }
      if (y === selectByStatus[i].keys.length - 1) {
        for (let z = 0; z < selectByStatus[i].values.length; z++) {
          if (Array.isArray(valueFinded)) {
            const isFind = valueFinded.findIndex(
              e => e === selectByStatus[i].values[z]
            );
            if (isFind !== -1) {
              return true;
            }
          } else {
            if (selectByStatus[i].values[z] === valueFinded) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
};

export const getFiltredColumns = (columns: any, hiddenCols: any): any => {
  if (Array.isArray(hiddenCols)) {
    return columns.filter((col: any) => {
      for (let i = 0; i < hiddenCols.length; i++) {
        if (hiddenCols[i] === col.accessor) {
          return false;
        }
      }
      return true;
    });
  }
  return columns;
};

export const convertFilterToState = (filter: string) => {
  if (typeof filter === 'string') {
    const state = filter.replaceAll('-', '_').toUpperCase();
    return state;
  }
  return filter;
};

export enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}

export const getSorted = (
  order: sortType,
  sortedColumn: string | null
): string | undefined => {
  if (sortedColumn) {
    return sortedMap[order] + sortedColumn;
  }
  return undefined;
};

const sortedMap = {
  desc: '-',
  asc: '',
};

export const mapSortByToApi = (sortBy: any) => {
  const sortedBy = sortBy
    .map(
      ({ desc, id }: { desc: boolean; id: string }) => `${desc ? '-' : ''}${id}`
    )
    .join('');

  return sortedBy.includes('month')
    ? `${sortedBy?.replaceAll('month', 'normalizedDate')} uuid`
    : sortedBy;
};

/**
 * getFilterByUrl types
 */
type StatusType = EInvoiceStatus | ECraStatus | 'ALL';

/**
 * Convert url status to api status
 * ex : /to-be-submited => TO_BE_SUBMITED
 * defaultStatus = no match return defaultStatus
 */
export const getFilterByUrl = (
  filterUrl: string,
  states: any,
  defaultStatus: string
): any => {
  const filter = snakeCase(filterUrl).toUpperCase();

  const STATUS = Object.values(states).find(status => status === filter);
  return (STATUS as StatusType) || defaultStatus;
};
