import PrivateRoute from 'components/PrivateRoute';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CreatePassword from './Common/CreatePassword';
import { PageNotFound } from './Common/PageNotFound';
import ResetPassword from './Common/ResetPassword';
import ChangeEmail from './Common/ChangeEmail';
import RefreshToken from './Common/RefreshToken';
import Dashboard from './Dashboard';
import { Invoices } from './Invoices/Invoices';
import { Establishments } from './Establishments/Establishments';
import { Contractors } from './Contractors/Contractors';
import Login from './Login/Login';
import { Notifications } from './Notifications';
import OnBoardPage from './OnBoarding';
import { User } from './User';
import { useLocation } from 'react-router-dom';
import { CustomerRoute } from './Customer/Customer';
import { FileReaderRoute } from './FileReader';
import { AnonymeRoute } from './Anonyme';
//@ts-ignore
import Websocket from 'react-websocket';
import {
  queryClient,
  WEBSOCKET_URL,
  cleanSession,
  checkAuthValid,
  useMe,
} from '@commons';
import { useWSNotificationsStore } from 'store/wsNotifications';
import { IWSPayload, EWSPayloadType } from '@freelancelabs/teoreme-commons';

export const Router: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { updateWSNotifications } = useWSNotificationsStore();
  const isAuth = checkAuthValid();
  const location = useLocation();
  const { me } = useMe();
  let useWebSocket = false;
  if (isAuth && location.pathname !== '/login' && localStorage.token) {
    useWebSocket = true;
  }
  const setNewNotification = React.useCallback(async (): Promise<void> => {
    updateWSNotifications({
      newMessage: true,
    });
  }, [updateWSNotifications]);
  const ableToAccessSite = me?.onboardingStep?.ableToAccessSite;
  // clean session on customer routes
  if (
    location?.pathname?.includes('/customer/cra/validation') &&
    localStorage?.token
  ) {
    cleanSession();
  }
  return (
    <>
      <Switch>
        <Route
          exact
          path="/login"
          render={props =>
            isAuth && me ? (
              <Redirect to={ableToAccessSite ? '/dashboard' : '/on-boarding'} />
            ) : (
              <Login />
            )
          }
        />
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/activate/:token">
          <CreatePassword />
        </Route>
        <Route exact path="/reset-password/:token">
          <ResetPassword />
        </Route>
        <Route exact path="/refresh-success">
          <RefreshToken />
        </Route>
        <Route exact path="/confirm-change-email/:token">
          <ChangeEmail />
        </Route>
        <Route exact path="/customer/cra/validation/:token">
          <CustomerRoute />
        </Route>
        <Route exact path="/anonyme/:token">
          <AnonymeRoute />
        </Route>

        <Route exact path="/404" component={PageNotFound} />
        <PrivateRoute exact path="/on-boarding" component={OnBoardPage} />
        <PrivateRoute path="/user" component={User} />
        <PrivateRoute path="/headquarter/:id" component={Establishments} />
        <PrivateRoute path="/contractors" component={Contractors} />
        <PrivateRoute path="/invoices" component={Invoices} />
        <PrivateRoute path="/notifications" component={Notifications} />
        <PrivateRoute
          exact
          path="/file/:fileLocation"
          component={FileReaderRoute}
        />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        {isAuth && me ? (
          <Redirect
            from="/"
            to={ableToAccessSite ? '/dashboard' : '/on-boarding'}
          />
        ) : (
          <Redirect from="/" to="/login" />
        )}
        {/*<PrivateRoute exact path="/" component={Dashboard} />*/}
        <Redirect from="*" to="/404" />
      </Switch>
      {useWebSocket && (
        <Websocket
          onOpen={(a: any) => {
            // console.log('OnOpen WS ', a);
          }}
          onClose={() => {
            // console.log('onClose WS ');
          }}
          url={`${WEBSOCKET_URL}?token=${localStorage.token}`}
          onMessage={(data: IWSPayload) => {
            if (data?.type === EWSPayloadType.NOTIFICATION) {
              setNewNotification();
              queryClient.refetchQueries({
                queryKey: ['notifications'],
                type: 'active',
              });
            }
          }}
          debug={true}
        />
      )}
    </>
  );
};

Router.displayName = 'Router';
