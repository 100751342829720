import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ActivitiesList } from 'routes/Invoices/Activities/List';
import { ActivitiyDetails } from 'routes/Invoices/Activities/Details';
import { Container } from 'components/ui';
import { InvoicesDetails } from './InvoicesDetails';
export const Activities = () => {
  return (
    <Switch>
      <Route path="/invoices/activities/billing/:missionRef/:month">
        <Container>
          <InvoicesDetails />
        </Container>
      </Route>
      <Route path="/invoices/activities/list/:filter(pending|validated)">
        <Container>
          <ActivitiesList />
        </Container>
      </Route>
      <Route path="/invoices/activities/:missionRef/:month">
        <Container>
          <ActivitiyDetails />
        </Container>
      </Route>
    </Switch>
  );
};
