import { useEffect } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { showDialogModal } from './DialogModal';
import { usePreventClose } from './ModalFrame';

const prompt = (event: BeforeUnloadEvent) => {
  event.returnValue = `Êtes-vous sûr(e) de vouloir quitter cette page sans enregistrer ?`;
};

export const ConfirmNavigationModal = ({ active }: { active: boolean }) => {
  usePreventClose()?.(active);
  useEffect(() => {
    if (active) window.addEventListener('beforeunload', prompt);
    return () => window.removeEventListener('beforeunload', prompt);
  }, [active]);

  return (
    <NavigationPrompt
      disableNative
      renderIfNotActive={false}
      when={(crntLocation, nextLocation) => {
        return active && crntLocation.pathname !== nextLocation?.pathname;
      }}
    >
      {({ onCancel, onConfirm }) => {
        showDialogModal({
          title:
            'Êtes-vous sûr(e) de vouloir quitter cette page sans enregistrer ?',
          text: 'Toutes vos modifications seront perdues.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
        }).then(confirm => {
          if (confirm) onConfirm();
          else onCancel();
        });

        return null;
      }}
    </NavigationPrompt>
  );
};
