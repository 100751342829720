import { Flex, SpinnerBox } from 'components/ui';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  useMe,
  deleteAllCognitoCookies,
  queryClient,
  cleanSession,
  getMe,
} from '@commons';
import { jwtDecode } from 'jwt-decode';

export const AnonymeRoute = () => {
  const [hasSetToken, setHasSetToken] = useState(false);
  //@ts-ignore
  const { token } = useParams();
  const history = useHistory();
  const { me } = useMe({ retry: hasSetToken });

  useEffect(() => {
    if (localStorage?.token) {
      if (localStorage?.token !== token) {
        localStorage.clear();
        queryClient.setQueryData(['me'], null);
        queryClient.clear();
        // console.log('CHECK AUTH CLEAR TOKEN != TOKEN', {
        //   localStorage: localStorage?.token,
        //   token: token,
        // });
      }
    }
  }, []);
  useEffect(() => {
    if (token) {
      localStorage.isImpersonate = 'true';
      if (hasSetToken) {
        if (localStorage?.token === token) {
          history.push('/login');
        }
      } else {
        try {
          let jwt = jwtDecode(token) as any;
          const exp = Number(jwt?.exp || 0);
          localStorage.token = token;
          localStorage.exp = exp;
          localStorage.cognitoUserId = jwt?.username;
          localStorage.isImpersonate = 'true';
          setHasSetToken(true);
        } catch (e) {
          localStorage.clear();
          queryClient.setQueryData(['me'], null);
          queryClient.clear();
          history.push('/404');
        }
      }
    }
  }, [token, hasSetToken]);

  return (
    <Flex
      top={0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <SpinnerBox />
      </Flex>
    </Flex>
  );
};
export default AnonymeRoute;
