import React, { ReactElement, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { boxProperties, BoxProps } from './Box';

type TabProps = {
  isSelected?: boolean;
  variant?: 'primary' | 'secondary';
  href?: string; // Maybe to deprecate
  children?: ReactNode;
  icon?: ReactElement;
} & BoxProps;

export const StyledTab = styled.div<TabProps>`
  display: flex;
  align-items: center;
  padding: 0 20px;

  text-decoration: none;
  line-height: normal;
  font-size: 16px;
  color: ${p => p.theme.colors.text.primary};

  letter-spacing: normal;
  height: 80px;
  white-space: nowrap;

  ${props =>
    props.variant === 'secondary' &&
    css`
      height: 60px;
    `}

  ${props => props.variant === 'primary' && css``}

  ${props =>
    props.isSelected &&
    css`
      color: ${p => p.theme.colors.primary.default};
      box-shadow: 0 -4px 0 0 ${props.theme.colors.primary.default} inset;

      svg {
        opacity: 1 !important;
      }
    `}

  &:hover {
    color: ${p => p.theme.colors.primary.default};
  }

  cursor: pointer;

  ${boxProperties}

  svg {
    margin-right: 10px;
    opacity: 0.4;
  }
  @media (max-width: 1439px) {
    svg {
      display: none;
    }
  }
`;

export const Tab = ({
  href,
  isSelected,
  children,
  icon,
  ...props
}: TabProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const onNavigate = () => !!href && history.push(href);

  const routeIsSelected = pathname.includes(href || '');

  return (
    <StyledTab
      onClick={onNavigate}
      isSelected={href ? routeIsSelected : isSelected}
      {...props}
    >
      {icon}
      {children}
    </StyledTab>
  );
};

Tab.defaultProps = {
  variant: 'primary',
};
