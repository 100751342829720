import { Header } from 'components/Header';
import { getFullName, getTradeNameSafe, useContractorFindOne } from '@commons';
import { EContractorStatus } from '@freelancelabs/teoreme-commons';
import {
  SubItem,
  Grid,
  Cell,
  Flex,
  Box,
  Text,
  Status,
  Spinner,
} from 'components/ui';
import { TextInformation } from 'components/TextInformation';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ContractorIcon } from 'components/icons';
import { ContractorMissions } from './Missions';
import { ContractorEstablishments } from './Establishments';
import { ContractorInvoices } from './Invoices';
type ContractorDetailsProps = {};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const ContractorDetails: React.FC<
  React.PropsWithChildren<ContractorDetailsProps>
> = () => {
  const theme = useTheme();
  const [establishmentSelected, setEstablishmentSelected] = React.useState<
    null | any
  >(null);
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { data: contractor, isFetching } = useContractorFindOne(id);
  const path = `/contractors/${id}`;
  const estaLinked = contractor?.scopedData;
  const establishment = estaLinked?.[0]?.establishment;
  const tabItems = (
    <>
      {estaLinked && establishment && (
        <SubItem disableArrow variant="primary" href={`${path}/establishments`}>
          {estaLinked?.length > 1
            ? 'Établissements'
            : getTradeNameSafe(establishment)}
        </SubItem>
      )}
      {/* <SubItem disableArrow variant="primary" href={`${path}/missions`}>
        Toutes les missions
      </SubItem>
      <SubItem disableArrow variant="primary" href={`${path}/cra-invoices`}>
        Tous les CRA et Factures
      </SubItem> */}
    </>
  );
  const getStatus = (): { variantColor: any; text: string } => {
    const scopedDataSelected =
      estaLinked?.[
        estaLinked.findIndex(
          esta => esta?.establishment?.uuid === establishmentSelected
        )
      ];

    if (scopedDataSelected?.disabled === true) {
      return { variantColor: 'error', text: 'Désactivé' };
    }
    switch (contractor?.status) {
      case EContractorStatus.ACTIVE:
        return { variantColor: 'success', text: 'Actif' };
      case EContractorStatus.PENDING:
        return { variantColor: 'warning', text: 'En attente de validation' };
      case EContractorStatus.REJECTED:
        return { variantColor: 'error', text: 'Refusé' };
      case EContractorStatus.ARCHIVED:
        return { variantColor: 'grey', text: 'Archivé' };
    }
    return { variantColor: '', text: '' };
  };
  if (isFetching) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  } else {
    return (
      <Box width={1 / 1}>
        <Box>
          <Header
            height={'200px'}
            tabs={tabItems}
            headband={
              <Flex
                alignContent="center"
                alignItems="center"
                flexWrap="wrap"
                width={1 / 1}
                height={120}
                backgroundColor={theme.colors.primary.inputBackGround}
                mt={20}
                mb={20}
              >
                <Box width={'80px'} ml={40}>
                  <ContractorIcon fontSize={80} />
                </Box>
                <Flex
                  alignContent="center"
                  alignItems="center"
                  flexWrap="wrap"
                  width={'calc(100% - 120px)'}
                >
                  <Flex width={1 / 1} justifyContent="flex-start" ml={40}>
                    <Box width={7 / 10} mb={'5px'}>
                      <Text variant="h1">{getFullName(contractor)}</Text>
                    </Box>

                    {contractor && (
                      <Box mr={20} mt={20}>
                        <Status
                          fontWeight="bold"
                          fontSize={16}
                          ml={'40px'}
                          variantColor={getStatus()?.variantColor}
                        >
                          {getStatus()?.text}
                        </Status>
                      </Box>
                    )}
                  </Flex>
                  {/* TODO WAIT APP TALENT READY !! */}
                  {/* {contractor && contractor?.user?.email && (
                    <Box width={7 / 10} mb={'5px'} ml={40}>
                      <Text variant="p">
                        Email compte talent : <b>{contractor?.user?.email}</b>
                      </Text>
                    </Box>
                  )} */}
                  {/* {contractor?.sendAppInvitation !== true && (
                    <Link
                      ml={30}
                      onClick={() =>
                        showSendAppInvitationModal({
                          contractor: contractor as IJoinedContractor,
                        })
                      }
                      iconLeft={<AddIcon />}
                    >
                      Donner accès à l'application mobile talents à cet
                      intervenant
                    </Link>
                  )} */}
                </Flex>
              </Flex>
            }
          ></Header>
          {contractor && (
            <Grid mt={80}>
              <Cell x-span={{ xs: '12' }}>
                {contractor?.status === EContractorStatus.REJECTED &&
                  contractor?.rejectReason && (
                    <TextInformation variant="error" width={1 / 1} mb={60}>
                      {`L'intervenant a été refusé le ${new Date(
                        contractor?.statusChangedAt as Date
                      )?.toLocaleDateString()} pour la raison suivante : ${contractor?.rejectReason}`}
                    </TextInformation>
                  )}
              </Cell>
              <Cell x-span={{ xs: '12' }}>
                {tab === 'establishments' && (
                  <ContractorEstablishments
                    contractor={contractor}
                    onChangeEstaSelected={esta =>
                      setEstablishmentSelected(esta)
                    }
                  />
                )}
                {tab === 'missions' && (
                  <ContractorMissions contractor={contractor} />
                )}
                {tab === 'cra-invoices' && (
                  <ContractorInvoices contractor={contractor} />
                )}
              </Cell>
            </Grid>
          )}
        </Box>
      </Box>
    );
  }
};

ContractorDetails.displayName = 'ContractorDetails';

export default ContractorDetails;
