import { ReactNode } from 'react';
import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

type TagProps = {
  children?: ReactNode;
  variantColor?: 'primary' | 'grey' | 'error' | 'success' | 'warning' | 'grey';
};
export const Tag = styled.span<BoxProps & TagProps>`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 12px;
  color: ${props => props.theme.colors.primary.white};
  background-color: ${props =>
    props.theme.colors[props.variantColor || 'primary'].default};
  ${boxProperties}
  border-radius: 30px;
`;
