import { useEstablishmentFindManyRCPRO } from '@commons';
import { mapSortByToApi } from 'helpers/table';
import { useRCPROListStore } from 'store/rcpro';

export const useFetchData = ({ pageSize, sortBy }: any) => {
  const { pageIndex, filterObject, uuid } = useRCPROListStore();
  const { data, status, isFetching } = useEstablishmentFindManyRCPRO({
    uuid: uuid as string,
    filterObject,
    sort: mapSortByToApi(sortBy),
    limit: pageSize,
    skip: pageIndex * pageSize,
  });
  return {
    data,
    status,
    isFetching,
  };
};
