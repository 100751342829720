import {
  Box,
  boxProperties,
  BoxProps,
  BreadCrumb,
  Container,
  Flex,
} from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { ReactNode } from 'react';
import styled from 'styled-components';

const HeaderStyled = styled.div<BoxProps>`
  width: 100%;
  position: relative;
  height: ${p => p.height || '160px'};
  ${boxProperties}
  z-index:1;
`;
type HeaderProps = BoxProps & {
  actions?: ReactNode;
  children?: ReactNode;
  tabs?: ReactNode;
  pre?: ReactNode;
  headband?: ReactNode;
};

export const Header = ({
  actions,
  children,
  tabs,
  pre,
  headband,
  ...props
}: HeaderProps) => {
  const { crumbs } = useBreadCrumb();
  return (
    <HeaderStyled {...props}>
      <Container height="100%">
        <Flex position="relative" left={0} top={0} mb={20}>
          <BreadCrumb crumbs={crumbs} />
        </Flex>
        {(actions || children) && (
          <Flex alignItems="center">
            {children && (
              <Box mb={20} flex={1}>
                {children}
              </Box>
            )}
            {actions && <Box>{actions}</Box>}
          </Flex>
        )}
        {headband && <Flex>{headband}</Flex>}
        <Flex mt={20} mb={20}>
          {tabs}
        </Flex>
      </Container>
    </HeaderStyled>
  );
};
