import React from 'react';
import { Box, Flex } from 'components/ui';
import styled from 'styled-components';

type RoundedButtonProps = {
  currentPage: boolean;
};
export const RoundedButton = styled.button<RoundedButtonProps>`
  border-radius: ${p => (p.currentPage ? '50%' : 'none')};
  background-color: ${p =>
    p.currentPage ? p.theme.colors.primary.default : 'white'};
  color: ${p => (p.currentPage ? 'white' : p.theme.colors.primary.default)};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: 30px;
  height: 30px;
  margin-left: 5px;
`;
type ArrowsProps = {
  disabled: boolean;
  direction: 'up' | 'left' | 'right' | 'down';
};

export const Arrows = styled.button<ArrowsProps>`
  color: ${p => (!p.disabled ? p.theme.colors.primary.default : 'grey')};
  font-size: 24px;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  /*${p =>
    p.direction === 'right' &&
    `transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);`}*/
  /*${p =>
    p.direction === 'left' &&
    `transform: rotate(135deg);
    -webkit-transform: rotate(135deg);`}*/
  ${p =>
    p.direction === 'up' &&
    `transform: rotate(180deg);
    -webkit-transform: rotate(180deg);`}
  ${p =>
    p.direction === 'down' &&
    `transform: rotate(0deg);
    -webkit-transform: rotate(0deg);`}
`;

export const Pagination: React.FC<React.PropsWithChildren<any>> = ({
  gotoPage,
  pageIndex,
  pageCount,
  pageSize,
  pageOptions,
  canNextPage,
  canPreviousPage,
  setPageSize,
  totalCount,
  labelData,
}) => (
  <Box>
    <Flex className="pagination" justifyContent="space-between">
      <Box mt={20}>
        <span>
          Page <b>{pageIndex + 1}</b> sur <b>{pageOptions.length}</b> -{' '}
          <b>{totalCount}</b> {labelData}
        </span>
      </Box>
      <Flex mt={20}>
        <Arrows
          direction="left"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </Arrows>
        <Arrows
          direction="left"
          onClick={() => gotoPage(pageIndex - 1)}
          disabled={!canPreviousPage}
        >
          {'<'}
        </Arrows>{' '}
        <Flex>
          {pageOptions.map((page: number, index: number) => {
            if (
              pageIndex === index - 1 ||
              pageIndex === index + 1 ||
              pageIndex === index - 2 ||
              pageIndex === index + 2 ||
              pageIndex === index
            ) {
              return (
                <RoundedButton
                  key={index}
                  currentPage={index === pageIndex}
                  onClick={() => gotoPage(index)}
                >
                  {index + 1}
                </RoundedButton>
              );
            } else {
              return <div key={index}></div>;
            }
          })}
        </Flex>
        <Arrows
          direction="right"
          onClick={() => gotoPage(pageIndex + 1)}
          disabled={!canNextPage}
        >
          {'>'}
        </Arrows>{' '}
        <Arrows
          direction="right"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </Arrows>
      </Flex>

      <select
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 20, 50, 100].map((pageSize, i) => (
          <option key={i} value={pageSize}>
            Voir {pageSize}
          </option>
        ))}
      </select>
    </Flex>
  </Box>
);
