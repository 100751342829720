import { resetPassword } from '@commons';
import { ETeoremeSpaces } from '@freelancelabs/teoreme-commons';
import { Button, Flex, FormControl, Input, Text } from 'components/ui';
import { showEmailHasSendModalModal } from 'components/modals/EmailHasSend';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormValues = {
  email: string;
};

type ForgetPasswordFormProps = {
  onSubmit?: () => void;
  onClose: () => void;
};

export const ForgetPasswordForm = ({
  onClose,
}: // onSubmit: onSubmitParent,
ForgetPasswordFormProps) => {
  const [loading, setLoading] = useState(false);
  // const showMessage = useShowMessage();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  // const { errors } = useFormState();
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      await resetPassword(
        { email: formValues.email, targetSpace: ETeoremeSpaces.PROVIDER },
        true
      );
    } catch {}
    showEmailHasSendModalModal().then(async () => {
      onClose();
    });
    setLoading(false);
  };
  const checkEmail = (value: string): string | undefined => {
    const regex = /[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i;
    if (regex.test(value)) {
      return undefined;
    } else {
      return 'Veuillez entrer une adresse e-mail valide';
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text mb={20} variant="h3">
        Réinitialiser le mot de passe
      </Text>
      <Text mb={20} variant="p">
        Saisissez l’adresse email associée à votre compte. Nous vous enverrons
        un lien sécurisé par email pour réinitialiser votre mot de passe.
      </Text>
      <Text>
        <Text mb={10}>
          Si votre email est utilisé sur d’autres applications du groupe
          <b> Freelance.com</b> (ex: Portail Partenaire) le changement de mot de
          passe sera effectif pour toutes les applications.
        </Text>
      </Text>
      <FormControl required label="Email" errorMessage={errors?.email?.message}>
        <Input
          isFullWidth
          id="email"
          type="email"
          {...register('email', {
            required: 'Ce champ est requis',
            validate: value => checkEmail(value),
          })}
        />
      </FormControl>
      <Flex justifyContent="center">
        <Button width={1 / 1} key="submit" type="submit" isLoading={loading}>
          Envoyer le lien
        </Button>
      </Flex>
    </form>
  );
};
