import React, { forwardRef } from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

type TextAreaProps = {
  isFullWidth?: boolean;
  variantColor?: 'primary' | 'error';
  isDisabled?: boolean;
  placeholder?: string;
} & Pick<
  TextareaAutosizeProps,
  | 'maxRows'
  | 'value'
  | 'onChange'
  | 'name'
  | 'minRows'
  | 'inputRef'
  | 'name'
  | 'disabled'
>;

const TextAreaContainer = styled.div<TextAreaProps>`
  position: relative;
  ${props => props.isFullWidth && 'width : 100%;'}
`;

const StyledTextArea = styled(TextareaAutosize)<TextAreaProps & BoxProps>`
  min-height: 60px;
  border-radius: 4px;
  border: solid 1px #b4c6d3;
  color: #004269;
  background-color: #fff;
  padding: 15px;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-top: 18px;
  line-height: 1.5;
  overflow: hidden;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'initial')};
  background-color: ${props =>
    props.disabled
      ? props.theme.colors.disabled.inputBackGround
      : props.theme.colors[props.variantColor || 'primary'].inputBackGround};
  resize: none;

  &:focus {
    border-color: ${props =>
      props.theme.colors[props.variantColor || 'primary'].default};
  }

  ${boxProperties}
`;

export const TextArea = forwardRef(
  (
    {
      variantColor,
      isFullWidth,
      isDisabled,
      value,
      onChange,
      maxRows,
      minRows,
      name,
      placeholder,
      ...props
    }: TextAreaProps & BoxProps,
    ref
  ) => {
    return (
      <TextAreaContainer
        variantColor={variantColor}
        isFullWidth={isFullWidth}
        {...props}
      >
        <StyledTextArea
          placeholder={placeholder}
          inputRef={ref as any}
          disabled={isDisabled}
          value={value}
          onChange={onChange}
          maxRows={maxRows}
          minRows={minRows}
          name={name}
        ></StyledTextArea>
      </TextAreaContainer>
    );
  }
);
TextArea.defaultProps = {
  variantColor: 'primary',
  fontSize: 16,
};

TextArea.displayName = 'TextArea';
