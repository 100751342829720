import { EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import { useInvoiceUpdateOne } from '@commons';
import { useShowMessage } from 'hooks';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFetchData } from './api';
import { getFilterByUrl } from 'helpers/table';
import { useCreditNoteListStore } from 'store/CreditNote';
import { columns, settingsByFilter } from './TableConfig';
import { getHiddenColumns } from './helpers';
import { snakeCase } from 'lodash';
export const useLogic = () => {
  const theme = useTheme();
  const t = useTranslationFormat();
  const { filter } = useParams<{ filter: string }>();

  useSetCrumbs(
    [filter],
    [
      { label: 'CRA & Factures', path: '/invoices' },
      { label: 'Avoirs', path: '/invoices/credit-note' },
      {
        label: t(
          `status:${snakeCase(filter).toLocaleUpperCase()}`,
          'capitalize-first'
        ),
        path: '/invoices/credit-note/' + filter,
      },
    ]
  );

  const {
    updateCreditNoteStore,
    resetCreditNoteStore,
    startDate,
    endDate,
    selectedStatus,
    searchQuery,
    pageIndex,
    selectedRowIds,
  } = useCreditNoteListStore();

  const filterStatus = getFilterByUrl(
    filter,
    EInvoiceStatus,
    EInvoiceStatus.TO_BE_SUBMITTED
  );
  const showMessage = useShowMessage();
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const windowSize = useWindowSize();

  const history = useHistory();

  React.useEffect(() => {
    return history.listen(history => {
      const filter = history.pathname.split('/').slice(-1).pop() || '';
      const filterStatus = getFilterByUrl(
        filter,
        EInvoiceStatus,
        EInvoiceStatus.VALIDATED
      );
      //@ts-ignore
      const columnsHidden = settingsByFilter?.[filterStatus]?.columnsHidden;
      resetCreditNoteStore({ columnsHidden });
    });
  }, [history]);

  const [fetchInfo, setFetchInfo] = React.useState({
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'month', desc: true }],
  });

  const { data, isFetching, status } = useFetchData(fetchInfo);

  const selectableInvoice = data.invoices
    .filter(({ status }) => status === EInvoiceStatus.TO_BE_SUBMITTED)
    .map(({ uuid }) => uuid);

  const isSelectAll = selectableInvoice.length ? undefined : 'selection';

  return {
    t,
    updateCreditNoteStore,
    theme,

    columnsHidden: [
      //@ts-ignore
      ...settingsByFilter[filterStatus].columnsHidden,
      isSelectAll,
      // @ts-ignore
      ...getHiddenColumns(windowSize.width),
    ],
    columns,
    isFetching,
    data,
    status,
    setFetchInfo,
    updateInvoice,
    showMessage,
    selectedRowIds,

    filterStatus,
    searchQuery,
    startDate,
    endDate,
    selectedStatus,
    selectableInvoice,

    pageIndex,
    history,
  };
};
