import * as React from 'react';

function CloudDownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9 7.33709C12.4467 5.03709 10.4267 3.31042 8 3.31042C6.07333 3.31042 4.4 4.40376 3.56667 6.00376C1.56 6.21709 0 7.91709 0 9.97709C0 12.1838 1.79333 13.9771 4 13.9771H12.6667C14.5067 13.9771 16 12.4838 16 10.6438C16 8.88376 14.6333 7.45709 12.9 7.33709ZM12.6667 12.6438H4C2.52667 12.6438 1.33333 11.4504 1.33333 9.97709C1.33333 8.61042 2.35333 7.47042 3.70667 7.33042L4.42 7.25709L4.75333 6.62376C5.38667 5.40376 6.62667 4.64376 8 4.64376C9.74667 4.64376 11.2533 5.88376 11.5933 7.59709L11.7933 8.59709L12.8133 8.67042C13.8533 8.73709 14.6667 9.61042 14.6667 10.6438C14.6667 11.7438 13.7667 12.6438 12.6667 12.6438ZM5.33333 9.31042H7.03333V11.3104H8.96667V9.31042H10.6667L8 6.64376L5.33333 9.31042Z"
        fill="#808080"
      />
    </svg>
  );
}

export default CloudDownloadIcon;
