import { cutLongText, uploadFile, validateFormPhoneFormatFR } from '@commons';
import { EFileType } from '@freelancelabs/teoreme-commons';
import { DownloadIcon } from 'components/icons';
import {
  Box,
  Cell,
  CheckSwitch,
  Divider,
  FileInput,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Link,
  PhoneInputControlled,
  Radio,
  Spinner,
  Text,
} from 'components/ui';
import React from 'react';

const grid = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};

type FormValues = any;

type SignatoryInformationFormProps = {
  onBoardingData?: any;
  form: FormValues & any;
  formInfos: any;
  onChangeIsCorporateOfficer: (value: boolean) => void;
  isLoadingDelegatedOrder: (value: boolean) => void;
};

export const SignatoryInformationForm = ({
  onChangeIsCorporateOfficer,
  formInfos,
  onBoardingData,
  isLoadingDelegatedOrder,
  form: {
    formState: { errors, isDirty, isValid },
    register,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
  },
}: SignatoryInformationFormProps) => {
  const onBoardingEstablishment =
    onBoardingData?.establishment && onBoardingData?.establishment.length > 0
      ? onBoardingData?.establishment[0]
      : undefined;

  const signOfficerType = watch('establishment.provider.signOfficerType');
  const [contractFile, setContractFile] = React.useState<File | null>(
    onBoardingEstablishment?.provider?.delegatedSignOfficer?.delegationOrder
  );
  const [loadingFile, setLoadingFile] = React.useState(false);
  const delegatedSignOfficer = watch(
    'establishment.provider.delegatedSignOfficer'
  );
  const delegationOrder = delegatedSignOfficer?.delegationOrder;
  React.useEffect(() => {
    // on change siret reset files
    if (formInfos.displayForms === false) {
      setContractFile(null);
    }
  }, [formInfos]);

  const [isCorporateOfficer, setIsCorporateOfficer] = React.useState(true);
  const handleContractFileChange = async (files: File[]) => {
    isLoadingDelegatedOrder(true);
    setLoadingFile(true);
    setContractFile(files[0]);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        //@ts-ignore
        fileType: EFileType.TEMPORARY,
        actionType: 'upload',
        establishment: onBoardingEstablishment?.uuid || '',
      });
      const delegationOrder = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag,
      };
      setValue(
        'establishment.provider.delegatedSignOfficer.delegationOrder',
        delegationOrder
      );
    } catch (e) {
      //
      setError(
        'establishment.provider.delegatedSignOfficer.delegationOrder',
        e
      );
    }
    setLoadingFile(false);
    isLoadingDelegatedOrder(false);
  };
  const checkEmail = (value: string): string | undefined => {
    const regex = /[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i;
    if (regex.test(value)) {
      return undefined;
    } else {
      return 'Veuillez entrer une adresse e-mail valide';
    }
  };
  return (
    <>
      <Box mt={60} style={{ display: formInfos.displayForms ? '' : 'none' }}>
        <Divider />
        <Text mt={60} mb={60} variant="h1">
          Préparez la signature de votre contrat-cadre
        </Text>
        <Box mt={20}>
          <CheckSwitch
            id="checkOne"
            mb={10}
            onChange={() => {
              clearErrors();
              onChangeIsCorporateOfficer(!isCorporateOfficer);
              setIsCorporateOfficer(!isCorporateOfficer);
            }}
            defaultChecked={isCorporateOfficer}
          >
            <Text variant="h3">
              Vous êtes mandataire social de l’établissement
            </Text>
            <Text variant="p">Nom apparaissant sur le KBIS</Text>
          </CheckSwitch>
        </Box>
        <>
          <Box style={{ display: isCorporateOfficer ? 'none' : '' }}>
            <Box mb={20}>
              <FormLabel mt={60}>Qui signera les contrats ?</FormLabel>
              <Radio
                {...register('establishment.provider.signOfficerType', {
                  required: undefined,
                })}
                value={'CORPORATE_OFFICER'}
                onClick={() => {
                  //clearErrors();
                  setValue(
                    'establishment.provider.signOfficerType',
                    'CORPORATE_OFFICER'
                  );
                }}
              >
                Le mandataire social
              </Radio>
              <Radio
                {...register('establishment.provider.signOfficerType', {
                  required: undefined,
                })}
                value={'DELEGATED_OFFICER'}
                onClick={() => {
                  //clearErrors();
                  setValue(
                    'establishment.provider.signOfficerType',
                    'DELEGATED_OFFICER'
                  );
                  // ON CHANGE TYPE CLEAN FORM
                  setValue('establishment.provider.delegatedSignOfficer', {
                    civility: 1,
                    jobTitle: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    delegationOrder: '',
                  });
                  setContractFile(null);
                }}
              >
                Un tiers muni d'une délégation de signature
              </Radio>
            </Box>
            <Box
              style={{
                display:
                  formInfos.displayForms && !isCorporateOfficer ? '' : 'none',
              }}
            >
              <Text mt={60} variant="h3">
                Informations du mandataire social
              </Text>
              <Box mt={20}>
                <FormControl
                  required
                  label={'Civilité'}
                  errorMessage={
                    errors?.establishment?.provider?.signOfficer?.civility
                      ?.message
                  }
                >
                  <>
                    <Radio
                      name="establishment.provider.signOfficer.civility"
                      {...(formInfos.displayForms && !isCorporateOfficer
                        ? register(
                            'establishment.provider.signOfficer.civility',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )
                        : register(
                            'establishment.provider.signOfficer.civility',
                            { required: undefined }
                          ))}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      name="establishment.provider.signOfficer.civility"
                      {...(formInfos.displayForms && !isCorporateOfficer
                        ? register(
                            'establishment.provider.signOfficer.civility',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )
                        : register(
                            'establishment.provider.signOfficer.civility',
                            { required: undefined }
                          ))}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                </FormControl>
              </Box>
              <Box>
                <Grid cols={12} gap={'20px'}>
                  <Cell x-span={grid}>
                    <FormControl
                      required
                      label="Prénom"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.firstName
                          ?.message
                      }
                    >
                      {formInfos.displayForms && !isCorporateOfficer ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.firstName',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )}
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.firstName',
                            {
                              required: undefined,
                            }
                          )}
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      required
                      label="Nom"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.lastName
                          ?.message
                      }
                    >
                      {formInfos.displayForms && !isCorporateOfficer ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.lastName',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )}
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.lastName',
                            {
                              required: undefined,
                            }
                          )}
                          name="establishment.provider.signOfficer.lastName"
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      required
                      label="Fonction"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.jobTitle
                          ?.message
                      }
                    >
                      {formInfos.displayForms && !isCorporateOfficer ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.jobTitle',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )}
                          name="establishment.provider.signOfficer.jobTitle"
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.jobTitle',
                            {
                              required: undefined,
                            }
                          )}
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      required={signOfficerType === 'CORPORATE_OFFICER'}
                      label="Email"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.email
                          ?.message
                      }
                    >
                      {formInfos.displayForms &&
                      !isCorporateOfficer &&
                      signOfficerType === 'CORPORATE_OFFICER' ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.email',
                            {
                              required: 'Ce champ est requis',
                              validate: (value: string) => checkEmail(value),
                            }
                          )}
                          type="email"
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.email',
                            {
                              required: undefined,
                              validate: undefined,
                            }
                          )}
                          type="email"
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      label="Téléphone"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.phone
                          ?.message
                      }
                    >
                      <PhoneInputControlled
                        control={control}
                        rules={{
                          validate: (value: string) => {
                            return validateFormPhoneFormatFR(value);
                          },
                        }}
                        isFullWidth
                        name="establishment.provider.signOfficer.phone"
                      />
                    </FormControl>
                  </Cell>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              display:
                formInfos.displayForms &&
                !isCorporateOfficer &&
                signOfficerType === 'DELEGATED_OFFICER'
                  ? ''
                  : 'none',
            }}
          >
            <Text variant="h3">
              Informations de la personne habilitée à signer
            </Text>
            <Box mt={20}>
              <FormControl
                required={true}
                label={'Civilité'}
                errorMessage={
                  errors?.establishment?.provider?.delegatedSignOfficer
                    ?.civility?.message
                }
              >
                {formInfos.displayForms &&
                !isCorporateOfficer &&
                signOfficerType === 'DELEGATED_OFFICER' ? (
                  <>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                ) : (
                  <>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: undefined,
                        }
                      )}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: undefined,
                        }
                      )}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                )}
              </FormControl>
            </Box>
            <Grid cols={12} gap={'20px'}>
              <Cell x-span={grid}>
                <FormControl
                  required={true}
                  label="Prénom"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.firstName?.message
                  }
                >
                  {formInfos.displayForms &&
                  !isCorporateOfficer &&
                  signOfficerType === 'DELEGATED_OFFICER' ? (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.firstName',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                    />
                  ) : (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.firstName',
                        {
                          required: undefined,
                        }
                      )}
                    />
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required={true}
                  label="Nom"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.lastName?.message
                  }
                >
                  {formInfos.displayForms &&
                  !isCorporateOfficer &&
                  signOfficerType === 'DELEGATED_OFFICER' ? (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.lastName',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                      name="establishment.provider.delegatedSignOfficer.lastName"
                    />
                  ) : (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.lastName',
                        {
                          required: undefined,
                        }
                      )}
                    />
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  label="Fonction"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.jobTitle?.message
                  }
                >
                  <Input
                    isFullWidth
                    {...register(
                      'establishment.provider.delegatedSignOfficer.jobTitle',
                      {
                        required: undefined,
                      }
                    )}
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required={true}
                  label="Email"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer?.email
                      ?.message
                  }
                >
                  {formInfos.displayForms &&
                  !isCorporateOfficer &&
                  signOfficerType === 'DELEGATED_OFFICER' ? (
                    <Input
                      type="email"
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.email',
                        {
                          required: 'Ce champ est obligatoire',
                          validate: (value: string) => checkEmail(value),
                        }
                      )}
                    />
                  ) : (
                    <Input
                      type="email"
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.email',
                        {
                          required: undefined,
                          validate: undefined,
                        }
                      )}
                    />
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  label="Téléphone"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer?.phone
                      ?.message
                  }
                >
                  <PhoneInputControlled
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        return validateFormPhoneFormatFR(value);
                      },
                    }}
                    isFullWidth
                    name="establishment.provider.delegatedSignOfficer.phone"
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  label="Delégation de signature"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.delegationOrder?.message
                  }
                >
                  <FileInput
                    required
                    label=""
                    accept=".pdf"
                    subLabel=""
                    onSelect={handleContractFileChange}
                  >
                    {loadingFile && <Spinner />}

                    <Link iconLeft={<DownloadIcon />}>
                      {cutLongText(
                        contractFile?.name ? contractFile.name : '',
                        20
                      ) ||
                        `${
                          delegationOrder
                            ? `Modifier le fichier '${cutLongText(
                                delegationOrder.fileName,
                                20
                              )}' `
                            : 'Ajouter une delégation de signature'
                        }`}
                    </Link>
                  </FileInput>
                </FormControl>
              </Cell>
            </Grid>
          </Box>
        </>
      </Box>
    </>
  );
};

export default SignatoryInformationForm;
