import {
  IEstablishment,
  IEstablishmentBase,
  ESignOfficerType,
} from '@freelancelabs/teoreme-commons';
import { EContactReason } from '@freelancelabs/teoreme-commons/dist/lib/models/enum/contact.enum';
import { establishmentLookupIdentifier, updateOnBoardingStep } from '@commons';
import { useMe, useEstablishmentUpdateOne } from '@commons';
import { showLinkedEstablishmentModal } from 'components/modals/LinkedEstablishmentModal';
import {
  Box,
  Button,
  Cell,
  FormControl,
  Grid,
  Input,
  Text,
  Link,
} from 'components/ui';
import { format } from 'date-fns';
import { VerifySiretForm } from 'forms/VerifySiretForm';
import React from 'react';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Theme } from 'styles';

type FormValues = any;

type EstablishmentFormProps = {
  onChangeEstablishment: (establishment: any) => void;
  onSubmit?: () => void;
  refreshStep: () => void;
  onBoardingData: any;
  formInfos: any;
  form: FormValues & any;
};

const grid = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};

export const EstablishmentForm = ({
  onChangeEstablishment,
  onBoardingData,
  refreshStep,
  formInfos,
  form: {
    formState: { errors, isDirty },
    register,
    setValue,
    clearErrors,
    reset,
  },
}: EstablishmentFormProps) => {
  const { me } = useMe();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notDiffusible, setNotDiffusible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [establishment, setEstablishment] = React.useState<
    IEstablishment | IEstablishmentBase | false
  >(
    onBoardingData?.establishment && onBoardingData?.establishment.length > 0
      ? onBoardingData?.establishment[0]
      : false
  );
  const [isFetchingCheckEsta, setIsFetchingCheckEsta] = React.useState(false);
  const [newEsta, setNewEsta] = React.useState(false);
  const [errorEstablishment, setErrorEstablishment] = React.useState<
    React.ReactNode | false
  >(false);
  const [warningEstablishment, setwarningEstablishment] = React.useState<
    React.ReactNode | false
  >(false);
  const displayForm =
    !errorEstablishment &&
    establishment &&
    (establishment.siret || establishment.identifier)
      ? true
      : false;
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const onCheckIdentifier = async ({ identifier }: { identifier?: string }) => {
    if (!identifier) return;
    try {
      setIsFetchingCheckEsta(true);
      setNewEsta(false);
      setEstablishment(false);
      setErrorEstablishment(false);
      setwarningEstablishment(false);
      setNotDiffusible(false);
      const establishment = await establishmentLookupIdentifier(identifier);
      setIsFetchingCheckEsta(false);
      if (
        //@ts-ignore
        establishment?.errorCode === 'INSI-COMP-003' &&
        !establishment.closedSince
      ) {
        setErrorEstablishment(
          <Text variant="p" color={Theme?.colors?.warning?.default}>
            L'établissement à fait valoir son droit à ne pas faire partie de la
            diffusion dans les fichiers de l'Insee, conformément à l'article
            A123-96 du code de commerce. Il est ainsi impossible de récupérer
            les informations de cet établissement du répertoire SIRENE. Afin de
            rajouter cet établissement dans Connecteed, veuillez contacter un
            chargé de compte ou envoyer un message via le{' '}
            <Link
              fontSize={14}
              onClick={() =>
                showContactUsModal({
                  contactReason: EContactReason.OTHER,
                })
              }
            >
              formulaire de contact.
            </Link>
          </Text>
        );
        setValue('establishment', undefined);
        setEstablishment(false);
        onChangeEstablishment(null);
        return;
      }
      if (establishment.closedSince) {
        setErrorEstablishment(
          <p>
            Établissement fermé depuis le :{' '}
            {format(establishment.closedSince, 'dd/MM/yyyy')}{' '}
          </p>
        );
        setEstablishment(false);
        onChangeEstablishment(null);
      } else {
        if (establishment.uuid) {
          setwarningEstablishment(<p>Etablissement déja enregistré</p>);
        }
        if (establishment.provider && !establishment.provider.signOfficerType) {
          establishment.provider.signOfficerType =
            ESignOfficerType.CORPORATE_OFFICER;
        }
        if (!establishment.provider) {
          //@ts-ignore
          establishment.provider = {
            signOfficerType: ESignOfficerType.CORPORATE_OFFICER,
          };
        }
        setEstablishment(establishment as IEstablishment);
        setValue('establishment', establishment);
        onChangeEstablishment(establishment);
      }
    } catch (e) {
      setErrorEstablishment(<p>Identifiant d'entreprise mal formé</p>);
      setValue('establishment', undefined);
      setIsFetchingCheckEsta(false);
    }
  };
  const handleLinkEstablishment = async () => {
    setLoading(true);
    setValue('establishment.attachRequestSent', true);
    try {
      await updateEstablishment({
        uuid: (establishment as IEstablishment).uuid,
        establishment: {},
        //@ts-ignore
        addTempContacts: {
          //@ts-ignore
          provider: [me.cognitoUserId],
        },
      });
      await showLinkedEstablishmentModal().then(async () => {
        // update Step attachRequestSent true redirect to step 3 .
        await updateOnBoardingStep({
          saveLater: false,
          data: {
            establishment: {
              siret: (establishment as IEstablishmentBase).siret as string,
              attachRequestSent: true,
              //@ts-ignore
              businessName: (establishment as IEstablishmentBase).businessName,
            },
          },
        });
      });
      await refreshStep();
    } catch (e) {
      //
    }

    setLoading(false);
  };
  //@ts-ignore
  const address = establishment?.address;
  //@ts-ignore
  const origin = establishment?.origin;
  return (
    <Box mt={60}>
      <Grid cols={12}>
        {(!establishment ||
          (establishment && warningEstablishment) ||
          newEsta) && (
          <Cell x-span={grid}>
            <FormControl
              label=""
              errorMessage={errors?.establishment?.siret?.message}
            >
              <VerifySiretForm
                mt={-20}
                pr={20}
                warning={warningEstablishment}
                required
                onSubmit={onCheckIdentifier}
                error={errorEstablishment}
                onUnlock={() => setEstablishment(false)}
                establishmentRole={'PROVIDER'}
              />
            </FormControl>
          </Cell>
        )}
        <Cell
          x-span={grid}
          style={{
            display:
              !establishment ||
              (establishment && warningEstablishment) ||
              newEsta
                ? 'none'
                : '',
          }}
        >
          {establishment && establishment?.siret && (
            <FormControl
              required
              label="N° SIRET (14 CHIFFRES)"
              errorMessage={errors?.establishment?.siret?.message}
            >
              <Input
                isDisabled
                isFullWidth
                pr={20}
                {...register('establishment.siret')}
              />
            </FormControl>
          )}
          {establishment &&
            !establishment?.siret &&
            establishment?.identifier && (
              <FormControl
                required
                label="N° IMMATRICULATION"
                errorMessage={errors?.establishment?.identifier?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  pr={20}
                  {...register('establishment.identifier')}
                />
              </FormControl>
            )}
        </Cell>
        <Cell x-span={grid} style={{ display: displayForm ? '' : 'none' }}>
          <FormControl
            label="Raison sociale"
            errorMessage={errors?.establishment?.businessName?.message}
          >
            <Input
              isDisabled
              isFullWidth
              pr={20}
              {...register('establishment.businessName')}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid} style={{ display: displayForm ? '' : 'none' }}>
          <FormControl
            required
            label="Adresse"
            errorMessage={errors?.establishment?.address?.street?.message}
          >
            <Input
              isDisabled={
                address?.street && address?.street?.length > 0 ? true : false
              }
              isFullWidth
              pr={20}
              {...register('establishment.address.street', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid} style={{ display: displayForm ? '' : 'none' }}>
          <FormControl required label="Code postal">
            <Input
              isDisabled={
                address?.postalCode && address?.postalCode?.length > 0
                  ? true
                  : false
              }
              isFullWidth
              pr={20}
              {...register('establishment.address.postalCode', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid} style={{ display: displayForm ? '' : 'none' }}>
          <FormControl required label="Ville">
            <Input
              isDisabled={
                address?.city && address?.city?.length > 0 ? true : false
              }
              isFullWidth
              pr={20}
              {...register('establishment.address.city', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Cell>
        <Cell x-span={grid} style={{ display: displayForm ? '' : 'none' }}>
          <FormControl
            required
            label="Pays"
            errorMessage={errors?.establishment?.address?.country?.message}
          >
            <Input
              isDisabled={
                address?.country && address?.country?.length > 0 ? true : false
              }
              isFullWidth
              pr={20}
              {...register('establishment.address.country', {
                required: 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Cell>
        <Cell
          x-span={grid}
          style={{
            display: displayForm && establishment ? '' : 'none',
          }}
        >
          <FormControl
            required={origin !== 'WORLD'}
            label="N° TVA"
            errorMessage={errors?.establishment?.vatNumber?.message}
          >
            <Input
              isDisabled
              isFullWidth
              pr={20}
              {...register('establishment.vatNumber', {
                required: origin !== 'WORLD' && 'Ce champ est requis',
              })}
            />
          </FormControl>
        </Cell>
        {formInfos.needLink && !isFetchingCheckEsta && (
          <Cell x-span={grid} mt={20}>
            <Text variant="p" pb={20}>
              Afin de poursuivre votre embarquement, merci de procéder à une
              demande de rattachement à cet établissement en cliquant sur le
              bouton suivant.
            </Text>
            <Button
              type="button"
              onClick={handleLinkEstablishment}
              isLoading={loading}
              isDisabled={loading}
            >
              Demander à être rattaché à cet établissement
            </Button>
          </Cell>
        )}
      </Grid>

      {establishment && formInfos.needLink === false && establishment && (
        <Box ml={-20} my={20}>
          <Button
            variant={'filled'}
            ml={20}
            type="button"
            isDisabled={loading}
            onClick={() => {
              clearErrors();
              setNewEsta(true);
              reset({
                siret: '',
                businessName: '',
                vatNumber: '',
                provider: {
                  bankAccount: {
                    iban: '',
                    bic: '',
                    owner: '',
                    rib: null,
                    bankName: '',
                  },
                  signOfficer: {
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    phone: undefined,
                    email: '',
                  },
                  delegatedSignOfficer: {
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    phone: undefined,
                    email: '',
                    delegationOrder: null,
                  },
                  signOfficerType: 'CORPORATE_OFFICER',
                },
                address: {},
              });
              setEstablishment(false);
              onChangeEstablishment(null);
              setErrorEstablishment(false);
              setwarningEstablishment(false);
            }}
          >
            Renseigner un autre établissement
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EstablishmentForm;
