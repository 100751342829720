import { Theme } from 'styles';
import {
  ECraStatus,
  EadditionalActivityStatus,
  IJoinedCraWithInvoice,
  IJoinedAdditionalActivityWithInvoice,
  IJoinedAdditionalActivity,
  EadditionalActivityType,
  IMilestoneReportExpenseData,
  IMilestoneReportMilestoneData,
  MilestoneReportProviderInvoiceProjection,
  IInvoice,
  EInvoiceStatus,
} from '@freelancelabs/teoreme-commons';
import { getHumanDate } from '@commons';
import { DutyTimeIcon, FeesIcon, MileStoneIcon } from 'components/ui/icons';
export const getStatusData = (
  data:
    | IJoinedCraWithInvoice
    | IJoinedAdditionalActivityWithInvoice
    | IMilestoneReportExpenseData
    | IMilestoneReportMilestoneData
    | Pick<IInvoice, MilestoneReportProviderInvoiceProjection>,
  hiddenDate?: boolean
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
  rejectReason?: string;
} => {
  //@ts-ignore
  const status = data?.state || data?.status;
  const validatedDate = hiddenDate
    ? ''
    : `le ${getHumanDate(data?.validatedAt as Date)}`;

  const rejectedDate = hiddenDate
    ? ''
    : `le ${
        data?.rejectedAt
          ? getHumanDate(data?.rejectedAt as Date)
          : getHumanDate(
              //@ts-ignore
              (data?.stateChangedAt || data?.statusChangedAt) as Date
            )
      }`;
  switch (status) {
    case ECraStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case ECraStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validé ${validatedDate}`,
        textColor: Theme.colors.success.default,
      };
    case EInvoiceStatus.PAID:
      return {
        variantColor: 'success',
        statusText: `Validé ${validatedDate}`,
        textColor: Theme.colors.success.default,
      };
    case ECraStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusé ${rejectedDate}`,
        textColor: Theme.colors.error.default,
        rejectReason: data?.rejectReason,
      };
    case ECraStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivé`,
        textColor: Theme.colors.grey.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};

export const getDateByStatus = (aa: IJoinedAdditionalActivity) => {
  switch (aa?.status) {
    case EadditionalActivityStatus.ARCHIVED:
      return getHumanDate(aa?.statusChangedAt as Date);
    case EadditionalActivityStatus.TO_BE_SUBMITTED:
      return getHumanDate(aa?.submittedAt as Date);
    case EadditionalActivityStatus.TO_BE_VALIDATED:
      return getHumanDate(aa?.statusChangedAt as Date);
    case EadditionalActivityStatus.VALIDATED:
      return getHumanDate(aa?.validatedAt as Date);
    case EadditionalActivityStatus.REJECTED:
      return getHumanDate(aa?.rejectedAt as Date);
    default:
      return 'N/A';
  }
};

export const getAdditionnalActivityIcon = (activity?: any) => {
  let icon;

  switch (activity?.type) {
    case EadditionalActivityType.STAND_BY_DUTY:
      icon = DutyTimeIcon;
      break;
    case EadditionalActivityType.EXPENSE:
      icon = FeesIcon;
      break;
    case EadditionalActivityType.MILESTONE:
      icon = MileStoneIcon;
      break;
  }
  return icon;
};
