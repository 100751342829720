import {
  getFullName,
  getTradeNameSafe,
  useCreateAditionalActivity,
  useAdditionalActivityFindOne,
  useAdditionalActivityUpdateOne,
  useMissionFindOne,
  queryClient,
  getHumanDateMonthAndYear,
  ADDIONAL_FILES_NUMBER,
  round,
  useInvoiceFindMany,
} from '@commons';

import {
  EadditionalActivityStatus,
  IJoinedMission,
  EadditionalActivityType,
  IExpenseLine,
  EBillingType,
} from '@freelancelabs/teoreme-commons';
import { Header } from 'components/Header';
import { EuroIcon } from 'components/icons';
import TextInformation from 'components/TextInformation';
import {
  Box,
  Button,
  Cell,
  Flex,
  Grid,
  LabelField,
  Link,
  SpinnerBox,
  Text,
  TextArea,
  FormControl,
  Divider,
  FileListControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useShowMessage } from 'hooks';
//@ts-ignore
import jsonQ from 'jsonq';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';
import { AddExpensesLine } from './AddExpensesLine';
import { useHistory } from 'react-router-dom';

type CreateOrUpdateExpensesFormProps = {
  month?: Date;
  missionRef: string;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
  onResolve?: (params: any) => void;
};
const grid12 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
type Line = IExpenseLine & {
  lock?: boolean;
};
const defaultLine = {
  lock: false,
};
export const CreateOrUpdateExpensesForm: React.FC<
  React.PropsWithChildren<CreateOrUpdateExpensesFormProps>
> = ({ month, missionRef, method, uuid, onResolve }) => {
  const { data: invoices } = useInvoiceFindMany({
    filterObject: uuid
      ? {
          additionalActivity: uuid,
        }
      : undefined,
    limit: 1,
    skip: 0,
  });
  const [onCreate, setOnCreate] = useState(method === 'CREATE' ? true : false);
  const [onUpdateLine, setOnUpdateLine] = useState<string | false>(false);
  const { data: activity, isFetching: isFetchingAddtional } =
    useAdditionalActivityFindOne(uuid);
  const {
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      //@ts-ignore
      attachments: activity?.attachments,
    },
  });
  const existingAttachments = activity?.attachments;
  const [providerComment, setProviderComment] = useState(
    activity?.providerComment
  );
  const isAttachmentsRequired = (mission: any) => {
    return mission?.expensesConfig?.shouldJoinAttachment;
  };

  const expensesLines: Line[] = activity?.expenseLines || [];
  const [lines, setLines] = useState<Line[] | []>(
    method === 'UPDATE' ? expensesLines : []
  );
  const { data: mission, isFetching: isFetchingMission } =
    useMissionFindOne(missionRef);

  // const showMessage = useShowMessage();

  const [loading, setLoading] = useState(false);

  const { mutateAsync: createAdditionalActivity } =
    useCreateAditionalActivity();
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();

  const handleDeleteLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex((l: Line) => l?.uuid === line?.uuid);
    updatedLines?.splice(index, 1);
    setOnUpdateLine(false);
    setLines(updatedLines);
    setOnCreate(false);
  };
  const handleCreateOrUpdateLine = (line: Line) => {
    const updatedLines = jsonQ.clone(lines);
    const index = lines?.findIndex((l: Line) => l?.uuid === line?.uuid);
    if (index !== -1) {
      updatedLines[index] = line;
    } else {
      updatedLines?.push(line);
    }

    setOnUpdateLine(false);
    setOnCreate(false);
    setLines(updatedLines);
  };
  const isEditable =
    !activity?.status ||
    activity?.status === EadditionalActivityStatus?.TO_BE_FILLED ||
    activity?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED ||
    activity?.status === EadditionalActivityStatus?.REJECTED;
  useEffect(() => {
    if (method === 'UPDATE' && uuid) {
      if (activity && activity?.expenseLines?.length) {
        setLines(
          activity?.expenseLines?.map((expense: any) => ({
            ...expense,
            lock: true,
          }))
        );
      }
    }
  }, [activity, method, uuid]);

  useEffect(() => {
    if (activity) {
      setProviderComment(activity?.providerComment);
    }
  }, [activity]);
  if (isFetchingAddtional || isFetchingMission) {
    return <SpinnerBox />;
  }
  const totalProviderAmount = () => {
    let total = 0;
    lines?.forEach(l => (total = total + Number(l?.amount || 0)));
    return round(total);
  };
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  if (!exepensesIsEnabled) {
    return (
      <Box width={1 / 1}>
        <Header tabs={false} height={120}>
          <Text variant="h1">Déclarer des frais</Text>
        </Header>
        Vous ne pouvez pas créer de frais sur cette mission.
      </Box>
    );
  }

  const expenseBudget = mission?.expensesConfig?.purchaseBudget ?? 0;
  const haveExpenseBudget = exepensesIsEnabled && expenseBudget > 0;
  const expenseConsumedBudget = mission?.expensesConfig?.consumedPurchaseBudget;
  let previousBudget = 0;
  activity?.expenseLines?.forEach((l: any) => (previousBudget += l?.amount));

  // fix https://fcomdev.atlassian.net/browse/TEOR-5657
  const statusToReduceBudget = [EadditionalActivityStatus.VALIDATED];
  const currentConsumedBudget =
    (expenseConsumedBudget || 0) -
    (!statusToReduceBudget?.find(s => s === activity?.status)
      ? previousBudget
      : 0) +
    totalProviderAmount();
  //   method !==
  // 'CREATE'
  //   ? totalProviderAmount()
  //   : // IF EXPENSE IS SUBMITED REMOVE CONSUMED BUDGET ET RECALCULATE AMOUNT
  //     (expenseConsumedBudget || 0) - previousBudget + totalProviderAmount();
  const isOutOffBudget = haveExpenseBudget
    ? currentConsumedBudget > (expenseBudget || 0)
    : false;
  const stepperPercentExpenses = (): { percent: string; color: string } => {
    let color = '';

    const percent = ((currentConsumedBudget || 0) / (expenseBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperWidthExpense = stepperPercentExpenses()?.percent;
  const colorStepperExpense = stepperPercentExpenses()?.color;
  const onSubmit = async (saveLater: boolean) => {
    const missionType = mission?.billing?.type;
    let providerInvoice = invoices?.invoices?.[0]?.uuid;
    let expenseCreated;
    const formValues = getValues();
    setError('attachments', { message: undefined });
    //@ts-ignores
    const attachments = formValues?.attachments;
    if (!attachments?.length) {
      if (isAttachmentsRequired(mission)) {
        setError('attachments', {
          message: 'Au moins un justificatif est requis',
        });
        return;
      }
    }
    const submitValues = {
      saveAndFinishLater: missionType === EBillingType.DAY ? true : false,
      missionReference: mission?.reference as string,
      type: EadditionalActivityType?.EXPENSE,
      status:
        missionType === EBillingType?.DAY
          ? EadditionalActivityStatus.TO_BE_SUBMITTED
          : EadditionalActivityStatus.TO_BE_VALIDATED,
      month: month,
      attachments,
      providerComment,
      expenses: lines?.map(l => {
        return {
          ...l,
          uuid: typeof l?.uuid === 'number' ? undefined : l?.uuid,
          lock: undefined,
        };
      }) as IExpenseLine[],
    };
    try {
      setLoading(true);
      if (method === 'CREATE') {
        expenseCreated = await createAdditionalActivity(submitValues);
        if (expenseCreated?.providerInvoice) {
          providerInvoice = expenseCreated?.providerInvoice;
        }
      }
      if (method === 'UPDATE') {
        const removeExpenseLines: string[] | [] = [];
        expensesLines?.forEach(expense => {
          if (!lines?.find(l => l?.uuid === expense.uuid)) {
            removeExpenseLines?.push(expense.uuid as never);
          }
        });
        const addAttachments: any[] = [];
        const removeAttachments: any[] = [];
        const existingAttachmentsFileNames =
          existingAttachments?.map((f: any) => f.fileName) || [];

        const currentAttachmentsFileNames =
          attachments?.map((file: any) => file.fileName) || [];

        attachments?.forEach((sd: any) => {
          if (!existingAttachmentsFileNames?.includes(sd?.fileName)) {
            addAttachments?.push(sd);
          }
        });
        existingAttachments?.forEach((sd: any) => {
          if (!currentAttachmentsFileNames?.includes(sd?.fileName)) {
            removeAttachments?.push(sd);
          }
        });

        const expenseUpdated = await updateAdditionalActivity({
          uuid: uuid,
          ...submitValues,
          removeExpenseLines,
          addAttachments,
          removeAttachments,
          status:
            missionType === EBillingType?.DAY
              ? EadditionalActivityStatus.TO_BE_SUBMITTED
              : EadditionalActivityStatus.TO_BE_VALIDATED,
        });
        if (expenseUpdated?.providerInvoice) {
          providerInvoice = expenseUpdated?.providerInvoice;
        }
      }

      queryClient.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient.refetchQueries({ queryKey: ['ActivitiesReport'] });
      queryClient.refetchQueries({ queryKey: [mission?.reference] });
      if (onResolve && typeof onResolve === 'function') {
        const missionType = mission?.billing?.type;
        onResolve({
          navigate: providerInvoice
            ? missionType === EBillingType.DAY
              ? undefined
              : `/invoices/time-spent/to-be-submitted/${providerInvoice}`
            : undefined,
          submitSuccess: true,
        });
      }
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <Box width={1 / 1}>
      <Text variant="h1" mb={10}>
        {method === 'UPDATE' ? 'Modifier' : 'Déclarer'} des frais
      </Text>
      <Box width={1 / 1}>
        {activity?.rejectReason &&
          activity?.status === EadditionalActivityStatus?.REJECTED && (
            <Box>
              <TextInformation variant="error" width={1 / 1} mb={10}>
                {`Cette prestation complémentaire été refusé le ${new Date(
                  activity?.statusChangedAt as Date
                ).toLocaleDateString()} pour cause de : ${activity?.rejectReason}`}
              </TextInformation>
            </Box>
          )}
        <Grid cols={12} gap="10px">
          <Cell x-span={grid12}>
            <Flex
              height={'100px'}
              p={25}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              backgroundColor="#edf3ff"
              borderRadius="8px"
            >
              {month && (
                <LabelField
                  underline
                  label="Période"
                  value={getHumanDateMonthAndYear(month)}
                />
              )}
              <LabelField
                underline
                ml={5}
                label="Référence mission"
                value={mission?.displayReference}
              />
              <LabelField
                underline
                ml={5}
                label="Client"
                value={
                  mission && getTradeNameSafe(mission?.customer?.establishment)
                }
              />
              <LabelField
                underline
                ml={5}
                label="Intervenant"
                value={getFullName(mission?.provider?.contractor)}
              />
            </Flex>
          </Cell>
          <Cell x-span={grid12}>
            {haveExpenseBudget && (
              <LabelField
                label="Budget des frais consommé"
                value={
                  <Box mb={20}>
                    <Flex
                      width={1 / 1}
                      zIndex={100}
                      justifyContent="space-around"
                      borderBottom={'12px solid #eaeefc'}
                      borderRadius={'6px'}
                    >
                      <Box
                        position="absolute"
                        left={0}
                        bottom={-12}
                        //@ts-ignore
                        width={stepperWidthExpense}
                        borderRadius={'6px'}
                        borderBottom={`12px solid ${colorStepperExpense}`}
                      />
                      <Box
                        width={1 / 1}
                        position="absolute"
                        left={0}
                        bottom={-12}
                      >
                        <Text
                          textAlign={'center'}
                          color={
                            Number(stepperWidthExpense?.replace('%', '')) > 47
                              ? 'white'
                              : 'black'
                          }
                          fontSize={10}
                        >
                          {stepperWidthExpense}
                        </Text>
                      </Box>
                    </Flex>
                    <Text mt={20} width={1 / 1}>
                      {`Vous avez consommé ${round(
                        currentConsumedBudget || 0
                      )} € de frais sur ${round(
                        expenseBudget
                      )} € prévus sur cette mission.`}
                    </Text>
                  </Box>
                }
                underline
                mb={10}
              />
            )}
          </Cell>
        </Grid>
        {lines
          ?.sort((a: Line, b: Line) => Number(b.lock) - Number(a.lock))
          ?.map((l: Line, index) => (
            <>
              <AddExpensesLine
                key={`line_${uuid}`}
                isEditable={isEditable}
                readOnly={onUpdateLine === l?.uuid ? false : l?.lock}
                line={l}
                mission={mission as IJoinedMission}
                onCreateOrUpdateLine={(line: Line) =>
                  handleCreateOrUpdateLine(line)
                }
                unLockToUpdate={(line: Line) =>
                  setOnUpdateLine(line?.uuid as string)
                }
                onDeleteLine={(line: Line) => handleDeleteLine(line)}
              />
              {index + 1 !== lines?.length && <Divider />}
            </>
          ))}
        {onCreate && (
          <>
            <AddExpensesLine
              key={`line_add`}
              readOnly={false}
              //@ts-ignore
              line={defaultLine}
              mission={mission as IJoinedMission}
              onCreateOrUpdateLine={(line: Line) =>
                handleCreateOrUpdateLine(line)
              }
              onDeleteLine={(line: Line) => handleDeleteLine(line)}
            />
            <Divider />
          </>
        )}
        {!onCreate && isEditable && (
          <Box mt={20}>
            <Link
              onClick={() => setOnCreate(true)}
              iconLeft={<AddIcon fill={Theme?.colors?.primary?.default} />}
            >
              Ajouter un frais
            </Link>
          </Box>
        )}
        <Box mt={25}>
          <Text variant="b" textAlign={'left'}>
            Justificatifs
          </Text>

          <FormControl
            label=""
            errorMessage={errors?.attachments?.message}
            mt={-20}
          />
          <FileListControlled
            control={control}
            maxFile={lines.length + ADDIONAL_FILES_NUMBER}
            name="attachments"
            missionRef={mission?.reference}
            isDisabled={!isEditable}
            actionName={'Importer un justificatif'}
            accept=".pdf"
            defaultValue={activity?.attachments as any}
            onChangeAction={(e: any) => {
              if (e?.length) setError('attachments', { message: undefined });
            }}
          />
        </Box>
        <Grid cols={12} mt={20} gap="10px">
          <Cell x-span={grid12}>
            <FormControl label="Commentaire envoyé">
              <TextArea
                isDisabled={!isEditable}
                minRows={3}
                name={'providerComment'}
                value={providerComment}
                onChange={e => setProviderComment(e?.target?.value)}
                placeholder={
                  isEditable
                    ? 'Ajouter un commentaire destiné aux équipes Freelance.com'
                    : 'N/A'
                }
              />
            </FormControl>
          </Cell>
        </Grid>
        <Flex width={1 / 1} justifyContent="flex-end" mt={10}>
          <Text
            p={10}
            variant="h1"
            color="#009933"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="rgba(0, 153, 51, 0.1)"
            fontWeight={500}
          >
            Montant total : {totalProviderAmount()}
            <EuroIcon fill="#009933" />
          </Text>
        </Flex>
        <Flex mt={10} justifyContent="flex-end">
          {(!activity?.status ||
            activity?.status === EadditionalActivityStatus?.TO_BE_FILLED ||
            activity?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED ||
            activity?.status === EadditionalActivityStatus?.REJECTED) && (
            <Button
              isLoading={loading}
              isDisabled={lines?.length < 1 || isOutOffBudget}
              variant="ghost"
              ml={10}
              onClick={() => onSubmit(true)}
            >
              Enregistrer
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

CreateOrUpdateExpensesForm.displayName = 'CreateOrUpdateExpensesForm';

export default CreateOrUpdateExpensesForm;
