import { ECraStatus } from '@freelancelabs/teoreme-commons';
import { add, getTime } from 'date-fns';
import { snakeCase } from 'lodash';
import { CRAStatusEnum } from './types';
enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
export const getCRAState = (
  filter: ECraStatus,
  selectedState: ECraStatus | 'ALL'
): Array<ECraStatus | CRAStatusEnum> => {
  if (filter === ECraStatus.TO_BE_SUBMITTED) {
    if (selectedState === 'ALL') {
      return [
        ECraStatus.TO_BE_FILLED,
        ECraStatus.TO_BE_SUBMITTED,
        ECraStatus.REJECTED,
        //ECraStatus.PENDING,
      ];
    } else {
      return [selectedState];
    }
  }
  return [filter];
};

export const getFilterByUrl = (filterUrl: string): ECraStatus => {
  const filter = snakeCase(filterUrl).toUpperCase();

  const CRA_STATUS = Object.values(CRAStatusEnum).find(
    status => status === filter
  );
  //@ts-ignore
  return CRA_STATUS || ECraStatus.TO_BE_SUBMITTED;
};

export const getSorted = (
  order: sortType,
  sortedColumn: string | null
): string | undefined => {
  if (sortedColumn) {
    return sortedMap[order] + sortedColumn;
  }
  return undefined;
};

const sortedMap = {
  desc: '-',
  asc: '',
};

export const getHiddenColumns = (innerWidth: number): string[] => {
  if (innerWidth <= 992) {
    return [
      'estCustomer.businessName',
      'estProvider.businessName',
      'contractorRate',
    ];
  }

  return [];
};

export const getTabsTextsByState = (state: ECraStatus) => {
  switch (snakeCase(state).toUpperCase()) {
    case ECraStatus.ARCHIVED: {
      return 'Retrouvez ici l’ensemble des CRA validés pour lesquels une facture fournisseur valide a été transmise à nos conseillers Connecteed.';
    }
    case ECraStatus.VALIDATED: {
      return 'Retrouvez ici l’ensemble des CRA validés par votre conseiller Connecteed et pour lesquels vous pouvez émettre les factures associées.';
    }
    case ECraStatus.TO_BE_SUBMITTED: {
      return 'Retrouvez ici l’ensemble des CRA de vos missions en cours, à compléter et à envoyer à votre conseiller Connecteed pour validation. Une fois vos CRA soumis et validés, vous pourrez émettre les factures associées.';
    }
    case ECraStatus.TO_BE_VALIDATED: {
      return 'Retrouvez ici l’ensemble des CRA, soumis pour vos missions en cours et en attente de validation par un conseiller Connecteed. Une fois vos CRA validés, vous pourrez émettre les factures associées.';
    }
    case ECraStatus.TO_BE_FILLED: {
      return 'Retrouvez ici l’ensemble des CRA validés par votre conseiller Connecteed et pour lesquels vous pouvez émettre les factures associées.';
    }
  }
};

export const getObjectFilterCRA = (
  debouncedFilterQuery = '',
  startDate: Date | null,
  endDate: Date | null,
  filterUrl: ECraStatus,
  selectedStatus: ECraStatus | 'ALL',
  isOpen?: boolean
): any => {
  const filterObject = {
    state: { $in: getCRAState(filterUrl, selectedStatus) },
    isOpen:
      filterUrl === ECraStatus?.TO_BE_SUBMITTED
        ? isOpen === true
          ? true
          : undefined
        : undefined,
  };
  const queryFilter = [
    {
      'estCustomer.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.firstName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      refCra: {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (startDate !== null && endDate !== null) {
    //@ts-ignore
    filterObject.$and = [
      {
        $expr: {
          $gte: [{ $toLong: '$month' }, getTime(startDate)],
        },
      },
      {
        $expr: {
          $lte: [{ $toLong: '$month' }, getTime(add(endDate, { months: 1 }))],
        },
      },
    ];
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$and.push({ $or: queryFilter });
    }
  } else {
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$or = queryFilter;
    }
  }
  return filterObject;
};

export const mapSortByToApi = (sortBy: any) => {
  const sortedBy = sortBy
    .map(
      ({ desc, id }: { desc: boolean; id: string }) => `${desc ? '-' : ''}${id}`
    )
    .join('');

  return sortedBy.includes('month') ? `${sortedBy} refCra` : sortedBy;
};
