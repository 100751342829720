import { Header } from 'components/Header';
import { getTradeNameSafe, useHeadQuarterFindMany } from '@commons';
import { IEstablishment } from '@freelancelabs/teoreme-commons';
import {
  Grid,
  Cell,
  Flex,
  Box,
  Text,
  Divider,
  Spinner,
  FormControl,
} from 'components/ui';
import React from 'react';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { StaticSelect } from 'components/selects/StaticSelect';
import { CreateContractorForm } from 'forms/Contractor/CreateContractorForm';
type CreateContractorProps = {};
const grid6 = {
  xs: '6',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};

export const CreateContractor: React.FC<
  React.PropsWithChildren<CreateContractorProps>
> = () => {
  const [disableEstablishmentSelect, setDisableEstablishmentSelect] =
    React.useState(false);
  useSetCrumbs([], [{ label: 'Intervenants', path: '/contractors' }]);
  const [establishmentSelected, setEstablishmentSelected] = React.useState<
    IEstablishment | undefined
  >();
  const { data: meEstablishments, isFetching: isFetchingMeEstablishments } =
    useHeadQuarterFindMany();

  React.useEffect(() => {}, []);

  const options: any = [];
  meEstablishments?.map(hq => {
    return hq?.establishments?.forEach(esta => {
      const me = localStorage?.cognitoUserId;
      const isTmp = esta?.provider?.tempContacts?.findIndex(
        (tmp: any) => tmp === me
      );
      if (isTmp === -1) {
        options.push({
          label: (
            <Flex>
              <Text variant={'p'}>{`${getTradeNameSafe(esta)}`} - </Text>
              <Text variant={'b'} ml={'5px'}>{`${
                esta.siret ?? esta?.identifier
              }`}</Text>
            </Flex>
          ),
          key: esta.uuid,
          value: esta,
        });
      }
    });
  });
  if (isFetchingMeEstablishments) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  } else {
    return (
      <Box width={1 / 1}>
        <Header height="auto">
          <Text variant="h1">Créer un nouvel intervenant</Text>
        </Header>
        <Text variant="h2" mt={20}>
          Établissement fournisseur
        </Text>
        <Text variant="p" mt={20} mb={20}>
          Veuillez sélectionner l'établissement fournisseur pour lequel vous
          souhaitez créer un nouvel intervenant. Si cet établissement
          fournisseur n’appartient pas à votre liste d'établissements, ajoutez
          le au préalable.
        </Text>
        <Grid cols={12}>
          <Cell x-span={grid6}>
            <FormControl label="Rechercher un établissement fournisseur">
              <StaticSelect
                isDisabled={disableEstablishmentSelect}
                //@ts-ignore
                options={options}
                //@ts-ignore
                onChange={value => setEstablishmentSelected(value)}
              />
            </FormControl>
          </Cell>
        </Grid>
        <Divider mt={20} mb={20} />
        <Box>
          <CreateContractorForm
            notEditable={!establishmentSelected}
            establishment={establishmentSelected}
            onChangeDisableEstablishmentSelect={(value: boolean) =>
              setDisableEstablishmentSelect(value)
            }
          />
        </Box>
      </Box>
    );
  }
};

CreateContractor.displayName = 'CreateContractor';

export default CreateContractor;
