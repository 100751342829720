import { FlatUser } from '@freelancelabs/teoreme-commons';
import { EContactReason } from '@freelancelabs/teoreme-commons/dist/lib/models/enum/contact.enum';
import { useMe } from '@commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { ContactUsForm } from 'forms/ContactUs';
import React from 'react';
import { create } from 'react-modal-promise';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  me?: FlatUser;
  contactReason?: EContactReason;
};

export const ContactUsModal = ({
  onResolve,
  isOpen,
  layer,
  contactReason,
}: Props) => {
  const [size, setSize] = React.useState(false);
  const { me } = useMe();

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={size ? 368 : 868}
      closeIcon
      layer={layer}
    >
      <ContactUsForm
        onChangeSize={setSize}
        me={me}
        onClose={() => onResolve(false)}
        contactReason={contactReason}
      />
    </ModalFrame>
  );
};

export const showContactUsModal = create<Props>(ContactUsModal);
