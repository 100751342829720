import {
  EFileType,
  ICompanyUpdateParams,
} from '@freelancelabs/teoreme-commons';
import {
  cutLongText,
  uploadFile,
  formatAllObject,
  useEstablishmentUpdateOne,
  validateFormPhoneFormatFR,
} from '@commons';
import { useMe } from '@commons';
import { DownloadIcon } from 'components/icons';
import {
  Flex,
  Button,
  Box,
  CheckSwitch,
  FileInput,
  FormControl,
  FormLabel,
  Input,
  Link,
  Radio,
  Text,
  Spinner,
  Grid,
  Cell,
  PhoneInputControlled,
} from 'components/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';

const grid = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};

type FormValues = any;

type SignatoryInformationFormProps = {
  establishment?: any;
  setDisplayForm: (value: boolean) => void;
};

export const SignatoryInformationForm = ({
  establishment,
  setDisplayForm,
}: SignatoryInformationFormProps) => {
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setError,
  } = useForm<FormValues>({
    defaultValues: {
      establishment: {
        provider: {
          signOfficerType:
            establishment?.provider?.signOfficerType || 'CORPORATE_OFFICER',
          signOfficer: {
            ...establishment?.provider?.signOfficer,
            civility: String(establishment?.provider?.signOfficer?.civility),
          },
          delegatedSignOfficer: {
            ...establishment?.provider?.delegatedSignOfficer,
            civility: String(
              establishment?.provider?.delegatedSignOfficer?.civility
            ),
          },
        },
      },
    },
  });
  const { me } = useMe();
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [loading, setLoading] = React.useState(false);
  const signOfficerType = watch('establishment.provider.signOfficerType');
  const [contractFile, setContractFile] = React.useState<File | null>(
    establishment?.provider?.delegatedSignOfficer?.delegationOrder
  );
  const [loadingFile, setLoadingFile] = React.useState(false);
  const delegatedSignOfficer = watch(
    'establishment.provider.delegatedSignOfficer'
  );
  const delegationOrder = delegatedSignOfficer?.delegationOrder;
  const checkIsCorporateOfficer = (): boolean | undefined => {
    if (signOfficerType === 'DELEGATED_OFFICER') {
      return false;
    }
    if (
      establishment &&
      establishment.provider &&
      establishment.provider.signOfficer
    ) {
      if (establishment.provider.signOfficer.email === me?.email) {
        return true;
      } else {
        return false;
      }
    }
    if (establishment && establishment.isCorporateOfficer !== undefined) {
      return establishment.isCorporateOfficer;
    } else {
      return true; // defaultState true
    }
  };
  const [isCorporateOfficer, setIsCorporateOfficer] = React.useState(
    checkIsCorporateOfficer() //isCorporateOfficerForm
  );
  const handleContractFileChange = async (files: File[]) => {
    setLoadingFile(true);
    setContractFile(files[0]);
    // try {
    //   const s3Response = await uploadFile({
    //     file: files[0],
    //     //@ts-ignore
    //     fileType: EFileType.TEMPORARY,
    //     actionType: 'upload',
    //     establishment: establishment?.uuid || '',
    //   });
    //   const delegationOrder = {
    //     fileName: s3Response?.fileName,
    //     fileLocation: s3Response?.fileLocation as string,
    //     eTag: s3Response?.eTag,
    //   };
    //   setValue(
    //     'establishment.provider.delegatedSignOfficer.delegationOrder',
    //     delegationOrder
    //   );
    // } catch (e) {
    //   //
    //   setError(
    //     'establishment.provider.delegatedSignOfficer.delegationOrder',
    //     e
    //   );
    // }
    setLoadingFile(false);
  };
  const checkEmail = (value: string): string | undefined => {
    const regex = /[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i;
    if (regex.test(value)) {
      return undefined;
    } else {
      return 'Veuillez entrer une adresse e-mail valide';
    }
  };
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    let onError = false;
    const signOfficerType = formValues?.establishment?.provider.signOfficerType;
    const delegatedSignOfficer =
      formValues?.establishment?.provider?.delegatedSignOfficer;
    const signOfficer = formValues?.establishment?.provider?.signOfficer;
    if (signOfficerType === 'DELEGATED_OFFICER') {
      if (!contractFile) {
        onError = true;
        setError(
          'establishment.provider.delegatedSignOfficer.delegationOrder',
          { message: 'Ce champ est obligatoire' }
        );
      } else {
        //@ts-ignore
        if (!contractFile.eTag) {
          try {
            const s3Response = await uploadFile({
              file: contractFile as File,
              //@ts-ignore
              fileType: EFileType.TEMPORARY,
              actionType: 'upload',
              establishment: establishment?.uuid || '',
            });
            const delegationOrder = {
              fileName: s3Response?.fileName,
              fileLocation: s3Response?.fileLocation as string,
              eTag: s3Response?.eTag,
            };
            setValue(
              'establishment.provider.delegatedSignOfficer.delegationOrder',
              delegationOrder
            );
            delegatedSignOfficer.delegationOrder = delegationOrder;
          } catch (e) {
            //
          }
        }
      }
    }
    const establishmentData: ICompanyUpdateParams = {
      uuid: establishment?.uuid,
      establishment: {
        //@ts-ignore
        provider: {
          //manager: establishment?.provider?.manager,
          signOfficerType: signOfficerType,
          delegatedSignOfficer:
            signOfficerType === 'CORPORATE_OFFICER'
              ? undefined
              : delegatedSignOfficer,
          signOfficer: signOfficer,
        },
      },
    };
    if (!onError) {
      try {
        establishmentData.establishment.provider = formatAllObject(
          establishmentData.establishment.provider
        );
        await updateEstablishment(establishmentData);
        queryClient.refetchQueries({ queryKey: [establishment.uuid] });
        //showMessage('success', 'Informations du signataire mise à jour');
        setDisplayForm(false);
      } catch (e) {
        //
      }
      setDisplayForm(false);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={10}>
        <Box
          height={50}
          backgroundImage={'linear-gradient(to bottom, #f5f7fc,#ffffff);'}
        />
        <Box mt={20}>
          <CheckSwitch
            id="checkOne"
            mb={10}
            defaultChecked={isCorporateOfficer}
            onChange={() => {
              clearErrors();
              setIsCorporateOfficer(!isCorporateOfficer);
            }}
          >
            <Text variant="h3">
              Vous êtes mandataire social de l’établissement
            </Text>
            <Text variant="p">Nom apparaissant sur le KBIS</Text>
          </CheckSwitch>
        </Box>
        <>
          <Box style={{ display: isCorporateOfficer ? 'none' : '' }}>
            <Box mb={20}>
              <FormLabel mt={60}>Qui signera les contrats ?</FormLabel>
              <Radio
                {...register('establishment.provider.signOfficerType', {
                  required: undefined,
                })}
                value={'CORPORATE_OFFICER'}
                onClick={() => {
                  //clearErrors();
                  setValue(
                    'establishment.provider.signOfficerType',
                    'CORPORATE_OFFICER'
                  );
                }}
              >
                Le mandataire social
              </Radio>
              <Radio
                {...register('establishment.provider.signOfficerType', {
                  required: undefined,
                })}
                value={'DELEGATED_OFFICER'}
                onClick={() => {
                  //clearErrors();
                  setValue(
                    'establishment.provider.signOfficerType',
                    'DELEGATED_OFFICER'
                  );
                }}
              >
                Un tiers muni d'une délégation de signature
              </Radio>
            </Box>
            <Box>
              <Text mt={60} variant="h3">
                Informations du mandataire social
              </Text>
              <Box mt={20}>
                <FormControl
                  required
                  label={'Civilité'}
                  errorMessage={
                    errors?.establishment?.provider?.signOfficer?.civility
                      ?.message
                  }
                >
                  <>
                    <Radio
                      {...register(
                        'establishment.provider.signOfficer.civility',
                        {
                          required: !isCorporateOfficer
                            ? 'Ce champ est obligatoire'
                            : undefined,
                        }
                      )}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      {...register(
                        'establishment.provider.signOfficer.civility',
                        {
                          required: !isCorporateOfficer
                            ? 'Ce champ est obligatoire'
                            : undefined,
                        }
                      )}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                </FormControl>
              </Box>
              <Box>
                <Grid cols={12} gap={'20px'}>
                  <Cell x-span={grid}>
                    <FormControl
                      required
                      label="Prénom"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.firstName
                          ?.message
                      }
                    >
                      {!isCorporateOfficer ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.firstName',
                            { required: 'Ce champ est obligatoire' }
                          )}
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.firstName',
                            { required: undefined }
                          )}
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      required
                      label="Nom"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.lastName
                          ?.message
                      }
                    >
                      {!isCorporateOfficer ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.lastName',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )}
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.lastName',
                            {
                              required: undefined,
                            }
                          )}
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      required
                      label="Fonction"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.jobTitle
                          ?.message
                      }
                    >
                      {!isCorporateOfficer ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.jobTitle',
                            {
                              required: 'Ce champ est obligatoire',
                            }
                          )}
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.jobTitle',
                            {
                              required: undefined,
                            }
                          )}
                          name="establishment.provider.signOfficer.jobTitle"
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      required={signOfficerType === 'CORPORATE_OFFICER'}
                      label="Email"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.email
                          ?.message
                      }
                    >
                      {!isCorporateOfficer &&
                      signOfficerType === 'CORPORATE_OFFICER' ? (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.email',
                            {
                              required: 'Ce champ est requis',
                              validate: value => checkEmail(value),
                            }
                          )}
                          name="establishment.provider.signOfficer.email"
                          type="email"
                        />
                      ) : (
                        <Input
                          isFullWidth
                          {...register(
                            'establishment.provider.signOfficer.email',
                            {
                              required: undefined,
                              validate: undefined,
                            }
                          )}
                          type="email"
                        />
                      )}
                    </FormControl>
                  </Cell>
                  <Cell x-span={grid}>
                    <FormControl
                      label="Téléphone"
                      errorMessage={
                        errors?.establishment?.provider?.signOfficer?.phone
                          ?.message
                      }
                    >
                      <PhoneInputControlled
                        control={control}
                        rules={{
                          validate: (value: string) => {
                            return validateFormPhoneFormatFR(value);
                          },
                        }}
                        isFullWidth
                        name="establishment.provider.signOfficer.phone"
                      />
                    </FormControl>
                  </Cell>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              display:
                !isCorporateOfficer && signOfficerType === 'DELEGATED_OFFICER'
                  ? ''
                  : 'none',
            }}
          >
            <Text variant="h3">
              Informations de la personne habilitée à signer
            </Text>
            <Box mt={20}>
              <FormControl
                required={true}
                label={'Civilité'}
                errorMessage={
                  errors?.establishment?.provider?.delegatedSignOfficer
                    ?.civility?.message
                }
              >
                {!isCorporateOfficer &&
                signOfficerType === 'DELEGATED_OFFICER' ? (
                  <>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                ) : (
                  <>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: undefined,
                        }
                      )}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      {...register(
                        'establishment.provider.delegatedSignOfficer.civility',
                        {
                          required: undefined,
                        }
                      )}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                )}
              </FormControl>
            </Box>
            <Grid cols={12} gap={'20px'}>
              <Cell x-span={grid}>
                <FormControl
                  required={true}
                  label="Prénom"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.firstName?.message
                  }
                >
                  {!isCorporateOfficer &&
                  signOfficerType === 'DELEGATED_OFFICER' ? (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.firstName',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                    />
                  ) : (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.firstName',
                        {
                          required: undefined,
                        }
                      )}
                    />
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required={true}
                  label="Nom"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.lastName?.message
                  }
                >
                  {!isCorporateOfficer &&
                  signOfficerType === 'DELEGATED_OFFICER' ? (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.lastName',
                        {
                          required: 'Ce champ est obligatoire',
                        }
                      )}
                    />
                  ) : (
                    <Input
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.lastName',
                        {
                          required: undefined,
                        }
                      )}
                    />
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  label="Fonction"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.jobTitle?.message
                  }
                >
                  <Input
                    isFullWidth
                    {...register(
                      'establishment.provider.delegatedSignOfficer.jobTitle',
                      {
                        required: undefined,
                      }
                    )}
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required={true}
                  label="Email"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer?.email
                      ?.message
                  }
                >
                  {!isCorporateOfficer &&
                  signOfficerType === 'DELEGATED_OFFICER' ? (
                    <Input
                      type="email"
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.email',
                        {
                          required: 'Ce champ est requis',
                          validate: value => checkEmail(value),
                        }
                      )}
                    />
                  ) : (
                    <Input
                      type="email"
                      isFullWidth
                      {...register(
                        'establishment.provider.delegatedSignOfficer.email',
                        {
                          required: undefined,
                          validate: undefined,
                        }
                      )}
                    />
                  )}
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  label="Téléphone"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer?.phone
                      ?.message
                  }
                >
                  <PhoneInputControlled
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        return validateFormPhoneFormatFR(value);
                      },
                    }}
                    isFullWidth
                    name="establishment.provider.delegatedSignOfficer.phone"
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required
                  label="Delégation de signature a"
                  errorMessage={
                    errors?.establishment?.provider?.delegatedSignOfficer
                      ?.delegationOrder?.message
                  }
                >
                  <FileInput
                    required
                    label=""
                    accept=".pdf"
                    subLabel=""
                    onSelect={handleContractFileChange}
                  >
                    {loadingFile && <Spinner />}

                    <Link iconLeft={<DownloadIcon />}>
                      {cutLongText(
                        contractFile?.name ? contractFile.name : '',
                        20
                      ) ||
                        `${
                          delegationOrder
                            ? `Modifier le fichier '${cutLongText(
                                delegationOrder.fileName,
                                20
                              )}' `
                            : 'Ajouter une delégation de signature'
                        }`}
                    </Link>
                  </FileInput>
                </FormControl>
              </Cell>
            </Grid>
          </Box>
        </>
        <Flex mt={20}>
          <Button
            variant="ghost"
            mr={10}
            type="button"
            onClick={() => setDisplayForm(false)}
          >
            Annuler
          </Button>
          <Button isLoading={loading} type="submit" variant="filled">
            Modifier
          </Button>
        </Flex>
        <Box
          height={50}
          backgroundImage={'linear-gradient(to top, #f5f7fc,#ffffff);'}
        />
      </Box>
    </form>
  );
};

export default SignatoryInformationForm;
