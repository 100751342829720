import * as React from 'react';
import { ContainerPagination } from './Pagination.styled';
import { Text } from 'components/ui';
import { Arrows, RoundedButton } from 'components/ui/Pagination';

const LEFT_PAGE = -1;
const RIGHT_PAGE = -1;

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export type Props = {
  total?: number;
  perPage?: number;
  page?: number;
  onChangePage: (pageNumber: number) => void;
  labelData: string;
};

export const TablePagination = ({
  total = 0,
  perPage = 0,
  page = 0,
  labelData = '',
  onChangePage,
}: Props) => {
  // reset page to 0 based

  const pageNeighbours = 2;
  const totalPages = Math.ceil(total / perPage);
  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBlocks = totalNumbers + 2;

  let pageModel: number[] = [];

  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, page - pageNeighbours);
    const endPage = Math.min(totalPages - 1, page + pageNeighbours);

    let pages: any[] = range(startPage, endPage);

    const hasLeftSpill = startPage > 2;
    const hasRightSpill = totalPages - endPage > 1;
    const spillOffset = totalNumbers - (pages.length + 1);

    switch (true) {
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
        break;
      }
      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
        break;
      }
      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
        break;
      }
    }
    pageModel = [1, ...pages, totalPages];
  } else {
    pageModel = range(1, totalPages);
  }

  return (
    <ContainerPagination>
      <div className="textPagination">
        <Text>
          page <b>{page + 1}</b> sur <b>{totalPages}</b> - <b>{total}</b>{' '}
          {labelData}
        </Text>
      </div>
      <div className="Pagination">
        <Arrows
          direction="left"
          onClick={() => onChangePage(0)}
          disabled={page === 0}
        >
          {'<<'}
        </Arrows>
        <Arrows
          direction="left"
          onClick={() => page - 1 >= 0 && onChangePage?.(page - 1)}
          disabled={page === 0}
        >
          {'<'}
        </Arrows>
        {/* <ChevronLeftIcon
          style={{ cursor: 'pointer' }}
          fill={page - 1 >= 0 ? '#00abef' : '#819cad'}
          onClick={() => page - 1 >= 0 && onChangePage?.(page - 1)}
        /> */}

        {pageModel.map(pageNumber => {
          if (pageNumber === -1) return <span>...</span>;
          else
            return (
              <RoundedButton
                key={pageNumber}
                onClick={() => onChangePage?.(pageNumber - 1)}
                currentPage={pageNumber === page + 1}
              >
                {pageNumber}
              </RoundedButton>
            );
        })}
        {/* <ChevronRightIcon
          style={{ cursor: 'pointer' }}
          fill={
            page < totalPages - 1 ? Theme.colors.primary.default : '#819cad'
          }
          onClick={() => page + 1 < totalPages && onChangePage?.(page + 1)}
        /> */}
        <Arrows
          direction="left"
          onClick={() => page + 1 < totalPages && onChangePage?.(page + 1)}
          disabled={page + 1 === totalPages}
        >
          {'>'}
        </Arrows>
        <Arrows
          direction="left"
          onClick={() => onChangePage(totalPages - 1)}
          disabled={page === totalPages - 1}
        >
          {'>>'}
        </Arrows>
      </div>
    </ContainerPagination>
  );
};

export default TablePagination;
