import { Header } from 'components/Header';
import {
  useActivitiesReportFindMany,
  useCraFindOne,
  removeOffsetDate,
  useAdditionalActivityFindMany,
  getHumanDateMonthAndYear,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  useMissionFindOne,
  round,
  queryClient,
  additionalActivityDeleteMany,
  getAdditionalActivitiesTotalAmount,
  getStandByDutiesTotalAmount,
  getDecimalInput,
} from '@commons';
import {
  Box,
  SpinnerBox,
  Spinner,
  Text,
  Flex,
  ColorTextBox,
  LabelField,
  Cell,
  Grid,
  Divider,
  Button,
  CustomToolTip,
} from 'components/ui';
import { FeesIcon, DeleteIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ECraStatus,
  EadditionalActivityType,
  IJoinedAdditionalActivity,
  EadditionalActivityStatus,
  IJoinedMission,
  IStandByDutyLine,
  IAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
  EAdditionalActivityDeleteErrorType,
} from '@freelancelabs/teoreme-commons';
import { useTheme } from 'styled-components';
import Accordion from 'components/Accordion';
import { Theme } from 'styles';
import { AddIcon, DutyTimeIcon, TimeLapsIcon } from 'components/ui/icons';
import { showCreateOrUpdateStandByDutyModal } from 'components/modals/Activities/CreateOrUpdateStandByDutyModal';
import { showCreateOrUpdateExpensesModal } from 'components/modals/Activities/CreateOrUpdateExpensesModal';
import { showUpdateCraModal } from 'components/modals/Activities/UpdateCraModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { showSubmitAllActivitiesModal } from 'components/modals/Activities/SubmitAllActivitiesModal';
type ActivitiyDetailsProps = {
  //
};
const getStatusData = (
  status: ECraStatus | EadditionalActivityStatus
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
} => {
  switch (status) {
    case ECraStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case ECraStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case ECraStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validé`,
        textColor: Theme.colors.success.default,
      };
    case ECraStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusé`,
        textColor: Theme.colors.error.default,
      };
    case ECraStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivé`,
        textColor: Theme.colors.grey.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};
export const ActivitiyDetails: React.FC<
  React.PropsWithChildren<ActivitiyDetailsProps>
> = () => {
  const theme = useTheme();
  const history = useHistory();
  const showMessage = useShowMessage();
  const location: any = useLocation();
  const { missionRef, month } = useParams<{
    missionRef: string;
    month: string;
  }>();
  const monthFormat = new Date(month.replaceAll('-', '/'));
  const apiMonth = removeOffsetDate(monthFormat);
  useSetCrumbs(
    [location],
    [
      { label: 'CRA & Factures', path: '/invoices' },
      {
        label: "Déclaration d'activité",
        path: '/invoices/activities/list/pending',
      },
      {
        label: getHumanDateMonthAndYear(apiMonth) || '',
        path: location,
      },
    ]
  );
  const { data: mission } = useMissionFindOne(missionRef);
  const {
    data: reports,
    status: reportsStatus,
    isFetching: isFetchingAR,
  } = useActivitiesReportFindMany({
    filterObject: {
      month: apiMonth,
      mission: missionRef,
    },
    limit: 1,
    skip: 0,
    //sort: '-month',
  });
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  const activity = reports?.reports?.[0];
  const expensesUuids = activity?.expenses?.map((f: any) => f?.uuid as string);
  const standByDutiesUuids = activity?.standByDuties?.map(
    (sb: any) => sb?.uuid as string
  );
  const uuids =
    expensesUuids && standByDutiesUuids
      ? [...(expensesUuids as string[]), ...(standByDutiesUuids as string[])]
      : [];
  const { data: aAResponse, isFetching: isFetchingAA } =
    useAdditionalActivityFindMany(
      uuids?.length
        ? {
            filterObject:
              uuids?.length > 0
                ? {
                    uuid: {
                      $in: uuids,
                    },
                  }
                : undefined,
            limit: uuids?.length,
            skip: 0,
          }
        : undefined
    );
  const additionalActivities = aAResponse?.additionalActivities;
  const {
    data: cra,
    status: craStatus,
    isFetching: isFetchingCra,
  } = useCraFindOne(activity?.cra?.refCra, true);
  const estCustomer = cra?.estCustomer;
  const estProvider = cra?.estProvider;
  const contractor = cra?.contractor;

  const showErrors = (
    errorDeleteDetailsArray: EAdditionalActivityDeleteErrorType[]
  ) => {
    if (
      errorDeleteDetailsArray.find(
        (e: any) =>
          e.type === EAdditionalActivityDeleteErrorType.HAS_LINKED_DOCUMENT
      )
    ) {
      showMessage(
        'error',
        'Impossible de supprimer cette activité car une facture lui est associée'
      );
    } else {
      showMessage('error', 'Impossible de supprimer cette activité');
    }
  };

  const standByDutyFormulas = mission?.standByDutyFormulas;
  const standByDuties =
    additionalActivities?.filter(
      (aa: IJoinedAdditionalActivity) =>
        aa?.type === EadditionalActivityType.STAND_BY_DUTY
    ) || [];
  const expenses =
    additionalActivities?.filter(
      (aa: IJoinedAdditionalActivity) =>
        aa?.type === EadditionalActivityType.EXPENSE
    ) || [];

  const standByDutiesToBeSubmitted = standByDuties?.filter(
    (sbd: any) => sbd?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED
  );
  const expensesToBeSubmitted = expenses?.filter(
    (sbd: any) => sbd?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED
  );
  const submitedCraCount =
    (cra?.state as string) === ECraStatus?.TO_BE_SUBMITTED ? 1 : 0;
  const submitedCount =
    expensesToBeSubmitted?.length +
    standByDutiesToBeSubmitted?.length +
    submitedCraCount;
  const hasRejectedActivities =
    (cra?.state as string) === ECraStatus?.REJECTED ||
    standByDuties?.filter(
      (sbd: any) => sbd?.status === EadditionalActivityStatus?.REJECTED
    )?.length > 0;

  const submitAllElements = async () => {
    showSubmitAllActivitiesModal({
      month: apiMonth as Date,
      mission: mission as IJoinedMission,
      activities: [...expensesToBeSubmitted, ...standByDutiesToBeSubmitted],
      cra:
        (cra?.state as string) === ECraStatus?.TO_BE_SUBMITTED
          ? cra
          : undefined,
    });
  };
  const onDeleteAdditionalActictiy = async (
    aa: IJoinedAdditionalActivityWithInvoice
  ) => {
    const textType =
      aa?.type === EadditionalActivityType.STAND_BY_DUTY
        ? 'la prestation complémentaire'
        : 'la déclaration de frais';
    await showDialogModal({
      title: `Supprimer ${textType}`,
      text: `Êtes-vous sûr de vouloir supprimer ${textType} ?`,
      confirmLabel: 'Supprimer',
      cancelLabel: 'Ne pas Supprimer',
      beforeValidation: async () => {
        try {
          const { errorDeleteDetailsArray } =
            await additionalActivityDeleteMany({ uuids: [aa?.uuid] });
          if (
            errorDeleteDetailsArray?.length &&
            errorDeleteDetailsArray.length > 0
          ) {
            showErrors(errorDeleteDetailsArray);
          }
          queryClient.refetchQueries({
            queryKey: ['additionalActivities'],
            type: 'active',
          });
          queryClient?.refetchQueries({
            queryKey: ['ActivitiesReport'],
            type: 'active',
          });
        } catch (e) {
          //
        }
      },
    });
  };
  useEffect(() => {
    if (reportsStatus === 'success' && reports?.totalCount === 0) {
      history?.push('/404');
    }
  }, [craStatus, reportsStatus, reports]);
  // if (isFetchingAR) {
  //   return <SpinnerBox />;
  // }
  const getProviderExpensesTotalAmount = (
    aa: IAdditionalActivity | IJoinedAdditionalActivityWithInvoice
  ): number => {
    let total = 0;
    aa?.expenseLines?.forEach(expense => (total = total + expense?.amount));
    return round(total);
  };
  return (
    <Box width={1 / 1}>
      <Header tabs={false}>
        <Text mb={'5px'} variant="h1">
          Déclaration d'activité au Temps passé :{' '}
          {getHumanDateMonthAndYear(apiMonth)}
        </Text>
      </Header>
      <Box hidden={!isFetchingAR}>
        <SpinnerBox />
      </Box>
      <Box hidden={isFetchingAR}>
        <Grid cols={12}>
          <Cell
            x-span={{
              xs: '12',
              sm: '12',
              md: '8',
              lg: '8',
              xl: '8',
            }}
            mb={20}
            pr={10}
          >
            <Accordion
              defaultOpen={true}
              openOnTop
              propsBar={{
                borderRadius: '8px',
                backgroundColor: 'white',
                border: '1px solid #d0d4e3',
              }}
              childrenTop={
                <Flex
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-between"
                  width={1 / 1}
                >
                  <Flex alignContent="center" alignItems="center">
                    <TimeLapsIcon
                      width={'30px'}
                      height={'30px'}
                      fill={Theme?.colors?.primary?.default}
                    />
                    <Text ml={10} variant="h2">
                      Prestations standard
                    </Text>
                  </Flex>
                  <Flex alignContent="center" alignItems="center">
                    {isFetchingCra ? (
                      <Spinner />
                    ) : (
                      <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                        {`${
                          cra?.nbWorkingDays
                            ? `${cra?.nbWorkingDays} jours : ${round(
                                cra?.nbWorkingDays * cra?.contractorRate
                              )} €`
                            : 'N/A'
                        }`}
                      </ColorTextBox>
                    )}
                  </Flex>
                </Flex>
              }
              key={`cras`}
            >
              <Box mt={20}>
                {craStatus === 'success' && (
                  <Box
                    position="relative"
                    top={-25}
                    p={10}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor={'#d0d4e3'}
                  >
                    <Flex
                      width={1 / 1}
                      alignContent="center"
                      alignItems="center"
                    >
                      <LabelField
                        label="Déclaré le"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {cra?.submittedAt
                                ? getHumanDate(cra?.submittedAt as Date)
                                : 'N/A'}
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />
                      <LabelField
                        label="MONTANT TOTAL HT"
                        underline
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {`${getDecimalInput(
                                (cra?.nbWorkingDays || 0) *
                                  (cra?.contractorRate || 0),
                                3
                              )} €`}{' '}
                            </ColorTextBox>
                          </Box>
                        }
                      />
                      <LabelField
                        label="Statut"
                        mr={'4px'}
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox
                              mr={'4px'}
                              variantColor={
                                getStatusData(cra?.state as ECraStatus)
                                  ?.variantColor
                              }
                            >
                              {
                                getStatusData(cra?.state as ECraStatus)
                                  ?.statusText
                              }
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />
                      <Button
                        onClick={() =>
                          showUpdateCraModal({ refCra: cra?.refCra as string })
                        }
                        minWidth="125px"
                        height={'35.5px'}
                        mr={24}
                      >
                        {cra?.state === ECraStatus?.TO_BE_FILLED
                          ? 'Déclarer'
                          : cra?.state === ECraStatus?.REJECTED
                            ? 'Modifier'
                            : 'Consulter'}
                      </Button>
                    </Flex>
                  </Box>
                )}
              </Box>
            </Accordion>
            {standByDutyFormulas && standByDutyFormulas?.length > 0 && (
              <Accordion
                defaultOpen={true}
                openOnTop
                propsBar={{
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  border: '1px solid #d0d4e3',
                }}
                childrenTop={
                  <Flex justifyContent="space-between" width={1 / 1}>
                    <Flex alignContent="center" alignItems="center">
                      <DutyTimeIcon
                        width={'30px'}
                        height={'30px'}
                        fill={Theme?.colors?.primary?.default}
                      />
                      <Text ml={10} variant="h2">
                        Prestations complémentaires
                      </Text>
                      <Flex alignContent="center" alignItems="center">
                        <Button
                          ml={'10px'}
                          onClick={() =>
                            showCreateOrUpdateStandByDutyModal({
                              missionRef: cra?.mission?.reference as string,
                              month: cra?.month as Date,
                              method: 'CREATE',
                            })
                          }
                          minWidth="125px"
                          height={'35.5px'}
                          variant="ghost"
                        >
                          <Flex alignContent="center" alignItems="center">
                            <Box mr={10}> Ajouter </Box>
                            <AddIcon fill={Theme?.colors?.primary?.default} />
                          </Flex>
                        </Button>
                      </Flex>
                    </Flex>
                    <Flex
                      cursor="pointer"
                      ml={'5px'}
                      alignContent="center"
                      alignItems="center"
                    >
                      {isFetchingAA ? (
                        <Spinner />
                      ) : (
                        <ColorTextBox variantColor="primary" ml={5}>
                          Total :{' '}
                          {getAdditionalActivitiesTotalAmount(
                            standByDuties,
                            EadditionalActivityType.STAND_BY_DUTY,
                            'PROVIDER'
                          )}{' '}
                          €
                        </ColorTextBox>
                      )}
                    </Flex>
                  </Flex>
                }
                key={`STAND_BY_DUTIES`}
              >
                <Box mt={20}>
                  <Box
                    position="relative"
                    top={-25}
                    p={10}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor={'#d0d4e3'}
                  >
                    {standByDuties?.map((aa: any, index: number) => {
                      return (
                        <Box width={1 / 1}>
                          <Flex
                            width={1 / 1}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Box width={1 / 1}>
                              <LabelField
                                label="Déclaré le"
                                value={
                                  <Box mt={'10px'}>
                                    <ColorTextBox variantColor="primary">
                                      {aa?.submittedAt
                                        ? getHumanDate(aa?.submittedAt as Date)
                                        : 'N/A'}
                                    </ColorTextBox>
                                  </Box>
                                }
                                underline
                              />
                            </Box>
                            <Box width={1 / 1}>
                              <LabelField
                                label="Montant total HT"
                                value={
                                  <Box mt={'10px'}>
                                    <ColorTextBox variantColor="primary">
                                      {getStandByDutiesTotalAmount(
                                        aa?.standByDutyLines as IStandByDutyLine[],
                                        'PROVIDER'
                                      )}
                                      €
                                    </ColorTextBox>
                                  </Box>
                                }
                                underline
                              />
                            </Box>
                            <Box width={1 / 1}>
                              <LabelField
                                label="Statut"
                                value={
                                  <Box mt={'10px'}>
                                    <ColorTextBox
                                      variantColor={
                                        getStatusData(
                                          aa?.status as EadditionalActivityStatus
                                        )?.variantColor
                                      }
                                    >
                                      {
                                        getStatusData(
                                          aa?.status as EadditionalActivityStatus
                                        )?.statusText
                                      }
                                    </ColorTextBox>
                                  </Box>
                                }
                                underline
                              />
                            </Box>
                            <Button
                              onClick={() =>
                                showCreateOrUpdateStandByDutyModal({
                                  missionRef: aa?.mission?.reference,
                                  month: aa?.month as Date,
                                  method: 'UPDATE',
                                  uuid: aa?.uuid,
                                })
                              }
                              minWidth="125px"
                              height={'35.5px'}
                              mr={
                                ![
                                  EadditionalActivityStatus?.TO_BE_SUBMITTED,
                                  EadditionalActivityStatus?.TO_BE_FILLED,
                                ].includes(aa?.status)
                                  ? 24
                                  : ''
                              }
                            >
                              {aa?.status ===
                              EadditionalActivityStatus?.TO_BE_FILLED
                                ? 'Déclarer'
                                : aa?.status ===
                                    EadditionalActivityStatus?.REJECTED
                                  ? 'Modifier'
                                  : 'Consulter'}
                            </Button>
                            {(aa?.status ===
                              EadditionalActivityStatus?.TO_BE_SUBMITTED ||
                              aa?.status ===
                                EadditionalActivityStatus?.TO_BE_FILLED) && (
                              <CustomToolTip
                                color={theme?.colors?.error?.default}
                                text={'Supprimer la prestation'}
                                id={aa?.uuid}
                              >
                                <DeleteIcon
                                  cursor={'pointer'}
                                  height={24}
                                  width={24}
                                  fill={theme?.colors?.error?.default}
                                  onClick={() => onDeleteAdditionalActictiy(aa)}
                                />
                              </CustomToolTip>
                            )}
                          </Flex>
                          {index !== standByDuties?.length - 1 && <Divider />}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Accordion>
            )}
            {exepensesIsEnabled && (
              <Accordion
                defaultOpen={true}
                //disableOpen={!exepensesIsEnabled || expenses?.length === 0}
                openOnTop
                propsBar={{
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  border: '1px solid #d0d4e3',
                }}
                childrenTop={
                  <Flex justifyContent="space-between" width={1 / 1}>
                    <Flex alignContent="center" alignItems="center">
                      <FeesIcon
                        width={'30px'}
                        height={'30px'}
                        fill={Theme?.colors?.primary?.default}
                      />
                      <Text ml={10} variant="h2">
                        Frais
                      </Text>
                      <Flex alignContent="center" alignItems="center">
                        <Button
                          ml={'10px'}
                          onClick={() =>
                            showCreateOrUpdateExpensesModal({
                              missionRef: cra?.mission?.reference as string,
                              month: cra?.month as Date,
                              method: 'CREATE',
                            })
                          }
                          minWidth="125px"
                          height={'35.5px'}
                          variant="ghost"
                        >
                          <Flex alignContent="center" alignItems="center">
                            <Box mr={10}> Ajouter </Box>
                            <AddIcon fill={Theme?.colors?.primary?.default} />
                          </Flex>
                        </Button>
                      </Flex>
                    </Flex>

                    <Flex
                      cursor="pointer"
                      ml={'5px'}
                      alignContent="center"
                      alignItems="center"
                    >
                      {isFetchingAA ? (
                        <Spinner />
                      ) : (
                        <ColorTextBox variantColor="primary" ml={5}>
                          Total :{' '}
                          {getAdditionalActivitiesTotalAmount(
                            additionalActivities as IJoinedAdditionalActivityWithInvoice[],
                            EadditionalActivityType.EXPENSE,
                            'PROVIDER'
                          )}{' '}
                          €
                        </ColorTextBox>
                      )}
                    </Flex>
                  </Flex>
                }
                key={`EXPENSES`}
              >
                <Box
                  position="relative"
                  top={-5}
                  p={10}
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  borderBottom="1px solid"
                  borderColor={'#d0d4e3'}
                >
                  {expenses?.map((aa: any, index: number) => {
                    return (
                      <Box width={1 / 1}>
                        <Flex
                          width={1 / 1}
                          alignItems="center"
                          alignContent="center"
                        >
                          <Box width={1 / 1}>
                            <LabelField
                              label="Déclaré le"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {getHumanDate(aa?.createdAt as Date)}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={1 / 1}>
                            <LabelField
                              label="Montant total HT"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {`${getProviderExpensesTotalAmount(aa)} €`}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={1 / 1}>
                            <LabelField
                              label="Statut"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox
                                    variantColor={
                                      getStatusData(
                                        aa?.status as EadditionalActivityStatus
                                      )?.variantColor
                                    }
                                  >
                                    {
                                      getStatusData(
                                        aa?.status as EadditionalActivityStatus
                                      )?.statusText
                                    }
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Button
                            onClick={() =>
                              showCreateOrUpdateExpensesModal({
                                missionRef: aa?.mission?.reference,
                                month: aa?.month as Date,
                                method: 'UPDATE',
                                uuid: aa?.uuid,
                              })
                            }
                            minWidth="125px"
                            height={'35.5px'}
                            mr={
                              ![
                                EadditionalActivityStatus?.TO_BE_SUBMITTED,
                                EadditionalActivityStatus?.TO_BE_FILLED,
                              ].includes(aa?.status)
                                ? 24
                                : ''
                            }
                          >
                            {aa?.status ===
                            EadditionalActivityStatus?.TO_BE_FILLED
                              ? 'Déclarer'
                              : aa?.status ===
                                  EadditionalActivityStatus?.REJECTED
                                ? 'Modifier'
                                : 'Consulter'}
                          </Button>
                          {(aa?.status ===
                            EadditionalActivityStatus?.TO_BE_SUBMITTED ||
                            aa?.status ===
                              EadditionalActivityStatus?.TO_BE_FILLED) && (
                            <CustomToolTip
                              color={theme?.colors?.error?.default}
                              text={'Supprimer le frais'}
                              id={aa?.uuid}
                            >
                              <DeleteIcon
                                cursor={'pointer'}
                                height={24}
                                width={24}
                                fill={theme?.colors?.error?.default}
                                onClick={() => onDeleteAdditionalActictiy(aa)}
                              />
                            </CustomToolTip>
                          )}
                        </Flex>
                        {index !== expenses?.length - 1 && <Divider />}
                      </Box>
                    );
                  })}
                </Box>
              </Accordion>
            )}
          </Cell>
          <Cell
            x-span={{
              xs: '12',
              sm: '12',
              md: '4',
              lg: '4',
              xl: '4',
            }}
            mb={20}
            pl={10}
          >
            <Box>
              {/* <Text mb={20} variant="h1" color={Theme.colors.text.secondary}>
              {getHumanDateMonthAndYear(cra?.month as Date, true)}
            </Text> */}
              <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
                <Box p={10}>
                  <LabelField
                    label="Référence de la mission"
                    value={mission?.displayReference}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Client"
                    value={getEstablishmentName(estCustomer)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Établissement"
                    value={getEstablishmentName(estProvider)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Intervenant"
                    value={getFullName(contractor)}
                    underline
                    mb={10}
                  />
                </Box>
              </Box>
              {hasRejectedActivities && (
                <Box
                  mt={20}
                  width={1 / 1}
                  borderRadius={'5px'}
                  backgroundColor={'#ffedcc'}
                >
                  <Box p={10}>
                    <LabelField
                      label="Déclaration refusée"
                      value={
                        'Attention, une ou plusieurs déclarations ont été réfusées. Cliquez sur le bouton modifier pour les corriger et les re-soumettre.'
                      }
                      underline
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Cell>
          {(standByDutiesToBeSubmitted?.length > 0 ||
            expensesToBeSubmitted?.length > 0 ||
            cra?.state === ECraStatus?.TO_BE_SUBMITTED) && (
            <Cell
              x-span={{
                xs: '12',
                sm: '12',
                md: '8',
                lg: '8',
                xl: '8',
              }}
            >
              <Flex width={1 / 1} justifyContent={'flex-end'}>
                <Button
                  mt={10}
                  variant="filled"
                  onClick={() => submitAllElements()}
                >
                  Soumettre ( {submitedCount} )
                </Button>
              </Flex>
            </Cell>
          )}
          {cra?.state !== ECraStatus?.TO_BE_SUBMITTED &&
            cra?.state !== ECraStatus?.TO_BE_FILLED &&
            standByDutiesToBeSubmitted?.length === 0 &&
            expensesToBeSubmitted?.length === 0 && (
              <Cell
                x-span={{
                  xs: '12',
                  sm: '12',
                  md: '8',
                  lg: '8',
                  xl: '8',
                }}
              >
                <Flex width={1 / 1} justifyContent={'flex-end'}>
                  <Button
                    mt={10}
                    variant="filled"
                    onClick={() =>
                      history.push(
                        `/invoices/activities/billing/${missionRef}/${month}`
                      )
                    }
                  >
                    Déposer le(s) facture(s)
                  </Button>
                </Flex>
              </Cell>
            )}
        </Grid>
      </Box>
    </Box>
  );
};

ActivitiyDetails.displayName = 'ActivitiyDetails';

export default ActivitiyDetails;
