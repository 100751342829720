import { EInvoiceStatus, EInvoiceType } from '@freelancelabs/teoreme-commons';
import { add, getTime } from 'date-fns';
import { InvoiceStatusEnum } from './types';
import { snakeCase } from 'lodash';

export const getInvoiceState = (
  filter: InvoiceStatusEnum,
  selectedState: EInvoiceStatus | 'ALL'
): Array<EInvoiceStatus | InvoiceStatusEnum> => {
  if (filter === InvoiceStatusEnum.TO_BE_SUBMITTED) {
    if (selectedState === 'ALL') {
      return [
        EInvoiceStatus.TO_BE_FILLED,
        EInvoiceStatus.TO_BE_SUBMITTED,
        EInvoiceStatus.REJECTED,
      ];
    } else {
      return [selectedState];
    }
  }
  return [filter];
};
export const getObjectFilterInvoice = (
  debouncedFilterQuery = '',
  startDate: Date | null,
  endDate: Date | null,
  filterUrl: InvoiceStatusEnum,
  selectedStatus: EInvoiceStatus | 'ALL'
): any => {
  const filterObject = {
    invoiceType: {
      $in: [
        EInvoiceType?.PROVIDER,
        EInvoiceType?.PROVIDER_STAND_BY_DUTY,
        EInvoiceType?.PROVIDER_EXPENSE,
        EInvoiceType?.PROVIDER_MILESTONE,
      ],
    },
    status: { $in: getInvoiceState(filterUrl, selectedStatus) },
  };
  const queryFilter = [
    {
      'estCustomer.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.firstName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'contractor.lastName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.displayReference': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'mission.sage.structure': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.tradeName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard1': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard2': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      'estProvider.signBoard3': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
    {
      providerInvoiceRef: {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (startDate !== null && endDate !== null) {
    //@ts-ignore
    filterObject.$and = [
      {
        $expr: {
          $gte: [{ $toLong: '$normalizedDate' }, getTime(startDate)],
        },
      },
      {
        $expr: {
          $lte: [
            { $toLong: '$normalizedDate' },
            getTime(add(endDate, { months: 1 })),
          ],
        },
      },
    ];
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$and.push({ $or: queryFilter });
    }
  } else {
    if (debouncedFilterQuery.length > 0) {
      //@ts-ignore
      filterObject.$or = queryFilter;
    }
  }
  return filterObject;
};

export const getTabsTextsByState = (state: EInvoiceStatus) => {
  switch (snakeCase(state).toUpperCase()) {
    case EInvoiceStatus.VALIDATED: {
      return `Retrouvez ici l’ensemble des factures validées par votre conseiller Connecteed et pour lesquelles vous pouvez suivre le statut du paiement. A noter : si le paiement de votre facture est bloqué, pensez à vérifier que vos documents sont bien à jour dans PROVIGIS ou contactez votre conseiller Connecteed.`;
    }
    case EInvoiceStatus.TO_BE_SUBMITTED: {
      return 'Pour tous vos CRA validés, merci de saisir votre facture et de joindre votre facture au format PDF. Une fois complété, envoyez votre formulaire facture à votre conseiller Connecteed pour validation.';
    }
    case EInvoiceStatus.TO_BE_VALIDATED: {
      return 'Retrouvez ici l’ensemble des factures soumises pour vos missions et en attente de validation par un conseiller Connecteed. Une fois vos factures validées, leur paiement sera dû.';
    }
    case EInvoiceStatus.CANCELLED: {
      return "Retrouvez ici l’ensemble des factures annulées suite à l'émission d’un avoir.";
    }
    case EInvoiceStatus.PAID: {
      return `Retrouvez ici l’ensemble des factures payées.`;
    }
  }
};

export const getHiddenColumns = (innerWidth: number): string[] => {
  if (innerWidth <= 992) {
    return [
      'estCustomer.businessName',
      'estProvider.businessName',
      'totalWithTaxes',
    ];
  }

  return [];
};
