import { useInvoiceFindMany } from '@commons';
import { EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import { useDebounce } from 'hooks/useDebounce';
import { useParams } from 'react-router-dom';
import { getFilterByUrl, mapSortByToApi } from 'helpers/table';
import { getObjectFilterInvoice } from './helpers';
import { useInvoiceListStore } from 'store/invoice';

export const useFetchData = ({
  pageSize,
  sortBy,
}: any): {
  data: { invoices: any[]; totalCount: number; limit: number };
  status: String;
  isFetching: boolean;
  isPending: boolean;
} => {
  const { startDate, endDate, selectedStatus, searchQuery, pageIndex } =
    useInvoiceListStore();

  const { filter } = useParams<{ filter: string }>();
  const filterUrl = getFilterByUrl(
    filter,
    EInvoiceStatus,
    EInvoiceStatus.TO_BE_SUBMITTED
  );

  const debounceSearchQuery = useDebounce(searchQuery, 400);

  const filterObject = getObjectFilterInvoice(
    debounceSearchQuery,
    startDate,
    endDate,
    filterUrl,
    selectedStatus
  );

  const {
    data = { invoices: [], totalCount: -1, limit: 10 },
    status,
    isFetching,
    isPending,
  } = useInvoiceFindMany({
    filterObject,
    sort: mapSortByToApi(sortBy),
    limit: pageSize,
    skip: pageIndex * pageSize,
  });
  return {
    data,
    status,
    isFetching,
    isPending,
  };
};
