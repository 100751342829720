import { EFileType } from '@freelancelabs/teoreme-commons';
import { useFileUpload, round, downloadFile } from '@commons';
import React, { useState } from 'react';
import { Box, FileInput, Flex, Link, SpinnerBox, FormLabel, ALink } from './ui';
import { AddIcon, CloseIcon, EyeIcon } from './ui/icons';

type FileDescr = { fileName: string; fileLocation: string; eTag: string };
type FileListProps = {
  value?: FileDescr[];
  onChange: (files: FileDescr[]) => any;
  onChangeAction?: (files: FileDescr[]) => any;
  fileType: EFileType;
  missionRef?: string;
  actionName?: string;
  accept?: string;
  maxSize?: number;
  maxFile?: number;
  isDisabled?: boolean;
  previousAttachments?: FileDescr[];
};

export const FileList = ({
  value = [],
  onChange,
  onChangeAction,
  fileType,
  missionRef,
  actionName,
  accept,
  maxSize,
  maxFile,
  isDisabled,
  previousAttachments,
}: FileListProps) => {
  const [fileList, setFileList] = React.useState([]);
  const { mutateAsync: upload, status } = useFileUpload();
  const [sizeRemaining, setSizeRemaining] = useState(maxSize || 0);
  const loading = status === 'pending';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getFileSizeRemaining = () => {
    let filesSize = 0;
    fileList.forEach((file: File) => (filesSize = filesSize + file?.size));
    if (maxSize) {
      return maxSize - filesSize;
    }
    return maxSize;
  };

  const getFileSize = async () => {
    try {
      let size = 0;
      if (value?.[0]) {
        const fileData = await downloadFile(value?.[0]?.fileLocation);
        if (fileData) {
          //@ts-ignore
          size = size + fileData?.data?.size;
        }
      }
      if (value?.[1]) {
        const fileData1 = await downloadFile(value?.[1]?.fileLocation);
        if (fileData1) {
          //@ts-ignore
          size = size + fileData1?.data?.size;
        }
      }
      if (value?.[2]) {
        const fileData2 = await downloadFile(value?.[2]?.fileLocation);
        if (fileData2) {
          //@ts-ignore
          size = size + fileData2?.data?.size;
        }
      }

      if (maxSize) {
        setSizeRemaining(maxSize - size);
      } else {
        setSizeRemaining(maxSize || 0);
      }
    } catch (e) {
      //
    }
  };
  //const sizeRemaining = getFileSize(); //getFileSizeRemaining();
  React.useEffect(() => {
    getFileSize();
    if (onChangeAction) onChangeAction(value);
  }, [value]);

  return (
    <>
      {Array.isArray(value) &&
        value?.map((f, index) => (
          <Flex key={index} backgroundColor="#fff" pb={10}>
            <Box flex={1}>
              <Link iconLeft={<EyeIcon />}>
                <ALink
                  target={'_blank'}
                  href={`/file/${f?.fileLocation.replaceAll('/', '§')}`}
                >
                  <Box flex={1}>{f.fileName}</Box>
                </ALink>
              </Link>
            </Box>
            {!isDisabled && (
              <Link
                onClick={() => {
                  onChange?.(value.filter((_, i) => i !== index));
                  setFileList(fileList.filter((_, i) => i !== index));
                }}
              >
                <CloseIcon fontSize={15} />
              </Link>
            )}
          </Flex>
        ))}
      {loading ? (
        <SpinnerBox height={90} />
      ) : (
        <>
          <FileInput
            accept={accept}
            maxSize={sizeRemaining}
            //@ts-ignore
            isDisabled={(maxFile && value?.length >= maxFile) || isDisabled}
            //@ts-ignore
            onSelect={
              maxFile && value?.length >= maxFile
                ? 'MAX_FILE'
                : async files => {
                    //@ts-ignore
                    setFileList([...fileList, files[0]]);
                    const response = await upload({
                      missionRef: missionRef || '',

                      actionType: 'upload',
                      file: files[0],
                      fileType,
                    });
                    if (response) onChange?.([...value, response]);
                  }
            }
          >
            {maxFile && value?.length >= maxFile ? (
              <Link isDisabled>
                Vous avez ateint le maximum de fichier autorisé ( {maxFile} )
              </Link>
            ) : (
              <Link isDisabled={isDisabled} iconLeft={<AddIcon />}>
                {actionName ? actionName : `Ajouter une pièce jointe `}
              </Link>
            )}
          </FileInput>
          <Flex justifyContent="flex-end">
            <FormLabel
              mt={10}
              style={{ fontWeight: 300, textTransform: 'none' }}
            >
              {maxSize
                ? //@ts-ignore
                  ` ${round((sizeRemaining as number) / 1e6)} Mo restant`
                : ''}
            </FormLabel>
          </Flex>
        </>
      )}
    </>
  );
};
