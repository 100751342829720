import { FlatUser } from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { CreateStandByDutyForm } from 'forms/Activities/StandByDuty/CreateStandByDutyForm';
import { create } from 'react-modal-promise';
import { showDialogModal } from 'components/modals/DialogModal';
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  me?: FlatUser;
  missionRef: string;
  month: Date;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
};

export const CreateOrUpdateStandByDutyModal = ({
  onResolve,
  isOpen,
  layer,
  missionRef,
  month,
  method,
  uuid,
}: Props) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() =>
        showDialogModal({
          title:
            'Êtes-vous sûr(e) de vouloir quitter cette page sans enregistrer ?',
          text: 'Toutes vos modifications seront perdues.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
        }).then(confirm => {
          if (confirm) {
            onResolve(false);
          }
        })
      }
      width={1200}
      closeIcon
      layer={layer}
    >
      <CreateStandByDutyForm
        missionRef={missionRef}
        month={month}
        method={method}
        uuid={uuid}
        onResolve={(params: any) => onResolve(params)}
      />
    </ModalFrame>
  );
};

export const showCreateOrUpdateStandByDutyModal = create<Props>(
  CreateOrUpdateStandByDutyModal
);
