import styled from 'styled-components';
import {
  gridArea,
  GridAreaProps,
  gridColumn,
  GridColumnProps,
  gridRow,
  GridRowProps,
} from 'styled-system';
import { GetProps } from '../../types/helpers';
import { Box } from './Box';

export type ItemProps = GetProps<typeof Box> &
  GridColumnProps &
  GridRowProps &
  GridAreaProps;

export const Item = styled(Box)<ItemProps>`
  ${gridColumn};
  ${gridRow};
  ${gridArea};
`;
