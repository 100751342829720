import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

export const Separator = styled.div<BoxProps>`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  ${boxProperties}
  margin : 20px 0;
`;
