import { Header } from 'components/Header';
import {
  removeOffsetDate,
  getHumanDateMonthAndYear,
  useInvoiceFindMany,
  useMissionFindOne,
  getEstablishmentName,
  getFullName,
  round,
} from '@commons';
import {
  Box,
  Spinner,
  Text,
  Flex,
  ColorTextBox,
  LabelField,
  Cell,
  Grid,
  Button,
} from 'components/ui';

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  EInvoiceType,
  IJoinedInvoice,
  EInvoiceStatus,
} from '@freelancelabs/teoreme-commons';
import { snakeCase } from 'lodash';
import Accordion from 'components/Accordion';
import { Theme } from 'styles';
import { FeesIcon, TimeLapsIcon } from 'components/ui/icons';
type InvoicesDetailsProps = {
  //
};
const getStatusData = (
  status: EInvoiceStatus
): {
  variantColor: 'grey' | 'warning' | 'success' | 'error';
  statusText: string;
  textColor: string;
} => {
  switch (status) {
    case EInvoiceStatus.TO_BE_FILLED:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
    case EInvoiceStatus.TO_BE_SUBMITTED:
      return {
        variantColor: 'grey',
        statusText: 'A soumettre',
        textColor: Theme.colors.grey.default,
      };
    case EInvoiceStatus.TO_BE_VALIDATED:
      return {
        variantColor: 'warning',
        statusText: 'En attente de validation',
        textColor: Theme.colors.warning.default,
      };
    case EInvoiceStatus.VALIDATED:
      return {
        variantColor: 'success',
        statusText: `Validé`,
        textColor: Theme.colors.success.default,
      };
    case EInvoiceStatus.REJECTED:
      return {
        variantColor: 'error',
        statusText: `Refusé`,
        textColor: Theme.colors.error.default,
      };
    case EInvoiceStatus.CANCELLED:
      return {
        variantColor: 'error',
        statusText: `Annulé`,
        textColor: Theme.colors.error.default,
      };
    case EInvoiceStatus.ARCHIVED:
      return {
        variantColor: 'grey',
        statusText: `Archivé`,
        textColor: Theme.colors.grey.default,
      };
    case EInvoiceStatus.PAID:
      return {
        variantColor: 'success',
        statusText: `Payé`,
        textColor: Theme.colors.success.default,
      };
    default:
      return {
        variantColor: 'grey',
        statusText: 'A saisir',
        textColor: Theme.colors.grey.default,
      };
  }
};
export const InvoicesDetails: React.FC<
  React.PropsWithChildren<InvoicesDetailsProps>
> = () => {
  const history = useHistory();
  const { missionRef, month } = useParams<{
    missionRef: string;
    month: string;
  }>();
  const monthFormat = new Date(month.replaceAll('-', '/'));
  const apiMonth = removeOffsetDate(monthFormat);
  const { data: mission } = useMissionFindOne(missionRef);
  const { data: ResponseInvoices, isFetching: isFetchinginvoices } =
    useInvoiceFindMany(
      {
        filterObject: {
          month: apiMonth,
          mission: missionRef,
          invoiceType: {
            $in: [
              EInvoiceType.PROVIDER,
              EInvoiceType.PROVIDER_CREDIT_NOTE,
              EInvoiceType.PROVIDER_STAND_BY_DUTY,
              EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
              EInvoiceType.PROVIDER_EXPENSE,
              EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
            ],
          },
        },
        limit: 20,
        skip: 0,
        //sort: '-month',
      },
      {
        // DISABLE CACHE
        refetchOnMount: 'always',
        staleTime: 0,
        gcTime: 0,
      }
    );
  const providerInvoices = ResponseInvoices?.invoices?.filter(
    i => i?.invoiceType === EInvoiceType.PROVIDER
  );
  const creditNoteInvoices = ResponseInvoices?.invoices?.filter(
    i =>
      i?.invoiceType === EInvoiceType.PROVIDER_CREDIT_NOTE ||
      i?.invoiceType === EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE ||
      i?.invoiceType === EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE
  );
  const standByDutyInvoices = ResponseInvoices?.invoices?.filter(
    i => i?.invoiceType === EInvoiceType.PROVIDER_STAND_BY_DUTY
  );
  const expensesInvoices = ResponseInvoices?.invoices?.filter(
    i => i?.invoiceType === EInvoiceType.PROVIDER_EXPENSE
  );
  const estCustomer = mission?.customer?.establishment;
  const estProvider = mission?.provider?.establishment;
  const contractor = mission?.provider?.contractor;
  const getInvoicesTotalWithTaxes = (invoices?: IJoinedInvoice[]) => {
    let total = 0;
    if (invoices) {
      invoices?.forEach((invoice: IJoinedInvoice) => {
        total = total + (invoice?.totalWithTaxes as number) || 0;
      });
    }

    return round(total);
  };

  return (
    <Box width={1 / 1}>
      <Header tabs={false}>
        <Text mb={'5px'} variant="h1">
          Factures : {getHumanDateMonthAndYear(apiMonth)}
        </Text>
      </Header>
      <Grid cols={12}>
        <Cell
          x-span={{
            xs: '12',
            sm: '12',
            md: '8',
            lg: '8',
            xl: '8',
          }}
          mb={20}
          pr={10}
        >
          <Accordion
            defaultOpen={true}
            openOnTop
            propsBar={{
              borderRadius: '8px',
              backgroundColor: 'white',
              border: '1px solid #d0d4e3',
            }}
            childrenTop={
              <Flex
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
                width={1 / 1}
              >
                <Flex alignContent="center" alignItems="center">
                  <TimeLapsIcon
                    width={'30px'}
                    height={'30px'}
                    fill={Theme?.colors?.primary?.default}
                  />
                  <Text ml={10} variant="h2">
                    Prestations standard
                  </Text>
                </Flex>
                <Flex alignContent="center" alignItems="center">
                  {isFetchinginvoices ? (
                    <Spinner />
                  ) : (
                    <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                      {`${getInvoicesTotalWithTaxes(
                        providerInvoices as IJoinedInvoice[]
                      )} €`}
                    </ColorTextBox>
                  )}
                </Flex>
              </Flex>
            }
            key={`cras`}
          >
            <Box mt={20}>
              {providerInvoices?.map(invoice => (
                <Box
                  position="relative"
                  top={-25}
                  p={10}
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  borderBottom="1px solid"
                  borderColor={'#d0d4e3'}
                >
                  <Flex
                    mt={20}
                    width={1 / 1}
                    alignContent="center"
                    alignItems="center"
                  >
                    <LabelField
                      label="Total HT"
                      value={
                        <Box mt={'10px'}>
                          <ColorTextBox variantColor="primary">
                            {round(invoice?.totalBeforeTaxes as number)} €
                          </ColorTextBox>
                        </Box>
                      }
                      underline
                    />
                    <LabelField
                      label="Total TTC"
                      underline
                      value={
                        <Box mt={'10px'}>
                          <ColorTextBox variantColor="primary">
                            {round(invoice?.totalWithTaxes as number)} €
                          </ColorTextBox>
                        </Box>
                      }
                    />
                    <LabelField
                      label="Statut"
                      value={
                        <Box mt={'10px'}>
                          <ColorTextBox
                            variantColor={
                              getStatusData(invoice?.status as EInvoiceStatus)
                                ?.variantColor
                            }
                          >
                            {
                              getStatusData(invoice?.status as EInvoiceStatus)
                                ?.statusText
                            }
                          </ColorTextBox>
                        </Box>
                      }
                      underline
                    />

                    <Button
                      onClick={() => {
                        history.push(
                          `/invoices/time-spent/${snakeCase(
                            invoice?.status
                          )?.replaceAll('_', '-')}/${invoice?.uuid}`
                        );
                      }}
                      minWidth="195px"
                      height={'35.5px'}
                    >
                      {invoice?.status === EInvoiceStatus.TO_BE_FILLED
                        ? 'Déposer la facture'
                        : 'Consulter'}
                    </Button>
                  </Flex>
                </Box>
              ))}
            </Box>
          </Accordion>
          {standByDutyInvoices && standByDutyInvoices?.length > 0 && (
            <Accordion
              defaultOpen={true}
              openOnTop
              propsBar={{
                borderRadius: '8px',
                backgroundColor: 'white',
                border: '1px solid #d0d4e3',
              }}
              childrenTop={
                <Flex
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-between"
                  width={1 / 1}
                >
                  <Flex alignContent="center" alignItems="center">
                    <TimeLapsIcon
                      width={'30px'}
                      height={'30px'}
                      fill={Theme?.colors?.primary?.default}
                    />
                    <Text ml={10} variant="h2">
                      Prestations complémentaires
                    </Text>
                  </Flex>
                  <Flex alignContent="center" alignItems="center">
                    {isFetchinginvoices ? (
                      <Spinner />
                    ) : (
                      <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                        {`${getInvoicesTotalWithTaxes(
                          standByDutyInvoices as IJoinedInvoice[]
                        )} €`}
                      </ColorTextBox>
                    )}
                  </Flex>
                </Flex>
              }
              key={`STAND_BY_DUTIES`}
            >
              <Box mt={20}>
                {standByDutyInvoices?.map((invoice, index) => (
                  <Box
                    position="relative"
                    top={-25}
                    p={10}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor={'#d0d4e3'}
                  >
                    <Flex
                      width={1 / 1}
                      alignContent="center"
                      alignItems="center"
                    >
                      <LabelField
                        label="Total HT"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {round(invoice?.totalBeforeTaxes as number)} €
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />
                      <LabelField
                        label="Total TTC"
                        underline
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {round(invoice?.totalWithTaxes as number)} €
                            </ColorTextBox>
                          </Box>
                        }
                      />
                      <LabelField
                        label="Statut"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox
                              variantColor={
                                getStatusData(invoice?.status as EInvoiceStatus)
                                  ?.variantColor
                              }
                            >
                              {
                                getStatusData(invoice?.status as EInvoiceStatus)
                                  ?.statusText
                              }
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />

                      <Button
                        onClick={() => {
                          history.push(
                            `/invoices/time-spent/${snakeCase(
                              invoice?.status
                            )?.replaceAll('_', '-')}/${invoice?.uuid}`
                          );
                        }}
                        minWidth="195px"
                        height={'35.5px'}
                      >
                        {invoice?.status === EInvoiceStatus.TO_BE_FILLED
                          ? 'Déposer la facture'
                          : 'Consulter'}
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Box>
            </Accordion>
          )}
          {expensesInvoices && expensesInvoices?.length > 0 && (
            <Accordion
              defaultOpen={true}
              openOnTop
              propsBar={{
                borderRadius: '8px',
                backgroundColor: 'white',
                border: '1px solid #d0d4e3',
              }}
              childrenTop={
                <Flex
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-between"
                  width={1 / 1}
                >
                  <Flex alignContent="center" alignItems="center">
                    <FeesIcon
                      width={'30px'}
                      height={'30px'}
                      fill={Theme?.colors?.primary?.default}
                    />
                    <Text ml={10} variant="h2">
                      Frais
                    </Text>
                  </Flex>
                  <Flex alignContent="center" alignItems="center">
                    {isFetchinginvoices ? (
                      <Spinner />
                    ) : (
                      <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                        {`${getInvoicesTotalWithTaxes(
                          expensesInvoices as IJoinedInvoice[]
                        )} €`}
                      </ColorTextBox>
                    )}
                  </Flex>
                </Flex>
              }
              key={`EXPENSES`}
            >
              <Box mt={20}>
                {expensesInvoices?.map((invoice, index) => (
                  <Box
                    position="relative"
                    top={-25}
                    p={10}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor={'#d0d4e3'}
                  >
                    <Flex
                      width={1 / 1}
                      alignContent="center"
                      alignItems="center"
                    >
                      <LabelField
                        label="Total HT"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {round(invoice?.totalBeforeTaxes as number)} €
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />
                      <LabelField
                        label="Total TTC"
                        underline
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {round(invoice?.totalWithTaxes as number)} €
                            </ColorTextBox>
                          </Box>
                        }
                      />
                      <LabelField
                        label="Statut"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox
                              variantColor={
                                getStatusData(invoice?.status as EInvoiceStatus)
                                  ?.variantColor
                              }
                            >
                              {
                                getStatusData(invoice?.status as EInvoiceStatus)
                                  ?.statusText
                              }
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />

                      <Button
                        onClick={() => {
                          history.push(
                            `/invoices/time-spent/${snakeCase(
                              invoice?.status
                            )?.replaceAll('_', '-')}/${invoice?.uuid}`
                          );
                        }}
                        minWidth="195px"
                        height={'35.5px'}
                      >
                        {invoice?.status === EInvoiceStatus.TO_BE_FILLED
                          ? 'Déposer la facture'
                          : 'Consulter'}
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Box>
            </Accordion>
          )}
          {creditNoteInvoices && creditNoteInvoices?.length > 0 && (
            <Accordion
              defaultOpen={true}
              openOnTop
              propsBar={{
                borderRadius: '8px',
                backgroundColor: 'white',
                border: '1px solid #d0d4e3',
              }}
              childrenTop={
                <Flex
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-between"
                  width={1 / 1}
                >
                  <Flex alignContent="center" alignItems="center">
                    <TimeLapsIcon
                      width={'30px'}
                      height={'30px'}
                      fill={Theme?.colors?.primary?.default}
                    />
                    <Text ml={10} variant="h2">
                      Avoirs
                    </Text>
                  </Flex>
                  <Flex alignContent="center" alignItems="center">
                    {isFetchinginvoices ? (
                      <Spinner />
                    ) : (
                      <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                        {`${getInvoicesTotalWithTaxes(
                          creditNoteInvoices as IJoinedInvoice[]
                        )} €`}
                      </ColorTextBox>
                    )}
                  </Flex>
                </Flex>
              }
              key={`CREDIT_NOTE`}
            >
              <Box mt={20}>
                {creditNoteInvoices?.map((invoice, index) => (
                  <Box
                    position="relative"
                    top={-25}
                    p={10}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom="1px solid"
                    borderColor={'#d0d4e3'}
                  >
                    <Flex
                      width={1 / 1}
                      alignContent="center"
                      alignItems="center"
                    >
                      <LabelField
                        label="Total HT"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {invoice?.totalBeforeTaxes} €
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />
                      <LabelField
                        label="Total TTC"
                        underline
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox variantColor="primary">
                              {round(invoice?.totalWithTaxes as number)} €
                            </ColorTextBox>
                          </Box>
                        }
                      />
                      <LabelField
                        label="Statut"
                        value={
                          <Box mt={'10px'}>
                            <ColorTextBox
                              variantColor={
                                getStatusData(invoice?.status as EInvoiceStatus)
                                  ?.variantColor
                              }
                            >
                              {
                                getStatusData(invoice?.status as EInvoiceStatus)
                                  ?.statusText
                              }
                            </ColorTextBox>
                          </Box>
                        }
                        underline
                      />

                      <Button
                        onClick={() => {
                          history.push(
                            `/invoices/time-spent/${snakeCase(
                              invoice?.status
                            )?.replaceAll('_', '-')}/${invoice?.uuid}`
                          );
                        }}
                        minWidth="195px"
                        height={'35.5px'}
                      >
                        {invoice?.status === EInvoiceStatus.TO_BE_FILLED
                          ? 'Déposer la facture'
                          : 'Consulter'}
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Box>
            </Accordion>
          )}
        </Cell>
        <Cell
          x-span={{
            xs: '12',
            sm: '12',
            md: '4',
            lg: '4',
            xl: '4',
          }}
          mb={20}
          pl={10}
        >
          <Box>
            {/* <Text mb={20} variant="h1" color={Theme.colors.text.secondary}>
              {getHumanDateMonthAndYear(cra?.month as Date, true)}
            </Text> */}
            <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
              <Box p={10}>
                <LabelField
                  label="Référence de la mission"
                  value={mission?.displayReference}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Client"
                  value={getEstablishmentName(estCustomer)}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Établissement"
                  value={getEstablishmentName(estProvider)}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Intervenant"
                  value={getFullName(contractor)}
                  underline
                  mb={10}
                />
              </Box>
            </Box>
          </Box>
        </Cell>
        <Cell
          x-span={{
            xs: '12',
            sm: '12',
            md: '8',
            lg: '8',
            xl: '8',
          }}
        >
          <Flex width={1 / 1} justifyContent={'flex-end'}>
            <Button
              mt={10}
              variant="ghost"
              onClick={() =>
                history.push(`/invoices/activities/${missionRef}/${month}`)
              }
            >
              Revenir à la déclaration
            </Button>
          </Flex>
        </Cell>
      </Grid>
    </Box>
  );
};

InvoicesDetails.displayName = 'InvoicesDetails';

export default InvoicesDetails;
