import {
  registerInterceptor,
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_SEED_BUILD_ID,
  REACT_APP_SEED_STAGE_NAME,
  queryClient,
  REACT_APP_MAINTENANCE_MODE,
  isProviderMaintenance,
  initialize as initializeAuth,
} from '@commons';
import { QueryClientProvider } from '@tanstack/react-query';
import Translator from 'components/Translator';
import { CssReset } from 'components/ui';
import Notifications from 'components/ui/Notifications';
import { BreadCrumbProvider } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useEffect } from 'react';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { Router } from 'routes';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, Theme } from 'styles';
import { InactivityCheck } from 'components/InactivityCheck';
import { StageRibbon } from 'components/StageRibbon';
import { AnonymRibbon } from 'components/AnonymRibbon';
import { CheckBuild } from 'components/CheckBuild';
import { IntercomProvider } from 'react-use-intercom';
import { useStatics } from 'hooks/global';
import Maintenance from 'Maintenance';

const RegisterAxiosInterceptor = () => {
  const alert = useShowMessage();
  useStatics();

  useEffect(() => {
    registerInterceptor(alert);
  }, []);
  return null;
};

console.log('VERSION :', REACT_APP_SEED_BUILD_ID);
initializeAuth();

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMaintenance = isProviderMaintenance(REACT_APP_MAINTENANCE_MODE);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={Theme}>
        <CssReset />
        <GlobalStyles />
        <Translator>
          <RegisterAxiosInterceptor />
          <BreadCrumbProvider>
            <ReactRouter>
              <InactivityCheck />
              <Notifications />
              {REACT_APP_SEED_STAGE_NAME !== 'production' && <StageRibbon />}
              <AnonymRibbon />
              <IntercomProvider
                autoBoot
                appId={REACT_APP_INTERCOM_APP_ID as string}
              >
                {isMaintenance ? (
                  <Maintenance />
                ) : (
                  <CheckBuild>
                    <Router />
                  </CheckBuild>
                )}
              </IntercomProvider>
              <ModalContainer />
            </ReactRouter>
          </BreadCrumbProvider>
        </Translator>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
export default App;
