import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { lighten } from 'polished';
import { Box, BoxProps } from './Box';
import { Spinner } from './Spinner';

const ButtonStyled = styled(Box)<{
  variant?: 'filled' | 'ghost' | 'warning' | 'error' | 'success' | 'ghostError';
}>`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 22.5px;
  background-color: ${p => p.theme.colors.primary.default};
  color: ${p => p.theme.colors.primary.white};

  &:hover {
    background-color: ${p =>
      p.variant === 'filled' && lighten(0.15, p.theme.colors.primary.default)};
  }

  ${props =>
    props.variant === 'ghost' &&
    css`
      color: ${props.theme.colors.primary.default};
      background-color: transparent;
      border: 2px solid ${props.theme.colors.primary.default};
    `}
  ${props =>
    props.variant === 'ghostError' &&
    css`
      color: ${props.theme.colors.error.default};
      background-color: transparent;
      border: 2px solid ${props.theme.colors.error.default};
    `}
  ${props =>
    props.variant === 'warning' &&
    css`
      color: 'white';
      background-color: ${props.theme.colors.warning.default};
      border: 2px solid ${props.theme.colors.warning.default};
    `}
      ${props =>
    props.variant === 'error' &&
    css`
      color: 'white';
      background-color: ${props.theme.colors.error.default};
      border: 2px solid ${props.theme.colors.error.default};
    `}
    ${props =>
    props.variant === 'success' &&
    css`
      color: 'white';
      background-color: ${props.theme.colors.success.default};
      border: 2px solid ${props.theme.colors.success.default};
    `}
    &:disabled {
    background-color: ${p => lighten(0.6, p.theme.colors.primary.default)};
    color: ${p => lighten(0.3, p.theme.colors.primary.default)};
    border: none;
    cursor: not-allowed;
  }
`;

const Loader = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
`;

type ButtonProps = BoxProps &
  Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as'> & {
    isLoading?: boolean;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    variant?:
      | 'filled'
      | 'ghost'
      | 'warning'
      | 'error'
      | 'success'
      | 'ghostError';
    size?: 'sm' | 'md' | 'xl';
  };

export const Button = ({
  children,
  isLoading,
  type,
  disabled,
  isDisabled,
  isFullWidth,
  size,
  variant = 'filled',
  ...props
}: ButtonProps) => {
  const theme = useTheme();
  return (
    <ButtonStyled
      width={isFullWidth ? '100%' : 'auto'}
      {...props}
      as="button"
      disabled={isDisabled || disabled}
      type={type as any}
      variant={variant}
    >
      <Box opacity={isLoading ? 0 : 1}>{children}</Box>
      {isLoading && (
        <Loader>
          <Spinner
            color={
              variant === 'filled' && !isDisabled
                ? theme.colors.primary.white
                : theme.colors.primary.default
            }
          />
        </Loader>
      )}
    </ButtonStyled>
  );
};
