import {
  getEstablishmentName,
  getFullName,
  getHumanDate,
  queryClient,
  useAdditionalActivityUpdateOne,
  useCreateAditionalActivity,
  useAdditionalActivityFindOne,
  round,
  BUDGET_COMPARISON_TOLERANCE,
  lenientLte,
} from '@commons';
import {
  EadditionalActivityStatus,
  EadditionalActivityType,
  IJoinedAdditionalActivity,
  IJoinedMission,
} from '@freelancelabs/teoreme-commons';
//import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  FileListControlled,
  Flex,
  FormControl,
  LabelField,
  NumberFormInputControlled,
  Text,
} from 'components/ui';

import { useShowMessage } from 'hooks/useShowMessage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from 'styles';
import TextInformation from 'components/TextInformation';
import { useHistory } from 'react-router-dom';

type FormValues = any;
type MilestonesDetailsFormProps = {
  mission: IJoinedMission;
  mileStone?: IJoinedAdditionalActivity;
  method: 'CREATE' | 'UPDATE';
  infoHidden?: boolean;
  isDisabled?: boolean;
  onResolve?: (data: any) => any | void;
  onClose?: (value?: any) => void;
};

export const MilestonesDetailsForm = ({
  method: methodProps,
  mission,
  mileStone: mileStoneProps,
  infoHidden,
  isDisabled,
  onResolve,
  onClose,
}: MilestonesDetailsFormProps) => {
  const history = useHistory();
  const [uuid, setUuid] = useState(mileStoneProps?.uuid);
  const { data: mileStone } = useAdditionalActivityFindOne(uuid);
  const [method, setMethod] = useState(methodProps);
  const [loading, setLoading] = useState(false);
  const [loadingLater, setLoadingLater] = useState(false);
  const [colorStepper, setColorStepper] = useState(
    Theme?.colors?.primary?.default
  );
  const showMessage = useShowMessage();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      milestoneAmount: mileStone?.milestoneAmount,
    },
  });
  const { mutateAsync: createAdditionalActivity } =
    useCreateAditionalActivity();
  const { mutateAsync: updateAdditionalActivity } =
    useAdditionalActivityUpdateOne();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSaveLater = async () => {
    const formValues = getValues();
    setLoadingLater(true);
    try {
      await updateAdditionalActivity({
        uuid: mileStone?.uuid,
        saveAndFinishLater: true,
        status: EadditionalActivityStatus.TO_BE_VALIDATED,
        milestoneAmount: formValues?.milestoneAmount,
        attachments: formValues?.attachments,
      });
      showMessage('success', 'La prestation à été mis à jour');
      queryClient?.refetchQueries({
        queryKey: [mileStone?.uuid],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      queryClient?.refetchQueries({
        queryKey: [mission?.reference],
        type: 'active',
      });
      setLoadingLater(false);
    } catch (e) {
      setLoadingLater(false);
    }
  };
  const onSubmit = async (formValues: FormValues) => {
    let providerInvoice;
    setLoading(true);
    if (method === 'UPDATE') {
      try {
        const mileStoneUpdated = await updateAdditionalActivity({
          uuid: mileStone?.uuid,
          //saveAndFinishLater: true,
          status: EadditionalActivityStatus.TO_BE_VALIDATED,
          milestoneAmount: formValues?.milestoneAmount,
          attachments: formValues?.attachments,
        });
        queryClient?.refetchQueries({
          queryKey: [mileStone?.uuid],
          type: 'active',
        });
        queryClient?.refetchQueries({
          queryKey: ['additionalActivities'],
          type: 'active',
        });
        queryClient?.refetchQueries({
          queryKey: [mission?.reference],
          type: 'active',
        });
        setLoading(false);
        providerInvoice = mileStoneUpdated?.providerInvoice;
      } catch (e) {
        setLoading(false);
      }
    }
    if (method === 'CREATE') {
      setLoading(true);
      try {
        const mileStoneCreated = await createAdditionalActivity({
          //saveAndFinishLater: true,
          status: EadditionalActivityStatus.TO_BE_VALIDATED,
          type: EadditionalActivityType.MILESTONE,
          milestoneAmount: formValues?.milestoneAmount,
          attachments: formValues?.attachments,
          missionReference: mission?.reference,
        });
        setUuid(mileStoneCreated?.uuid);
        setMethod('UPDATE');
        queryClient?.refetchQueries({ queryKey: ['milestoneReport'] });
        queryClient?.refetchQueries({
          queryKey: ['additionalActivities'],
          type: 'active',
        });
        queryClient?.refetchQueries({
          queryKey: [mission?.reference],
          type: 'active',
        });
        setLoading(false);
        providerInvoice = mileStoneCreated?.providerInvoice;
      } catch (e) {
        setLoading(false);
      }
    }
    if (providerInvoice) {
      history.push(`/invoices/time-spent/to-be-submitted/${providerInvoice}`);
    }
    if (onClose) {
      onClose();
    }
  };

  const contractorRate = mission?.billing?.contractorRate || 0;
  const milestoneAmount = watch('milestoneAmount');
  const consumedPurchaseBudget = mission?.billing?.consumedPurchaseBudget || 0;
  const getCurrentConsumedBudget = () => {
    if (
      method === 'CREATE' ||
      mileStone?.status === EadditionalActivityStatus.REJECTED
    ) {
      return consumedPurchaseBudget + Number(milestoneAmount || 0);
    }
    return (
      consumedPurchaseBudget -
      (mileStone?.milestoneAmount || 0) +
      Number(milestoneAmount)
    );
  };
  const currentConsumedBudget = getCurrentConsumedBudget();
  const stepperPercent = () => {
    const percent = (currentConsumedBudget / (contractorRate || 0)) * 100;
    if (percent > 100) {
      if (colorStepper !== Theme?.colors?.error?.default) {
        setColorStepper(Theme?.colors?.error?.default);
      }

      return '100%';
    }
    if (percent >= 80) {
      if (colorStepper !== Theme?.colors?.warning?.default) {
        setColorStepper(Theme?.colors?.warning?.default);
      }
    } else {
      if (colorStepper !== Theme?.colors?.primary?.default) {
        setColorStepper(Theme?.colors?.primary?.default);
      }
    }

    return `${percent}%`;
  };

  const stepperWidth = stepperPercent();
  const checkIsEditable = () => {
    if (method === 'CREATE') {
      return true;
    }
    if (isDisabled) {
      return false;
    }
    if (
      mileStone?.status === EadditionalActivityStatus.TO_BE_VALIDATED ||
      mileStone?.status === EadditionalActivityStatus.VALIDATED ||
      mileStone?.status === EadditionalActivityStatus.ARCHIVED
    ) {
      return false;
    }
    return true;
  };
  const isEditable = checkIsEditable();
  const estCustomer = mileStone?.estCustomer?.uuid
    ? mileStone?.estCustomer
    : mission?.customer?.establishment;
  const estProvider = mileStone?.estProvider?.uuid
    ? mileStone?.estProvider
    : mission?.provider?.establishment;
  const contractor = mileStone?.contractor?.uuid
    ? mileStone?.contractor
    : mission?.provider?.contractor;
  return (
    <Box>
      <Flex>
        <Box width={infoHidden ? 12 / 12 : 8 / 12} pr={10}>
          {mileStone?.rejectReason &&
            mileStone?.status === EadditionalActivityStatus?.REJECTED && (
              <Box>
                <TextInformation variant="error" width={1 / 1} mb={10}>
                  {`Cette prestation à été refusé le ${new Date(
                    mileStone?.statusChangedAt as Date
                  ).toLocaleDateString()} pour cause de : ${mileStone?.rejectReason}`}
                </TextInformation>
              </Box>
            )}
          <Box mb={20}>
            <Flex
              width={1 / 1}
              zIndex={100}
              justifyContent="space-around"
              borderBottom={'12px solid #eaeefc'}
              borderRadius={'6px'}
            >
              <Box
                position="absolute"
                left={0}
                bottom={-12}
                //@ts-ignore
                width={stepperWidth}
                borderRadius={'6px'}
                borderBottom={`12px solid ${colorStepper}`}
              />
            </Flex>
            <Text mt={20} width={1 / 1}>
              {`Vous avez consommé ${round(
                currentConsumedBudget || 0
              )} € sur ${contractorRate} € prévus sur cette mission.`}
            </Text>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} /> */}
            <FormControl
              label="Montant HT à facturer"
              errorMessage={errors?.milestoneAmount?.message}
            >
              <NumberFormInputControlled
                isDisabled={!isEditable}
                control={control}
                isFullWidth
                name="milestoneAmount"
                type="number"
                rules={{
                  required: 'Ce champ est requis',
                  validate: (value: number) => {
                    let result = 0;
                    let remainingPurchBudget =
                      (mission?.billing?.contractorRate || 0) -
                      (mission.billing.consumedPurchaseBudget || 0);
                    if (
                      method === 'CREATE' ||
                      mileStone?.status === EadditionalActivityStatus.REJECTED
                    ) {
                      result = consumedPurchaseBudget + Number(value);
                    } else {
                      remainingPurchBudget =
                        (mission?.billing?.contractorRate || 0) -
                        (mission.billing.consumedPurchaseBudget || 0) +
                        (mileStone?.milestoneAmount || 0);
                      result =
                        consumedPurchaseBudget -
                        (mileStone?.milestoneAmount || 0) +
                        Number(value);
                    }
                    if (Number(value) <= 0.01) {
                      return 'La valeur doit être supérieur à 0.01';
                    }
                    //console.log('remainingPurchBudget', remainingPurchBudget);

                    if (
                      !lenientLte(
                        Number(value),
                        Number(remainingPurchBudget),
                        BUDGET_COMPARISON_TOLERANCE
                      )
                    ) {
                      return `Le montant saisi ${result} € dépasse le budget  ${Number(
                        contractorRate
                      )} €`;
                    }
                    return undefined;
                  },
                }}
              />
            </FormControl>

            <FormControl
              mb={-20}
              label="Justificatifs"
              errorMessage={errors?.attachments?.message}
            />
            <FileListControlled
              control={control}
              maxFile={1}
              name="attachments"
              missionRef={mission?.reference}
              isDisabled={!isEditable}
              actionName={'Importer un justificatif'}
              accept=".pdf"
              defaultValue={mileStone?.attachments as any}
              // onChangeAction={(e: any) => {
              //   setError('attachments', { message: undefined });
              // }}
            />
            <Box>
              <Flex justifyContent="flex-end" mt={20}>
                {/* <Button
                    mr={10}
                    key="Cancel"
                    type="button"
                    onClick={() =>
                      onClose
                        ? onClose()
                        : history.push('/invoices/cra/to-be-submitted')
                    }
                    variant="ghost"
                  >
                    Annuler
                  </Button> */}
                {isEditable && (
                  <>
                    <Button
                      key="submit"
                      type="submit"
                      isLoading={loading}
                      mr={10}
                      isDisabled={!isEditable || loading || loadingLater}
                    >
                      Enregistrer
                    </Button>
                  </>
                )}
              </Flex>
            </Box>
          </form>
        </Box>
        <Box hidden={infoHidden} width={4 / 12}>
          <Box>
            <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
              <Box p={10}>
                <LabelField
                  label="Date de mission"
                  value={`${getHumanDate(
                    mission?.startAt as Date
                  )} au ${getHumanDate(mission?.endAt as Date)}`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Référence de la mission"
                  value={mission?.displayReference}
                  linkValue={`/delivery/missions/${mission?.reference}/gescom-create`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Client"
                  value={getEstablishmentName(estCustomer)}
                  linkValue={`/clients/establishments/${estCustomer?.uuid}/information`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Fournisseur"
                  value={getEstablishmentName(estProvider)}
                  linkValue={`/providers/establishments/${estProvider?.uuid}/information`}
                  underline
                  mb={10}
                />
                <LabelField
                  label="Intervenant"
                  value={getFullName(contractor)}
                  linkValue={`/providers/contractors/${contractor?.uuid}`}
                  underline
                  mb={10}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
