import React from 'react';

import { HelpIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Box } from 'components/ui';

const IconModal = () => (
  <Box cursor="pointer" onClick={() => showContactUsModal()}>
    <HelpIcon height="50px" width="50px" id="open-modal-contact" />
  </Box>
);

export default IconModal;
