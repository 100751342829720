import { ReactNode } from 'react';
import styled from 'styled-components';
import { boxProperties, BoxProps } from './Box';

type StatusProps = {
  children?: ReactNode;
  variantColor?: 'primary' | 'grey' | 'error' | 'success' | 'warning' | 'grey';
};
export const Status = styled.span<BoxProps & StatusProps>`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${props =>
    props.theme.colors[props.variantColor || 'primary'].default};
  ${boxProperties}

  &:before {
    content: '';
    height: 7px;
    width: 7px;
    min-width: 7px;
    border-radius: 100%;
    margin-right: 10px;
    background-color: ${props =>
      props.theme.colors[props.variantColor || 'primary'].default};
  }
`;
