import * as React from 'react';
import PhoneInputComponent from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { BoxProps } from 'components/ui';
import { Container } from './styles';

// https://www.npmjs.com/package/react-phone-input-2?activeTab=readme
export type PhoneInputProps = BoxProps & {
  onChange?: (phone: string) => void;
  value?: string;
  disabled?: boolean;
  isFullWidth?: boolean;
  country?: string;
  onError?: (value: boolean) => void;
};
export const PhoneInput: React.FC<React.PropsWithChildren<PhoneInputProps>> = ({
  onChange,
  isFullWidth,
  onError,

  ...props
}) => {
  const handleChange = (number: string) => {
    onChange && onChange(number.replace(/\s/g, ''));
  };

  return (
    <Container {...props} isFullWidth={isFullWidth}>
      <PhoneInputComponent
        isValid={(value, country) => {
          if (value.replace(/\s/g, '').length === 0 || value === '33') {
            if (onError && typeof onError === 'function') {
              onError(false);
              return true;
            }
          }
          if ((country as any).countryCode === '33' && value.length < 11) {
            if (onError && typeof onError === 'function') {
              onError(true);
            }
            return false;
          } else {
            if (onError && typeof onError === 'function') {
              onError(false);
            }
            return true;
          }
        }}
        onChange={handleChange}
        masks={{ fr: '. .. .. .. ..' }}
        prefix={''}
        country={props.country ? props.country : 'fr'}
        {...props}
      />
    </Container>
  );
};

export default PhoneInput;
