import React, { Fragment } from 'react';
import { ALink } from './ALink';
import { Flex } from './Flex';
import { Text } from './Text';
import { useTheme } from 'styled-components';

type BreadCrumbsProps = {
  crumbs: {
    label: string;
    path: string;
  }[];
};

export const BreadCrumb = ({ crumbs }: BreadCrumbsProps) => {
  const theme = useTheme();
  return (
    <Flex>
      {crumbs?.map((c, index) => (
        <Fragment key={index}>
          <Text
            fontSize={11}
            color={
              index + 1 < crumbs.length
                ? theme.colors.primary.default
                : theme.colors.text.secondary
            }
          >
            <ALink color="primary.light" href={c.path}>
              {c.label}
            </ALink>
          </Text>
          {index !== crumbs?.length - 1 && (
            <Text fontSize={11} color="primary.light" m="0 5px">
              {'>'}
            </Text>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
