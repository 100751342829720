import { useProviderStatistics } from '@commons';
import { BienvenuIcon } from 'components/icons';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Flex, Cell, Text } from 'components/ui';
import React from 'react';
import { LastNotifications } from './LastNotifications';
import { useBreadCrumb } from 'hooks/breadCrumb';
import styled from 'styled-components';
import { Template } from 'components/Template';

const Container = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  max-width: ${p => p.theme.innerSize}px;
  padding-top: 50px;
  position: relative;
`;

export const Dashboard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data: providerStats } = useProviderStatistics();
  const haveTimeSpentMission =
    providerStats && providerStats?.dayMission?.totalCount > 0;
  const haveFlateRateMission =
    providerStats && providerStats?.flatRateMission?.totalCount > 0;
  const { setCrumbs } = useBreadCrumb();
  setCrumbs([
    {
      label: `Tableau de bord`,
      path: '/dashboard',
    },
  ]);
  const history = useHistory();
  return (
    <Template>
      <Container
        cols={12}
        gap="30px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Cell x-span={{ xs: '12' }} placeSelf="left">
          <Text variant="h1" mb={50}>
            Bienvenue
          </Text>
        </Cell>

        <BienvenuIcon
          height={202}
          style={{ position: 'absolute', left: 0, width: '100%', top: 100 }}
        />
        {/* <Cell x-span={{ xs: '12', md: '8' }} placeSelf="left">
          <Text width={440} ml={40} fontSize={18}>
            Depuis votre tableau de bord, accédez facilement aux bons plans, aux
            documents et liens utiles, à vos notifications, aux offres de
            missions et autres actualités.
          </Text>
        </Cell>
        <Cell x-span={{ xs: '12', md: '4' }} placeSelf="left">
          <Button>Créer une nouvelle mission</Button>
        </Cell> */}
        <Cell x-span={{ xs: '12', md: '7' }} placeSelf="left">
          <Text width={440} ml={40} fontSize={18}>
            Depuis votre tableau de bord, accédez facilement aux activités et
            bientôt d'autres fonctionnalités.
          </Text>
        </Cell>
        <Cell x-span={{ xs: '12', md: '5' }} placeSelf="left">
          <Flex ml={70}>
            {haveTimeSpentMission && (
              <Button
                style={{ padding: '15px 8px' }}
                mr={'5px'}
                onClick={() =>
                  history.push('/invoices/activities/list/pending')
                }
              >
                Activités au temps passé
              </Button>
            )}
            {haveFlateRateMission && (
              <Button
                style={{ padding: '15px 8px' }}
                ml={'5px'}
                onClick={() =>
                  history.push('/invoices/flat-rate/activities/list/pending')
                }
              >
                Activités au forfait
              </Button>
            )}
          </Flex>
        </Cell>
        <Cell mt={80} x-span={{ xs: '12' }} placeSelf="left">
          {localStorage.token && <LastNotifications />}
        </Cell>
      </Container>
    </Template>
  );
};
export default Dashboard;
