import { Header } from 'components/Header';
import { getTradeNameSafe, useEstablishmentFindOne } from '@commons';
import {
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
} from '@freelancelabs/teoreme-commons';
import {
  SubItem,
  Grid,
  Cell,
  Flex,
  Box,
  Text,
  SpinnerBox,
} from 'components/ui';
import { EtablishementTreeIcon } from 'components/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Information } from './Information';
import { Contacts } from './Contacts';
import { Contractors } from './Contractors';

type EstablishmentProps = {
  //
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const Establishment: React.FC<
  React.PropsWithChildren<EstablishmentProps>
> = () => {
  const theme = useTheme();
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { data: establishment, status: estaStatus } =
    useEstablishmentFindOne(id);
  const establishmentName = getTradeNameSafe(
    establishment as IEstablishmentMinifiedUsers | IEstablishmentFullUsers
  );
  useSetCrumbs(
    [establishment, tab],
    [
      { label: 'Établissement', path: '' },
      {
        label: `${establishment?.businessName}`,
        path: `/headquarter/${establishment?.siren}`,
      },
      {
        label: `${establishmentName}`,
        path: `/headquarter/establishment/${id}/information`,
      },
      {
        label: `${tab}`,
        path: `/headquarter/establishment/${id}/${tab}`,
      },
    ]
  );
  const path = `/headquarter/establishment/${id}`;
  const tabItems = (
    <>
      <SubItem disableArrow variant="primary" href={`${path}/information`}>
        Informations générales
      </SubItem>
      <SubItem disableArrow variant="primary" href={`${path}/contacts`}>
        Contacts
      </SubItem>
      <SubItem disableArrow variant="primary" href={`${path}/contractors`}>
        Intervenants
      </SubItem>
    </>
  );
  if (estaStatus === 'pending') {
    return <SpinnerBox />;
  }
  if (estaStatus === 'success') {
    return (
      <Box width={1 / 1}>
        <Box>
          <Header
            height={'200px'}
            tabs={tabItems}
            headband={
              <Flex
                alignContent="center"
                alignItems="center"
                width={1 / 1}
                height={80}
                backgroundColor={theme.colors.primary.inputBackGround}
                mt={20}
                mb={20}
              >
                <Box ml={40}>
                  <EtablishementTreeIcon fontSize={80} />
                </Box>

                <Text mb={'5px'} variant="h1" ml={40}>
                  {establishmentName}
                </Text>
              </Flex>
            }
          ></Header>
          <Grid mt={20}>
            <Cell x-span={{ xs: '12' }}>
              {establishment && tab === 'information' && (
                <Information establishment={establishment} />
              )}
              {establishment && tab === 'contacts' && (
                <Contacts establishment={establishment} />
              )}
              {establishment && tab === 'contractors' && (
                <Contractors establishment={establishment} />
              )}
            </Cell>
          </Grid>
        </Box>
      </Box>
    );
  }

  return <></>;
};

Establishment.displayName = 'Establishment';

export default Establishment;
