import {
  EOnboardingStep,
  FlatUser,
  IContactProvider,
  IEstablishment,
} from '@freelancelabs/teoreme-commons';
import { EContactReason } from '@freelancelabs/teoreme-commons/dist/lib/models/enum/contact.enum';
import {
  contactUs,
  establishmentLookupIdentifier,
  formatAllObject,
  validateFormPhoneFormatFR,
} from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { EnvoyIcon } from 'components/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Row,
  Text,
  Radio,
  PhoneInputControlled,
  StaticSelectControlled,
  TextAreaControlled,
} from 'components/ui';
import { format } from 'date-fns';
import { useShowMessage } from 'hooks/useShowMessage';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { VerifySiretForm } from './VerifySiretForm';

type FormValues = IContactProvider;

type ContactUFormProps = {
  onSubmit?: () => void;
  onClose: () => void;
  onChangeSize: (boolean: boolean) => void;
  me?: FlatUser;
  contactReason?: EContactReason;
};

export const ContactUsForm = ({
  onChangeSize,
  onClose,
  me,
  contactReason,
}: ContactUFormProps) => {
  const [loading, setLoading] = useState(false);
  const showMessage = useShowMessage();
  const [isSend, setIsSend] = React.useState(false);
  const [selectOptions, setSelectOptions] = React.useState([
    {
      label: 'Demande de contact',
      value: EContactReason.HANDSHAKE,
    },
    { label: 'Mission', value: EContactReason.MISSION },
    { label: 'Autres', value: EContactReason.OTHER },
  ]);
  const [establishment, setEstablishment] = React.useState<
    IEstablishment | undefined
  >(undefined);
  const [errorEstablishment, setErrorEstablishment] = React.useState<
    React.ReactNode | false
  >(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    setError,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      reason: contactReason
        ? contactReason
        : me?.onboardingStep?.step === EOnboardingStep.STEP_THREE
          ? EContactReason.MISSION
          : EContactReason.HANDSHAKE,
      //@ts-ignore
      civility: me?.civility ? String(me?.civility) : '1',
      firstName: me?.firstName || '',
      lastName: me?.lastName || '',
      email: me?.email || '',
      phone: me?.phone || '',
      object: 'Contact fournisseur à recontacter',
      siret: establishment?.siret || '',
      businessName: establishment?.businessName || '',
    },
  });
  const reason = watch('reason');
  React.useEffect(() => {
    if (establishment) {
      setValue('siret', establishment?.siret);
      setValue('businessName', establishment?.businessName);
      setValue(
        'object',
        `Connecteed - Mission - ${establishment.businessName}`
      );
    }
  }, [establishment]);

  React.useEffect(() => {
    if (reason === EContactReason.MISSION) {
      setValue('object', `Connecteed - Mission`);
    }
    if (reason === EContactReason.HANDSHAKE) {
      setValue('object', `Connecteed - Prise de contact`);
    }
    if (reason === EContactReason.OTHER) {
      setValue('object', `Connecteed - Contact fournisseur à recontacter`);
    }
  }, [reason]);

  React.useEffect(() => {
    if (!me) {
      setSelectOptions([
        {
          label: 'Demande de contact',
          value: EContactReason.HANDSHAKE,
        },
        { label: 'Autres', value: EContactReason.OTHER },
      ]);
    } else {
      setSelectOptions([
        {
          label: 'Demande de contact',
          value: EContactReason.HANDSHAKE,
        },
        { label: 'Mission', value: EContactReason.MISSION },
        { label: 'Autres', value: EContactReason.OTHER },
      ]);
      if (!contactReason) {
        setValue('reason', EContactReason.MISSION);
      }
    }
  }, [me]);

  const onCheckIdentifier = async ({ identifier }: { identifier?: string }) => {
    if (!identifier) return;
    try {
      setEstablishment(undefined);
      setErrorEstablishment(false);
      const establishment = await establishmentLookupIdentifier(identifier);
      if (establishment.closedSince) {
        setErrorEstablishment(
          <p>
            Établissement fermé depuis le :{' '}
            {format(establishment.closedSince, 'dd/MM/yyyy')}{' '}
          </p>
        );
      }
      setEstablishment(establishment as IEstablishment);
    } catch (e) {
      //@ts-ignore
      if (e.errorCode === 'INSI-COMP-001') {
        setErrorEstablishment(<p>Identifiant d'entreprise mal formé</p>);
      }
    }
  };

  const onSubmit = async (formValues: FormValues) => {
    let onError = false;
    if (formValues.message && formValues.message.length > 0) {
      setError('message', { message: undefined });
    } else {
      onError = true;
      setError('message', { message: 'Ce champ est requis' });
    }
    if (formValues.phone.length !== 11) {
      setError('phone', { message: 'Ce champ est obligatoire' });
      onError = true;
    }
    if (onError === false) {
      setLoading(true);
      try {
        await contactUs(formatAllObject(formValues));
        onChangeSize(true);
        // onClose()
        setIsSend(true);
      } catch {
        showMessage('error', "Erreur sur l'envoie de l'email");
      }
      setLoading(false);
    }
  };
  const checkEmail = (value: string): string | undefined => {
    const regex = /[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i;
    if (regex.test(value)) {
      return undefined;
    } else {
      return 'Veuillez entrer une adresse e-mail valide';
    }
  };
  const checkPhoneValide = (value: string | undefined): boolean => {
    if (value !== undefined) {
      if (value.replace(/\s/g, '').length === 0 || value === '33') {
        return false;
      }
      if (value.length < 11) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
  if (!isSend) {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
        <Text variant="h3">Nous contacter</Text>
        <Text mb={20} variant="p">
          Merci de compléter les informations suivantes afin que nous puissions
          revenir vers vous.
        </Text>
        <Box mt={60}>
          <FormControl
            required
            label={'Civilité'}
            errorMessage={errors?.civility?.message}
          >
            <>
              <Radio
                {...register('civility', {
                  required: 'Ce champ est obligatoire',
                })}
                value="1"
              >
                Monsieur
              </Radio>
              <Radio
                {...register('civility', {
                  required: 'Ce champ est obligatoire',
                })}
                value="2"
              >
                Madame
              </Radio>
            </>
          </FormControl>
        </Box>
        <Row spacing={20}>
          <FormControl
            required
            label="Prénom"
            errorMessage={errors?.firstName?.message}
          >
            <Input
              disabled={
                (me && me.firstName ? me?.firstName?.length > 0 : false) &&
                (me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_ONE)
              }
              isDisabled={
                (me && me.firstName ? me?.firstName?.length > 0 : false) &&
                (me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_ONE)
              }
              isFullWidth
              id="firstName"
              type="text"
              {...register('firstName', { required: 'Ce champ est requis' })}
            />
          </FormControl>
          <FormControl
            required
            label="Nom"
            errorMessage={errors?.lastName?.message}
          >
            <Input
              disabled={
                (me && me.lastName ? me?.lastName?.length > 0 : false) &&
                (me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_ONE)
              }
              isDisabled={
                (me && me.lastName ? me?.lastName?.length > 0 : false) &&
                (me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_ONE)
              }
              isFullWidth
              id="lastName"
              type="text"
              {...register('lastName', { required: 'Ce champ est requis' })}
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl
            required
            label="Email"
            errorMessage={errors?.email?.message}
          >
            <Input
              disabled={
                me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                me?.onboardingStep?.step === EOnboardingStep.STEP_ONE
              }
              isDisabled={
                me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                me?.onboardingStep?.step === EOnboardingStep.STEP_ONE
              }
              isFullWidth
              id="email"
              type="email"
              {...register('email', {
                required: 'Ce champ est requis',
                validate: value => checkEmail(value),
              })}
            />
          </FormControl>
          <FormControl
            required
            label="Téléphone"
            errorMessage={errors?.phone?.message}
          >
            <PhoneInputControlled
              disabled={
                checkPhoneValide(me?.phone) &&
                (me?.onboardingStep?.step === EOnboardingStep.STEP_THREE ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_TWO ||
                  me?.onboardingStep?.step === EOnboardingStep.STEP_ONE)
              }
              rules={{
                required: 'Ce champs est requis',
                validate: (value: string) => {
                  return validateFormPhoneFormatFR(value);
                },
              }}
              control={control}
              isFullWidth
              name="phone"
            />
          </FormControl>
        </Row>
        <Row spacing={20}>
          <FormControl
            required
            label="Motif"
            errorMessage={errors?.object?.message}
          >
            <StaticSelectControlled
              control={control}
              name="reason"
              value={reason}
              options={selectOptions}
              placeholder="Type de service"
            />
          </FormControl>
          <FormControl label="Objet" errorMessage={errors?.reason?.message}>
            <Input
              isDisabled
              isFullWidth
              id="object"
              type="text"
              {...register('object', { required: 'Ce champ est requis' })}
            />
          </FormControl>
        </Row>
        {reason === EContactReason.MISSION && (
          <Row spacing={20}>
            <FormControl label="" errorMessage={errors?.siret?.message}>
              <VerifySiretForm
                mt={-20}
                required
                onSubmit={onCheckIdentifier}
                error={errorEstablishment}
                establishmentRole={'PROVIDER'}
              />
            </FormControl>
            <FormControl
              required
              label="Raison sociale de votre client"
              errorMessage={errors?.businessName?.message}
            >
              <Input
                isDisabled
                isFullWidth
                {...register('businessName', {
                  required: 'Ce champs est requis',
                })}
              />
            </FormControl>
          </Row>
        )}
        <Box>
          <FormControl
            label="Votre message"
            required
            errorMessage={errors?.message?.message}
          >
            <TextAreaControlled
              control={control}
              minRows={3}
              name="message"
              rules={{ required: 'Ce champ est requis' }}
              // {...register('message', {
              //   required: 'Ce champ est requis',
              // })}
              isFullWidth
            />
          </FormControl>
        </Box>

        <Flex justifyContent="left">
          <Button
            key="submit"
            type="submit"
            isLoading={loading}
            isDisabled={loading}
          >
            Envoyer
          </Button>
        </Flex>
      </form>
    );
  } else {
    return (
      <Flex justifyContent="center">
        <Box alignItems="center" alignContent="center">
          <EnvoyIcon
            width="207px"
            height="302px"
            style={{ margin: '0 auto' }}
          />
          <Text mt={20} mb={20} variant="h3" textAlign="center">
            Votre message a bien été envoyé
          </Text>
          <Flex justifyContent="center">
            <Button width={1 / 1} key="submit" onClick={() => onClose()}>
              Fermer
            </Button>
          </Flex>
        </Box>
      </Flex>
    );
  }
};
