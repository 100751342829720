import { useUserFindMany, useUserFindOne } from '@commons';
import { Select } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import { ComponentPropsWithoutRef, useState } from 'react';

type UserSelectProps = {
  value?: string;
  onChange?: (id: string) => void;
  onChangeCompleteObject?: (object: any) => void;
  role?: string;
  filter?: any;
} & Omit<ComponentPropsWithoutRef<'input'>, 'event' | 'onChange' | 'value'>;

export const UserSelect = ({
  value,
  onChange,
  role,
  filter: globalFilter,
  ...props
}: UserSelectProps) => {
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, 500);

  /** Get selected user */
  const { data: selectedUser } = useUserFindOne(value);
  const selectedOption = selectedUser && {
    label: `${selectedUser.lastName || ''} ${selectedUser.firstName || ''} ${
      selectedUser.email
    }`,
    value: selectedUser.cognitoUserId || '',
  };

  // get user list
  const { data: usersQuery, isFetching } = useUserFindMany({
    filterObject: {
      'roles.name': role || undefined,
      ...globalFilter,
      $or: [
        { firstName: { $regex: debouncedFilter, $options: 'i' } },
        { lastName: { $regex: debouncedFilter, $options: 'i' } },
        { email: { $regex: debouncedFilter, $options: 'i' } },
      ],
    },
    limit: 10,
  });

  const userOptions =
    usersQuery?.users?.map((u: any) => ({
      label: `${u.lastName || ''} ${u.firstName || ''} ${u.email}`,
      value: u.cognitoUserId || '',
    })) || [];

  return (
    <Select
      options={userOptions}
      isLoading={isFetching}
      onInputChange={(value: string) => setFilter(value)}
      onChange={(option: any) => {
        onChange && onChange(option && option.value);
        props.onChangeCompleteObject &&
          props.onChangeCompleteObject(option && option.value);
      }}
      isClearable={true}
      value={selectedOption}
      {...props}
    />
  );
};
