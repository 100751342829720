import { Header } from 'components/Header';
import {
  useAdditionalActivityFindMany,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  useMissionFindOne,
  round,
  queryClient,
  additionalActivityDeleteMany,
  getAdditionalActivitiesTotalAmount,
  formatInvoiceOrAdditionalActivitySort,
} from '@commons';
import {
  Box,
  SpinnerBox,
  Spinner,
  Text,
  Flex,
  ColorTextBox,
  LabelField,
  Cell,
  Grid,
  Divider,
  Button,
  CustomToolTip,
} from 'components/ui';
import { FeesIcon, DeleteIcon, MileStoneIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  EadditionalActivityType,
  IJoinedAdditionalActivity,
  EadditionalActivityStatus,
  IJoinedMission,
  IJoinedAdditionalActivityWithInvoice,
  EAdditionalActivityDeleteErrorType,
} from '@freelancelabs/teoreme-commons';
import { useTheme } from 'styled-components';
import Accordion from 'components/Accordion';
import { Theme } from 'styles';
import { AddIcon } from 'components/ui/icons';
import { showCreateOrUpdateExpensesModal } from 'components/modals/Activities/CreateOrUpdateExpensesModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { showSubmitAllActivitiesModal } from 'components/modals/Activities/SubmitAllActivitiesModal';
import { getStatusData } from 'helpers';
import { showCreateOrUpdateMileStoneModal } from 'components/modals/Activities/CreateOrUpdateMileStoneModal';
type ActivitiyFlateRateDetailsProps = {
  //
};

export const ActivitiyFlateRateDetails: React.FC<
  React.PropsWithChildren<ActivitiyFlateRateDetailsProps>
> = () => {
  const theme = useTheme();
  const history = useHistory();
  const showMessage = useShowMessage();
  const location: any = useLocation();
  const { missionRef } = useParams<{ missionRef: string }>();

  useSetCrumbs(
    [location],
    [
      { label: 'CRA & Factures', path: '/invoices' },
      {
        label: "Déclaration d'activité",
        path: '/invoices/activities/list/pending',
      },
      {
        label: 'Forfait',
        path: location,
      },
    ]
  );
  const { data: mission } = useMissionFindOne(missionRef);

  //const activity = reports?.reports?.[0];
  const { data: activitiesResponse, isFetching: isFetchingAA } =
    useAdditionalActivityFindMany({
      filterObject: missionRef
        ? {
            'mission.reference': missionRef,
          }
        : undefined,
      limit: 100,
      skip: 0,
      sort: formatInvoiceOrAdditionalActivitySort('month'),
    });
  const additionalActivities = activitiesResponse?.additionalActivities;
  const customer = mission?.customer;
  const provider = mission?.provider;
  const contractor = provider?.contractor;
  const missionConsumedBudget = mission?.billing?.consumedPurchaseBudget || 0;
  const contractorRate = mission?.billing?.contractorRate || 0;
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  const haveExpenseBudget =
    exepensesIsEnabled && mission?.expensesConfig?.purchaseBudget;
  const expenseBudget = mission?.expensesConfig?.purchaseBudget;
  const expenseConsumedBudget = mission?.expensesConfig?.consumedPurchaseBudget;
  const stepperPercentMission = (): { percent: string; color: string } => {
    let color = '';
    const percent = (missionConsumedBudget / (contractorRate || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperPercentExpenses = (): { percent: string; color: string } => {
    let color = '';
    const percent = ((expenseConsumedBudget || 0) / (expenseBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };

  const stepperWidthMission = stepperPercentMission()?.percent;
  const colorStepperMission = stepperPercentMission()?.color;
  const stepperWidthExpense = stepperPercentExpenses()?.percent;
  const colorStepperExpense = stepperPercentExpenses()?.color;
  const mileStones = additionalActivities?.filter(
    (activity: IJoinedAdditionalActivity) =>
      activity?.type === EadditionalActivityType.MILESTONE
  );
  const expenses = additionalActivities?.filter(
    (activity: IJoinedAdditionalActivity) =>
      activity?.type === EadditionalActivityType.EXPENSE
  );
  const showErrors = (
    errorDeleteDetailsArray: EAdditionalActivityDeleteErrorType[]
  ) => {
    if (
      errorDeleteDetailsArray.find(
        (e: any) =>
          e.type === EAdditionalActivityDeleteErrorType.HAS_LINKED_DOCUMENT
      )
    ) {
      showMessage(
        'error',
        'Impossible de supprimer cette activité car une facture lui est associée'
      );
    } else {
      showMessage('error', 'Impossible de supprimer cette activité');
    }
  };
  const onDeleteAdditionalActictiy = async (
    aa: IJoinedAdditionalActivityWithInvoice
  ) => {
    const textType =
      aa?.type === EadditionalActivityType.STAND_BY_DUTY
        ? 'la prestation complémentaire'
        : 'la déclaration de frais';
    await showDialogModal({
      title: `Supprimer ${textType}`,
      text: `Êtes-vous sûr de vouloir supprimer ${textType} ?`,
      confirmLabel: 'Supprimer',
      cancelLabel: 'Ne pas Supprimer',
      beforeValidation: async () => {
        try {
          const { errorDeleteDetailsArray } =
            await additionalActivityDeleteMany({ uuids: [aa?.uuid] });
          if (
            errorDeleteDetailsArray?.length &&
            errorDeleteDetailsArray.length > 0
          ) {
            showErrors(errorDeleteDetailsArray);
          }
          queryClient.refetchQueries({
            queryKey: ['additionalActivities'],
            type: 'active',
          });
          queryClient.refetchQueries({ queryKey: [mission?.reference] });
          queryClient?.refetchQueries({
            queryKey: ['ActivitiesReport'],
            type: 'active',
          });
        } catch (e) {
          //
        }
      },
    });
  };
  const mileStonesToBeSubmitted = additionalActivities?.filter(
    (sbd: any) =>
      sbd?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED &&
      sbd?.type === EadditionalActivityType.MILESTONE
  );
  const expensesToBeSubmitted = expenses?.filter(
    (sbd: any) =>
      sbd?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED &&
      sbd?.type === EadditionalActivityType.EXPENSE
  );
  const standByDutiesToBeSubmitted = additionalActivities?.filter(
    (sbd: any) =>
      sbd?.status === EadditionalActivityStatus?.TO_BE_SUBMITTED &&
      sbd?.type === EadditionalActivityType.STAND_BY_DUTY
  );
  const submitedCount =
    (expensesToBeSubmitted ? expensesToBeSubmitted?.length : 0) +
    (standByDutiesToBeSubmitted ? standByDutiesToBeSubmitted?.length : 0) +
    (mileStonesToBeSubmitted ? mileStonesToBeSubmitted?.length : 0);
  const submitAllElements = async () => {
    showSubmitAllActivitiesModal({
      // month: apiMonth as Date,
      mission: mission as IJoinedMission,
      activities: additionalActivities?.filter(
        (aa: any) => aa?.status === EadditionalActivityStatus.TO_BE_SUBMITTED
      ),
    });
  };
  return (
    <Box width={1 / 1}>
      <Header tabs={false}>
        <Text mb={'5px'} variant="h1">
          Déclaration d'activité au forfait
        </Text>
      </Header>
      <Box hidden={!isFetchingAA}>
        <SpinnerBox />
      </Box>
      <Box hidden={isFetchingAA}>
        <Grid cols={12}>
          <Cell
            x-span={{
              xs: '12',
              sm: '12',
              md: '8',
              lg: '8',
              xl: '8',
            }}
            mb={20}
            pr={10}
          >
            <Accordion
              defaultOpen={true}
              openOnTop
              propsBar={{
                borderRadius: '8px',
                backgroundColor: 'white',
                border: '1px solid #d0d4e3',
              }}
              childrenTop={
                <Flex justifyContent="space-between" width={1 / 1}>
                  <Flex alignContent="center" alignItems="center">
                    <MileStoneIcon
                      fontSize={25}
                      fill={Theme?.colors?.primary?.default}
                    />
                    <Text ml={10} variant="h2">
                      Prestations
                    </Text>
                    <Flex alignContent="center" alignItems="center">
                      <Button
                        ml={'10px'}
                        onClick={() =>
                          showCreateOrUpdateMileStoneModal({
                            method: 'CREATE',
                            mission: mission as IJoinedMission,
                          })
                        }
                        minWidth="125px"
                        height={'35.5px'}
                        variant="ghost"
                      >
                        <Flex alignContent="center" alignItems="center">
                          <Box mr={10}> Ajouter </Box>
                          <AddIcon fill={Theme?.colors?.primary?.default} />
                        </Flex>
                      </Button>
                    </Flex>
                  </Flex>

                  <Flex
                    cursor="pointer"
                    ml={'5px'}
                    alignContent="center"
                    alignItems="center"
                  >
                    {isFetchingAA ? (
                      <Spinner />
                    ) : (
                      <ColorTextBox variantColor="primary" ml={5}>
                        Total :{' '}
                        {round(
                          (mission?.billing
                            ?.consumedPurchaseBudget as number) || 0
                        )}
                        €
                      </ColorTextBox>
                    )}
                  </Flex>
                </Flex>
              }
              key={`EXPENSES`}
            >
              <Box
                position="relative"
                top={-5}
                p={10}
                borderLeft="1px solid"
                borderRight="1px solid"
                borderBottom="1px solid"
                borderColor={'#d0d4e3'}
              >
                {mileStones &&
                  mileStones?.map((aa: any, index: number) => {
                    return (
                      <Box width={1 / 1}>
                        <Flex
                          width={1 / 1}
                          alignItems="center"
                          alignContent="center"
                        >
                          <Box width={1 / 1}>
                            <LabelField
                              label="Déclaré le"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {getHumanDate(
                                      aa?.submittedAt || (aa.createdAt as Date)
                                    )}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={1 / 1}>
                            <LabelField
                              label="Montant total HT"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {`${getAdditionalActivitiesTotalAmount(
                                      [aa],
                                      EadditionalActivityType.MILESTONE,
                                      'PROVIDER'
                                    )} €`}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={1 / 1}>
                            <LabelField
                              label="Statut"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox
                                    variantColor={
                                      getStatusData(
                                        aa as IJoinedAdditionalActivityWithInvoice
                                      )?.variantColor
                                    }
                                  >
                                    {
                                      getStatusData(
                                        aa as IJoinedAdditionalActivityWithInvoice
                                      )?.statusText
                                    }
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Button
                            onClick={() =>
                              showCreateOrUpdateMileStoneModal({
                                method: 'UPDATE',
                                uuid: aa?.uuid,
                                mission: mission as IJoinedMission,
                              })
                            }
                            minWidth="125px"
                            height={'35.5px'}
                            mr={
                              ![
                                EadditionalActivityStatus?.TO_BE_SUBMITTED,
                                EadditionalActivityStatus?.TO_BE_FILLED,
                              ].includes(aa?.status)
                                ? 24
                                : ''
                            }
                          >
                            {aa?.status ===
                            EadditionalActivityStatus?.TO_BE_FILLED
                              ? 'Déclarer'
                              : aa?.status ===
                                  EadditionalActivityStatus?.REJECTED
                                ? 'Modifier'
                                : 'Consulter'}
                          </Button>
                          {(aa?.status ===
                            EadditionalActivityStatus?.TO_BE_SUBMITTED ||
                            aa?.status ===
                              EadditionalActivityStatus?.TO_BE_FILLED) && (
                            <CustomToolTip
                              color={theme?.colors?.error?.default}
                              text={'Supprimer la prestation'}
                              id={aa?.uuid}
                            >
                              <DeleteIcon
                                cursor={'pointer'}
                                height={24}
                                width={24}
                                fill={theme?.colors?.error?.default}
                                onClick={() => onDeleteAdditionalActictiy(aa)}
                              />
                            </CustomToolTip>
                          )}
                        </Flex>
                        {index !== mileStones?.length - 1 && <Divider />}
                      </Box>
                    );
                  })}
              </Box>
            </Accordion>

            {exepensesIsEnabled && (
              <Accordion
                defaultOpen={expenses && expenses?.length > 0}
                //disableOpen={!exepensesIsEnabled || expenses?.length === 0}
                openOnTop
                propsBar={{
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  border: '1px solid #d0d4e3',
                }}
                childrenTop={
                  <Flex justifyContent="space-between" width={1 / 1}>
                    <Flex alignContent="center" alignItems="center">
                      <FeesIcon
                        width={'30px'}
                        height={'30px'}
                        fill={Theme?.colors?.primary?.default}
                      />
                      <Text ml={10} variant="h2">
                        Frais
                      </Text>
                      <Flex alignContent="center" alignItems="center">
                        <Button
                          ml={'10px'}
                          onClick={() =>
                            showCreateOrUpdateExpensesModal({
                              missionRef: mission?.reference as string,
                              //month: cra?.month as Date,
                              method: 'CREATE',
                            })
                          }
                          minWidth="125px"
                          height={'35.5px'}
                          variant="ghost"
                        >
                          <Flex alignContent="center" alignItems="center">
                            <Box mr={10}> Ajouter </Box>
                            <AddIcon fill={Theme?.colors?.primary?.default} />
                          </Flex>
                        </Button>
                      </Flex>
                    </Flex>

                    <Flex
                      cursor="pointer"
                      ml={'5px'}
                      alignContent="center"
                      alignItems="center"
                    >
                      {isFetchingAA ? (
                        <Spinner />
                      ) : (
                        <ColorTextBox variantColor="primary" ml={5}>
                          Total :{' '}
                          {getAdditionalActivitiesTotalAmount(
                            additionalActivities as IJoinedAdditionalActivityWithInvoice[],
                            EadditionalActivityType.EXPENSE,
                            'PROVIDER'
                          )}{' '}
                          €
                        </ColorTextBox>
                      )}
                    </Flex>
                  </Flex>
                }
                key={`EXPENSES`}
              >
                <Box
                  position="relative"
                  top={-5}
                  p={10}
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  borderBottom="1px solid"
                  borderColor={'#d0d4e3'}
                >
                  {expenses &&
                    expenses?.map((aa: any, index: number) => {
                      return (
                        <Box width={1 / 1}>
                          <Flex
                            width={1 / 1}
                            alignItems="center"
                            alignContent="center"
                          >
                            <Box width={1 / 1}>
                              <LabelField
                                label="Déclaré le"
                                value={
                                  <Box mt={'10px'}>
                                    <ColorTextBox variantColor="primary">
                                      {getHumanDate(aa?.createdAt as Date)}
                                    </ColorTextBox>
                                  </Box>
                                }
                                underline
                              />
                            </Box>
                            <Box width={1 / 1}>
                              <LabelField
                                label="Montant total HT"
                                value={
                                  <Box mt={'10px'}>
                                    <ColorTextBox variantColor="primary">
                                      {`${getAdditionalActivitiesTotalAmount(
                                        [aa],
                                        EadditionalActivityType.EXPENSE,
                                        'PROVIDER'
                                      )} €`}
                                    </ColorTextBox>
                                  </Box>
                                }
                                underline
                              />
                            </Box>
                            <Box width={1 / 1}>
                              <LabelField
                                label="Statut"
                                value={
                                  <Box mt={'10px'}>
                                    <ColorTextBox
                                      variantColor={
                                        getStatusData(
                                          aa as IJoinedAdditionalActivityWithInvoice
                                        )?.variantColor
                                      }
                                    >
                                      {
                                        getStatusData(
                                          aa as IJoinedAdditionalActivityWithInvoice
                                        )?.statusText
                                      }
                                    </ColorTextBox>
                                  </Box>
                                }
                                underline
                              />
                            </Box>
                            <Button
                              onClick={() =>
                                showCreateOrUpdateExpensesModal({
                                  missionRef: aa?.mission?.reference,
                                  month: aa?.month as Date,
                                  method: 'UPDATE',
                                  uuid: aa?.uuid,
                                })
                              }
                              minWidth="125px"
                              height={'35.5px'}
                              mr={
                                ![
                                  EadditionalActivityStatus?.TO_BE_SUBMITTED,
                                  EadditionalActivityStatus?.TO_BE_FILLED,
                                ].includes(aa?.status)
                                  ? 24
                                  : ''
                              }
                            >
                              {aa?.status ===
                              EadditionalActivityStatus?.TO_BE_FILLED
                                ? 'Déclarer'
                                : aa?.status ===
                                    EadditionalActivityStatus?.REJECTED
                                  ? 'Modifier'
                                  : 'Consulter'}
                            </Button>
                            {(aa?.status ===
                              EadditionalActivityStatus?.TO_BE_SUBMITTED ||
                              aa?.status ===
                                EadditionalActivityStatus?.TO_BE_FILLED) && (
                              <CustomToolTip
                                color={theme?.colors?.error?.default}
                                text={'Supprimer le frais'}
                                id={aa?.uuid}
                              >
                                <DeleteIcon
                                  cursor={'pointer'}
                                  height={24}
                                  width={24}
                                  fill={theme?.colors?.error?.default}
                                  onClick={() => onDeleteAdditionalActictiy(aa)}
                                />
                              </CustomToolTip>
                            )}
                          </Flex>
                          {index !== expenses?.length - 1 && <Divider />}
                        </Box>
                      );
                    })}
                </Box>
              </Accordion>
            )}
          </Cell>
          <Cell
            x-span={{
              xs: '12',
              sm: '12',
              md: '4',
              lg: '4',
              xl: '4',
            }}
            mb={20}
            pl={10}
          >
            <Box>
              {/* <Text mb={20} variant="h1" color={Theme.colors.text.secondary}>
              {getHumanDateMonthAndYear(cra?.month as Date, true)}
            </Text> */}
              <Box width={1 / 1} borderRadius={'5px'} backgroundColor="#e5f5ff">
                <Box p={10}>
                  <LabelField
                    label="Référence de la mission"
                    value={mission?.displayReference}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Client"
                    value={getEstablishmentName(customer?.establishment)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Établissement"
                    value={getEstablishmentName(provider?.establishment)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Intervenant"
                    value={getFullName(contractor)}
                    underline
                    mb={10}
                  />
                  <LabelField
                    label="Budget mission consommé"
                    value={
                      <Box mb={20}>
                        <Flex
                          width={1 / 1}
                          zIndex={100}
                          justifyContent="space-around"
                          borderBottom={'12px solid #eaeefc'}
                          borderRadius={'6px'}
                        >
                          <Box
                            position="absolute"
                            left={0}
                            bottom={-12}
                            //@ts-ignore
                            width={stepperWidthMission}
                            borderRadius={'6px'}
                            borderBottom={`12px solid ${colorStepperMission}`}
                          />
                          <Box
                            width={1 / 1}
                            position="absolute"
                            left={0}
                            bottom={-12}
                          >
                            <Text
                              textAlign={'center'}
                              color={
                                Number(stepperWidthMission?.replace('%', '')) >
                                47
                                  ? 'white'
                                  : 'black'
                              }
                              fontSize={10}
                            >
                              {stepperWidthMission}
                            </Text>
                          </Box>
                        </Flex>
                        <Text mt={20} width={1 / 1}>
                          {`Vous avez consommé ${round(
                            missionConsumedBudget
                          )} € sur ${contractorRate} € prévus sur cette mission.`}
                        </Text>
                      </Box>
                    }
                    underline
                    mb={-10}
                  />
                  {haveExpenseBudget && (
                    <LabelField
                      label="Budget des frais consommé"
                      value={
                        <Box mb={20}>
                          <Flex
                            width={1 / 1}
                            zIndex={100}
                            justifyContent="space-around"
                            borderBottom={'12px solid #eaeefc'}
                            borderRadius={'6px'}
                          >
                            <Box
                              position="absolute"
                              left={0}
                              bottom={-12}
                              //@ts-ignore
                              width={stepperWidthExpense}
                              borderRadius={'6px'}
                              borderBottom={`12px solid ${colorStepperExpense}`}
                            />
                            <Box
                              width={1 / 1}
                              position="absolute"
                              left={0}
                              bottom={-12}
                            >
                              <Text
                                textAlign={'center'}
                                color={
                                  Number(
                                    stepperWidthExpense?.replace('%', '')
                                  ) > 47
                                    ? 'white'
                                    : 'black'
                                }
                                fontSize={10}
                              >
                                {stepperWidthExpense}
                              </Text>
                            </Box>
                          </Flex>
                          <Text mt={20} width={1 / 1}>
                            {`Vous avez consommé ${round(
                              expenseConsumedBudget || 0
                            )} € de frais sur ${round(
                              expenseBudget || 0
                            )} € prévus sur cette mission.`}
                          </Text>
                        </Box>
                      }
                      underline
                      mb={10}
                    />
                  )}
                </Box>
              </Box>
              {/* {hasRejectedActivities && (
                <Box
                  mt={20}
                  width={1 / 1}
                  borderRadius={'5px'}
                  backgroundColor={'#ffedcc'}
                >
                  <Box p={10}>
                    <LabelField
                      label="Déclaration refusée"
                      value={
                        'Attention, une ou plusieurs déclarations ont été réfusées. Cliquez sur le bouton modifier pour les corriger et les re-soumettre.'
                      }
                      underline
                    />
                  </Box>
                </Box>
              )} */}
            </Box>
          </Cell>
          {((mileStonesToBeSubmitted && mileStonesToBeSubmitted?.length > 0) ||
            (expensesToBeSubmitted && expensesToBeSubmitted?.length > 0)) && (
            <Cell
              x-span={{
                xs: '12',
                sm: '12',
                md: '8',
                lg: '8',
                xl: '8',
              }}
            >
              <Flex width={1 / 1} justifyContent={'flex-end'}>
                <Button
                  mt={10}
                  variant="filled"
                  onClick={() => submitAllElements()}
                >
                  Soumettre ( {submitedCount} )
                </Button>
              </Flex>
            </Cell>
          )}
          {standByDutiesToBeSubmitted?.length === 0 &&
            expensesToBeSubmitted?.length === 0 &&
            mileStonesToBeSubmitted?.length === 0 && (
              <Cell
                x-span={{
                  xs: '12',
                  sm: '12',
                  md: '8',
                  lg: '8',
                  xl: '8',
                }}
              >
                <Flex width={1 / 1} justifyContent={'flex-end'}>
                  <Button
                    mt={10}
                    variant="filled"
                    onClick={() =>
                      history.push(
                        `/invoices/flat-rate/activities/billing/${missionRef}`
                      )
                    }
                  >
                    Déposer le(s) facture(s)
                  </Button>
                </Flex>
              </Cell>
            )}
        </Grid>
      </Box>
    </Box>
  );
};

ActivitiyFlateRateDetails.displayName = 'ActivitiyDetails';

export default ActivitiyFlateRateDetails;
