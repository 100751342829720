import { EditIcon } from 'components/icons';
import {
  FormInfo,
  Link,
  Grid,
  Cell,
  Box,
  BoxProps,
  Flex,
  Text,
  LabelField,
  Status,
} from 'components/ui';
import {
  establishmentUseIban,
  queryClient,
  updateAdminValidation,
} from '@commons';
import * as React from 'react';
import { printFormat } from 'iban';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { BankAccountForm } from 'forms/establishment/BankAccountForm';
import {
  EValidationStatus,
  IValidationRequest,
  IProviderBankAccountValidationRequest,
  EValidationType,
} from '@freelancelabs/teoreme-commons';
import { showDialogModal } from 'components/modals/DialogModal';
const grid4 = {
  xs: '4',
  sm: '4',
  md: '4',
  lg: '4',
  xl: '4',
};

type Props = {
  establishment: any;
  temporary?: boolean | undefined;
  editable?: boolean;
  bankAccountPending?: IValidationRequest & {
    payload: IProviderBankAccountValidationRequest;
  };
};

export const BankCard = ({
  establishment,
  editable,
  bankAccountPending,
  ...props
}: BoxProps & Props) => {
  const [displayForm, setDisplayForm] = React.useState(false);
  const bankAccount =
    bankAccountPending?.payload?.bankAccount ||
    establishment?.provider?.bankAccount;
  const getStatusBankAccountPending = () => {
    if (bankAccountPending) {
      if (bankAccountPending?.status === EValidationStatus.PENDING) {
        return (
          <Status ml={10} variantColor="warning">
            En attente de validation
          </Status>
        );
      }
      if (bankAccountPending?.status === EValidationStatus.REJECTED) {
        return (
          <Box maxWidth={500}>
            <Status ml={10} variantColor="error">
              Rejeté{' '}
              {bankAccountPending?.rejectReason
                ? `Motif : ${bankAccountPending?.rejectReason}`
                : undefined}
            </Status>
          </Box>
        );
      }
    } else {
      if (establishment?.provider?.bankAccount) {
        return (
          <Status ml={10} variantColor="success">
            Actif
          </Status>
        );
      }
    }
  };
  const onCancelRequest = async () => {
    await showDialogModal({
      width: 570,
      title: 'Etes-vous sûr(e) de vouloir annuler la demande ?',
      text: ``,
      confirmLabel: 'Oui',
      cancelLabel: 'Non',
      beforeValidation: async () => {
        try {
          let validationData = {
            uuid: bankAccountPending?.uuid as string,
            type: EValidationType.PROVIDER_BANK_ACCOUNT,
            ownerId: establishment?.uuid,
            status: EValidationStatus?.CANCELED,
          };
          await updateAdminValidation(validationData);
          queryClient?.refetchQueries({ queryKey: [establishment?.uuid] });
          queryClient?.refetchQueries({
            queryKey: ['admin-validation-search'],
          });
        } catch (e) {
          //
        }
      },
    });
  };
  return (
    <Box>
      <Flex width={1 / 1} justifyContent="flex-start" alignItems="center">
        <Box>
          <Text minWidth={300} variant="h2">
            {bankAccountPending
              ? 'Demande de modification bancaire'
              : 'Coordonnées bancaires'}
          </Text>
        </Box>
        <Box></Box>
        {getStatusBankAccountPending()}
        {bankAccount && !displayForm && editable && (
          <Link
            ml={10}
            onClick={() => setDisplayForm(true)}
            iconLeft={<EditIcon />}
          >
            Modifier
          </Link>
        )}
        {!bankAccount && !displayForm && editable && (
          <Link onClick={() => setDisplayForm(true)} iconLeft={<EditIcon />}>
            Compléter
          </Link>
        )}
        {editable && bankAccountPending && (
          <Link
            ml={10}
            onClick={() => onCancelRequest()}
            iconLeft={<EditIcon />}
          >
            Annuler
          </Link>
        )}
      </Flex>
      {bankAccount && !displayForm && (
        <Grid cols={12} mt={20}>
          {establishmentUseIban(establishment) ? (
            <>
              <Cell x-span={grid4}>
                <LabelField
                  label={'IBAN : '}
                  value={
                    bankAccount?.iban
                      ? printFormat(bankAccount?.iban, ' ')
                      : undefined
                  }
                />
              </Cell>
              <Cell x-span={grid4}>
                <LabelField label={'BIC : '} value={bankAccount?.bic} />
              </Cell>
              <Cell x-span={grid4}>
                <LabelField
                  label={'Nom de la banque : '}
                  value={bankAccount?.bankName}
                />
              </Cell>
              <Cell x-span={grid4}>
                <LabelField
                  label={'Titulaire du compte : '}
                  value={bankAccount?.owner}
                />
              </Cell>
              <Cell x-span={grid4}>
                <LabelField
                  label={'RIB : '}
                  value={
                    <Link
                      onClick={() =>
                        showDisplayPdfModal({
                          fileLocation: bankAccount?.rib?.fileLocation || '',
                          fileName: bankAccount?.rib?.fileName || '',
                        })
                      }
                    >
                      {bankAccount?.rib?.fileName}
                    </Link>
                  }
                />
              </Cell>
            </>
          ) : (
            <Cell x-span={grid4}>
              <LabelField
                label={'RIB :'}
                value={
                  <Link
                    onClick={() =>
                      showDisplayPdfModal({
                        fileLocation: bankAccount?.rib?.fileLocation || '',
                        fileName: bankAccount?.rib?.fileName || '',
                      })
                    }
                  >
                    {bankAccount?.rib?.fileName}
                  </Link>
                }
              />
            </Cell>
          )}
        </Grid>
      )}
      {!bankAccount && bankAccountPending && !displayForm && (
        <FormInfo variantColor="warning">Informations à compléter</FormInfo>
      )}

      {displayForm && (
        <BankAccountForm
          setDisplayForm={(value: boolean) => setDisplayForm(value)}
          establishment={establishment}
          bankAccountPending={bankAccountPending}
        />
      )}
    </Box>
  );
};
BankCard.displayName = 'BankCard';

export default BankCard;
