import * as React from 'react';

function SvgFreelanceComIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148 114" {...props}>
      <title>Logo Freelance.com</title>
      <g>
        <path d="M13.4,69.3C10.9,69.5,9.1,69,7.9,68c-1.1-1-1.6-2.6-1.6-4.8V30.5H0v32.7c0,4.1,1.1,7.2,3.4,9.3c2.1,1.9,5,2.9,8.6,2.9c0.5,0,0.9,0,1.4,0l0.5,0v-6.1L13.4,69.3z"></path>
        <path d="M19.8,3.6c-2.3,2.1-3.4,5.2-3.4,9.3v0.3h-4.7v6.1h4.7V44h6.3V19.3h7.7v-6.1h-7.7v-0.3c0-2.2,0.5-3.8,1.6-4.8c1.2-1.1,3-1.5,5.5-1.3l0.6,0V0.8l-0.5,0C25.5,0.5,22.1,1.4,19.8,3.6"></path>
        <path d="M50.7,12.7c-3.8,0-6.7,1.3-8.6,3.9v-3.4h-6.3V44h6.3V27.6c0-3,0.8-5.2,2.5-6.5c1.7-1.4,3.7-2,6-1.9l0.6,0v-6.5H50.7z"></path>
        <path d="M42.4,44.1V48c-2.6-3.1-6.1-4.6-10.5-4.6c-4.2,0-7.9,1.6-10.9,4.7c-3,3.1-4.5,6.9-4.5,11.3c0,4.4,1.5,8.3,4.5,11.4c3,3.1,6.6,4.6,10.9,4.6c4.4,0,7.9-1.5,10.5-4.6v3.8h6.3V44.1H42.4z M39.5,66.4c-1.9,1.9-4.2,2.8-7,2.8c-2.8,0-5.1-0.9-7-2.8c-1.9-2-2.8-4.3-2.8-7.1c0-2.8,0.9-5.1,2.8-7c1.9-1.9,4.2-2.9,7-2.9c2.8,0,5.1,0.9,7,2.9c1.9,1.9,2.8,4.2,2.8,7C42.4,62.2,41.4,64.5,39.5,66.4"></path>
        <path d="M70.3,43.4c-3.8,0-6.8,1.3-8.9,3.8v-3h-6.3v30.5h6.3V58.5c0-5.9,2.8-9.1,7.9-9.1c4.2,0,6.7,2.5,6.7,6.9v18.3h6.3V56C82.3,48.3,77.6,43.4,70.3,43.4"></path>
        <path d="M110.8,63.8l-0.3,0.5c-1.6,3-4.7,4.8-8.3,4.8c-2.8,0-5.1-0.9-7-2.8c-1.8-1.9-2.8-4.2-2.8-7c0-2.8,0.9-5.1,2.8-7c1.9-1.9,4.2-2.8,7-2.8c3.6,0,6.7,1.8,8.1,4.8l0.3,0.6l5.3-3l-0.2-0.5c-2.4-4.9-7.6-8-13.5-8c-4.6,0-8.4,1.6-11.4,4.6c-3,3-4.6,6.9-4.6,11.4c0,4.6,1.5,8.3,4.6,11.4l0,0c3,3,6.9,4.6,11.4,4.6c5.8,0,11-3,13.7-7.9l0.3-0.5L110.8,63.8z"></path>
        <path
          fill="#003cc2"
          d="M35.5,110.5c-4.6,0-8.4-1.5-11.4-4.6l0,0c-3.1-3.1-4.6-6.9-4.6-11.4c0-4.5,1.5-8.3,4.6-11.4c3-3.1,6.9-4.6,11.4-4.6c5.9,0,11.1,3.1,13.5,8l0.2,0.5l-5.3,3.1l-0.3-0.6c-1.4-3-4.5-4.8-8.1-4.8c-2.8,0-5.1,0.9-7,2.8c-1.9,1.9-2.8,4.2-2.8,7c0,2.7,0.9,5.1,2.8,7c1.9,1.9,4.2,2.8,7,2.8c3.6,0,6.7-1.8,8.3-4.8l0.3-0.5l5.4,3.1l-0.3,0.5C46.5,107.5,41.3,110.5,35.5,110.5"
        ></path>
        <path
          fill="#003cc2"
          d="M133.2,110.5h-6.3V91.9c0-4.1-2.2-6.6-5.9-6.6c-4.3,0-6.6,2.9-6.6,8.3v16.9h-6.3V91.9c0-4.1-2.1-6.6-5.6-6.6c-4.3,0-6.9,3.1-6.9,8.3v16.9h-6.3V80h6.3v2.8c2-2.3,4.8-3.5,8.1-3.5c3.8,0,6.7,1.5,8.6,4.5c2.1-3,5.2-4.5,9.2-4.5c6.9,0,11.6,5,11.6,12.4V110.5z"
        ></path>
        <path
          fill="#003cc2"
          d="M68.7,103.6c-9.8,0-17.7-8-17.7-17.7s8-17.7,17.7-17.7c9.8,0,17.7,8,17.7,17.7S78.5,103.6,68.7,103.6 M68.7,74.2c-6.4,0-11.7,5.2-11.7,11.7c0,6.4,5.2,11.7,11.7,11.7c6.4,0,11.7-5.2,11.7-11.7C80.4,79.5,75.1,74.2,68.7,74.2"
        ></path>
        <path d="M74.2,85.9c0,3.1-2.5,5.5-5.5,5.5c-3.1,0-5.5-2.5-5.5-5.5c0-3.1,2.5-5.5,5.5-5.5C71.8,80.4,74.2,82.8,74.2,85.9"></path>
        <path d="M79.3,16.5c-2.8-3.2-6.5-4.8-10.9-4.8c-4.7,0-8.5,1.5-11.5,4.6c-2.9,3-4.4,6.9-4.4,11.4c0,4.6,1.5,8.4,4.5,11.5c3,3,7,4.5,11.8,4.5c5.9,0,10.4-2.3,13.4-6.9l0.3-0.5l-5.4-3l-0.3,0.5c-1.6,2.7-4.2,4-7.9,4c-5.1,0-8.7-2.6-9.9-7.1h24.3l0.1-0.5c0.1-0.8,0.2-1.6,0.2-2.4C83.6,23.3,82.1,19.5,79.3,16.5 M59,25c1.1-4.5,4.6-7.3,9.4-7.3c4.5,0,7.8,2.8,8.7,7.3H59z"></path>
        <path d="M113.2,16.5c-2.8-3.2-6.5-4.8-10.9-4.8c-4.7,0-8.5,1.5-11.5,4.6c-2.9,3-4.4,6.9-4.4,11.4c0,4.6,1.5,8.4,4.5,11.5c3,3,7,4.5,11.8,4.5c5.9,0,10.4-2.3,13.4-6.9l0.3-0.5l-5.4-3l-0.3,0.5c-1.6,2.7-4.2,4-7.9,4c-5.1,0-8.7-2.6-9.9-7.1h24.3l0.1-0.5c0.1-0.8,0.2-1.6,0.2-2.4C117.4,23.3,116,19.5,113.2,16.5 M92.8,25c1.1-4.5,4.6-7.3,9.4-7.3c4.5,0,7.8,2.8,8.7,7.3H92.8z"></path>
        <path d="M143.9,48.1c-2.8-3.2-6.5-4.8-10.9-4.8c-4.7,0-8.5,1.5-11.5,4.6c-2.9,3-4.4,6.9-4.4,11.4c0,4.6,1.5,8.4,4.5,11.5c3,3,7,4.5,11.8,4.5c5.9,0,10.4-2.3,13.4-6.9l0.3-0.5l-5.4-3l-0.3,0.5c-1.6,2.7-4.2,4-7.9,4c-5.1,0-8.7-2.6-9.9-7.1h24.3l0.1-0.5c0.1-0.8,0.2-1.6,0.2-2.4C148.1,55,146.7,51.2,143.9,48.1 M123.5,56.7c1.1-4.5,4.6-7.3,9.4-7.3c4.5,0,7.8,2.8,8.7,7.3H123.5z"></path>
      </g>
    </svg>
  );
}

export default SvgFreelanceComIcon;
