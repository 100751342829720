import { Box, Status, Link } from 'components/ui';
import { EDpaeStatus } from '@freelancelabs/teoreme-commons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
export const columns = [
  {
    disableSortBy: false,
    Header: 'Document',
    accessor: 'attachment',
    Cell: (item: any) => {
      const attachment = item?.row?.original?.attachment;
      if (attachment) {
        return (
          <Link
            onClick={() =>
              showDisplayPdfModal({
                fileLocation: attachment?.fileLocation,
                fileName: attachment?.fileName,
              })
            }
          >
            {attachment?.fileName}.pdf
          </Link>
        );
      }
      return <></>;
    },
  },
  {
    disableSortBy: false,
    Header: 'Statut',
    accessor: 'state',
    Cell: (item: any) => {
      const status = item?.row?.original?.status;
      const obj: { color: 'grey' | 'success' | 'warning'; text: string } = {
        color: 'grey',
        text: '',
      };
      switch (status) {
        case EDpaeStatus.ARCHIVED:
          obj.color = 'grey';
          obj.text = 'Archivé';
          break;
        case EDpaeStatus.ACTIVE:
          obj.color = 'success';
          obj.text = 'Active';
          break;
        case EDpaeStatus.TO_BE_VALIDATED:
          obj.color = 'warning';
          obj.text = 'En attente de validation';
          break;
        case EDpaeStatus.PENDING:
          obj.color = 'warning';
          obj.text = 'En attente de dpae';
          break;
      }
      return (
        <Box>
          <Status variantColor={obj.color}>{obj.text}</Status>
        </Box>
      );
    },
  },
];
