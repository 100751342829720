/* eslint-disable react/display-name */
import { EContractorStatus } from '@freelancelabs/teoreme-commons';
import { EyeIcon } from 'components/icons';
import { Link, Status, Text } from 'components/ui';
import { useHistory } from 'react-router-dom';
import { getDpaeStatus } from './helpers';

export const columns = [
  {
    Header: 'Civilité',
    accessor: 'civility',
    Cell: (item: any) => {
      const civility = item?.row?.original?.civility;

      return <Text variant="p">{civility === '2' ? 'Mme' : 'Mr'}</Text>;
    },
  },
  {
    Header: 'Prénom',
    accessor: 'firstName',
    Cell: (item: any) => {
      const firstName = item?.row?.original?.firstName;

      return <Text variant="p">{firstName}</Text>;
    },
  },
  {
    Header: 'Nom',
    accessor: 'lastName',
    Cell: (item: any) => {
      const lastName = item?.row?.original?.lastName;

      return <Text variant="p">{lastName}</Text>;
    },
  },
  {
    Header: 'Statut DPAE',
    accessor: 'dpae',
    Cell: (item: any) => {
      const scopedData = item?.row?.original?.scopedData;
      const status = getDpaeStatus(scopedData);
      const contractorStatus = item?.row?.original?.status;
      if (contractorStatus === EContractorStatus.PENDING) {
        return <Status variantColor="warning">En attente de validation</Status>;
      }
      if (status) {
        return <Status variantColor={status?.variant}>{status?.text}</Status>;
      }
      return <></>;
    },
  },
  {
    Header: "Statut de l'intervenant",
    accessor: 'status',
    Cell: (item: any) => {
      const status = item?.row?.original?.status;
      let allDisabled = true;
      item?.row?.original?.scopedData?.forEach((scop: any) => {
        if (!scop?.disabled) {
          allDisabled = false;
        }
      });
      if (allDisabled) {
        return <Status variantColor="error">Désactivé</Status>;
      }
      if (status === EContractorStatus.ACTIVE) {
        return <Status variantColor="success">Actif</Status>;
      }
      if (status === EContractorStatus.PENDING) {
        return <Status variantColor="warning">En attente de validation</Status>;
      }
      if (status === EContractorStatus.REJECTED) {
        return <Status variantColor="error">Refusé</Status>;
      }
      return '';
    },
  },
  {
    Header: 'Actions',
    accessor: 'Actions',
    textAlign: 'right',
    Cell: (item: any) => {
      const uuid = item?.row?.original?.uuid;
      const history = useHistory();
      return (
        <Text variant="p">
          <Link
            onClick={() => history.push(`/contractors/${uuid}/establishments`)}
            iconLeft={<EyeIcon />}
          >
            Voir la fiche
          </Link>
        </Text>
      );
    },
  },
];
