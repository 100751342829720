import { transformText } from '@commons';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { isDate } from 'date-fns';
import { MONTH_FR } from '@commons';
// Hook
export function useTranslationFormat(): (
  key: string,
  format?: string | false,
  plural?: any,
  params?: any
) => string {
  const { t } = useTranslation(
    [
      'texts',
      'status',
      'errors',
      'notifications',
      'notificationsProvider',
      'links',
      'linksProvider',
    ],
    { i18n }
  );
  return (key, format, plural, params) => {
    if (typeof params === 'object') {
      Object.keys(params).forEach(key => {
        if (isDate(params[key])) {
          const day = new Date(params[key]).getDay();
          const month = MONTH_FR[new Date(params[key]).getUTCMonth()];
          const year = new Date(params[key]).getUTCFullYear();
          params[key] = `${day} ${month} ${year}`;
        }
      });
    }
    if (format === 'capitalize-first') {
      return (
        // @ts-ignore
        transformText(t(key, params), 'capitalize-first') + (plural ? 's' : '')
      );
    }
    if (format === 'lowercase') {
      // @ts-ignore
      return transformText(t(key, params), 'lowercase') + (plural ? 's' : '');
    }
    if (format === 'uppercase') {
      // @ts-ignore
      return t(key, params).toUpperCase() + (plural ? 's' : '');
    }
    return t(key, params) + (plural ? 's' : '');
  };
}
