import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Account } from './Account';
import { Container } from 'components/ui';
import { Template } from 'components/Template';
export const User = () => {
  return (
    <Switch>
      <Template>
        <Route path="/user/account">
          <Container>
            <Account />
          </Container>
        </Route>
      </Template>
    </Switch>
  );
};
