import {
  getFullName,
  transformPhoneToInternationalSafe,
  getFirstCaracts,
  cutLongText,
} from '@commons';
import {
  Box,
  boxProperties,
  BoxProps,
  Flex,
  Text,
  Initial,
  Radio,
} from 'components/ui';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { FormLabel } from '../Form';
import { Tooltip as ReactTooltip } from 'react-tooltip';
const ContainerCard = styled(Box)<{
  isSelected?: boolean;
  temporary?: boolean | undefined;
  bottomChildrens?: any;
  height: any;
  isSelectable: Boolean | undefined;
}>`
  width: 570px;
  height: ${p => (p.height ? p.height : p.bottomChildrens ? 'auto' : '168px;')};
  padding: 21px 40px 26px 28px;
  border-radius: 4px;
  border: ${props =>
    props.temporary
      ? 'dashed 1px #d0d4e3'
      : props.isSelected
        ? 'solid 1px #003cc2'
        : 'solid 1px #d0d4e3'};
  cursor: ${props => (props.isSelectable ? 'pointer' : 'initial')};
  background-color: var(--color-white);
  ${boxProperties}
`;
type Props = {
  user: any;
  rightChidlrens?: React.ReactNode;
  bottomChildrens?: React.ReactNode;
  temporary?: boolean | undefined;
  height?: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  onChangeSelected?: () => void;
};
const reverseString = (str: string) => str.split('').reverse().join('');

export const UserCard = ({
  user,
  rightChidlrens,
  bottomChildrens,
  temporary,
  height,
  isSelectable,
  isSelected,
  onChangeSelected,
  ...props
}: BoxProps & Props) => {
  const theme = useTheme();
  const fullName = getFullName(user);
  return (
    <ContainerCard
      height={height}
      bottomChildrens={bottomChildrens}
      temporary={temporary}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={() => (onChangeSelected ? onChangeSelected() : false)}
    >
      {fullName.length > 25 && (
        <>
          <ReactTooltip
            id={user.uuid}
            place={'top'}
            variant={'dark'}
            float={true}
            style={{ backgroundColor: theme.colors.primary.default }}
          >
            <Text color="white" fontSize={11}>
              {fullName}
            </Text>
          </ReactTooltip>
        </>
      )}
      <Flex opacity={temporary ? '0.7' : '1'}>
        {isSelectable && (
          <Box>
            <Radio
              checked={isSelected}
              onChange={() => (onChangeSelected ? onChangeSelected() : false)}
            >
              <></>
            </Radio>
          </Box>
        )}
        <Box ml={'15px'}>
          <Initial initial={reverseString(getFirstCaracts(user as any))} />
        </Box>
        <Flex width={1 / 1} flexWrap="wrap">
          <Box ml={'15px'}>
            {fullName.length < 25 ? (
              <Text variant="b" color={theme.colors.text.secondary}>
                {fullName}
              </Text>
            ) : (
              <a data-for={user.uuid} data-tip="" data-iscapture="true">
                <Text variant="b" color={theme.colors.text.secondary}>
                  {cutLongText(getFullName(user), 25)}
                </Text>
              </a>
            )}
          </Box>
          <Flex width="100%">
            <Text
              color="grey !important"
              ml={'15px'}
              mb={'2px'}
              fontSize={13}
              variant="p"
            >
              {user?.jobTitle}
            </Text>
          </Flex>
        </Flex>
        <Box ml={'15px'}>
          <Box width={'100px'}>{rightChidlrens ? rightChidlrens : <></>}</Box>
        </Box>
      </Flex>
      <Box
        opacity={temporary ? '0.7' : '1'}
        width={1 / 1}
        mt={bottomChildrens ? '10px' : 20}
      >
        <Flex width="100%">
          <FormLabel
            ml={'15px'}
            mt={'2px'}
            color={
              temporary ? 'grey !important' : theme.colors.primary.inputLabel
            }
          >
            Tel :
          </FormLabel>
          <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
            {transformPhoneToInternationalSafe(user?.phone, 'FR') || 'N/A'}
          </Text>
        </Flex>
      </Box>
      <Box
        opacity={temporary ? '0.7' : '1'}
        width={1 / 1}
        mt={bottomChildrens ? '10px' : 20}
      >
        <Flex width="100%">
          <FormLabel
            ml={'15px'}
            mt={'2px'}
            color={theme.colors.primary.inputLabel}
          >
            Email :
          </FormLabel>
          {user?.email ? (
            <Text ml={'5px'} mb={'2px'} fontSize={13} variant="a">
              <a href={`mailto: ${user?.email}`}> {user?.email}</a>
            </Text>
          ) : (
            <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
              N/A
            </Text>
          )}
        </Flex>
      </Box>
      {bottomChildrens && (
        <Box ml={'15px'} mt={'10px'}>
          {bottomChildrens}
        </Box>
      )}
    </ContainerCard>
  );
};
UserCard.displayName = 'UserCard';

export default UserCard;
