import React, { ComponentPropsWithRef, forwardRef } from 'react';
import styled from 'styled-components';
import { Box } from './Box';
const StyledRadioContainer = styled(Box).attrs({ as: 'label' })`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
  margin-top: 15px;
  .check {
    position: relative;
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 100%;
    box-sizing: border-box;
    border: 1px solid;
    color: ${props => props.theme.colors.primary.light};

    background-color: ${props => props.theme.colors.primary.lighter};
    margin-right: 10px;
  }

  &:hover .check {
    color: ${props => props.theme.colors.primary.default};
  }

  input {
    display: none;
  }

  input:checked ~ .check {
    color: ${props => props.theme.colors.primary.default};
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      bottom: 3px;
      right: 3px;
      border-radius: 100%;
      background-color: ${props => props.theme.colors.primary.default};
    }
  }

  input:disabled ~ .label {
    color: ${props => props.theme.colors.disabled.light};
  }

  input:disabled ~ .check {
    color: ${props => props.theme.colors.disabled.light};
  }
`;
type RadioProps = ComponentPropsWithRef<'input'> & {
  children: any;
};
export const Radio = forwardRef<any, RadioProps>(
  ({ children, ...props }: RadioProps, ref) => {
    return (
      <StyledRadioContainer>
        <input type="radio" {...props} ref={ref as any} />
        <div className="check" />
        <div className="label">{children}</div>
      </StyledRadioContainer>
    );
  }
);

Radio.displayName = 'Radio';
