import React, { ReactNode } from 'react';
import { Link, Text, Flex, Box, FormLabel, BoxProps } from 'components/ui';
import { ALink } from 'components/ui/ALink';

type Props = {
  label: string;
  value: string | ReactNode;
  underline?: boolean;
  boldValue?: boolean;
  linkValue?: string;
  colorValue?: string;
  boldReverse?: boolean;
} & BoxProps;

export const LabelField = ({
  label,
  value,
  underline,
  boldValue,
  linkValue,
  colorValue,
  boldReverse,
  ...props
}: Props) => {
  if (underline) {
    return (
      <Flex
        style={{ flexWrap: 'wrap' }}
        width={1 / 1}
        mb={'5px'}
        mt={'5px'}
        {...props}
      >
        <Box width={1 / 1}>
          <FormLabel fontWeight={boldReverse ? 'initial' : 'bold'} mt={'2px'}>
            {label}
          </FormLabel>
        </Box>
        <Box width={1 / 1}>
          <Text
            color={colorValue}
            mb={'2px'}
            fontSize={13}
            variant={boldValue ? 'b' : 'p'}
          >
            {linkValue ? (
              <Link>
                <ALink href={linkValue} target={'_blank'}>
                  {value}
                </ALink>
              </Link>
            ) : (
              value
            )}
          </Text>
        </Box>
      </Flex>
    );
  } else {
    return (
      <Flex alignItems={'center'} mb={'5px'} mt={'5px'} {...props}>
        <FormLabel fontWeight={boldReverse ? 'initial' : 'bold'}>
          {label}
        </FormLabel>
        <Text
          color={colorValue}
          ml={'5px'}
          mb={'5px'}
          fontSize={13}
          variant={boldValue ? 'b' : 'p'}
        >
          {linkValue ? (
            <Link>
              <ALink href={linkValue} target={'_blank'}>
                {value}
              </ALink>
            </Link>
          ) : (
            value
          )}
        </Text>
      </Flex>
    );
  }
};
