import {
  EAdditionalServiceType,
  EMissionStatus,
  EPriceUnit,
  EMissionUpdateServiceAction,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import {
  useMissionFindOne,
  missionUpdateServiceOne,
  removeOffsetDate,
  displayMonth,
} from '@commons';
import { queryClient } from '@commons';
import {
  Row,
  Flex,
  Box,
  Text,
  Button,
  FormControl,
  DatePickerControlled,
  MissionSelectControlled,
  LabelField,
} from 'components/ui';
import { ServiceSuccessIcon } from 'components/icons';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
import { getTime } from 'date-fns';
type FormValues = {
  validityStart: Date;
  validityEnd: Date;
  mission: string;
  type: EAdditionalServiceType;
  unit: EPriceUnit;
  price: number;
  paidByProvider: boolean;
  currency: string;
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  providerContactsIds: Array<string>;
  uuid: string;
  invoice?: IJoinedInvoice;
  from?: 'INVOICE' | 'CRA' | 'MISSION';
  rcproRangeAvalaibleDates?:
    | {
        start: Date;
        end: Date;
        results?: {
          start?: Date | undefined;
          end?: Date | null | undefined;
        }[];
      }
    | false;
};

export const AddRCPROModal = ({
  onResolve,
  isOpen,
  providerContactsIds,
  uuid,
  invoice,
  from = 'MISSION',
  rcproRangeAvalaibleDates,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const submitData = {
      //invoice_uuid: from === 'INVOICE' ? invoice?.uuid : undefined,
      missionRef:
        from === 'INVOICE' ? invoice?.mission?.reference : formValues.mission,
      action: EMissionUpdateServiceAction.ENABLE,
      serviceType: EAdditionalServiceType.PROFESSIONAL_INDEMNITY_INSURANCE,
      serviceData:
        from === 'INVOICE'
          ? {
              validityStart: rcproRangeAvalaibleDates
                ? rcproRangeAvalaibleDates?.start
                : removeOffsetDate(invoice?.month as Date),
              validityEnd: rcproRangeAvalaibleDates
                ? rcproRangeAvalaibleDates?.end
                : removeOffsetDate(invoice?.mission?.endAt as Date),
            }
          : {
              validityStart: removeOffsetDate(formValues.validityStart),
              validityEnd: removeOffsetDate(formValues?.validityEnd),
            },
    };
    try {
      //@ts-ignore
      await missionUpdateServiceOne(submitData);
      queryClient.refetchQueries({ queryKey: ['rcpros'], type: 'active' });
      setDisplaySuccess(true);
    } catch (e) {
      //
    }
    onResolve(true);
    setLoading(false);
  };

  // const selectedUnit = watch('unit');
  const mission = watch('mission');
  const { data: missionSelected } = useMissionFindOne(mission);
  // const checkPrice = (price: number): string | undefined => {
  //   switch (selectedUnit) {
  //     case EPriceUnit.PERCENT_INVOICE:
  //       if (price <= 0 || price > 100) {
  //         return 'Compris entre 0 & 100';
  //       } else {
  //         return undefined;
  //       }
  //     default:
  //       if (price <= 0) {
  //         return 'Supérieur à 0';
  //       } else {
  //         return undefined;
  //       }
  //   }
  // };
  // const validityStart = watch('validityStart');
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={displaySuccess ? 470 : 870}
      closeIcon
    >
      {!displaySuccess ? (
        <Box>
          <Text variant="h3" mb={20}>
            Ajouter une RC Pro
          </Text>

          <form onSubmit={handleSubmit(onSubmit)}>
            {from === 'INVOICE' && (
              <Box mb={20}>
                <Text variant="p" mb={20}>
                  La période de souscription sera du &nbsp;
                  {rcproRangeAvalaibleDates
                    ? displayMonth(rcproRangeAvalaibleDates?.start)
                    : displayMonth(invoice?.month as Date)}
                  &nbsp; au &nbsp;
                  {rcproRangeAvalaibleDates
                    ? displayMonth(rcproRangeAvalaibleDates?.end)
                    : displayMonth(invoice?.mission?.endAt as Date)}
                  . La RC Pro s'applique sur tous les établissements du même
                  siren ( {invoice?.estProvider?.siren} ).
                </Text>
                <Text mb={20}>
                  Rappel : Tout mois entamé sera entièrement dû.
                </Text>
                <LabelField
                  label="Montant de la RC Pro : "
                  value=" 50€ ( HT ) par mois "
                />
                <LabelField
                  value=""
                  label="Si vous souhaitez retirer la Responsabilité Civile Pro par la suite, contactez votre chargé de compte."
                />
              </Box>
            )}
            {from === 'MISSION' && (
              <>
                <Text variant="p" mb={20}>
                  Afin de déterminer la mission pour laquelle le montant de la
                  RC Pro va être prélevé, merci de sélectionner une de vos
                  missions en cours. La période de souscription à la RC Pro ne
                  pourra être en dehors des dates de début et de fin de ladite
                  mission. Pour rappel : Tout mois entamé sera entièrement dû.
                </Text>
                <Box>
                  <FormControl
                    label=" "
                    errorMessage={errors?.mission?.message}
                  >
                    <MissionSelectControlled
                      control={control}
                      name="mission"
                      filter={{
                        'provider.contact': {
                          $in: [
                            /*localStorage.cognitoUserId,*/ ...providerContactsIds,
                          ],
                        },
                        'provider.establishment.uuid': uuid,
                        endAt: {
                          $exists: true,
                        },
                        startAt: {
                          $exists: true,
                        },
                        $or: [
                          {
                            $expr: {
                              $gte: [
                                { $toLong: '$endAt' },
                                getTime(new Date()),
                              ],
                            },
                            status: EMissionStatus.VALIDATED,
                          },
                          {
                            $expr: {
                              $gte: [
                                { $toLong: '$endAt' },
                                getTime(new Date()),
                              ],
                            },
                            status: EMissionStatus.DRAFT,
                          },
                        ],
                      }}
                      width="100%"
                      referenceValue="uuid"
                      placeholder="Rechercher..."
                      rules={{ required: 'Ce champs est requis' }}
                    />
                  </FormControl>
                </Box>

                {mission && mission !== null && (
                  <Flex>
                    <Row spacing={20} width={1 / 2} mt={20}>
                      <FormControl
                        errorMessage={errors?.validityStart?.message}
                        label="Souscrire à partir du"
                        required
                      >
                        <DatePickerControlled
                          disabled={
                            missionSelected === null || rcproRangeAvalaibleDates
                          }
                          control={control}
                          name="validityStart"
                          minDate={
                            rcproRangeAvalaibleDates
                              ? rcproRangeAvalaibleDates?.start
                              : missionSelected && missionSelected?.startAt
                          }
                          maxDate={missionSelected && missionSelected?.endAt}
                          rules={{ required: 'Ce champs est requis' }}
                          valueName="selected"
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </FormControl>
                      <FormControl
                        errorMessage={errors?.validityEnd?.message}
                        label="Jusqu'au"
                        required
                      >
                        <DatePickerControlled
                          disabled={
                            missionSelected === null || rcproRangeAvalaibleDates
                          }
                          name="validityEnd"
                          control={control}
                          valueName="selected"
                          dateFormat="MM/yyyy"
                          maxDate={
                            rcproRangeAvalaibleDates
                              ? rcproRangeAvalaibleDates?.end
                              : missionSelected?.endAt
                          }
                          showMonthYearPicker
                          showFullMonthYearPicker
                          rules={{ required: 'Ce champs est requis' }}
                        />
                      </FormControl>
                    </Row>
                  </Flex>
                )}
              </>
            )}
            <Button
              type="submit"
              isLoading={loading}
              isDisabled={from === 'MISSION' ? !isDirty : false}
            >
              Ajouter
            </Button>
          </form>
        </Box>
      ) : (
        <Flex justifyContent="center">
          <Box alignItems="center" alignContent="center">
            <ServiceSuccessIcon
              width="207px"
              height="202px"
              style={{ margin: '0 auto' }}
            />
            <Text mt={20} mb={20} variant="h3" textAlign="center">
              Félicitations, vous avez souscris à notre RCPro !
            </Text>
            <Text mt={20} mb={20} variant="p" textAlign="center">
              Vous allez recevoir votre attestation par mail à mettre à jour
              dans PROVIGIS.
            </Text>
            <Flex justifyContent="center">
              <Button
                width={1 / 1}
                key="button"
                type="button"
                onClick={() => onResolve(false)}
              >
                C'est noté
              </Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </ModalFrame>
  );
};

export const showAddRCPROModal = create<Props, boolean>(AddRCPROModal);
