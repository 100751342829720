import {
  IOnboardingStep,
  IOnboardingUpdateStep,
  IOnboardingStepResponse,
} from '@freelancelabs/teoreme-commons';
import { ONBOARDING_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const getOnBoardingStep = (disableAlert?: boolean) =>
  fetcher<IOnboardingStep, IOnboardingStepResponse>(
    ONBOARDING_SERVICE_URL + `/step`,
    {
      method: 'get',
    },
    disableAlert
  );

export const updateOnBoardingStep = (
  body: IOnboardingUpdateStep,
  disableAlert?: boolean
) =>
  fetcher<IOnboardingUpdateStep, IOnboardingStepResponse>(
    ONBOARDING_SERVICE_URL + `/step`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
