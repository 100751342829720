import {
  ERoles,
  buildOneLineAddress,
  useAdminValidationFindMany,
  useMe,
} from '@commons';
import {
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
  EValidationType,
  EValidationStatus,
  IValidationRequest,
  IProviderBankAccountValidationRequest,
} from '@freelancelabs/teoreme-commons';
import { Flex, Box, Text, Row, Divider, LabelField } from 'components/ui';
import React from 'react';
import { BankCard, SignatoryCard } from 'components/ui/Cards';
type InformationProps = {
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers;
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
type IValidationRequestBankAccount = IValidationRequest & {
  payload: IProviderBankAccountValidationRequest;
};
export const Information: React.FC<
  React.PropsWithChildren<InformationProps>
> = ({ establishment }) => {
  const { me } = useMe();
  const isEditable =
    me?.roles?.find(
      r =>
        r?.resource === establishment?.uuid &&
        r?.name === ERoles.PROVIDER_RESPONSIBLE
    ) !== undefined
      ? true
      : false;

  const { data: validationPending } = useAdminValidationFindMany({
    filterObject: isEditable && {
      ownerId: establishment?.uuid,
      type: EValidationType.PROVIDER_BANK_ACCOUNT,
      status: { $in: [EValidationStatus.PENDING, EValidationStatus.REJECTED] },
    },
    limit: 1,
    skip: 0,
    sort: 'uuid',
  });
  const bankAccountPending = validationPending?.validationRequests?.[0];
  return (
    <Box width={1 / 1} ml={'15px'}>
      <Box mt={20}>
        <Text variant="h2" mb={20}>
          Informations
        </Text>
        <Text fontSize={13} variant="b">
          {buildOneLineAddress(establishment?.address)}
        </Text>
      </Box>
      <Row spacing={20} width="450px" mt={20}>
        <Flex width="100%">
          {establishment?.siret ? (
            <LabelField label={'N° SIRET : '} value={establishment?.siret} />
          ) : (
            <LabelField
              label={`N° D'IDENTIFICATION : `}
              value={establishment?.identifier}
            />
          )}
        </Flex>
        <Flex width="100%">
          <LabelField label={'N° TVA : '} value={establishment?.vatNumber} />
        </Flex>
      </Row>
      <Divider mb={20} mt={20} />
      {!establishment?.provider?.bankAccount &&
        !bankAccountPending &&
        isEditable && (
          <BankCard editable={isEditable} establishment={establishment} />
        )}
      {establishment?.provider?.bankAccount && (
        <BankCard
          editable={bankAccountPending ? false : isEditable}
          establishment={establishment}
        />
      )}

      <Box mt={20}>
        {bankAccountPending && (
          <>
            <Divider mb={20} mt={20} />
            <BankCard
              bankAccountPending={
                bankAccountPending as unknown as IValidationRequestBankAccount
              }
              editable={isEditable}
              establishment={establishment}
            />
          </>
        )}
      </Box>
      {isEditable && <Divider mb={20} mt={20} />}
      <SignatoryCard editable={false} establishment={establishment} />
    </Box>
  );
};

Information.displayName = 'Information';

export default Information;
