import { FlatUser } from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { CreateOrUpdateExpensesForm } from 'forms/Activities/Expenses/CreateOrUpdateExpensesForm';
import { create } from 'react-modal-promise';
import { showDialogModal } from 'components/modals/DialogModal';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  me?: FlatUser;
  missionRef: string;
  month?: Date;
  method: 'CREATE' | 'UPDATE';
  uuid?: string;
};

export const CreateOrUpdateExpensesModal = ({
  onResolve,
  isOpen,
  layer,
  missionRef,
  month,
  method,
  uuid,
}: Props) => {
  const history = useHistory();
  const [isSumitSuccess, setSumitSuccess] = useState(false);
  const resolveAction = (data: any) => {
    if (data?.submitSuccess) {
      setSumitSuccess(true);
      onResolve(true);
      if (data?.navigate) {
        history.push(data?.navigate);
      }
    } else {
      onResolve(data);
    }
  };
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => {
        if (!isSumitSuccess) {
          showDialogModal({
            title:
              'Êtes-vous sûr(e) de vouloir quitter cette page sans enregistrer ?',
            text: 'Toutes vos modifications seront perdues.',
            confirmLabel: 'Confirmer',
            cancelLabel: 'Annuler',
          }).then(confirm => {
            if (confirm) {
              onResolve(false);
            }
          });
        } else {
          onResolve(true);
        }
      }}
      width={1200}
      closeIcon
      layer={layer}
    >
      <CreateOrUpdateExpensesForm
        missionRef={missionRef}
        month={month}
        method={method}
        uuid={uuid}
        onResolve={(params: any) => resolveAction(params)}
      />
    </ModalFrame>
  );
};

export const showCreateOrUpdateExpensesModal = create<Props>(
  CreateOrUpdateExpensesModal
);
