// import { ECraStatus } from '@freelancelabs/teoreme-commons';

// const { REJECTED, TO_BE_FILLED, ...CRAStatusEnum } = ECraStatus;

// type excludedOptions =
//   | typeof ECraStatus.REJECTED
//   | typeof ECraStatus.TO_BE_FILLED;

// // eslint-disable-next-line @typescript-eslint/no-redeclare
// type CRAStatusEnum = Exclude<ECraStatus, excludedOptions>;

// export { CRAStatusEnum };

export enum CRAStatusEnum {
  TO_BE_SUBMITTED = 'TO_BE_SUBMITTED',
  TO_BE_VALIDATED = 'TO_BE_VALIDATED',
  VALIDATED = 'VALIDATED',
  ARCHIVED = 'ARCHIVED',
}
