import {
  cutLongText,
  uploadFile,
  createAdminValidation,
  establishmentUseIban,
  updateAdminValidation,
  extractVerifyRib,
  isNotEmpty,
} from '@commons';
import {
  EFileType,
  IValidationRequest,
  IFile,
  EValidationType,
  IProviderBankAccountValidationRequest,
  EValidationStatus,
} from '@freelancelabs/teoreme-commons';
import { printFormat } from 'iban';
import {
  isValidIBAN,
  isValidBIC,
  electronicFormatIBAN,
  friendlyFormatIBAN,
} from 'ibantools';
import { DownloadIcon } from 'components/icons';
import {
  Box,
  Button,
  Cell,
  FileInput,
  Flex,
  FormControl,
  Grid,
  Input,
  Link,
  Spinner,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks';
const grid = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '4',
  xl: '4',
};

type FormValues = any; //IBankAccount;

type Props = {
  establishment?: any;
  setDisplayForm: (bool: boolean) => void;
  bankAccountPending?: IValidationRequest & {
    payload: IProviderBankAccountValidationRequest;
  };
};

export const BankAccountForm = ({
  establishment,
  setDisplayForm,
  bankAccountPending,
}: Props) => {
  const bankAccount =
    bankAccountPending?.payload?.bankAccount ||
    establishment?.provider?.bankAccount;
  const {
    setValue,
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      bankAccount: {
        // bic: bankAccount?.bic?.toUpperCase(),
        // iban: bankAccount?.iban ? printFormat(bankAccount?.iban, ' ') : '',
        // owner: bankAccount?.owner,
        // bankName: bankAccount?.bankName,
        // rib: bankAccount?.rib,
      },
    },
  });
  const [isFetchingExtract, setIsFetchingExtract] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [ribFile, setRibFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rib, setRib] = useState<IFile | null>();
  // establishment?.provider?.bankAccount?.rib
  const iban = watch('bankAccount.iban');
  const showMessage = useShowMessage();
  const handleChangeRIB = async (files: File[]) => {
    setLoadingFile(true);
    setRibFile(files[0]);
    try {
      if (establishmentUseIban(establishment)) {
        setIsFetchingExtract(true);
      }
      const s3Response = await uploadFile({
        file: files[0],
        //@ts-ignore
        fileType: EFileType.TEMPORARY,
        establishment: establishment?.uuid || '',
        actionType: 'upload',
      });
      const rib = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag,
      };
      setValue('bankAccount.rib', rib);
      if (establishmentUseIban(establishment)) {
        const verifyFileData = await extractVerifyRib({
          fileLocation: rib?.fileLocation as string,
        });
        if (verifyFileData?.extractedIban && !isNotEmpty(iban)) {
          if (isValidIBAN(verifyFileData?.extractedIban)) {
            setValue(
              'bankAccount.iban',
              friendlyFormatIBAN(verifyFileData?.extractedIban)
            );
          }
        }
        setIsFetchingExtract(false);
      }
    } catch (e) {
      setValue('bankAccount.rib', e);
    }

    setLoadingFile(false);
  };
  const checkIban = (value: string): string | undefined => {
    const iban = value ? electronicFormatIBAN(value) : undefined;
    if (iban && isValidIBAN(iban)) {
      return undefined;
    } else {
      return 'Le code IBAN est invalide ';
    }
  };
  const checkBIC = (value: string): string | undefined => {
    if (isValidBIC(value)) {
      return undefined;
    } else {
      return 'Le code BIC est invalide ';
    }
  };
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    try {
      if (!bankAccountPending) {
        // CREATE
        let validationData = {
          type: EValidationType.PROVIDER_BANK_ACCOUNT,
          ownerId: establishment?.uuid,
          payload: {
            bankAccount: {
              iban: formValues?.bankAccount?.iban
                ? (electronicFormatIBAN(
                    formValues?.bankAccount?.iban
                  ) as string)
                : undefined,
              bic: formValues?.bankAccount?.bic?.toUpperCase(),
              bankName: formValues?.bankAccount?.bankName,
              owner: formValues?.bankAccount?.owner,
              rib: formValues?.bankAccount?.rib,
            },
          },
        };
        await createAdminValidation(validationData);
        setDisplayForm(false);
      } else {
        let validationData = {
          uuid: bankAccountPending?.uuid,
          type: EValidationType.PROVIDER_BANK_ACCOUNT,
          ownerId: establishment?.uuid,
          status:
            bankAccountPending?.status === EValidationStatus.REJECTED
              ? EValidationStatus.PENDING
              : undefined,
          payload: {
            bankAccount: {
              iban: formValues?.bankAccount?.iban
                ? (electronicFormatIBAN(
                    formValues?.bankAccount?.iban
                  ) as string)
                : undefined,
              bic: formValues?.bankAccount?.bic?.toUpperCase(),
              bankName: formValues?.bankAccount?.bankName,
              owner: formValues?.bankAccount?.owner,
              rib: formValues?.bankAccount?.rib,
            },
          },
        };
        await updateAdminValidation(validationData);
        setDisplayForm(false);
      }
      queryClient?.refetchQueries({ queryKey: [establishment?.uuid] });
      queryClient?.refetchQueries({ queryKey: ['admin-validation-search'] });
      showMessage('success', 'Votre demande à bien été envoyée');
    } catch (e) {
      //
    }

    // const establishmentData = {
    //   uuid: establishment?.uuid,
    //   establishment: {
    //     provider: {
    //       bankAccount: {
    //         iban: electronicFormatIBAN(formValues?.bankAccount?.iban),
    //         bic: formValues?.bankAccount?.bic?.toUpperCase(),
    //         bankName: formValues?.bankAccount?.bankName,
    //         owner: formValues?.bankAccount?.owner,
    //         rib: formValues?.bankAccount?.rib,
    //       },
    //     },
    //   },
    // };

    // try {
    //   if (ribFile) {
    //     const s3Response = await uploadFile({
    //       file: ribFile,
    //       fileType: EFileType.BANK_ACCOUNT,
    //       establishment: establishment?.uuid || '',
    //       actionType: 'upload',
    //     });
    //     establishmentData.establishment.provider.bankAccount.rib = {
    //       // @ts-ignore
    //       fileName: s3Response?.fileName,
    //       fileLocation: s3Response?.fileLocation as string,
    //       eTag: s3Response?.eTag,
    //     };
    //   } else {
    //     // @ts-ignore
    //     establishmentData.establishment.provider.bankAccount.rib =
    //       establishment?.provider?.bankAccount?.rib;
    //   }
    //   await updateEstablishment(establishmentData as any);
    //   queryClient.refetchQueries({ queryKey: [establishment.uuid] });
    //   setDisplayForm(false);
    // } catch (e) {
    //   //
    // }
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={10}>
        <Box
          height={50}
          backgroundImage={'linear-gradient(to bottom, #f5f7fc,#ffffff);'}
        />
        <Grid cols={12}>
          {establishmentUseIban(establishment) && (
            <>
              <Cell x-span={grid}>
                <FormControl
                  required
                  label="Relevé d'identité bancaire"
                  errorMessage={errors?.bankAccount?.rib?.message}
                >
                  <FileInput
                    pr={20}
                    label=""
                    accept=".pdf"
                    subLabel=""
                    onSelect={handleChangeRIB}
                  >
                    {loadingFile && <Spinner />}
                    <Link iconLeft={<DownloadIcon />}>
                      {ribFile || rib?.fileName
                        ? `Modifier le fichier ${
                            ribFile?.name
                              ? cutLongText(ribFile.name, 20)
                              : cutLongText(
                                  rib && rib.fileName ? rib?.fileName : '',
                                  20
                                )
                          }`
                        : 'Importer un document'}
                    </Link>
                  </FileInput>
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required
                  label="Iban"
                  errorMessage={errors?.bankAccount?.iban?.message}
                >
                  <Input
                    isDisabled={isFetchingExtract}
                    isFullWidth
                    pr={20}
                    {...register('bankAccount.iban', {
                      validate: (value: any) => checkIban(value),
                    })}
                    onChange={e => {
                      // @ts-ignore
                      e.target.value = printFormat(e.target.value, ' ');
                      if (
                        // @ts-ignore
                        checkIban(printFormat(e.target.value, ' ')) ===
                        undefined
                      ) {
                        clearErrors('iban');
                      }
                    }}
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required
                  label="BIC"
                  errorMessage={errors?.bankAccount?.bic?.message}
                >
                  <Input
                    isDisabled={isFetchingExtract}
                    isFullWidth
                    pr={20}
                    {...register('bankAccount.bic', {
                      validate: (value: any) => checkBIC(value),
                    })}
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required
                  label="Nom de la banque"
                  errorMessage={
                    errors?.bankAccount?.bankName?.type === 'maxLength'
                      ? 'Veuillez saisir moins de 31 caractères'
                      : errors?.bankAccount?.bankName?.message
                  }
                >
                  <Input
                    isDisabled={isFetchingExtract}
                    isFullWidth
                    pr={20}
                    {...register('bankAccount.bankName', {
                      required: 'Ce champ est obligatoire',
                      maxLength: 30,
                    })}
                  />
                </FormControl>
              </Cell>
              <Cell x-span={grid}>
                <FormControl
                  required
                  label="Titulaire du compte"
                  errorMessage={errors?.owner?.message}
                >
                  <Input
                    isDisabled={isFetchingExtract}
                    isFullWidth
                    pr={20}
                    {...register('bankAccount.owner', {
                      required: 'Ce champ est obligatoire',
                    })}
                  />
                </FormControl>
              </Cell>
            </>
          )}
          {!establishmentUseIban(establishment) && (
            <Cell x-span={grid}>
              <FormControl
                required
                label="Relevé d'identité bancaire"
                errorMessage={errors?.bankAccount?.rib?.message}
              >
                <FileInput
                  pr={20}
                  label=""
                  accept=".pdf"
                  subLabel=""
                  onSelect={handleChangeRIB}
                >
                  {loadingFile && <Spinner />}
                  <Link iconLeft={<DownloadIcon />}>
                    {ribFile || rib?.fileName
                      ? `Modifier le fichier ${
                          ribFile?.name
                            ? cutLongText(ribFile.name, 20)
                            : cutLongText(
                                rib && rib.fileName ? rib?.fileName : '',
                                20
                              )
                        }`
                      : 'Importer un document'}
                  </Link>
                </FileInput>
              </FormControl>
            </Cell>
          )}
        </Grid>
        <Flex>
          <Button
            variant="ghost"
            mr={10}
            type="button"
            onClick={() => setDisplayForm(false)}
          >
            Annuler
          </Button>
          <Button isLoading={loading} type="submit" variant="filled">
            {bankAccount
              ? bankAccountPending
                ? 'Soumettre la demande de modification'
                : 'Modifier'
              : 'Ajouter'}
          </Button>
        </Flex>
        <Box
          height={50}
          backgroundImage={'linear-gradient(to top, #f5f7fc,#ffffff);'}
        />
      </Box>
    </form>
  );
};

export default BankAccountForm;
