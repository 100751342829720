import { EDpaeStatus } from '@freelancelabs/teoreme-commons';

export const getHiddenColumns = (innerWidth: number): string[] => {
  if (innerWidth <= 992) {
    return [
      // 'estCustomer.businessName',
      // 'estProvider.businessName',
      // 'contractorRate',
    ];
  }

  return [];
};
export const mapSortByToApi = (sortBy: any) => {
  return sortBy
    .map(
      ({ desc, id }: { desc: boolean; id: string }) => `${desc ? '-' : ''}${id}`
    )
    .join('');
};

export const getDpaeStatus = (
  scopedData: any
):
  | {
      text: string;
      variant: 'success' | 'grey' | 'primary' | 'error' | 'warning' | undefined;
    }
  | false => {
  let hasOneDpae = false;
  let refuse = false;
  let pending = false;
  let toBeValidated = false;
  let active = false;
  let dontNeedDpae = false;
  let nbActions = 0;
  let nbAwait = 0;
  if (scopedData) {
    scopedData.forEach((data: any) => {
      if (data?.dpae?.attachment) {
        hasOneDpae = true;
      }
      if (data?.dpae?.status === EDpaeStatus.ACTIVE) {
        active = true;
      }
      if (data?.dpae?.status === EDpaeStatus.PENDING) {
        pending = true;
        nbActions = nbActions += 1;
      }
      if (data?.dpae?.status === EDpaeStatus.TO_BE_VALIDATED) {
        toBeValidated = true;
        nbAwait = nbAwait += 1;
      }
      if (data?.dpae?.status === EDpaeStatus.REJECTED) {
        refuse = true;
        nbActions = nbActions += 1;
      }
      if (!data?.dpae) {
        dontNeedDpae = true;
      }
    });
    if (refuse || pending) {
      return {
        text: `Action requise sur ${nbActions} Dpae`,
        variant: 'error',
      };
    }
    if (toBeValidated) {
      return {
        text: `${nbAwait} DPAE en attente de validation`,
        variant: 'warning',
      };
    }
    if (active) {
      return {
        text: `DPAE active(s)`,
        variant: 'success',
      };
    }
    if (dontNeedDpae && hasOneDpae === false) {
      return {
        text: `Aucune DPAE requise`,
        variant: 'success',
      };
    }
  }
  return false;
};
