import { ECraCustomerValidationActions } from '@freelancelabs/teoreme-commons';
import {
  Box,
  Button,
  FormControl,
  Text,
  TextAreaControlled,
} from 'components/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from './ModalFrame';
type FormValues = {
  rejectMetaData?: string;
  customerComment?: string;
};
type Props = ModalProps & {
  action: ECraCustomerValidationActions;
  beforeValidation?: () => void;
};

export const ValidateOrRejectCustomerCra = ({
  onResolve,
  isOpen,
  action,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  const onSubmit = async (data: FormValues) => {
    onResolve(data);
  };
  const labelAction =
    action === ECraCustomerValidationActions?.REJECT ? 'Refuser' : 'Valider';
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={450}
      closeIcon
    >
      <Box mt={20}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text variant="h2" mb={10}>
            {labelAction} le CRA
          </Text>
          {action === ECraCustomerValidationActions?.REJECT && (
            <Box>
              <Text mb={10}>Veuillez indiquer le motif de refus.</Text>
              <FormControl
                required
                label={'Motif de refus'}
                errorMessage={errors?.rejectMetaData?.message}
              >
                <TextAreaControlled
                  control={control}
                  minRows={3}
                  isFullWidth
                  rules={{ required: 'Ce champ est requis' }}
                  name="rejectMetaData"
                />
              </FormControl>
            </Box>
          )}
          {action === ECraCustomerValidationActions?.VALIDATE && (
            <Box>
              <Text mb={20}>Êtes-vous sûr de vouloir valider ce CRA ?</Text>
            </Box>
          )}

          <Box width={1 / 1}>
            <Button width={1 / 1} type="submit" isLoading={loading}>
              {labelAction}
            </Button>
          </Box>
        </form>
      </Box>
    </ModalFrame>
  );
};

export const showValidateOrRejectCustomerCra = create<Props>(
  ValidateOrRejectCustomerCra
);
