import {
  getServiceAmount,
  useInvoiceUpdateOne,
  getInvoiceWithTaxeAndDecution,
  uploadFile,
  cutLongText,
  removeOffsetDate,
  getEstablishmentName,
  getInvoiceTotalAmount,
  useDownloadFile,
} from '@commons';
import {
  EFileType,
  EInvoiceStatus,
  IJoinedInvoice,
  IFile,
  ECraStatus,
} from '@freelancelabs/teoreme-commons';
import { DownloadIcon, EuroIcon } from 'components/icons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  Box,
  Button,
  Cell,
  FileInput,
  Flex,
  FormControl,
  Grid,
  Spinner,
  Text,
  Link,
  Divider,
  TextAreaControlled,
  CustomToolTip,
} from 'components/ui';
import { queryClient } from '@commons';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AdditionalServicesForm } from 'forms/partials/invoices/AdditionalServicesForm';
import { InvoiceMissionInfomationsForm } from 'forms/partials/invoices/InvoiceMissionInfomationsForm';
import { InvoiceInformationsForm } from 'forms/partials/invoices/InvoiceInformationsForm';
import { InvoiceEstablishmentInformations } from 'forms/partials/invoices/InvoiceEstablishmentInformations';
import { showOnConfirmFormModal } from 'components/modals/OnConfirmFormModal';
import { Theme } from 'styles';
import { ExclamationCircleIcon } from 'components/ui/icons';
import TextInformation from 'components/TextInformation';
import styled from 'styled-components';

type FormValues = any;

type EditTimeSpentFormProps = {
  onSubmit?: () => void;
  defaultValues: IJoinedInvoice;
};

const grid6 = {
  xs: '12',
  sm: '6',
  md: '6',
  lg: '6',
  xl: '6',
};
const grid30 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '5',
  xl: '5',
};
const grid70 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '7',
  xl: '7',
};
const grid100 = {
  xs: '12',
  sm: '12',
  md: '12',
  lg: '12',
  xl: '12',
};
const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;
export const EditTimeSpentForm: React.FC<
  React.PropsWithChildren<FormValues>
> = ({ defaultValues }: EditTimeSpentFormProps) => {
  const { mutateAsync: updateInvoice } = useInvoiceUpdateOne();
  const theme = useTheme();
  const history = useHistory();
  const [loadingFile, setLoadingFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLater, setLoadingLater] = useState(false);
  const [invoiceFormFile, setInvoiceFormFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [invoiceFile, setInvoiceFile] = React.useState<IFile | null>(
    defaultValues?.invoiceFile ? defaultValues?.invoiceFile : null
  );
  const [checkFileWarning, setCheckFileWarning] = useState<
    React.ReactNode | undefined
  >();
  const form = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      //invoiceDate: addDays(new Date(), 10),
    },
  });
  const {
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    control,
    getValues,
  } = form;
  const nbWorkingDays = watch('nbWorkingDays');
  //const totalAmountHT = nbWorkingDays * contractorRate;
  const totalAmountHT = getInvoiceTotalAmount(defaultValues);
  const deductionAmount = watch('deductionAmount');
  const vatRate = watch('vatRate');
  const isVat = watch('isVat');
  const invoice = getValues();
  const mission = invoice?.mission;
  const isMandateMode = mission?.isMandateMode;
  const invoiceFileDirectPayment =
    invoice?.invoiceFileDirectPayment?.fileLocation;
  const fileLocation = invoiceFileDirectPayment
    ? invoiceFileDirectPayment
    : invoiceFile?.fileLocation;
  const { status, data } = useDownloadFile(fileLocation || '', {
    enabled: fileLocation ? true : false,
  });

  useEffect(() => {
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload

      reader.onload = () => {
        //setFile(reader.result as string);
      };
    }
  }, [data]);

  const checkIsEditableForm = () => {
    if (!defaultValues) {
      return false;
    }
    switch (defaultValues.status) {
      case EInvoiceStatus.TO_BE_FILLED:
        return true;
      case EInvoiceStatus.TO_BE_SUBMITTED:
        return true;
      case EInvoiceStatus.TO_BE_VALIDATED:
        return false;
      case EInvoiceStatus.VALIDATED:
        return false;
      case EInvoiceStatus.ARCHIVED:
        return false;
      case EInvoiceStatus.REJECTED:
        return true;
      default:
        return false;
    }
  };
  const handleChangeProofFile = async (files: File[]) => {
    setLoadingFile(true);
    try {
      const s3Response = await uploadFile({
        file: files[0],
        fileType: EFileType.TEMPORARY,
        missionRef: defaultValues?.mission?.reference,
        actionType: 'upload',
      });
      const data = {
        fileName: s3Response?.fileName,
        fileLocation: s3Response?.fileLocation as string,
        eTag: s3Response?.eTag as string,
      };
      setInvoiceFile(data);
    } catch (e) {
      setLoadingFile(false);
    }
    setLoadingFile(false);
    setInvoiceFormFile(files[0]);
    clearErrors('invoiceFile');
  };
  const onUpdateInvoice = async (
    formValues: FormValues,
    saveLater: boolean
  ) => {
    if (saveLater) {
      setLoadingLater(true);
    } else {
      setLoading(true);
    }
    let onError = false;
    const submitData = {
      comment: formValues?.comment,
      deductionAmount: formValues?.isDeductible
        ? formValues?.deductionAmount
        : undefined,
      invoiceDate:
        formValues?.invoiceDate &&
        formValues?.invoiceDate !== null &&
        removeOffsetDate(formValues?.invoiceDate),
      invoiceFile: formValues?.invoiceFile,
      isDeductible: formValues?.isDeductible,
      isVat: formValues?.isVat,
      providerInvoiceRef: formValues?.providerInvoiceRef,
      socialSecurityNumber: formValues?.isDeductible
        ? formValues?.socialSecurityNumber
        : undefined,
      vatNumber: formValues?.vatNumber,
      vatRate: formValues?.vatRate,
      status: saveLater
        ? EInvoiceStatus.TO_BE_SUBMITTED
        : EInvoiceStatus.TO_BE_VALIDATED,
    };
    if (invoiceFile || invoiceFormFile) {
      if (invoiceFormFile) {
        try {
          submitData.invoiceFile = invoiceFile;
        } catch (e) {
          onError = true;
        }
        setLoadingFile(false);
      }
    } else {
      if (!saveLater) {
        onError = true;
        setError('invoiceFile', { message: 'Ce champs est requis' });
      }
    }

    if (onError === false) {
      try {
        await updateInvoice({
          uuids: [defaultValues.uuid],
          invoice: submitData,
        });
        queryClient.refetchQueries({ queryKey: [defaultValues.uuid] });
        queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
        // history.push(
        //   `/invoices/time-spent/${
        //     !saveLater ? 'to-be-validated' : 'to-be-submitted'
        //   }`
        // );

        history?.goBack();
      } catch (e) {
        //console.log(e)
      }
    }
    if (saveLater) {
      setLoadingLater(false);
    } else {
      setLoading(false);
    }
  };
  const onSaveLater = async () => {
    const formValues = getValues();
    await onUpdateInvoice(formValues, true);
  };
  const onSubmit = async (formValues: FormValues) => {
    if (!invoiceFile && !invoiceFormFile) {
      setError('invoiceFile', { message: 'Ce champs est requis' });
    } else {
      clearErrors('invoiceFile');
      await showOnConfirmFormModal({
        title:
          'Etes-vous sûr(e) de vouloir envoyer cette facture  à votre conseiller Connecteed pour validation ?',
        text: '',
        confirmLabelButton: 'Envoyer',
        cancelLabelButton: 'Corriger',
        data: { ...getValues(), invoiceFile },
        checkInvoice: true,
      }).then(action => {
        if (action === true) {
          onUpdateInvoice(formValues, false);
        }
      });
    }
  };

  const getIsDisabledForm = () => {
    return false;
  };
  const getIsDisabledSend = () => {
    const craStatus = defaultValues?.cra?.state;
    if (craStatus === ECraStatus.REJECTED) return true;
    return false;
  };
  const isEditable = checkIsEditableForm();
  const isDirectPayment =
    defaultValues?.mission?.customer?.billingOptions?.isDirectPayment;
  return (
    <form style={{ padding: 5 }} onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal
        active={isDirty && !loading && !loadingLater && !isSubmitSuccessful}
      />
      <Grid cols={12}>
        <Cell x-span={invoiceFile ? grid70 : grid100}>
          <InvoiceInformationsForm
            invoice={defaultValues}
            form={form}
            isEditable={isEditable}
          />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '12' }}>
              <Text variant="h2">Importer la facture correspondante</Text>
            </Cell>
            <Cell x-span={grid6} placeSelf="center">
              {!isMandateMode ? (
                <Text>
                  Merci de vérifier que votre facture correspond bien aux
                  données indiquées dans ce formulaire. Une fois envoyée, elle
                  sera vérifiée par votre conseiller pour traitement.
                </Text>
              ) : (
                <Flex alignItems={'center'}>
                  <Box width={80} mr={10}>
                    <ExclamationCircleIcon
                      fill={theme?.colors?.primary?.default}
                      fontSize={40}
                    />
                  </Box>

                  <Text>
                    Merci de vérifier que votre facture correspond bien aux
                    données indiquées dans ce formulaire. Il faut faire
                    apparaitre sur la facture la mention :{' '}
                    <b style={{ color: 'red' }}>
                      « Facture adressée par Freelance.com, mandataire, au nom
                      et pour le compte de{' '}
                      {getEstablishmentName(invoice?.estProvider)}, mandant ».
                    </b>{' '}
                    Une fois envoyée, elle sera vérifiée par votre conseiller
                    pour traitement.
                  </Text>
                </Flex>
              )}
            </Cell>
            <Cell x-span={grid6} justifySelf="end">
              <FormControl
                required={isEditable}
                label={
                  isEditable
                    ? 'Importer la facture (.pdf seulement)'
                    : 'Facture'
                }
                errorMessage={errors?.invoiceFile?.message}
              >
                {isEditable ? (
                  <FileInput
                    isDisabled={!isEditable}
                    pr={20}
                    label=""
                    accept=".pdf"
                    subLabel=""
                    onSelect={handleChangeProofFile}
                  >
                    {loadingFile && <Spinner />}
                    <Link iconLeft={<DownloadIcon />}>
                      {invoiceFormFile || invoiceFile?.fileName
                        ? `Modifier le fichier ${
                            invoiceFormFile?.name
                              ? cutLongText(invoiceFormFile.name, 20)
                              : cutLongText(
                                  invoiceFile && invoiceFile.fileName
                                    ? invoiceFile?.fileName
                                    : '',
                                  20
                                )
                          }`
                        : 'Importer un document'}
                    </Link>
                  </FileInput>
                ) : (
                  <Box pr={20}>
                    <Flex
                      pl={'10px'}
                      justifyContent="flex-start"
                      alignContent="center"
                      alignItems="center"
                      border={`1px solid ${theme.colors.primary.inputBorder}`}
                      height={45}
                      borderRadius={5}
                      backgroundColor="rgba(182,198,238,0.5)"
                    >
                      <Link
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation: invoiceFile?.fileLocation || '',
                            fileName: invoiceFile?.fileName || '',
                          })
                        }
                      >
                        fileName
                        <u>{invoiceFile?.fileName}</u>
                      </Link>
                    </Flex>
                  </Box>
                )}
              </FormControl>
              {checkFileWarning && (
                <TextInformation variant="warning">
                  {checkFileWarning}
                </TextInformation>
              )}
            </Cell>
          </Grid>
          <Divider mb={20} mt={20} />
          <InvoiceMissionInfomationsForm form={form} isEditable={isEditable} />
          <Divider mb={10} mt={10} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '9' }}>
              <Text variant="h2">Montant HT</Text>
            </Cell>
            <Cell x-span={{ xs: '3' }}>
              <Text
                variant="h1"
                color="#003cc2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#f4f6fd"
                fontWeight={500}
              >
                {totalAmountHT.toFixed(2) || '0'}{' '}
                <Box>
                  <EuroIcon fill="#003cc2" />
                </Box>
              </Text>
            </Cell>
          </Grid>
          <Divider mb={10} mt={10} />
          <AdditionalServicesForm
            invoice={defaultValues}
            form={form}
            isEditable={isEditable && !isDirectPayment}
          />
          <Divider mb={10} mt={10} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '9' }}>
              <Text variant="h2">Montant Net (HT)</Text>
            </Cell>
            <Cell x-span={{ xs: '3' }}>
              <Text
                variant="h1"
                color="#003cc2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="#f4f6fd"
                fontWeight={500}
              >
                {(
                  totalAmountHT -
                  getServiceAmount(totalAmountHT, nbWorkingDays, defaultValues)
                ).toFixed(2)}
                <Box>
                  <EuroIcon fill="#003cc2" />
                </Box>
              </Text>
            </Cell>
          </Grid>
          <Divider mb={10} mt={10} />
          <InvoiceEstablishmentInformations
            invoiceData={defaultValues}
            form={form}
            isEditable={isEditable}
          />
          <Divider mb={10} mt={10} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '9' }}>
              <Text variant="h1" color="#009933">
                Montant Total TTC
              </Text>
            </Cell>
            <Cell x-span={{ xs: '3' }}>
              <Text
                variant="h1"
                color="#009933"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="rgba(0, 153, 51, 0.1)"
                fontWeight={500}
                px={'10px'}
                ml={'10px'}
              >
                {getInvoiceWithTaxeAndDecution(
                  totalAmountHT,
                  nbWorkingDays,
                  defaultValues,
                  isVat ? vatRate : 0,
                  deductionAmount
                ).toFixed(2)}
                <Box>
                  <EuroIcon fill="#009933" />
                </Box>
              </Text>
            </Cell>
          </Grid>
          <Divider mb={20} mt={20} />
          <Grid cols={12} gap="30px">
            <Cell x-span={{ xs: '12' }}>
              <Text variant="h2">Commentaire</Text>
            </Cell>
            <Cell x-span={{ xs: '12' }}>
              <FormControl
                label="Message à adresser à votre conseiller lors de la transmission de votre facture"
                errorMessage={
                  errors?.comment?.type === 'maxLength'
                    ? 'Veuillez renseigner moins de 1000 caractères'
                    : errors?.comment?.message
                }
              >
                <TextAreaControlled
                  isDisabled={!isEditable}
                  control={control}
                  name="comment"
                  rules={{ maxLength: 999 }}
                  minRows={3}
                />
              </FormControl>
            </Cell>
          </Grid>
        </Cell>
        <Cell
          hidden={!invoiceFile ? true : false}
          x-span={grid30}
          marginLeft={'15px'}
        >
          {!loading && data && (
            <FileContainer
              style={{
                width: '100%',
                position: 'sticky',
                top: '0px',
                paddingBottom: '60px',
                height: 'calc(100vh - 80px)',
              }}
            >
              <iframe
                //@ts-ignore
                src={data && (data as any)?.config?.url + '#toolbar=0'}
                frameBorder="0"
                title="iframe"
                style={{ width: '100%', height: 'calc(100vh - 80px)' }}
              ></iframe>
            </FileContainer>
          )}
        </Cell>
      </Grid>
      <Flex justifyContent="flex-start">
        <Button
          mr={10}
          key="Cancel"
          type="button"
          onClick={() => history?.goBack()}
          variant="ghost"
        >
          Fermer
        </Button>
        {isEditable && (
          <>
            <Button
              onClick={handleSubmit(onSaveLater)}
              mr={10}
              key="saveLater"
              type="button"
              isLoading={loadingLater}
              isDisabled={
                getIsDisabledForm() || loading || loadingLater || loadingFile
              }
              variant="ghost"
            >
              Enregistrer et finir plus tard
            </Button>
            <Button
              key="submit"
              type="submit"
              isLoading={loading}
              isDisabled={
                getIsDisabledForm() ||
                loading ||
                loadingLater ||
                getIsDisabledSend() ||
                loadingFile
              }
            >
              {getIsDisabledSend() ? (
                <CustomToolTip
                  color={Theme?.colors?.error?.default}
                  text={
                    <Box>
                      Cette facture ne peut pas être soumise car la prestation
                      standard qui lui est associée a été refusée. Veuillez
                      d’abord soumettre de nouveau la prestation standard avant
                      de renvoyer la facture associée.
                    </Box>
                  }
                  id={'send_invoice'}
                >
                  Envoyer
                </CustomToolTip>
              ) : (
                <Box>Envoyer</Box>
              )}
            </Button>
          </>
        )}
      </Flex>
    </form>
  );
};

export default EditTimeSpentForm;
