/* eslint-disable react/display-name */
import { EInvoiceStatus } from '@freelancelabs/teoreme-commons';
import { CRA_STATUS_SELECT } from '@commons';
import { Header } from 'components/Header';
import { SearchIcon, SendIcon } from 'components/icons';
import { showDialogModal } from 'components/modals/DialogModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  Box,
  Cell,
  DatePickerRange,
  Flex,
  FormLabel,
  Grid,
  Input,
  Link,
  SubItem,
  Text,
} from 'components/ui';
import Table from 'components/ui/Table';
import { isArrayEqual } from 'helpers';
import { intersection } from 'lodash';
import React from 'react';
import { getTabsTextsByState } from './helpers';
import { useLogic } from './hooks';
import { queryClient } from '@commons';
//@ts-ignore

const CRA_STATUS_SELECT_CUSTOM = CRA_STATUS_SELECT;
const Findex = CRA_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
CRA_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

const grid = {
  xs: '12',
  sm: '6',
  md: '4',
};

const tabItems = (
  <>
    <SubItem variant="primary" href={'/invoices/time-spent/to-be-submitted'}>
      A envoyer
    </SubItem>
    <SubItem variant="primary" href={'/invoices/time-spent/to-be-validated'}>
      En attente de validation
    </SubItem>
    <SubItem variant="primary" href={'/invoices/time-spent/validated'}>
      Validées
    </SubItem>
    <SubItem variant="primary" href={'/invoices/time-spent/paid'}>
      Payées
    </SubItem>
    <SubItem variant="primary" href={'/invoices/time-spent/cancelled'}>
      Annulées
    </SubItem>
  </>
);

export const TimeSpentList = () => {
  const {
    theme,
    updateInvoiceStore,
    status,
    isFetching,
    isPending,
    data,
    columns,
    setFetchInfo,

    startDate,
    endDate,
    selectedStatus,

    filterStatus,
    searchQuery,

    pageIndex,

    columnsHidden,
    selectedRowIds,
    selectableInvoice,

    updateInvoice,
    showMessage,
    history,
  } = useLogic();

  const colorActionSelect =
    selectedRowIds.length > 0
      ? theme.colors.primary.default
      : theme.colors.disabled.light;

  const handleChangeAllStates = async () => {
    if (!selectedRowIds.length) {
      return false;
    }

    await showDialogModal({
      width: 570,
      title:
        'Etes-vous sûr(e) de vouloir envoyer cette facture à votre conseiller Connecteed pour validation ?',
      text: '',
      confirmLabel: 'Envoyer',
      btnFull: true,
    }).then(async (action: boolean) => {
      if (action) {
        try {
          await updateInvoice({
            uuids: selectedRowIds,
            invoice: {
              status: EInvoiceStatus.TO_BE_VALIDATED,
            },
          });
          showMessage(
            'success',
            'Vos/votre ligne(s) de facture ont bien été envoyées à votre conseiller Connecteed.'
          );
          await queryClient.invalidateQueries({ queryKey: ['Invoices'] });
          history.push('/invoices/time-spent/to-be-validated');
        } catch (e) {
          //
        }
      }
    });
  };
  return (
    <Box width={1 / 1}>
      <Header tabs={tabItems}>
        <Text variant="h1">Factures</Text>
      </Header>
      <Text variant="p" mt={20}>
        {getTabsTextsByState(filterStatus)}
      </Text>
      <Box mt={20} mb={20}>
        <Grid cols={12}>
          <Cell x-span={grid}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              icon={<SearchIcon />}
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) =>
                updateInvoiceStore({
                  searchQuery: e.target.value,
                  pageIndex: 0,
                })
              }
              pr={20}
            />
          </Cell>
          {filterStatus === EInvoiceStatus.TO_BE_SUBMITTED && (
            <Cell x-span={grid}>
              <FormLabel>STATUT</FormLabel>
              <StaticSelect
                width={1 / 1}
                options={CRA_STATUS_SELECT_CUSTOM.filter(
                  data =>
                    data.key === 'ALL' ||
                    data.key === EInvoiceStatus.TO_BE_FILLED ||
                    data.key === EInvoiceStatus.TO_BE_SUBMITTED ||
                    data.key === EInvoiceStatus.REJECTED
                )}
                value={selectedStatus}
                onChange={value =>
                  updateInvoiceStore({
                    selectedStatus: value as 'ALL' | EInvoiceStatus | undefined,
                    pageIndex: 0,
                  })
                }
                placeholder="Statut social"
              />
            </Cell>
          )}
          <Cell x-span={grid}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value =>
                updateInvoiceStore({ startDate: value, pageIndex: 0 })
              }
              setEndDate={value =>
                updateInvoiceStore({ endDate: value, pageIndex: 0 })
              }
            />
          </Cell>
        </Grid>
      </Box>
      <Box width={1 / 1}>
        <Flex p={20} pl={0}>
          <SendIcon width={20} height={20} fill={colorActionSelect} />
          <Link
            onClick={handleChangeAllStates}
            pl={10}
            color={colorActionSelect}
            fontSize={16}
          >
            Envoyer
          </Link>
        </Flex>

        <Table
          onFetchData={setFetchInfo}
          controlledPageIndex={pageIndex}
          setPageIndex={pageIndex => updateInvoiceStore({ pageIndex })}
          setSelectedRowsId={(selectedRows: any) => {
            const selectedIds = intersection(
              selectableInvoice,
              Object.keys(selectedRows)
            );

            if (isArrayEqual(selectedIds, selectedRowIds)) {
              return;
            }

            updateInvoiceStore({ selectedRowIds: selectedIds });
          }}
          identifier="uuid"
          controlledHiddenColumns={columnsHidden}
          initialSortBy={[{ id: 'month', desc: true }]}
          columns={columns}
          data={data.invoices}
          controlledPageCount={Math.ceil(data.totalCount / data.limit)}
          totalCount={data.totalCount}
          labelData="Factures"
          isFetching={status === 'success' && isFetching}
          isLoading={isPending}
        />
      </Box>
    </Box>
  );
};

TimeSpentList.displayName = 'TimeSpentList';
// CRAList.whyDidYouRender = true;

export default TimeSpentList;
