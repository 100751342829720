import {
  useVerifyCustomerToken,
  displayMonth,
  validateCustomerCra,
  cutLongText,
} from '@commons';
import {
  ECraCustomerValidationActions,
  ERejectCraReason,
} from '@freelancelabs/teoreme-commons';
import { CustomerTemplate } from 'components/CustomerTemplate';
import { Header } from 'components/Header';
import { EyeIcon } from 'components/icons';
import { ExclamationCircleIcon, HelpOutlineIcon } from 'components/ui/icons';
import {
  Box,
  Cell,
  Flex,
  FormLabel,
  Grid,
  Spinner,
  Text,
  Button,
  FormControl,
  Link,
  CustomToolTip,
} from 'components/ui';
import { CustomerCardCra } from 'components/ui/Cards';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomerAccesDenied from '../Common/CustomerAccesDenied';
import { Theme } from 'styles/theme';
import { showValidateOrRejectCustomerCra } from 'components/modals/ValidateOrRejectCustomerCraModal';
import { queryClient } from '@commons';
import { useSetCrumbs } from 'hooks';
import { StaticSelect } from 'components/selects/StaticSelect';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showDialogModal } from 'components/modals/DialogModal';
import { showContactUsModal } from 'components/modals/ContactUs';

const Container = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  max-width: ${p => p.theme.innerSize}px;
  padding-top: 50px;
  position: relative;
`;
const tabItems = (
  <>
    {/* <SubItem variant="primary" href={'/invoices/cra/to-be-validated'}>
      En attente de validation
    </SubItem>
    <SubItem variant="primary" href={'/invoices/cra/validated'}>
      Validés
    </SubItem> */}
  </>
);
export const ValidateActions = ({
  token,
  cra,
}: {
  token: string;
  cra: any;
}) => {
  const [isLoading, setLoading] = useState(false);
  const onValidate = async () => {
    try {
      showValidateOrRejectCustomerCra({
        action: ECraCustomerValidationActions?.VALIDATE,
      })?.then(async action => {
        if (action) {
          setLoading(true);
          await validateCustomerCra({
            token: token,
            action: ECraCustomerValidationActions?.VALIDATE,
            refCra: cra?.refCra,
          });
        }
        queryClient?.refetchQueries({ queryKey: [token] });
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  };
  const onReject = async () => {
    try {
      showValidateOrRejectCustomerCra({
        action: ECraCustomerValidationActions?.REJECT,
      })?.then(async action => {
        if (action) {
          setLoading(true);
          await validateCustomerCra({
            token: token,
            action: ECraCustomerValidationActions?.REJECT,
            refCra: cra?.refCra,
            rejectMetaData: action?.rejectMetaData,
            rejectReason: ERejectCraReason?.OTHER,
          });
        }
        queryClient?.refetchQueries({ queryKey: [token] });
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Flex alignItems={'center'}>
      <Button
        isLoading={isLoading}
        onClick={onReject}
        height={'70%'}
        mr={10}
        variant="ghostError"
      >
        Refuser
      </Button>
      <Button
        isLoading={isLoading}
        onClick={onValidate}
        height={'70%'}
        variant="success"
      >
        Valider
      </Button>
    </Flex>
  );
};
export const columns = (token: string) => [
  {
    Header: 'Mois ',
    accessor: 'month',
    disableSortBy: true,
    Cell: (item: any) => {
      const month = item?.row?.original?.month;

      return <Flex>{displayMonth(month)}</Flex>;
    },
  },
  {
    Header: 'Intervenant',
    accessor: 'contractorFirstName',
    disableSortBy: true,
    Cell: (item: any) => {
      const contractorFirstName = item?.row?.original?.contractorFirstName;
      const contractorLastName = item?.row?.original?.contractorLastName;
      const contractor = `${contractorFirstName} ${contractorLastName}`;
      return <Flex>{contractor}</Flex>;
    },
  },
  {
    Header: 'Établissement partenaire',
    accessor: 'estProvider',
    disableSortBy: true,
    Cell: (item: any) => {
      return <Flex>{item?.row?.original?.estProvider}</Flex>;
    },
  },
  {
    Header: 'Jours travailés',
    accessor: 'nbWorkingDay',
    disableSortBy: true,
    Cell: (item: any) => {
      return <Flex>{item?.row?.original?.nbWorkingDay}</Flex>;
    },
  },
  {
    Header: 'Commentaire',
    accessor: 'customerComment',
    disableSortBy: true,
    Cell: (item: any) => {
      const customerComment = item?.row?.original?.customerComment;
      if (customerComment) {
        if (customerComment?.length > 20) {
          return (
            <CustomToolTip
              text={customerComment}
              id={item?.uuid + 'customerComment'}
            >
              <Text fontSize={9}>{cutLongText(customerComment, 20)}</Text>
            </CustomToolTip>
          );
        }
      }
      return <Flex>{item?.row?.original?.customerComment}</Flex>;
    },
  },
  {
    Header: 'Justificatif',
    accessor: 'proof',
    disableSortBy: true,
    Cell: (item: any) => {
      const proof = item?.row?.original?.proof;
      if (proof) {
        return (
          <Link
            onClick={() =>
              showDisplayPdfModal({
                fileLocation: proof,
                fileName: proof,
                fromCustomer: true,
              })
            }
            iconLeft={<EyeIcon />}
          >
            voir le justificatif
          </Link>
        );
      } else {
        return <Flex>Aucun justificatif</Flex>;
      }
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    disableSortBy: true,
    Cell: (item: any) => {
      return (
        <Flex>
          <ValidateActions token={token} cra={item?.row?.original} />
        </Flex>
      );
    },
  },
];
export const CustomerRoute: React.FC<React.PropsWithChildren<unknown>> = () => {
  const width = window?.innerWidth;
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { status, data } = useVerifyCustomerToken(token);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchQuery, setSearchQuery] = useState('');
  const [linkedMissionSelected, setLinkedMissionSelected] = useState(token);
  // const [fetchInfo, setFetchInfo] = React.useState({
  //   pageSize: 10,
  //   pageIndex: 0,
  //   sortBy: [{ id: 'month', desc: true }],
  // });
  useSetCrumbs(
    [],
    [
      { label: 'CRA', path: `/customer/cra/validation/${token}` },
      { label: 'Validation', path: `/customer/cra/validation/${token}` },
    ]
  );
  const filterItems = () => {
    let cras = data?.cras;
    if (searchQuery?.length && cras?.length) {
      cras = cras?.filter(cra => {
        if (cra?.contractorFirstName?.includes(searchQuery)) {
          return true;
        }
        if (cra?.contractorLastName?.includes(searchQuery)) {
          return true;
        }
        if (cra?.estProvider?.includes(searchQuery)) {
          return true;
        }
        if (cra?.refCra?.includes(searchQuery)) {
          return true;
        }
        return false;
      });
    }
    return cras;
  };
  const items = filterItems();
  const enableSelectMission = data ? data?.linkedMission?.length > 0 : false;
  return (
    <CustomerTemplate disableTopBar={true}>
      {width < 850 && (
        <Box
          position={'absolute'}
          top={4}
          right={3}
          onClick={() =>
            showDialogModal({
              title: '🎉 Nouvelle fonctionalité Freelance.com',
              text: `Bienvenue sur votre interface de validation des Compte-Rendus d’Activité (CRA) des missions dont vous avez la responsabilité. Merci de valider/refuser les CRA ci-dessous pour que nos équipes puissent payer vos  consultants en mission.`,
              confirmLabel: 'Fermer',
            })
          }
        >
          <HelpOutlineIcon />
        </Box>
      )}
      <Container
        cols={12}
        gap="30px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Cell x-span={{ xs: '12' }}>
          {status === 'pending' ? (
            <Spinner />
          ) : status === 'success' ? (
            <Box width={1 / 1}>
              <Box width={1 / 1} mb={10}>
                <Header
                  tabs={tabItems}
                  height={!enableSelectMission ? 30 : 120}
                >
                  {/* <Text variant="h1">
                    CRA pour le bon de commande N° {data?.refPurchaseOrder}
                  </Text> */}

                  {data && enableSelectMission && (
                    <Box width={width <= 850 ? 1 / 1 : 8 / 12}>
                      <FormControl label="Vos missions en cours">
                        <StaticSelect
                          isClearable={false}
                          isDisabled={data?.linkedMission?.length === 0}
                          options={
                            data?.linkedMission?.length > 0
                              ? data?.linkedMission
                                  ?.map(linked => {
                                    return {
                                      label: `Référence freelance : ${linked?.missionReference} , bon de commande N° ${linked?.refPurchaseOrder}`,
                                      value: linked?.craToken,
                                      data: linked,
                                    };
                                  })
                                  ?.concat([
                                    {
                                      label: `Référence freelance : ${data?.displayReference} , bon de commande N° ${data?.refPurchaseOrder}`,
                                      value: token,
                                      data: {
                                        refPurchaseOrder:
                                          data?.refPurchaseOrder,
                                        missionReference:
                                          data?.displayReference,
                                        craToken: token,
                                      },
                                    },
                                  ])
                                  ?.sort((c, d) =>
                                    c?.data?.missionReference?.localeCompare(
                                      d?.data?.missionReference
                                    )
                                  )
                              : [
                                  {
                                    label: `Référence freelance : ${data?.displayReference} , bon de commande N° ${data?.refPurchaseOrder}`,
                                    value: token,
                                    data: {
                                      refPurchaseOrder: data?.refPurchaseOrder,
                                      missionReference: data?.displayReference,
                                      craToken: token,
                                    },
                                  },
                                ]
                          }
                          onChange={value => {
                            setLinkedMissionSelected(value as string);
                            history?.push(`/customer/cra/validation/${value}`);
                          }}
                          value={linkedMissionSelected}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Header>
              </Box>
              {width >= 850 && (
                <Box
                  borderRadius={'5px'}
                  backgroundColor="#E5F5FF"
                  p={10}
                  width={1 / 1}
                >
                  <Text variant="b">
                    🎉 Nouvelle fonctionalité Freelance.com
                  </Text>
                  <Text mt={10} variant="p">
                    Bienvenue sur votre interface de validation des
                    Compte-Rendus d’Activité (CRA) des missions dont vous avez
                    la responsabilité. Merci de valider/refuser les CRA
                    ci-dessous pour que nos équipes puissent payer vos
                    consultants en mission.
                  </Text>
                  <Link
                    onClick={() => showContactUsModal()}
                    fontWeight={'bold'}
                    mt={10}
                    style={{ textDecoration: 'none' }}
                    iconLeft={
                      <ExclamationCircleIcon
                        height={12}
                        style={{ marginTop: '7px' }}
                      />
                    }
                  >
                    Besoin d’information ? Contactez-nous
                  </Link>
                </Box>
              )}
              <Cell x-span={{ xs: '12' }}>
                <Box width={1 / 1}>
                  <Flex textAlign={'center'} p={'5px'} width={350}>
                    <Text
                      mt={20}
                      borderRadius={4}
                      backgroundColor={'#edf3ff'}
                      variant="b"
                      p={'5px'}
                      color={Theme?.colors?.primary?.default}
                    >
                      Mission {data?.displayReference}
                    </Text>
                    <Text
                      mt={20}
                      color={Theme?.colors?.primary?.default}
                      p={'5px'}
                    >
                      BDC N° {data?.refPurchaseOrder}
                    </Text>
                  </Flex>
                  {width >= 850 ? (
                    <Box width={1 / 1}>
                      {items?.map(cra => (
                        <Flex
                          mt={40}
                          width={1 / 1}
                          height={40}
                          display={'inline-flex'}
                          justifyContent={'flex-start'}
                          flexWrap={'wrap'}
                          alignItems={'center'}
                        >
                          <Box width={3 / 24}>
                            <FormLabel
                              color={Theme?.colors?.grey?.default}
                              style={{ textTransform: 'none' }}
                              fontWeight={'initial'}
                            >
                              Période
                            </FormLabel>
                            <Text fontSize={14} variant="b">
                              {displayMonth(cra?.month)}
                            </Text>
                          </Box>
                          <Box width={3 / 24}>
                            <FormLabel
                              color={Theme?.colors?.grey?.default}
                              style={{ textTransform: 'none' }}
                              fontWeight={'initial'}
                            >
                              Intervenant
                            </FormLabel>
                            <Text>{`${cra?.contractorFirstName} ${cra?.contractorLastName}`}</Text>
                          </Box>
                          <Box width={4 / 24}>
                            <FormLabel
                              color={Theme?.colors?.grey?.default}
                              style={{ textTransform: 'none' }}
                              fontWeight={'initial'}
                            >
                              Partenaire
                            </FormLabel>
                            <Text>{cra?.estProvider}</Text>
                          </Box>
                          <Box width={3 / 24}>
                            <FormLabel
                              color={Theme?.colors?.grey?.default}
                              style={{ textTransform: 'none' }}
                              fontWeight={'initial'}
                            >
                              Jours travaillés
                            </FormLabel>
                            <Text variant="b" fontSize={14}>
                              {cra?.nbWorkingDay}
                            </Text>
                          </Box>
                          <Box width={4 / 24}>
                            <FormLabel
                              color={Theme?.colors?.grey?.default}
                              style={{ textTransform: 'none' }}
                              fontWeight={'initial'}
                            >
                              Commentaire
                            </FormLabel>
                            <Text variant="b" fontSize={9}>
                              {cra?.customerComment ? (
                                <CustomToolTip
                                  text={cra?.customerComment}
                                  id={cra?.refCra + '_customerComment'}
                                >
                                  <Text fontSize={9}>
                                    {cutLongText(
                                      cra?.customerComment as string,
                                      25
                                    )}
                                  </Text>
                                </CustomToolTip>
                              ) : (
                                'N/A'
                              )}
                            </Text>
                          </Box>
                          <Box width={3 / 24}>
                            <FormLabel
                              color={Theme?.colors?.grey?.default}
                              style={{ textTransform: 'none' }}
                              fontWeight={'initial'}
                            >
                              Justificatif
                            </FormLabel>
                            <Text>
                              {cra?.proof ? (
                                <Link
                                  onClick={() =>
                                    showDisplayPdfModal({
                                      //@ts-ignore
                                      fileLocation: cra?.proof,
                                      //@ts-ignore
                                      fileName: cra?.proof,
                                      fromCustomer: true,
                                    })
                                  }
                                >
                                  Voir le justificatif
                                </Link>
                              ) : (
                                'Aucun justificatif'
                              )}
                            </Text>
                          </Box>
                          <Box width={3 / 24}>
                            <FormLabel> </FormLabel>
                            <ValidateActions token={token} cra={cra} />
                          </Box>
                        </Flex>
                      ))}
                    </Box>
                  ) : (
                    // <Table
                    //   disablePagination
                    //   setSelectedRowsId={() => []}
                    //   isFetching={false}
                    //   isLoading={false}
                    //   onFetchData={setFetchInfo}
                    //   controlledPageIndex={0}
                    //   setPageIndex={pageIndex => false}
                    //   controlledHiddenColumns={[]}
                    //   initialSortBy={[]}
                    //   columns={columns(token)}
                    //   data={(items as any) || []}
                    //   controlledPageCount={Math.ceil((items?.length || 0) / 20)}
                    //   totalCount={items?.length as number}
                    //   labelData={`CRA à valider pour la mission ${data?.displayReference}`}
                    //   identifier="refCra"
                    // />
                    <Flex width={1 / 1} display={'inline-flex'} flexWrap="wrap">
                      {items?.map(cra => (
                        <Box mt={10} width={1 / 1} p={10}>
                          <CustomerCardCra
                            cra={cra}
                            bottomChildrens={
                              <ValidateActions token={token} cra={cra} />
                            }
                          />
                        </Box>
                      ))}
                    </Flex>
                  )}
                  {items?.length === 0 && (
                    <Flex width={1 / 1}>
                      <Text width={1 / 1} variant="b" textAlign={'center'}>
                        Aucun CRA à valider pour la mission{' '}
                        {data?.displayReference}
                      </Text>
                    </Flex>
                  )}
                </Box>
              </Cell>
            </Box>
          ) : (
            <CustomerAccesDenied token={token} />
          )}
        </Cell>
      </Container>
    </CustomerTemplate>
  );
};
export default CustomerRoute;
