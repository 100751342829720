import { useShowMessage } from 'hooks';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useFetchData } from './api';
import { useDpaeListStore } from 'store/dpae';
import { columns } from './TableConfig';

export const useLogic = () => {
  const theme = useTheme();
  const t = useTranslationFormat();
  const { updateDpaeListStore, filterObject, pageIndex } = useDpaeListStore();

  const showMessage = useShowMessage();

  const history = useHistory();
  const [fetchInfo, setFetchInfo] = React.useState({
    pageSize: 10,
    pageIndex: 0,
    sortBy: [{ id: 'createdAt', desc: true }],
    filterObject,
  });

  const { data, isFetching, status } = useFetchData(fetchInfo);
  return {
    t,
    updateDpaeListStore,
    theme,

    columnsHidden: [],
    columns,
    isFetching,
    data,
    status,
    setFetchInfo,
    showMessage,
    pageIndex,
    history,
  };
};
