import { NotFoundIcon } from 'components/icons';
import { showContactUsModal } from 'components/modals/ContactUs';
import { Box, Flex, Link, Text } from 'components/ui';
import * as React from 'react';
import { useTheme } from 'styled-components';
// type FormValues = {
//   email: string;
//   password: string;
//   confirmPassword: string;
// };

export const PageNotFound: React.FC<React.PropsWithChildren<unknown>> = () => {
  const Theme = useTheme();
  return (
    <Flex
      top={0}
      left={0}
      bottom={0}
      right={0}
      alignItems="center"
      justifyContent="center"
      position="fixed"
    >
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Box ml={'320px'}>
          <Text variant="h1" color={Theme.colors.primary.default}>
            Oups !
          </Text>
          <Text variant="p">La page que vous cherchez semble introuvable.</Text>
          <Text variant="p">
            Vous avez besoin d’aide ?{' '}
            <Link onClick={() => showContactUsModal()}>Contactez-nous</Link>
          </Text>
        </Box>
      </Flex>
      <NotFoundIcon width={703} height={647} />
    </Flex>
  );
};
export default PageNotFound;
