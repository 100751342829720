import * as React from 'react';

function EventIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3333 8.64376H8V11.9771H11.3333V8.64376ZM10.6667 1.31042V2.64376H5.33333V1.31042H4V2.64376H3.33333C2.59333 2.64376 2.00667 3.24376 2.00667 3.97709L2 13.3104C2 14.0438 2.59333 14.6438 3.33333 14.6438H12.6667C13.4 14.6438 14 14.0438 14 13.3104V3.97709C14 3.24376 13.4 2.64376 12.6667 2.64376H12V1.31042H10.6667ZM12.6667 13.3104H3.33333V5.97709H12.6667V13.3104Z"
        fill="#808080"
      />
    </svg>
  );
}

export default EventIcon;
