/* eslint-disable react/display-name */
import { ECraStatus } from '@freelancelabs/teoreme-commons';
import { CRA_STATUS_SELECT } from '@commons';
import { Header } from 'components/Header';
import { SearchIcon, SendIcon } from 'components/icons';
import { showDialogModal } from 'components/modals/DialogModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import {
  Box,
  Cell,
  DatePickerRange,
  Flex,
  FormLabel,
  Grid,
  Input,
  Link,
  SubItem,
  Text,
  CheckBox,
} from 'components/ui';
import Table from 'components/ui/Table';
import { isArrayEqual } from 'helpers';
import { intersection } from 'lodash';
import React from 'react';
import { getTabsTextsByState } from './helpers';
import { useLogic } from './hooks';
import { useParams } from 'react-router-dom';
const CRA_STATUS_SELECT_CUSTOM = CRA_STATUS_SELECT;
const Findex = CRA_STATUS_SELECT.findIndex(
  state => state.key === ECraStatus.REJECTED
);
CRA_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';

const tabItems = (
  <>
    <SubItem variant="primary" href={'/invoices/cra/to-be-submitted'}>
      A envoyer
    </SubItem>
    <SubItem variant="primary" href={'/invoices/cra/to-be-validated'}>
      En attente de validation
    </SubItem>
    <SubItem variant="primary" href={'/invoices/cra/validated'}>
      Validés
    </SubItem>
    <SubItem variant="primary" href={'/invoices/cra/archived'}>
      Archivés
    </SubItem>
  </>
);

export const CRAList = () => {
  const { filter } = useParams<{ filter: string }>();

  const {
    theme,
    updateCraStore,
    status,
    isFetching,
    data,
    invoices,
    columns,
    setFetchInfo,

    startDate,
    endDate,
    selectedStatus,

    filterStatus,
    searchQuery,

    pageIndex,

    columnsHidden,
    selectedRowIds,
    selectableCra,
    isOpen,

    updateCra,
    showMessage,
    history,
  } = useLogic();
  const grid = {
    xs: '12',
    sm: '6',
    md: filterStatus === ECraStatus?.TO_BE_SUBMITTED ? '3' : '6',
  };
  const colorActionSelect =
    selectedRowIds.length > 0
      ? theme.colors.primary.default
      : theme.colors.disabled.light;

  const handleChangeAllStates = async () => {
    if (!selectedRowIds.length) {
      return false;
    }

    await showDialogModal({
      width: 570,
      title:
        'Etes-vous sûr(e) de vouloir envoyer le(s) CRA sélectionné(s) à nos conseillers Connecteed pour validation ?',
      text: ``,
      confirmLabel: 'Envoyer',
      btnFull: true,
    }).then(async (action: boolean) => {
      if (action) {
        try {
          const updateCRAResponse = await updateCra({
            refCras: selectedRowIds,
            cra: {
              state: ECraStatus.TO_BE_VALIDATED,
            },
          });
          if (
            //@ts-ignore
            updateCRAResponse?.errorUpdateArray &&
            //@ts-ignore
            updateCRAResponse?.errorUpdateArray?.length > 0
          ) {
            showMessage(
              'error',
              //@ts-ignore
              `Plusieurs lignes de CRA n'on pas été envoyées   ${updateCRAResponse?.errorUpdateArray?.length} / ${selectedRowIds?.length} `
            );
          } else {
            showMessage(
              'success',
              'Vos/votre ligne(s) de CRA ont bien été envoyées à votre conseiller Connecteed.'
            );
          }
          history.push('/invoices/cra/to-be-validated');
        } catch (e) {
          //
        }
      }
    });
  };
  //@ts-ignore
  const wrappedData = data?.cras?.map((cra: any) => {
    return {
      key: cra?.uuid,
      ...cra,
      invoice:
        //@ts-ignore
        invoices?.[
          //@ts-ignore
          invoices?.findIndex(
            (invoice: any) => invoice?.cra?.refCra === cra.refCra
          )
        ],
    };
  });
  return (
    <Box width={1 / 1}>
      <Header tabs={tabItems}>
        <Text variant="h1">CRA</Text>
      </Header>
      <Text variant="p" mt={20}>
        {getTabsTextsByState(filterStatus)}
      </Text>
      <Box mt={20} mb={20}>
        <Grid cols={12}>
          <Cell x-span={grid}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              icon={<SearchIcon />}
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) =>
                updateCraStore({ searchQuery: e.target.value, pageIndex: 0 })
              }
              pr={20}
            />
          </Cell>
          {filterStatus === ECraStatus.TO_BE_SUBMITTED && (
            <Cell x-span={grid}>
              <FormLabel>STATUT</FormLabel>
              <StaticSelect
                width={1 / 1}
                options={CRA_STATUS_SELECT_CUSTOM.filter(
                  data =>
                    data.key === 'ALL' ||
                    data.key === ECraStatus.TO_BE_FILLED ||
                    data.key === ECraStatus.TO_BE_SUBMITTED ||
                    data.key === ECraStatus.REJECTED
                )}
                value={selectedStatus}
                onChange={value =>
                  updateCraStore({
                    selectedStatus: value as 'ALL' | ECraStatus | undefined,
                    pageIndex: 0,
                  })
                }
                placeholder="Statut social"
              />
            </Cell>
          )}

          <Cell x-span={grid}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value =>
                updateCraStore({ startDate: value, pageIndex: 0 })
              }
              setEndDate={value =>
                updateCraStore({ endDate: value, pageIndex: 0 })
              }
            />
          </Cell>
          {filterStatus === ECraStatus.TO_BE_SUBMITTED && (
            <Cell x-span={grid}>
              <FormLabel></FormLabel>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                flexWrap="wrap"
                alignContent="center"
                width={1 / 1}
                height={'100%'}
              >
                <Box>
                  <CheckBox
                    checked={isOpen}
                    onChange={() =>
                      updateCraStore({ isOpen: !isOpen, pageIndex: 0 })
                    }
                    id="isOpen"
                  >
                    {'CRA ouverts à la saisie uniquement'}
                  </CheckBox>
                </Box>
              </Flex>
            </Cell>
          )}
        </Grid>
      </Box>
      <Box width={1 / 1}>
        {selectedRowIds.length > 0 && (
          <Flex p={20} pl={0}>
            <SendIcon width={20} height={20} fill={colorActionSelect} />
            <Link
              onClick={handleChangeAllStates}
              pl={10}
              color={colorActionSelect}
              fontSize={16}
            >
              Envoyer
            </Link>
          </Flex>
        )}

        <Table
          onFetchData={setFetchInfo}
          controlledPageIndex={pageIndex}
          setPageIndex={pageIndex => updateCraStore({ pageIndex })}
          setSelectedRowsId={(selectedRows: any) => {
            const selectedIds = intersection(
              selectableCra,
              Object.keys(selectedRows)
            );

            if (isArrayEqual(selectedIds, selectedRowIds)) {
              return;
            }

            updateCraStore({ selectedRowIds: selectedIds });
          }}
          identifier="refCra"
          controlledHiddenColumns={columnsHidden}
          initialSortBy={[{ id: 'month', desc: true }]}
          columns={columns}
          data={
            filter?.toUpperCase() === ECraStatus?.VALIDATED
              ? wrappedData
              : data?.cras
          }
          controlledPageCount={Math.ceil(data.totalCount / data.limit)}
          totalCount={data.totalCount}
          labelData="CRA"
          isFetching={status === 'success' && isFetching}
          isLoading={status === 'pending'}
        />
      </Box>
    </Box>
  );
};

CRAList.displayName = 'CRAList';
// CRAList.whyDidYouRender = true;

export default CRAList;
