import { useEstablishmentUpdateOne, useUserFindMany } from '@commons';
import {
  IEstablishmentFullUsers,
  IEstablishmentMinifiedUsers,
} from '@freelancelabs/teoreme-commons';
import { AlphabeticalSearch } from 'components/AlphabeticalSearch';
import { LinkIcon, SearchIcon, EditIcon } from 'components/icons';
import {
  Box,
  Cell,
  Divider,
  Flex,
  FormLabel,
  Grid,
  Input,
  Link,
  Text,
  UserCard,
  Spinner,
  Button,
  Status,
} from 'components/ui';
import React from 'react';
import { showDialogModal } from 'components/modals/DialogModal';
import { queryClient } from '@commons';
import { UserSelect } from 'components/selects/UserSelect';
import { TablePagination } from 'components/Pagination';
type ContactsProps = {
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers;
};

export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
const grid = {
  xs: '12',
  sm: '6',
  md: '4',
};
export const Contacts: React.FC<React.PropsWithChildren<ContactsProps>> = ({
  establishment,
}: ContactsProps) => {
  const isResponsible =
    establishment?.provider?.manager?.cognitoUserId ===
    localStorage.cognitoUserId;
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const [page, setPage] = React.useState<number>(0);
  const [openLinkManager, setOpenLinkManager] = React.useState(false);
  const [openLinkContact, setOpenLInkContact] = React.useState(false);
  const [contactSelected, setContactSelected] = React.useState<false | string>(
    false
  );
  const [managerSelected, setManagerSelected] = React.useState<false | string>(
    false
  );
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();
  const [letterQuery, setLetterQuery] = React.useState<string | undefined>();
  const estaContactsIds: any = [];
  const allContacts: any = [];
  let allContactSets: any = [];
  //@ts-ignore
  establishment?.provider?.contacts?.map((c: any) =>
    estaContactsIds.push(c.cognitoUserId)
  );
  //@ts-ignore
  establishment?.provider?.tempContacts?.map((c: any) =>
    estaContactsIds.push(c.cognitoUserId)
  );
  const headquarters = queryClient.getQueryData(['headquarters']);
  //@ts-ignore
  headquarters?.forEach((hq: any) => {
    const a = hq?.establishments?.reduce(
      (accumulator: any, currentValue: any) => {
        accumulator.push(...currentValue?.provider?.contacts);
        return accumulator;
      },
      []
    );
    allContacts.push(...a);
  });
  allContactSets = [...new Set(allContacts)];
  const { data: usersQuery, isFetching: isFetchingUsers } = useUserFindMany({
    filterObject: {
      cognitoUserId: {
        //@ts-ignore
        $in: estaContactsIds,
      },
      $or:
        !searchQuery && !letterQuery
          ? undefined
          : [
              // { firstName: { $regex: `^${letterQuery}`, $options: 'i' } },
              // { lastName: { $regex: `^${letterQuery}`, $options: 'i' } },
              searchQuery
                ? { firstName: { $regex: searchQuery, $options: 'i' } }
                : { firstName: { $regex: `^${letterQuery}`, $options: 'i' } },
              searchQuery
                ? { lastName: { $regex: searchQuery, $options: 'i' } }
                : { lastName: { $regex: `^${letterQuery}`, $options: 'i' } },
            ],
    },
    limit: 10,
    skip: 10 * page,
  });
  const addTempContact = async (tmpContact: any) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeTempContacts: { provider: [tmpContact] },
        addContacts: { provider: [tmpContact] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Rattacher',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir rattacher ce contact à cet établissement ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
        },
      });
    } catch (e) {
      //
    }
  };
  const removeTempContact = async (tmpContact: any) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        removeTempContacts: { provider: [tmpContact] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Refuser le rattachement',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir refuser le rattachement de ce contact à cet établissement ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
        },
      });
    } catch (e) {
      //
    }
  };
  // const removeContact = async (contact: any) => {
  //   try {
  //     const establishmentData = {
  //       uuid: establishment?.uuid,
  //       removeContacts: { provider: [contact] },
  //     };
  //     showDialogModal({
  //       closeIcon: true,
  //       confirmLabel: 'Détacher',
  //       cancelLabel: 'Annuler',
  //       title:
  //         'Etes-vous sûr de vouloir détacher ce chargé de mission de cet établissement ?',
  //       text: '',
  //       beforeValidation: async () => {
  //         await updateEstablishment(establishmentData as any);
  //         queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
  //       },
  //     });
  //   } catch (e) {
  //     //
  //   }
  // };
  const addContact = async (contact: any) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        addContacts: { provider: [contact] },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'Rattacher',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir rattacher ce contact à cet établissement ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
          setOpenLInkContact(false);
        },
      });
    } catch (e) {
      //
    }
  };
  const addManager = async (contact: any) => {
    try {
      const establishmentData = {
        uuid: establishment?.uuid,
        establishment: {
          provider: {
            manager: contact,
          },
        },
        // API MOVE PREVIOUS MANAGER IN ESTABLISHMENT CONTACT !!!
        // addContacts: {
        //   provider: [establishment?.provider?.manager?.cognitoUserId],
        // },
      };
      showDialogModal({
        closeIcon: true,
        confirmLabel: 'valider',
        cancelLabel: 'Annuler',
        title:
          'Etes-vous sûr de vouloir definir ce contact en tant que responsable de comptes de cet établissement ?',
        text: '',
        beforeValidation: async () => {
          await updateEstablishment(establishmentData as any);
          queryClient.refetchQueries({ queryKey: [establishment?.uuid] });
          queryClient.refetchQueries({ queryKey: ['user'] });
          setOpenLinkManager(false);
        },
      });
    } catch (e) {
      //
    }
  };
  return (
    <Box width={1 / 1} ml={'15px'}>
      <Grid cols={12}>
        <Cell x-span={{ xs: '8' }}>
          <Text mt={20} variant="h2">
            Responsable de compte
          </Text>
        </Cell>
        {isResponsible && !openLinkManager && (
          <Cell textAlign="right" alignSelf="center" x-span={{ xs: '4' }}>
            <Link
              onClick={() => setOpenLinkManager(true)}
              mr={20}
              iconLeft={<EditIcon />}
            >
              Modifier
            </Link>
          </Cell>
        )}
      </Grid>
      {openLinkManager && (
        <Box width={1 / 1} mt={20}>
          <Box
            height={50}
            backgroundImage={'linear-gradient(to bottom, #f5f7fc,#ffffff);'}
          />
          <Text variant="h3" mb={20}>
            Désigner un autre responsable de compte
          </Text>
          <Text>
            Attention : vous êtes sur le point de désigner un autre
            <b> responsable de comptes</b> pour cet établissement. Une fois ce
            changement validé, vous perdrez tous vos droits en tant que
            responsable de compte. Vous pourrez toujours accéder aux
            informations de votre établissement mais vous n'aurez plus la
            possibilité de les modifier.
          </Text>

          <Box width={400}>
            <UserSelect
              filter={{
                cognitoUserId: {
                  //@ts-ignore
                  $in: allContactSets,
                },
              }}
              placeholder="Tous les contacts"
              //role={ERoles.PROVIDER_CONTACT}
              onChange={value => setManagerSelected(value)}
              value={undefined}
            />
          </Box>
          <Flex mt={20}>
            <Button
              onClick={() => setOpenLinkManager(false)}
              height={40}
              mr={20}
              variant="ghost"
            >
              Annuler
            </Button>
            <Button
              isDisabled={!managerSelected}
              onClick={() => addManager(managerSelected)}
              height={40}
              variant="filled"
            >
              Valider
            </Button>
          </Flex>
          <Box
            height={50}
            backgroundImage={'linear-gradient(to top, #f5f7fc,#ffffff);'}
          />
        </Box>
      )}
      <Box mt={20}>
        <UserCard user={establishment?.provider?.manager} />
      </Box>
      <Divider mt={20} mb={20} />
      <Box width={1 / 1}>
        <Flex width={1 / 1} justifyContent="space-between">
          <Text variant="h2">
            Chargés de mission {isFetchingUsers && <Spinner ml={20} />}
          </Text>
          {isResponsible && (
            <Link
              onClick={() => setOpenLInkContact(true)}
              mr={20}
              iconLeft={<LinkIcon />}
            >
              Rattacher un contact
            </Link>
          )}
        </Flex>
        {openLinkContact && (
          <Box width={1 / 1}>
            <Box
              height={50}
              backgroundImage={'linear-gradient(to bottom, #f5f7fc,#ffffff);'}
            />
            <Text variant="h3" mb={20}>
              Rattacher un nouveau chargé de mission
            </Text>
            <Box width={400}>
              <UserSelect
                filter={{
                  cognitoUserId: {
                    //@ts-ignore
                    $in: [...allContactSets],
                  },
                }}
                placeholder="Tous les contacts"
                //role={ERoles.PROVIDER_CONTACT}
                onChange={value => setContactSelected(value)}
                value={undefined}
              />
            </Box>
            <Flex mt={20}>
              <Button
                onClick={() => setOpenLInkContact(false)}
                height={40}
                mr={20}
                variant="ghost"
              >
                Annuler
              </Button>
              <Button
                isDisabled={!contactSelected}
                onClick={() => addContact(contactSelected)}
                height={40}
                variant="filled"
              >
                Rattacher
              </Button>
            </Flex>
            <Box
              height={50}
              backgroundImage={'linear-gradient(to top, #f5f7fc,#ffffff);'}
            />
          </Box>
        )}
      </Box>
      <Box maxWidth={'100vw'} mt={20} mb={20}>
        <AlphabeticalSearch
          value={letterQuery}
          onChange={(value: string | undefined) => setLetterQuery(value)}
        />
      </Box>
      <Box mt={20} mb={20}>
        <Grid cols={12}>
          <Cell x-span={grid}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              icon={<SearchIcon />}
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) => setSearchQuery(e.target.value)}
              pr={20}
            />
          </Cell>
        </Grid>
      </Box>
      <Grid cols={2}>
        {usersQuery &&
          usersQuery.users.map((user: any) => {
            const isTmp = establishment?.provider?.tempContacts?.findIndex(
              //@ts-ignore
              tmp => tmp.cognitoUserId === user.cognitoUserId
            );
            return (
              <Cell mt={20} x-span={grid2} placeSelf="left">
                <UserCard
                  temporary={isTmp !== -1}
                  user={user}
                  // TODO NEED US
                  // rightChidlrens={
                  //   isTmp === -1 &&
                  //   isResponsible && (
                  //     <Link
                  //       onClick={() => removeContact(user.cognitoUserId)}
                  //       iconLeft={<UnLinkIcon />}
                  //     >
                  //       Détacher
                  //     </Link>
                  //   )
                  // }
                  bottomChildrens={
                    isTmp !== -1 ? (
                      isResponsible ? (
                        <Flex mt={20}>
                          <Button
                            onClick={() => addTempContact(user.cognitoUserId)}
                            height={40}
                            mr={20}
                            variant="filled"
                          >
                            Rattacher
                          </Button>
                          <Button
                            onClick={() =>
                              removeTempContact(user.cognitoUserId)
                            }
                            height={40}
                            variant="ghost"
                          >
                            Refuser
                          </Button>
                        </Flex>
                      ) : (
                        <Box ml={'15px'} mt={'15px'}>
                          {/*@ts-ignore*/}
                          <Status variantColor={'warning'}>
                            <b>En cours de rattachement</b>
                          </Status>
                        </Box>
                      )
                    ) : (
                      <></>
                    )
                  }
                />
              </Cell>
            );
          })}
      </Grid>
      {usersQuery?.users && usersQuery.users?.length > 0 && (
        <Grid cols={2}>
          <Cell x-span={{ xs: '2' }}>
            <TablePagination
              total={usersQuery?.totalCount || 0}
              page={page}
              perPage={10}
              onChangePage={(p: number) => setPage(p)}
              labelData={'Chargés de mission'}
            />
          </Cell>
        </Grid>
      )}
    </Box>
  );
};

Contacts.displayName = 'Contacts';

export default Contacts;
