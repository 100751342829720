import {
  useContractorFindMany,
  getScopedDataBySiret,
  buildContractorByScopedData,
  DPAE_STATUS,
  DPAE_STATUS_SELECT,
  contractorNeedDpae,
} from '@commons';
import {
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
  EDpaeStatus,
  EContractorSocialStatus,
  EContractorStatus,
} from '@freelancelabs/teoreme-commons';
import { EyeIcon, SearchIcon } from 'components/icons';
import {
  Grid,
  Cell,
  Box,
  Input,
  FormLabel,
  Status,
  Link,
  Spinner,
} from 'components/ui';
import { StaticSelect } from 'components/selects/StaticSelect';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ContractorCard } from 'components/ui/Cards';
import { AlphabeticalSearch } from 'components/AlphabeticalSearch';
import { TablePagination } from 'components/Pagination';
type ContractorsProps = {
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers;
};
const grid = {
  xs: '12',
  sm: '6',
  md: '4',
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const Contractors: React.FC<
  React.PropsWithChildren<ContractorsProps>
> = ({ establishment }) => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [filterDPAE, setFilterDpae] = React.useState<string | undefined>();
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();
  const [letterQuery, setLetterQuery] = React.useState<string | undefined>();
  const { data: contractorsQuery, isFetching: isFetchingContractors } =
    useContractorFindMany({
      filterObject: {
        'scopedData.establishment.siret': establishment.siret,

        $and: filterDPAE
          ? filterDPAE !== 'N/A'
            ? [
                {
                  //'scopedData.dpae.uuid': { $exists: true },
                  scopedData: {
                    $elemMatch: {
                      'dpae.status': filterDPAE,
                      'establishment.siren': establishment.siren,
                    },
                  },
                },
              ]
            : [
                {
                  //'scopedData.establishment.uuid': { $exists: true },
                  scopedData: {
                    $elemMatch: {
                      // dpae: { $exists: false },
                      socialStatus: {
                        $in: [
                          EContractorSocialStatus.COMPANY_MANAGER,
                          EContractorSocialStatus.MAJORITY_MANAGER,
                        ],
                      },
                      'establishment.siren': establishment.siren,
                    },
                  },
                },
              ]
          : undefined,
        $or:
          !searchQuery && !letterQuery
            ? undefined
            : [
                searchQuery
                  ? { firstName: { $regex: searchQuery, $options: 'i' } }
                  : { firstName: { $regex: `^${letterQuery}`, $options: 'i' } },
                searchQuery
                  ? { lastName: { $regex: searchQuery, $options: 'i' } }
                  : { lastName: { $regex: `^${letterQuery}`, $options: 'i' } },
              ],
      },
      limit: 10,
      skip: 10 * page,
      sort: 'updatedAt',
    });
  const checkContractorIsComplete = (contractor: any) => {
    const scopedData = getScopedDataBySiret(
      contractor?.scopedData,
      establishment.siret as string
    );
    if (
      contractor.firstName &&
      contractor.lastName &&
      contractor.socialStatus &&
      contractor.jobTitle &&
      (contractor?.dpae?.status === EDpaeStatus.ACTIVE ||
        !contractorNeedDpae(scopedData))
    ) {
      return true;
    }
  };
  return (
    <Box width={1 / 1}>
      <Box maxWidth={'100vw'} mt={20} mb={20}>
        <AlphabeticalSearch
          value={letterQuery}
          onChange={(value: string | undefined) => setLetterQuery(value)}
        />
      </Box>
      <Box mt={20} mb={20}>
        <Grid cols={12}>
          <Cell x-span={grid}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              icon={<SearchIcon />}
              isFullWidth
              type="search"
              value={searchQuery}
              placeholder="Rechercher ..."
              onChange={(e: any) => setSearchQuery(e.target.value)}
              pr={20}
            />
          </Cell>
          <Cell x-span={grid}>
            <FormLabel>Statut de la DPAE</FormLabel>
            <StaticSelect
              width={1 / 1}
              options={[
                {
                  //@ts-ignore
                  value: undefined,
                  key: undefined,
                  label: 'Tous les statuts',
                },
                ...DPAE_STATUS_SELECT,
                {
                  //@ts-ignore
                  value: 'N/A',
                  key: 'N/A',
                  label: 'Non requise',
                },
              ]}
              value={'type'}
              onChange={(value: any) => {
                setFilterDpae(value as string);
                setPage(0);
              }}
            />
          </Cell>
          <Cell x-span={grid} alignSelf="center">
            {isFetchingContractors === true && (
              <Box ml={20}>
                <Spinner />
              </Box>
            )}
          </Cell>
        </Grid>
      </Box>
      <Grid cols={2}>
        {contractorsQuery &&
          contractorsQuery.contractors.map(contractor => {
            const scopedDataBySiret = getScopedDataBySiret(
              contractor?.scopedData,
              establishment.siret as string
            );
            const contractorBuild = buildContractorByScopedData(
              contractor,
              scopedDataBySiret
            );
            const isTmp = checkContractorIsComplete(contractorBuild);
            const dpaeStatus = {
              variantColor: 'success',
              //@ts-ignore
              text: DPAE_STATUS?.[contractorBuild?.dpae?.status || 'undefined'],
            };
            if (contractorBuild?.dpae) {
              switch (contractorBuild?.dpae?.status) {
                case EDpaeStatus.ACTIVE: {
                  dpaeStatus.variantColor = 'success';
                  break;
                }
                case EDpaeStatus.PENDING: {
                  dpaeStatus.variantColor = 'warning';
                  break;
                }
                case EDpaeStatus.TO_BE_VALIDATED: {
                  dpaeStatus.variantColor = 'warning';
                  break;
                }
                case EDpaeStatus.ARCHIVED: {
                  dpaeStatus.variantColor = 'grey';
                  break;
                }
                case EDpaeStatus.REJECTED: {
                  dpaeStatus.variantColor = 'error';
                  break;
                }
              }
            } else {
              if (
                contractorBuild?.socialStatus ===
                  EContractorSocialStatus.COMPANY_MANAGER ||
                contractorBuild?.socialStatus ===
                  EContractorSocialStatus.MAJORITY_MANAGER ||
                contractorBuild?.socialStatus ===
                  EContractorSocialStatus.SUBCONTRACTOR
              ) {
                dpaeStatus.variantColor = 'grey';
                dpaeStatus.text = 'DPAE non requise';
              } else {
                dpaeStatus.variantColor = 'error';
                //dpaeStatus.text = 'En attente de DPAE';
              }
            }
            return (
              <Cell mt={20} x-span={grid2} placeSelf="left">
                <ContractorCard
                  isDisabled={contractorBuild?.disabled === true}
                  temporary={
                    !isTmp || contractor?.status !== EContractorStatus.ACTIVE
                  }
                  user={contractorBuild}
                  rightChidlrens={
                    contractorBuild?.disabled === true ? (
                      <></>
                    ) : (
                      <Link
                        onClick={() =>
                          history.push(
                            `/contractors/${contractor?.uuid}/establishments`
                          )
                        }
                        iconLeft={<EyeIcon />}
                      >
                        Voir la fiche
                      </Link>
                    )
                  }
                  bottomChildrens={
                    contractorBuild?.disabled === true ? (
                      <Status variantColor={'error'}>
                        <b> Intervenant désactivé </b>
                      </Status>
                    ) : contractor?.status === EContractorStatus.ACTIVE ? (
                      <Box>
                        {/*@ts-ignore*/}
                        <Status variantColor={dpaeStatus.variantColor}>
                          <b>{dpaeStatus.text}</b>
                        </Status>
                      </Box>
                    ) : (
                      <Box>
                        <Status
                          variantColor={
                            contractor?.status === EContractorStatus.PENDING
                              ? 'warning'
                              : contractor?.status ===
                                  EContractorStatus.REJECTED
                                ? 'error'
                                : 'grey'
                          }
                        >
                          <b>
                            {contractor?.status === EContractorStatus.PENDING
                              ? 'Intervenant en attente de validation'
                              : contractor?.status ===
                                  EContractorStatus.REJECTED
                                ? 'Refusé'
                                : 'Archivé'}
                          </b>
                        </Status>
                      </Box>
                    )
                  }
                />
              </Cell>
            );
          })}
      </Grid>
      {contractorsQuery?.contractors &&
        contractorsQuery?.contractors?.length > 0 && (
          <Grid cols={2}>
            <Cell x-span={{ xs: '2' }}>
              <TablePagination
                total={contractorsQuery?.totalCount || 0}
                page={page}
                perPage={10}
                onChangePage={(p: number) => setPage(p)}
                labelData={'intervenants'}
              />
            </Cell>
          </Grid>
        )}
    </Box>
  );
};

Contractors.displayName = 'Contractors';

export default Contractors;
