import { Header } from 'components/Header';
import {
  getTradeNameSafe,
  useHeadQuarterFindOne,
  buildOneLineAddress,
  useEstablishmentFindManyRCPRO,
} from '@commons';
import {
  Link,
  Grid,
  Cell,
  Flex,
  Box,
  Text,
  SpinnerBox,
  Row,
  FormLabel,
  Divider,
  EstablishmentCard,
  Status,
} from 'components/ui';
import { useSetCrumbs } from 'hooks/breadCrumb';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AddIcon } from 'components/ui/icons';
import { ContractList } from 'components/Tables/ContractList';
import { RCPROList } from 'components/Tables/RcproList';
import { showAddRCPROModal } from 'components/modals/AddRcProModal';
import { IEstablishment } from '@freelancelabs/teoreme-commons';
type EditCRAPageProps = {
  //
};
export const grid2 = {
  xs: '2',
  sm: '2',
  md: '2',
  lg: '2',
  xl: '1',
};
export const HeadQuarter: React.FC<
  React.PropsWithChildren<EditCRAPageProps>
> = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: headQuarterData, status: hqStatus } = useHeadQuarterFindOne(id);
  const headquarter = headQuarterData?.[0]?.headquarter;
  const { data } = useEstablishmentFindManyRCPRO({
    uuid: id,
    filterObject: {
      status: { $in: ['ACTIVE', 'PENDING'] },
    },
    limit: 10,
    skip: 0,
    sort: 'createdAt',
  });
  const establishmentContacts: Array<string> = [];
  headQuarterData?.[0]?.establishments.map((estaC: IEstablishment) =>
    estaC?.provider?.contacts?.forEach((estaD: any) =>
      establishmentContacts.push(estaD)
    )
  );

  const establishments = headQuarterData?.[0]?.establishments;
  const me = localStorage?.cognitoUserId;

  let isTmpLink;
  let isResponsible;
  establishments &&
    establishments.forEach((esta: any) => {
      const tmpLink = esta?.provider?.tempContacts?.findIndex(
        (tmp: any) => tmp === me
      );
      if (tmpLink > -1) {
        isTmpLink = true;
      }
      if (
        esta?.provider?.manager?.cognitoUserId === localStorage.cognitoUserId
      ) {
        isResponsible = true;
      }
    });
  useSetCrumbs(
    [headquarter],
    [
      { label: 'Établissement', path: '/headquarter' },
      {
        label: `${getTradeNameSafe(headquarter)}`,
        path: `/headquarter/${id}`,
      },
    ]
  );

  if (hqStatus === 'pending') {
    return <SpinnerBox />;
  }
  //no result found
  if (hqStatus === 'success' && headQuarterData?.length === 0) {
    history.push('/404');
  }

  if (hqStatus === 'success' && headQuarterData?.length > 0) {
    return (
      <Box width={1 / 1}>
        <Header tabs={false}>
          <Text mb={'5px'} variant="h1">
            {getTradeNameSafe(headquarter)}
          </Text>
        </Header>
        <Box>
          <Box>
            <Text variant="h2">Informations</Text>
          </Box>
          <Box mt={20}>
            <Text fontSize={13} variant="b">
              {buildOneLineAddress(headquarter?.address)}
            </Text>
          </Box>
          <Row spacing={20} width="450px" mt={20}>
            <Flex width="100%">
              {headquarter?.siret ? (
                <>
                  <FormLabel mt={'2px'}>N° SIREN : </FormLabel>
                  <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
                    {headquarter?.siren}
                  </Text>
                </>
              ) : (
                <>
                  <FormLabel mt={'2px'}>N° D'IDENTIFICATION : </FormLabel>
                  <Text ml={'5px'} mb={'2px'} fontSize={13} variant="p">
                    {headquarter?.identifier}
                  </Text>
                </>
              )}
            </Flex>
            <Flex width="100%">
              <FormLabel mt={'2px'}>N° TVA : </FormLabel>
              <Text ml={'5px'} fontSize={13} variant="p">
                {headquarter?.vatNumber}
              </Text>
            </Flex>
          </Row>
          <Divider mb={40} mt={40} />
          <Grid cols={2} gap="5px" mt={20}>
            {establishments.map((esta: any) => {
              const status = { variantColor: 'success', text: 'Rattaché' };
              const me = localStorage?.cognitoUserId;
              const hasTmpContact = esta?.provider?.tempContacts?.findIndex(
                (tmp: any) => tmp === me
              );
              if (hasTmpContact > -1) {
                status.variantColor = 'warning';
                status.text = 'En cours de rattachement';
              }
              return (
                <Cell x-span={grid2} placeSelf="center">
                  <EstablishmentCard
                    height={'200px'}
                    temporary={hasTmpContact !== -1}
                    establishment={esta}
                    status={
                      <Box ml={'15px'} mt={'15px'}>
                        {/*@ts-ignore*/}
                        <Status variantColor={status.variantColor}>
                          <b>{status.text}</b>
                        </Status>
                      </Box>
                    }
                  />
                </Cell>
              );
            })}
          </Grid>
          <Grid cols={2} gap="5px" mt={20}>
            {/* <Cell x-span={grid2} placeSelf="center">
              <Box width={'570px'}>
                <Button>Ajouter un établissement</Button>
              </Box>
            </Cell> */}
          </Grid>
          <Divider mb={40} mt={40} />
          {isTmpLink !== true && (
            <Box>
              <Grid cols={2} gap="5px" mt={20}>
                <Cell x-span={{ xs: '12' }}>
                  <Text variant="h2" mb={20}>
                    Contrat-cadre
                  </Text>
                </Cell>
                <Cell x-span={{ xs: '12' }}>
                  <ContractList
                    filterObject={{
                      'establishment.uuid': {
                        $in:
                          headQuarterData?.[0]?.establishments?.length > 0
                            ? headQuarterData?.[0]?.establishments?.map(
                                (e: any) => e?.uuid
                              )
                            : headQuarterData?.[0]?.uuid,
                      },
                    }}
                  />
                </Cell>
              </Grid>
              <Divider mb={40} mt={40} />
              <Grid cols={12} gap="5px" mt={20}>
                <Cell x-span={{ xs: '10' }}>
                  <Text variant="h2" mb={20}>
                    Assurance Responsabilité Civile Professionnelle
                  </Text>
                </Cell>
                {/* {<Cell x-span={{ xs: '2' }}>
              {data?.length ? (
                //@ts-ignore
                data[0]?.contact?.cognitoUserId ===
                localStorage?.cognitoUserId ? (
                  <Link
                    onClick={() =>
                      showCancelRcProModal({
                        rcpro: data[0],
                      })
                    }
                    iconLeft={<AddIcon />}
                  >
                    Résilier
                  </Link>
                ) : (
                  <></>
                )
              ) : (
                <Link
                  iconLeft={<AddIcon />}
                  onClick={() =>
                    showAddRCPROModal({
                      providerContactsIds: establishmentContacts,
                      // establishmentIds: headQuarterData?.[0]?.establishments.map(
                      //   (esta: any) => esta.uuid
                      // ),
                    })
                  }
                >
                  Souscrire
                </Link>
              )}
            </Cell>} */}
                {isResponsible && (
                  // data?.results.findIndex(
                  //   rcpro =>
                  //     rcpro.status === 'ACTIVE' || rcpro.status === 'PENDING'
                  // ) === -1 && (
                  <Cell x-span={{ xs: '2' }}>
                    <Link
                      iconLeft={<AddIcon />}
                      onClick={() =>
                        showAddRCPROModal({
                          uuid: id,
                          providerContactsIds: establishmentContacts,
                        })
                      }
                    >
                      Souscrire
                    </Link>
                  </Cell>
                )}
                <Cell x-span={{ xs: '12' }}>
                  {data?.results.length ? (
                    <Text mt={20} mb={20}>
                      Une fois activée, votre RC PRO protège tous les
                      intervenants de votre entreprise dans le cadre de la
                      mission pour laquelle elle a été souscrite. A la fin de
                      cette mission, si vous souhaitez continuer à être couvert
                      par notre assurance responsabilité civile professionnelle,
                      merci d’y souscrire dans le cadre d’une autre mission.
                    </Text>
                  ) : (
                    <Text mt={20} mb={20}>
                      Vous n’avez pas de RC PRO Freelance.com active
                      actuellement pour cet établissement. Vous pourrez
                      souscrire à cette assurance pour une de vos missions
                      validées au moment du dépôt de votre facture sur
                      Connecteed (ou en contactant votre chargé de compte). A
                      noter : Vous pourrez résilier cette assurance facilement
                      et à n’importe quel moment en contactant votre chargé de
                      compte.
                    </Text>
                  )}
                  <RCPROList uuid={id} filterObject={{}} />
                </Cell>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return <></>;
};

HeadQuarter.displayName = 'HeadQuarter';

export default HeadQuarter;
