import { EOnboardingStep } from '@freelancelabs/teoreme-commons';
import {
  useMe,
  useGetOnBoardingStep,
  useUserUpdateOne,
  queryClient,
} from '@commons';
import { Stepper } from 'components/Stepper';
import { Box, Flex, Spinner } from 'components/ui';
import { OnBoardingStepTwo } from 'forms/onBoarding/stepTwo';
import { OnBoardingStepOne } from 'forms/OnBoardingStepOne';
//import OnBoardingStepThree from 'forms/OnBoardingStepThree';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AccesDenied from '../Common/AccesDenied';
import OnBoardFinish from './OnBoardFinish';
import { useBreadCrumb } from 'hooks/breadCrumb';
import { showCGUModal } from 'components/modals/CGUModal';

// type FormValues = {};
const steps = [
  { index: 0, title: 'Étape 1', subTitle: 'Vos informations' },
  { index: 1, title: 'Étape 2', subTitle: 'Votre établissement' },
  //{ index: 2, title: 'Étape 3', subTitle: 'Vos intervenants' },
];

export const OnBoardPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { me, isLoading: isLoadingMe, refetch: refetchMe } = useMe();
  const history = useHistory();
  const { setCrumbs } = useBreadCrumb();
  const { mutateAsync: updateUser } = useUserUpdateOne();
  setCrumbs([
    {
      label: `Embarquement`,
      path: '/user/account',
    },
  ]);
  const {
    data: onBoardingData,
    isLoading: isLoadingStep,
    isError,
    refetch,
  } = useGetOnBoardingStep(true);
  React.useEffect(() => {
    if (me && !me?.termsOfUseAccepted) {
      showCGUModal({
        beforeValidation: async () => {
          try {
            await updateUser({
              id: me?.cognitoUserId as string,
              user: {
                termsOfUseAccepted: true,
                isConfirmed: true,
              },
            });
            queryClient?.refetchQueries({ queryKey: ['me'] });
          } catch (e) {
            //console.log('catch', e);
          }
        },
      });
    }
  }, [me]);
  if (isLoadingStep || isLoadingMe) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  }

  if (isError || !onBoardingData || !me) {
    console.log(isError, onBoardingData, me);

    return <AccesDenied />;
  }

  if (me.onboardingStep?.ableToAccessSite) {
    history.push('/dashboard');
  }

  if (me.onboardingStep?.done) {
    return <OnBoardFinish />;
  }
  const { step } = onBoardingData;
  if (step === EOnboardingStep.STEP_ONE) {
    return (
      <Box>
        <Stepper steps={steps} currentStep={0} />
        <OnBoardingStepOne
          onBoardingData={onBoardingData}
          refreshStep={() => {
            refetchMe();
            refetch();
          }}
        />
      </Box>
    );
  }

  if (step === EOnboardingStep.STEP_TWO) {
    return (
      <Box>
        <Stepper steps={steps} currentStep={1} />
        <OnBoardingStepTwo
          onBoardingData={onBoardingData}
          refreshStep={() => {
            refetchMe();
            refetch();
          }}
        />
      </Box>
    );
  }

  // if (step === EOnboardingStep.STEP_THREE) {
  //   return (
  //     <Box>
  //       <Stepper steps={steps} currentStep={2} />
  //       <OnBoardingStepThree
  //         me={me}
  //         onBoardingData={onBoardingData}
  //         refreshStep={async () => {
  //           await refetchMe();
  //           await refetch();
  //         }}
  //       />
  //     </Box>
  //   );
  // }
  // fix for when onboarding is done but status in user me.onboardingStep is not updated
  // https://fcomdev.atlassian.net/browse/TEOR-5527?focusedCommentId=48229
  if (
    step === EOnboardingStep.STEP_THREE &&
    me.onboardingStep?.step !== 'STEP_THREE'
  ) {
    return (
      <Box>
        <Stepper steps={steps} currentStep={2} />
        <Flex
          top={0}
          left={0}
          bottom={0}
          right={0}
          alignItems="center"
          justifyContent="center"
          position="fixed"
        >
          <Spinner />
        </Flex>
      </Box>
    );
  }

  return <AccesDenied />;
};
export default OnBoardPage;
