import { Flex, Text, Box, Link, Spinner, Tag } from 'components/ui';
import { EyeIcon, TaskIcon, StarIcon } from 'components/ui/icons';
import { useNotificationFindMany, getCivilityAllKeys } from '@commons';
import {
  INotification,
  IJoinedNotificationUser,
} from '@freelancelabs/teoreme-commons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { ALink } from 'components/ui/ALink';
import { NOTIFICATION_TYPES } from '@commons';
import { Theme } from 'styles';
import { formatDateAndTime } from '@commons';

type Item = Itemize<IJoinedNotificationUser>;
export type Itemize<T> = T & { key: string };
export const LastNotifications = () => {
  const t = useTranslationFormat();
  const { data: notificationsQuery, status } = useNotificationFindMany({
    filterObject: {
      'notificationUser.state.read': false,
    },
    sort: '-createdAt',
    limit: 3,
    skip: 0,
  });
  const loading = status === 'pending';
  const items: Item[] =
    //@ts-ignore
    notificationsQuery?.results.map((notification: INotification) => ({
      ...notification,
      key: notification.uuid || '',
    })) || [];

  return (
    <>
      <Box
        display={'inline-flex'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Text variant="h2" ml={20}>
          Dernières notifications
        </Text>
        <ALink href={'/notifications/all'}>
          <Link iconLeft={<EyeIcon />} mr={22} mt={1}>
            Voir tout
          </Link>
        </ALink>
      </Box>
      {!loading && items.length === 0 && (
        <Box alignContent="center" alignItems="center">
          <TaskIcon style={{ margin: '0 auto' }} />
          <Text variant="h3" color={'primary.light'} textAlign="center">
            Aucune notification
          </Text>
          <Text color={'primary.light'} textAlign="center">
            Dès que vous recevrez une nouvelle notification, elle sera visible
            ici.
          </Text>
        </Box>
      )}
      {loading && (
        <Flex justifyContent="center" alignItems="center" alignContent="center">
          <Spinner />
        </Flex>
      )}
      {items.map(item => (
        <Box
          border="1px solid #d0d4e3"
          borderRadius={5}
          margin={20}
          padding={'10px'}
          minHeight={130}
          key={item.uuid}
          opacity={
            item.notificationUser?.state.read || item.defaultState?.read
              ? 0.5
              : 1
          }
        >
          <Flex
            display="inline-flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            mb={2}
            mt={2}
            width={1 / 1}
          >
            <Flex width={6 / 10}>
              <Flex justifyContent="flex-start">
                <StarIcon
                  fill={
                    item.notificationUser?.state.priority ||
                    item.defaultState?.priority
                      ? 'gold'
                      : 'grey'
                  }
                />
                <Tag
                  maxHeight={20}
                  marginLeft={2}
                  variantColor={
                    item.type === 'ALERT'
                      ? 'warning'
                      : item.type === 'ACTION'
                        ? 'error'
                        : 'success'
                  }
                >
                  {NOTIFICATION_TYPES[item.type]}
                </Tag>
                <Text ml={'10px'} fontSize={14} variant="b">
                  {
                    t(`notificationsProvider:${item.reference}`, false, false, {
                      ...getCivilityAllKeys(item.parameters, item?.reference),
                    }).split('--')[0]
                  }
                </Text>
              </Flex>
            </Flex>
            <Flex justifyContent="flex-end" width={3 / 10}>
              <ALink
                style={{ marginLeft: 'auto' }}
                href={t(
                  `linksProvider:${item.reference}`,
                  false,
                  false,
                  item.parameters
                )}
              >
                <Link iconLeft={<EyeIcon />}>Voir</Link>
              </ALink>
            </Flex>
          </Flex>
          <Box pl={4}>
            <Text>
              {t(`notificationsProvider:${item.reference}`, false, false, {
                ...getCivilityAllKeys(item.parameters, item?.reference),
              })
                .split('--')[1]
                ?.replaceAll('&amp;', '&')}
            </Text>
          </Box>
          {item.createdAt && (
            <Box mt={3} pl={4}>
              <Text color={Theme.colors.grey.default}>
                {formatDateAndTime(item.createdAt || '')}
              </Text>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};
export default LastNotifications;
