import { useNotificationStore } from 'store/notifications';

export function useShowMessage(): (
  variant: 'error' | 'success' | 'info',
  message: string
) => void {
  const { updateNotifications } = useNotificationStore();
  return (variant: 'error' | 'success' | 'info', message: string) => {
    updateNotifications({
      variant,
      message,
      open: true,
    });
  };
}
