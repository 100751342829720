import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HeadQuarter } from './Headquarter';
import { Establishment } from './Details';
import { Template } from 'components/Template';
import { Container } from 'components/ui';
export const Establishments = () => {
  return (
    <Switch>
      <Template>
        <Route exact path="/headquarter/:id">
          <Container>
            <HeadQuarter />
          </Container>
        </Route>
        <Route path="/headquarter/establishment/:id/:tab">
          <Container>
            <Establishment />
          </Container>
        </Route>
      </Template>
    </Switch>
  );
};
