import { useContractorFindMany } from '@commons';
import { useDebounce } from 'hooks/useDebounce';
import { mapSortByToApi } from './helpers';
import { useContractorListStore } from 'store/contractors';
import { EContractorSocialStatus } from '@freelancelabs/teoreme-commons';
export const useFetchData = ({ pageSize, sortBy }: any) => {
  const { selectedStatus, searchQuery, pageIndex, filterDPAE } =
    useContractorListStore();

  const debounceSearchQuery = useDebounce(searchQuery, 400);
  const AND = [];
  const NOT = [];
  if (filterDPAE) {
    if (filterDPAE === 'N/A') {
      NOT.push(EContractorSocialStatus.EMPLOYEE);
      NOT.push(EContractorSocialStatus.MINORITY_MANAGER);
    } else {
      AND.push({ 'scopedData.dpae.status': filterDPAE });
    }
  }
  if (selectedStatus && selectedStatus !== 'N/A') {
    AND.push({ status: selectedStatus });
  }
  const {
    data = { contractors: [], totalCount: -1, limit: 10 },
    status,
    isFetching,
  } = useContractorFindMany({
    filterObject: {
      'scopedData.socialStatus': NOT.length ? { $nin: NOT } : undefined,
      $and: AND.length ? AND : undefined,
      $or: [
        {
          firstName: {
            $regex: debounceSearchQuery,
            $options: 'i',
          },
        },
        {
          lastName: {
            $regex: debounceSearchQuery,
            $options: 'i',
          },
        },
        {
          'user.email': {
            $regex: debounceSearchQuery,
            $options: 'i',
          },
        },
      ],
    },
    sort: mapSortByToApi(sortBy),
    limit: pageSize,
    skip: pageIndex * pageSize,
  });

  return {
    data,
    status,
    isFetching,
  };
};
